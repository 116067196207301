import {ensureArray, concatArrays, uuid, riResolve} from "./lib.js"

export const isComplex = value => {
  return typeof value === 'object' && value !== null && typeof value['rbs:ref'] === 'undefined'
}

export const areComplex = values => {
  return values.every(isComplex)
}

export const entriesToComplex = entries => {
  const result = {}
  entries.forEach(([key, values]) => {
    if (values.length === 1) {
      result[key] = values[0]
    }
    if (values.length > 1) {
      result[key] = values
    }
  })
  return result
}

const valuesDeletePlace = (values, place) => {
  return concatArrays(values.map(value => {
    if (isComplex(value)) {
      return deletePlace(value, place)
    } else {
      return [value]
    }
  }))
}

const complexToEntries = complex => {
  return Object.keys(complex).map(key => [key, ensureArray(complex[key])])
}

export const deletePlace = (complex, place) => {
  if (!place.property && complex['rbs:id'] === place.complexId) {
    return []
  }
  return [entriesToComplex(complexToEntries(complex).map(([property, values]) => {
    if (place.property === property && complex['rbs:id'] === place.complexId) {
      values = values.filter((_, i) => i !== place.index)
    }
    return [property, valuesDeletePlace(values, place)]
  }))]
}

export const updateValue = (value, update) => {
  if (update.type === 'deletePlace') {
    return deletePlace(value, update.place)[0]
  } else if (update.type === 'setPlace') {
    return setPlace(value, update.place, update.value)
  } else if (update.type === 'batch') {
    return update.items.reduce((result, update) => {
      return updateValue(result, update)
    }, value)
  }
}


const valuesSetPlace = (values, place, newValue) => {
  return concatArrays(values.map(value => {
    if (isComplex(value)) {
      return setPlace(value, place, newValue)
    } else {
      return value
    }
  }))
}

export const setPlace = (complex, place, newValue) => {
  if (!place) {
    return newValue
  }
  if (!place.property && complex['rbs:id'] === place.complexId) {
    return newValue
  }

  const result = entriesToComplex(complexToEntries(complex).map(([property, values]) => {
    if (place.property === property && complex['rbs:id'] === place.complexId) {
      values = values.map((value, i) =>  {
        if (i === place.index) {
          return newValue
        }
        return value
      })
      if (place.index === values.length) {
        values.push(newValue)
      }
      
    }
    return [property, valuesSetPlace(values, place, newValue)]
  }))
  if (place.property && complex['rbs:id'] === place.complexId && !Object.keys(complex).includes(place.property) && place.index === 0) {
    result[place.property] = newValue
  }
  return result
}


export const removeFullIds = complex => {
  return entriesToComplex(Object.entries(complex).map(([property, value]) => {
    const values = ensureArray(value)
    const resultValues = values.map(item => {
      if (isComplex(item)) {
        return removeFullIds(item)
      } else {
        return item
      }
    })
    return [property, resultValues]
  }).filter(([property, _]) => property !== 'rbs:fullId'))
}

export const propertyLocalName = property => property.split(':')[1]

export const copyValue = value => {
  if (Array.isArray(value)) {
    return value.map(item => copyValue(item))
  }
  if (isComplex(value)) {
    const result = {}
    Object.keys(value).forEach(key => {
      if (key === 'rbs:id') {
        result[key] = uuid()
      } else if (key !== 'rbs:fullId') {
        result[key] = copyValue(value[key])
      }
    })
    return result
  }
  return value
} 

export const arrayToValues = array => {
  if (array.length === 0) {
    return null
  }
  if (array.length === 1) {
    return array[0]
  }
  return array
}

// reference

export const referenceFileName = reference => {
  const ref = reference['rbs:ref']
  const segments = ref.split('/')
  return segments[segments.length - 1]
}


export const resolveReference = (basePath, reference) => {
  const relativePath = reference['rbs:ref']
  return riResolve(basePath, relativePath)
}
