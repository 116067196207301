import React from "../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "../library.js";

const Input = createReactClass({
  handleChangeValue: function(event) {
    const component = this;
    const value = event.target.value;
    component.props.onValueUpdate(value);
  },
  render: function() {
    const component = this;
    const props = component.props;
    const placeholder = props.placeholder;
    const value = props.value;
    return <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={component.handleChangeValue}/>
    }
})

export {Input};
