import {FieldDef} from "./FieldDef.js";
import {WidgetModel} from "./WidgetModel.js";

const Clipboard = {
    paseFieldsFromClipboard: function(modeler) {
      var storageValue = localStorage.getItem('formModelerClipboard');
      if (storageValue) {
        var clipboard = JSON.parse(storageValue);
        clipboard.forEach(function(field) {
          FieldDef.setProto(field);
          field.setFlow(modeler.state.form.flow);
          field.setParents();
          field.generateRbsId();
        })
        if (clipboard.length != 0) {
          modeler.store(modeler.state.form);
        }
        var selectedField;
        if (modeler.state.selectedFieldIds.length == 1) {
          selectedField = modeler.selectedFields()[0];
        }

        clipboard.forEach(function(field) {
          var target;
          if (selectedField) {
            target = selectedField;
          } else {
            target = modeler.state.form;
          }

          if (WidgetModel.isSupportedChild(field.widget, target.widget)) {
            if (target.isTable()) {
              var title = field.getOption(["param", "@title"]);
              if(!title) {
                title = field.getOption(["param", "columns", "@title"]);
              }
              var fieldColumn = {
                "@sortable": true,
                "@title": title,
                "@visible": true
              };
              field.param.columns = fieldColumn;
              field.defParam.columns = fieldColumn;
              target.param.columns[field.id] = fieldColumn;
            }

            var copy = field.getCopy();
            target.setSubfields(target.getSubfields().concat([copy]));
            Clipboard.checkFieldIdUniquness(modeler,copy);
          }
        });
        modeler.formWasChanged();
      }
    },
    checkFieldIdUniquness: function(modeler,field) {
      var form = modeler.state.form;
      var fields = form.findFields(field.id);
      if (fields.length != 1) {
        var count = 1;
        while (form.findField(field.id + count))
        {
          count++;
        }
        var newFieldId = field.id + count;
        if (field.isInTable()) {
          var table =field.getTrueParent();
          table.tableRenameColumn(field.id, newFieldId);
        }
        field.setOption(["id"], newFieldId);
      }
      field.getSubfields().forEach(function(subfield) {
        Clipboard.checkFieldIdUniquness(modeler, subfield);
      })
    },
    copySelectedFields: function(modeler) {
      var clipboard = modeler.selectedFields().map(function(field) {return field.getCopy()})
      clipboard.forEach(function(field) {
        field.unsetParents();
        field.unsetFlow();
        field.deleteAllRbsIds();
      });
      localStorage.setItem('formModelerClipboard', JSON.stringify(clipboard));
    },
    deleteSelectedFields: function(modeler) {
      var stored = false;
      modeler.state.selectedFieldIds.forEach(function(fieldId) {
        var field = modeler.state.form.findFieldByRbsId(fieldId);
        if (field) {
          if (!stored) {
            modeler.store(modeler.state.form);
            stored = true;
          }
          field.removeField();
        }});
      modeler.setState({selectedFieldIds: []});
    }
};

export {Clipboard};
