import React from "../../miniclient/in/client/node_modules/react";
import ReactDOM from "../../miniclient/in/client/node_modules/react-dom";
import createReactClass from 'create-react-class';
import {Library} from "./library.js";

const InlineEditor = createReactClass({
  getInitialState: function() {
    return {text: undefined};
  },
  initialize: function(props) {
    const component = this;
     var editor = this;
     var context = editor.props.context;
     var node = ReactDOM.findDOMNode(this);
     let html = editor.props.html;
     if (typeof html === 'undefined') {
       html = '';
     }
     html = this.addFullUrls(html);
     node.innerHTML = html;
     var toolbar = [{ name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
     {name: "placeholders", items: ["CreatePlaceholder"]},
     {name: 'colors', items: [ 'TextColor', 'BGColor' ] },
     {name: 'links', items: [ 'Link', 'Unlink' ] },
     {name: 'edvanced', items: [ 'Image', 'Table' ] },
     //{name: 'unre', items: [ 'Undo', 'Redo' ] },
     "/",
     {name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] }];
     switch (editor.props.type) {
       case "img":
         toolbar = [ ["Image"]];
       break;
       case "plainText":
         toolbar = [["CreatePlaceholder"]];
       break;
       default:
     }
     editor.ckeditor = CKEDITOR.inline(node, {toolbar: toolbar, filebrowserBrowseUrl: '../miniapp/filebrowser/?tm=' + props.modelPath + '&baseUrl=' + encodeURIComponent(component.props.baseUrl)} );
     editor.ckeditor._placeholders = props.placeholders || [];
     editor.ckeditor.on('change', function( evt ) {
       if ( editor.withoutChanges)
         return;
       if (editor.changeTimeout) {
         clearTimeout(editor.changeTimeout);
       }
       editor.changeTimeout = setTimeout(function() {
         var html = evt.editor.getData();
         if (props.type === 'plainText') {
           html = html.replace(/<[^>]*>/g, '')
         }
         html = editor.removeFullUrls(html);
         editor._html = html;
         editor.props.onUpdate(html);
         editor.changeTimeout = undefined;
       }, 1000);

     });
     //var text = object.getValue();

     editor._html = html;
     editor.ckeditor.setData(html);
  },
  componentDidMount: function() {
    let editor = this;
    editor.initialize(editor.props);
  },
  componentWillReceiveProps: function(nextProps) {
    var editor = this;
    if (!Library.equals(editor.props.placeholders, nextProps.placeholders)) {
      editor.ckeditor.destroy();
      editor.initialize(nextProps);
    }
    if (nextProps.html != editor._html) {
      editor.withoutChanges = true;
      let html = nextProps.html;
      html = this.addFullUrls(html);
      editor._html = html;
      editor.ckeditor.setData(html, function() {
        editor.withoutChanges = false;
      });
    }
  },
  removeFullUrls: function (html) {
    const component = this;
    // <img href=""/>
    let reImg = new RegExp("<img([^>]* )src=\"([^\"]+)\"", "g");
    html = html.replace(reImg, function (match, c1, c2) {
      if (c2.startsWith(component.props.baseUrl)) {
        return '<img' + c1 + 'src="' + c2.substring(c2.lastIndexOf('/')+1) + '"';
      } else {
        return '<img' + c1 + 'src="' + c2 + '"';
      }
    });
    return html;
  },
  addFullUrls: function (html) {
    const component = this;
    // <img href=""/>
    let reImg = new RegExp("<img([^>]* )src=\"([^\"]+)\"", "g");
    html = html.replace(reImg, function (match, c1, c2) {
      if (c2.indexOf('/') === -1) {
        return '<img' + c1 + 'src="' + component.props.baseUrl + c2 + '"';
      } else {
        return '<img' + c1 + 'src="' + c2 + '"';
      }
    });
    return html;
  },
  render: function() {
    var editor = this;
    var tagName = editor.props.tagName;
    if (!tagName) {
      tagName = 'div';
    }
    return React.createElement(tagName, {contentEditable: true, style: editor.props.style});
  }
});

export {InlineEditor};
