import {SimpleTextInspector} from "./simple-text-inspector.jsx";
import {SimpleBooleanInspector} from "./simple-boolean-inspector.jsx";
import {SimpleLabelInspector} from "./simple-label-inspector.jsx";
import {SimpleEnumerationInspector} from "./simple-enumeration-inspector.jsx";
import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import {Library} from '../library.js';
import {ensureArray} from '../lib.js'

const SimpleInspector = createReactClass({
  load: function(context, property, value, possibility) {

    if (possibility) {
      let props
      const dataType = possibility['meta3:dataType']
      const enumeratedValues = ensureArray(possibility['meta3:enum'])
      if (enumeratedValues.length !== 0) {
        props = SimpleEnumerationInspector.prototype.load(context, property, value, enumeratedValues, possibility)
      } else if (dataType === 'boolean') {
        props = SimpleBooleanInspector.prototype.load(context, property, value, possibility)
      } else {
        props = SimpleTextInspector.prototype.load(context, property, value, possibility)
      }
      return {
        type: 'simpleInspector',
        dataType,
        ...props
      }
    }
    const enumeratedValues = Library.ensureArray(Library.propertyProperty(property, 'meta3:enum'));
    const display = Library.getPropertyProperty(property, 'app:display');
    const dataType = Library.propertyDataType(property);
    if (enumeratedValues.length !== 0) {
      SimpleEnumerationInspector.prototype.load(context, property, value)
    }
    let props;
    if (display === 'label') {
      props = SimpleLabelInspector.prototype.load(context, property, value);
    } else if (enumeratedValues.length != 0) {
      props = SimpleEnumerationInspector.prototype.load(context, property, value, enumeratedValues);
    } else if (dataType === 'boolean') {
      props = SimpleBooleanInspector.prototype.load(context, property, value);
    } else {
      props = SimpleTextInspector.prototype.load(context, property, value);
    }
    return Object.assign({
      type: 'simpleInspector',
      display: display,
      dataType: dataType
    }, props);
  },
  render: function() {
    const component = this;
    const props = component.props;
    let widget;
    if (props.display === 'label') {
      widget = React.createElement(SimpleLabelInspector, props);
    } else if (typeof props.enumeratedValues !== 'undefined' && props.enumeratedValues.length != 0) {
      widget = React.createElement(SimpleEnumerationInspector, props);
    } else if (props.dataType === 'boolean') {
      widget = React.createElement(SimpleBooleanInspector, props);
    } else {
      widget = React.createElement(SimpleTextInspector, props);
    }
    return widget;
  }
})

export {SimpleInspector};
