import React from "../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "./library.js";
import {Button} from 'semantic-ui-react';

const EditButton = createReactClass({
  handleClickEdit: function() {
    const component = this;
    const props = component.props;
    let path;
    if (component.isInEditor()) {
      path = props.file['meta3:path'];
    } else {
      path = 'properties-editor/' + props.file['meta3:path'];
    }
    props.onUpdate({type: 'routeTo', ri: path});
  },
  load(context) {
    return {file: context.file, path: context.riValue.path}
  },
  isInEditor() {
    const component = this;
    const props = component.props;
    return Library.isSubpath('properties-editor/', props.path);
  },
  render: function () {
    const component = this;
    const props = component.props;
    const buttonProps = {};
    if (props.file) {
      if (component.isInEditor()) {
        buttonProps.active = true;
      }
    } else {
      buttonProps.disabled = true;
    }
    return (
      <Button {...buttonProps}
        onClick={component.handleClickEdit}>
        Edit
      </Button>
     );
    }
});

export {EditButton};
