import React from "react";

import {MC} from './MC.js';
import {Widget} from "./Widget.jsx";

class Repeater extends React.Component {

  buildSubFields(field, iterations, disabled, readOnly) {
    var resolution = this.props.resolution;
    hrows = '';
    if (Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields) {
      var rows = MC.splitFieldsIntoRows(field.fields[0].fields, resolution);
      var hrows = [];
      for (var i = 0; i < rows.length; i++) {
        var maxX = MC.getMaxX(field.width, rows[i]);
        var hrow = [];
        var lastX = 0;
        for (var ii = 0; ii < rows[i].length; ii++) {
          var subField = rows[i][ii];
          let offsetDiv;
          if (MC.isNull(resolution)) {
            if (subField.x > lastX + Math.round(maxX/12)) {
              var cls = "ui " + MC.getFieldWideClass(maxX, subField.x - lastX) + " wide column field";
              offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>;
            }
          } else {
            var grid = MC.getFieldGrid(subField, resolution);
            if (grid.offset > 0) {
              var cls = "ui " + MC.getFieldWideClassFromInt(grid.offset) + " wide column field";
              offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>;
            }
          }
          subField.onSubmit = field.onSubmit;
          subField.flow = field.flow;
          hrow.push(<Widget key={subField.rbsid} widget={subField} maxX={maxX} ref={subField.rbsid} iteration={iterations} disabled={disabled} readOnly={readOnly}
                            resolution={resolution} offsetDiv={offsetDiv}/>);
          lastX = subField.x + subField.width;
        }
        hrows.push(<div key={i} className="ui row">{hrow}</div>);
      }
    }
    return hrows;
  }

  render() {
    var field = this.props.data;
    var count = MC.getRowsCount(this.props.data, this.props.iteration, 0);
    var widgets = [];
    if (MC.showAtLeastOneIteration(field) && count == 0) {
      count = 1;
    }
    var inline = MC.getFieldParamBooleanValue(this.props.data.param, '@inline', this.props.iteration);
    for (var i = 0; i < count; i++) {
      let iToPass = (this.props.iteration) ? [...this.props.iteration, i] : [i]
      var visible = MC.getFieldParamBooleanValue(this.props.data.param, '@visible', iToPass);
      var inlineCss = {};
      if (visible === false) {
        inlineCss.display = 'none';
      }
      var disabled = MC.getFieldParamBooleanValue(this.props.data.param, '@enabled', iToPass);
      if (disabled == null) {
        disabled = this.props.disabled;
      } else {
        disabled = !disabled;
      }
      var readOnly = MC.getFieldParamBooleanValue(this.props.data.param, '@readonly', iToPass);
      if (readOnly == null) {
        readOnly = this.props.readOnly;
      }
      var resolution = this.props.resolution;
      var wideClass = "twelve";
      if (inline && !MC.isNull(resolution)) {
        var grid = MC.getFieldGrid(field, resolution);
        wideClass = MC.getFieldWideClassFromInt(grid.columns);
      }
      var clsItem = MC.getFieldParamValue(this.props.data.param, '@cssClass', iToPass);
      var className = "ui " + wideClass + " wide column " + clsItem;
      widgets.push(<div key={i} className={className} style={inlineCss}><div className="ui twelve column grid stackable">{this.buildSubFields(this.props.data, iToPass, disabled, readOnly)}</div></div>);
    }
    let clsGrid = MC.getFieldParamValue(this.props.data.param, '@cssClassField', this.props.iteration);
    clsGrid = "ui twelve column grid stackable" + (clsGrid ? clsGrid : '');
    return (
    <div className={clsGrid} data-widget-i-name={field.id}>
      {widgets}
    </div>)
  }

}

export {Repeater};
