'use strict';
import {RB} from "../../lib/in/resourcebus/1.0.2018-07-31/resourcebus.js";

const Library = {};

// namespaces
Library._namespaces = {
  'http://resourcebus.org/ns/storage#': 'rbs',
  'https://metarepository.com/meta;v=3.0.x/ns#': 'meta3'
};

Library.properties = {};

Library.namespaces = () => Library._namespaces;

Library.namespacesPrefixes = () => { // Hot fix
  return ['rbs', 'mm', 'meta3', 'chub', 'dsl', 'app']
}

Library.logStorageOperation = false;

Library.log = (string) => {
  if (Library.logStorageOperation) {
    console.log(string);
  }
}

Library.invertedNamespaces = () => Library.invertObject(Library.namespaces());

Library._propertiesDefinitionsLoaded = false

Library.loadNamespaces = async (path) => {
  if (!Library._propertiesDefinitionsLoaded) {
    const result = await Library.runFlow('in/app', 'APPL_GetPropertiesDefinitions', {'app:ri': path})
    Library._propertiesDefinitions = Library.ensureArray(result['app:propertyDefinition'])
    Library._propertiesDefinitions.forEach(definition => {
      Library.properties[definition['app:property']] = definition['app:description']
    })
    Library._propertiesDefinitionsLoaded = true
  }
}

// flow
const propertiesType = 'application/vnd.org.resourcebus.meta+json';
const jsonType = 'application/json';

const runFlowRiTemplate = 'in/flow/api/?start=true&redirect=false&interactive=false&flowconfig={flowConfig}&operationname={operationName}&includeid=true&exptrace=false';
const runFlowAsGetRiTemplate = 'in/flow/api/?start=true&redirect=false&interactive=false&flowconfig={flowConfig}&operationname={operationName}&includeid=true&exptrace=false&inputdata={inputData}';

/*
Library.runFlow = (flowConfig, operationName, input, runAsGet) => {
  return new Promise((resolve, reject) => {
    let runFlowRi;
    if (runAsGet) {
      runFlowRi = runFlowAsGetRiTemplate.replace('{flowConfig}', flowConfig).replace('{operationName}', operationName).replace('{inputData}', encodeURIComponent(JSON.stringify(input)));
    } else {
      runFlowRi = runFlowRiTemplate.replace('{flowConfig}', flowConfig).replace('{operationName}', operationName);
    }
    const request = new XMLHttpRequest();
    const method = runAsGet ? 'GET' : 'POST';
    request.open(method, rbBaseUri + runFlowRi);
    request.setRequestHeader('Accept', 'application/json');

    if (typeof autorization !== 'undefined') {
      request.setRequestHeader('Authorization', autorization);
    }
    if (!runAsGet) {
      request.setRequestHeader('Content-Type', 'application/json');
      request.send(JSON.stringify(input));
    } else {
      request.send('');
    }
    request.onload = () => {
      resolve(JSON.parse(request.responseText));
    };
    request.onerror = error => {
      reject(error);
    };
  });
}*/


Library.runFlow = (flowConfig, operationName, input, runAsGet) => {
  let runFlowRi;
  if (runAsGet) {
    runFlowRi = runFlowAsGetRiTemplate.replace('{flowConfig}', flowConfig).replace('{operationName}', operationName).replace('{inputData}', encodeURIComponent(JSON.stringify(input)));
  } else {
    runFlowRi = runFlowRiTemplate.replace('{flowConfig}', flowConfig).replace('{operationName}', operationName);
  }
  const method = runAsGet ? 'GET' : 'POST';
  const rbResource = RB.resource().method(method).accept(jsonType).ri(runFlowRi)
  if (!runAsGet) {
    rbResource.content(JSON.stringify(input)).contentType(jsonType);
  }
  return rbResource.load().then(resource => {
    if (resource.status() == 200 || resource.status() == 204) {
      return JSON.parse(resource.content());
    } else {
      throw new Error('Run flow error: ' + JSON.stringify({status: resource.status(), flowConfig, operationName, input: input }));
    }
  });
}



Library.removeUserData = files => {
  return Library.entriesToObject(Library.objectEntries(files).filter(entry => {
    const properties = entry[1]['meta3:properties'];
    if (properties) {
      const userData = Library.boolean(properties['app:userData']);
      if (userData) {
        return false;
      }
    }
    return true;
  }));
}

Library.existFile = (path) => {
  if (Library.resources[path]) {
    return Promise.resolve(true);
  }
  const input = {'rbs:path': path}
  return Library.runFlow('in/storage/', 'STRG_GetFileResource', input, true).then(file => {
    if (typeof file['rbs:path'] === 'undefined') {
      return false;
    } else {
      return true;
    }
  })
}

Library.isFolderOrIndex = (file) => {
  return file['rbs:isFolder'] || Library.pathName(file['rbs:path']) === 'index';
}

Library.loadFileResource = (path) => {
  const filePromise = Library.filesPromises[path];
  if (typeof filePromise !== 'undefined') {
    return filePromise;
  }
  const resource = Library.resources[path];
  if (typeof resource !== 'undefined') {
    return Promise.resolve(resource);
  }
  const input = {'rbs:path': path}
  Library.log('Get:   ' + path);
  const newFilePromise = Library.runFlow('in/storage/', 'STRG_GetFileResource', input, true).then(file => {
    if (typeof file['rbs:path'] === 'undefined') {
      throw new Error('Could not load file resource on path ' + path)
    }
    Library.resources[path] = file;
    Library.storedResources[path] = file;
    const parentPath = file['rbs:isFolder'] ? RB.resolve('..', path) : RB.resolve('.', path);
    if (typeof Library.foldersItemsPaths[parentPath] != 'undefined' && !Library.arrayIncludes(Library.foldersItemsPaths[parentPath], path)) {
      Library.foldersItemsPaths[parentPath].push(path);
    }
    delete Library.filesPromises[path];
    return file;
  })
  Library.filesPromises[path] = newFilePromise;
  return newFilePromise;
}

Library.loadResource = Library.loadFileResource; // deprecated

Library.storeProperties = (path, properties) => {
  const input = {
    'rbs:path': path,
    'rbs:data': properties
  }
  Library.log('Store: ' + path);
  return Library.runFlow('in/storage/', 'STRG_StoreProperties', input);
}

Library.updateProperties = (path, properties) => {
  const input = {
    'rbs:path': path,
    'rbs:data': properties
  }
  Library.log('Update: ' + path);
  return Library.runFlow('in/storage/', 'STRG_UpdateProperties', input);
}

Library._listFolderRecursive = {};

Library.listFolder = (folderPath, recursive) => {
  if (!recursive) {
    const itemsPaths = Library.foldersItemsPaths[folderPath];
    if (typeof itemsPaths !== 'undefined') {
      const items = itemsPaths.map(itemPath => {
        return Library.resources[itemPath];
      });
      return Promise.resolve(items);
    }
  } else {
    if (Library._listFolderRecursive[folderPath]) {
      const itemsPaths = Library.getFolderItemsPathsRecursive(folderPath);
       const items = itemsPaths.map(itemPath => {
         return Library.resources[itemPath];
       });
       return Promise.resolve(items);
    }
  }
  let recursiveInput = 'false';
  if (recursive) {
    recursiveInput = 'true';
  }
  const input = {
      'rbs:recursive': recursiveInput,
      'rbs:path': folderPath
  };
  Library.log('List:  ' + folderPath);
  return Library.runFlow('in/storage/', 'STRG_List', input, true).then(result => {
    const items = Library.ensureArray(result['rbs:item']);
    items.forEach(item => {
      const itemPath = item['rbs:path'];
      if (typeof Library.resources[itemPath] === 'undefined') {
        Library.resources[itemPath] = item;
        Library.storedResources[itemPath] = item;
      }
    })
    const itemsPaths = items.filter(item => {
      const name = Library.pathName(item['rbs:path']);
      return name !== 'index';
    }).map(item => item['rbs:path']);
    if (recursive) {
      Library.storeFolderItemsPathsRecursive(folderPath, itemsPaths);
    } else {
      Library.foldersItemsPaths[folderPath] = itemsPaths;
    }
    return items;
  })
}

Library.getFolderItemsPathsRecursive = (folderPath) => {
   const itemsPaths = Library.foldersItemsPaths[folderPath];
   const arrayOfPaths = itemsPaths.map(itemPath => {
     if (Library.isFolderPath(itemPath)) {
       return Library.getFolderItemsPathsRecursive(itemPath)
     } else {
       return [];
     }
   })
   return itemsPaths.concat(Library.concatArrays(arrayOfPaths))
}

Library.storeFolderItemsPathsRecursive = (folderPath, paths) => {
  const itemsPaths = paths.filter(path => Library.isItemPath(folderPath, path));
  Library.foldersItemsPaths[folderPath] = itemsPaths;
  Library._listFolderRecursive[folderPath] = true;
  itemsPaths.forEach(itemPath => {
    if (Library.isFolderPath(itemPath)) {
      Library.storeFolderItemsPathsRecursive(itemPath, paths);
    }
  })
}

// reference
Library.referenceFragment = (ri) => {
  return ri.split('#')[1];
}

Library.isReferenceValueLocal = referenceValue => {
  if (typeof referenceValue['rbs:ref'] === 'undefined') {
    return false
  }
  return referenceValue['rbs:ref'][0] === '#'
}

// RI
Library.riFragment = (ri) => {
  return ri.split('#')[1];
}

Library.riPath = (ri) => {
  return ri.split('#')[0];
}

// path
Library.isItemPath = (folderPath, path) => {
  const relativePath = Library.relativizePath('/' + folderPath,'/' + path);
  if (relativePath.split('..').length !== 1) {
    return false;
  }
  if (Library.isFolderPath(path)) {
    return relativePath.split('/').length === 2;
  } else {
    return relativePath.split('/').length === 1;
  }
}

Library.isSubpath = (path1, path2) => {
  if (path1[path1.length - 1] != '/') {
    path1 += '/';
  }
  if (path2[path2.length - 1] != '/') {
    path2 += '/';
  }
  const relativePath = Library.relativizePath('/' + path1,'/' + path2);
  return relativePath[0] !== '/' && relativePath.split('..').length === 1;
}

Library.isProperSubpath = (path1, path2) => {
  return path1 !== path2 && Library.isSubpath(path1, path2);
}

Library.isFolderPath = (path) => {
  return path[path.length - 1] === '/';
}

Library.pathFileName = (path) => {
  if (Library.isFolderPath(path)) {
    return Library.folderPathFileName(path);
  } else {
    return Library.nonFolderPathFileName(path);
  }
}

Library.resourceName = Library.pathFileName;

Library.pathName = Library.pathFileName;

Library.nonFolderPathFileName = (path) => {
  return path.split('/').slice(-1)[0];
}

Library.folderName = (path) => {
  const lastSegment = path.split('/').slice(-2,-1)[0];
  return lastSegment.split(';')[0];
}

Library.folderPathFileName = Library.folderName;

Library.pathVersion = (path) => {
  const steps = path.split('/');
  const versionStep = steps.find(step => step.split(';').length === 2);
  if (typeof versionStep === 'undefined') {
    return 'master';
  }
  return versionStep.split(';v=')[1];
}

Library.propertyProperties = (property) => {
  return Library.properties[property] || {};
}

Library.getPropertyDefinition = Library.propertyProperties; // deprecated
Library.propertyDefinition = Library.propertyProperties; // deprecated

Library.getPropertyProperty = (property1, property2) => {
  return Library.propertyDefinition(property1)[property2]
}

Library.propertyProperty = Library.getPropertyProperty;

Library.isComplex = (value) => value instanceof Object && !(value instanceof Array) && typeof value['rbs:ref'] === 'undefined';

Library.isSimple = (value) => !(value instanceof Object);

Library.isReference = (value) => value instanceof Object && typeof value['rbs:ref'] !== 'undefined';

Library.areComplex = (values) => {
  return values instanceof Array && values.every(value => Library.isComplex(value));
}

Library.areSimple = (values) => {
  return values instanceof Array && values.every(value => Library.isSimple(value));
}

Library.areValuesComplex = Library.areComplex; // deprecated

Library.isResource = value => {
  return typeof value['meta3:path'] !== 'undefined';
}


// generate rbs ids
Library.integerSequence = () => {
  let index = 0;
  return () => {
    return index++;
  }
}

Library._complexGenerateRbsIds = (complex, idMaker) => {
  const result = {};
  Library.objectEntries(complex).forEach(([key, value]) => {
    const values = Library.ensureArray(value);
    let resultValues;
    if (Library.areComplex(values)) {
      resultValues = values.map(value => Library._complexGenerateRbsIds(value, idMaker));
    } else {
      resultValues = values;
    }
    result[key] = Library.unwrapSingleton(resultValues);
  })
  if (!result['rbs:id']) {
    result['rbs:id'] = String(idMaker());
  }
  return result
}

Library.complexGenerateRbsIds = (complex, uuid = false) => {
  let idMaker;
  if (uuid) {
    idMaker = Library.guid;
  } else {
    idMaker = Library.integerSequence();
  }
  return Library._complexGenerateRbsIds(complex, idMaker);
}

// make properties update
Library.makePropertyValuesUpdateEntries = (basePath, property, oldValues, newValues, isRoot) => {
  if (Library.areComplex(oldValues) && Library.areComplex(newValues)) {
    return  Library.makeComplexValuesUpdate(basePath, oldValues, newValues, isRoot).then(resultUpdate => {
      if (resultUpdate.length !== 0) {
        return [property, resultUpdate];
      }
    })
  } else {
    if (!Library.equals(oldValues, newValues)) {
      return [property, Library.unwrapSingleton(newValues)];
    }
  }
}
Library.makeComplexNotMatchedEntriesUpdate = (basePath, oldComplex, newComplex, isRoot) => {
  return Promise.all(Library.objectEntries(newComplex).map(entry => {
    const property = entry[0];
    if (typeof oldComplex[property] === 'undefined') {
      const newValues = Library.ensureArray(entry[1]);
      if (Library.areComplex(newValues)) {
        return Library.makeComplexValuesUpdate(basePath, [], newValues, isRoot).then(updateValues => {
          return [property, updateValues];
        })
      } else {
        return [property, Library.unwrapSingleton(newValues)];
      }
    }
  }))
}

Library.makeComplexMatchedEntriesUpdate = (basePath, oldComplex, newComplex, isRoot) => {
  return Promise.all(Library.objectEntries(oldComplex).map(entry => {
    const property = entry[0];
    if (typeof newComplex[property] !== 'undefined') {
      const newValues = Library.ensureArray(newComplex[property]);
      const oldValues = Library.ensureArray(oldComplex[property]);
      return Library.makePropertyValuesUpdateEntries(basePath, property, oldValues, newValues, isRoot)
    } else {
      return [property, ''];
    }
  }))
}

Library.makeComplexUpdate = async (basePath, oldComplex, newComplex, isRoot) => {
  if (Library.isComplexEmpty(newComplex)) {
    return []
  }
  const matchedEntries = await Library.makeComplexMatchedEntriesUpdate(basePath, oldComplex, newComplex, false)
  const matchedResult = Library.entriesToObject(matchedEntries.filter(entry => typeof entry !== 'undefined'))
  const notMatchedEntries = await Library.makeComplexNotMatchedEntriesUpdate(basePath, oldComplex, newComplex, false)
  const notMatchedResult = Library.entriesToObject(notMatchedEntries.filter(entry => typeof entry !== 'undefined'))
  const result = {...matchedResult, ...notMatchedResult}
  if (Library.objectEntries(result).length !== 0) {
    result['rbs:id'] = oldComplex['rbs:id']
    return [result]
  }
  return []
}


Library.makeComplexValuesMatchedUpdate = (basePath, oldValues, newValues, isRoot) => {
  return Promise.all(oldValues.map(oldValue => {
    let matchedValue = newValues.find(newValue => newValue['rbs:id'] == oldValue['rbs:id']);
    if (matchedValue) {
      return Library.makeComplexUpdate(basePath, oldValue, matchedValue, isRoot);
    } else {
      return [{'rbs:id': oldValue['rbs:id']}];
    }
  })).then(arrayOfValues => {
    return Library.concatArrays(arrayOfValues);
  });
}


Library.makeComplexNotMatchedUpdate = (basePath, notMatchedValue, isRoot) => {
  const skeleton = {'rbs:id': notMatchedValue['rbs:id']};
  if (notMatchedValue['meta3:subtypeOf']) {
    skeleton['meta3:subtypeOf'] = notMatchedValue['meta3:subtypeOf'];
  }
  if (notMatchedValue['meta3:instanceOf']) {
    skeleton['meta3:instanceOf'] = notMatchedValue['meta3:instanceOf'];
  }
  let inheritedPromise;
  if (Object.keys(skeleton).length > 1) {
    inheritedPromise = Library.computeInheritance(basePath, skeleton, isRoot);
  } else {
    inheritedPromise = Promise.resolve(skeleton);

  }
  return inheritedPromise.then(inherited => {
    return Library.makeComplexUpdate(basePath, inherited, notMatchedValue, isRoot).then(skeletonUpdate => {
      let notMatchedResult = {...skeleton};
      if (skeletonUpdate.length !== 0) {
        notMatchedResult = {...notMatchedResult, ...skeletonUpdate[0]}
      }
      return notMatchedResult;
    })
  });
}



Library.makeComplexValuesNotMatchedUpdate = (basePath, oldValues, newValues, isRoot) => {
  return Promise.all(newValues.filter(newValue => {
    return !oldValues.find(oldValue => oldValue['rbs:id'] === newValue['rbs:id']);
  }).map(notMatchedValue => {
    return Library.makeComplexNotMatchedUpdate(basePath, notMatchedValue, isRoot);
  }))
}

Library.makeComplexValuesUpdate = async (basePath, oldValues, newValues, isRoot) => {
  const matchedResult = await Library.makeComplexValuesMatchedUpdate(basePath, oldValues, newValues, isRoot)
  const notMatchedValues = await Library.makeComplexValuesNotMatchedUpdate(basePath, oldValues, newValues, isRoot)
  return [...matchedResult, ...notMatchedValues]
}

// simplified properties
Library.namespacesToProperties = (complex, prefix) => {
  const prefixes = Library.namespacesPrefixes();
  const result = {};
  Object.keys(complex).forEach(property => {
    const propertyPrefix = Library.propertyPrefix(property);
    const localName = Library.propertyLocalName(property);
    if (Library.arrayIncludes(prefixes, localName)) {
      throw new Error('Local name of ' + property + ' is a prefix of a namespace.');
    }
    const values = Library.ensureArray(complex[property]);
    let simplifiedValue;
    if (Library.areSimple(values)) {
      simplifiedValue = values;
    } else {
      simplifiedValue = values.map(value => Library.namespacesToProperties(value, propertyPrefix));
    }
    const unwrappedValue = Library.unwrapSingleton(simplifiedValue);
    if (propertyPrefix === prefix ) {
      result[localName] = unwrappedValue;
    } else {
      if (typeof result[propertyPrefix] === 'undefined') {
        result[propertyPrefix] = {};
      }
      result[propertyPrefix][localName] = unwrappedValue;
    }
  })
  return result;
}

Library.propertiesToNamespaces = (complex, prefix) => {
  const prefixes = Library.namespacesPrefixes();
  let result = {};
  Object.keys(complex).forEach(key => {
    if (Library.arrayIncludes(prefixes, key)) {
      Object.assign(result, Library.propertiesToNamespaces(complex[key], key));
    } else {
      const property = prefix + ':' + key;
      const values = Library.ensureArray(complex[key]);
      let resultValues;
      if (Library.areSimple(values)) {
        resultValues = values;
      } else {
        resultValues = Library.unwrapSingleton(values.map(value => {
         return Library.propertiesToNamespaces(value, prefix);
        }))
      }
      result[property] = Library.unwrapSingleton(resultValues);
    }
  })
  return result;
}

// inheritance
Library._cacheInheritedComplexes = {};

Library.deleteCacheInheritedComplexes = () => {
  Library._cacheInheritedComplexes = {};
}

Library.cacheInheritedComplex = (basePath, complexId) => {
  if (Library.pathName(basePath) === complexId) {
    return Library._cacheInheritedComplexes[basePath]
  } else {
    return Library._cacheInheritedComplexes[basePath + '#' + complexId]
  }
}

Library.cacheSetInheritedComplex = (basePath, complexId, inheritedComplex) => {
  if (Library.pathName(basePath) === complexId) {
    Library._cacheInheritedComplexes[basePath] = inheritedComplex
  } else {
    Library._cacheInheritedComplexes[basePath + '#' + complexId] = inheritedComplex;
  }
}

Library.isSubtypeOf = (basePath, value, conceptPath) => {
  if (Library.isResource(value)) {
    if (value['meta3:path'] === conceptPath) {
      return Promise.resolve(true);
    } else {
      return Library.isSubtypeOf(value['meta3:path'], value['meta3:properties'], conceptPath);
    }
  } else {
    const superObjectsReferences = Library.ensureArray(value['meta3:subtypeOf']);
    return Promise.all(superObjectsReferences.map(reference => {
      const targetPath = Library.referenceResolve(reference, basePath);
      return Library.getInheritedFileResource(targetPath).then(concept => Library.isSubtypeOf(undefined, concept, conceptPath));
    })).then(results => results.some(value => value))
  }
}

Library.pathIsSubtypeOf = (path, superPath) => {
  if (path === superPath) {
    return Promise.resolve(true);
  } else {
    return Library.getInheritedFileResource(path).then(file => Library.isSubtypeOf(undefined, file, superPath));
  }
}

Library.isInstanceOf = (basePath, value, conceptPath) => {
  if (Library.isResource(value)) {
    if (!value['meta3:properties']) {
      return Promise.resolve(false);
    }
    return Library.isInstanceOf(value['meta3:path'], value['meta3:properties'], conceptPath);
  } else {
    const conceptsReferences = Library.ensureArray(value['meta3:instanceOf']);
    return Promise.all(conceptsReferences.map(reference => {
      const targetPath = Library.referenceResolve(reference, basePath);
      return Library.pathIsSubtypeOf(targetPath, conceptPath);
    })).then(results => results.some(value => value));
  }
}

Library.inheritedId = (id, isRoot, baseId) => {
  if (isRoot) {
    return id;
  } else {
    return baseId + '_' + id;
  }
}

Library.valuesInheritanceUpdate = (firstValues, secondValues, isRoot, baseId) => {
  const arrayFirstValues = Library.ensureArray(firstValues);
  const arraySecondValues = Library.ensureArray(secondValues);
  const areFirstComplex = Library.areValuesComplex(arrayFirstValues);
  const areSecondComplex = Library.areValuesComplex(arraySecondValues);
  const areComplex = areFirstComplex && areSecondComplex;
  if (areComplex) {
    const updatedValues = arrayFirstValues.map(firstValue => {
      const matchedValue = arraySecondValues.find(secondValue => {
        const secondValueId = secondValue['rbs:id']
        const id = Library.inheritedId(secondValueId, isRoot, baseId);
        return id == firstValue['rbs:id'];
      });
      if (typeof matchedValue === 'undefined') {
        return firstValue;
      } else {
        if (Library.isComplex(firstValue) && Library.isComplex(matchedValue)) {
          return Library.complexInheritanceUpdate(firstValue, matchedValue, isRoot, baseId);
        } else {
          return firstValue;
        }
      }
    });
    const notMatchedValues = arraySecondValues.filter(secondValue => {
      return !arrayFirstValues.some(firstValue => {
        const id = Library.inheritedId(secondValue['rbs:id'], isRoot, baseId);
        return id === firstValue['rbs:id'];
      });
    }).map(value => {
      if (isRoot) {
        return value;
      } else {
        const id = Library.inheritedId(value['rbs:id'], isRoot, baseId);
        return Library.complexInheritanceUpdate({'rbs:id': id}, value, isRoot, baseId);
      }
    })
    const resultValues = updatedValues.concat(notMatchedValues);
    if (resultValues.length === 1) {
      return resultValues[0];
    } else {
      return resultValues;
    }
  } else {
    if (!firstValues || firstValues.length === 0) {
      return secondValues;
    } else {
      return firstValues;
    }
  }
}

Library.complexInheritanceUpdate = (firstComplex, secondComplex, isRoot, baseId) => {
  let complexBaseId;
  if (typeof baseId === 'undefined') {
    complexBaseId = firstComplex['rbs:id'];
  } else {
    complexBaseId = baseId;
  }
  const notMatchedKeyValues = Library.objectEntries(secondComplex).filter(keyValue => {
    const key = keyValue[0];
    const keyNotMatched = typeof firstComplex[key] === 'undefined';
    return keyNotMatched;
  }).map(keyValue => {
    if (isRoot) {
      return keyValue;
    } else {
      const updatedValues = Library.valuesInheritanceUpdate([], keyValue[1], isRoot, complexBaseId)
      const updatedKeyValue = [keyValue[0], updatedValues];
      return updatedKeyValue
    }
  })

  // update existing key values
  const updatedKeyValues =  Library.objectEntries(firstComplex).map(keyValue => {
    const key = keyValue[0];
    const firstValues = keyValue[1];
    const secondValues = secondComplex[key];
    let updatedValues;
    updatedValues = Library.valuesInheritanceUpdate(firstValues, secondValues, isRoot, complexBaseId)
    return [key, updatedValues];
  }).concat(notMatchedKeyValues);
  return Library.entriesToObject(updatedKeyValues);
}

Library.computeInheritance = (basePath, complex, isRoot) => {
  const input = {
    'meta3:path': basePath,
    'meta3:properties': complex,
    'meta3:isRoot': isRoot
  }
  return Library.runFlow('in/meta', 'META_Inheritance', input).then(result => {
    return result['meta3:properties'] || {'rbs:id': complex['rbs:id']};
  })
}

Library.computeInheritanceOnClient = (basePath, complex, isRoot) => {
  const inheritedComplex = Library.cacheInheritedComplex(basePath, complex['rbs:id']);
  if (inheritedComplex) {
    return Promise.resolve(inheritedComplex);
  }
  const inheritedKeyValuesPromises = Library.objectEntries(complex).map(([key, values]) => {
    let inheritedValuesPromise;
    if (!(values instanceof Array) && Library.isComplex(values)) {
      inheritedValuesPromise = Library.computeInheritance(basePath, values, false);
    } else if (Library.areValuesComplex(values)) {
      inheritedValuesPromise = Promise.all(values.map(value => {
        return Library.computeInheritance(basePath, value, false);
      }));
    } else {
      inheritedValuesPromise = Promise.resolve(values);
    }
    return inheritedValuesPromise.then(inheritedValues => [key, inheritedValues]);
  });
  return Promise.all(inheritedKeyValuesPromises).then(inheritedKeyValues => {
    const inherited = Library.entriesToObject(inheritedKeyValues);
    const subtypeOfReferences = Library.ensureArray(complex['meta3:subtypeOf']);
    const subtypeOfConceptsPromises = subtypeOfReferences.map(reference => Library.referencedValue(basePath, reference));
    const instanceOfReferences = Library.ensureArray(complex['meta3:instanceOf']);
    const instanceOfConceptsPromises = instanceOfReferences.map(reference => Library.referencedValue(basePath, reference));
    return Promise.all(subtypeOfConceptsPromises).then(concepts => {
      return concepts.reduce((result, concept) => {
        return Library.complexInheritanceUpdate(result, concept, isRoot, undefined);
      }, inherited);
    }).then(inherited => {
      return Promise.all(instanceOfConceptsPromises).then(concepts => {
        return concepts.reduce((result, concept) => {
          const properties = concept['meta3:properties'];
          const prototypeProps = properties['meta3:prototype'];
          if (prototypeProps) {
            return Library.complexInheritanceUpdate(result, prototypeProps, isRoot, undefined);
          } else {
            return result;
          }
        }, inherited);
      }).then(inheritedComplex => {
        Library.cacheSetInheritedComplex(basePath, complex['rbs:id'], inheritedComplex);
        return inheritedComplex;
      })
    })
  })

}

Library.inheritance = (basePath, complex) => {
  return Library.computeInheritance(basePath, complex, true);
}

// interpretation

Library.interpretation = (complex) => {
  const resultEntries = Library.objectEntries(complex).map(keyValue => {
    const property = keyValue[0];
    const values = Library.ensureArray(keyValue[1]);
    const dataType = Library.propertyProperty(property, 'meta3:dataType');
    const isCollection = Library.propertyProperty(property, 'meta3:isCollection') === 'true';
    let result;
    if (dataType === 'boolean') {
      result = values.map(value => value === 'true');
    } else if (dataType === 'int' || dataType === 'Number') {
      result = values.map(value => Number(value));
    } else {
      result = values;
    }

    if (!isCollection) {
      result = result[0]
    }
    return [property, result];
  })
  return Library.entriesToObject(resultEntries);
}

// references

Library.resolveReference = (basePath, reference) => {
   return Library.referenceResolve(reference, basePath);
}

Library.pathResolve = RB.resolve;


Library.riResolve = (baseRi, relativeRi) => {
  const resolved = RB.resolve(relativeRi, '/' + baseRi);
  if (resolved !== '/') {
    return resolved.slice(1);
  } else {
    return '/'
  }
}

Library.resolveRelativePath = Library.riResolve;

Library.referenceResolve = (reference, basePath) => {
  const relativePath = reference['rbs:ref'];
  return Library.resolveRelativePath(basePath, relativePath);
}

Library.fileProperties = (file) => { // deprecated
  if (file['rbs:properties']) {
    return file['rbs:properties'];
  } else {
      return {'rbs:id': Library.pathFileName(file['rbs:path'])}
  }
}

Library.fileInheritedProperties = (file) => {
  return file['meta3:properties'];
}

Library.unwrapSingleton = array => {
  if (array.length === 1) {
    return array[0];
  } else {
    return array;
  }
}

Library.ensureArray = (value) => {
  if (typeof value === 'undefined') {
    return [];
  } else if (value instanceof Array) {
    return value;
  } else {
    return [value];
  }
}

Library.last = array => {
  return array[array.length - 1];
}

Library.ensureObject = (value) => {
  if (typeof value === 'undefined') {
    return {};
  } else if (!(value instanceof Object)) {
    throw new Error('Value can not be converted to an object.', value);
  } else {
    return value;
  }
}

Library.sortComplex = function(listOfComplex) {
  let first = listOfComplex.filter(complex => {
    return complex['meta3:specialPosition'] === 'first';
  })
  let last = listOfComplex.filter(complex => {
    return complex['meta3:specialPosition'] === 'last';
  })
  let others = listOfComplex.filter(complex => {
    return typeof complex['meta3:specialPosition'] === 'undefined';
  });
  others.sort((item1, item2) => {
    let position1 = Number(item1['meta3:position']);
    let position2 = Number(item2['meta3:position']);
    if (typeof position1 != "undefined" && typeof position2 != "undefined") {
      return position1 - position2;
    }
  });
  return first.concat(others).concat(last);
}

// complex
Library.isComplexEmpty = (complex) => {
  const nonIdKeys = Object.keys(complex).filter(property => property !== 'rbs:id' && property !== 'rbs:fullId');
  return nonIdKeys.length === 0;
}

Library.findComplex = (complex, id) => {
  const results = Library.findComplexes(complex, id);
  if (results.length === 0) {
    throw new Error('Complex value with given id does not exists.');
  }
  if (results.length > 1) {
    throw new Error('There are multiple complex values with given id.');
  }
  return results[0];
}

Library.findComplexes = (complex, id) => {
  if (complex['rbs:id'] === id) {
    return [complex];
  } else {
    return Library.concatArrays(Object.keys(complex).map(key => {
      const values = Library.ensureArray(complex[key]);
      if (Library.areComplex(values)) {
        return Library.concatArrays(values.map(value => {
          return Library.findComplexes(value, id);
        }))
      } else {
        return [];
      }
    }))
  }
}

// updates

Library.makeComplexValueUpdate = (complexValue, update) => {
  const results = Library.makeComplexValueUpdates(complexValue, update);
  if (results.length === 0) {
    throw new Error('Could not find complex with update id.');
  }
  if (results.length > 1) {
    throw new Error('More then one complexes are found to update.')
  }
  return results[0];
}

Library.makeComplexValueUpdates = (complexValue, update) => {
  const id = update['rbs:id'];
  if (complexValue['rbs:id'] === id) {
    return [update];
  } else {
    const arrayOfResults = Object.keys(complexValue).map(key => {
      const values = complexValue[key];
      if (Library.areComplex(values)) {
        const arrayOfResults = values.map(value => {
          return Library.makeComplexValueUpdates(value, update).map(update => {
            const result = {'rbs:id': complexValue['rbs:id']};
            result[key] = [update];
            return result;
          })
        })
        return Library.concatArrays(arrayOfResults);
      } else if (Library.isComplex(values)) {
        return Library.makeComplexValueUpdates(values, update).map(update => {
          const result = {'rbs:id': complexValue['rbs:id']};
          result[key] = [update];
          return result;
        })
      } else {
        return [];
      }
    })
    return Library.concatArrays(arrayOfResults);
  }
}


Library.complexUpdate = (firstComplex, secondComplex) => {
  if (Library.isComplexEmpty(secondComplex)) {
    return [];
  } else {
    const notMatched = Library.objectEntries(secondComplex).filter(keyValue => {
      return typeof firstComplex[keyValue[0]] === 'undefined';
    })
    const updated = Library.objectEntries(firstComplex).map(([key, value]) => {
      const firstValues = Library.ensureArray(value);
      const secondValues = Library.ensureArray(secondComplex[key]);
      const updatedValues = Library.valuesUpdate(firstValues, secondValues);
      return [key, updatedValues];
    }).filter(([key, values]) => {
      return values.length !== 0;
    }).map(([key, values]) => {
      if (values.length !== 1) {
        return [key, values];
      } else {
        return [key, values[0]];
      }
    })
    const resultEntries = updated.concat(notMatched);
    const resultObject = Library.entriesToObject(resultEntries);
    if (Library.isComplexEmpty(resultObject)) {
      return [];
    } else {
      return [resultObject];
    }
  }
}

Library.complexValuesUpdate = (firstValues, secondValues) => {
  const updated = Library.concatArrays(firstValues.map(value => {
    const matchedValue = secondValues.find(updateItem => updateItem['rbs:id'] == value['rbs:id']);
    if (matchedValue) {
      return Library.complexUpdate(value, matchedValue);
    } else {
      return [value];
    }
  }));
  const notMatched = secondValues.filter(secondValue => {
    return !firstValues.find(firstValue => {
      return firstValue['rbs:id'] === secondValue['rbs:id'];
    })
  });
  return updated.concat(notMatched);
}

Library.valuesUpdate = (firstValues, secondValues) => {
  if (secondValues.length === 0) {
    return firstValues;
  } else {
    const areFirstComplex = Library.areComplex(firstValues);
    const areSecondComplex = Library.areComplex(secondValues);
    if (areFirstComplex && areSecondComplex) {
      return Library.complexValuesUpdate(firstValues, secondValues);
    } else {
      if (secondValues.length === 1 && secondValues[0] === '') {
        return []
      } else {
        return secondValues;
      }
    }
  }
}

// properties
Library.propertyPrefix = property => {
  const parts = property.split(":");
  return parts[0];
}

Library.propertyNamespace = Library.propertyPrefix;

Library.propertyLocalName = property => {
  const parts = property.split(":");
  return parts[1];
}

Library.namespaceURL = function(namespace) {
  return Object.keys(Library.namespaces()).find((url) => {
    return Library.namespaces()[url] === namespace;
  });
}

Library.namespaceRi = function(namespace) {
  const url = Library.namespaceURL(namespace);
  const start = url.slice(0, 27)
  const end = url.slice(-4)
  if ('https://metarepository.com/' == start && "/ns#" == end) {
    const ri = url.slice(27, -1);
    return ri;
  } else {
    throw new Error("URL of namespace does not have the required format.");
  }
}

Library.namespaceDefinition = (namespace) => {
  const ri = Library.namespaceRi(namespace);
  return Library.resources[ri];
}

Library.sortProperties = (properties) => {
    const result = properties.slice()
    result.sort((property1, property2) => {
      const position1 = Library._propertiesDefinitions.findIndex(definition => {
        return definition['app:property'] === property1
      });
      const position2 = Library._propertiesDefinitions.findIndex(definition => {
        return definition['app:property'] === property2
      });
      return position1 - position2
    })
    return result;
}

Library.propertyShortName = (propertyFullName) => {
  const parts = propertyFullName.split("#");
  const namespaceUrl = parts[0] + "#";
  const namespace = Library.namespaces()[namespaceUrl];
  const localName = parts[1];
  const property = namespace + ":" + localName;
  return property;
}

Library.propertyTitle = (property) => {
  const title = Library.getPropertyProperty(property, 'meta3:title');
  if (title) {
    return title['meta3:titleText'];
  }
  return property;
}

Library.propertyDataType = (property) => {
  const propertyDefinition = Library.propertyDefinition(property);
  if (propertyDefinition) {
    return propertyDefinition['meta3:dataType'];
  }
}
// simple JSON

Library.convertType = (value, dataType) => {
  switch (dataType) {
    case 'Number':
    case 'int':
    case 'number':
      return Number(value);
    break;
    case 'Bool':
    case 'boolean':
      return value == 'true';
      break;
    case 'json':
      return JSON.parse(value);
      break;
    default:
      return value;
  }
}

// Argument property je povinný pouze u jednoduchých hodnot a slouží ke konverzi datového typu.
Library.toSimpleJSON = (value, property) => {
  if (value instanceof Array) {
    throw new Error('Could not convert an array to simple JSON.')
  }
  if (Library.isSimple(value)) {
    const dataType = Library.propertyProperty(property, 'meta3:dataType');
    return Library.convertType(value, dataType);
  }
  if (Library.isReference(value)) {
    throw new Error('Could not turn reference to simple JSON.')
  }
  const result = {};
  const nameValuePairs = Library.ensureArray(value['mm:nameValuePair']);
  nameValuePairs.forEach(pair => {
    const name = pair['mm:name'];
    const value = Library.toSimpleJSON(Library.ensureArray(pair['mm:value'])[0]);
    result[name] = value;
  })
  const booleanValue = value['meta3:boolean'];
  if (typeof booleanValue !== 'undefined') {
    return booleanValue == 'true';
  }
  Object.keys(value).forEach(property => {
    if (property != 'mm:nameValuePair') {
      const namespace = Library.propertyNamespace(property);
      if (namespace == "mm") {
        const localName = Library.propertyLocalName(property);
        const values = Library.ensureArray(value[property]);
        const isCollection = Library.boolean(Library.propertyProperty(property, 'meta3:isCollection'));
        if (isCollection) {
          result[localName] = values.map(value => {
            return Library.toSimpleJSON(value, property);
          });
        } else {
          if (values.length > 1) {
            throw new Error('Property is not a collection.');
          }
          result[localName] = Library.toSimpleJSON(values[0], property);
        }
      }
    }
  })
  return result;
}

// changes manager

// general

Library.boolean = (value) => {
  return value === 'true';
}

Library.entriesToObject = (entries) => {
  return entries.reduce((result, keyValue) => {
    result[keyValue[0]] = keyValue[1];
    return result;
  }, {})
}

Library.invertObject = (object) => {
  return Object.keys(object).reduce((result,key) => {
     result[object[key]] = key;
     return result;
  }, {});
}

Library.objectEntries = (object) => {
  if (typeof Object.entries !== 'undefined') {
    return Object.entries(object);
  } else {
    return Object.keys(object).map(key => [key, object[key]]);
  }
}

Library.arrayIncludes = (array, value) => {
  if (typeof array.includes !== 'undefined') {
    return array.includes(value);
  } else {
    return array.find(element => element === value);
  }
}

Library.objectValues = (object) => {
  if (typeof Object.values !== 'undefined') {
    return Object.values(object);
  } else {
    return Object.keys(object).map(key => object[key]);
  }
}

Library.removeDuplicates = (array) => {
  return array.filter((item, pos) => {
    return array.indexOf(item) == pos;
  })
}

Library.zip = (array1, array2) => {
  return array1.map((item, index) => {
    return [item, array2[index]];
  });
}

Library.split = (array, predicate) => {
  return array.reduce((accumulator, value) => {
    if (predicate(value)) {
      return [[...accumulator[0], value], accumulator[1]];
    } else {
      return [accumulator[0], [...accumulator[1], value]];
    }
  }, [[], []]);
}

Library.equals = (value1, value2) => {
  if (value1 === value2) {
    return true;
  } else if (value1 instanceof Array) {
    if (value2 instanceof Array) {
      if (value1.length === value2.length) {
        return Library.zip(value1, value2).every(([item1, item2]) => {
          return Library.equals(item1, item2);
        })
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else if (value1 instanceof Object) {
    if (value2 instanceof Object) {
      const keys1 = Object.keys(value1);
      const keys2 = Object.keys(value2);
      return Library.removeDuplicates([...keys1, ...keys2]).every(key => {
        return Library.equals(value1[key], value2[key]);
      })
    } else {
      return false
    }
  } else {
    return false;
  }
}

Library.guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

Library.concatArrays = (arrays) => {
  return arrays.reduce((result, array) => {
    return result.concat(array);
  }, []);
}

Library.riRelativize = (sourceRi, targetRi) => Library.relativizePath('/' + sourceRi, '/' + targetRi)

Library.relativizePath = function(source, target) {
  var sep = '/';
	var targetArr = target.split(sep);
	var sourceArr = source.split(sep);
  var filename = targetArr.pop();
  var targetPath = targetArr.join(sep);
	var relativePath = '';
  sourceArr.pop();
	while (targetPath.indexOf(sourceArr.join(sep)) === -1) {
		sourceArr.pop();
		relativePath += ".." + sep;
	}
  if (sourceArr.length <= 1) {
    return target;
  }
	var relPathArr = targetArr.slice(sourceArr.length);
	relPathArr.length && (relativePath += relPathArr.join(sep) + sep);
	return relativePath + filename;
}

Library.relativizeReferences = (basePath, complex) => {

}

// url
Library.hrefParameters = (href)  => {
  let regex = /[?&]([^=#]+)=([^&#]*)/g,
      url = href || window.location.href,
      params = {},
      match;
  while(match = regex.exec(url)) {
      params[match[1]] = match[2];
  }
  return params;
}

Library.urlQueryParameter = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

Library.setHref = ri => {
  if (ri !== Library.locationRi(ri)) {
    window.history.pushState(null, 'title', Library.getBase() + ri)
  }
}

Library.getBase = () => {
  let base = document.querySelector('base').href.split('?')[0]
  return base.substring(0, base.lastIndexOf("/") + 1)
}

Library.locationRi = () => {
  const base = Library.getBase();
  return window.location.href.substring(base.length);
}

Library.parseRi = (ri) => {
  const pathAndQuery = ri.split('#')[0];
  const fragment = ri.split('#')[1];
  const path = pathAndQuery.split('?')[0];
  const queryString = pathAndQuery.split('?')[1];
  const query = {};
  if (queryString) {
    const pl = /\+/g;
    const search = /([^&=]+)=?([^&]*)/g;
    const decode = s => decodeURIComponent(s.replace(pl, " "))
    let match;
    while (match = search.exec(queryString)) {
      const attribute = decode(match[1]);
      const value =  decode(match[2]);
      if (query[attribute]) {
        if (typeof query[attribute] === 'array') {
          query[attribute] = [...query[attribute], value];
        } else {
          query[attribute] = [query[attribute], value];
        }
      } else {
        query[attribute] = value;
      }
    }
  }
  return {query, path, fragment};
}

Library.stringifyRiValue = (riValue) => {
  const queryString = Library.concatArrays(Library.objectEntries(riValue.query).map(([attribute, value]) => {
    const encodedAttribute = encodeURIComponent(attribute);
    return Library.ensureArray(value).map(singleValue => {
      return encodedAttribute + '=' + encodeURIComponent(singleValue);
    })
  })).join('&');
  let result = riValue.path;
  if (queryString.length !== 0) {
    result += '?' + queryString;
  }
  if (riValue.fragment) {
    result += '#' + riValue.fragment;
  }
  return result;
}

// promises

Library._promiseReduce = (accumulator, array, callback, index) => {
  if (array.length === 0) {
    return Promise.resolve(accumulator)
  }
  return callback(accumulator, array[0], index).then(result => {
    return Library._promiseReduce(result, array.slice(1), callback, index + 1);
  })
}

Library.promiseReduce = (array, callback, initialValue) => {
  return Library._promiseReduce(initialValue, array, callback, 0);
}

Library.promiseFilter = (array, callback) => {
  return Promise.all(array.map(item => callback(item))).then(truthValues => {
    return Library.concatArrays(truthValues.map((truthValue, index) => {
      if (truthValue) {
        return [array[index]];
      } else {
        return [];
      }
    }));
  })
}

Library.asyncFind = async (array, callback) => {
  if (array.length !== 0) {
    const result = await callback(array[0])
    if (result) {
      return array[0]
    } else {
      return Library.asyncFind(array.slice(1), callback)
    }
  }
}

Library.asyncSome = async (array, callback) => {
  if (array.length !== 0) {
    const result = await callback(array[0])
    if (result) {
      return true
    } else {
      return Library.asyncSome(array.slice(1), callback)
    }
  }
}

// destructive functions

Library.objectClean = (object) => {
  Object.keys(object).forEach(key => {
    delete object[key];
  })
}

// server resource
Library.catchErrors = (promise) => {
  return promise.catch(error => {
    const errorObject = {message: error.message, stack: error.stack};
    const errorString = JSON.stringify(errorObject);
    console.log(errorString);
    rbContext.setResultError(errorString);
    out.println(errorString);
  })
}


Library.callOnce = func => {
  let called = false;
  let result;
  return async (...args) => {
    if (!called) {
      result = await func(...args);
      called = true;
    } 
    return result;
  }
}

export {Library};
