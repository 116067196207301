import React from "react";
import {Dropdown} from 'semantic-ui-react'

import {EditableLabel} from "../modeler/EditableLabel.jsx";

import {Widget} from "./Widget.jsx";
import {Paginator} from "./Paginator.jsx";
import {MC} from './MC.js';

class Table extends React.Component {

  buildTableRow(field, iteration, disabled, readOnly, columns) {
    var row = [];
    if (Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields) {
      for (var i = 0; i< field.fields[0].fields.length; i++) {
        var subField = field.fields[0].fields[i];
        subField.onSubmit = field.onSubmit;
        subField.flow = field.flow;
        var cls = MC.getFieldParamValue(subField.param, '@cssClass', iteration);
        cls = cls ? cls : '';
        var halign =  MC.getFieldParamValue(subField.param, '@horizontalAlignment', iteration);
        if (halign) {
          if (halign == 'right') {
            cls += ' right aligned';
          } else if (halign == 'center') {
            cls += ' center aligned';
          }
        }
        var layoutVisible = MC.getFieldParamValue(columns[subField.id], '@layoutVisible');
        if (!MC.isNull(layoutVisible) && layoutVisible !== '') {
          layoutVisible = layoutVisible.split(' ');
        } else {
          layoutVisible = false;
        }
        var columnVisible = MC.getFieldParamBooleanValue(columns[subField.id], '@visible', iteration);
        if(subField.flow.modelerReact && subField.flow.modelerReact.state.ghostMode) {
          columnVisible = true;
        }
        if (!columnVisible || Array.isArray(layoutVisible) && !MC.isNull(this.props.resolution) && layoutVisible.indexOf(this.props.resolution) == -1) {
          continue;
        }
        var visible = MC.getFieldParamBooleanValue(subField.param, '@visible', iteration);
        if(subField.flow.modelerReact && subField.flow.modelerReact.state.ghostMode) {
          visible = true;
        }
        if (visible) {
          row.push(<td className={cls} key={i}><Widget key={subField.rbsid} ref={subField.rbsid} widget={subField} iteration={iteration} resolution={this.props.resolution} inTable={true} disabled={disabled} readOnly={readOnly}/></td>);
        } else {
          row.push(<td className={cls} key={i}>&nbsp;</td>);
        }
      }
    }
    return row;
  }

  onPaginate = (page) => {
    MC.putFieldParamValue(this.props.data.param, 'settings/@page', this.props.iteration, page);
    var dataAction = MC.getFieldParamValue(this.props.data.param, '@dataAction', null);
    if (!MC.isNull(dataAction)) {
      if (this.props.data.flow && MC.isFunction(this.props.data.flow.paginateForm)) {
        this.props.data.flow.paginateForm(MC.getFieldParamValue(this.props.data.param, '@dataAction', null));
      } else {
        MC.error('paginateForm function not found in passed flow!');
      }
    } else {
      if (this.props.data.flow) {
        this.props.data.flow.submitForm.call(this.props.data.flow, this.props.data, true, this.props.iteration, null);
      } else {
        MC.error('paginateForm function not found in passed flow!');
      }
    }
  };

  onRowsPerPage = (e, dropdown) => {
    MC.putFieldParamValue(this.props.data.param, 'settings/@defaultRowsPerPage', this.props.iteration, dropdown.value);
    MC.putFieldParamValue(this.props.data.param, 'settings/@page', this.props.iteration, 1);
    var dataAction = MC.getFieldParamValue(this.props.data.param, '@dataAction', null);
    if (!MC.isNull(dataAction)) {
      if (this.props.data.flow && MC.isFunction(this.props.data.flow.paginateForm)) {
        this.props.data.flow.paginateForm(dataAction);
      } else {
        MC.error('paginateForm function not found in passed flow!');
      }
    } else {
      if (this.props.data.flow) {
        this.props.data.flow.submitForm.call(this.props.data.flow, this.props.data, true, this.props.iteration, null);
      } else {
        MC.error('paginateForm function not found in passed flow!');
      }
    }
  };

  onSort = (e) => {
    var dataName = e.target.getAttribute('data-data-name');
    if (!MC.isNull(dataName)) {
      MC.putFieldParamValue(this.props.data.param, 'settings/@page', this.props.iteration, null);
      var oldDataName = MC.getFieldParamValue(this.props.data.param, 'settings/@sortColumn', this.props.iteration);
      if (oldDataName === dataName) {
        var sortAscending = MC.getFieldParamBooleanValue(this.props.data.param, 'settings/@sortAscending', this.props.iteration);
        MC.putFieldParamValue(this.props.data.param, 'settings/@sortAscending', this.props.iteration, !sortAscending);
      } else {
        MC.putFieldParamValue(this.props.data.param, 'settings/@sortAscending', this.props.iteration, true);
      }
      MC.putFieldParamValue(this.props.data.param, 'settings/@sortColumn', this.props.iteration, dataName);
      var dataAction = MC.getFieldParamValue(this.props.data.param, '@dataAction', null);
      if (!MC.isNull(dataAction)) {
        if (this.props.data.flow && MC.isFunction(this.props.data.flow.paginateForm)) {
          this.props.data.flow.paginateForm(MC.getFieldParamValue(this.props.data.param, '@dataAction', null));
        } else {
          MC.error('paginateForm function not found in passed flow!');
        }
      } else {
        if (this.props.data.flow) {
          this.props.data.flow.submitForm.call(this.props.data.flow, this.props.data, true, this.props.iteration, null);
        } else {
          MC.error('paginateForm function not found in passed flow!');
        }
      }
    }
  };

  render() {
    var field = this.props.data;
    var dataAction = MC.getFieldParamValue(field.param, '@dataAction', null);
    var sortColumn = MC.getFieldParamValue(field.param, 'settings/@sortColumn');
    var sortAscending = MC.getFieldParamBooleanValue(field.param, 'settings/@sortAscending', this.props.iteration);
    var headAlign =  MC.getFieldParamValue(field.param, 'settings/@headAlign');
    var pageable = MC.getFieldParamBooleanValue(field.param, 'settings/@pageable', this.props.iteration);
    let rowsPerPageSelector = MC.getFieldParamBooleanValue(field.param, 'settings/@rowsPerPageSelector', this.props.iteration);
    var header = [];
    if (Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields) {
      field.fields[0].flow = field.flow;
      for (var i = 0; i < field.fields[0].fields.length; i++) {
        var column = field.param.columns[field.fields[0].fields[i].id];
        if (column) {
          var layoutVisible = MC.getFieldParamValue(column, '@layoutVisible');
          if (!MC.isNull(layoutVisible) && layoutVisible !== '') {
            layoutVisible = layoutVisible.split(' ');
          } else {
            layoutVisible = false;
          }
          var columnVisible = MC.getFieldParamBooleanValue(column, '@visible');
          if (field.flow.modelerReact && field.flow.modelerReact.state.ghostMode) {
            columnVisible = true;
          }
          if (!columnVisible || Array.isArray(layoutVisible) && !MC.isNull(this.props.resolution) && layoutVisible.indexOf(this.props.resolution) == -1) {
            continue;
          }
          var dataName = MC.getFieldParamValue(column, '@dataName');
          var sortable = MC.getFieldParamBooleanValue(column, '@sortable') === true;
          var title = MC.getFieldParamValue(column, '@title');
          var cls = '';
          if (headAlign) {
            if (headAlign == 'right') {
              cls = 'right aligned';
            } else if (headAlign == 'center') {
              cls = 'center aligned';
            }
          }
          var inline = {};
          var onclick = this.onSort;
          if (MC.isNull(dataAction) && !pageable || !sortable || MC.isNull(dataName) || dataName === '') {
            inline.cursor = 'auto';
            onclick = null;
          } else {
            if (sortColumn === dataName && !MC.isNull(sortColumn)) {
              if (sortAscending) {
                cls += ' sorted ascending';
              } else {
                cls += ' sorted descending';
              }
            } else {
              cls += ' sorted sortable';
            }
          }
          if (MC.isModelerActive(field)) {
            title = <EditableLabel field={field.fields[0].fields[i]} widget={title} path={["param", "columns", "@title"]}/>
          }
          header.push(<th key={field.fields[0].fields[i].rbsid} className={cls} data-data-name={dataName} style={inline} onClick={onclick}>{title}</th>);
        } else {
          header.push(<th key={i}>&nbsp;</th>);
        }
      }
    }
    var count = MC.getRowsCount(field, this.props.iteration, 0);
    if (MC.showAtLeastOneIteration(field) && count == 0) {
        count = 1;
    }
    field.fields[0].fields.forEach(function (subField) {
        subField.flow = field.flow;
    });
    var body = [];
    for (var i=0; i<count; i++) {
      let iToPass = (this.props.iteration) ? [...this.props.iteration, i] : [i]
      var visible = MC.getFieldParamBooleanValue(field.param, '@visible', iToPass);
      var inlineCss = {};
      if (visible === false) {
        inlineCss.display = 'none';
      }
      var disabled = MC.getFieldParamBooleanValue(field.param, '@enabled', iToPass);
      if (disabled == null) {
        disabled = this.props.disabled;
      } else {
        disabled = !disabled;
      }
      var readOnly = MC.getFieldParamBooleanValue(field.param, '@readonly', iToPass);
      if (readOnly == null) {
        readOnly = this.props.readOnly;
      }
      var cssClass = null;
      if (Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields) {
        cssClass = MC.getFieldParamValue(field.fields[0].param, '@cssClass', iToPass);
      }
      body.push(<tr key={i} className={cssClass} style={inlineCss}>{this.buildTableRow(field, iToPass, disabled, readOnly, field.param.columns)}</tr>);
    }
    var cls = 'ui table ';
    cls += (MC.getFieldParamValue(field.param, '@cssClass', this.props.iteration) ? MC.getFieldParamValue(field.param, '@cssClass', this.props.iteration) : '');
    var pag = null;
    // paging
    if (!MC.isNull(dataAction) || pageable) {
      cls += ' sortable';
      var perPage = 20;
      var defaultRowsPerPage = MC.getFieldParamValue(field.param, 'settings/@defaultRowsPerPage', this.props.iteration);
      if (MC.isNumeric(defaultRowsPerPage)) {
        perPage = parseInt(defaultRowsPerPage);
      }
      var rows = MC.getFieldParamValue(field.param, 'settings/@rows');
      var pPage = MC.getFieldParamValue(field.param, 'settings/@page');
      if (MC.isNumeric(rows)) {
        var totalCount = parseInt(rows);
        var page = 1;
        if (MC.isNumeric(pPage)) {
          page = parseInt(pPage);
        } else if (Array.isArray(pPage) && MC.isNumeric(pPage[0])) {
          page = parseInt(pPage[0]);
        }
        var totalPages = Math.ceil(totalCount/perPage);
        let rowsSelector;
        if (rowsPerPageSelector) {
          let rowsOptions = MC.getFieldParamValue(field.param, 'settings/@rowsPerPageItems*', this.props.iteration);
          if (MC.isNull(rowsOptions) || !Array.isArray(rowsOptions)) {
            rowsOptions = [5, 10, 20, 50];
          }
          const options = rowsOptions.map(v => {return {value: v, text: v}});
          rowsSelector = <Dropdown selection compact onChange={this.onRowsPerPage} value={perPage} options={options}/>
        }
        pag = (
          <Paginator totalPages={totalPages} page={page} onChange={this.onPaginate} key="paginator" rowsSelector={rowsSelector}/>
        );
      }
    }
    return (
      <div className="tableWrapper">
        <table ref="table" className={cls} data-widget-i-name={field.id}>
          <thead>
            <tr>
              {header}
            </tr>
          </thead>
          <tbody>
            {body}
          </tbody>
        </table>
        {pag}
      </div>
    );
  }

}

export {Table};
