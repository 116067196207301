import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import ReactDOM from "../../../miniclient/in/client/node_modules/react-dom";

const Checkbox = createReactClass({
 componentDidMount: function() {
   let checkbox = this;
   let value = checkbox.props.value;
   let title = checkbox.props.title;
   let input = document.createElement('input');
   input.setAttribute('type', 'checkbox');
   input.setAttribute('class', 'hidden');
   if (checkbox.props.readOnly) {
     input.setAttribute('readonly','readonly');
   }
   let label = document.createElement('label');
   label.textContent = title;
   let divCheckbox = document.createElement('div');
   divCheckbox.setAttribute('class','ui checkbox');
   divCheckbox.appendChild(input);
   divCheckbox.appendChild(label);
   let node = ReactDOM.findDOMNode(checkbox);
   node.appendChild(divCheckbox);
   if (value) {
     $(divCheckbox).checkbox('check');
   }

   $(divCheckbox).checkbox({
     onChecked: function() {
       if (!checkbox.withoutChanges) {
         checkbox.props.onValueChange(true);
       }
     },
     onUnchecked: function() {
        if (!checkbox.withoutChanges) {
          checkbox.props.onValueChange(false);
        }
      }
   });
  },
  componentWillReceiveProps: function(nextProps) {
    let checkbox = this;
    let nextValue = nextProps.value;
    if (checkbox.value != nextValue) {
      let node = ReactDOM.findDOMNode(checkbox);
      const $checkbox = $(node).find(".ui.checkbox");
      checkbox.value = nextValue;
      checkbox.withoutChanges = true;
      if (nextValue) {
        $checkbox.checkbox('check');
      } else {
        $checkbox.checkbox('uncheck');
      }
      checkbox.withoutChanges = false;
    }
  },
  render: function() {
    return <div/>
    }
})

export {Checkbox};
