class JdateFormat {

  static javaFormatMapping = {
    d: 'D',
    dd: 'DD',
    y: 'YYYY',
    yy: 'YY',
    yyy: 'YYYY',
    yyyy: 'YYYY',
    a: 'a',
    A: 'A',
    M: 'M',
    MM: 'MM',
    MMM: 'MMM',
    MMMM: 'MMMM',
    h: 'h',
    hh: 'hh',
    H: 'H',
    HH: 'HH',
    m: 'm',
    mm: 'mm',
    s: 's',
    ss: 'ss',
    S: 'SSS',
    SS: 'SSS',
    SSS: 'SSS',
    E: 'ddd',
    EE: 'ddd',
    EEE: 'ddd',
    EEEE: 'dddd',
    EEEEE: 'dddd',
    EEEEEE: 'dddd',
    D: 'DDD',
    w: 'W',
    ww: 'WW',
    z: 'ZZ',
    zzzz: 'Z',
    Z: 'ZZ',
    X: 'ZZ',
    XX: 'ZZ',
    XXX: 'Z',
    u: 'E'
  }
  static javaFormatMappingLuxon = {
    D: 'o',
    w: 'W',
    ww: 'WW',
    z: 'ZZ',
    zzzz: 'Z',
    Z: 'ZZ',
    X: 'ZZ',
    XX: 'ZZ',
    XXX: 'ZZ',
    u: 'E'
  }

  static toMomentFormatString(formatString) {
    return JdateFormat.translateFormat(formatString, JdateFormat.javaFormatMapping)
  }

  static toLuxonFormatString(formatString) {
    return JdateFormat.translateFormat(formatString, JdateFormat.javaFormatMappingLuxon)
  }

  static translateFormat(formatString, mapping) {
    let len = formatString.length
    let i = 0
    let beginIndex = -1
    let lastChar = null
    let currentChar = ""
    let resultString = ""
    let literalMode = false
    for (; i < len; i++) {
      currentChar = formatString.charAt(i)
      if (literalMode) {
        resultString += lastChar
        beginIndex = i
      } else {
        if (lastChar === null || lastChar !== currentChar) {
          // change detected
          resultString = JdateFormat.appendMappedString(formatString, mapping, beginIndex, i, resultString)
          beginIndex = i
        }
      }
      lastChar = currentChar
      if (currentChar == "'") {
        literalMode = !literalMode
      }
    }
    return JdateFormat.appendMappedString(formatString, mapping, beginIndex, i, resultString)
  }

  static appendMappedString(formatString, mapping, beginIndex, currentIndex, resultString) {
    let tempString
    if (beginIndex !== -1) {
      tempString = formatString.substring(beginIndex, currentIndex)
      // check if the temporary string has a known mapping
      if (mapping[tempString]) {
        tempString = mapping[tempString]
      }
      resultString = resultString.concat(tempString)
    }
    return resultString
  }

}

export {JdateFormat}