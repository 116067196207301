import React from "react";
import math from 'mathjs';
import ReactRangeSlider from 'react-rangeslider';

import {MC} from './MC.js';

import "./Slider.css";

class Slider extends React.Component {

  componentWillMount() {
    this.initValue(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.initValue(nextProps);
  }

  initValue(props) {
    var value = MC.getFieldParamValue(props.data.param, 'value', props.iteration);
    if (MC.isPlainObject(value)) {
      value = MC.getFieldParamValue(props.data.param, 'value/@value', props.iteration);
    }
    if (!MC.isModelerActive(props.data)) {
      if (MC.isNumeric(value)) {
        var min = new Number(MC.getFieldParamValue(this.props.data.param, '@min', this.props.iteration)).valueOf();
        if (MC.isNumeric(min) && value < min) {
          MC.error('Value of slider (' + value + ') can not be less than minimum (' + min + ')!');
          return;
        }
        var max = new Number(MC.getFieldParamValue(this.props.data.param, '@max', this.props.iteration)).valueOf();
        if (MC.isNumeric(max) && value > max) {
          MC.error('Value of slider (' + value + ') can not be greater than maximum (' + max + ')!');
          return;
        }
      } else {
        MC.error('Initial value of slider must be defined!');
      }
    }
    this.setState({value: value});
  }

  handleChange = (value) => {
    this.putValue(value);
  };

  updateTextChange = (e) => {
    var value = e.target.value;
    if (!MC.isNull(value)) {
      value = value.replace(/(?!-)[^0-9., ]/g, "");
      value = value.replace(/\s+/g, '');
      value = value.replace(",", ".");
      value = new Number(value).valueOf();
      if (["integer", "int", "long", "short"].indexOf(this.props.data.basictype) > -1) {
        value = Math.round(value);
      }
      var min = new Number(MC.getFieldParamValue(this.props.data.param, '@min', this.props.iteration)).valueOf();
      if (MC.isNumeric(min) && value < min) {
        value = min;
      }
      var max = new Number(MC.getFieldParamValue(this.props.data.param, '@max', this.props.iteration)).valueOf();
      if (MC.isNumeric(max) && value > max) {
        value = max;
      }
    } else {
      value = null;
    }
    this.setState({focused: false});
    this.putValue(value);
  };

  handleTextChange = (e) => {
    this.setState({value: e.target.value});
  };

  doStep(up) {
    var step = MC.getFieldParamValue(this.props.data.param, '@step', this.props.iteration);
    var value = this.state.value;
    if (!MC.isNumeric(value)) {
      value = 0;
    }
    if (!MC.isNumeric(step)) {
      step = 1;
    }
    if (up) {
      value = math.add(math.bignumber(value), math.bignumber(step));
      var max = MC.getFieldParamValue(this.props.data.param, '@max', this.props.iteration);
      if (MC.isNumeric(max) && math.larger(value, math.bignumber(max))) {
        value = math.bignumber(max);
      }
    } else {
      value = math.subtract(math.bignumber(value), math.bignumber(step));
      var min = MC.getFieldParamValue(this.props.data.param, '@min', this.props.iteration);
      if (MC.isNumeric(min) && math.smaller(value, math.bignumber(min))) {
        value = math.bignumber(min);
      }
    }
    this.putValue(MC.getNumberAsString(value));
  }

  putValue(newValue) {
    var formatter = MC.getFieldParamValue(this.props.data.param, '@formatType', this.props.iteration);
    if (formatter == 'message') {
      MC.putFieldParamValue(this.props.data.param, 'value/@value', this.props.iteration, newValue);
    } else {
      MC.putFieldParamValue(this.props.data.param, 'value', this.props.iteration, newValue);
    }
    this.setState({value: newValue});
    MC.handleEvent(this.props.data, this.props.iteration, 'change');
  }

  focusValueInput = () => {
    this.setState({focused: true});
  };

  render() {
    var min = new Number(MC.getFieldParamValue(this.props.data.param, '@min', this.props.iteration)).valueOf();
    var max = new Number(MC.getFieldParamValue(this.props.data.param, '@max', this.props.iteration)).valueOf();
    var step = MC.getFieldParamValue(this.props.data.param, '@step', this.props.iteration);
    if (MC.isNumeric(step)) {
      step = new Number(step).valueOf();
    } else {
      step = 1;
    }
    var valueStyle = MC.getFieldParamValue(this.props.data.param, '@valueStyle', this.props.iteration);
    var valuePosition = MC.getFieldParamValue(this.props.data.param, '@valuePosition', this.props.iteration);
    if (['left', 'right', 'top', 'bottom'].indexOf(valuePosition) < 0) {
      valuePosition = 'right';
    }
    var asStepper = MC.getFieldParamBooleanValue(this.props.data.param, '@showAsStepper', this.props.iteration);
    var valueLabel = null;
    var growValueCss = "";
    if (asStepper && ['left', 'right'].indexOf(valuePosition) > -1) {
      growValueCss = " flex-grow";
    }
    if (['readonly', 'textbox'].indexOf(valueStyle) > -1) {
      var value = this.state.value;
      var formatter = MC.getFieldParamValue(this.props.data.param, '@formatType', this.props.iteration);
      if (!MC.isNull(formatter) && !this.state.focused) {
        value = MC.formatValue(value, formatter, this.props.data.basictype, MC.getFieldParamValue(this.props.data.param, '@formatPattern', this.props.iteration), this.props.data, this.props.iteration);
      }
      if (valueStyle === 'readonly') {
        var cls = "value " + valuePosition + growValueCss;
        valueLabel = <div className={cls}>{value}</div>;
      } else if (valueStyle === 'textbox') {
        var cls = "valueInput " + valuePosition + growValueCss;
        if (this.state.focused) {
          cls += " focused";
        }
        valueLabel = <div className={cls}><input type="text" ref="valueInput" value={value} onBlur={this.updateTextChange} onChange={this.handleTextChange} onFocus={this.focusValueInput} key="valueInput"/></div>;
      }
    }
    var slider;
    if (asStepper) {
      var plusCss = "right attached ui button";
      var minusCss = "ui left attached button";
      if (this.state.value >= max) {
        plusCss += " disabled"
      }
      if (this.state.value <= min) {
        minusCss += " disabled"
      }
      slider = (<div className="stepper-buttons">
                  <button className={minusCss} type="button" onClick={this.doStep.bind(this, false)}>-</button>
                  <button className={plusCss} type="button" onClick={this.doStep.bind(this, true)}>+</button>
                </div>);
    } else {
      var leftText = MC.getFieldParamValue(this.props.data.param, '@leftText', this.props.iteration);
      var rightText = MC.getFieldParamValue(this.props.data.param, '@rightText', this.props.iteration);
      var texts = {};
      if (!MC.isNull(leftText)) {
        texts[min] = leftText;
      }
      if (!MC.isNull(rightText)) {
        texts[max] = rightText;
      }
      var sliderCls = null;
      if (['left', 'right'].indexOf(valuePosition) > -1) {
        sliderCls = "flex-grow";
      }
      var tooltip = MC.getFieldParamBooleanValue(this.props.data.param, '@valueTooltip', this.props.iteration);
      let value = new Number(this.state.value).valueOf();
      slider = <div className={sliderCls}><ReactRangeSlider onChange={this.handleChange} min={min} max={max} step={step} tooltip={tooltip} value={value} labels={texts}/></div>;
    }
    var conatinerCls = "slider";
    if (['left', 'right'].indexOf(valuePosition) > -1) {
      conatinerCls += " flex-row flex-no-wrap";
    }
    if (['left', 'top'].indexOf(valuePosition) > -1) {
      return (
        <div className={conatinerCls} data-widget-i-name={this.props.data.id}>
          {valueLabel}
          {slider}
        </div>
      )
    } else {
      return (
        <div className={conatinerCls} data-widget-i-name={this.props.data.id}>
          {slider}
          {valueLabel}
        </div>
      )
    }
  }

}

export {Slider};
