import {Library} from "../../../../app/src/library.js";
import {Meta} from "../../../../app/src/meta.js";
import {CHub} from "./library/c-hub.js";
import {Appmodeler} from "./appmodeler.js";

const AssemblyGenerator = {};
const yStep = 100;
AssemblyGenerator.generateStart = (context, node) => {
  return [{
    'rbs:id': 'start',
    'svc:code': 'start',
    'svc:kind': 'start',
    'svc:nextaction': node['rbs:id'],
    'svc:posx': String(context.x + 10),
    'svc:posy': String(context.y)
  }]
}

AssemblyGenerator.generateEnd = (context) => {
  return [{
    'rbs:id': 'end',
    'svc:code': 'end',
    'svc:kind': 'end',
    'svc:posx': String(context.x + 10),
    'svc:posy': String(context.y)
  }]
}

AssemblyGenerator.findNode = (nodes, nodeId) => {
  return nodes.find(node => node['rbs:id'] === nodeId);
}

AssemblyGenerator.nextNode = (context, node) => {
  const link = node['chub:nextNode']
  if (link) {
    const reference  = link['meta3:target'];
    if (reference) {
      const targetRi = Library.referenceResolve(reference, context.basePath);
      const id = Library.riFragment(targetRi);
      return AssemblyGenerator.findNode(context.nodes, id);
    }
  }
}

AssemblyGenerator.generateAction = (context, node) => {
  return {
    'rbs:id': node['rbs:id'],
    'svc:code': node['chub:flowCall'] + String(context.callNumber),
    'svc:extopcalled': node['chub:flowOperation'],
    'svc:kind': 'call',
    'svc:posx': String(context.x),
    'svc:posy': String(context.y)
  }
}

AssemblyGenerator.generateActions = (context, node) => {
  const conceptPath = Library.referenceResolve(node['meta3:instanceOf'], context.basePath);
  const conceptName = Library.pathName(conceptPath);
  if (conceptName === 'file-input') {
    return AssemblyGenerator.generateFileInput(context, node);
  } else if (conceptName === 'flow') {
    return AssemblyGenerator.generateFlow(context, node);
  } else if (conceptName === 'db-input') {
    return AssemblyGenerator.generateDatabaseInput(context, node);
  } else if (conceptName === 'no-output') {
    return AssemblyGenerator.generateNoOutput(context, node);
  } else {
    throw new Error('Node of type \'' + conceptName + '\' is not supported yet.');
  }
}

AssemblyGenerator.standardActionsGenerate = (context, node) => {
  const nextNode = AssemblyGenerator.nextNode(context, node);
  let nextActions = [];
  let nextActionId;
  if (nextNode) {
    const nextContext = {...context,
      callNumber: context.callNumber + 1,
      y: context.y + yStep
    }
    nextActions = AssemblyGenerator.generateActions(nextContext, nextNode);
    nextActionId = nextNode['rbs:id'];
  } else {
    const nextContext = {...context,
      y: context.y + yStep
    }
    nextActions = AssemblyGenerator.generateEnd(nextContext);
    nextActionId = 'end';
  }
  return { nextActionId, nextActions };
}

AssemblyGenerator.generateFileInput = (context, node) => {
  const batchIdPath = 'FileInputCall' + context.callNumber + '/surveyId*';
  const nextContext = {...context,
    y: context.y + yStep,
    batchIdPath: batchIdPath
  }
  const { nextActionId, nextActions } = AssemblyGenerator.standardActionsGenerate(nextContext, node);
  const action = {...AssemblyGenerator.generateAction(context, node),
    'svc:nextaction': 'fileInputDecision',
    'svc:OperationActionMapping': [
      Appmodeler.simpleMapping('communicationId', 'start/communicationId'),
      Appmodeler.simpleMapping('status', 'start/status')
    ]
  }
  return [
    action,
    {...Appmodeler.decisionAction(),
      'rbs:id': 'fileInputDecision',
      'svc:code': 'decision',
      'svc:posx': String(context.x + 10),
      'svc:posy': String(context.y + yStep),
      'svc:OperationActionBranch': [
        {...Appmodeler.operationActionBranch(),
          'svc:name': 'hasBatch',
          'svc:position': '100',
          'svc:nextaction': nextActionId,
          'svc:OperationActionBranchCondition': [{
            'rbs:id': Library.guid(),
            'svc:operator': 'hasData',
            'svc:OperationActionBranchCondition': [{
              'rbs:id': Library.guid(),
              'svc:param1': batchIdPath
            }]
          }]
        },
        {...Appmodeler.operationActionBranch(),
          'svc:name': 'doesNotHaveBatch',
          'svc:position': '200',
          'svc:nextaction': 'withoutBatchEnd',
        }
      ]
    },
    {...Appmodeler.endOperationAction(),
      'svc:posx': String(context.x + 200),
      'svc:posy': String(context.y + yStep),
      'rbs:id': 'withoutBatchEnd',
      'svc:code': 'withoutBatchEnd'
    },
    ...nextActions
  ]
}

AssemblyGenerator.generateDatabaseInput = (context, node) => {
  const batchIdPath = 'DatabaseInputCall' + context.callNumber + '/surveyId*';
  const nextContext = {...context,
    y: context.y + yStep,
    batchIdPath: batchIdPath
  }
  const { nextActionId, nextActions } = AssemblyGenerator.standardActionsGenerate(nextContext, node);
  const action = {...AssemblyGenerator.generateAction(context, node),
    'svc:nextaction': 'databaseInputDecision',
    'svc:OperationActionMapping': [
      Appmodeler.simpleMapping('communicationId', 'start/communicationId'),
      Appmodeler.simpleMapping('status', 'start/status')
    ]
  }
  return [
    action,
    {...Appmodeler.decisionAction(),
      'rbs:id': 'databaseInputDecision',
      'svc:code': 'decision',
      'svc:posx': String(context.x + 10),
      'svc:posy': String(context.y + yStep),
      'svc:OperationActionBranch': [
        {...Appmodeler.operationActionBranch(),
          'svc:name': 'hasBatch',
          'svc:position': '100',
          'svc:nextaction': nextActionId,
          'svc:OperationActionBranchCondition': [{
            'rbs:id': Library.guid(),
            'svc:operator': 'hasData',
            'svc:OperationActionBranchCondition': [{
              'rbs:id': Library.guid(),
              'svc:param1': batchIdPath
            }]
          }]
        },
        {...Appmodeler.operationActionBranch(),
          'svc:name': 'doesNotHaveBatch',
          'svc:position': '200',
          'svc:nextaction': 'withoutBatchEnd',
        }
      ]
    },
    {...Appmodeler.endOperationAction(),
      'svc:posx': String(context.x + 200),
      'svc:posy': String(context.y + yStep),
      'rbs:id': 'withoutBatchEnd',
      'svc:code': 'withoutBatchEnd'
    },
    ...nextActions
  ]
}

AssemblyGenerator.generateNoOutput = (context, node) => {
  const { nextActionId, nextActions } = AssemblyGenerator.standardActionsGenerate(context, node);
  return [{...AssemblyGenerator.generateAction(context, node),
    'svc:OperationActionMapping': [
      Appmodeler.simpleMapping('batchId*', context.batchIdPath)
    ],
    'svc:nextaction': nextActionId
  }, ...nextActions]
}

AssemblyGenerator.generateFlow = (context, node) => {
  const flowReference = node['dsl:flow'];
  const flowPath = Library.referenceResolve(flowReference, context.basePath);
  const flowId = Library.pathName(flowPath);
  const { nextActionId, nextActions } = AssemblyGenerator.standardActionsGenerate(context, node);
  const action = {...AssemblyGenerator.generateAction(context, node),
    'svc:OperationActionMapping': [
      Appmodeler.simpleMapping('surveyId*', context.batchIdPath),
      Appmodeler.constantMapping('flowOperationName', CHub.flowName(flowId))
    ],
    'svc:nextaction': nextActionId
  }
  return [action, ...nextActions]
}

AssemblyGenerator.generateOperation = (context, startNode) => {
  const statActions = AssemblyGenerator.generateStart(context, startNode);
  const nextContext = {...context, y: context.y + yStep};
  const actions = [...statActions, ...AssemblyGenerator.generateActions(nextContext, startNode)];
  const id = context.assembly['rbs:id'];
  const name = CHub.assemblyName(id);
  context.storage.getFile('commhub/concept/template/assembly-operation').then(assemblyOperation => {
    const operation = {...assemblyOperation['meta3:properties'],
      'svc:name': name,
      'rbs:id': id,
      'svc:OperationAction': actions
    }
    const path = context.modelPath + 'gop/Operation/' + id;
    Library.storeProperties(path, operation);
  })
}

AssemblyGenerator.generate = (storage, basePath, assembly, modelPath) => {
  const nodes = Library.ensureArray(assembly['chub:node']);
  const conceptsPath = 'commhub/concept/assembly-node/';
  return Promise.all(nodes.map(node => {
    return Meta.isInstanceOf(basePath, node, conceptsPath + 'start');
  })).then(isStartNode => {
    const startNode = nodes.find((element, index) => isStartNode[index]);
    if (!startNode) {
      throw new Error('There is no start node.')
    }
    const context = {
      storage,
      modelPath: modelPath,
      basePath: basePath,
      assembly: assembly,
      isFirstNode: true,
      callNumber: 1,
      conceptsPath: conceptsPath,
      nodes: nodes,
      x: 100,
      y: 100
    }
    AssemblyGenerator.generateOperation(context, startNode);
  })
}

export {AssemblyGenerator};
