import React from "../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "./library.js";
import {ApplicationUtils} from './application-utils.js';

const ApplicationComponent = createReactClass({
  load: function(context, component) {
    const conceptReference = component['meta3:instanceOf'];
    const conceptPath = Library.resolveReference(context.layout['meta3:path'], conceptReference);
    const applicationComponent = ApplicationUtils.components[conceptPath];
    const componentContext = {...context, component};
    return applicationComponent.prototype.load(componentContext).then(props => {
      return {...props,
        conceptPath: conceptPath,
        id: component['rbs:id']
      }
    })
  },
  render: function() {
    const component = this;
    const props = component.props;
    const conceptPath = props.conceptPath;
    const applicationComponent = ApplicationUtils.components[conceptPath];
    const element = React.createElement(applicationComponent, {...props, onUpdate: props.onUpdate});
    return element;
  }
});

export {ApplicationComponent};
