import {Library} from "../../../../app/src/library.js"
import {uuid, ensureArray} from "../../../../app/src/lib.js"
import {ReactFlow} from '../../../../miniclient/in/client/src/client/ReactFlow.jsx'

export const validateFlow = async (file, debug, configuration) => {  
  const result = await ReactFlow.prototype.load(configuration, 'COM_FlowValidate', {file}, {debug})
  const newValidations = ensureArray(result.output.validation).map(validation => {
    return {
      'chub:nodeId': validation.nodeId,
      'chub:property': validation.property,
      'chub:message': validation.message,
      'rbs:id': uuid()
    }
  })
  const oldValidations = ensureArray(file['meta3:properties']['chub:validation']).map(validation => {
    return {'rbs:id': validation['rbs:id']}
  })
  const update = {
    'chub:validation': newValidations.concat(oldValidations)
  }

  const updated =  Library.complexUpdate(file['meta3:properties'], update)
  const resultFile =  {...file, 'meta3:properties': updated[0]}
  return resultFile
}