import {InspectorComponent} from "./package.js";
import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";

const SimpleTextInspector = createReactClass({
  getInitialState: function() {
    return {value: ''};
  },
  handleChangeValue: function(event) {
    const component = this;
    const value = event.target.value;
    component.setState({value: value});
    if (component.changeTimeout) {
      clearTimeout(component.changeTimeout);
    }
    component.changeTimeout = setTimeout(() => {
      let updatedValue = value;
      if (!component.props.possibility && value === '') {
        updatedValue = '~';
      }
      component.props.onValueUpdate(updatedValue);
      component.changeTimeout = undefined;
    }, 1000);
  },
  componentWillMount: function() {
    const component = this;
    component.setState({value: component.props.value});
  },
  componentWillReceiveProps: function(nextProps) {
    const component = this;
    const props = component.props;
    if (!component.changeTimeout && props.value !== nextProps.value) {
      component.setState({value: nextProps.value});
    }
  },
  load: function(context, property, value, possibility) {
    if (possibility) {
      return {value, possibility}
    }
    if (value == '~') {
      return {value: ''}
    } else {
      return {
        value: value
      }
    }
  },
  render: function() {
    const component = this;
    const placeholder = component.props.placeholder;
    const value = component.state.value;
    return (<div className="ui input">
              <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={component.handleChangeValue}
                readOnly={component.props.readOnly}/>
            </div>);
    }
})
export {SimpleTextInspector};
