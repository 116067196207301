import joint from './rappid/rappid.js'
joint.ui.Dialog = joint.ui.Modal.extend({
  className: 'cell-dialog',

  eventNamespace: 'cell-dialog',

  events: {'click form button[name="OK"]': 'onOK',
            'click form button[name="cancel"]': 'onCancel'
  },
  template: _.template('<div class="field"><label><%= label %></label><input id="mainInput" type="text" id="dialogInput" name="name" value="<%= value %>"></div>' +
      '<button name="OK" class="ui primary button">OK</button> <button name="cancel" class="ui button"><%= cancel %></button>'),
  options: _.create(joint.ui.Modal.prototype.options, {label: 'Name'}),
  start: function() {

  },
  renderContent: function() {
    //var self = this;
    var $html = $('<div/>', {'class': 'ui segment'})
    var $form = $('<form class="ui form" id="dialogform"/>')
    $html.append($form)
    var temp = this.template({
        label: this.options.label,
        value: this.getValue(),
        cancel: 'Cancel'
    })
    $form.append(temp)
    this.$el.append($html)
    this.start()
  },
  afterRender: function() {
    this.$el.find("input[name='name']").focus()
  },
  getValue: function() {
    return ''
  },
  doAction: function() {
  },
  onOK: function(evt) {
    evt.preventDefault()
    this.doAction()
    this.remove()
  },
  onCancel: function(evt) {
    evt.preventDefault()
    this.cancel()
  }
})

const ChooseDialog = joint.ui.Dialog.extend({
  template: _.template('<div class="field"><label><%= label %></label>' +
      '  <div class="ui search selection dropdown instanceDropdown">' +
      '    <input type="hidden" class="instance"/>' +
      '    <i class="dropdown icon"></i>' +
      '    <div class="default text"><%= value %></div>' +
      '    <div class="menu instanceMenu"></div>' +
      '  </div></div>' +
      '<button class="ui primary button" name="OK">OK</button> <button name="cancel" class="ui button"><%= cancel%></button>'),
  start: function() {
    this.fillDropdown($('#dialogform .instanceMenu'))
  },
  getSelectedValue: function() {
    const id = $('#dialogform .instance').val()
    const item = this.items.find(item => item.id === id)
    return item.value
  },
  doAction: function() {
    const value = this.getSelectedValue()
    this.options.onChoose(value)
  },
  fillDropdown: async function(instancesSelect) {
    instancesSelect.html('')
    instancesSelect.append('<div class="item" data-value=""></div>')
    const items = await this.options.onGetItems()
    this.items = items
    items.forEach(item => {
      const option = $('<div class="item"></div>')
      option.html(item.title)
      option.attr('data-value', item.id)
      instancesSelect.append(option)          
    })
    $('#dialogform .ui.dropdown').dropdown({
       // fullTextSearch: 'exact',
        match: 'text',
       // showOnFocus: false,
        //forceSelection: false,
       // allowAdditions: false,
       // hideAdditions: false,
        message: 'Choose'
    })
    $('#dialogform input.search').focus()
  }
})


const ActionDialogArray = {
    choose: ChooseDialog
}

export const OpenDialog = options => {
    const dialog = new ActionDialogArray[options.action](options)
    dialog.render()
}