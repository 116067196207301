import React from "../../miniclient/in/client/node_modules/react";

class ErrorPage extends React.Component {
  render() {
    const props = this.props;
    let errorMessage;
    if (props.debug) {
      errorMessage = <p>{props.error}</p>;
    }
    return (
      <div className="ui container" style={{marginTop: '20px'}}>
        <div className="ui icon negative red large message own-error">
          <i className="bug icon"></i>
          <div className="content">
            <div className="header">
              Error occured!
            </div>
            {errorMessage}
          </div>
        </div>
      </div>
    );
  }
}

export {ErrorPage}
