import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import {Dropdown} from '../widget/dropdown.jsx';

const SimpleEnumerationInspector = createReactClass({
  load: function(context, property, value, enumeratedValues) {
    return {
      value: value,
      enumeratedValues: enumeratedValues
    }
  },
  render: function() {
    const component = this;
    const items = component.props.enumeratedValues.map(function(value) {
      return {value: value, name: value}
    });
    const value = component.props.value;
    return <Dropdown items={items} value={value} onSetValue={component.props.onValueUpdate}/>
  }
})

export {SimpleEnumerationInspector};
