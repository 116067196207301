import React from "../../miniclient/in/client/node_modules/react";
import {Library} from "./library.js";
import createReactClass from 'create-react-class';

const Breadcrumbs = createReactClass({
  load: function(context) {
    return {values: context.breadcrumbs};
  },
  render: function() {
    const component = this;
    const props = component.props;
    const values = props.values;
    const navigationParts = values.reduce(function(accumulator, value, index) {
      const name = value.name;
      const applicationPath = value.path;
      const result = []
      if (index != 0) {
        let divider = <div key={index + "_div"} className="divider"> / </div>;
        result.push(divider);
      }

      let section;
      if (index === values.length - 1) {
        section = <div key={index} className="section active">{name}</div>
      } else {
        section = (
          <a key={index}
             className="section"
             onClick={() => props.onUpdate({type: 'routeTo', ri: applicationPath})}>{name}</a>
        )
      }
      result.push(section);
      return accumulator.concat(result);
    }, []);

    return (
      <div className="meta vertical-center-content">
        <div className="ui breadcrumb">{navigationParts}</div>
      </div>
    )
   }
 })

export {Breadcrumbs};
