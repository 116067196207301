import React from "react";

import {MC} from './MC.js';
import {Modal} from "./Modal.jsx";
import {ReactFlow} from "./ReactFlow.jsx";

class Dialog extends React.Component {

  state = {flowName: null, input: null, start: false};

  componentWillMount() {
    if (this.props.dialog && this.props.dialog.start) {
      var dialog = this.props.dialog;
      dialog.start = false;
      this.startFlow(this.props);
    } else {
      this.setState({start: false});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dialog && nextProps.dialog.start) {
      var dialog = nextProps.dialog;
      dialog.start = false;
      this.startFlow(nextProps);
    } else {
      this.setState({start: false});
    }
  }

  startFlow = (props) => {
    if (MC.isPlainObject(props.dialog) && !MC.isNull(props.dialog.flowName)) {
      this.setState({flowName: props.dialog.flowName, input: props.dialog.input, size: props.dialog.size, start: true});
    } else {
      this.setState({flowName: null, input: null, start: false});
    }
  };

  close = () => {
    if (this.state.flowName) {
      this.setState({flowName: null, input: null});
      this.props.dialog.parentFlow.endDialog();
    }
  };

  onEnd = (output, message) => {
    if (this.state.flowName) {
      this.setState({flowName: null, input: null});
      document.querySelector('body').classList.remove('showing-modal');
      this.props.dialog.parentFlow.endDialog(output, message);
    }
  };

  render() {
    var flowHtml = null;
    if (this.state.flowName) {
      var flow = this.props.dialog.parentFlow;
      flowHtml = <ReactFlow configuration={flow.confPath} flowName={this.state.flowName} env={flow.env} input={this.state.input} start={this.state.start} onEndFunction={this.onEnd}
                            flowTemplate={flow.flowdatatemplate} formTemplate={flow.formdatatemplate} flowServerUrl={flow.flowServerUrl} embedded={true} options={flow.reactFlow.props.options}
                            configurationObject={flow.context.data['env/cfg']} debug={flow.debug}/>;
      return (
        <Modal show={true} onClose={this.close} cssClass={this.state.size}>
          {flowHtml}
        </Modal>
      );
    } else {
      return null;
    }
  }

}

export {Dialog};