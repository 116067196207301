import React from "../../../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "../../../../../app/src/library.js";
import {ContentPlaceholder} from './content-placeholder.jsx';

const Wireframe = createReactClass({
  deletePart: function(part) {
    const component = this;
    const partUdpate = {'rbs:id': part['rbs:id']};
    const templateUpdate = {'app:part': [partUdpate]};
    component.updateTemplate(templateUpdate);
  },
  handleMoveUpPart: function(part) {
    const component = this;
    const template = component.props.template;
    const partPosition = Number(part['meta3:position']);
    const parts = Library.sortComplex(template['app:part']);
    const positions = parts.filter(part => {
      return typeof part['meta3:position'] !== 'undefined';
    }).map(part => {
      return Number(part['meta3:position']);
    });
    const beforePositions = positions.filter(position => {
      return position < partPosition;
    });
    if (beforePositions.length !== 0) {
      const beforePosition = beforePositions.slice(-1)[0];
      const beforePart = parts.find(part => {
        return Number(part['meta3:position']) === beforePosition;
      });
      const partUpdate = {'rbs:id': part['rbs:id'], 'meta3:position': String(beforePosition)};
      const beforePartUpdate = {'rbs:id': beforePart['rbs:id'], 'meta3:position': String(partPosition)};
      const partsUpdates = [partUpdate, beforePartUpdate];
      const templateUpdate = {'app:part': partsUpdates};
      component.updateTemplate(templateUpdate);
    }
  },
  handleMoveDownPart: function(part) {
    const component = this;
    const template = component.props.template;
    const partPosition = Number(part['meta3:position']);
    const parts = Library.sortComplex(template['app:part']);
    const positions = parts.filter(part => {
      return typeof part['meta3:position'] !== 'undefined';
    }).map(part => {
      return Number(part['meta3:position']);
    });
    const nextPositions = positions.filter(position => {
      return partPosition < position;
    });
    if (nextPositions.length !== 0) {
      const nextPosition = nextPositions[0];
      const nextPart = parts.find(part => {
        return Number(part['meta3:position']) === nextPosition;
      })
      const partUpdate = {'rbs:id': part['rbs:id'], 'meta3:position': String(nextPosition)};
      const nextPartUpdate = {'rbs:id': nextPart['rbs:id'], 'meta3:position': String(partPosition)};
      const partsUpdates = [partUpdate, nextPartUpdate];
      const templateUpdate = {'app:part': partsUpdates};
      component.updateTemplate(templateUpdate);
    }
  },
  load: async function(context, file, display) {
    const template = file['meta3:properties'];

    const parts = Library.sortComplex(Library.ensureArray(template['app:part'])).map(part => {
      return ContentPlaceholder.prototype.load(context, part);
    })
    let placeholders;
    if (template['dsl:dataStructure']) {
      const dataStructure = await context.storage.referencedValue(file['meta3:path'], template['dsl:dataStructure'])
      if (dataStructure) {
        placeholders = Library.ensureArray(dataStructure['chub:item']).map(item => item['chub:name'])
      }
    }
    return {
      placeholders,
      parts: parts,
      landingPageUrl: context.properties['app:landingPageUrl'],
      modelPath: context.model['meta3:path'],
      file: file,
      template: template,
      display: context.display,
      showHidden: context.showHidden
    }
  },
  handlePartUpdate: function(partUpdate) {
    const component = this;
    const templateUpdate = {'app:part': [partUpdate]};
    component.updateTemplate(templateUpdate);
  },
  handleSelectPart: function(id) {
    const component = this;
    const props = component.props;
    const templatePath = props.file['meta3:path'];
    props.onUpdate({type: 'routeTo', ri: templatePath + '#' + id});
  },
  updateTemplate: function(templateUpdate) {
    const component = this;
    const props = component.props;
    component.props.onUpdate({type: 'fileUpdate', path: props.file['meta3:path'], fileUpdate: templateUpdate});
  },
  render: function() {
    const component = this;
    const props = component.props;
    const display = props.display;
    const elements = props.parts.filter(part => {
      if (props.showHidden) {
        return true;
      }
      if (!Library.boolean(part.definition['app:displayOnMobile']) && display === 'mobile') {
        return false;
      }
      if (!Library.boolean(part.definition['app:displayOnTablet']) && display === 'tablet') {
        return false;
      }
      if (!Library.boolean(part.definition['app:displayOnDesktop']) && display === 'desktop') {
        return false;
      }
      return true;
    }).map(part => {
      const id = part.id;
      return <ContentPlaceholder
                key={id}
                placeholders={props.placeholders}
                context={component.props.context}
                part={part}
                screenDisplay={display}
                displayToolbar={display !== 'mobile'}
                onUpdate={props.onUpdate}
                onValueUpdate={component.handlePartUpdate}
                onSelectObject={component.handleSelectPart}
                deletePart={component.deletePart}
                onMoveUpPart={component.handleMoveUpPart}
                onMoveDownPart={component.handleMoveDownPart}/>
    });
    return (
      <div className="inner">
        <div className="mobile">
          {elements}
        </div>
      </div>
    )
  }
})

export {Wireframe};
