'use strict';
import {Library} from "../../../../../app/src/library.js";
import {CHub} from "../library/c-hub.js";

const TemplateRender = {};

TemplateRender.renderPart = (part) => {
  const contents = Library.sortComplex(Library.ensureArray(part['app:content']));
  const columns = contents.map(content => {
    const display = content['app:display'];
    let tdProperties = '';
    let className = 'column';
    if (contents.length === 2) {
      className += ' two';
    }
    if (contents.length === 3) {
      className += ' three';
    }
    if (display) {
      className +=  ' ' + display;
    }

    const align = content['app:align'];
    if (align) {
      tdProperties = 'style = "text-align: ' + align + '"';
    }
    return '        <table class="' + className +'" >\n          <tbody>\n            <tr>\n              <td ' + tdProperties + '>\n                ' + content['app:value'] + '\n              </td>\n            </tr>\n          </tbody>\n        </table>'
  })
  const partDisplay = part['app:display'];
  let partAttributes = '';
  let className = '';
  if (!Library.boolean(part['app:displayOnMobile'])) {
    className += ' doNotDisplayOnMobile'
  }
  if (!Library.boolean(part['app:displayOnTablet'])) {
    className += ' doNotDisplayOnTablet'
  }
  if (!Library.boolean(part['app:displayOnDesktop'])) {
    className += ' doNotDisplayOnDesktop'
  }
  if (partDisplay) {
    className += ' ' + partDisplay;
  }
  if (className !== '') {
    partAttributes += ' class="' + className.slice(1)  + '"';
  }
  return '<table' + partAttributes +'>\n  <tbody>\n    <tr>\n      <td>\n' + columns.join('\n') + '\n        <span style="display: block; clear: both"></span>\n      </td>\n    </tr>\n  </tbody>\n</table>'
}


TemplateRender.renderHtml = (storage, path, template) => {
  const parts = Library.sortComplex(Library.ensureArray(template['app:part']));
   const htmls = parts.map(part => {
     return TemplateRender.renderPart(part);
   })
   const body = htmls.join('\n');
   const htmlTemplate = template['app:template'];
   return CHub.templateCss(storage, path, template).then(css => {
     return htmlTemplate.replace('{title}', 'Title').replace('{css}', css).replace('{body}', body);
   })
}

TemplateRender.linksEvents = template => {
  const html = template['app:templateHtml'];
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const arrayOfUrls = Library.removeDuplicates(Array.from(doc.links).map(link => link.getAttribute('href')));
  const currentEvents = Library.ensureArray(template['dsl:templateEvent']);
  const events = arrayOfUrls.map(url => {
    const matchEvent = currentEvents.find(event => event['chub:eventUrl'] === url);
    let eventName;
    if (!matchEvent) {
      eventName = '~';
    } else {
      eventName = matchEvent['chub:eventName'];
    }
    return {'rbs:id': Library.guid(), 'chub:eventName': eventName, 'chub:eventUrl': url};
  });
  return events;
}

TemplateRender.ensureHtml = (storage, path, template) => {
  if (!Library.boolean(template['app:editHtml'])) {
    return TemplateRender.renderHtml(storage, path, template).then(html => {
      return {...template, 'app:templateHtml': html};
    })
  } else {
    return Promise.resolve(template);
  }
}

TemplateRender.templateTransform = (storage, path, template) => {
  return TemplateRender.ensureHtml(storage, path, template).then(template => {
    const events = TemplateRender.linksEvents(template);
    if (events.length !== 0) {
      return {...template, 'dsl:templateEvent': events};
    } else {
      return Library.entriesToObject(Library.objectEntries(template).filter(([property, value]) => property !== 'dsl:templateEvent'))
    }
  })
}

TemplateRender.templateFileTransform = (storage, file) => {
  const path = file['meta3:path'];
  const template = file['meta3:properties'];
  return TemplateRender.templateTransform(storage, path, template).then(template => {
    return {...file, 'meta3:properties': template}
  })
}

export {TemplateRender};
