import React from "react";
import ReactDOM from "react-dom";

import {MC} from './MC.js';

class Button extends React.Component {

  state = {rippleStyle: {}};

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearTimeout(this.timerAfter)
  }

  handleClick = (ev) => {
    var field = this.props.button;
    var options = field.flow.reactFlow.props.options;
    if (options && options.ripple === true) {
      this.ripple(ev);
    }
    var behavior = MC.getFieldParamValue(field.param, '@behavior', this.props.iteration);
    if (behavior == 'url') {
      var url = MC.getFieldParamValue(field.param, '@url', this.props.iteration);
      if (window && !MC.isNull(url) && url !== '') {
        var newWindow =  MC.getFieldParamBooleanValue(field.param, '@newWindow', this.props.iteration);
        if (newWindow) {
          window.open(url);
        } else {
          window.location.href = url;
        }
      }
    } else if (behavior == 'dialog') {
      var dialogAction = MC.getFieldParamValue(field.param, '@dialogAction', this.props.iteration);
      var dRes = field.flow.callLogicAction({dataAction: dialogAction}, field, this.props.iteration);
      if (!MC.isNull(dRes)) {
        field.flow.reactFlow.setState({dialog: {flowName: dRes.flowName, input: dRes.input, actionCode: dialogAction, triggeredByField: field, iteration: this.props.iteration,
          size: dRes.size, start: true, parentFlow: field.flow}});
      }
    } else {
      if (MC.isFunction(this.props.button.onSubmit) && !MC.isModelerActive(this.props.button)) {
        this.props.button.onSubmit(this.props.button, this.props.iteration);
      }
    }
  };

  ripple(ev) {
    var elem = ReactDOM.findDOMNode(this);
    var pos = MC.getElemCoords(elem);
    var rect = elem.getBoundingClientRect();
    var left = ev.pageX - pos.left;
    var top = ev.pageY - pos.top;
    var size = Math.max(rect.width, rect.height);
    this.setState({ rippleStyle: {top: top, left: left, opacity: 1, transform: 'translate(-50%, -50%)', transition: 'initial', width: '35px', height: '35px'} });
    this.timer = setTimeout(() => {
      this.setState({ rippleStyle:  {top: top, left: left, opacity: 0, transform: 'scale(' + size / 9 + ')', transition: 'all 600ms', width: '35px', height: '35px'}});
      this.timerAfter = setTimeout(() => {
        this.setState({ rippleStyle:  {width: '0px', height: '0px'}})
      }, 600);
    });
  }

  render() {
    var field = this.props.button;
    var label = MC.getFieldParamValue(field.param, '@title', this.props.iteration);
    var icon = MC.getFieldParamValue(field.param, '@icon', this.props.iteration);
    if (!MC.isNull(icon) && icon != "") {
      var cls = 'icon ' + icon;
      icon = <i className={cls}></i>
    } else {
      icon = null;
    }
    var value = this.props.value ? this.props.value : label;
    var cls = MC.getFieldParamValue(field.param, '@cssClass', this.props.iteration);
    cls = cls ? 'ui button ripper-wrapper ' + cls : 'ui button ripper-wrapper';
    return (
      <button type="button" id={field.rbsid} className={cls} ref={field.rbsid} value={value} disabled={this.props.disabled} onClick={this.handleClick} data-widget-i-name={field.id}>
        {icon}{label}
        <s style={this.state.rippleStyle} className="ripper"/>
      </button>
    );
  }
}

export {Button};