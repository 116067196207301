import {ValueInspector} from "./value-inspector.jsx";
import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import {Library} from '../library.js';

const TableInspector = createReactClass({
  load: function(context, property, values) {
    const inspectorDefinition = Library.propertyProperty(property, 'app:inspectorDefinition');
    const columnsProperties = inspectorDefinition['meta3:item'].map(item => item['meta3:propertyName']);
    const columns = columnsProperties.map(columnProperty => {
      return {key: columnProperty, title: Library.propertyTitle(columnProperty)}
    })
    const sortedValues = Library.sortComplex(values);
    return Promise.all(sortedValues.map(value => {
      return Promise.all(columnsProperties.map(columnProperty => {
        const columnValue = value[columnProperty];
        const props =  ValueInspector.prototype.load(context, columnProperty, columnValue);
        return Object.assign({}, props, {property: columnProperty});
      })).then(columns => {
        return {valueId: value['rbs:id'], columns: columns}
      })
    })).then(rows => {
      return {
        columns: columns,
        rows: rows,
        isTable: true
      }
    })
  },
  render: function() {
    const component = this;
    const props = component.props;
    const headerColumns = props.columns.map(column => {
      return <th key={column.key}>{column.title}</th>
    })
    const header = <thead><tr>{headerColumns}</tr></thead>;
    const bodyRows = props.rows.map(row => {
      const id = row.valueId;
      const columns = row.columns.map(entry => {
        const property = entry.property;
        const props = Object.assign({}, entry, {
          onValueUpdate: function(value) {
            const update = {'rbs:id': id};
            update[property] = value;
            component.props.onValueUpdate(update);
          }
        })
        const subvalueInspector = React.createElement(ValueInspector, props);
        return <td key={property}>{subvalueInspector}</td>
      })
      return <tr key={id}>{columns}</tr>
    })
    let body = <tbody>{bodyRows}</tbody>;
    return  <table className="ui celled table">{header}{body}</table>;
  }
})

export {TableInspector};
