import {Library} from './library.js'
import {App} from './app.js'

const ApplicationUtils = {}

ApplicationUtils.components = {}

ApplicationUtils.registerComponent = (conceptPath, component) => {
  ApplicationUtils.components[conceptPath] = component
}

ApplicationUtils.setting = {}

ApplicationUtils.setFlowConfig = path => {
  ApplicationUtils.setting.flowConfig = path
}
ApplicationUtils.setUserAndPermissionOperation = operation => {
  ApplicationUtils.setting.userAndPermissionOperation = operation
}

ApplicationUtils.setNoLayoutOperation = operation => {
  ApplicationUtils.setting.noLayoutOperation = operation
}

ApplicationUtils.setTheoryPath = theoryPath => {
  ApplicationUtils.setting.theoryPath = theoryPath
}


ApplicationUtils.cache = {
  appLoad: {},
  userPermissions: {}
}

ApplicationUtils.deleteCache = () => {
  Library.objectClean(ApplicationUtils.cache.appLoad);
  Library.objectClean(ApplicationUtils.cache.userPermissions);
}

const memorize = (cache, func) => {
  return (input) => {
    if (cache[input]) {
      return cache[input]
    }
    const result = func(input)
    cache[input] = result
    return result
  }
}

ApplicationUtils.appLoad = memorize(ApplicationUtils.cache.appLoad, modelPath => {
  return Library.runFlow(modelPath, 'APP_Load', {})
})

ApplicationUtils.loadContextProperties = async (context) => {
  if (context.model) {
    const properties = await ApplicationUtils.appLoad(context.model['meta3:path'])
    return {...context, properties}
  } else {
    return context
  }
}

ApplicationUtils.loadUserPermissions = memorize(ApplicationUtils.cache.userPermissions, async (path) => {
  const result = await Library.runFlow(ApplicationUtils.setting.flowConfig, ApplicationUtils.setting.userAndPermissionOperation, {path})
  return {user: result.user, permissions: Library.ensureArray(result.permission)}
})

ApplicationUtils.loadContextUser = async (context) => {
  let path
  if (context.model) {
    path = context.model['meta3:path']
  } else {
    path = '/'
  }
  if (path === '') {
    path = '/'
  }
  const result = await ApplicationUtils.loadUserPermissions(path)
  return {...context, ...result}
},


ApplicationUtils.loadBreadcrumbs = async (context) => {
  const pathParameters = context.pathParameters || {}
  const values = await Promise.all(Library.ensureArray(context.breadcrumbItems).map(async (item) => {
    const properties = item['meta3:properties']
    let name
    if (properties['app:nameFlow']) {
      name = await Library.runFlow(context.flowConfiguration, properties['app:nameFlow'], context.pathParameters).then(output => output.name)
    } else {
      name = properties['meta3:name'] || ''
      Object.keys(pathParameters).forEach(key => {
        name = name.split('{' + key + '}').join(pathParameters[key])
      })
    }
    return {
        name: name,
        path: item['app:path']
      }
  }))
  return {...context, breadcrumbs: values}
}

ApplicationUtils.loadRi = async (context) => {
  const applicationPath = rbThisRi
  context = {...context, applicationPath, base: rbBaseUri + rbThisRi}
  context = await ApplicationUtils.loadContextUser(context)

  if (typeof context.user === 'undefined') {
    return context
  } else {
    const clientContext = await App.getContext(context.storage, applicationPath, context.ri)
    context = {...context, ...clientContext};
    context.inheritedValue = context.value['meta3:properties']
     let flowConfiguration
     if (context.model) {
       flowConfiguration = context.model['meta3:path']
     } else {
       flowConfiguration = ApplicationUtils.setting.flowConfig
     }
     context = {...context, flowConfiguration}
     context = await ApplicationUtils.loadContextUser(context)
     context = await ApplicationUtils.loadBreadcrumbs(context)
     context = await ApplicationUtils.loadContextProperties(context)
     return context
   }
}

ApplicationUtils.fileUpdate = (path, update) => {
  return Library.makeInheritedUpdate(path, update, true).then(inheritedUpdate => {
    return Library.existFile(path).then(exist => {
      if (exist) {
        return Library.getInheritedFileResource(path).then(file => {
          const properties = file['meta3:properties'] || {'rbs:id': update['rbs:id']}
          const updated = Library.complexUpdate(properties , inheritedUpdate);
          if (updated.length === 1) {
            return Library.fileReplace(path, updated[0]);
          } else {
            return Library.fileReplace(path, {'rbs:id': file['meta3:properties']['rbs:id']});
          }
        })
      } else {
        return Library.fileReplace(path, inheritedUpdate)
      }
    })
  })
}

ApplicationUtils.enritchRi = (appRi, lastRiValue) => {
  if (appRi.startsWith('/')) {
    appRi = appRi.substring(1);
  }
  if (typeof lastRiValue !== 'undefined' && lastRiValue.query['jsi.debug'] === 'true') {
    const newRiValue = Library.parseRi(appRi);
    newRiValue.query['jsi.debug'] = 'true';
    appRi = Library.stringifyRiValue(newRiValue);
  }
  return appRi;
}

ApplicationUtils.logout = async () => {
  ApplicationUtils.deleteCache()
  return await Library.runFlow('in/auth', 'AUTH_Logout', {})
}

ApplicationUtils.addLeaveCheck = storage => {
  window.onbeforeunload = e => {
    if (!storage.filesNeedStore()) {
      return null;
    }
    const dialogText = 'You have unsaved data. Do you want to leave?';
    e.returnValue = dialogText;
    return dialogText;
  };
}

export {ApplicationUtils}
