import React from "../../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "../../../../app/src/library.js";

const SwitchConsoleButtons = createReactClass({
  load: function(context) {
    if (context.properties) {
      const path = context.riValue.path;
      const designPath = context.properties.designPath;
      const runtimePath = context.properties.runtimePath;
      let commhubConsole;
      if (Library.isSubpath(designPath, path)) {
        commhubConsole = 'design';
      }
      if (Library.isSubpath(runtimePath, path)) {
        commhubConsole = 'runtime';
      }
      return {commhubConsole, designPath, runtimePath}
    }
    return {};

  },
  handleConsoleClick: function(event, path) {
    const component = this;
    const props = component.props;
    props.onUpdate({type: 'routeTo', ri: path});
  },
  render: function () {
    const component = this;
    const props = component.props;
    let label;
    let runtime;
    let design;
    if (props.commhubConsole === 'runtime') {
      design = <div onClick={(event) => component.handleConsoleClick(event, props.designPath)} className="pasive button"><img src="icons/design-pasive.svg"/><span>Design</span></div>;
      runtime = <div onClick={(event) => component.handleConsoleClick(event, props.runtimePath)} className="active button"><img src="icons/runtime-active.svg"/><span>Runtime</span></div>;
    } else if (props.commhubConsole === 'design') {
      design = <div onClick={(event) => component.handleConsoleClick(event, props.designPath)} className="active button"><img src="icons/design-active.svg"/><span>Design</span></div>;
      runtime = <div onClick={(event) => component.handleConsoleClick(event, props.runtimePath)} className="pasive button"><img src="icons/runtime-pasive.svg"/><span>Runtime</span></div>;
    }
    return (<div className="meta row console-buttons">{design}{runtime}</div>);
  }
});

export {SwitchConsoleButtons};
