import React from "react";
import ReactDOM from "react-dom";

class EditableLabel extends React.Component {

  state = {isActive: false};

  render() {
    if (this.state.isActive) {
      var cls = "ui input";
      var style = {textAlign: "left"};
      return <div className={cls} style={style}>
                 <input type="text"
                        value = {this.state.value}
                        onKeyPress = {this.handleKeyPress}
                        onChange = {this.handleChange}
                        onBlur = {this.handleBlur}/>
               </div>;
    } else {
      return <span onDoubleClick={this.handleDoubleClick}>{this.props.widget}</span>;
    }
  }

  componentDidUpdate() {
    if (this.state.isActive) {
      var $node = $(ReactDOM.findDOMNode(this));
      $node.find("input").focus();
      var modelerReact = this.props.field.getModelerReact();
      modelerReact.isDragPossible = false;
    }
  }

  deactivate() {
    var field = this.props.field;
    var value = this.state.value;
    var path = this.props.path;
    var modelerReact = field.getModelerReact();
    var lastId = field.id;
    var form = modelerReact.state.form.getCopy();

    try {
      if (typeof value === "undefined") {
        value = "";
      }
      field.setOption(path, value);

      if (path.length === 1 && path[0] === "id") {
          if (lastId === modelerReact.state.selectedField) {
            modelerReact.setState({selectedField: field.id});
          }
      }
    } catch (e) {
      //this.setState({valid: false});
    }
    this.setState({isActive: false});

    modelerReact.isDragPossible = true;
    modelerReact.formWasChanged();
    modelerReact.store(form);
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.deactivate();
    }
    event.stopPropagation();
  };

  handleChange = (event) => {
    this.setState({value: event.target.value});
  };

  handleDoubleClick = () => {
    var value = this.props.field.getOption(this.props.path);
    this.setState({isActive: true, value: value});
  };

  handleBlur = () => {
    this.deactivate();
  };

}

export {EditableLabel};
