import React from "../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "../library.js";
import ReactDOM from "../../../miniclient/in/client/node_modules/react-dom";

const DropdownButton = createReactClass({
  componentDidMount: function() {
    let dropdown = this;
    let text = dropdown.props.text;
    let items = dropdown.props.items;
    let className = dropdown.props.className;

    let textElement = document.createElement('span');
    textElement.setAttribute('class', 'text');
    textElement.textContent = text;

    let icon = document.createElement('i');
    icon.setAttribute('class', 'dropdown icon');

    let menu = document.createElement('div');
    menu.setAttribute('class', 'menu');
    items.forEach(function(item) {
      let itemElement = document.createElement("div");
      itemElement.setAttribute("class", "item");
      itemElement.setAttribute("data-value", item.value);
      itemElement.textContent = item.name;
      menu.appendChild(itemElement);
    });


    let dropdownElement = document.createElement("div");
    dropdownElement.setAttribute('class', 'ui labeled dropdown icon button' + (className ? " " + className : ""));
    dropdownElement.appendChild(icon);
    dropdownElement.appendChild(textElement);
    dropdownElement.appendChild(menu);

    let node = ReactDOM.findDOMNode(dropdown);
    node.appendChild(dropdownElement);
    $(dropdownElement).dropdown(
      {action: "select",
       onChange: function(value) {
        dropdown.props.onSelect(value);
      }
    });
  },
  render: function() {
    return <div/>
  }
})

export {DropdownButton};
