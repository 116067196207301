import React from "../../../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "../../../../../app/src/library.js";
import {Renderer} from "./renderer.jsx";

const ContentPlaceholder = createReactClass({
  getInitialState: function() {
    return {loaded: false};
  },
  deletePart: function() {
    var placeholder = this;
    var part = placeholder.props.part;
    placeholder.props.deletePart(part.definition);
  },
  moveUp: function() {
    var placeholder = this;
    var part = placeholder.props.part;
    placeholder.props.onMoveUpPart(part.definition);
  },
  moveDown: function() {
    var placeholder = this;
    let part = placeholder.props.part;
    placeholder.props.onMoveDownPart(part.definition);
  },
  displayOptions: function() {
    const component = this;
    const props = component.props;
    component.props.onSelectObject(props.part.id);

  },
  load: function(context, part) {
    const deletable = !(part['app:deletable'] === 'false');
    const specialPosition = part['meta3:specialPosition'];
    //const renderer = part['app:render'];
    return {
      id: part['rbs:id'],
      definition: part,
      renderer: Renderer.prototype.load(context, part),
      deletable: deletable,
      specialPosition: specialPosition
    }
  },
  render: function() {
    const component = this;
    const props = component.props;
    const part = props.part;
    const deletable = part.deletable;
    const moveUpAndDown = !part.specialPosition;
    let toolbar;
    if (props.displayToolbar) {
      const toolbarItems = [];
      if (deletable) {
        toolbarItems.push(
            <i
              key="delete"
              className="remove link icon"
              onClick={component.deletePart}>
            </i>
          )
      }
      if (moveUpAndDown) {
        toolbarItems.push(
          <i
            key="up"
            className="angle up link icon"
            onClick={component.moveUp}>
          </i>
        )
        toolbarItems.push(
          <i
            key="down"
            className="angle down link icon"
            onClick={component.moveDown}>
          </i>
        )
      }
      toolbarItems.push(
          <i
            key="detail"
            className="options link icon"
            onClick={component.displayOptions}>
          </i>
      )
      toolbar = (
        <div className="right toolbar">
          {toolbarItems}
        </div>
      )
    }
    return (
      <div className="contentPlaceholder">
         <div className="paper">
           <Renderer {...part.renderer}
             context={props.context}
             screenDisplay={props.screenDisplay}
             placeholders={props.placeholders}
             onValueUpdate={component.props.onValueUpdate}/>
         </div>
         {toolbar}
      </div>
    )
  }
})

export {ContentPlaceholder};
