import React from "react"
import ReactDOM from "react-dom"
import Datetime from 'react-datetime'
import MaskedInput from 'react-maskedinput'
import moment from "moment"
import tippy from "tippy.js"
import NumberFormat from 'react-number-format'
import {Transition, Dropdown, Checkbox, Accordion} from 'semantic-ui-react'

import {Field} from "../modeler/Field.jsx";
import {EditableLabel} from "../modeler/EditableLabel.jsx";
import {Dummy} from "../modeler/Dummy.jsx";
import {MC} from './MC.js';
import {MCHistory} from "./MCHistory.js";
import {Button} from "./Button.jsx";
import {Camera} from "./Camera.jsx";
import {Repeater} from "./Repeater.jsx";
import {Expression} from "./Expression.js";
import {Table} from "./Table.jsx";
import {TabPanel} from "./TabPanel.jsx";
import {Slider} from "./Slider.jsx";
import {Label} from "./Label.jsx";
import {Download} from "./Download.jsx";
import {Media} from "./Media.jsx";
import {Link} from "./Link.jsx";
import {Chart} from "./Chart.jsx";
import {EmbeddedDialog} from "./EmbeddedDialog.jsx";
import {WhisperBox} from "./WhisperBox.jsx";
import {MenuButton} from "./MenuButton.jsx";
import {Upload} from "./Upload.jsx";
import {JdateFormat} from "./JdateFormat.js";

import "react-datetime/css/react-datetime.css"
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/themes/light-border.css'

class Widget extends React.Component {

  componentWillMount() {
    this.updateState(this.props);
  }
  
  componentWillReceiveProps(nextProps) {
    this.updateState(nextProps);
  }

  updateState(props) {
    var field = props.widget;
    var wideClass = MC.getFieldWideClass(props.maxX, field.width);
    var visible = true;
    if (!MC.isNull(props.resolution)) {
      var grid = MC.getFieldGrid(field, props.resolution);
      wideClass = MC.getFieldWideClassFromInt(grid.columns);
      visible = (grid.visible === 'true');
    }
    if (visible) {
      visible = MC.getFieldParamBooleanValue(field.param, '@visible', props.iteration);
    }
    if (MC.isModelerActive(field)) {
      if (field.isInteractive) {
        wideClass = "";
      }
      if (MC.isModelerInEyeMode(field)) {
        visible = true;
      }
    }
    var focus = MC.getFieldParamBooleanValue(field.param, '@focused', props.iteration);
    if (focus) { // set focused false, for re-render caused by formlogic
      MC.putFieldParamValue(field.param, '@focused', props.iteration, false);
    }
    this.setState({visible: visible, wideClass: wideClass, focus: focus});
  }

  componentDidMount() {
    this.afterUpdate(false)
    this.scriptedWidget()
    let tooltip = MC.getFieldParamValue(this.props.widget.param, '@tooltip', this.props.iteration)
    if (tooltip) {
      let tippyNode = ReactDOM.findDOMNode(this.refs.widgetroot)
      let toolTipHere = tippyNode.querySelector('.tooltip-here')
      if (toolTipHere) {
        tippyNode = toolTipHere
      }
      tippy(tippyNode, {content: tooltip, arrow: true, delay: 400, animation: 'scale', theme: 'light-border'})
    }
    this.conditionalForceValidate()
    if (MC.getFieldParamBooleanValue(this.props.widget.param, '@sticky', this.props.iteration) && !MC.isModelerActive(this.props.widget)) {
      window.addEventListener('scroll', this.handleStickyScroll)
    }
  }

  componentDidUpdate() {
    this.afterUpdate(true);
    this.scriptedWidget();
    this.conditionalForceValidate();
  }

  componentWillUnmount() {
    if (MC.getFieldParamBooleanValue(this.props.widget.param, '@sticky', this.props.iteration) && !MC.isModelerActive(this.props.widget)) {
      window.removeEventListener('scroll', this.handleStickyScroll);
    }
  }

  scriptedWidget() {
    var field = this.props.widget;
    if (MC.isModelerActive(field)) {
      return;
    }
    if (field.widget == 'panel' && field.scriptedWidget && field.scriptedWidget.script && field.flow.reactFlow.state.runReady) {
      let widget;
      eval(field.scriptedWidget.script);
      // run scripted widget
      if (widget) {
        if (MC.isFunction(widget.setValue)) {
          var input = MC.extend(true, {}, this.props.widget.param);
          widget.setValue(MC.stripStars(input));
        }
        if (MC.isFunction(widget.onCreate)) {
          widget.onCreate();
        }
        this.props.widget.scriptedWidgetObject = widget;
      }
    }
  }

  handleStickyScroll = () => {
    var div = ReactDOM.findDOMNode(this.refs.widgetroot);
    var parentCol = MC.findAncestor(div, 'widget');
    if (parentCol) {
      if (!this.isSticked) {
        this.stickyTop = MC.getElemCoords(div).top;
        var fixedHeader = document.getElementsByClassName('fixed-header-size');
        if (fixedHeader && fixedHeader.length === 1) {
          this.fixedHeaderHeight = fixedHeader[0].getBoundingClientRect().height;
        } else {
          this.fixedHeaderHeight = 0;
        }
      }
      var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      y = y + this.fixedHeaderHeight;
      if (y >= this.stickyTop) {
        var yD = y + div.getBoundingClientRect().height + 5;
        var stickyBottom = MC.getElemCoords(parentCol).top + parentCol.getBoundingClientRect().height;
        if (yD < stickyBottom) {
          div.style.cssText = "position: relative; top: " + (y - this.stickyTop) + "px;";
        }
        this.isSticked = true;
      } else {
        div.style.cssText = "";
        this.isSticked = false;
      }
    }
  };

  panelOpenClose = (e, acc) => {
    MC.putFieldParamValue(this.props.widget.param, '@collapsed', this.props.iteration, acc.active)
    this.forceUpdate()
    MC.handleEvent(this.props.widget, this.props.iteration, 'change')
  }

  afterUpdate(update) {
    var self = this;
    var thisNode = ReactDOM.findDOMNode(this.refs.widgetroot);
    if (this.state.focus) {
      var input = ReactDOM.findDOMNode(this.refs[this.props.widget.rbsid]);
      if (input) {
        input.focus();
      } else {
        ReactDOM.findDOMNode(this.refs.widgetroot).scrollIntoView();
      }
      var fixedHeader = document.getElementsByClassName('fixed-header-size');
      if (fixedHeader && fixedHeader.length === 1) {
        scrollBy(0, -fixedHeader[0].getBoundingClientRect().height);
      }
      this.setState({focus: false});
    }
    /*
    let collapsible = MC.getFieldParamBooleanValue(this.props.widget.param, '@collapsible', this.props.iteration);
    if (collapsible) { // is this code still needed????
      if (update) {
        let collapsed = MC.getFieldParamBooleanValue(this.props.widget.param, '@collapsed', this.props.iteration);
        if (collapsed) { // semantic ui hack, which repairs dom manipulation
          const el = ReactDOM.findDOMNode(this.refs.widgetroot).querySelector('.ui.row.transition.visible')
          if (el) {
            el.classList.remove('visible')
            el.classList.add('hidden')
          }
        } else {
          const el = ReactDOM.findDOMNode(this.refs.widgetroot).querySelector('.ui.row.transition.hidden')
          if (el) {
            el.classList.remove('hidden')
            el.classList.add('visible')
          }
        }
      }
    }
    */
  }

  conditionalForceValidate() {
    if (MC.isModelerActive(this.props.widget)) {
      return;
    }
    var forceValidation = MC.getFieldParamBooleanValue(this.props.widget.param, 'validation/@forceValidation', this.props.iteration);
    if (forceValidation) {
      MC.putFieldParamValue(this.props.widget.param, 'validation/@forceValidation', this.props.iteration, false);
      this.revalidate(true);
    }
  }

  handleDateChange = (value) => {
    var field = this.props.widget;
    if (value && value._isAMomentObject == true) {
      let withTimezone = MC.getFieldParamBooleanValue(field.param, '@outputTimezone', this.props.iteration);
      switch (field.basictype) {
        case 'dateTime': MC.putFieldParamValue(field.param, 'value', this.props.iteration, value.format(withTimezone ? "YYYY-MM-DDTHH:mm:ss.SSSZ" : "YYYY-MM-DDTHH:mm:ss.SSS")); break;
        case 'time': MC.putFieldParamValue(field.param, 'value', this.props.iteration, value.format(withTimezone ? "HH:mm:ssZ" : "HH:mm:ss")); break;
        default: MC.putFieldParamValue(field.param, 'value', this.props.iteration, value.format(withTimezone ? "YYYY-MM-DDZ" : "YYYY-MM-DD")); break;
      }
      if (this.props.widget.flow &&  this.props.widget.flow.context && this.props.widget.flow.context.data['env/cfg'] && this.props.widget.flow.context.data['env/cfg']['fl:validationStyle'] == 'blur') {
        this.revalidate(true);
      } else {
        this.revalidate();
      }
    } else {
      MC.putFieldParamValue(field.param, 'value', this.props.iteration, value);
      this.resetValidation();
    }
    MC.handleEvent(field, this.props.iteration, 'change');
  };

  blurDate = (value) => {
    ReactDOM.findDOMNode(this.refs.widgetroot).removeAttribute("data-focused")
  }

  handleTextChange = (e, v) => {
    var field = this.props.widget;
    switch (field.widget) {
      case "checkbox":
        MC.putFieldParamValue(field.param, 'value', this.props.iteration, v.checked)
        break;
      case "combobox":
        MC.putFieldParamValue(field.param, 'value', this.props.iteration, v.value);
        MC.putFieldParamValue(field.param, 'text', this.props.iteration, v.text);
        if (MC.isFunction(field.onSubmit)) {
          field.onSubmit(field, this.props.iteration);
        }
        break;
      case "whisperbox":
        MC.putFieldParamValue(field.param, 'value', this.props.iteration, e);
        MC.putFieldParamValue(field.param, 'text', this.props.iteration, v);
        if (MC.isFunction(field.onSubmit)) {
          field.onSubmit(field, this.props.iteration);
        }
        break;
      case "radiobutton":
        if (typeof this.props.parent != "undefined") {
          var parent = this.props.parent.props.widget;
          for (var i=0; i<parent.fields.length; i++) {
            var radio = parent.fields[i];
            if (radio.widget == 'radiobutton') {
              if (field.rbsid == radio.rbsid) {
                MC.putFieldParamValue(radio.param, 'value', this.props.iteration, true);
              } else {
                MC.putFieldParamValue(radio.param, 'value', this.props.iteration, false);
              }
            }
          }
        }
        break;
      case "unitcombobox":
        if (!v) {
          if (e.target.value) {
            let newVal = e.target.value;
            if (['integer', 'int', 'long', 'short', 'byte'].indexOf(field.basictype) > -1) {
              newVal = newVal.replace(/(?!-)[^0-9, ]/g, "");
            } else if (['decimal', 'double', 'float'].indexOf(field.basictype) > -1) {
              newVal = newVal.replace(/(?!-)[^0-9., ]/g, "");
            }
            MC.putFieldParamValue(field.param, 'value', this.props.iteration, newVal);
          } else {
            MC.putFieldParamValue(field.param, 'value', this.props.iteration, null);
          }
        } else {
          MC.putFieldParamValue(field.param, '@unit', this.props.iteration, v.value);
        }
        break;
      default:
        let formatter = MC.getFieldParamValue(field.param, '@formatType', this.props.iteration);
        if (formatter == 'number') {
          MC.putFieldParamValue(field.param, 'value', this.props.iteration, e.value)
        } else if (e.target.value) {
          var newVal = e.target.value;
          if (['integer', 'int', 'long', 'short', 'byte'].indexOf(field.basictype) > -1) {
            newVal = newVal.replace(/(?!-)[^0-9, ]/g, "");
          } else if (['decimal', 'double', 'float'].indexOf(field.basictype) > -1) {
            newVal = newVal.replace(/(?!-)[^0-9., ]/g, "");
          }
          MC.putFieldParamValue(field.param, 'value', this.props.iteration, newVal);
        } else {
          MC.putFieldParamValue(field.param, 'value', this.props.iteration, null);
        }
    }
    if (['checkbox', 'combobox', 'radiobutton'].indexOf(field.widget) > -1) {
      if (field.flow && field.flow.context.data['env/cfg'] && field.flow.context.data['env/cfg']['fl:validationStyle'] == 'blur') {
        this.revalidate(true);
      } else {
        this.revalidate();
      }
    } else {
      this.resetValidation();
      this.forceUpdate();
    }
    MC.handleEvent(field, this.props.iteration, 'change');
  };

  revalidate = (blur) => {
    this.resetValidation();
    var self = this;
    var field = this.props.widget;
    if (field.widget === "radiobutton") {
      self = this.props.parent;
      field = self.props.widget;
    }
    if (MC.getFieldParamBooleanValue(field.param, '@invalid', this.props.iteration) || blur) { // run only if invalid or blur mode
      MC.validateField(field, this.props.iteration).then(function () {
        self.forceUpdate();
      }).catch(function (exception) {
        if (MC.isPlainObject(exception) && !MC.isNull(exception.type)) {
          field.flow.endOperationException(exception.type, exception.message, exception.input, exception.output, exception.log);
        } else {
          field.flow.endOperationException('SYS_UnrecoverableRuntimeExc', exception);
        }
      });
    } else {
      self.forceUpdate();
    }
  };

  buildSubFields(field, iteration, disabled, readOnly, label) {
    var resolution = this.props.resolution;
    var hrows = [];
    if (label) {
      hrows.push(<div key="label" className="ui row mc-label">{label}</div>);
    }
    if (field.fields) {
      var rows = MC.splitFieldsIntoRows(field.fields, resolution);
      for (var i = 0; i < rows.length; i++) {
        var maxX = MC.getMaxX(field.width, rows[i]);
        var hrow = [];
        var lastX = 0;
        for (var ii = 0; ii < rows[i].length; ii++) {
          var subField = rows[i][ii];
          let offsetDiv;
          if (MC.isNull(resolution)) {
            if (subField.x > lastX + Math.round(maxX/12)) {
              var cls = "ui " + MC.getFieldWideClass(maxX, subField.x - lastX) + " wide column field";
              offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>;
              if (MC.isModelerActive(field)) {
                subField.autoLayoutOffset =  MC.getFieldWideClassAsInt(maxX, subField.x - lastX);
              }
            }
            if (MC.isModelerActive(field)) {
              subField.autoLayoutColumns = MC.getFieldWideClassAsInt(maxX, subField.width);
            }
          } else {
            var grid = MC.getFieldGrid(subField, resolution);
            if (grid.offset > 0) {
              var cls = "ui " + MC.getFieldWideClassFromInt(grid.offset) + " wide column field";
              offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>;
            }
          }
          subField.onSubmit = field.onSubmit;
          subField.flow = field.flow;
          hrow.push(<Widget key={subField.rbsid} widget={subField} parent={this} maxX={maxX} ref={subField.rbsid} iteration={iteration} disabled={disabled}
                            readOnly={readOnly} resolution={resolution} offsetDiv={offsetDiv}/>);
          lastX = subField.x + subField.width;
        }
        var inlineCss = {};
        if (!MC.isRowVisible(rows[i], resolution, iteration)) {
          inlineCss.display = 'none';
        }
        if (MC.isNull(resolution) && MC.isModelerActive(field)) {
          if (rows[i].length != 0) {
            var lastField = rows[i][rows[i].length - 1];
            lastField.autoLayoutNewLineAfter = "yes";
          }
        }
        hrows.push(<div key={i} className="ui row" style={inlineCss}>{hrow}</div>);
      }
    }
    return hrows;
  }

  buildHbox(field, iteration, disabled, readOnly) {
    var className = "";
    if (MC.getFieldParamBooleanValue(field.param, '@fitWidth', iteration)) {
      className += " flex-grow";
    }
    if (!MC.getFieldParamBooleanValue(field.param, '@grouped', iteration)) {
      className += " gapped";
    }
    var hcolumns = [];
    if (field.fields) {
      for (var i = 0; i < field.fields.length; i++) {
        var subField = field.fields[i];
        subField.onSubmit = field.onSubmit;
        subField.flow = field.flow;
        var maxX = MC.getMaxX(field.width, field.fields);
        hcolumns.push(
          <div className={className} key={i}>
            <Widget key={subField.rbsid} widget={subField} parent={this} maxX={maxX} ref={subField.rbsid} inTable={true} iteration={iteration} disabled={disabled} readOnly={readOnly}/>
          </div>
        );
      }
    }
    return hcolumns;
  }

  buildVbox(field, iteration, disabled, readOnly) {
    var className = "";
    if (MC.getFieldParamBooleanValue(field.param, '@fitWidth', iteration)) {
      className += " flex-grow";
    }
    if (!MC.getFieldParamBooleanValue(field.param, '@grouped', iteration)) {
      className += " gapped";
    }
    var vrows = [];
    if (field.fields) {
      for (var i = 0; i < field.fields.length; i++) {
        var subField = field.fields[i];
        subField.onSubmit = field.onSubmit;
        subField.flow = field.flow;
        var maxX = MC.getMaxX(field.width, field.fields);
        vrows.push(
          <div className={className} key={i}>
            <Widget key={subField.rbsid} widget={subField} parent={this} maxX={maxX} ref={subField.rbsid} inTable={true} iteration={iteration} disabled={disabled} readOnly={readOnly}/>
          </div>
        );
      }
    }
    return vrows;
  }

  buildOptions(field, required, iteration, defaultValue) {
    var options = [];
    if (!required) {
      options.push({value: '', text: ''})
    }
    if (MC.getFieldParamBooleanValue(field.param, '@allOptions', iteration) == true) {
      options.push({value: '*', text: '*'})
    }
    if (field.param['items*'] && field.param['items*']['@key']) {
      let keys = MC.asArray(MC.getFieldParamValue(field.param, 'items*/@key', iteration));
      let titles = field.param['items*']['@title'] ? MC.asArray(MC.getFieldParamValue(field.param, 'items*/@title', iteration)) : [];
      let groups = MC.getFieldParamValue(field.param, 'items*/@group', iteration);
      let icons = MC.asArray(MC.getFieldParamValue(field.param, 'items*/@icon', iteration));
      let urls = MC.asArray(MC.getFieldParamValue(field.param, 'items*/@imageUrl', iteration));
      let data = [];
      if (Array.isArray(groups) && groups.length > 0) {
        var groupsSet = {};
        for (var i = 0; i < groups.length; i++) {
          if (!groupsSet['a']) {
            groupsSet[groups[i]] = true;
          }
        }
        for (let groupName in groupsSet) {
          var group = {};
          group.title = groupName;
          group.keys = [];
          group.titles = [];
          group.icons = [];
          group.urls = [];
          for (var i = 0; i < groups.length; i++) {
            if (groups[i] == groupName && keys[i] !== undefined) {
              group.keys.push(keys[i]);
              var ititle = titles[i] ? titles[i] : keys[i];
              if (MC.isNull(keys[i])) {
                ititle = <span style={{color: 'red'}}>[!NULL VALUE IN KEY!]</span>;
              }
              group.titles.push(ititle);
              group.icons.push(icons[i] ? icons[i] : null);
              group.urls.push(urls[i] ? urls[i] : null);
            }
          }
          data.push(group);
        }
      } else {
        data.push({title: null, keys: keys, titles: titles, icons: icons, urls: urls});
      }
      var text = '';
      for (var d = 0; d < data.length; d++) {
        if (data[d].title) {
          options.push({ value: '$$' + d, disabled: true, content: (<Dropdown.Header><h5>{data[d].title}</h5></Dropdown.Header>) });
        }
        for (var i = 0; i < data[d].keys.length; i++) {
          var icon = null;
          if (!MC.isNull(data[d].urls[i])) {
            var imageUrl = MC.rebaseUrl(field.flow.flow.model, data[d].urls[i]);
            icon = <img src={imageUrl} className={data[d].icons[i]}/>;
          } else if (!MC.isNull(data[d].icons[i])) {
            icon = <i className={data[d].icons[i]}></i>;
          }
          if (data[d].keys[i] == defaultValue) {
            text = <React.Fragment>{icon}{data[d].titles[i]}</React.Fragment>;
          }
          options.push({ value: data[d].keys[i], text: data[d].titles[i], content: (<React.Fragment>{icon}{data[d].titles[i]}</React.Fragment>) })
        }
      }
    }
    return {options: options, text: text};
  }

  focus = () => {
    this.setState({focused: true});
  };

  focusDate = () => {
    ReactDOM.findDOMNode(this.refs.widgetroot).setAttribute("data-focused", "true");
  }

  blur = () => {
    this.setState({focused: false})
    if (this.props.widget.flow && this.props.widget.flow.context && this.props.widget.flow.context.data['env/cfg'] && this.props.widget.flow.context.data['env/cfg']['fl:validationStyle'] == 'blur') {
      this.revalidate(true)
    } else {
      this.revalidate()
    }
  }

  checkWarning = () => {
    var field = this.props.widget;
    MC.putFieldParamValue(field.param, "@invalid", this.props.iteration, false);
    MC.putFieldParamValue(field.param, "@invalidState", this.props.iteration, 'validChecked');
    MC.putFieldParamValue(field.param, "@invalidmessage", this.props.iteration, null);
    this.forceUpdate();
  };

  resetValidation = () => {
    var field = this.props.widget;
    MC.putFieldParamValue(field.param, "@invalid", this.props.iteration, false);
    MC.putFieldParamValue(field.param, "@invalidState", this.props.iteration, null);
    MC.putFieldParamValue(field.param, "@invalidmessage", this.props.iteration, null);
  };

  dateTimeStringToMoment(s, parseZone) {
    let mockDate = moment().format('YYYY-MM-DD')
    let m = parseZone ? moment.parseZone : moment
    if (MC.isNull(s)) {
      return m()
    }
    if (s.match(/^\d\d:\d\d(:\d\d)?(\.\d+)?$/i)) {
      return m(mockDate + '$$$' + s, 'YYYY-MM-DD$$$HH:mm:ss.SSS')
    } else if (s.match(/^\d\d:\d\d:\d\d(([+-]\d\d:\d\d)|Z)$/i)) {
      return m(mockDate + '$$$' + s, 'YYYY-MM-DD$$$HH:mm:ssZ')
    } else if (s.match(/^\d\d:\d\d(([+-]\d\d:\d\d)|Z)$/i)) {
      return m(mockDate + '$$$' + s, 'YYYY-MM-DD$$$HH:mmZ')
    } else if (s.match(/^\d\d:\d\d:\d\d?\.\d+(([+-]\d\d:\d\d)|Z)$/i)) {
      return m(mockDate + '$$$' + s, 'YYYY-MM-DD$$$HH:mm:ss.SSSZ')
    } else if (s.match(/^\d{4}(-\d\d(-\d\d((([+-]\d\d:\d\d)|Z))?)?)?$/i)) {
      return m(s, 'YYYY-MM-DDZ')
    } else {
      return m(s)
    }
  }

  render() {
    var widget;
    var field = this.props.widget;
    var iteration = this.props.iteration;
    var defaultValue =  MC.getFieldParamValue(field.param, 'value', iteration);
    var htmlId = field.rbsid + (Array.isArray(iteration) ? iteration.join('-') : '');
    if (Array.isArray(defaultValue)) {
      defaultValue = defaultValue[0];
    }
    if (defaultValue == null) {
      defaultValue = "";
    }
    var formatter = MC.getFieldParamValue(field.param, '@formatType', this.props.iteration);
    if (!MC.isNull(formatter) && ['label', 'textarea', 'slider'].indexOf(field.widget) > -1) {
      if (!MC.isModelerActive(field) || (!MC.isNull(defaultValue) && defaultValue !== '')) {
        defaultValue = MC.formatValue(defaultValue, formatter, field.basictype, MC.getFieldParamValue(field.param, '@formatPattern', this.props.iteration), field, iteration);
      }
    } else if (typeof defaultValue !== 'string' && !MC.isNull(defaultValue)) {
      defaultValue = JSON.stringify(defaultValue);
    }
    var required = MC.getFieldParamBooleanValue(field.param, 'validation/@required', iteration);
    var labelStr = MC.getFieldParamValue(field.param, '@title', iteration);
    var titlePlacement = MC.getFieldParamValue(field.param, '@titlePlacement', this.props.iteration);
    var label = '';
    if (field.widget == 'checkbox' && !labelStr) {
      labelStr = '\u00a0';
    }
    if (!this.props.inTable) {
      if (titlePlacement == 'PI' && labelStr) {
        label = <h3 className="ui header editable" key="h3">{labelStr}</h3>;
      } else if (labelStr) {
        var inlineCss = {};
        if (['L', 'LL'].indexOf(titlePlacement) > -1) {
          inlineCss.display = 'inline-block';
          if (titlePlacement == 'L') {
            inlineCss.textAlign = 'right';
          } else {
            inlineCss.textAlign = 'left';
          }
          var tWidth = MC.getFieldParamValue(field.param, '@titleWidth', this.props.iteration);
          if (!MC.isNull(tWidth) && MC.isNumeric(tWidth)) {
            inlineCss.width = tWidth + 'px';
          }
        }
        var requiredStar = '';
        var escapeHtml = MC.getFieldParamValue(field.param, '@escapeTitleHtml', this.props.iteration);
        if (escapeHtml) {
          if (required) {
            requiredStar = <span className="rstar">*</span>;
          }
          label = <label className="editable" style={inlineCss} htmlFor={field.rbsid} key="wlabel">{labelStr}{requiredStar}</label>;
        } else {
          if (required) {
            requiredStar = '<span class="rstar">*</span>';
          }
          label = <label className="editable" style={inlineCss} htmlFor={field.rbsid} key="wlabel" dangerouslySetInnerHTML={{__html: MC.customHtml(labelStr + requiredStar)}}/>;
        }
      }
    }
    if (MC.isModelerActive(field)) {
      label = <EditableLabel field={field} widget={label} key="EditableLabel" path={["param", "@title"]}/>;
    }
    if (titlePlacement === 'IN') {
      label = '';
    }
    var readOnly = MC.getFieldParamBooleanValue(field.param, '@readonly', iteration);
    if (!readOnly && this.props.readOnly) {
      readOnly = true;
    }
    var disabled = !MC.getFieldParamBooleanValue(field.param, '@enabled', iteration);
    if (!disabled && this.props.disabled) {
      disabled = true;
    }
    if (['slider'].indexOf(field.widget) > -1 && readOnly) { // fallback for widgets not having readonly mode
      disabled = true;
    }
    if (MC.isModelerInEyeMode(field)) {
      disabled = false;
    }
    var maxlength = MC.getFieldParamValue(field.param, 'validation/@maxLength', iteration);
    var placeholder = MC.getFieldParamValue(field.param, '@placeholder', iteration);
    var help = MC.getFieldParamValue(field.param, '@help', iteration);
    var invalid = MC.getFieldParamBooleanValue(field.param, '@invalid', iteration);
    var invalidState = MC.getFieldParamValue(field.param, '@invalidState', iteration);
    var helpCls = 'help';
    var helpCheckbox = null;
    if (invalid) {
      var errMsg = MC.getFieldParamValue(field.param, 'validation/@title', iteration);
      if (!MC.isNull(errMsg) && errMsg !== '') {
        help = errMsg;
      } else {
        var invalidMsg = MC.getFieldParamValue(field.param, '@invalidmessage', iteration);
        if (!MC.isNull(invalidMsg) && invalidMsg !== '') {
          help = invalidMsg;
        }
      }
      if (invalidState == 'warning') {
        helpCls = 'warning';
        helpCheckbox = <Checkbox key="warning-checkbox" onChange={this.checkWarning}/>;
      } else {
        helpCls = 'error';
      }
    }
    if ((MC.isNull(help) || help == '') && !MC.isModelerInStructuralMode(field)) {
      help = '\u00a0';
    }
    var errLabel = this.props.inTable ? '' : <label htmlFor={htmlId} className={helpCls} ref="label-error" key="error" onClick={(e) => {e.stopPropagation(); e.preventDefault()}}>{helpCheckbox}<span className="text">{help}</span></label>;
    var cssclass = MC.getFieldParamValue(field.param, '@cssClassField', iteration);
    var cssin = MC.getFieldParamValue(field.param, '@cssClass', iteration);
    var collapsible = MC.getFieldParamBooleanValue(field.param, '@collapsible', iteration);
    switch (field.widget) {
      case 'textbox':
      case 'numberbox':
      case 'unitbox':
      case 'unitcombobox':
        if (formatter == 'number') {
          const decimalScale = Number(MC.getFieldParamValue(field.param, '@decimalScale', iteration)) || 2
          widget = <NumberFormat name={field.rbsid} ref={field.rbsid} placeholder={placeholder} value={defaultValue} readOnly={readOnly} disabled={disabled} key="input" 
                    maxLength={maxlength} onFocus={this.focus} onBlur={this.blur} data-widget-i-name={field.id} className={cssin}
                    isNumericString={true} decimalSeparator="," thousandSeparator=" " decimalScale={decimalScale} onValueChange={this.handleTextChange}/>
        } else if (formatter == 'mask') {
          var mask = MC.getFieldParamValue(field.param, '@formatPattern', this.props.iteration);
          widget = <MaskedInput mask={mask} name={field.rbsid}  id={htmlId} ref={field.rbsid} placeholder={placeholder} value={defaultValue} readOnly={readOnly} disabled={disabled}
                                maxLength={maxlength} onChange={this.handleTextChange} key="input" onFocus={this.focus} onBlur={this.blur} data-widget-i-name={field.id} className={cssin}/>
        } else {
          const type = ['integer', 'int', 'long', 'short', 'byte', 'decimal', 'double', 'float'].indexOf(field.basictype) > -1 ? 'number' : 'text';
          const maxValue = type === 'number' ? MC.getFieldParamValue(field.param, 'validation/@maxValue', iteration) : null;
          const minValue = type === 'number' ? MC.getFieldParamValue(field.param, 'validation/@minValue', iteration) : null;
          const step = ['decimal', 'double', 'float'].indexOf(field.basictype) > -1 ? '0.01' : null;
          widget = <input type={type} step={step} name={field.rbsid} id={htmlId} ref={field.rbsid} placeholder={placeholder} value={defaultValue} readOnly={readOnly} disabled={disabled}
                          maxLength={maxlength} onChange={this.handleTextChange} key="input" onFocus={this.focus} onBlur={this.blur} data-widget-i-name={field.id} className={cssin}
                          min={minValue} max={maxValue}/>;
        }
        if (field.widget === 'unitbox') {
          var unit = MC.getFieldParamValue(field.param, '@unit', iteration);
          var placement = MC.getFieldParamValue(field.param, '@unitPlacement', iteration);
          if (placement === 'left') {
            widget = [<div className="ui labeled input" key="input"><div className="ui label">{unit}</div>{widget}</div>, errLabel];
          } else {
            widget = [<div className="ui right labeled input" key="input">{widget}<div className="ui label">{unit}</div></div>, errLabel];
          }
        } else if (field.widget === 'unitcombobox') {
          var unit = MC.getFieldParamValue(field.param, '@unit', iteration);
          var placement = MC.getFieldParamValue(field.param, '@unitPlacement', iteration);
          let options = this.buildOptions(field, required, iteration, unit);
          if (placement === 'left') {
            widget = ([
              <div className="ui labeled input" key="field">
                <Dropdown className="label" value={unit} options={options.options} onFocus={this.focus} onBlur={this.blur} text={options.text} 
                  onChange={this.handleTextChange} key="dropdown" disabled={disabled} selectOnNavigation={false} selectOnBlur={false} />
                {widget}
              </div>,
              errLabel,
              <label className="error" key="error2" style={{display: 'none'}}/>
            ]);
          } else {
            widget = ([
              <div className="ui right labeled input" key="field">
                {widget}
                <Dropdown className="label" value={unit} options={options.options} onFocus={this.focus} onBlur={this.blur} text={options.text} 
                 onChange={this.handleTextChange} key="dropdown" disabled={disabled} selectOnNavigation={false} selectOnBlur={false} />
              </div>,
              errLabel,
              <label className="error" key="error2" style={{display: 'none'}}/>
            ]);
          }
        } else {
          widget = [widget, errLabel];
        }
        break;
      case 'datebox':
        var dateValue = null;
        let withTimezone = MC.getFieldParamBooleanValue(field.param, '@outputTimezone', this.props.iteration);
        if (!MC.isNull(defaultValue) && defaultValue != '') {
          let defaultMoment = this.dateTimeStringToMoment(defaultValue, false);
          if (defaultMoment.isValid()) {
            if (withTimezone && !MC.hasTimezone(defaultValue)) {
              let expression = new Expression(null, field.flow.context ? field.flow.context.data : {}, null);
              defaultValue = expression.operatorFillTimezone([defaultValue]);
            }
            if (!withTimezone && MC.hasTimezone(defaultValue)) {
              let expression = new Expression(null, field.flow.context ? field.flow.context.data : {}, null);
              defaultValue = expression.operatorRemoveTimezone([defaultValue]);
            }
          }
          dateValue = this.dateTimeStringToMoment(defaultValue, withTimezone);
        } else {
          dateValue = moment(defaultValue);
        }
        var dateFormat = 'DD. MM. YYYY';
        var timeFormat = 'HH:mm';
        var pattern = MC.getFieldParamValue(field.param, '@formatPattern', iteration);
        if (!MC.isNull(pattern)) {
          switch (field.basictype) {
            case 'dateTime':
              var tokens = pattern.split("'T'");
              if (tokens.length == 2) {
                dateFormat = JdateFormat.toMomentFormatString(tokens[0]);
                timeFormat = JdateFormat.toMomentFormatString(tokens[1]);
              } else {
                MCHistory.log(MCHistory.T_ERROR, 'Unsupported dateTime format' + pattern + ' at dateTimePicker (date\'T\'time), using default formats!', field.flow.debug);
              }
              break;
            case 'time': timeFormat = JdateFormat.toMomentFormatString(pattern); break;
            default: dateFormat = JdateFormat.toMomentFormatString(pattern); break;
          }
        } else {
          dateValue.milliseconds(0);
          dateValue.seconds(0);
        }
        if (!MC.isNull(defaultValue) && defaultValue != '' && dateValue.isValid()) {
          switch (field.basictype) { //normalize bad mapped value for validation
            case 'dateTime': MC.putFieldParamValue(field.param, 'value', iteration, dateValue.format(withTimezone ? "YYYY-MM-DDTHH:mm:ss.SSSZ" : "YYYY-MM-DDTHH:mm:ss.SSS")); break;
            case 'time': MC.putFieldParamValue(field.param, 'value', iteration, dateValue.format(withTimezone ? "HH:mm:ssZ" : "HH:mm:ss")); break;
            default: MC.putFieldParamValue(field.param, 'value', iteration, dateValue.format(withTimezone ? "YYYY-MM-DDZ" : "YYYY-MM-DD")); break;
          }
        }
        if (!dateValue.isValid() && !MC.isNull(defaultValue)) {
          dateValue = defaultValue
        }
        if (field.basictype == 'time') {
          widget = <Datetime onChange={this.handleDateChange} onBlur={this.blurDate} onFocus={this.focusDate} value={dateValue} dateFormat={false} timeFormat={timeFormat} closeOnSelect={true} locale={MC.getLang()}
                             inputProps={{id: htmlId, name: field.rbsid, onBlur: this.blurDate, placeholder: placeholder, readOnly: readOnly, disabled: (disabled || readOnly), 'data-widget-i-name': field.id}} key="input"/>;
        } else if (field.basictype == 'dateTime') {
          widget = <Datetime onChange={this.handleDateChange} onBlur={this.blurDate} onFocus={this.focusDate} value={dateValue} dateFormat={dateFormat} timeFormat={timeFormat} closeOnSelect={true} locale={MC.getLang()}
                             inputProps={{id: htmlId, name: field.rbsid, onBlur: this.blurDate, placeholder: placeholder, readOnly: readOnly, disabled: (disabled || readOnly), 'data-widget-i-name': field.id}} key="input"/>;
        } else {
          widget = <Datetime onChange={this.handleDateChange} onBlur={this.blurDate} onFocus={this.focusDate} value={dateValue} dateFormat={dateFormat} timeFormat={false} closeOnSelect={true} locale={MC.getLang()}
                             inputProps={{id: htmlId, name: field.rbsid, onBlur: this.blurDate, placeholder: placeholder, readOnly: readOnly, disabled: (disabled || readOnly), 'data-widget-i-name': field.id}} key="input"/>;

        }
        widget = [widget, errLabel];
        break;
      case 'passwordbox':
        var autocomplete = 'on';
        if (MC.isModelerActive(field)) {
          autocomplete = 'off';
        }
        widget = <input type="password" name={field.rbsid} id={htmlId} ref={field.rbsid} placeholder={placeholder} value={defaultValue} readOnly={readOnly}
                        disabled={disabled} onChange={this.handleTextChange} key="input" onFocus={this.focus} onBlur={this.blur} autoComplete={autocomplete} data-widget-i-name={field.id}/>;
        widget = [widget, errLabel];
        break;
      case 'textarea':
        var rows = MC.getFieldParamValue(field.param, '@rows', iteration);
        if (MC.isNull(rows) || !MC.isNumeric(rows)) {
          rows = null;
        }
        var resize = MC.getFieldParamBooleanValue(field.param, '@resize', iteration);
        var txtStyle = {};
        if (!resize) {
          txtStyle.resize = 'none';
        }
        widget = <textarea type="text" name={field.rbsid} id={htmlId} ref={field.rbsid} placeholder={placeholder} value={defaultValue} readOnly={readOnly}
                           disabled={disabled} maxLength={maxlength} onChange={this.handleTextChange} key="input" rows={rows} style={txtStyle} data-widget-i-name={field.id}/>;
        widget = [widget, errLabel];
        break;
      case 'upload':
        widget = <Upload field={field} widget={this} key="input" htmlId={htmlId} placeholder={placeholder} readOnly={readOnly} disabled={disabled} iteration={iteration}/>;
        widget = [widget, errLabel];
        break;
      case 'checkbox':
        const checked = defaultValue == true || defaultValue == 'true'
        if (!checked && defaultValue !== false && defaultValue !== 'false') {
          MC.putFieldParamValue(field.param, 'value', this.props.iteration, false);
        }
        if (typeof cssclass == 'string' && cssclass.indexOf("toggle") > -1) {
          cssin += " toggle"
        }        
        widget = <Checkbox key="input" label={label} checked={checked} onChange={this.handleTextChange} className={cssin} disabled={disabled} readOnly={readOnly}/>
        break;
      case 'radiobutton':
        const parent = this.props.parent
        if (typeof parent != "undefined") {
          widget = <Checkbox radio key="input" name={parent.props.widget.rbsid} checked={defaultValue == true || defaultValue == 'true'} 
                     onChange={this.handleTextChange} className={cssin} disabled={disabled} readOnly={readOnly} label={label}/>
        }
        break;
      case 'label':
        widget = <Label id={htmlId} ref={field.rbsid} field={field} inTable={this.props.inTable} iteration={iteration} value={defaultValue} key="label"/>;
        if (MC.isModelerActive(field)) {
          widget = <EditableLabel key="editableLabel" field={field} widget={widget} path={["param", "@title"]}/>;
        }
        widget = [widget, errLabel];
        break;
      case 'button':
        widget = [<Button button={field} ref={field.rbsid} value={defaultValue} iteration={iteration} disabled={disabled} key="button"/>, errLabel];
        if (MC.isModelerActive(field)) {
          widget = <EditableLabel key="editableLabel" field={field} widget={widget} path={["param", "@title"]}/>;
        }
        break;
      case 'link':
        widget = <Link data={field} ref={field.rbsid} value={defaultValue} iteration={iteration} disabled={disabled} key="link"/>;
        widget = [widget, errLabel];
        if (MC.isModelerActive(field)) {
          widget = <EditableLabel key="editableLabel" field={field} widget={widget} path={["param", "@title"]}/>;
        }
        break;
      case 'combobox':
        const whisper = MC.getFieldParamBooleanValue(field.param, '@whisper', iteration)
        let options = this.buildOptions(field, required, iteration, defaultValue)
        if (!MC.isNull(options.text)) {
          MC.putFieldParamValue(field.param, 'text', iteration, options.text)
        }
        let disabledCmb = false
        if (!MC.isModelerInEyeMode(field) && (disabled || readOnly)) {
          disabledCmb = true
        }
        widget = [<Dropdown fluid selection key="dropdown" onChange={this.handleTextChange} search={whisper} value={defaultValue} options={options.options} selectOnNavigation={false} 
                    selectOnBlur={false} text={options.text} disabled={disabledCmb} onFocus={this.focus} onBlur={this.blur}/>, errLabel]
        break
      case 'panel':
      case 'radiogroup':
        if (field.widget == 'panel' && field.scriptedWidget) {
          widget =  <div className="ui twelve wide column" dangerouslySetInnerHTML={{__html: field.scriptedWidget.html}}></div>;
        } else {
          var labelToSend = field.widget == 'panel' && titlePlacement == 'PI' && !collapsible ? label : null;
          widget = this.buildSubFields(field, iteration, disabled, readOnly, labelToSend);
        }
        if (field.widget == 'radiogroup') {
          widget = [widget, errLabel];
        }
        break;
      case 'hbox':
        widget = this.buildHbox(field, iteration, disabled, readOnly);
        break;
      case 'vbox':
        widget = this.buildVbox(field, iteration, disabled, readOnly);
        break;
      case 'tabpanel':
        widget = <TabPanel key="tabpanel" data={field} ref={field.rbsid} disabled={disabled} readOnly={readOnly} resolution={this.props.resolution}/>;
        break;
      case 'repeater':
        widget = <Repeater key="repeater" data={field} iteration={iteration} ref={field.rbsid} disabled={disabled} readOnly={readOnly} resolution={this.props.resolution}/>;
        break;
      case 'basictable':
      case 'loadabletable':
      case 'pageabletable':
      case 'scrollabletable':
        widget = <Table key="table" data={field} iteration={iteration} ref={field.rbsid} disabled={disabled} readOnly={readOnly} resolution={this.props.resolution}/>;
        break;
      case 'download':
        if (MC.isModelerActive(field)) {
          widget = <Dummy key="widget" field={field}/>
        } else {
          widget = <Download key="download" data={field} ref={field.rbsid}/>;
        }
        break;
      case 'imageviewer':
      case 'defaultviewer':
      case 'pdfviewer':
        widget = [<Media key="media" data={field} ref={field.rbsid} iteration={iteration}/>, errLabel];
        break;
      case 'icon':
          var icon = MC.getFieldParamValue(field.param, '@icon', this.props.iteration);
          if (!MC.isNull(icon)) {
            var cls = 'icon ' + icon;
            widget = <i key="icon" className={cls} id={htmlId} ref={field.rbsid} data-widget-i-name={field.id}></i>;
          } else if (MC.isModelerActive) {
            widget = <Dummy field={field}/>
          }
        break;
      case 'piechart':
        widget = <Chart key="chart" data={field} ref={field.rbsid} iteration={iteration} type="pie"/>;
        break;
      case 'chart':
        widget = <Chart key="chart" data={field} ref={field.rbsid} iteration={iteration} type="basic"/>;
        break;
      case 'embeddeddialog':
        if (MC.isModelerActive(field)) {
          widget = <Dummy key="widget" field={field}/>;
        } else {
          widget = <EmbeddedDialog key="dialog" data={field} ref={field.rbsid} iteration={iteration}/>;
        }
        break;
      case 'camera':
        widget = <Camera key="camera" data={field} ref={field.rbsid} iteration={iteration} htmlId={htmlId} help={help} helpCls={helpCls}/>;
        break;
      case 'slider':
        widget = [<Slider key="slider" iteration={iteration} data={field}/>, errLabel];
        break;
      case 'whisperbox':
        widget = [<WhisperBox key="slider" iteration={iteration} data={field} placeholder={placeholder} widget={this} readOnly={readOnly} disabled={disabled}/>, errLabel];
        break;
      case 'menubutton':
        widget = [<MenuButton key="menubutton" iteration={iteration} data={field}/>, errLabel];
        break;
      default:
        var customComponent = MC.getReactRomponent(field.widget);
        if (customComponent) {
          widget = React.createElement(customComponent, {key: field.rbsid, data: field, ref: field.rbsid, iteration: iteration})
        } else {
          if (MC.isModelerActive(field)) {
            widget = this.buildSubFields(field, iteration, disabled, readOnly, null);
          } else {
            widget = <span key="unrecognized">Unrecognized widget: {field.widget}</span>;
          }
        }
        break;
    }
    cssclass = cssclass ? ' ' + cssclass : '';
    if (!MC.isModelerInEyeMode(field) && disabled) {
      cssclass += ' disabled';
    }
    if (['L', 'LL'].indexOf(titlePlacement) > -1) {
      cssclass += ' inline';
    } 
    var halign = MC.getFieldParamValue(field.param, '@horizontalAlignment', iteration);
    var inlineCss = {};
    if (this.state.visible === false) {
      inlineCss.display = 'none';
    }
    if (halign == 'center') {
      inlineCss['textAlign'] = 'center';
    } else if (halign == 'right') {
      inlineCss['textAlign'] = 'right';
    }
    var valign = MC.getFieldParamValue(field.param, '@verticalAlignment', iteration) + ' ';
    if (valign == 'bottom ') {
      valign = 'bottom aligned ';
    } else {
      valign = '';
    }
    var appendCss = '';
    if (invalid) {
      if (invalidState == 'warning') {
        appendCss = ' warning';
      } else {
        appendCss = ' error';
      }
    } else {
      if (invalidState == 'valid' || invalidState == 'validChecked') {
        appendCss = ' valid';
      }
    }
    if (this.state.focused) {
      appendCss += ' focused';
    }
    if (!MC.isNull(defaultValue) && defaultValue !== '' || field.widget === 'upload') {
      if (["checkbox", "radiogroup", "radiobutton"].indexOf(field.widget) < 0) {
        appendCss += ' valued';
      }
    }
    let collapsed = MC.getFieldParamBooleanValue(field.param, '@collapsed', iteration);
    let resField;
    if (['button', 'link', 'icon'].indexOf(field.widget) > -1) {
      if (this.props.inTable) {
        if (!inlineCss['display'] || inlineCss['display'] != 'none') {
          if (typeof this.props.parent == "undefined" || ['hbox', 'vbox'].indexOf(this.props.parent.props.widget.widget) < 0) {
            inlineCss['display'] = 'inline-block';
          }
        }
        resField = <Field key="field" ref="widgetroot" iteration={iteration} className={cssclass} field={field} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      } else {
        var cls = "ui " + valign + this.state.wideClass + " wide column field widget" + appendCss + cssclass;
        resField = <Field key="field" ref="widgetroot" iteration={iteration} field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      }
  	} else if (["radiogroup"].indexOf(field.widget) > -1) {
      var cls = "ui " + valign + this.state.wideClass + " wide column field widget radiogroup"  + cssclass + appendCss;
      var clsGrid = "ui twelve column grid stackable";
      widget = [label,
                    <div key="kd" className={clsGrid} data-widget-i-name={field.id}>
                        {widget}
                    </div>];
      resField = <Field key="field" ref="widgetroot" iteration={iteration} field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
    } else if (["panel"].indexOf(field.widget) > -1) {
      let cls = "ui " + valign + this.state.wideClass + " wide column widget" + cssclass
      let clsGrid = "ui twelve column grid stackable widgetContainer"
      label = field.widget == 'panel' && titlePlacement == 'PI' ? null : label
      if (collapsible) {
        let activeIndex = collapsed ? -1 : 0
        let panel = [{
          key: 'panel',
          title: {
            content: <span className="ui header">{labelStr}</span>
          },
          content: {
            content: <div key="kd" className={clsGrid} data-widget-i-name={field.id}>{widget}</div>
          }
        }]
        widget = <Accordion fluid panels={panel} onTitleClick={this.panelOpenClose} activeIndex={activeIndex}/>
      } else {
        widget = [label,
          <div key="kd" className={clsGrid} data-widget-i-name={field.id}>
            {widget}
          </div>]
      }
      resField = <Field key="field" ref="widgetroot" iteration={iteration} field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>
    } else if (["hbox"].indexOf(field.widget) > -1) {
      var cls = "ui " + valign + this.state.wideClass + " wide column" + cssclass;
      var className = "hbox flex-row";
      if (halign == 'center') {
        className += ' flex-center';
      } else if (halign == 'right') {
        className += ' flex-right';
      }
      widget = [label, <div  key="kd" className={className} style={inlineCss} data-widget-i-name={field.id}>{widget}</div>, errLabel];
      resField = <Field key="field" ref="widgetroot" iteration={iteration} field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
    } else if (["vbox"].indexOf(field.widget) > -1) {
      if (MC.getFieldParamBooleanValue(field.param, '@fitWidth', iteration)) {
        inlineCss['height'] = '100%';
      }
      var cls = "ui " + valign + this.state.wideClass + " wide column" + cssclass;
      widget = [label, <div  key="kd" className="vbox flex-column" style={inlineCss} data-widget-i-name={field.id}>{widget}</div>, errLabel];
      resField = <Field key="field" ref="widgetroot" iteration={iteration} field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
    } else if (field.widget == "repeater") {
      var cls = "ui " + valign + this.state.wideClass + " wide column widget" + cssclass;
      var inline = MC.getFieldParamBooleanValue(field.param, '@inline', iteration);
      if (inline) {
        cls = "ui " + valign + " twelve wide column widget" + cssclass;
      }
      if (collapsible) {
        let activeIndex = collapsed ? -1 : 0
        let panel = [{
          key: 'panel',
          title: {
            content: <span className="ui header">{labelStr}</span>
          },
          content: {
            content: widget
          }
        }]
        widget =  <Accordion panels={panel} onTitleClick={this.panelOpenClose} activeIndex={activeIndex}/>
        resField = <Field key="field" ref="widgetroot" iteration={iteration} field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      } else {
        widget = [label, widget];
        resField = <Field key="field" ref="widgetroot" iteration={iteration} field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      }
    } else {
      if (this.props.inTable) {
        if (!inlineCss['display'] || inlineCss['display'] != 'none') {
          inlineCss['display'] = 'inline-block';
        }
        resField = <Field key="field" ref="widgetroot" iteration={iteration} field={field} className={cssclass} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      } else {
        var cls = "ui " + valign + this.state.wideClass + " wide column field widget" + cssclass + appendCss;
        if (field.widget == 'checkbox') {
          cls += ' widget-checkbox';
          if (MC.isModelerActive(field)) {
            cls += " read-only";
          }
        } else if (field.widget == 'radiobutton') {
          cls += ' widget-radio';
          if (MC.isModelerActive(field)) {
            cls += " read-only";
          }
          if (defaultValue == true || defaultValue == 'true') {
            cls += " checked";
          }
          inlineCss['paddingTop'] = '15px';
        } else if (field.widget == 'upload') {
          cls += " upload";
        }
        if (field.widget == 'checkbox') {
          widget = [widget, errLabel];
        } else if (field.widget == 'radiobutton') { 
          widget = widget 
        } else {
          widget = [label, widget]
        }
        resField = <Field key="field" ref="widgetroot" iteration={iteration} field={field} className={cls} style={inlineCss} dataWidgetId={field.rbsid} widget={widget}/>;
      }
    }
    let toReturn = null
    if (this.state.visible && this.props.offsetDiv) {
      toReturn = [this.props.offsetDiv, resField]
    } else {
      toReturn = resField
    }
    const transition = MC.getFieldParamValue(this.props.widget.param, '@transition', this.props.iteration)
    if (MC.isNull(transition)) {
      return toReturn
    } else {
      return <Transition visible={this.state.visible} animation={transition} duration={1000}>{toReturn}</Transition>
    }
  }

}

export {Widget};