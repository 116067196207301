import createReactClass from 'create-react-class';
import {Library} from "./library.js";
import {ValueInspector} from "./inspector-component/value-inspector.jsx";
import React from "../../miniclient/in/client/node_modules/react";
import './inspector.css';

const Inspector = createReactClass({
  handleValueUpdate: function(update) {
    const self = this
    const props = self.props
    const fileUpdate = Library.makeComplexValueUpdate(props.file['meta3:properties'], update)
    self.props.onUpdate({type: 'fileUpdate', path: props.file['meta3:path'], fileUpdate})
  },
  load: function(context) {
    const value = context.value
    const properties = value['meta3:properties']
    const titleProperty = properties['app:inspectorTitleProperty'] || 'meta3:name'
    return ValueInspector.prototype.load(context, undefined, properties).then(inspectorComponent => {
      return {
        inheritedProperties: properties,
        inspectorComponent: inspectorComponent,
        file: context.file,
        title: properties[titleProperty]
      }
    })
  },
  render: function() {
    const self = this;
    const props = self.props;
    const inspectorComponent = {...props.inspectorComponent,
      isTopLevel: true,
      onValueUpdate: self.handleValueUpdate,
      onUpdate: self.props.onUpdate,
      readOnly: props.readOnly
    };
    const valueInspector = React.createElement(ValueInspector, inspectorComponent);
    return (
      <div className="component inspector">
       <h4 className="ui dividing header">{props.title}</h4>
        <form className="ui form">
          {valueInspector}
        </form>
      </div>
    )
  }
});

export {Inspector};
