import React from "../../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {ApplicationComponent} from '../../../../app/src/application-component.jsx';
import {ReactFlow} from '../../../../miniclient/in/client/src/client/ReactFlow.jsx';
import {ApplicationUtils} from '../../../../app/src/application-utils.js';
import {Header} from './header.jsx';
import {FlowGenerator} from './flow-generator.js';
import {AssemblyGenerator} from './assembly-generator.js';
import {fixDiagram} from './diagram-repairer.js';
import {TemplateRender} from './template-editor/template-render.js';
import {Flow} from './flow.jsx';
import {TemplateEditor} from './template-editor/template-editor.jsx';
import {PropertiesEditor} from '../../../../app/src/properties-editor.jsx';
import {FlowEditor} from './flow-editor.jsx';
import {AssemblyEditor} from './assembly-editor.jsx';
import {Inspector} from "../../../../app/src/inspector.jsx";
import {Library} from "../../../../app/src/library.js";
import {flowSortNodes} from './flow-sort-nodes.js'
import {validateFlow} from './flow-validation.js'
import  '../../../../app/src/calendar.jsx'
import "./application.css";

const registerHandlersAndTransformations = Library.callOnce(context => {
  const controler = context.controler
  context.storage.registerStoreHandler('commhub/concept/assembly', file => {
    const properties = file['meta3:properties'];
    const path = file['meta3:path'];
    return Promise.resolve().then(() => {
      return AssemblyGenerator.generate(context.storage, path, properties, controler.getContext().model['meta3:path'])
    }).then(() => {
      return Library.updateProperties(path, {'chub:error': ''});
    }).catch(error => {
      return Library.updateProperties(path, {'chub:error': error.message});
    })
  })

  context.storage.registerStoreHandler('commhub/concept/flow', file => {
    const properties = file['meta3:properties'];
    const path = file['meta3:path'];
    return Promise.resolve().then(() => {
      return FlowGenerator.generate(context.storage, path, properties, controler.getContext().model['meta3:path']);
    }).then(async () => {
      return Library.updateProperties(path, {'chub:error': ''});
    }).catch(error => {
      throw error
      return Library.updateProperties(path, {'chub:error': error.message});
    })
  })

  context.storage.registerFileTransformation('commhub/concept/html-object', file => TemplateRender.templateFileTransform(context.storage, file))

  //context.storage.registerStoreTransformation('commhub/concept/flow', fixDiagram)
  //context.storage.registerStoreTransformation('commhub/concept/assembly', fixDiagram)
  context.storage.registerStoreTransformation('commhub/concept/flow', flowSortNodes)
  context.storage.registerStoreTransformation('commhub/concept/flow', file => validateFlow(file, context.debug, controler.getContext().model['meta3:path']))
})

const loadCKEditor = Library.callOnce(async () => {
  window.CKEDITOR_BASEPATH = rbBaseUri + 'lib/ckeditor/4.7.2_09d2f8ff62db/';
  await $.getScript(rbBaseUri + 'lib/ckeditor/4.7.2_09d2f8ff62db/ckeditor.js', function( data, textStatus, jqxhr ) {
    CKEDITOR.config.extraPlugins = "base64image";
    //CKEDITOR.config.extraPlugins = 'placeholder';
  })
})

const loadAceEditor = Library.callOnce(async () => {
  await $.getScript(rbBaseUri +'lib/ace/1.2.2/ace.js', function() {
    ace.config.set("basePath", rbBaseUri + 'lib/ace/1.2.2');
  });
})

const registerComponents = Library.callOnce(()  => {
  ApplicationUtils.registerComponent('in/app/concept/component/flow', Flow)
  ApplicationUtils.registerComponent('in/app/concept/component/inspector', Inspector)
  ApplicationUtils.registerComponent('commhub/concept/component/template-editor', TemplateEditor)
  ApplicationUtils.registerComponent('commhub/concept/component/flow-editor', FlowEditor)
  ApplicationUtils.registerComponent('commhub/concept/component/assembly-editor', AssemblyEditor)
  ApplicationUtils.registerComponent('in/app/concept/component/properties-editor', PropertiesEditor)
})

const Application = createReactClass({
  load: async function (context) {
    context = Application.prototype.checkReadOnly(context)
    registerHandlersAndTransformations(context)
    await loadCKEditor()
    await loadAceEditor()
    registerComponents()
    await Library.loadNamespaces('commhub/api/def/Namespace/')
    const header = await Header.prototype.load(context)
    const applicationComponent = await ApplicationComponent.prototype.load(context, context.layout['meta3:properties'])
    return {header, applicationComponent}
  },

  checkReadOnly: function(context) {
    if (context.model) {
      if (context.model['meta3:path'].includes('design')) {
        return {...context, readOnly: !context.permissions.includes('chp:design')}
      }
      if (context.model['meta3:path'].includes('runtime')) {
        return {...context, readOnly: !context.permissions.includes('chp:runtime')}
      }
    }
    return context
  },
  render: function() {
    const component = this;
    const props = component.props;
    const header = (
      <Header
        {...props.header}
        loading={props.loading}
        error={props.error}
        onUpdate={props.onUpdate}/>
    )
    const body = <ApplicationComponent {...props.applicationComponent} onUpdate={props.onUpdate}/>;
    const footer = <div className="footer meta center-content">version: 3.0.1 | © 2018 Tatra Billing Digital s.r.o.</div>;
    return (
      <div className="application commhub meta column center-content vertical scroll">
           <div className="meta container">
             {header}
             <div className="content meta column">
               {body}
             </div>
             {footer}
           </div>
       </div>
    );
  }
});

export {Application};
