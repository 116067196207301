import React from "../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import { Dropdown } from 'semantic-ui-react';

const UserDropdown = createReactClass({
  load: function(context) {
    return Promise.resolve({
      user: context.user,
      isOwner:  context.permissions && context.permissions.includes('auth:owner'),
      modelPath: context.model && context.model['meta3:path']
    })
  },
  handleChange: function(event, data) {
    const component = this;
    const props = component.props;
    const value = data.value;
    if (value == "logout") {
      props.onUpdate({type: 'logout'});
    } else if (value == 'user') {
      props.onUpdate({type: 'routeTo', ri: 'admin/user'});
    } else if (value == 'users') {
      props.onUpdate({type: 'routeTo', ri: 'admin/users'});
    } else if (value == 'rights') {
      let ri;
      if (props.modelPath) {
        ri = '/admin/rights?scope=' + props.modelPath
      } else {
        ri = '/admin/rights'
      }
      props.onUpdate({type: 'routeTo', ri});
    }
  },
  render: function () {
    const component = this;
    const props = component.props;
    const options = [{
      text: 'User profile',
      value: 'user'
    }]
    if (props.isOwner) {
      options.push({
        text: 'Users Administration',
        value: 'users'
      });
      options.push({
        text: 'Rights Administration',
        value: 'rights'
      });
    }
    options.push({
      text: 'Logout',
      value: 'logout'
    });
    return (<Dropdown className="user" text={props.user} options={options} direction='left' value={false} onChange={(event, data) => component.handleChange(event,data)}/>)
  }
});

export {UserDropdown};
