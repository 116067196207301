import React from "../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "./library.js";
import {DropdownNavigationBarItem} from "./dropdown-navigation-bar-item.jsx";

const NavigationBarItem = createReactClass({
  handleClick(event) {
    const component = this;
    const props = component.props;
    props.onUpdate({type: 'routeTo', ri: props.target});
  },
  handleSelectSubitem(subitem) {
    const component = this;
    const props = component.props;
    props.onUpdate({type: 'routeTo', ri: subitem.path});
  },
  load(context, filePath, item) {
    const name = item['meta3:name'];
    const permission = item['app:permission'];
    const targetValueReference = item['app:target'];
    const targetRi = Library.resolveReference(filePath, targetValueReference);
    const appPath = context.riValue.path;
    if (!permission ||context.permissions && context.permissions.includes(permission)) {
      const items = Library.ensureArray(item['app:item']);
      const itemsDefinitions = Library.concatArrays(items.map(item => {
        return NavigationBarItem.prototype.load(context, filePath, item);
      }));
        const targetPath = Library.parseRi(targetRi).path;
        const active = Library.isSubpath(targetPath, appPath);
        return [{
          active,
          target: targetRi,
          name: name,
          items: itemsDefinitions
        }]
    } else {
      return [];
    }
  },
  render: function() {
    const component = this;
    const props = component.props;
    let text = props.name;
    let subitems = props.items;
    if (subitems.length > 0 ) {
      let dropdownItems = subitems.map((subitem, i) => {
        return {text: subitem.name, key:  i, path: subitem.target}
      });
      let className = props.active ? 'active' : '';
      return <DropdownNavigationBarItem
        text={text}
        className={className}
        items={dropdownItems}
        active={props.active}
        handleClick={component.handleSelectSubitem}/>
    } else {
      let className = 'item';
      if (props.active) {
        className += ' active';
      }
      return (<div className={className} onClick={component.handleClick}>
        <span>
          {text}
        </span>
      </div>)
    }
  }
})

export {NavigationBarItem};
