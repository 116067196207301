import {Library} from "../../../../app/src/library.js"
import {referenceFragment, ensureArray, uuid, concatArrays} from "../../../../app/src/lib.js"

const getNodesWithoutElements = (nodes, elements) => {
  return nodes.filter(node => !elements.find(element => node['rbs:id'] === referenceFragment(element['mm:object'])))
}

const getElementsWithoutNodes = (elements, nodes) => {
  return elements.filter(element => !nodes.find(node => node['rbs:id'] === referenceFragment(element['mm:object'])))
}

const getNodePropertiesToCheck = node => {
  return ensureArray(node['meta3:possibility']).filter(possibility => possibility['meta3:modeled'] === 'true').map(possibility => possibility['meta3:property'])
}

const getNodeReferencesToCheck = node => {
  return concatArrays(getNodePropertiesToCheck(node).map(property => {
    const targetNodesIds = ensureArray(node[property]).map(reference => referenceFragment(reference))
    return targetNodesIds.map(targetNodeId => {
      return {
        property,
        sourceNode: node,
        targetNodeId
      }
    })
  }))
}

const getNodesReferencesToCheck = nodes => {
  return concatArrays(nodes.map(node => {
    return getNodeReferencesToCheck(node)
  }))
}

const getElementByObjectId = (elements, objectId) => {
  return elements.find(element => referenceFragment(element['mm:object']) === objectId)
}

const findLink = (links, sourceElementId, property, targetElementId) => {
  return links.find(link => link['mm:source']['mm:id'] === sourceElementId && link['mm:property'] === property && link['mm:target']['mm:id'] === targetElementId)
}

const getReferenceDescriptionLink = (elements, links, referenceDescription) => {
  const sourceNodeId = referenceDescription.sourceNode['rbs:id']
  const targetNodeId = referenceDescription.targetNodeId
  const sourceElement = getElementByObjectId(elements, sourceNodeId)
  const targetElement = getElementByObjectId(elements, targetNodeId)
  if (sourceElement && targetElement) {
    return findLink(links, sourceElement['rbs:id'], referenceDescription.property, targetElement['rbs:id'])
  }
}

const getReferencesWithoutLinks = (elements, links, nodes) => {
  return getNodesReferencesToCheck(nodes).filter(referenceDescription => !getReferenceDescriptionLink(elements, links, referenceDescription))
}

const getElementById = (elements, id) => {
  return elements.find(element => element['rbs:id'] === id)
}

const getNodeById = (nodes, id) => {
  return nodes.find(node => node['rbs:id'] === id)
}

const getElementIdNode = (nodes, elements, elementId) => {
  const element = getElementById(elements, elementId)
  if (element) {
    return getNodeById(nodes, referenceFragment(element['mm:object']))
  }
}

const getSourceNode = (nodes, elements, link) => {
  const sourceElementId = link['mm:source']['mm:id']
  return getElementIdNode(nodes, elements, sourceElementId)
}

const getTargetNode = (nodes, elements, link) => {
  const targetElementId = link['mm:target']['mm:id']
  return getElementIdNode(nodes, elements, targetElementId)
}

const getNodePossibility = (node, property) => {
  const possibilities = ensureArray(node['meta3:possibility'])
  return possibilities.find(possibility => possibility['meta3:property'] === property)
}

const isLinkValid = (nodes, elements, link) => {
  const source = link['mm:source']
  const sourceNode = getSourceNode(nodes, elements, link)
  if (!sourceNode) {
    return false
  }
  const property = link['mm:property']
  const possibility = getNodePossibility(sourceNode, property)
  if (possibility) {
    if (source['mm:port'] !== possibility['mm:port']) {
      return false
    }
    if (source['mm:selector'] !== '.link-target' && source['mm:selector'] !== possibility['mm:selector']) {
      return false
    }
  }
  const target = link['mm:target']
  let targetNode = getTargetNode(nodes, elements, link)
  if (!targetNode) {
    return false
  }
  const linkTarget = targetNode['mm:linkTarget']
  if (linkTarget) {
    if (target['mm:port'] !== linkTarget['mm:port']) {
      return false
    }
    if (source['mm:selector'] !== '.link-target' && target['mm:selector'] !== linkTarget['mm:selector']) {
      return false
    }

  }
  const targetNodeId = targetNode['rbs:id']
  const targetsReferences = ensureArray(sourceNode[property])
  return targetsReferences.find(reference => referenceFragment(reference) === targetNodeId)
}

const getInvalidLinks = (nodes, elements, links) => {
  return links.filter(link => !isLinkValid(nodes, elements, link))
}

const fixElements = diagram => {
  const nodes = ensureArray(diagram['chub:node'])
  const elements = ensureArray(diagram['mm:element'])
  const nodesWithoutElements = getNodesWithoutElements(nodes, elements)
  const elementsWithoutNodes = getElementsWithoutNodes(elements, nodes)
  const elementsFix = nodesWithoutElements.map(node => {
    return {
      'rbs:id': uuid(),
      'mm:object': {
        'rbs:ref': '#' + node['rbs:id']
      },
      "mm:position": {
        "rbs:id": uuid(),
        "mm:x": "100",
        "mm:y": "100"
      },
      "mm:size": {
        "rbs:id": uuid(),
        "mm:height": "40",
        "mm:width": "40"
      }
    }
  }).concat(elementsWithoutNodes.map(element => {
    return {
      'rbs:id': element['rbs:id']
    }
  }))
  const elementsFixUpdate = {
    'rbs:id': diagram['rbs:id'],
    'mm:element': elementsFix
  }
  const updated =  Library.complexUpdate(diagram, elementsFixUpdate)
  return updated[0]
}

const fixLinks = diagram => {
  const nodes = ensureArray(diagram['chub:node'])
  const elements = ensureArray(diagram['mm:element'])
  const links = ensureArray(diagram['mm:link'])
  let linksUpdates = []
  const invalidLinks = getInvalidLinks(nodes, elements, links)
  linksUpdates = linksUpdates.concat(invalidLinks.map(link => {
    return {'rbs:id': link['rbs:id']}
  }))
  const referencesWithoutLinks = getReferencesWithoutLinks(elements, links, nodes) 
  linksUpdates = linksUpdates.concat(referencesWithoutLinks.map(reference => {
    const sourceNode = reference.sourceNode
    const targetNode = getNodeById(nodes, reference.targetNodeId)
    const property = reference.property
    const possibility = getNodePossibility(sourceNode, property)
    const sourceNodeId = sourceNode['rbs:id']
    const targetNodeId = targetNode['rbs:id']
    const sourceElement = getElementByObjectId(elements, sourceNodeId)
    const targetElement = getElementByObjectId(elements, targetNodeId)
    const sourceDescription = {
      'rbs:id': uuid(),
      'mm:id': sourceElement['rbs:id'],
    }
    if (possibility['mm:selector']) {
      sourceDescription['mm:selector'] = possibility['mm:selector']
    } else {
      sourceDescription['mm:selector'] = '.link-target'
    }

    if (possibility['mm:port']) {
        sourceDescription['mm:port'] = possibility['mm:port']
    }
    let targetDescription = {
      'rbs:id': uuid(),
      'mm:id': targetElement['rbs:id'],
    }
    if (targetNode['mm:linkTarget']) {
      targetDescription = {...targetDescription, ...targetNode['mm:linkTarget']}
    } else {
      targetDescription['mm:selector'] = '.link-target'
    }
    return {
      'rbs:id': uuid(),
      'mm:property': property,
      'mm:source': sourceDescription,
      'mm:target': targetDescription
    }
  })
  )
  const linksFixUpdate = {
    'rbs:id': diagram['rbs:id'],
    'mm:link': linksUpdates
  }
  const updated =  Library.complexUpdate(diagram, linksFixUpdate)
  return updated[0]
}

export const fixDiagram = file => {
  const diagram = file['meta3:properties']
  const fixedElementsDiagram = fixElements(diagram)
  const fixedDiagram = fixLinks(fixedElementsDiagram)
  return {
    ...file,
    'meta3:properties': fixedDiagram
  }
}
