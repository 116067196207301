import React from "../../miniclient/in/client/node_modules/react";
import {Library} from "./library.js";
import ReactDOM from "../../miniclient/in/client/node_modules/react-dom";
import {Button, Dropdown } from 'semantic-ui-react';
import {ApplicationUtils} from './application-utils.js';

class VersionDropdown extends React.Component {
  constructor(props) {
    super(props);
    let dropdown = this;

    // This binding is necessary to make `this` work in the callback
    dropdown.handleItemClick = dropdown.handleItemClick.bind(dropdown);
    dropdown.handleVersionClick = dropdown.handleVersionClick.bind(dropdown);
  }

  async load(context) {
    const result = await context.storage.getVersioningInfo(context.versioningRoot['meta3:path'])
    const branches = Library.ensureArray(result['rbs:branches'])
    const isInVersionControl = Library.isSubpath('version-control/', context.riValue.path)
    return {branchesNames: branches, isInVersionControl, versioningRootPath: context.versioningRoot['meta3:path']}
  }

  handleItemClick(event) {
    const dropdown = this;
    const version = event.target.dataset.value;
    const versionParam = version == 'master' ? '' : ';v=' + version;
    let pathArray = dropdown.props.versioningRootPath.split('/');
    pathArray.length = 3;
    const model = pathArray[1];
    const index = model.indexOf(';v=');
    if (index != -1) {
      pathArray[1] = model.substring(0, index) + versionParam;
    } else {
      pathArray[1] = model + versionParam;
    }
    dropdown.props.onUpdate({type: 'routeTo', ri: pathArray.join('/')})
  }

  handleVersionClick() {
    const component = this;
    const props = component.props;
    if (props.isInVersionControl) {
      props.onUpdate({type: 'routeTo', ri: props.versioningRootPath});
    } else {
      props.onUpdate({type: 'routeTo', ri : 'version-control/' + props.versioningRootPath});
    }
  }

  render() {
    const component = this;
    const props = component.props;
    const options = props.branchesNames.map(function(brancheName) {
      return { key: brancheName, text: brancheName, value: brancheName };
    });
    const version = Library.pathVersion(props.versioningRootPath);

    return (
      <div className="meta row version">
        <div onClick={component.handleVersionClick} className="text">version</div>
        <Dropdown options={options} floating inline className='icon' direction='left' text={version} defaultValue={version} />
      </div>
    );
  }
}

export {VersionDropdown};
