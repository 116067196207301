import {MC} from './MC.js';

const MCHistory = {

  historyArr: [],
  logArr: [],
  T_ERROR: 'ERROR',
  T_EXCEPTION: 'EXCEPTION',
  T_WARNING: 'WARNING',
  T_INFO: 'INFO',

  log: function(type, text, debug) {
    if (MC.isNull(type) || MC.isNull(text)) {
      console.error("Bad log function calling!");
    }
    var record = {};
    record.time = new Date();
    record.type = type;
    record.text = text;
    this.logArr.push(record);
    if (debug) {
      if (type == this.T_ERROR) {
        console.error(this.T_ERROR + ' - ' + record.time.toISOString() + ': ' + text);
      } else if (type == this.T_EXCEPTION) {
        console.error(this.T_EXCEPTION + ' - ' + record.time.toISOString() + ': ' + text);
      } else if (type == this.T_WARNING) {
        console.warn(this.T_WARNING + ' - ' + record.time.toISOString() + ': ' + text);
      } else {
        console.log(this.T_INFO + ' - ' + record.time.toISOString() + ': ' + text);
      }
    }
  },

  logEnv: function(env, debug) {
    if (debug) {
      console.log('ENVIRONMENT:', env);
    }
  },

  getLog: function() {
    return this.logArr;
  },

  history: function(flowObject, action, text, logObject) {
    if (MC.isNull(flowObject)) {
      console.error("Bad log function calling!");
    }
    var flow = flowObject.flow;
    var record = {};
    record.path = MCHistory.getFlowPath(flowObject);
    record.time = new Date();
    if (flow) {
      record.flow = flow.id;
      record.flowKind = flow.kind;
    }
    if (!MC.isNull(action)) {
      record.action = action.code;
      record.kind = action.kind;
    } else if (flow) {
      record.kind = flow.kind;
    }
    record.text = text;
    if (!MC.isNull(logObject) && MC.isPlainObject(logObject)) {
      record.logObject = {};
      for (root in logObject) {
        if (!MC.isNull(logObject[root])) { // filter null objects
          record.logObject[root] = {};
          MC.extend(true, record.logObject[root], logObject[root]);
        }
      }
    }
    this.historyArr.push(record);
    if (flowObject.debug) {
      console.log((record.path ? record.path + ' / ' : '') + record.flow + (record.action ? ' / ' + record.action : '')  + ' (' + record.kind + ') - ' + record.time.toISOString() + (record.text ? ' - ' + record.text : ''));
      if (!MC.isNull(record.logObject)) {
        for (var root in record.logObject) {
          console.log('       ' + root + ':', record.logObject[root]);
        }
      }
    }
  },

  getHistory: function() {
    return this.historyArr;
  },

  clearLog: function() {
    this.logArr = [];
  },

  clearHistory: function() {
    this.historyArr = [];
  },

  clear: function() {
    MCHistory.clearLog();
    MCHistory.clearHistory();
    console.clear();
  },

  getFlowPath: function(flow) {
    if (flow.parentFlow) {
      var result = '';
      var sep = '';
      while (flow.parentFlow) {
        result =  flow.parentFlow.flow.id + sep + result;
        sep = ' / ';
        flow = flow.parentFlow;
      }
      return result;
    } else {
      return null;
    }
  }

};

export {MCHistory};