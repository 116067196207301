import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import {Library} from '../library.js';
import {Checkbox} from '../widget/checkbox.jsx';

const SimpleBooleanInspector = createReactClass({
  handleValueChange(value) {
    let component = this;
    component.props.onValueUpdate(String(value));
  },
  load: function(context, property, value) {
    const title = Library.propertyTitle(property);
    return {value: Library.boolean(value), title: title, hasTitle: true}
  },
  render: function() {
    const component = this;
    const props = component.props;
    return <Checkbox
              value={props.value}
              title={props.title}
              onValueChange={component.handleValueChange}
              readOnly={props.readOnly}/>
  }
})

export {SimpleBooleanInspector};
