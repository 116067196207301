import {Library} from "../../../../app/src/library.js"

const findNode = (referenceValue, nodes) => {
  const id = Library.referenceFragment(referenceValue['rbs:ref'])
  const node = nodes.find(node => node['rbs:id'] === id)
  if (node) {
    return [node]
  } else {
    return []
  }
}

const neighbours = (node, nodes) => {
  const nextNode = node['chub:nextNode']
  if (nextNode) {
    return findNode(nextNode['meta3:target'], nodes)
  }
  const splits = Library.ensureArray(node['chub:split'])
  return Library.concatArrays(splits.map(split => {
    return findNode(split['meta3:target'], nodes)
  }))
}

const removeNode = (nodes, node) => {
  return nodes.filter(aNode => aNode['rbs:id'] !== node['rbs:id'])
}

const nodeConceptName = node => {
  return node['meta3:instanceOf']['rbs:ref'].split('/').slice(-1)[0]
}

const breadthFirstSearch = (queue, nodes, endNodeConceptName, result = [], endNode) => {
  if (queue.length === 0) {
    return [result, nodes, endNode]
  }
  const node = queue[0]
  queue = queue.slice(1)

  const conceptName = nodeConceptName(node)
  
  if (result.find(resultNode => resultNode['rbs:id'] === node['rbs:id'])) {
    // do nothing
  } else if (conceptName === 'processing-split-based-on-data-input' || conceptName === 'processing-ab-split') {
    const [splitResult, restNodes, endNode] = breadthFirstSearch(neighbours(node, nodes), removeNode(nodes, node), 'join-of-processing-and-data-structure', [node])
    nodes = restNodes
    result = result.concat(splitResult)
    if (endNode) {
      queue = neighbours(endNode, nodes).concat(queue)
      result = result.concat([endNode])
    }
  } else {
    if (endNodeConceptName && conceptName === endNodeConceptName) {
      // do nothing
      endNode = node
    } else {
      result = result.concat([node])
      queue = queue.concat(neighbours(node, nodes))
    }
    nodes = removeNode(nodes, node)
  }
  return breadthFirstSearch(queue, nodes, endNodeConceptName, result, endNode)
}

export const flowSortNodes = file => {
  const flow = file['meta3:properties']
  const nodes = Library.ensureArray(flow['chub:node'])
  const start = nodes.find(node => node['meta3:instanceOf']['rbs:ref'] === '/commhub/concept/flow-node/start')
  let sorted, unachievable
  if (start) {
    [sorted, unachievable] = breadthFirstSearch([start], removeNode(nodes, start))
  } else {
    unachievable = nodes
    sorted = []
  }
  

  const nodesUpdate = sorted.map((node, index) => {
    return {'rbs:id': node['rbs:id'], 'chub:position': String(index), 'chub:runAsync': 'true'}
  }).concat(unachievable.map(node => {
    return {'rbs:id': node['rbs:id'], 'chub:runAsync': 'false', 'chub:position': ''}
  }))
  const sortUpdate = {
    'chub:node': nodesUpdate
  }
  const updated =  Library.complexUpdate(file['meta3:properties'], sortUpdate)
  const result =  {...file, 'meta3:properties': updated[0]}
  return result
}