import React from "../../miniclient/in/client/node_modules/react";
import {Library} from "./library.js";
import {Meta} from "./meta.js";
import "./funnel.jsx";
import {ApplicationUtils} from './application-utils.js';
import './basic.css';
import '../../miniclient/in/client/src/modeler/meta.css';
import {Login} from './login.jsx';
import {Loading} from './loading.jsx';
import {ErrorPage} from "./error-page.jsx";


const addLeaveCheck = Library.callOnce((storage) => ApplicationUtils.addLeaveCheck(storage))


class Controller extends React.Component {
  constructor(props) {
    super(props)
    const self = this
    const storage = Meta.storage()
    if (props.theoryPath) {
      storage.setTheoryPath('appmodeler;v=5/theory/')
    }
    
    this.state = {loading: false, error: false, storage}
    
    this.handleUpdate = this.handleUpdate.bind(this)
    this.actionPromise = Promise.resolve()
    this.actionLevel = 0
    this.loadId = 0
    ApplicationUtils.setFlowConfig(props.flowConfig)
    ApplicationUtils.setUserAndPermissionOperation(props.userAndPermissionsGetOperation)

    window.onpopstate = history.onpushstate = () => {
      self.display()
    };
  }
  startLoadingData() {
    const self = this
    if (self.dataLoading) {
      return;
    }
    self.dataLoading = true;
    setTimeout(() => {
      if (self.dataLoading) {
        self.setState({loading: true, error: false, errorMessage: undefined});
      }
    }, 500);
  }
  async display() {
    const self = this;
    self.startLoadingData()
    try {
      self.loadId = (self.loadId + 1) % Number.MAX_SAFE_INTEGER
      const loadId = self.loadId
      const data = await self.load();
      if (loadId === self.loadId) {
        self.setState({data, loading: false, error: false, errorMessage: undefined});
      }
      
    } catch (error) {
      self.setState({loading: false, error: true, errorMessage: error.message});
      throw error;
    } finally {
      self.dataLoading = false;
    }
  }
  async load() {
    const ri = Library.locationRi()
    const riValue = Library.parseRi(ri)
    const controler = this
    const storage = this.getStorage()
    addLeaveCheck(storage)
    let context = {ri, controler, riValue, storage, debug: riValue.query['jsi.debug'] === 'true'}

    if (!storage.filesNeedStore()) {
      storage.deleteUserData()
    }
    
    return await this.loadRi(context, this.props)
  }
  performeAction(action) {
    const self = this
    self.startLoadingData()
    self.actionLevel++
    console.log('performe action', self.actionLevel)
    self.actionPromise = self.actionPromise.then(async () => {
      let ri;
      try {
        ri = await action();
      } catch (error) {
        self.setState({loading: false, error: true, errorMessage: error.message});
        throw error;
      } 
      
      if (typeof ri === 'undefined') {
        ri = self.getRi()
      }
      self.actionLevel--
      console.log('finish action', self.actionLevel)
      if (self.actionLevel === 0 && self.getStorage().batchLevel === 0) {
        self.dataLoading = false
        if (typeof ri === 'string') {
          self.routeTo(ri)
        } else {
          self.setState({loading: false});
        }
      }
      
    })
  }
  async loadRi(context, props) {
    context = await ApplicationUtils.loadRi(context)
    if (context.user) {
      const application = await props.applicationComponent.prototype.load(context)
      return {application, context}
    } else {
      return {context}
    }
  }
  componentDidMount() {
    const component = this;
    
  }

  handleReload(actionPromise) {
    const self = this
    self.performeAction(() => actionPromise)
  }

  async handleUpdate(update) {
    const self = this
    const data = self.state.data
    const context = data.context
    const storage = context.storage
    console.log('update', update);
    if (update.type === 'save') {
      self.performeAction(async () => {
        await storage.storeChanges()
        storage.emptyStacks()
      })
    } else if (update.type === 'undo') {
      self.performeAction(() => storage.undo());
    } else if (update.type === 'reload') {
      self.performeAction(() => undefined);
    } else if (update.type === 'redo') {
      self.performeAction(() => storage.redo());
    } else if (update.type === 'routeTo') {
      self.performeAction(() => update.ri);
    } else if (update.type === 'fileUpdate') {
      return self.handleFileUpdate(update.path, update.fileUpdate, update.doNotReload);
    } else if (update.type === 'fileReplace') {
      return self.handleFileReplace(update.path, update.properties);
    } else if (update.type === 'logout') {
      self.performeAction(() => self.handleLogout())
    } else if (update.type === 'login') {
      self.performeAction(() => ApplicationUtils.deleteCache())
    } else if (update.type === 'initBatchCommand') {
      self.performeAction(() =>  {
        storage.initBatchCommand()
      })
    } else if (update.type === 'storeBatchCommand') {
      self.performeAction(async () => {
        await storage.storeBatchCommand()
      })
    }
  }

  async handleLogout() {
    const self = this;
    const storage = self.getStorage();
    let allowToLogout = !storage.filesNeedStore();
    if (!allowToLogout) {
      allowToLogout = window.confirm('Do you really want to logout? (Unsaved changes will be lost.)');
    }
    if (allowToLogout) {
      self.performeAction(async () => {
        storage.deleteUserData();
        await ApplicationUtils.logout();
      })
    }
  }
  async routeTo(ri) {
    const self = this;
    const data = self.state.data
    const context = data.context
    self.startLoadingData()
    let doNotLeavePath = context.doNotLeavePath;
    let allowToLeave = true;
    const riValue = Library.parseRi(ri);
    if (doNotLeavePath) {
        let leaving
        if (context.allowSubpaths) {
          leaving = !riValue.path.startsWith(doNotLeavePath)
        } else {
          leaving = riValue.path !== doNotLeavePath
        }
        if (leaving) {
          allowToLeave = window.confirm('Do you really want to leave? (Unsaved changes will be lost.)');
          if (allowToLeave) {
            context.storage.deleteUserData();
            context.storage.emptyStacks();
          }
        }
    }
    if (allowToLeave) {
      ri = ApplicationUtils.enritchRi(ri, context.riValue);
      Library.setHref(ri)
      return self.display()
    }
  }

  handleReloadPath(actionPromise) {
    const self = this
    self.performeAction(async () => {
      await actionPromise
    })
  }
  getStorage() {
    return this.state.storage
  }
  getContext() {
    return this.state.data.context
  }
  getRi() {
    return this.getContext().ri
  }
  handleFileUpdate(path, update) {
    const self = this
    self.performeAction(async () => {
      await self.getStorage().fileUpdate(path, update)
    })
  }
  handleFileReplace(path, properties) {
    const self = this;
    self.performeAction(async () => {
      await self.getStorage().filePropertiesReplace(path, properties)
    })
  }
  componentDidMount() {
    this.display()
  }

  render() {
    const self = this;
    const state = self.state;
    const data = state.data;
    const props = self.props;
    if (!data && state.loading) {
      return <Loading/>;
    } 
    
    if (!data && state.error) {
      return <ErrorPage debug={context && context.debug} error={state.errorMessage}/>;
    }
    if (!data) {
      return <div/>;
    }

    const context = data.context;
    if (context.user) {
      const Application = props.applicationComponent
      return <Application {...data.application} onUpdate={self.handleUpdate} loading={state.loading} error={state.error}/>;
    } 
    
    return <Login onUpdate={self.handleUpdate}/>;
  }
}

export {Controller};
