import React from "../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "./library.js";
import {NavigationBarItem} from './navigation-bar-item.jsx';



const NavigationBar = createReactClass({
  load: function(context, menu) {
    const items = Library.ensureArray(menu['meta3:properties']['app:item']);
    const promises = items.map(item => {
      return NavigationBarItem.prototype.load(context, menu['meta3:path'], item)
    });
    return Promise.all(promises).then(itemsArray => {
      return {items: Library.concatArrays(itemsArray)};
    });
  },
  render: function () {
    const component = this;
    const props = component.props;
    let items = props.items.map((item, i) => {
      return (<NavigationBarItem {...item}
              key={i}
              onUpdate={component.props.onUpdate}/>)
    })
    const className = 'meta row navigation-bar ' + props.className;
    return (
      <div className={className}>
        {items}
      </div>
    )
  }
});

export {NavigationBar};
