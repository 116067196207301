import React from "../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Button} from './widget/button.jsx';

const UndoRedoButtons = createReactClass({
  load: function(context) {
    return {readOnly: context.readOnly, storage: context.storage};
  },
  render: function () {
    const component = this
    const props = component.props
    const storage = props.storage
    const isUndoDisabled = props.readOnly || !storage.hasUndo();
    const isRedoDisabled = props.readOnly || !storage.hasRedo();
    return (
      <div className="ui icon buttons meta menu-item">
        <Button className="flat divider-button"
          icon="undo"
          onClick={() => props.onUpdate({type: 'undo'})}
          disabled={isUndoDisabled}
          help="Odvolat akci"/>
        <Button className="flat"
          icon="horizontally flipped undo"
          onClick={() => props.onUpdate({type: 'redo'})}
          disabled={isRedoDisabled}
          help="Opakovat akci"/>
      </div>
    )
   }
});

export {UndoRedoButtons};
