import joint from './rappid/rappid.js';

joint.ui.Modal = joint.mvc.View.extend({
    options: {
        padding: 20,
    },
    afterRender: function() {

    },
    render: function() {
        var self = this;
        self.event = self.options.event;

        // disable page scrolling
        $("paper" ).css( "overflow", "hidden" );

        // put an overlay div in front of screen
        var $overlay = $('<div/>', {'class': 'overlay'});
        $("body").append($overlay);
        $overlay.on("click",function () {self.cancel()});
        $overlay.contextmenu(false);


        // puts element in front
        self.$el.css("z-index", 4);
        self.$el.on("click",false);

        $("body").append(self.$el);

        self.renderContent();

        self.$el.css({
            left: self.event.pageX,
            top: self.event.pageY
        });

        this.trigger("modal:create",self);
        self.afterRender();
        return self;
    },
    cancel: function() {
        this.trigger('modal:canceled');
        this.remove();
    },
    onRemove: function() {
        $(".overlay").remove(); // remove the overlay div
        $("paper" ).css( "overflow", "auto" ); // enable scrolling
        this.trigger("modal:remove");
    }
});

const Modal = joint.ui.Modal

export {Modal};
