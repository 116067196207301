import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import {Library} from '../../../app/src/library.js';
import {ensureArray, uuid, referenceFragment} from '../../../app/src/lib.js'
import joint from './rappid/rappid.js';
import {MC} from '../../../miniclient/in/client/src/client/MC.js';
import {MUtils} from './metamodeler-utils.js';
import ReactDOM from "../../../miniclient/in/client/node_modules/react-dom";
import ContextMenu from './context-menu.js';
import {OpenDialog} from './dialog.js';

import './rappid/rappid.css';
import './metamodeler.css';

joint.shapes.metamodeler = {};

joint.shapes.metamodeler.Element = joint.shapes.basic.Generic.extend({});

joint.shapes.metamodeler.Link = joint.dia.Link.extend({});

const Metamodeler = createReactClass({
  componentWillMount: function() {
    const component = this;
    const props = component.props;
    const graph = new joint.dia.Graph;
    component.graph = graph;
    component.graph.modeler = component;

    component.managerPromise = Promise.resolve();


    // command manager
    component.commandManager = new joint.dia.CommandManager({ graph: component.graph, cmdBeforeAdd: component.cmdBeforeAdd, revertOptionsList: function(attrValue, attrName) { return false; } });

    /*joint.dia.CommandManager.prototype.addCommand = function(cmdName, cell, graph, options) {
      modeler.addCommand(this, cmdName, cell, graph, options);
    };*/

    // validator
    component.validator = new joint.dia.Validator({ commandManager: component.commandManager, cancelInvalid: true });


    function isLink(err, command, next) {
      const cell = component.graph.getCell(command.data.id);
      if (cell && cell.get('temporary')) {
        return;
      }
      if (component.withoutChanges) {
        return;
      }
      //return;
      if (cell.isLink()) {
        return next(err);
      }
      // otherwise stop validating (don't call next validation function)
    }
    function connectivity(err, command, next) {
      //return next(err);
      const link = component.graph.getCell(command.data.id);

      const sourceId = link.get('source').id;
      const targetId = link.get('target').id;

      // source and target are both cells
      if (sourceId && targetId) {
        const source = component.graph.getCell(sourceId);
        const target = component.graph.getCell(targetId);
        let linkValue;
        try {
          //linkValue = 
        } catch (error) {
          return next(err);
        }

        /*const property = linkValue.property;
        const sourceObject = component.getElementObject(source);
        const targetObject = component.getElementObject(target);
*/
        // can not be asynchronous
        /*component.isLinkPossible(property, sourceObject, targetObject).then(isLinkPossible => {
          if (!isLinkPossible) {
            return next('Link is not possible.');
          }
        })*/

        return next(err);
      } else {
        return next('Links can\'t be pinned to the paper');
      }
    };
    component.validator.validate('change:source change:target add', isLink, connectivity);
    //modeler.definition = new Definition(modeler.props.definition);

    /*
    graph.on('change:source change:target', function(link) {
      if (link.get('temporary') === true) {
        return;
      }
      if (link.get('linkValueCreated')) {
        return;
      }
      if (link.get('type') !== 'link') {
        return;
      }
      var sourceId = link.get('source').id;
      var targetId = link.get('target').id;
      if (!targetId) {
        return false;
      }
      return;
      var sourceCell = graph.getCell(sourceId);
      var sourcePort = null;
      if (sourceCell.getPorts && sourceCell.getPorts().length > 0) {
        sourcePort = 'ko';
        var propertyName = 'chub:nextNodeTimeout';
        if (link.get('source').selector.startsWith('g:nth-child(3)')) {
          propertyName = 'chub:nextNode';
          sourcePort = 'ok';
        }
        var targetPort = null;
        var targetCell = graph.getCell(targetId);
        if (targetCell.getPorts && targetCell.getPorts().length > 0) {
          targetPort = 'in';
        }
        link.set('linkValueCreated', true);
        component.createLinkValue(propertyName, link, sourcePort, targetPort);
      }
      return false;
    });*/
  },
  componentDidMount: function() {
    const component = this;
    const props = component.props;
    const param = props.data.param;
    component.initialize();
    if (param['@concepts']) {
      const concepts = Library.ensureArray(param['@concepts']['rbs:item*']).map(item => item['meta3:properties']);
      concepts.forEach(concept => {
         const elementVisual = concept['mm:elementVisual'];
         if (elementVisual) {
           const visual = Library.toSimpleJSON(elementVisual);
           MUtils.loadVisual(visual, undefined);
         }
      })
    }
    if (param['@properties']) {
      const properties = Library.ensureArray(param['@properties']['meta3:property']);
      properties.forEach(property => {
        const visual = property['mm:elementVisual'];
        if (visual) {
          MUtils.loadVisual(Library.toSimpleJSON(visual), property);
        }
      })
    }
    component.updateGraph(param['@view'], param['@objectsFiles'])

    component.paperScroller.scroll(0, 0);
    component.highlightObject(props.data.param['@selectedModelId']);
  },
  initialize: function() {
    const self = this;
    const domNode = ReactDOM.findDOMNode(self)
    const paper = domNode.childNodes[0]
    const view = self.getView()

    //var $modeler = $(ReactDOM.findDOMNode(self));
    //var $paper = $modeler.find(".paper");
    let readOnly = self.props.data.param['@readonly'];
    let interactive = {vertexAdd: false, vertexRemove: false};
    if (readOnly) {
      interactive = false;
    }
    let paperConfig = {
      model: self.graph,
      gridSize: 1,
      interactive: interactive,
      gridSize: 10,
      //drawGrid: { name: 'fixedDot', args: { color: 'silver' }},
      defaultLink: new joint.dia.Link({
        attrs: {
          '.marker-target': { d: 'M 8 0 L 0 4 L 8 8 M 0 4 L 8 4' , fill: 'black' },
          '.connection': { stroke: 'black', 'stroke-width': 1.25 }
        }
      }),
      validateConnection: function(cellViewS, magnetS, cellViewT, magnetT, end, linkView) {
        console.log('validate connection', cellViewS, magnetS, cellViewT, magnetT, end, linkView)
        if (cellViewS.model.isLink() || cellViewT.model.isLink()) {
          return false;
        }
        /*if (cellViewS.model.getPorts && cellViewS.model.getPorts().length > 0) { // source has ports
          if (!magnetS) {
            return false;
          }
          var properties = ['chub:nextActionRelationship', 'chub:nextActionRelationshipX'];
          if (properties.length == 0) {
            return false;
          } else {
            var validPort = false;
            for (let property of properties) {
              var name = property;
              if (name === 'chub:nextActionRelationship' && magnetS.classList.contains('ok')) {
                validPort = true;
              } else if (name === 'chub:nextActionRelationshipX' && magnetS.classList.contains('ko')) {
                validPort = true;
              }
            }
            if (!validPort) {
              return false;
            }
          }
        }*/
        if (cellViewT.model.getPorts && cellViewT.model.getPorts().length > 0) { // target has ports
          if (!magnetT) {
            return false;
          } else if (magnetT.getAttribute('magnet') !== 'passive') {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      validateMagnet: function(cellView, magnet) {
        return magnet.getAttribute('magnet') !== 'passive'
      }
    };
    if (view['mm:router']) {
      paperConfig.defaultRouter = {name: view['mm:router']}
    }
    if (view['mm:connector']) {
      paperConfig.defaultConnector = Library.toSimpleJSON(view['mm:connector'])
    }
    self.paper = new joint.dia.Paper(paperConfig)

    if (self.props.onPaperCreated) {
      self.props.onPaperCreated(self.paper)
    }

    self.paperScroller = new joint.ui.PaperScroller({
        autoResizePaper: true,
        padding: 0,
        paper: self.paper,
        cursor: 'grab',
        baseWidth: 600,
        baseHeight: 500,
        contentOptions: {
            minWidth: 800,
            minHeight: 1200,
            allowNewOrigin: 'positive'
        }
    });

    /*new joint.ui.Tooltip({
        target: '[data-tooltip]',
        direction: 'auto',
        padding: 10
    });*/


    if (self.props.zoom) {
      self.paperScroller.zoom(self.props.zoom, {absolute: true});
    }

    /*modeler.paperScroller.$el.css({
        width: 1200,
        height: 1200
    });*/

    paper.appendChild(self.paperScroller.render().el)
    //$paper.html(self.paperScroller.render().el);

    self.elementCollection = new Backbone.Collection;
    self.selection = new joint.ui.Selection({
        paper: self.paper,
        collection: self.elementCollection,
    });

    self.selection.removeHandle('rotate');
    self.selection.removeHandle('move');

    // Snaplines
    self.snaplines = new joint.ui.Snaplines({ paper: this.paper });
    self.snaplines.startListening();

    self.initListeners();

  },
  componentDidUpdate: function(prevProps) {
    const self = this
    const props = self.props
    const param = props.data.param
    const prevParam = prevProps.data.param
    if (!Library.equals(param['@view'], prevParam['@view'])|| !Library.equals(param['@objectsFiles'], prevParam['@objectsFiles'])) {
      self.updateGraph(param['@view'], param['@objectsFiles'], prevParam['@view'], prevParam['@objectsFiles'])
    }
    if (props.zoom != prevProps.zoom) {
      self.paperScroller.zoom(props.zoom, {absolute: true});
    }
    if (props.grid) {
      self.paper.drawGrid(props.grid)
    }
    

    if (props.data.param['@selectedModelId'] != prevProps.data.param['@selectedModelId']) {
      self.highlightObject(props.data.param['@selectedModelId']);
    }
  },
  highlightObject: function(selectedModelId) {
    const component = this;
    const props = component.props;
    component.unhighlightAll();
    const modelId = selectedModelId;
    const view = component.getView()

    const cell = Library.ensureArray(view[view['mm:elementsProperty']]).find(element => {
      const objectReferenceValue = element['mm:object'];
      if (!objectReferenceValue) {
        return element['rbs:id'] === selectedModelId
      }
      if (Library.isReferenceValueLocal(objectReferenceValue)) {
        const objectId = Library.referenceFragment(objectReferenceValue['rbs:ref'])
        return objectId === modelId;
      } else {
        const objectPath = Library.riResolve(component.props.data.param['@viewPath'], objectReferenceValue['rbs:ref']);
        return selectedModelId === Library.pathFileName(objectPath)
      }
    });
    if (cell) {
      const element = component.graph.getCell(cell['rbs:id']);
      if (element) {
        const elementView = element.findView(component.paper);
        elementView.highlight();
      }
    }
  },
  load: async function(context) {
    const storage = context.storage
    const viewFile = context.view
    const viewPath = viewFile['meta3:path']
    const view = viewFile['meta3:properties']  
    const modelPath = context.model['meta3:path']
    const objectsFiles = await MUtils.viewObjectsFiles(storage, viewPath, view)
    const conceptsFiles = await MUtils.viewConceptsFiles(storage, viewPath, view)
    MUtils.loadConceptsFilesVisuals(conceptsFiles)
    MUtils.loadPropertiesVisauls()
    const param = {
      '@readonly': context.readOnly, 
      '@view': view, 
      '@modelPath': modelPath,
      '@conceptsFiles': conceptsFiles, 
      '@objectsFiles': objectsFiles,
      '@viewPath': viewPath, 
      '@selectedModelId': context.inheritedValue['rbs:id']}
    return {data: {param}, storage: context.storage}
  },
  render: function() {
    return <div className="metamodeler component"><div className="paper"/></div>;
  },
  cmdBeforeAdd: function(cmdName, cell, graph, options) {
    /*if (["change:z"].indexOf(cmdName) != -1 ) {
      return false;
    }*/

    if (cell.get("temporary")) {
      return false;
    }
    return true;
  },
  viewUpdate: function(update) {
    const self = this
    const props = self.props
    const fileUpdate = Library.makeComplexValueUpdate(self.getView(), update)
    return props.onUpdate({type: 'fileUpdate', path: self.getViewPath(), fileUpdate})
  },
  routeTo: function(ri) {
    const component = this;
    return component.props.onUpdate({type: 'routeTo', ri: ri});
  },
  handleAddCellCommand: function(command) {
    const component = this;
    const data = command.data;
    const type = data.type;
    if (type === 'link') {
      const linkId = data.id;
      const link = component.graph.getCell(linkId);
      component.createLinkValue(link);
    }
  },
  handleChangePositionCommand: function(command) {
    const component = this;
    const cellValue = component.cellIdValue(command.data.id);
    const nextPosition = command.data.next.position;
    const positionUpdate = {
      'rbs:id': cellValue['mm:position']['rbs:id'],
      'mm:x': String(nextPosition.x),
      'mm:y': String(nextPosition.y)
    }
    return component.viewUpdate(positionUpdate);
  },
  handleChangeVerticesCommand: function(command) {
    const component = this;
    const linkId = command.data.id;
    const nextVertices = command.data.next.vertices;
    let vertices = '';
    if (nextVertices.length !== 0) {
      vertices = JSON.stringify(nextVertices);
    }

    const update = {
      'rbs:id': linkId,
      'mm:vertices': vertices
    }
    return component.viewUpdate(update);
  },
  handleChangeSizeCommand: function(command) {
    const cellValue = this.cellIdValue(command.data.id);
    const nextSize = command.data.next.size;
    const sizeUpdate = {
      'rbs:id': cellValue['mm:size']['rbs:id'],
      'mm:height': String(nextSize.height),
      'mm:width': String(nextSize.width)
    }
    return this.viewUpdate(sizeUpdate);
  },
  updateElementAttrs: function(element, elementValue, object, prevElementValue, prevObject) {
    const labelProperty = object['mm:labelProperty']
    if (labelProperty) {
      const value = object[labelProperty] || ''
      element.attr(".label/text", value)
    }
  },
  handleChangeTargetCommand: async function(command) {
    const self = this
    const props = self.props
    const view = self.getView()
    const data = command.data
    const previousTargetElementId = data.previous.target.id
    const nextTargetElementId = data.next.target.id
    if (!previousTargetElementId) {
      return
    }
    if (!nextTargetElementId) {
      return
    }
    const linkId = data.id
    const link = self.graph.getCell(linkId)
    if (!link || link.get('temporary')) {
      return;
    }
    const sourceElementId = link.get('source').id
    const property = link.get('property')

    if (view['mm:objectFolderPath']) {
      
      const sourceElementValue = self.getElementValue(sourceElementId)
      const targetElementValue = self.getElementValue(nextTargetElementId)
      const sourceObject = self.getElementValueObject(sourceElementValue)
      const sourceObjectFile = self.getElementValueObjectFile(sourceElementValue)
      const targetObjectPath = self.getElementValueObjectPath(targetElementValue)
      const values = ensureArray(sourceObject[property])
      const value = values.find(value => value['rbs:id'] === link.get('valueId'))
      const sourceObjectUpdate = {
        'rbs:id': sourceObject['rbs:id']
      }
      sourceObjectUpdate[property] = {
        'rbs:id': value['rbs:id'],
        'meta3:target': {'rbs:ref': Library.riRelativize(self.getViewPath(), targetObjectPath)}
      }
      await props.onUpdate({type: 'fileUpdate', path: sourceObjectFile['meta3:path'], fileUpdate: sourceObjectUpdate})
    } else {
      const sourceObjectUpdate = {
        'rbs:id': sourceElementId
      }
      sourceObjectUpdate[property] = {
        'rbs:id': link.get('id'),
        'meta3:target': {'rbs:ref': '#' + nextTargetElementId}
      }
      const viewUpdate = {
        'chub:node': sourceObjectUpdate,
        'rbs:id': view['rbs:id']
      }
      viewUpdate[view['mm:elementsProperty']] = sourceObjectUpdate
      await self.viewUpdate(viewUpdate)
    }
  },
  cellIdValue: function(id) {
    const self = this
    return Library.findComplex(self.getView(), id)
  },
  getCellValue: function(cell) {
    const component = this;
    return component.cellIdValue(cell.id);
  },
  handleChangeSourceCommand: async function(command) {
    const self = this
    const props = self.props
    const filePath = self.getViewPath()
    const view = self.getView()
    const data = command.data
    const linkId = data.id
    const previousSourceElementId = data.previous.source.id
    const nextSourceElementId = data.next.source.id

    const link = self.graph.getCell(linkId)
    const previousSourceElement = self.graph.getCell(previousSourceElementId)
    const nextSourceElement = self.graph.getCell(nextSourceElementId)
    const linkValue = self.getCellValue(link)
    const property = linkValue['mm:property']
    if (view['mm:objectFolderPath']) {
      const previousSourceElementValue = self.getElementValue(previousSourceElementId)
      const previousSourceFile = self.getElementValueObjectFile(previousSourceElementValue)
      const previousSourceObject = previousSourceFile['meta3:properties']
      const previousSourceObjectUpdate = {
        'rbs:id': previousSourceObject['rbs:id']
      }
      previousSourceObjectUpdate[property] = {
        'rbs:id': linkValue['mm:id']
      }
      await props.onUpdate({type: 'fileUpdate', path: previousSourceFile['meta3:path'], fileUpdate: previousSourceObjectUpdate})
      const nextSourceElementValue = self.getElementValue(nextSourceElementId)
      const nextSourceObjectFile = self.getElementValueObjectFile(nextSourceElementValue)
      const nextSourceObject = nextSourceObjectFile['meta3:properties']
      const nextSourceObjectUpdate = {
        'rbs:id': nextSourceObject['rbs:id']
      }
      nextSourceObjectUpdate[property] = {...linkValue,
        'rbs:id': uuid()
      }
      await props.onUpdate({type: 'fileUpdate', path: nextSourceObjectFile['meta3:path'], fileUpdate: nextSourceObjectUpdate})
      const previousSourceElementUpdate = {'rbs:id': previousSourceElementId}
      previousSourceElementUpdate[property] = {'rbs:id': linkValue['rbs:id']}
      const nextSourceElementUpdate = {'rbs:id': nextSourceElementId}
      nextSourceElementUpdate[property] = linkValue

      const viewUpdate = {
        'mm:element': [previousSourceElementUpdate, nextSourceElementUpdate]
      }
      await self.viewUpdate(viewUpdate)

    } else {
      const previousSourceObjectUpdate = {
        'rbs:id': previousSourceElementId
      }
      previousSourceObjectUpdate[property] = {
        'rbs:id': link.get('id')
      }
      const nextSourceObjectUpdate = {
        'rbs:id': nextSourceElementId
      }
      nextSourceObjectUpdate[property] = {...linkValue, 'rbs:id': uuid()}
      const viewUpdate = {
        'rbs:id': view['rbs:id']
      }
      viewUpdate[view['mm:elementsProperty']] = [previousSourceObjectUpdate, nextSourceObjectUpdate] 
      await self.viewUpdate(viewUpdate)
    }
  },
  getCellEmbeddedLink: function(cell, targetCell) {
    const self = this
    let links = self.graph.getConnectedLinks(cell, {inbound: true})
    links = links.filter(link => link.getSourceElement() == targetCell)
    const embeddedLink = links.find(link => link.get('isEmbeddedLink'))
    return embeddedLink
  },
  unembedCell: function(cell) {
    const self = this
    const parent = self.graph.getCell(cell.get('parent'))
    //var relationshipKey = cell.get("embeddedSourceModelKey");
    /*var embeddedRelationship = RBUtils.GetObject(relationshipKey);
    if (embeddedRelationship) {
      var link = this.showRelationshipDirect(parent, cell, embeddedRelationship);
      link.set('isEmbeddedLink', true);
      cell.unset('embeddedSourceModelKey');
      cell.unset('embeddedTargetModelKey');
      cell.unset('embeddedPropertyName');
      modeler.updateEmbeddedLinkProperties(cell);
    }*/
    parent.unembed(cell)
  },
  getEmbeddedElements: function(cell) {
    const embeddedCells = cell.getEmbeddedCells()
    return embeddedCells.filter(cell => !cell.isLink())
  },
  setParentSize: function(parent) {
    var parentBbox = parent.getBBox();

    if (!parent.get('originalPosition')) parent.set('originalPosition', parent.get('position'));
    if (!parent.get('originalSize')) parent.set('originalSize', parent.get('size'));

    var originalPosition = parent.get('position');
    var originalSize = parent.get('size');

    var newX = originalPosition.x;
    var newY = originalPosition.y;
    var newCornerX = originalPosition.x + originalSize.width;
    var newCornerY = originalPosition.y + originalSize.height;

    var embeddedElements = this.getEmbeddedElements(parent);

    embeddedElements.forEach(child => {

      var childBbox = child.getBBox();

      if (childBbox.x < newX) {
        newX = childBbox.x;
      }
      if (childBbox.y < newY) {
        newY = childBbox.y;
      }
      if (childBbox.corner().x > newCornerX) {
        newCornerX = childBbox.corner().x;
      }
      if (childBbox.corner().y > newCornerY) {
        newCornerY = childBbox.corner().y;
      }
    })

    // Note that we also pass a flag so that we know we shouldn't adjust the
    // `originalPosition` and `originalSize` in our handlers as a reaction
    // on the following `set()` call.
    parent.set({
      position: {
        x: newX,
        y: newY
      },
      size: {
        width: newCornerX - newX,
        height: newCornerY - newY
      }
    });
  },
  handleCellPointerMove: function(cellView, event, x, y) {
    const self = this
    const cell = cellView.model
    if (cell.isLink()) return

    const parentId = cell.get('parent')
    if (parentId) {
      const parent = self.graph.getCell(parentId)

      if (!parent.getBBox().intersect(cell.getBBox())) {
        self.unembedCell(cell)
      }
    } else {
      const elementBelow = self.getElementBelow(cell)
      if (elementBelow) {
         const embeddedLink = self.getCellEmbeddedLink(cell, elementBelow)
         if (embeddedLink) {
           self.embedCellByLink(embeddedLink)
         }
      }
    }
  },
  handleCellPointerUp: async function(cellView, event, x, y) {
    const self = this
    const cell = cellView.model
    if (cell.isLink()) return

    self.handleElementPointerUp(cell, event, x ,y)
    if (!this.isCellSelected(cell)) {
      this.renderHalo(cellView)
    }
  },
  unembedElementValue: function(sourceElementValue, targetElementValue) {
    const self = this
    const view = self.getView()
    const embeddedLink = ensureArray(sourceElementValue['mm:link']).find(link => link['mm:embedded'] === 'true')
    const embeddedLinkId = embeddedLink['rbs:id']
    const viewUpdate = {
      'rbs:id': view['rbs:id'],
      'mm:element': {
        'rbs:id': sourceElementValue['rbs:id'],
        'mm:link': {
          'rbs:id': embeddedLinkId,
          'mm:embedded': ''
        }
      }
    }
    self.viewUpdate(viewUpdate)
  },
  handleElementPointerUp: async function(element, event, x, y) {
    const self = this
    const view = self.getView();
    if (view['mm:allowEmbedding'] === 'true') {
      const parentId = element.get('parent')

      if (parentId) {
        const parent = self.graph.getCell(parentId)
        if (!parent.getBBox().intersect(element.getBBox())) {
          //self.unembedCell(cell)
          const elementValue = self.getElementElementValue(element)
          const parentValue = self.getElementElementValue(parent)
          self.unembedElementValue(parentValue, elementValue)
        } else {
          self.setParentSize(parent)
        }
      } else {
  
        // Prevent recursive embedding.
        const elementBelow = self.getElementBelow(element)
        if (elementBelow && elementBelow !== element && elementBelow.get('parent') !== element.id) {
          const sourceObject = await self.getElementObject(elementBelow)
          const targetObject = await self.getElementObject(element)
          const sourceElementValue = self.getElementElementValue(elementBelow)
          const targetElementValue = self.getElementElementValue(element)
          const possibilities = await MUtils.objectsEmbedablePossibilities(self.getConceptsFiles(), self.getViewPath(), sourceObject, targetObject)
          self.displayEmbeddingContextMenu(sourceElementValue, targetElementValue, possibilities)
        }
      }
  
    }
    
    self.selectCell(element);
  },
  getElementsBelow: function(cell) {
    const self = this
    const elementsBelow = self.graph.findModelsUnderElement(cell)
    const sortedElementsBelow = _.sortBy(elementsBelow, element => - element.get('z'))
    return sortedElementsBelow.filter(el => el.id != cell.id)
  },
  getElementBelow: function(cell) {
    const self = this
    const elementsBelow = self.getElementsBelow(cell)
    if (elementsBelow.length) {
      return elementsBelow[0]
    }
  },
  selectCell: function(cell) {
    const component = this;
    const props = component.props;
    const cellView = cell.findView(component.paper);
    const viewPath = component.props.data.param['@viewPath'];
    const cellModel = component.getElementObject(cell);
    if (props.data.onSubmit) {
      props.data.param['@selectedModelId'] = cellModel['rbs:id'];
      props.data.onSubmit(props.data);
    } else {
      const view = props.data.param['@view']

      let objectRi = viewPath + '#' + cellModel['rbs:id'];
      if (view['mm:objectEntryPointSegment']) {
        objectRi = viewPath + '/' + view['mm:objectEntryPointSegment'] + '/' + cell.id
      } else {
        objectRi = viewPath + '#' + cellModel['rbs:id']
      }

      component.props.onUpdate({type: 'routeTo', ri: objectRi});
    }

    if (!component.props.data.param['@readonly']) {
      component.renderHalo(cellView);
    }
  },
  unhighlightAll: function() {
    const component = this;
    component.graph.getElements().forEach(element => {
      const cellView = element.findView(component.paper);
      cellView.unhighlight();
    })
  },
  isCellSelected: function(cell) {
    return this.elementCollection.models.indexOf(cell) != -1;
  },
  handleStartLinking: function(event, x, y) {
    var modeler = this;
    var linkConstructor = joint.dia.Link;
    if (modeler.linkingProperty) {
      linkConstructor = MUtils.propertyVisualConstructor(modeler.linkingProperty);
    }
    var link = new linkConstructor({}, '.link-target');
    link.set("temporary", true);
    link.set('source', {
      id: this.haloCellView.model.id,
      selector: '.link-target'
    });
    link.set('target', {
      x: x,
      y: y
    });
    this.graph.addCell(link, {
      validation: false
    });
    var linkView = this.paper.findViewByModel(link);
    linkView.startArrowheadMove('target');
    this.creationLinkView = linkView;
    this.haloBlocked = true;
  },
  handleDoLinking: function(event, x, y) {
    var clientCoords = this.paper.snapToGrid({
      x: event.clientX,
      y: event.clientY
    });
    this.creationLinkView.pointermove(event, clientCoords.x, clientCoords.y);
  },
  handleStopLinking: function(event, x, y) {
    const component = this;
    component.haloBlocked = false;
    const linkView = component.creationLinkView;
    const property = component.linkingProperty;
    linkView.pointerup(event);
    const link = linkView.model;
    if (property) {
      component.createLinkValue(link, property)
    } else {
      component.displayNewLinkContextMenu(link, event)
    }
  },
  displayEmbeddingContextMenu: async function(sourceElementValue, targetElementValue, possibilities) {
    const self = this
    
    let items = possibilities.map(possibility => {
      const property = possibility['meta3:property']
      const propertyProperties = Library.propertyProperties(property) 
      const content =  propertyProperties['meta3:name']
      return {
        action: possibility['rbs:id'],
        content,
        callback:  async () => {
          self.createEmbeddedRelationships(sourceElementValue, targetElementValue, property)
          //await self.createLinkValue(link, property)
        }
      }
    })

    items = _.sortBy(items, ['content'])

    const contextMenu = new joint.ui.ContextMenu({
      items,
      target: self.paper.$el,
      event
    })

    items.forEach(item => {
      contextMenu.on('action:' + item.action, item.callback)
    })


    contextMenu.render()
  },
  displayNewLinkContextMenu: async function(link, event) {
    const self = this
    const source = link.getSourceElement('source')
    const target = link.getTargetElement('target')

    var targetObject = self.getElementObject(target)
    var sourceObject = self.getElementObject(source)

    const sourcePossibleProperties = MUtils.modelPossibleProperties(sourceObject)
    const possibleProperties = await Library.promiseFilter(sourcePossibleProperties, property => self.isLinkPossible(property, sourceObject, targetObject))

    let items = possibleProperties.map(property => {
      const propertyProperties = Library.propertyProperties(property) 
      const content =  propertyProperties['meta3:name']
      return {
        action: property.replace(':', '_'),
        content,
        callback:  async () => {
          await self.createLinkValue(link, property)
          link.remove()
        }
      }
    })

    items = _.sortBy(items, ['content'])

    const contextMenu = new joint.ui.ContextMenu({
      items,
      target: self.paper.$el,
      event
    })

    items.forEach(item => {
      contextMenu.on('action:' + item.action, item.callback)
    })

    contextMenu.on('modal:canceled', () => {
      link.remove()
    })

    contextMenu.render()
  },
  renderHalo: function(cellView) {
    const self = this;
    const cell = cellView.model;
    let model;
    try {
      model = self.getElementObject(cell);
    } catch (error) {
      return;
    }


    const ports = cell.getPorts();
    if (ports && ports.length > 0) {
      return;
    }

    const halo = new joint.ui.Halo({
      cellView: cellView,
      boxContent: false
    });
    halo.removeHandle('rotate');
    halo.removeHandle('fork');
    halo.removeHandle('unlink');
    halo.removeHandle('link');
    if (model['mm:hasUniversalLinkHandler'] === 'true') {
      halo.addHandle({
        name: 'link',
        position: 's',
        events: {
          pointerdown: function(event, x, y) {
            self.handleStartLinking(event, x, y)
          },
          pointermove: function(event, x, y) {
            self.handleDoLinking(event, x, y)
          },
          pointerup: function(event, x, y) {
            self.handleStopLinking(event, x, y)
          }
        }
      });
    } else {
      const properties = MUtils.propertiesWithHalo(model);
      properties.forEach(property => {
        const propertyProperties = Library.propertyProperties(property);
        const haloName = propertyProperties['mm:haloName'];
        const haloPosition = propertyProperties['mm:haloPosition'];
        halo.addHandle({
          name: haloName,
          position: haloPosition,
          events: {
            pointerdown: function(event, x, y) {
              self.linkingProperty = property;
              self.handleStartLinking(event, x, y)
            },
            pointermove: function(event, x, y) {
              self.handleDoLinking(event, x, y)
            },
            pointerup: function(event, x, y) {
              self.handleStopLinking(event, x, y)
            }
          }
        });
      })
    }
    halo.removeHandle('clone');
    halo.removeHandle('remove');
    if (!Library.boolean(model['mm:isResizable'])) {
      halo.removeHandle('resize');
    }

    if (model['mm:allowExplore'] === 'true') {
      const element = cellView.model
      const elementValue = self.getElementElementValue(element)
      const links = MUtils.elementValueGetRelationshipsNotInView(self.getViewPath(), elementValue, self.getObjectsFiles())
      if (links.length > 0) {
        halo.addHandle({
          name: 'explore',
          position: 'e',
          events: {
            pointerdown: () => {
              self.handleExplore(cellView.model)
            }
          }
        })
      }
    }
    halo.render();
    self.haloCellView = cellView;
    self.halo = halo;
  },
  objectFileElementEnsure: function(objectFile, position) {
    const self = this
    const elementValues = self.getElementsValues()
    const basePath = self.getViewPath()
    const elementValue = elementValues.find(elementValue => {
      const objectReference = elementValue['mm:object']
      const objectPath = Library.resolveReference(basePath, objectReference)
      return objectPath === objectFile['meta3:path']
    })
    if (!elementValue) {
      self.createElementValue(objectFile['meta3:path'], objectFile['meta3:properties'], position)
    }
  },
  handleExplore: function(element) {
    const self = this
    self.clearHalo()
    const storage = self.getStorage()
    const elementValue = self.getElementElementValue(element)
    const objectFile = self.getElementValueObjectFile(elementValue)
    const links = MUtils.elementValueGetRelationshipsNotInView(self.getViewPath(), elementValue, self.getObjectsFiles())
    const objectBasePath = objectFile['meta3:path']
    const position = element.get('position')
    const size = element.get('size')
    links.forEach(async (link, index) => {
      const value = link.value
      const targetReference = value['meta3:target']
      const targetPath = Library.resolveReference(objectBasePath, targetReference)
      const targetFile = await storage.getFile(targetPath)
      const defaultPosition = {
        x: position.x + size.width + 200,
        y: position.y + (index * 300)
      }
      self.objectFileElementEnsure(targetFile, defaultPosition)
      self.createLinkValueData(elementValue['rbs:id'], link.property, value['rbs:id'])
    })

  },
  initListeners: function() {
    const self = this
    const props = self.props
    self.paper.on('cell:pointerdown', function(cellView, event, x, y) {
      console.log(cellView, event, x, y);
    })
    self.paper.on('cell:pointerup', function(cellView, event, x, y) {
      self.handleCellPointerUp(cellView, event, x, y);
      console.log("cell", cellView.model);
      if (cellView.model.isElement()) {
        console.log("model", self.getElementObject(cellView.model));
      }
    });

    if (self.props.data.param['@readonly']) {
      return;
    }

    self.paper.on('blank:pointerdown', function(event, x, y) {
      joint.ui.TextEditor.close();
      if (event.shiftKey) {
        self.selection.startSelecting(event, x, y);
      } else {
        self.selection.collection.reset([]);
        self.paperScroller.startPanning(event, x, y);
      }
      delete this.haloCellView;
    });

    self.paper.on('blank:contextmenu', (event, x, y) => {
      console.log(event, x, y)
      self.displayBlankContextMenu(event, x ,y)
    })


    self.paper.on('blank:pointerup', function(event, x, y) {
      self.props.onUpdate({type: 'routeTo', ri: self.props.data.param['@viewPath']});
    })

    self.paper.on('cell:contextmenu', function(cellView, event, x, y) {
      self.displayCellContextMenu(cellView, event, x, y);
    })
    //this.paper.on('cell:pointermove', self.handleCellPointerMove)

    self.graph.on('remove', function(cell) {
      if (cell.get("temporary"))
        return;
      if (self.haloCellView && cell == self.haloCellView.model) {
        self.clearHalo();
      }
    })

    self.commandManager.on('add', function(param) {
      self.handleCommandManagerAdd(param);
    });

    self.graph.on('add', async function(cell, collection, opt) {
      if (opt.stencil) {
        props.onUpdate({type: 'initBatchCommand'})
        const conceptPath = cell.get('conceptRI')
        const objectPath = cell.get('objectPath')
        const position = cell.get('position')
        if (conceptPath) {
          self.createObjectAndElementValue(conceptPath, position)
        } else if (objectPath) {
          const storage = self.getStorage()
          const objectFile = await storage.getFile(objectPath)
          self.createElementValue(objectFile['meta3:path'], objectFile['meta3:properties'], position)
        }
        cell.remove()
        await props.onUpdate({type: 'storeBatchCommand'})
      }
    });

    self.paper.on('cell:mouseover', function(cellView, event) {
      var cell = cellView.model;
      if (event.buttons == 0 && !cell.isLink()) {
        if (self.haloCellView != cellView && !self.haloBlocked) {
          if (!self.isCellSelected(cell)) {
            self.renderHalo(cellView);
          }
        }
      }
    });

    self.paper.$el.mousemove(function(event) {
      if (event.buttons == 0 && !self.haloBlocked) {
        if (!self.isEventInHalo(event)) {
          self.clearHalo();
        }
      }
    });

    self.paper.on('element:pointerup', function(cellView, evt) {
      var element = cellView.model;
      if (evt.ctrlKey || evt.metaKey) {
        self.selection.collection.add(element);
        if (self.haloCellView && cellView == self.haloCellView) {
          self.clearHalo();
        }
      }
    });

    self.selection.on('selection-box:pointerdown', function(elementView, evt) {
      if (evt.ctrlKey || evt.metaKey) {
        self.selection.collection.remove(elementView.model);
      }
    });

  },
  createElementValue: async function(basePath, object, position) {
    const self = this;
    const props = self.props;
    const conceptReference = object['meta3:instanceOf']
    const conceptPath = Library.resolveReference(basePath, conceptReference)
    const conceptFile = MUtils.getFile(self.getConceptsFiles(), conceptPath)
    const visuals = ensureArray(conceptFile['meta3:properties']['meta3:prototype']['mm:elementVisual'])
    const visualProperties = visuals.find(visual => visual['rbs:id'] === 'defaultElementVisual')|| visuals[0]
    const visual = Library.toSimpleJSON(visualProperties) 
    const size =  visual.defaults.size
    const elementId = uuid()

    var sizeValue = {
      'rbs:id': elementId + '_size',
      'mm:width': String(size.width),
      'mm:height': String(size.height)
    }

    const positionValue = {
      'rbs:id': elementId + '_position',
      'mm:x': String(position.x),
      'mm:y': String(position.y)
    }
    
    const view = self.getView()
    const fileUpdate = {'rbs:id': view['rbs:id']}
    let objectReference;
    if (view['mm:objectFolderPath']) {
      const objectPath = props.data.param['@modelPath'] + view['mm:objectFolderPath'] + object['rbs:id']
      objectReference = Library.relativizePath( props.data.param['@viewPath'], objectPath)
      const elementValue = {
        'rbs:id': elementId,
        'mm:size': sizeValue,
        'mm:position': positionValue,
        'mm:object': {
            'rbs:ref': objectReference
        }
      }
      fileUpdate['mm:element'] = elementValue
    } else {
      fileUpdate[view['mm:elementsProperty']] = {...object,
        'mm:size': sizeValue,
        'mm:position': positionValue
      }
    }
    self.viewUpdate(fileUpdate)
  },
  createObjectAndElementValue: async function(conceptPath, position) {
    const self = this;
    const props = self.props;
    props.onUpdate({type: 'initBatchCommand'})
    const objectId = Library.guid();
    const concept = await props.storage.getFile(conceptPath)
    const prototype = concept['meta3:properties']['meta3:prototype']
    const defaultPossibilities = ensureArray(prototype['meta3:possibility']).filter(possibility => typeof possibility['meta3:default'] !== 'undefined')
    const object = {
      'rbs:id': objectId,
      'meta3:instanceOf': {
        'rbs:ref': '/' + conceptPath
      }
    }
    defaultPossibilities.forEach(possibility => {
      object[possibility['meta3:property']] = possibility['meta3:default']
    })
    const view = props.data.param['@view']
    let objectFilePath
    if (view['mm:objectFolderPath']) {
      objectFilePath = props.data.param['@modelPath'] + view['mm:objectFolderPath'] + objectId
      props.onUpdate({type: 'fileUpdate', path: objectFilePath, fileUpdate: object});
    } else {
      objectFilePath = self.getViewPath()
    }
    self.createElementValue(objectFilePath, object, position)
    await props.onUpdate({type: 'storeBatchCommand'})
  },
  handleSubCommandManagerAdd: function(command) {
    var modeler = this;
    if (command instanceof Array) {
      return Promise.all(command.map(function(subCommand) {
        return modeler.handleSubCommandManagerAdd(subCommand);
      }))
    } else {
      console.log(command);
      switch (command.action) {
        case "change:position":
          return modeler.handleChangePositionCommand(command);
        break;
        case "change:size":
          return modeler.handleChangeSizeCommand(command);
        break;
        case "change:vertices":
          return modeler.handleChangeVerticesCommand(command);
        break;
        case "change:target":
          return modeler.handleChangeTargetCommand(command);
        break;
        case "change:source":
          return modeler.handleChangeSourceCommand(command);
        break;
       /* case "remove":
          return modeler.handleRemoveCellCommand(command);
        break;*/
        case "add":
          return modeler.handleAddCellCommand(command);
        break;
      default:
        return Promise.resolve();
      }
    }
  },
  handleCommandManagerAdd: async function(command) {
    var modeler = this;
    const component = this
    const props = component.props
    const storage = props.storage
    if (!modeler.withoutChanges) {
      modeler.managerPromise = modeler.managerPromise.then( async () => {
        props.onUpdate({type: 'initBatchCommand'})
        modeler.withoutChanges = true
        await modeler.handleSubCommandManagerAdd(command)
        modeler.withoutChanges = false
        await props.onUpdate({type: 'storeBatchCommand'})
      })
    }
  },
  clearHalo: function() {
    joint.ui.Halo.clear(this.paper);
    this.haloCellView = null;
  },
  isEventInHalo: function(event) {
    if (!this.haloCellView) {
      return false;
    }
    var box = this.haloCellView.getBBox();
    box.moveAndExpand({
       x: -40,
       y: -40,
       width: 80,
       height: 80
    });
    var offset = this.paper.$el.offset();
    var point = {
       x: event.pageX - offset.left,
       y: event.pageY - offset.top
    };
    return box.containsPoint(point);
  },
  referenceValueObjectId: function(reference) {
    const basePath = this.getViewPath()
    const view = this.getView()
    const ri = Library.resolveReference(basePath, reference)
    if (view['mm:objectFolderPath']) {
      return Library.pathFileName(ri)
    } else {
      return Library.riFragment(ri)
    }
  },
  getElementsValuesProperty: function() {
    const self = this
    const view = self.getView()
    return view['mm:elementsProperty'] || 'mm:element'
  },
  linkRemove: async function(link, fromModel) {
    const self = this;
    const props = self.props
    const view = this.getView()
    self.callAsBatch(async () => {
      const sourceElementId = link.get('source').id
      if (!self.areObjectsInView()) {
        const objectDiagramUpdate = {
          'rbs:id': view['rbs:id'],
          'mm:element': {
            'rbs:id': sourceElementId,
            'mm:link': {
              'rbs:id': link.get('id')  
            }
          }
        }
        await self.viewUpdate(objectDiagramUpdate)
        if (fromModel) {
          const linkValue = self.getCellValue(link)
  
          const sourceElementValue = self.getElementValue(sourceElementId)
          const sourceFile = self.getElementValueObjectFile(sourceElementValue)
          const sourceObject = sourceFile['meta3:properties']
          const sourceObjectUpdate = {
            'rbs:id': sourceObject['rbs:id']
          }
          sourceObjectUpdate[linkValue['mm:property']] = {
            'rbs:id': linkValue['mm:id']
          }
          await props.onUpdate({type: 'fileUpdate', path: sourceFile['meta3:path'], fileUpdate: sourceObjectUpdate})
        }
      } else {
        const elementsValuesProperty = self.getElementsValuesProperty()
        const sourceElementUpdate = {
          'rbs:id': sourceElementId
        }
        sourceElementUpdate[link.get('property')] = {
          'rbs:id': link.get('id')
        }
        const objectDiagramUpdate = {
          'rbs:id': view['rbs:id']
        }
        objectDiagramUpdate[elementsValuesProperty] = sourceElementUpdate
        await self.viewUpdate(objectDiagramUpdate)
      }
    })
  },
  elementRemove: async function(element, fromModel) {
    const self = this;
    const props = self.props;
    self.callAsBatch(() => {
      element.graph.getConnectedLinks(element).forEach(link => {
        self.linkRemove(link, fromModel)
      })
      const elementValue = self.getElementElementValue(element)
      const view = self.getView()
      if (!self.areObjectsInView() && fromModel) {
        const elementValueFile = self.getElementValueObjectFile(elementValue)
        const elementValueFileUpdate = {
          'rbs:id': elementValueFile['meta3:properties']['rbs:id']
        }
        props.onUpdate({type: 'fileUpdate', path: elementValueFile['meta3:path'], fileUpdate: elementValueFileUpdate})
      }
      const elementsValuesProperty = self.getElementsValuesProperty()
      const elementValueUpdate = {'rbs:id': elementValue['rbs:id']}
      const viewUpdate = {'rbs:id': view['rbs:id']}
      viewUpdate[elementsValuesProperty] = elementValueUpdate
      self.viewUpdate(viewUpdate)
    })
    self.routeTo(self.getViewPath())
  },
  callAsBatch: async function(fun) {
    this.props.onUpdate({type: 'initBatchCommand'})
    try {
      await fun()
    } finally {
      this.props.onUpdate({type: 'storeBatchCommand'})
    }
  },
  areObjectsInView: function() {
    const self = this
    const view = self.getView()
    return typeof view['mm:objectFolderPath'] === 'undefined'
  },
  switchElementVisual: function(elementValue, object) {
    const self = this
    const typeVisual = self.getElementValueVisualType(elementValue)
    const typeVisuals = ensureArray(object['mm:elementVisual']).map(elementVisual => elementVisual['rbs:id'])
    const currentIndex = typeVisuals.indexOf(typeVisual)
    const nextIndex = (currentIndex + 1) % typeVisuals.length
    const nextVisual = typeVisuals[nextIndex]
    const update = {
      'rbs:id': elementValue['rbs:id'],
      'mm:typeVisual': nextVisual
    }
    self.viewUpdate(update)
  },
  addElementFromModel: async function(event, x ,y) {
    const self = this
    const storage = self.getStorage()
    const modelPath = self.getModelPath()
    const view = self.getView()
    const viewPath = self.getViewPath()
    const onGetItems = async () => {
      const objectsFiles = await MUtils.getObjectsFilesNotInView(storage, modelPath, viewPath, view)
      const items = objectsFiles.map(file => {
        const properties = file['meta3:properties']
        const name = properties['meta3:name']
        const description = properties['arch:description']
        const title = description || name || ''
        return {title, id: file['meta3:path'], value: file}
      })
      return items
    }
    const onChoose = async (file) => {
      self.objectFileElementEnsure(file, {x, y})
    }
    const options = {
      target: self.paper.$el,
      event: event,
      action: 'choose',
      onGetItems,
      onChoose
    }
    OpenDialog(options)
  },
  displayBlankContextMenu: function(event, x, y) {
    const self = this;
    const areObjectsInView = self.areObjectsInView() 
    if (!areObjectsInView) {
      const items = []
      items.push({
        action: 'addFromModel',
        content: 'Add from model',
        actionFunction: () => {
          self.addElementFromModel(event, x, y)
        }
      })

      const contextMenu = new joint.ui.ContextMenu({
        items,
        target: self.paper.$el,
        event
      })
  
      items.forEach(item => {
        const action = 'action:' + item.action
        const actionFunction = item.actionFunction
        contextMenu.on(action, actionFunction)
      })
      contextMenu.render()
      
    }

  },
  displayCellContextMenu: function(cellView, event, x, y) {
    var self = this;
    var cell = cellView.model
    var items = []

    var baseOptions = {
      target: cellView.$el,
      cell: cellView.model,
      event: event,
      x: event.pageX,
      y: event.pageY
    }
    const areObjectsInView = self.areObjectsInView() 
    if (cell.isLink()) {
      
      if (areObjectsInView) {
        items.push({
          action: 'remove',
          content: 'Remove',
          actionFunction: () => {
            self.linkRemove(cell)
          }
        })
      } else {
        items.push({
          action: 'removeFromView',
          content: 'Remove from model',
          actionFunction: () => {
            self.linkRemove(cell, true)
          }
        })
        items.push({
          action: 'removeFromModel',
          content: 'Remove from view',
          actionFunction: () => {
            self.linkRemove(cell, false)
          }
        })        
      }
      

      if (event.target.getAttribute('class') == 'marker-vertex') {
        items.push({
            action: 'unbendLine',
            content: 'Unbend line',
            actionFunction: function () {
              cell.removeVertex(event.target.getAttribute('idx'));
            }
         })
      } else {
        items.push({
           action: 'bendLine',
           content: 'Bend line' ,
           actionFunction: function () {
             cellView.addVertex({
                 x: x,
                 y: y
             });
           }
        })
      }
    } else {
      if (areObjectsInView) {
        items.push({
          action: 'remove',
          content: 'Remove',
          actionFunction: () => self.elementRemove(cell)
        })
      } else {
        items.push({
          action: 'removeFromView',
          content: 'Remove from view',
          actionFunction: () => self.elementRemove(cell, false)
        })
        items.push({
          action: 'removeFromModel',
          content: 'Remove from model',
          actionFunction: () => self.elementRemove(cell, true)
        })
      }
      const elementValue = self.getCellValue(cell)
      const object = self.getElementValueObject(elementValue)
      const visuals = ensureArray(object['mm:elementVisual'])
      if (visuals.length > 1) {
        items.push({
          action: 'switchVisual',
          content: 'Switch visual',
          actionFunction: () => self.switchElementVisual(elementValue, object)
        })
      }
    }

    if (items.length == 0) { // If there are not items in context menu exit
      return;
    }

    const contexMenuItems = _.sortBy(items, function(item) {
      return item.priority || 0;
    });

    const contextMenu = new joint.ui.ContextMenu({
      items: items,
      target: cellView.$el,
      event: event
    });

    var action;
    var actionFunction;
    _.each(items, function(item) {
      action = 'action:' + item.action;
      actionFunction = item.actionFunction
      if (!actionFunction) { // Open dialog is the default action
        actionFunction = function() {
          OpenDialog(_.extend(item, baseOptions));
        }
      };
      contextMenu.on(action, actionFunction);
    });
    contextMenu.render();
  },
  bendLinkIfNecessary: function(link) {
    // add vertex to the link when the link leads between already linked elements
    var linkView = link.findView(this.paper);
    if (!linkView) {
      return;
    }

    var graph = this.graph;
    //var paper = this.paper;
    var linksSource = graph.getConnectedLinks(link.getSourceElement(), {outbound: true});
    var linksTarget = graph.getConnectedLinks(link.getTargetElement(), {inbound: true});
    var commonLinks = _.intersection(linksSource, linksTarget);
    if (link.getSourceElement() == link.getTargetElement()) {
      var count = commonLinks.length - 1;
      var point = linkView.sourcePoint;
      var point1 = g.point(point);
      point1.move(g.point(point.x, point.y + 100), 100);
      var point2 = g.point(point1);
      point2.move(g.point(point1.x + 100, point1.y), 100);
      point1.rotate(point, count * 10);
      point2.rotate(point, count * 10);
      linkView.addVertex(point1);
      linkView.addVertex(point2);
    } else {

      var isDirectLink = commonLinks.some(function(l) {
        if (l == link) {
          return false;
        }
        var vertices = l.get('vertices');
        return typeof vertices == "undefined" || vertices === 0;
      });
      if (isDirectLink) { // there is already direct linkg
        var point = g.line(linkView.sourcePoint, linkView.targetPoint).midpoint();
        var vect = linkView.targetPoint.difference(linkView.sourcePoint);
        vect.normalize()
        vect.rotate(g.point(0, 0), -90);
        point.offset(vect.x * 30, vect.y * 30);
        linkView.addVertex(point);
      };
    }


  },
  getParams: function() {
    return this.props.data.param
  },
  getStorage: function() {
    return this.props.storage
  },
  getModelPath: function() {
    return this.getParams()['@modelPath']
  },
  getView: function() {
    return this.getParams()['@view']
  },
  getViewPath: function() {
    return this.getParams()['@viewPath']
  },
  getObjectsFiles: function() {
    return this.getParams()['@objectsFiles']
  },
  getConceptsFiles: function() {
    return this.getParams()['@conceptsFiles']
  },
  isLinkPossible: function(property, sourceObject, targetObject) {
    return MUtils.isLinkPossible(this.getConceptsFiles(), this.getViewPath(), property, sourceObject, targetObject)
  },
  createLinkValueData: async function(sourceElementId, property, valueId) {
    const self = this
    const sourceElementUpdate = {
      'rbs:id': sourceElementId,
      'mm:link': {
        'rbs:id': uuid(),
        'mm:id': valueId,
        'mm:property': property
      }
    }
    self.viewUpdate(sourceElementUpdate)
  },
  createLinkValue: async function(link, property) {
    const self = this;
    const props = self.props;
    props.onUpdate({type: 'initBatchCommand'})
    
    const sourceElement = link.getSourceElement();
    const targetElement = link.getTargetElement();
    if (!link.get('target')) {
      link.remove();
      return;
    }
    const sourceElementId = link.get('source').id
    const targetElementId = link.get('target').id

    let sourcePort = null;
    if (sourceElement.getPorts && sourceElement.getPorts().length > 0) {
      if (link.get('source').port === 'ok') {
        sourcePort = 'ok';
        property = 'chub:nextNode';
      } else {
        sourcePort = 'ko';
        property = 'chub:nextNodeTimeout';
      }
    }

    let targetPort = null;
    if (targetElement.getPorts && targetElement.getPorts().length > 0) {
      targetPort = 'in';
    }
    const sourceElementValue = self.getElementValue(sourceElementId)
    const targetElementValue = self.getElementValue(targetElementId)
    const sourceObject = self.getElementValueObject(sourceElementValue)
    const targetObject = self.getElementValueObject(targetElementValue)
    const isPossible = await self.isLinkPossible(property, sourceObject, targetObject)
    if (isPossible) {
      const view = self.getView()
      if (view['mm:objectFolderPath']) {
        const sourceFile = self.getElementValueObjectFile(sourceElementValue)
        const valueId = uuid()
        const sourceFileUpdate = {
          'rbs:id': sourceObject['rbs:id']
        }
        sourceFileUpdate[property] = {
          'rbs:id': valueId,
          'meta3:target': {'rbs:ref': targetObject['rbs:id']}
        }
        await props.onUpdate({type: 'fileUpdate', path: sourceFile['meta3:path'], fileUpdate: sourceFileUpdate})
        self.createLinkValueData(sourceElementId, property, valueId)
      } else {
        const sourceObjectUpdate = {
          'rbs:id': sourceObject['rbs:id']
        }
  
        let sourceSelector = '.link-target';
        if (sourcePort) {
          sourceSelector = '.port-body';
        }
        let targetSelector = '.link-target';
        if (targetPort) {
          targetSelector = '.port-body';
        }
        const sourceValue = {
          'rbs:id': Library.guid(),
          'mm:selector': sourceSelector
        }
        if (sourcePort) {
          sourceValue['mm:port'] = sourcePort;
        }
        const targetValue = {
          'rbs:id': Library.guid(),
          'mm:selector': targetSelector
        }
        if (targetPort) {
          targetValue['mm:port'] = targetPort;
        }

        sourceObjectUpdate[property] = {
          'rbs:id': uuid(),
          'mm:source': sourceValue,
          'mm:target': targetValue,
          'meta3:target': {
            'rbs:ref': "#" + targetObject['rbs:id']
          }
        }

        const viewUpdate = {
          'rbs:id': view['rbs:id']
        };
        viewUpdate[view['mm:elementsProperty']] = sourceObjectUpdate
        await self.viewUpdate(viewUpdate)
      }
      self.selectCell(targetElement)
    } else {
      link.remove()
    }
    await props.onUpdate({type: 'storeBatchCommand'})
  },
  createEmbeddedRelationships: async function(sourceElementValue, targetElementValue, property) {
    const self = this;
    const props = self.props;
    const sourceFile = self.getElementValueObjectFile(sourceElementValue)
    const targetFile = self.getElementValueObjectFile(targetElementValue)
    const sourceObject = sourceFile['meta3:properties']
    const targetObject = targetFile['meta3:properties']  
    const valueId = uuid()
    const sourceFileUpdate = {
      'rbs:id': sourceObject['rbs:id']
    }
    sourceFileUpdate[property] = {
      'rbs:id': valueId,
      'meta3:target': {'rbs:ref': targetObject['rbs:id']}
    }
    props.onUpdate({type: 'fileUpdate', path: sourceFile['meta3:path'], fileUpdate: sourceFileUpdate})
    const viewUpdate = {
      'rbs:id': self.getView()['rbs:id'],
      'mm:element': {
        'rbs:id': sourceElementValue['rbs:id'],
        'mm:link': {
          'rbs:id': uuid(),
          'mm:id': valueId,
          'mm:property': property,
          'mm:embedded': 'true'
        }
      }
    }
    self.viewUpdate(viewUpdate)
  },
  getElementObject: function(element) {
    const self = this
    const elementValue = self.getCellValue(element)
    return self.getElementValueObject(elementValue)
  },
  getElementObjectFile: function (element) {
    const self = this;
    const elementValue = self.getCellValue(element)
    return self.getElementValueObjectFile(elementValue)
  },
  getElementValueObject: function(elementValue) {
    return MUtils.elementValueObject(this.getViewPath(), elementValue, this.getObjectsFiles())
  },
  getElementValueObjectPath: function(elementValue) {
    return Library.resolveReference(this.getViewPath(), elementValue['mm:object'])
  },
  getElementsValues: function() {
    const view = this.getView()
    return ensureArray(view[view['mm:elementsProperty'] || 'mm:element'])
  },
  getElementElementValue: function(element) {
    const self = this
    return self.getElementValue(element.get('id'))
  },
  getElementValue: function(elementId) {
    const self = this
    const elementValues = self.getElementsValues()
    return elementValues.find(element => element['rbs:id'] === elementId)
  },
  getObjectElementValue: function(objectPath) {
    const self = this
    const elements = self.getElementsValues()
    const basePath = self.getViewPath()
    return elements.find(element => Library.resolveReference(basePath, element['mm:object']) === objectPath)
  },
  getLinkValueTargetElementValueId: function(elementValue, linkValue) {
    const self = this
    if (linkValue['meta3:target']) {
      return referenceFragment(linkValue['meta3:target'])
    } else {
      const object = self.getElementValueObject(elementValue)
      const objectPath = self.getElementValueObjectPath(elementValue)
      const property = linkValue['mm:property']
      const valueId = linkValue['mm:id']
      const values = ensureArray(object[property])
      const value = values.find(item => item['rbs:id'] === valueId)
      const target = value['meta3:target']
      const targetObjectPath = Library.resolveReference(objectPath, target)
      const element = self.getObjectElementValue(targetObjectPath)
      return element['rbs:id']
    }
  },
  /* Update graph */
  updateElementLinks: function(elementValue, object, prevElementValue, prevObject) {
    const self = this
    const possibilities = ensureArray(elementValue['meta3:possibility']).filter(possibility => possibility['meta3:modeled'] === 'true')
    possibilities.forEach(possibility => {
      const property = possibility['meta3:property']
      const linksValues = ensureArray(elementValue[property])
      linksValues.forEach(linkValue => {
        self.updateLinkValue(property, elementValue, linkValue)
      })
    })
    const links = ensureArray(elementValue['mm:link'])
    let prevLinks 
    if (prevElementValue) {
      prevLinks = ensureArray(prevElementValue['mm:link'])
    }
    
    links.forEach(linkValue => {
      const prevLinkValue = MUtils.getValueById(prevLinks, linkValue['rbs:id'])
      const relationship = MUtils.linkValueRelationship(linkValue, object)
      let prevRelationship
      if (prevLinkValue && prevObject) {
        prevRelationship = MUtils.linkValueRelationship(prevLinkValue, prevObject)
      }
      if (linkValue['mm:embedded'] === 'true') {
        self.updateEmbedding(elementValue, linkValue, prevElementValue, prevLinkValue)
      } else {
        if (prevLinkValue && prevLinkValue['mm:embedded'] === 'true') {
          self.updateEmbedding(elementValue, linkValue, prevElementValue, prevLinkValue)
        }
        const property = linkValue['mm:property']
        self.updateLinkValue(property, elementValue, linkValue, relationship, prevElementValue, prevLinkValue, prevRelationship)
      }

    })
  },
  updateElement: function(element, elementValue, object, prevElementValue, prevObject) {
    const self = this
    if (!Library.equals(elementValue, prevElementValue) || !Library.equals(object, prevObject)) {
      const position = Library.toSimpleJSON(elementValue['mm:position'])
      const size = Library.toSimpleJSON(elementValue['mm:size'])
      element.set('position', position)
      element.set('size', size)
      self.updateElementAttrs(element, elementValue, object, prevElementValue, prevObject)
    }
    element.set('alive', true)
  },
  makeElement: function(elementValue, objectFile) {
    const component = this;
    try {
      const element = component.createElement(elementValue)
      component.graph.addCell(element)
      component.updateElement(element, elementValue, objectFile)
    } catch (error) {
      console.log(`Could not create element with id ${elementValue['rbs:id']}`)
      throw error
    }
  },

  getElementValueObjectFile: function (elementValue) {
    const self = this
    return MUtils.elementValueObjectFile(self.getViewPath(), elementValue, self.getObjectsFiles())
  },
  createElement: function(elementValue) {
    const self = this
    const id = elementValue['rbs:id']
    const elementConstructor = MUtils.elementConstructor(self.getViewPath(), elementValue, self.getObjectsFiles())
    const element = new elementConstructor({id: id})
    return element
  },
  updateLinkValue: function(property, elementValue, linkValue, relationship, prevElementValue, prevLinkValue, prevRelationship) {
    const self = this
    const linkId = linkValue['rbs:id']
    const graph = self.graph
    const link = graph.getCell(linkId)
    if (link) {
      self.updateLink(link, elementValue, property, linkValue, relationship, prevElementValue, prevLinkValue, prevRelationship)
    } else {
      self.makeLink(elementValue, property, linkValue, relationship)
    }
  },
  updateEmbedding: function(elementValue, linkValue) {
    const self = this
    const graph = self.graph
    const targetElementId = self.getLinkValueTargetElementValueId(elementValue, linkValue)
    const sourceElement = graph.getCell(elementValue['rbs:id'])
    const targetElement = graph.getCell(targetElementId)
    if (linkValue['mm:embedded'] === 'true') {
      sourceElement.embed(targetElement)
    } else {
      sourceElement.unembed(targetElement)
    }
  },
  updateLink: function(link, elementValue, property, linkValue, relationship, prevElementValue, prevLinkValue, prevRelationship) {
    const self = this;
    if (!Library.equals(linkValue, prevLinkValue) || !Library.equals(relationship, prevRelationship)) {
      const source = Library.toSimpleJSON(linkValue['mm:source']) || {}
      source.id = elementValue['rbs:id']
      const target = Library.toSimpleJSON(linkValue['mm:target']) || {}
      target.id = self.getLinkValueTargetElementValueId(elementValue, linkValue)
      link.set("source", source);
      link.set("target", target);
      link.set('property', property)
      if (linkValue['mm:id']) {
        link.set('valueId', linkValue['mm:id'])
      }
      if (typeof linkValue['mm:vertices'] !== 'undefined') {
        const vertices = Library.toSimpleJSON(linkValue['mm:vertices'], 'mm:vertices');
        link.set("vertices", vertices);
      }
    }
    link.set("alive", true);
  },
  createLink: function(property, linkValue) {
    const id = linkValue['rbs:id'];
    const type = MUtils.propertyType(property)
    const linkConstructor = MUtils.typeToConstructor(type);
    const link = new linkConstructor({id: id});
    return link;
  },
  makeLink: function(elementValue, property, linkValue) {
    const self = this
    const link = self.createLink(property, linkValue)
    self.graph.addCell(link)
    self.updateLink(link, elementValue, property, linkValue)
  },
  getElementValueVisualType: function(elementValue) {
    return (elementValue && elementValue['mm:typeVisual']) || 'defaultElementVisual'
  },
  hasElementCorrectVisualType: function(elementValue, lastElementValue) {
    const self = this
    const lastElementVisualType = self.getElementValueVisualType(lastElementValue)
    const elementVisualType = self.getElementValueVisualType(elementValue)
    return elementVisualType === lastElementVisualType
  },
  callWithoutChange: function(bodyFunction) {
    const self = this
    self.withoutChanges = true
    try {
      bodyFunction()
    } finally {
      self.withoutChanges = false
    }
  },
  updateGraph: function(view, objectsFiles, prevView, prevObjectsFiles) {
    const self = this
    const graph = self.graph
    const viewPath = self.getViewPath()
    const elementsProperty = view['mm:elementsProperty']
    if (!elementsProperty) {
      throw new Error("Missing elements property in view.")
    }
    const elementsValues = ensureArray(view[elementsProperty])
    let prevElementsValues
    if (prevView) {
      prevElementsValues = ensureArray(prevView[elementsProperty])
    }
    self.callWithoutChange(() => {
      elementsValues.forEach(elementValue => {
        const object = MUtils.elementValueObject(viewPath, elementValue, objectsFiles)
        const elementId = elementValue['rbs:id']
        let element = graph.getCell(elementId)
        if (element) {
          const prevElementValue = MUtils.getValueById(prevElementsValues, elementValue['rbs:id'])
          let prevObject
          if (prevElementValue && prevObjectsFiles) {
            prevObject = MUtils.elementValueObject(viewPath, prevElementValue, prevObjectsFiles)
          }
          if (!self.hasElementCorrectVisualType(elementValue, prevElementValue)) {
            element.remove()
            self.makeElement(elementValue, object)
          } else {
            self.updateElement(element, elementValue, object, prevElementValue, prevObject)
          }
        } else {
          self.makeElement(elementValue, object)
        }
      })
      elementsValues.forEach(elementValue => {
        const object = MUtils.elementValueObject(viewPath, elementValue, objectsFiles)
        
        const prevElementValue = MUtils.getValueById(prevElementsValues, elementValue['rbs:id'])
        let prevObject
        if (prevElementValue && prevObjectsFiles) {
          prevObject = MUtils.elementValueObject(viewPath, prevElementValue, prevObjectsFiles)
        } 
        self.updateElementLinks(elementValue, object, prevElementValue, prevObject)
      })
      graph.getCells().forEach(cell => {
        if (!cell.get('alive')) {
          cell.remove();
        }
      })
      graph.getCells().forEach(cell => {
        cell.unset('alive');
      })
    })

  }
});

MC.registerReactRomponent('metamodeler', Metamodeler);

export {Metamodeler};
