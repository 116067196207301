'use strict';
import {Library} from "./library.js";
import {ensureArray, resolveReference} from "./lib.js";

export const Meta = {};


Meta.computeInheritance = async (basePath, complex, isRoot) => {
  const input = {
    'meta3:path': basePath,
    'meta3:properties': complex,
    'meta3:isRoot': isRoot
  }
  const result = await Library.runFlow('in/meta', 'META_Inheritance', input)
  return result['meta3:properties'] || {'rbs:id': complex['rbs:id']}
}

Meta.makeInheritedUpdate = (basePath, update, isRoot) => {
  if (update['meta3:instanceOf'] || update['meta3:subtypeOf']) {
    return Meta.computeInheritance(basePath, update, isRoot)
  } else {
    return Promise.all(Library.objectEntries(update).map(([property, value]) => {
      const values = Library.ensureArray(value);
      if (Library.areComplex(values)) {
        return Promise.all(values.map(value => Meta.makeInheritedUpdate(basePath, value, false))).then(inheritedUpdateValues => [property, Library.unwrapSingleton(inheritedUpdateValues)]);
      } else {
        return Promise.resolve([property, Library.unwrapSingleton(values)]);
      }
    })).then(entries => {
      return Library.entriesToObject(entries);
    })
  }
}

Meta.pathIsInstanceOf = async (path, conceptPath) => {
  const input = {
    'meta3:path': path,
    'meta3:conceptPath': conceptPath
  }
  const result = await Library.runFlow('in/meta', 'META_PathIsInstanceOf', input)
  return result['meta3:result']
}

Meta.isInstanceOf = async (basePath, value, conceptPath) => {
  const input = {
    'meta3:basePath': basePath,
    'meta3:value': value,
    'meta3:conceptPath': conceptPath
  }
  const result = await Library.runFlow('in/meta', 'META_IsInstanceOf', input)
  return result['meta3:result']
}

Meta.fileApplyTransformation = async (file, transformation) => {
  const isInstanceOf = await Meta.pathIsInstanceOf(file['meta3:path'] || '/', transformation.conceptPath)
  if (isInstanceOf) {
    return transformation.fileTransformation(file)
  } else {
    return file
  }
}

export const getFile = (files, path) => {
  return files.find(file => file['meta3:path'] === path)
}

export const isSubtypeOf = (files, basePath, value, superPath) => {
  const superObjectsReferences = ensureArray(value['meta3:subtypeOf'])
  return superObjectsReferences.some(reference => {
    const targetPath = resolveReference(basePath, reference)
    return pathIsSubtypeOf(files, targetPath, superPath, )
  })
}

export const pathIsSubtypeOf = (files, path, superPath) => {
  if (path === superPath) {
    return true
  } else {
    const file = getFile(files, path)
    if (!file) {
      return false
    }
    return isSubtypeOf(files, file['meta3:path'], file['meta3:properties'], superPath)
  }
}

export const isInstanceOf = (files, basePath, value, conceptPath) => {
  const conceptsReferences = ensureArray(value['meta3:instanceOf'])
  return conceptsReferences.some(reference => {
    const targetPath = resolveReference(basePath, reference)
    return pathIsSubtypeOf(files, targetPath, conceptPath)
  })
}

export const pathIsInstanceOf = (files, path, conceptPath) => {
  const file = getFile(files, path)
  return isInstanceOf(files, path, file['meta3:properties'], conceptPath)
}

class Storage {
  constructor() {
    const self = this
    self.files = {} // aktuální soubory
    self.storedFiles = {} // soubory uložené na serveru
    self.filesPromises = {} // přísliby souborů
    self.foldersItemsPaths = {} // přísliby cest prvků adresáře 
    self.filesTransformations = []
    self.storeTransformations = []
    self.undoStack = []
    self.redoStack = []
    self.batch = []
    self.batchLevel = 0
    self.storeHandlers = []
    self.batchCommand = false
    self.versioningInfos = {}
    
  }
  async getVersioningInfo(versioningRootPath) {
    if (!this.versioningInfos[versioningRootPath]) {
      const input = {
        'rbs:path': versioningRootPath,
        'rbs:branches': 'true',
        'rbs:includePrivate': 'true'
      }
      this.versioningInfos[versioningRootPath] = Library.runFlow('in/storage', 'STRG_VersioningInfo', input)
    } 
    return this.versioningInfos[versioningRootPath]
  }
  setTheoryPath(theoryPath) {
    this.theoryPath = theoryPath
    return this
  }
  deleteUserData() {
    const self = this
    self.foldersItemsPaths = {}
    self.storedFiles = Library.entriesToObject(Library.objectEntries(self.storedFiles).filter(([path, file]) => {
      const properties = file['meta3:properties']
      if (properties) {
        const userData = Library.boolean(properties['app:userData'])
        if (userData) {
          return false
        }
      }
      if (!file['meta3:exist']) {
        return false
      }
      return true
    }))
    self.files = self.storedFiles
  }
  emptyStacks() {
    const self = this
    self.undoStack = []
    self.redoStack = []
  }
  hasUndo() {
    const self = this
    return self.undoStack.length !== 0
  }

  hasRedo() {
    const self = this
    return self.redoStack.length !== 0
  }
  undo() {
    const self = this
    console.log(self.batchCommand)
    const files = self.undoStack.pop()
    const paths = files.map(file => file['meta3:path'])
    self.storeResources(paths, true)
    self.replaceFiles(files)
  }
  redo() {
    const self = this
    console.log(self.batchCommand)
    const files = self.redoStack.pop()
    const paths = files.map(file => file['meta3:path'])
    self.storeResources(paths)
    self.replaceFiles(files)
  }
  initBatchCommand() {
    const self = this
    self.batchCommand = true
    self.batchLevel++
  }
  async storeBatchCommand() {
    const self = this
    self.batchLevel--
    if (self.batchCommand && self.batchLevel === 0) {
      const batch = await Promise.all(self.batch.map(file => self.transformFile(file)))
      self.batchCommand = false
      if (batch.length !== 0) {
        self.undoStack.push(batch)
      }
      self.batch = []
      self.redoStack = []
    }
  }
  registerStoreHandler(conceptPath, handler) {
    const self = this
    self.storeHandlers.push({conceptPath, handler});
  }
  registerFileTransformation(conceptPath, fileTransformation) {
    const self = this
    self.filesTransformations.push({conceptPath, fileTransformation})
  }
  registerStoreTransformation(conceptPath, fileTransformation) {
    const self = this
    self.storeTransformations.push({conceptPath, fileTransformation})
  }
  cacheFile(file) {
    const self = this
    const path = file['meta3:path'] || '/'
    if (typeof self.files[path] === 'undefined') {
      delete file.flowId
      delete file.flowLogId
      self.storedFiles[path] = file
      self.files[path] = file
    }
  }
  getFile(path) {
    const self = this
    const filePromise = self.filesPromises[path]
    if (filePromise) {
      return filePromise
    }
    let file = self.files[path]
    if (!file) {
      if (Library.isFolderPath(path)) {
        file = self.files[path.slice(0, path.length -1)]
      } else {
        file = self.files[path + '/']
      }
    }
    if (file) {
      return Promise.resolve(file)
    } else {
      const input = {
        'meta3:path': path
      }
      let operationName;
      if (self.theoryPath) {
        input['meta3:theoryPath'] = self.theoryPath
        operationName = 'META_GetExpandedFileResource'
      } else {
        operationName = 'META_GetInheritedFileResource'
      }
      const promise = Library.runFlow('in/meta/', operationName, input, true).then(file => {
        self.cacheFile(file)
        delete self.filesPromises[path]
        return file
      })
      self.filesPromises[path] = promise
      return promise
    }
  }
  async existFile(path) {
    const self = this
    const file = await self.getFile(path)
    return file['meta3:exist']
  }
  filesNeedStore() {
    const self = this
    return !Library.equals(self.files, self.storedFiles)
  }
  fileApplyTransformation = async (file, transformation) => {
    const self = this
    const isInstanceOf = await self.pathIsInstanceOf(file['meta3:path'] || '/', transformation.conceptPath)
    if (isInstanceOf) {
      return transformation.fileTransformation(file)
    } else {
      return file
    }
  }
  transformFile(file) {
    const self = this
    return Library.promiseReduce(self.filesTransformations, (result, transformation) => self.fileApplyTransformation(result, transformation), file)
  }
  storeTransformFile(file) {
    const self = this
    return Library.promiseReduce(self.storeTransformations, (result, transformation) => self.fileApplyTransformation(result, transformation), file)
  }
  storeResources(paths, toRedo) {
    const self = this
    // collect resources
    const files = paths.map(path => {
      const file =  self.files[path]
      if (file) {
        return file
      } else {
        return {'meta3:path': path, 'rbs:properties': [{'rbs:id': Library.pathName(path)}]}
      }
    })
    // store resources
    if (toRedo) {
      self.redoStack.push(files);
    } else {
      if (self.batchCommand) {
        if (self.batch) {
          files.forEach(file => {
            if (!self.batch.find(batchFile => batchFile['meta3:path'] === file['meta3:path'])) {
              self.batch.push(file)
            }
          })
        } else {
          self.batch = files
        }
      } else {
        self.undoStack.push(files)
      }
    }
  }
  replaceFiles(files) {
    const self = this
    const storageFiles = {...self.files}
    files.forEach(file => {
      storageFiles[file['meta3:path'] || '/'] = file
    })
    self.files = storageFiles;
  }
  async filePropertiesReplace(path, properties, withoutChanges) {
    const self = this
    let file = self.files[path]
    if (typeof file === 'undefined') {
      file = {'meta3:path': path}
    }
    file = {...file, 'meta3:properties': properties}
    if (self.batchLevel === 0) {
      file = await self.transformFile(file)
    }
    if (!withoutChanges) {
      self.storeResources([path])
    }
    self.replaceFiles([file])
  }
  async fileUpdate(path, update) {
    const self = this
    const inheritedUpdate = await Meta.makeInheritedUpdate(path, update, true)
    const exist =  self.existFile(path)
    if (exist) {
      const file = await self.getFile(path)
      const properties = file['meta3:properties'] || {'rbs:id': update['rbs:id']}
      const updated = Library.complexUpdate(properties, inheritedUpdate)
      if (updated.length === 1) {
        return self.filePropertiesReplace(path, updated[0])
      } else {
        return self.filePropertiesReplace(path, {'rbs:id': file['meta3:properties']['rbs:id']})
      }
    } else {
      return self.filePropertiesReplace(path, inheritedUpdate)
    }
  }
  async getFoldresItemsPaths(path) {
    const self = this
    if (!self.foldersItemsPaths[path]) {
      self.foldersItemsPaths[path] = Library.runFlow('in/meta/', 'META_InheritedList', {'meta3:path': path}, true).then(result => {
        const items = Library.ensureArray(result['meta3:item'])
        items.forEach(item => self.cacheFile(item))
        return items.map(item => item['meta3:path'])
      })
    }
    return self.foldersItemsPaths[path]
  }
  async list(path) {
    const self = this
    const itemsPaths = await self.getFoldresItemsPaths(path)
    return Promise.all(itemsPaths.map(path => self.getFile(path)))
  }
  async storeChanges() {
    const self = this
    const handlers = []
    if (self.filesNeedStore()) {
      await Promise.all(Object.keys(self.files).map(async (path) => {
        if (!Library.equals(self.files[path], self.storedFiles[path])) {
          let storedPropertiesArray;
          const storedFile = self.storedFiles[path]
          if (storedFile && storedFile['meta3:exist']) {
            storedPropertiesArray = [storedFile['meta3:properties']]
          } else {
            storedPropertiesArray = []
          }
          const file = await self.storeTransformFile(self.files[path])
          self.files[path] = file
          const properties =  file['meta3:properties']
          const updateProperties = await Library.makeComplexValuesUpdate(path, storedPropertiesArray, [properties], true)
          let update
          if (updateProperties.length > 0) {
            update = updateProperties[0]
          } else {
            update = null
          }
          await Library.updateProperties(path, update);
          return Promise.all(self.storeHandlers.map(async ({conceptPath, handler}) => {
            const isInstance = await Meta.pathIsInstanceOf(path, conceptPath);
              if (isInstance) {
                handlers.push(() => handler(file));
              }
          }))
        }
      }))
      self.storedFiles = self.files;
      return Promise.all(handlers.map(handler => handler()));
    } else {
      return Promise.resolve();
    }
  }
  async loadValue(ri) {
    const self = this
    const file = await self.getFile(ri)
    return file['meta3:properties']
  }

  referencedValue(basePath, reference) {
    const self = this
    const targetRi = Library.resolveReference(basePath, reference)
    return self.loadValue(targetRi)
  }

  async serverIsInstanceOf(basePath, value, conceptPath) {
    return Meta.isInstanceOf(basePath, value, conceptPath)
  }

  async isSubtypeOf(basePath, value, superPath) {
    const self = this
    const superObjectsReferencesValues = Library.ensureArray(value['meta3:subtypeOf'])
    return Library.asyncSome(superObjectsReferencesValues, async (referenceValue) => {
      const targetPath = Library.referenceResolve(referenceValue, basePath)
      return self.pathIsSubtypeOf(targetPath, superPath)
    })
  }

  async pathIsSubtypeOf(path, superPath) {
    if (path === superPath) {
      return Promise.resolve(true)
    } else {
      const file = await this.getFile(path)
      return this.isSubtypeOf(file['meta3:path'], file['meta3:properties'], superPath)
    }
  }
  
  async isInstanceOf(basePath, value, conceptPath) {
    const self = this
    const conceptsReferencesValues = Library.ensureArray(value['meta3:instanceOf'])
    return Library.asyncSome(conceptsReferencesValues, referenceValue => {
      const targetPath = Library.referenceResolve(referenceValue, basePath)
      return self.pathIsSubtypeOf(targetPath, conceptPath)
    })
  }

  async pathIsInstanceOf(path, conceptPath) {
    const self = this
    const file = await this.getFile(path)
    return self.isInstanceOf(path, file['meta3:properties'], conceptPath)
  }
}

Meta.Storage = Storage

Meta.storage = () => new Storage()
