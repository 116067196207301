import React from "../../../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "../../../../../app/src/library.js";
import {Meta} from "../../../../../app/src/meta.js";
import {Header} from './header.jsx';
import {Wireframe} from './wireframe.jsx';
import './template-editor.css';
import {HtmlPage} from '../../../../../app/src/html-page.jsx';
import {Inspector} from '../../../../../app/src/inspector.jsx';
import {CodeEditor} from '../../../../../app/src/code-editor.jsx'
import {CHub} from "../../../../../commhub/ui/app/src/library/c-hub.js";


const message = {};
message.addBeseUrlToImages = (message, imgBaseUrl) => {
  const reImg = new RegExp("<img([^>]* )src=\"([^\"]+)\"", "g");
  const reBack = new RegExp("url\\('([^\']+)'\\)", "g");
  message = message.replace(reImg,  (match, c1, c2) => {
    if (c2.startsWith('http://') || c2.startsWith('https://')) {
      return '<img' + c1 + 'src="' + c2 + '"';
    } else {
      return '<img' + c1 + 'src="' + imgBaseUrl + c2 + '"';
    }
  });
  message = message.replace(reBack, (match, c1) => {
    if (c1.startsWith('http://') || c1.startsWith('https://')) {
      return 'url(\'' + c1 + '\')';
    } else {
      return 'url(\'' + imgBaseUrl + c1 + '\')';
    }
  });
  return message;
}

const TemplateEditor = createReactClass({
  load: function(context) {
    const file = context.file;
    const template = file['meta3:properties'];
    const query = context.riValue.query;
    const imgBaseUrl = context.properties['app:landingPageUrl'] + 'content/' + context.model['meta3:path'] + 'img/';
    const displays = Library.ensureArray(template['mm:display'])
    const display = context.riValue.query.display || displays[0];
    const editHtml = Library.boolean(template['app:editHtml']);
    const showHidden = Library.boolean(query.showHidden);

    let layout = context.riValue.query.layout;



    const templateCss = template['app:css'];
    const templateHtml = template['app:templateHtml'];

    let componentPromise;
    const widths = {
      mobile: 363,
      tablet: 600,
      desktop: 800
    }
    const width = widths[display];

    let readOnly = context.readOnly;


    return Meta.pathIsInstanceOf(file['meta3:path'], 'commhub/concept/sms').then(isMessage => {

      const loadContext = {...context, imgBaseUrl, display, displays, editHtml, showHidden, isMessage};
      const headerPromise = Header.prototype.load(loadContext, file);
      if (!layout && editHtml || readOnly) {
        layout = 'preview';
      }

      if (layout === 'edit-html') {
        componentPromise = Promise.resolve({value: templateHtml,  contentType: 'text/html'});
      } else if (layout === 'edit-css') {
        componentPromise = CHub.templateCss(context.storage, file['meta3:path'], template).then(css => {
          return {value: css, contentType: 'text/css'}
        })
      } else if (layout === 'edit-properties') {
        componentPromise = Inspector.prototype.load(context, file, template);
      } else if (layout === 'preview') {
        const html = message.addBeseUrlToImages(templateHtml, imgBaseUrl);
        componentPromise = HtmlPage.prototype.load(html, imgBaseUrl, width + 'px');
      } else {
        componentPromise = Wireframe.prototype.load(loadContext, file);
      }
      return Promise.all([componentPromise, headerPromise]).then(([component, header]) => {
        return {
          component,
          editHtml,
          header,
          layout,
          display,
          file,
          readOnly
        }
      })
    })
  },
  onTemplateUpdate: function(update) {
    const component = this;
    const props = component.props;
    component.props.onUpdate({type: 'fileUpdate', path: props.file['meta3:path'], 'fileUpdate': update});
  },
  htmlUpdate: function(htmlValue) {
    const component = this;
    const update = {'app:templateHtml': htmlValue, 'app:editHtml': 'true'};
    component.onTemplateUpdate(update);
  },
  cssUpdate: function(css) {
    const component = this;
    const update = {
      'app:css': css,
      'dsl:templateStyle': {
          'rbs:ref': '/commhub/concept/template-style/own'
      }
    }
    component.onTemplateUpdate(update);
  },
  render: function() {
    const component = this;
    const props = component.props;
    const display = props.display;
    let className = display;
    var inner
    if (props.layout === 'edit-html') {
      inner = <CodeEditor {...props.component} onValueUpdate={component.htmlUpdate}/>;
    } else if (props.layout === 'edit-css') {
      inner = <CodeEditor {...props.component} onValueUpdate={component.cssUpdate}/>;
    } else if (props.layout === 'edit-properties') {
      inner = <Inspector {...props.component} onUpdate={props.onUpdate}/>
    } else if (props.editHtml || props.layout === 'preview') {
      inner =  (
         <div className="inner">
           <div className="mobile">
             <HtmlPage {...props.component}/>
           </div>
         </div>
      )
    } else {
      inner = <Wireframe {...props.component} onUpdate={props.onUpdate}/>
    }
    return (
      <div className="component template template-editor meta grow column">
        <Header {...props.header}
                onUpdate={props.onUpdate}
                readOnly={props.readOnly}/>
        <div className={"template meta column grow " + className}>
          {inner}
        </div>
      </div>
    )
  }
})

export {TemplateEditor};
