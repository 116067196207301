import {RB} from "./resourcebus.js";

var RBS  = {};

var PROPS_TYPE_1 = 'application/vnd.org.resourcebus.meta+json';
var PROPS_TYPE_2 = 'application/vnd.org.resourcebus.meta2+json';

RBS.getStorageResource = function(ri, nsVal) {
  return new Promise(function (resolve, reject) {
    RB.resource().method('GET').ri(ri).accept(PROPS_TYPE_1).load()
    .then(function (res) {
      if (res.status() == 200) {
        res.ns(nsVal);
        resolve(new RBS.StorageResource(res));
      } else {
        reject(new Error('Storage resource ' + ri + ' not initialized due to response status ' + res.status()));
      }
    })
    .catch(function (err) {
      reject(err);
    });
  });
}

function StorageResource(resource, properties, nsVal) {
  if (resource) {
    this.resource = resource;
    this.ri = resource.ri();
    this.props = resource.properties();
    this.nsVal = resource.ns();
    this._exists = resource.exists();
  } else if (properties) {
    this.resource = null;
    this.ri = properties['rbs:key'];
    this.nsVal = nsVal;
    this.props = properties;
    this._exists = true;
  } else {
    throw new Error('It is not possible to create Storage resource without resource or properties parameter!');
  }
  if (Array.isArray(this.props)) {
    this.propsColl = this.props;
  }
  this.userProps = {};
  if (this.props && typeof this.props === 'object') {
    for (var name in this.props) {
      if (this.props.hasOwnProperty(name) && name.indexOf('rbs:') != 0 || name == 'rbs:id') {
        this.userProps[name] = this.props[name];
      }
    }
  } else {
    this.props = {};
  }
  this.userCollProps = [];
  if (this.propsColl && Array.isArray(this.propsColl)) {
    for (var i=0; i<this.propsColl.length; i++) {
      var up = {};
      var name;
      var coll = this.propsColl[i];
      for (var name in coll) {
        if (coll.hasOwnProperty(name) && name.indexOf('rbs:') != 0 || name == 'rbs:id' || name == 'rbs:key') {
          up[name] = this.propsColl[i][name];
        }
      }
      this.userCollProps[i] = up;
    }
  }
}

StorageResource.prototype.ns = function(nsVal) {
  if (arguments.length != 0) {
    for (var attrname in nsVal) {
      this.nsVal[attrname] = nsVal[attrname];
    }
    return this;
  } else {
    return this.nsVal;
  }
};

// interface StorageResource.java
StorageResource.prototype.isSingle = function() {
  return this.userCollProps.length == 0;
};
StorageResource.prototype.isCollection = function() {
  return this.userCollProps.length != 0;
};
StorageResource.prototype.path = function() {
  return new RB.URLUtils(this.ri, "").pathname;
};

// interface FileResource.java
StorageResource.prototype.currentRI = function() {
  return this.ri;
};
StorageResource.prototype.lastModified = function() {
  throw new Error('It is not possible to call lastModified. Not supported.');
};
StorageResource.prototype.hash = function() {
  return this.props['rbs:hash'];
};
StorageResource.prototype.isRoot = function() {
  var path = this.path();
  return !path;
};
StorageResource.prototype.parent = function(argsObj) {
  return doGetAction(this, 'rbs:parent', argsObj);
};
StorageResource.prototype.isVersioned = function() {
  return this.props['rbs:isVersioned'] === 'true' || this.props['rbs:isVersioned'] === true;
};
StorageResource.prototype.isFolder = function() {
  return this.props['rbs:isFolder'] === "true" || this.props['rbs:isFolder'] === true;
};
StorageResource.prototype.hasProperties = function() {
  return this.props['rbs:hasProperties'] === 'true' || this.props['rbs:hasProperties'] === true;
};
StorageResource.prototype.properties = function() {
  return this.userProps;
};
StorageResource.prototype.storeProperties = function(properties) {
  var that = this;
  return new Promise(function (resolve, reject) {
    RB.resource().method('PUT').ri(that.path()).ns(that.nsVal).content(properties).contentType(PROPS_TYPE_1).accept(PROPS_TYPE_1).load()
    .then(function (res) {
      if (res.statusVal >= 400) { // reject on server error status
        reject({status: res.statusVal, content: res.contentVal });
      }
      resolve();
    })
    .catch(function (err) {
      reject(err);
    });
  });
};
StorageResource.prototype.updateProperties = function(properties) {
  var that = this;
  return new Promise(function (resolve, reject) {
    // TODO: should use rbs:update template that needs updatesingle support added
    RB.resource().method('POST').ri(that.path()+'?updatesingle=true').ns(that.nsVal).content(properties).contentType(PROPS_TYPE_1).accept(PROPS_TYPE_1).load()
    .then(function (res) {
      resolve(true);
    })
    .catch(function (err) {
      reject(err);
    });
  });
};
StorageResource.prototype.deleteProperties = function() {
  var that = this;
  return new Promise(function (resolve, reject) {
    RB.resource().method('DELETE').ri(that.path()).accept(PROPS_TYPE_1).load()
    .then(function (res) {
      resolve(true);
    })
    .catch(function (err) {
      reject(err);
    });
  });
};
StorageResource.prototype.backReferences = function(argsObj) {
  return doGetAction(this, 'rbs:backReferences', argsObj);
};
StorageResource.prototype.forwardReferences = function(argsObj) {
  return doGetAction(this, 'rbs:forwardReferences', argsObj);
};
StorageResource.prototype.hasStaticContent = function() {
  return this.props['rbs:hasContent'] === 'true' || this.props['rbs:hasContent'] === true;
};
StorageResource.prototype.getContentLength = function() {
  if (this.props['rbs:contentLength']) {
    return Number(this.props['rbs:contentLength']);
  } else {
    return null;
  }
};
StorageResource.prototype.staticContent = function() { //TODO: static content
  throw new Error('It is not possible to call staticContent. Not implemented yet.');
};
StorageResource.prototype.storeStaticContent = function() { //TODO: store static content
  throw new Error('It is not possible to call storeStaticContent. Not implemented yet.');
};
StorageResource.prototype.deleteStaticContent = function() { //TODO: delete static content
  throw new Error('It is not possible to call deleteStaticContent. Not implemented yet.');
};
StorageResource.prototype._delete = function(argsObj) {
  return doDeleteAction(this, 'rbs:delete', argsObj);
};
StorageResource.prototype.relativeFile = function(argsObj) {
  return doGetAction(this, 'rbs:relativeFile', argsObj);
};
StorageResource.prototype.refresh = function() {
  return RBS.getStorageResource(this.ri, this.nsVal);
};
StorageResource.prototype.store = function(propertiesArray) {
  return doPostAction(this, 'rbs:store', {}, propertiesArray);
};

// interface VersionedFile.java
StorageResource.prototype.version = function() {
  var tokens = this.path().split('/');
  var version = null;
  for (var i=0; i<tokens.length; i++) {
    var k = tokens[i].indexOf(';v=');
    if (k != -1) {
      version = tokens[i].substring(k + 3);
    }
  }
  if (version == 'master' || version == '') {
    version = null;
  }
  return version;
};
StorageResource.prototype.verInfo = function(argsObj) {
  return doGetAction(this, 'rbs:verInfo', argsObj)
  .then(function(storageResource) {
    return new RBS.VerInfo(storageResource.props);
  }, function(errorThrown) {
    return errorThrown;
  });
};
StorageResource.prototype.switchVersion = function(version) {
  if (!version) {
    throw new Error('Version must be passed as first argument of switchVersion.');
  }
  return doGetAction(this, 'rbs:switchVersion', {version: version});
};
StorageResource.prototype.versioningRootPath = function() {
  return this.props['rbs:versioningRoot'];
};
StorageResource.prototype.versioningRoot = function() {
  return RBS.getStorageResource(this.versioningRootPath(), this.nsVal);
};
StorageResource.prototype.newCommit = function(argsObj) {
  return doPostAction(this, 'rbs:newCommit', argsObj);
};
StorageResource.prototype.revert = function(argsObj) {
  return doPostAction(this, 'rbs:revert', argsObj);
};
StorageResource.prototype.baseStatus = function(argsObj) {
  return doGetAction(this, 'rbs:baseStatus', argsObj)
  .then(function(storageResource) {
    var props = storageResource.properties();
    return new RBS.BaseStatus(props['rbs:baseStatus']);
  }, function(errorThrown) {
    return errorThrown;
  });
};
StorageResource.prototype.baseCommit = function(argsObj) {
  return doGetAction(this, 'rbs:baseCommit', argsObj)
  .then(function(storageResource) {
    return new RBS.Commit(storageResource.props['rbs:baseCommit'][0]);
  }, function fail(errorThrown) {
    return errorThrown;
  });
};

// interface Folder.java
StorageResource.prototype.child = function(argsObj) {
  return doGetAction(this, 'rbs:child', argsObj);
};
StorageResource.prototype.list = function(argsObj) {
  return doGetAction(this, 'rbs:list', argsObj);
};
StorageResource.prototype.search = function(argsObj) {
  return doGetAction(this, 'rbs:search', argsObj);
};
StorageResource.prototype.replace = function(argsObj) {
  return doPostAction(this, 'rbs:replace', argsObj);
};
StorageResource.prototype.update = function(argsObj, propertiesArray) {
  return doPostAction(this, 'rbs:update', argsObj, propertiesArray);
};
StorageResource.prototype.makeFolder = function(argsObj, properties) {
  return doPostAction(this, 'rbs:newFolder', argsObj, properties);
};
StorageResource.prototype._export = function(argsObj) {
  return doGetAction(this, 'rbs:export', argsObj);
};
StorageResource.prototype._import = function(argsObj, propertiesArray) {
  return doPostAction(this, 'rbs:import', argsObj, propertiesArray);
};

// interface VersionedFolder.java
StorageResource.prototype.isVersioningRoot = function() {
  return this.props['rbs:isVersioningRoot'] === 'true' || this.props['rbs:isVersioningRoot'] === true;
};
StorageResource.prototype.newBranch = function(argsObj, propertiesArray) {
  return doPostAction(this, 'rbs:newBranch', argsObj, propertiesArray);
};
StorageResource.prototype.deleteBranch = function(argsObj) {
  return doDeleteAction(this, 'rbs:deleteBranch', argsObj);
};
StorageResource.prototype.newTag = function(argsObj, propertiesArray) {
  return doPostAction(this, 'rbs:newTag', argsObj, propertiesArray);
};
StorageResource.prototype.deleteTag = function(argsObj) {
  return doDeleteAction(this, 'rbs:deleteTag', argsObj);
};

StorageResource.prototype.fastForwardMerge = function(what) {
  if (!what) {
    throw new Error('Parameter "what" must have value specified.');
  }
  return doPostAction(this, 'rbs:fastForwardMerge', {what: what})
  .then(function(resource) {
    resource = new RBS.StorageResource(resource);
    return new RBS.ChangeSet(resource.props['rbs:changeSet'][0]);
  }, function(errorThrown) {
    return errorThrown;
  });
};

StorageResource.prototype.rebase = function(to) {
  if (!to) {
    throw new Error('Parameter "to" must have value specified.');
  }
  return doPostAction(this, 'rbs:rebase', {to: to})
  .then(function(resource) {
    resource = new RBS.StorageResource(resource);
    return new RBS.ChangeSet(resource.props['rbs:changeSet'][0]);
  }, function(errorThrown) {
    return errorThrown;
  });
};

StorageResource.prototype.diff = function(revision) {
  return doGetAction(this, 'rbs:diff', {revision: revision})
  .then(function(resource) {
    return new RBS.ChangeSet(resource.props['rbs:changeSet'][0]);
  }, function(errorThrown) {
    return errorThrown;
  });
};

StorageResource.prototype.diff3 = function(argsObj) {
  return doPostAction(this, 'rbs:diff3', argsObj)
  .then(function(resource) {
    resource = new RBS.StorageResource(resource);
    return new RBS.Diff3ChangeSet(resource.props['rbs:diff3ChangeSet'][0]);
  }, function(errorThrown) {
    return errorThrown;
  });
};

// interface NonTraversableFolderListing.java
StorageResource.prototype.itemsProperties = function() {
  return this.userCollProps;
};
StorageResource.prototype.itemsPropertiesMap = function() {
  if (Array.isArray(this.userCollProps)) {
    var result = {};
    for (var i=0; i<this.userCollProps.length; i++) {
      result[this.userCollProps[i]['rbs:key']] = this.userCollProps[i];
    }
    return result;
  } else {
    return null;
  }
};
StorageResource.prototype.folder = function() {
  return this.parent();
};
StorageResource.prototype.filterExpression = function() {
  var params = queryParameters(this.currentRI());
  return  decodeURIComponent(params['query']);
};
StorageResource.prototype.filterExpression2 = function() {
  var params = queryParameters(this.currentRI());
  return decodeURIComponent(params['query2']);
};
StorageResource.prototype.namesFilter = function() {
  var params = queryParameters(this.currentRI());
  return params['oids'];
};
StorageResource.prototype.orderBy = function() {
  var params = queryParameters(this.currentRI());
  return decodeURIComponent(params['orderby']);
};
StorageResource.prototype.pageNumber = function() {
  var params = queryParameters(this.currentRI());
  return params['pagenumber'];
};
StorageResource.prototype.pageSize = function() {
  var params = queryParameters(this.currentRI());
  return params['pagesize'];
};
StorageResource.prototype.shallow = function() {
  var params = queryParameters(this.currentRI());
  return params['shallow'] == 'true';
};
StorageResource.prototype.totalUnpagedCount = function() {
  if (this.resource) {
    var content = resource.content();
    if (!content || isNaN(content['rbs:totalCount'])) {
      return 0;
    } else {
      return Number(content['rbs:totalCount']);
    }
  } else {
    return 0;
  }
};

// interface FolderListing.java
StorageResource.prototype.items = function() {
  var result = [];
  if (Array.isArray(this.userCollProps)) {
    for (var i=0; i<this.userCollProps.length; i++) {
      result.push(new RBS.StorageResource(null, this.userCollProps[i], this.nsVal));
    }
  }
  return result;
};
StorageResource.prototype.itemsMap = function() {
  if (Array.isArray(this.userCollProps)) {
    var result = {};
    for (var i=0; i<this.userCollProps.length; i++) {
      result[this.userCollProps[i]['rbs:key']] = new RBS.StorageResource(null, this.userCollProps[i], this.nsVal);
    }
    return result;
  } else {
    return null;
  }
};
StorageResource.prototype.item = function(i) {
  if (isNaN(i)) {
    throw new Error('Item index must be positive integer!');
  }
  var items = this.items();
  if (i >= items.length) {
    throw new Error('Item index is out of bound!');
  }
  return items[i];
};

StorageResource.prototype.exists = function() {
  return this._exists;
};
StorageResource.prototype.stats = function(argsObj) {
  return doGetAction(this, 'rbs:stats', argsObj); //TODO: return special stats object similar like in base status?
};


/* section with internal methods */
function riFromTemplate(ri, urit, argsObj) {
  return RB.resolve(RB.evaluateUriTemplate(urit, argsObj), ri);
};

function queryParametres(ri) {
  var queryParameters = {};
  var re = /([^&=]+)=([^&]*)/g;
  var m;
  while (m = re.exec(ri)) {
    name = decodeURIComponent(m[1]);
    if (queryParameters[name] != null) {
      if (Array.isArray(queryParameters[name])) {
        queryParameters[name].push(decodeURIComponent(m[2]));
      } else {
        var arr = Array();
        arr.push(queryParameters[name]);
        arr.push(decodeURIComponent(m[2]));
        queryParameters[name] = arr;
      }
    } else {
      queryParameters[name] = decodeURIComponent(m[2]);
    }
  }
  return queryParameters;
};

function doGetAction(that, action, argsObj) {
  var urit = that.props[action];
  if (typeof urit !== 'string') {
    throw new Error('It is not possible to call ' + action + ' on storage resource: ' + that.ri);
  }
  return RBS.getStorageResource(riFromTemplate(that.ri, urit, argsObj), that.nsVal);
}

function doPostAction(that, action, argsObj, props) {
  var urit = that.props[action];
  if (typeof urit !== 'string') {
    throw new Error('It is not possible to call ' + action + ' on storage resource: ' + that.ri);
  }
  var ri = riFromTemplate(that.ri, urit, argsObj);
  return new Promise(function (resolve, reject) {
    var res = RB.resource().method('POST').ri(ri).ns(that.nsVal).accept(PROPS_TYPE_1);
    if (props) {
      res.contentType(PROPS_TYPE_1).content(props);
    }
    res.load()
    .then(function (res) {
      resolve(true);
    })
    .catch(function (err) {
      reject(err);
    });
  });
}

function doDeleteAction(that, action, argsObj) {
  var urit = that.props[action];
  if (typeof urit !== 'string') {
    throw new Error('It is not possible to call ' + action + ' on storage resource: ' + that.ri);
  }
  var ri = riFromTemplate(that.ri, urit, argsObj);
  return new Promise(function (resolve, reject) {
    var res = RB.resource().method('DELETE').ri(ri).accept(PROPS_TYPE_1).load()
    .then(function (res) {
      resolve(true);
    })
    .catch(function (err) {
      reject(err);
    });
  });
}

RBS.BaseStatus = function BaseStatus(props) {
  this.props = props;
  this.code = function code() {
    return props['rbs:baseStatusCode'];
  };
  this.branches = function branches() {
    return props['rbs:name'];
  };
};

RBS.VerInfo = function VerInfo(props) {
  this.props = props;
  this.commitsArr = [];
  if (props['rbs:commit']) {
    for (var i=0; i<props['rbs:commit'].length; i++) {
      this.commitsArr.push(new RBS.Commit(props['rbs:commit'][i]));
    }
  }
  this.branchesArr = [];
  if (props['rbs:branch']) {
    for (var i=0; i<props['rbs:branch'].length; i++) {
      this.branchesArr.push(new RBS.Branch(props['rbs:branch'][i]));
    }
  }
  this.tagsArr = [];
  if (props['rbs:tag']) {
    for (var i=0; i<props['rbs:tag'].length; i++) {
      this.tagsArr.push(new RBS.Tag(props['rbs:tag'][i]));
    }
  }
  this.status = function status() {
    return props['rbs:status'];
  };
  this.deepStatus = function deepStatus() {
    return props['rbs:deepStatus'];
  };
  this.commits = function commits() {
    return this.commitsArr;
  };
  this.branches = function branches() {
    return this.branchesArr;
  };
  this.tags = function tags() {
    return this.tagsArr;
  };
};

RBS.Commit = function Commit(props) {
  this.props = props;
  this.author = function author() {
    return props['rbs:author'];
  };
  this.commitId = function commitId() {
    return props['rbs:commitId'];
  };
  this.committer = function committer() {
    return props['rbs:committer'];
  };
  this.message = function message() {
    return props['rbs:message'];
  };
  this.time = function time() {
    return props['rbs:time'];
  };
};

RBS.Branch = function Branch(props) {
  this.props = props;
  if (props['rbs:commit'] && props['rbs:commit'][0]) {
    this.commitObj = new RBS.Commit(props['rbs:commit'][0])
  }
  this.name = function name() {
    return props['rbs:name'];
  };
  this.commit = function commit() {
    return this.commitObj;
  };
};

RBS.Tag = function Tag(props) {
  this.props = props;
  if (props['rbs:commit'] && props['rbs:commit'][0]) {
    this.commitObj = new RBS.Commit(props['rbs:commit'][0])
  }
  this.name = function name() {
    return props['rbs:name'];
  };
  this.message = function message() {
    return props['rbs:message'];
  };
  this.commit = function commit() {
    return this.commitObj;
  };
};

RBS.ChangeSet = function ChangeSet(props) {
  this.createdArr = [];
  this.modifiedArr = [];
  this.removedArr = [];
  this.allArr;
  if (Array.isArray(props['rbs:created'])) {
    for (var i=0; i<props['rbs:created'].length; i++) {
      this.createdArr.push(props['rbs:created'][i]);
    }
  } else if (typeof props['rbs:created'] == 'string') {
    this.createdArr.push(props['rbs:created']);
  }
  if (Array.isArray(props['rbs:modified'])) {
    for (var i=0; i<props['rbs:modified'].length; i++) {
      this.modifiedArr.push(props['rbs:modified'][i]);
    }
  } else if (typeof props['rbs:modified'] == 'string') {
    this.modifiedArr.push(props['rbs:modified']);
  }
  if (Array.isArray(props['rbs:removed'])) {
    for (var i=0; i<props['rbs:removed'].length; i++) {
      this.removedArr.push(props['rbs:removed'][i]);
    }
  } else if (typeof props['rbs:removed'] == 'string') {
    this.removedArr.push(props['rbs:removed']);
  }
  this.allArr = this.createdArr + this.modifiedArr + this.removedArr;
  this.created = function created() {
    return this.createdArr;
  };
  this.modified = function modified() {
    return this.modifiedArr;
  };
  this.removed = function removed() {
    return this.removedArr;
  };
  this.all = function all() {
    return this.allArr;
  };
};

RBS.Diff3ChangeSet = function Diff3ChangeSet(props) {
  this.thisChangeSetObj = null;
  this.otherChangeSetObj = null;
  this.conflictingArr = null;
  if (props['rbs:thisChangeSet']) {
    this.thisChangeSetObj = new RBS.ChangeSet(props['rbs:thisChangeSet'][0]);
  }
  if (props['rbs:otherChangeSet']) {
    this.otherChangeSetObj = new RBS.ChangeSet(props['rbs:otherChangeSet'][0]);
  }
  if (Array.isArray(props['rbs:conflicting'])) {
    for (var i=0; i<props['rbs:conflicting'].length; i++) {
      this.conflictingArr.push(props['rbs:conflicting'][i]);
    }
  } else if (typeof props['rbs:conflicting'] == 'string') {
    this.conflictingArr.push(props['rbs:conflicting']);
  }
  this.thisChangeSet = function thisChangeSet() {
    return this.thisChangeSetObj;
  };
  this.otherChangeSet = function otherChangeSet() {
    return this.otherChangeSetObj;
  };
  this.conflicting = function conflicting() {
    return this.conflictingArr;
  };
};

RBS.StorageResource = StorageResource;

export {RBS};


//function printProps(storageResource) {
//  console.log(JSON.stringify(storageResource.properties(), null, 2))
//}

//function printPropsColl(storageResource) {
//  console.log(JSON.stringify(storageResource.itemsProperties(), null, 2))
//}

//RB.addNS('http://metarepository.com/meta/mta#', 'mta');

//RBS.getStorageResource("meta;v=2.13.x/mta/Concept/111").done(function(fileResource) {
//  printProps(fileResource);
//  fileResource.backReferences().done(printPropsColl);
//});

//RBS.getStorageResource("meta;v=2.13.x/mta/Concept/111").done(function(fileResource) {
//  printProps(fileResource);
//  fileResource.baseStatus({primarybranch: '2.13.x', otherbranches: ['2.12.x', '2.11.x']}).done(function done(baseStatus) {
//    console.log(baseStatus.code());
//    console.log(baseStatus.branches());
//  });
//});
