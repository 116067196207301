import React from "../../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "../../../../app/src/library.js";
import {SaveButton} from '../../../../app/src/save-button.jsx';
import {UndoRedoButtons} from '../../../../app/src/undo-redo-buttons.jsx';
import { Dropdown } from 'semantic-ui-react'

const DiagramEditorHeader = createReactClass({
  load: function(context) {
    const file = context.file;
    const inheritedProperties = Library.fileInheritedProperties(file);
    const errorMessage = inheritedProperties['chub:error'];
    const saveButton = SaveButton.prototype.load(context);
    const undoRedoButtons = UndoRedoButtons.prototype.load(context);
    const validations = Library.ensureArray(inheritedProperties['chub:validation'])
    return {errorMessage, saveButton, undoRedoButtons, filePath: file['meta3:path'], validations, readOnly: context.readOnly};
  },
  render: function() {
    const component = this;
    const props = component.props;
    let deleteButtonClassName = "ui icon button delete";
    if (props.readOnly) {
      deleteButtonClassName += " disabled";
    }
    const saveDeleteItem = (
      <div className="item">
        <SaveButton {...props.saveButton}
          onUpdate={props.onUpdate}/>
        <button className={deleteButtonClassName} title="Delete" onClick={() => props.onUpdate({type:'routeTo', ri: props.filePath + '/delete'})}>
          <i className="icon trash alternate"></i>
        </button>
      </div>
    );
    const undoRedoItem = (
       <div className="item">
         <UndoRedoButtons {...props.undoRedoButtons}
           onUpdate={props.onUpdate}/>
       </div>
    );
    const zoomItem = (
      <div className="meta menu-item">
        <div className="ui icon buttons">
          <div className="ui button zoomin flat" onClick={props.onZoomIn}>
            <i className="zoom icon"></i>
          </div>
          <div className="ui button zoomout flat" onClick={props.onZoomOut}>
            <i className="zoom out icon"></i>
          </div>
        </div>
      </div>
    );
    let editButtonClassName = "ui button flat divider-button";
    if (props.readOnly) {
      editButtonClassName += " disabled";
    }
    let cloneButtonClassName = "ui button flat";
    if (props.readOnly) {
      cloneButtonClassName += " disabled";
    }

    let validations
    if (props.validations.length === 0) {
      validations = 'Valid'
    } else {
      validations = (
        <Dropdown trigger={<span style={{color: 'red'}}>Invalid ({props.validations.length})</span>}>
        <Dropdown.Menu>
          {props.validations.map(validation => <Dropdown.Item key={validation['rbs:id']} text={validation['chub:message']} onClick={(event, data) => {
            console.log(event, data)
            if (validation['chub:nodeId']) {
              props.onUpdate({type:'routeTo', ri: props.filePath + '#' + validation['chub:nodeId']})
            }
          }}/>)}
        </Dropdown.Menu>
      </Dropdown>
      )
    }


    const actionsItem = (
      <div className="meta menu-item">
        <div className="ui icon buttons">
          <button className={editButtonClassName} title="Properties" onClick={() => props.onUpdate({type:'routeTo', ri: props.filePath + '/properties'})}>
            <i className="icon edit"></i>
          </button>
          <button className={cloneButtonClassName} title="Clone" onClick={() => props.onUpdate({type:'routeTo', ri: props.filePath + '/clone'})}>
            <i className="icon copy"></i>
          </button>
        </div>
      </div>
    );
    return (
      <div className="meta menu top component">
        {zoomItem}
        {undoRedoItem}
        {actionsItem}
        <div className="meta right menu">
          <div className="meta item" style={{color: 'red'}}>
            {props.errorMessage}
          </div>
          <div className="meta item">
          {validations}
          </div>
          
          {saveDeleteItem}
        </div>
      </div>
    )
  }
});

export {DiagramEditorHeader};
