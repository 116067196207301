import React from "../../../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "../../../../../app/src/library.js";
import {InlineEditor} from '../../../../../app/src/inline-editor.jsx';

function cssNameToJsName(name) {
  var split = name.split("-");
  var output = "";
  for(var i = 0; i < split.length; i++)
  {
      if (i > 0 && split[i].length > 0 && !(i == 1 && split[i] == "ms"))
      {
          split[i] = split[i].substr(0, 1).toUpperCase() + split[i].substr(1);
      }
      output += split[i];
  }
  return output;
}

const Renderer = createReactClass({
  handleContentUpdate: function(content, html) {
    const component = this;
    const props = component.props;
    const update = {'app:content': [{'app:value': html, 'rbs:id': content['rbs:id']}], 'rbs:id': props.id};
    component.props.onValueUpdate(update);
  },
  load: function(context, renderer) {
    const children = Library.ensureArray(renderer['app:child']).map(child => Renderer.prototype.load(context, child));
    const style = Library.ensureArray(renderer['app:cssDeclaration']).reduce((result, declaration) => {
      result[cssNameToJsName(declaration['app:cssProperty'])] = declaration['app:cssValue'];
      return result;
    }, {})
    let content =  renderer['app:content'];
    return {
      isImage: renderer['app:type'] === 'image',
      editable: Library.boolean(renderer['app:editable']),
      contents: Library.sortComplex(Library.ensureArray(content)),
      children: children,
      style: style,
      id: renderer['rbs:id'],
      modelPath: context.model['meta3:path'],
      baseUrl: context.properties['app:landingPageUrl'] + 'content/' + context.model['meta3:path'] + 'img/'
    }
  },
  render: function() {
    const component = this;
    const props = component.props;
    const screenDisplay = props.screenDisplay;
    const containerStyle = {
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '10px',
      paddingRight: '10px',
    };
    const columnStyle = {width: '100%', paddingTop: '9px', paddingBottom: '9px'}
    if (screenDisplay === 'desktop') {
      containerStyle.maxWidth = '600px';
      containerStyle.margin = '0 auto';
      if (props.contents.length === 2) {
        columnStyle.width = '280px';
        columnStyle.float = 'left';
      } else if (props.contents.length === 3) {
        columnStyle.width = '190px';
        columnStyle.float = 'left';
      }
    } else if (screenDisplay === 'tablet') {
       containerStyle.maxWidth = '430px';
       containerStyle.margin = '0 auto';
       if (props.contents.length === 2) {
         columnStyle.width = '200px';
         columnStyle.float = 'left';
       }
    }
    const columns = props.contents.map((content, index) => {
      const display = content['app:display'];
      const outerStyle = {...columnStyle};
      const innerStyle = {}
      let className = '';
      if (display === 'button') {
        innerStyle.textDecoration = 'none';
        innerStyle.color = '#FFF';
        innerStyle.backgroundColor = 'rgb(202, 202, 202)';
        innerStyle.padding = '10px 16px';
        innerStyle.fontWeight = 'bold';
        innerStyle.marginRight = '10px';
        innerStyle.textAlign = 'center';
        innerStyle.display = 'inline-block';
        if (screenDisplay === 'mobile') {
          innerStyle.width = '100%';
        }
        className = 'button';
      }

      const align = content['app:align'];
      if (align) {
        outerStyle.textAlign = align;
      }
      let type;
      if (display === 'plainText') {
        type = 'plainText'
      }

      return (
       <div style={outerStyle} className={className} key={content['rbs:id']}>
         <InlineEditor
           key={index}
           type={type}
           style={innerStyle}
           html={content['app:value']}
           placeholders={props.placeholders}
           onUpdate={value => component.handleContentUpdate(content, value)}
           baseUrl={props.baseUrl}
           modelPath={props.modelPath}/>
       </div>
      )
    })

    return <div style={containerStyle}>{columns}</div>;

  }
});

export {Renderer};
