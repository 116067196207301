import React from "../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';

const Loading = createReactClass({
  render: function() {
    return <div className="application-loader meta vertical-center-content"><div className="ui active centered inline loader"></div></div>;
  }
});

export {Loading};
