import React from "react";

import {MC} from './MC.js';

class MenuButton extends React.Component {

  buildButtons(field) {
    const iteration = this.props.iteration;
    let buttons = [];
    let keys = MC.asArray(MC.getFieldParamValue(field.param, 'items*/@key', iteration));
    if (keys.length > 0) {
      let titles = field.param['items*'] && field.param['items*']['@title'] ? MC.asArray(MC.getFieldParamValue(field.param, 'items*/@title', iteration)) : Array(keys.length).fill(null);
      let icons = MC.asArray(MC.getFieldParamValue(field.param, 'items*/@icon', iteration));
      let urls = MC.asArray(MC.getFieldParamValue(field.param, 'items*/@imageUrl', iteration));
      let enabled = MC.asArray(MC.getFieldParamValue(field.param, 'items*/@enabled', iteration));
      let tooltips = MC.asArray(MC.getFieldParamValue(field.param, 'items*/@tooltip', iteration));
      for (let i = 0; i < keys.length; i++) {
        let icon = null;
        if (!MC.isNull(urls[i])) {
          let imageUrl = MC.rebaseUrl(field.flow.flow.model, urls[i]);
          icon = <img src={imageUrl} className={icons[i]}/>;
        } else if (!MC.isNull(icons[i])) {
          icon = <i className={icons[i]}></i>;
        }
        let btnCls = "ui button";
        let iEnabled = enabled[i] == false ? false : true;
        if (!iEnabled) {
          btnCls += " disabled";
        }
        buttons.push(<button key={'b' + i} title={tooltips[i]} className={btnCls} onClick={() => this.handleClick(i)}>{icon}{titles[i]}</button>);
      }
    }
    return buttons;
  }

  handleClick(i) {
    let field = this.props.data;
    const iteration = this.props.iteration;
    MC.putFieldParamValue(field.param, 'value', iteration, MC.getFieldParamValue(field.param, 'items*/@key', iteration)[i]);
    let behavior = MC.getFieldParamValue(field.param, '@behavior', iteration);
    if (behavior == 'url') {
      let url = MC.asArray(MC.getFieldParamValue(field.param, 'items*/@url', iteration))[i];
      if (window && !MC.isNull(url) && url !== '') {
        let newWindow =  MC.getFieldParamBooleanValue(field.param, '@newWindow', iteration);
        if (newWindow) {
          window.open(url);
        } else {
          window.location.href = url;
        }
      }
    } else if (behavior == 'dialog') {
      let dialogAction = MC.getFieldParamValue(field.param, '@dialogAction', iteration);
      let dRes = field.flow.callLogicAction({dataAction: dialogAction}, field, iteration);
      if (!MC.isNull(dRes)) {
        field.flow.reactFlow.setState({dialog: {flowName: dRes.flowName, input: dRes.input, actionCode: dialogAction, triggeredByField: field, iteration: this.props.iteration,
            size: dRes.size, start: true, parentFlow: field.flow}});
      }
    } else {
      if (MC.isFunction(field.onSubmit) && !MC.isModelerActive(field)) {
        field.onSubmit(field, iteration);
      }
    }
  }

  render() {
    let field = this.props.data;
    const iteration = this.props.iteration;
    let cls = MC.getFieldParamValue(field.param, '@cssClass', iteration);
    cls = cls ? 'ui buttons ' + cls : 'ui buttons';
    let fitWidth = MC.getFieldParamBooleanValue(field.param, '@fitWidth', iteration);
    if (fitWidth) {
      cls += ' fluid';
    }
    let type =  MC.getFieldParamValue(field.param, '@type', iteration);
    if (type == 'vertical') {
      cls += ' vertical';
    }
    return (
      <div className={cls} data-widget-i-name={field.id}>
        {this.buildButtons(field)}
      </div>
    );
  }

}

export {MenuButton};