import React from "react";
import ReactDOM from "react-dom";

import {WidgetModel} from "./WidgetModel.js";

class CheckboxOption extends React.Component {

  setValue(value, indeterminate) {
    var $node = this.get$node();
    if (indeterminate) {
      $node.checkbox("set indeterminate");
    } else {
      if (value) {
        $node.checkbox("set checked");
      } else {
        $node.checkbox("set unchecked");
      }
    }
  }
  
  get$node() {
    return $(ReactDOM.findDOMNode(this));
  }
  
  componentWillReceiveProps(nextProps) {
    this.setValue(nextProps.value, nextProps.indeterminate);
  }
  
  componentDidMount() {
    var $node = this.get$node();
    var self = this;
    $node[0].addEventListener("keydown", function (event) {
      if (event.key == "Enter") {
        // disable so that the value is not changed after enter on the inspector
        $node.checkbox("set disabled"); 
      }
    });
    $node.checkbox({onChecked() {self.props.onChange(true)},
                    onUnchecked() {self.props.onChange(false)}});
    this.setValue(this.props.value, this.props.indeterminate);
  }
  
  render () {
    return (<div className="ui form fitted checkbox">
              <input type="checkbox"/>
            </div>);
  }
}

class DropdownOption extends React.Component {

  get$node() {
    return $(ReactDOM.findDOMNode(this));//.find(".ui.dropdown");
  }
  
  setValue(value, indeterminate) {
    var $node = this.get$node();
    try {
      this.blockedChange = true;
      if (indeterminate) {
        $node.dropdown("clear");
        //$node.dropdown("set selected", undefined);
        $node.dropdown("set placeholder text", 'nerozhodnuto');
        //$node.dropdown("clear");
      } else {
        if (typeof value == "undefined") {
          $node.dropdown("clear");
        } else {
          $node.dropdown("set selected", value);
        }
      }

    } finally {
      this.blockedChange = false;
    }
  }
  
  componentWillReceiveProps(nextProps) {
    this.setValue(nextProps.value, nextProps.indeterminate);
  }
  
  componentDidMount() {
    var self = this;
    var $node = this.get$node();
    $node.dropdown({onChange (value, text, $selectedItem) {
      if (!self.blockedChange) {
        self.props.onChange(value);
      }
    }/*, action(text, value, element) {
      console.log("dropdown: ", arguments)
    }*/});
    this.setValue(this.props.value, this.props.indeterminate);
  }
  
  render () {
    var defaultText;
    var items = this.props.items.map(e => <div key={e} className="item" data-value={e}>{e}</div>);
    return <div className="ui mini form fluid selection dropdown">
              <input type="hidden" name={name}/>
              <i className="dropdown icon"/>
              <div className="default text"/>
              <div className="menu">
                <div key="" className="item" data-value=""/>
                {items}
              </div>
            </div>;
  }
}

class InputOption extends React.Component {

  handleChange = (event) => {
    this.props.onChange(event.target.value);
  };
  
  handleFocus = (event) => {
    this.props.onFocus(event.target.value);
  };

  render() {
    var cls = "ui mini input fluid";
    if (!this.props.valid) {
      cls += " error";
    }
    var placeholder;
    if (this.props.indeterminate) {
      placeholder = "nerozhodnuto";
    }

    return <div className={cls}>
             <input type = "text"
                    placeholder={placeholder}
                    value = {this.props.value}
                    onChange = {this.handleChange}
                    onFocus = {this.handleFocus}/>
            </div>;
  }
}

class InspectorOption extends React.Component {

  state = {value: this.getOptionValue(), valid: true};

  getOptionValue() {
    return this.props.onOptionValue(this.props.option.path);
  }

  changeValue(value) {
    var path = this.props.option.path;
    try {
      this.props.onSetOptionValue(path, value);
        this.setState({valid: true});
        /*if (path.length == 1 && path[0] == "id") {
          this.props.onSetSelectedField(value);
        }*/
        this.props.onFormWasChanged();
        if (!this.stored) {
          this.stored = true;
          this.props.onStoreForm(this.form);
        } else if (typeof this.initValue != "undefined" && this.initValue == value) {
          this.stored = false;
          this.props.onCancelChange();
        }
    } catch (e) {
      this.setState({valid: false});
    }
    this.setState({value: value});
  }

  storeFormBeforeChange() {
    this.form = this.props.onGetFormCopy();
  }

  handleInputChange = (value) => {
    this.changeValue(value);
  };

  handleInputFocus = (value) => {
    this.storeFormBeforeChange();
    this.initValue = value;
    this.stored = false;
  };

  componentWillReceiveProps(nextProps) {
    var value = this.getOptionValue();
    this.setState({value: value});
  }

  handleDropdownOrCheckboxChange = (value) => {
    this.storeFormBeforeChange();
    this.stored = false;
    this.changeValue(value);
  };

  getType() {
    var dataType = this.props.option.dataType;
    if (dataType && dataType == "boolean") {
      return "checkbox";
    }
    if (dataType && dataType == "staticValues") {
      return "dropdown";
    }
    var optionEnum = this.props.option.enum;
    if (this.props.option.enum) {
      return "dropdown";
    }
    return "input";
  }

  getDropdownItems() {
    var enumeration = this.props.option.enum;
    if (enumeration) {
      if (enumeration instanceof Array) {
        return enumeration;
      } else {
        return [enumeration];
      }
    } else {
      return WidgetModel.getStaticValuesNames();
    }
  }

  render () {
    var name = this.props.option.name;
    var option;
    var indeterminate = false;
    var value = this.state.value;
    if (value instanceof Object && value.message == "indeterminate") {
      value = undefined;
      indeterminate = true;
    }

    switch (this.getType()) {
      case "checkbox":
      value = value == true || value == "true";
      option = <CheckboxOption name={name}
                              value={value}
                              indeterminate={indeterminate}
                              onChange={this.handleDropdownOrCheckboxChange}/>
    break;
    case "dropdown":
      var items = this.getDropdownItems();
      option = <DropdownOption name = {name}
                              value={value}
                              indeterminate={indeterminate}
                              onChange = {this.handleDropdownOrCheckboxChange}
                              items = {items}/>
    break;
    case "input":
      option = <InputOption name = {name}
                           value={value}
                           indeterminate={indeterminate}
                           valid = {this.state.valid}
                           onChange = {this.handleInputChange}
                           onFocus = {this.handleInputFocus}/>
    break;
    }

    return option;
  }
}

export {InspectorOption};
