import React from "react";

import {MC} from './MC.js';
import {ReactFlow} from "./ReactFlow.jsx";

class EmbeddedDialog extends React.Component {

  state = {flowName: null, start: false, input: {}};

  componentDidMount() {
    window.addEventListener("message", this.onMessage, false);
    this.operateReload();
  }

  componentDidUpdate() {
     this.operateReload();
  }

  componentWillUnmount () {
    window.removeEventListener("message", this.onMessage);
  }

  operateReload() {
    var field = this.props.data;
    if (MC.getFieldParamBooleanValue(field.param, '@reload', this.props.iteration)) {
      MC.putFieldParamValue(field.param, '@reload', this.props.iteration, false);
      this.startFlow();
    } else if (this.state.start) {
      this.setState({start: false, input: {}});
    }
  }

  onMessage = (e) => {
    let self = this;
    if (e.data && MC.isPlainObject(e.data)) {
      if ('MNC-INTERNAL.READY-RUN' === e.data.name && self.props.data.flow.instanceId === e.data.instanceId) {
        // start flow in e. dialog if auotload is set to true
        if (MC.getFieldParamBooleanValue(self.props.data.param, '@dataActionAutoLoad', self.props.iteration)) {
          this.startFlow();
        }
      }
    }
  };

  startFlow = () => {
    var field = this.props.data;
    var flow = field.flow;
    if (!flow) {
      flow.endOperationException('SYS_InvalidModelExc', 'Dialog widget was initialized badly! Flow instance must be set in data!');
      return;
    }
    var dialogAction = MC.getFieldParamValue(field.param, '@dataAction', this.props.iteration);
    var dResult = flow.callLogicAction({dataAction: dialogAction}, field, this.props.iteration, true);
    if (!MC.isNull(dResult)) {
      if (MC.isNull(dResult.flowName) || dResult.flowName === '') {
        flow.endOperationException('SYS_InvalidModelExc', 'Failed to find flow name called in action ' + dialogAction + '!');
        return;
      }
      this.setState({flowName: dResult.flowName, start: true, input: dResult.input});
    }
  };

  onEnd  = (output, message) => {
    var flow = this.props.data.flow;
    this.setState({flowName: null, input: null, start: false});
    MC.putFieldParamValue(this.props.data.param, '@visible', this.props.iteration, false);
    if (!MC.isNull(message)) {
      flow.endOperationException(output, message, this.state.input, null, null);
    } else {
      var submitOpts = null;
      var dActionCode = MC.getFieldParamValue(this.props.data.param, '@dataAction', this.props.iteration);
      var dAction = flow.getActionByCode(dActionCode);
      if (dAction.submitParent) {
        submitOpts = {};
        submitOpts.triggeredByField = this.props.data;
        submitOpts.iteration = this.props.iteration;
      }
      flow.endEmbeddedDialog(dActionCode, this.state.input, output, submitOpts, null);
    }
  };

  render() {
    var flow = this.props.data.flow;
    return <ReactFlow configuration={flow.confPath} flowName={this.state.flowName} env={flow.env} input={this.state.input} start={this.state.start}
                      autoScrollUp={false} onEndFunction={this.onEnd} flowTemplate={flow.flowdatatemplate} formTemplate={flow.formdatatemplate} flowServerUrl={flow.flowServerUrl} embedded={true}
                      options={flow.reactFlow.props.options} configurationObject={flow.context.data['env/cfg']} debug={flow.debug}/>;
  }

}

export {EmbeddedDialog};