import React from "../../miniclient/in/client/node_modules/react";
import ReactDOM from "../../miniclient/in/client/node_modules/react-dom";
import {MC} from '../../miniclient/in/client/src/client/MC.js';
import D3Funnel from 'd3-funnel';

class Funnel extends React.Component {
  componentDidMount() {
    var funnel = this;
    funnel.update(funnel.props.data.param["data*"])
  }
  componentWillReceiveProps(nextProps) {
    var funnel = this;
    var fieldData = nextProps.data.param["data*"];
    if (fieldData) {
      funnel.update(fieldData)
    }

  }
  update(filedData) {
    var funnel = this;
    if (!filedData) {
      return;
    }
    var node = ReactDOM.findDOMNode(funnel);
    if (!MC.isVisible(node)) {
      return;
    }
    var data = filedData["@label"].map(function(label, index) {
      var value = filedData["@value"][index];
      var backgroundColor = filedData["@backgroundColor"][index];
      return {label: label, value: value, backgroundColor: backgroundColor}
    });
    const options = {
        block: {
            dynamicSlope: true,
            minHeight: 15,
        },
    };

    const chart = new D3Funnel(node);
    chart.draw(data, options);
  }
  render() {
    return <div />
  }
}

MC.registerReactRomponent('funnel', Funnel);
export {Funnel};
