import React from "react";

import {MC} from './MC.js';

class Link extends React.Component {

  handleClick = (e) => {
    var escapeTitleHtml = MC.getFieldParamBooleanValue(this.props.data.param, '@escapeTitleHtml', this.props.iteration);
    if (!escapeTitleHtml && e.target.nodeName !== 'A') {
      return;
    }
    if (MC.isFunction(this.props.data.onSubmit) && !MC.isModelerActive(this.props.data)) {
      this.props.data.onSubmit(this.props.data, this.props.iteration);
    }
    if (MC.isFunction(this.props.onClick) && !MC.isModelerActive(this.props.data)) {
      this.props.onClick();
    }
    if (this.props.handleEvents) {
      MC.handleReactEvent(this.props.data, this.props.iteration, e);
    }
    var field = this.props.data;
    var behavior = MC.getFieldParamValue(field.param, '@behavior', this.props.iteration);
    if (behavior == 'dialog') {
      var dialogAction = MC.getFieldParamValue(field.param, '@dialogAction', this.props.iteration);
      var dRes = field.flow.callLogicAction({dataAction: dialogAction}, field, this.props.iteration);
      if (!MC.isNull(dRes)) {
        field.flow.reactFlow.setState({dialog: {flowName: dRes.flowName, input: dRes.input, actionCode: dialogAction, triggeredByField: field, iteration: this.props.iteration,
          size: dRes.size, start: true, parentFlow: field.flow}});
      }
    }    
  }

  render() {
    var field = this.props.data;
    var title = MC.getFieldParamValue(field.param, '@title', this.props.iteration);
    var cls;
    if (this.props.cls) {
      cls = this.props.cls;
    } else {
      cls = MC.getFieldParamValue(field.param, '@cssClass', this.props.iteration);
      cls = cls ? 'ui link ' + cls : 'ui link';
    }
    if (this.props.disabled) {
      return (
        <span id={field.rbsid} className={cls} ref={field.rbsid}>{title}</span>
      );
    } else {
      var label;
      var onClick = this.handleClick;
      var href = 'javascript:;';
      var behavior = MC.getFieldParamValue(field.param, '@behavior', this.props.iteration);
      var imageUrl = MC.getFieldParamValue(field.param, '@imageUrl', this.props.iteration);
      if (!MC.isNull(imageUrl)) {
        imageUrl = MC.rebaseUrl(field.flow.flow.model, imageUrl);
        label = <img src={imageUrl}/>
      } else {
        var icon = MC.getFieldParamValue(field.param, '@icon', this.props.iteration);
        if (!MC.isNull(icon) && icon != "") {
          var icls = 'icon ' + icon;
          label = <i className={icls} key="icon"></i>;
        }
      }
      if (behavior == 'url') {
        onClick = null;
        href = MC.getFieldParamValue(field.param, '@url', this.props.iteration);
      }
      var target = MC.getFieldParamValue(field.param, '@target', this.props.iteration);
      if (['blank', 'parent', 'top'].indexOf(target) > -1) {
        target = '_' + target;
      } else {
        target = null;
      }
      var onMouseDown;
      var onMouseMove;
      if (MC.isModelerReactAvailable(field) && this.props.handleEvents) {
        var modelerReact = MC.getModelerReact(field);
        cls += Interactive.getInteractiveClasses.bind(modelerReact)(field, this.props.iteration);
        onMouseDown = Interactive.handleMouseDown.bind(modelerReact, field);
        onMouseMove = Interactive.handleMouseMove.bind(modelerReact, field, this);
      }
      var escapeTitleHtml = MC.getFieldParamBooleanValue(field.param, '@escapeTitleHtml', this.props.iteration);
      if (escapeTitleHtml) {
        if (this.props.handleEvents) {
          var it = this.props.iteration;
          return (
            <a className={cls} id={field.rbsid} ref={field.rbsid} onClick={this.handleClick} onMouseDown={onMouseDown} onMouseMove={onMouseMove} href={href} target={target}
               onFocus={MC.handleReactEvent.bind(this, field, it)} onBlur={MC.handleReactEvent.bind(this, field, it)} onMouseEnter={MC.handleReactEvent.bind(this, field, it)}
               onMouseLeave={MC.handleReactEvent.bind(this, field, it)} data-widget-i-name={field.id}>{label}{title}</a>
          );
        } else {
          return (
            <a className={cls} id={field.rbsid} ref={field.rbsid} onClick={onClick} onMouseDown={onMouseDown} href={href} target={target} data-widget-i-name={field.id}>{label}{title}</a>
          );
        }
      } else {
        if (this.props.handleEvents) {
          var it = this.props.iteration;
          return <div className={cls} id={field.rbsid} ref={field.rbsid} onClick={this.handleClick} onMouseDown={onMouseDown} onMouseMove={onMouseMove}
                      onFocus={MC.handleReactEvent.bind(this, field, it)} onBlur={MC.handleReactEvent.bind(this, field, it)} onMouseEnter={MC.handleReactEvent.bind(this, field, it)}
                      onMouseLeave={MC.handleReactEvent.bind(this, field, it)} dangerouslySetInnerHTML={{__html: MC.customHtml(title)}}  data-widget-i-name={field.id}></div>;
        } else {
          return <div className={cls} id={field.rbsid} ref={field.rbsid} onClick={onClick} onMouseDown={onMouseDown} dangerouslySetInnerHTML={{__html: MC.customHtml(title)}} data-widget-i-name={field.id}></div>;
        }
      }
    }
  }

}

export {Link};