import React from "../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Input} from './input.jsx';
import {Button} from './button.jsx';

const ActionInput = createReactClass({
  getInitialState: function() {
    return {
      value: ''
    };
  },
  handleValueUpdate: function(value) {
    let component = this;
    component.setState({value: value});
  },
  handleButtonClick: function() {
    let component = this;
    component.props.onAction(component.state.value);
  },
  render: function() {
    const component = this;
    const state = component.state;
    const props = component.props;
    return (<div className="ui action input">
      <Input
        value={state.value}
        placeholder={props.placeholder}
        onValueUpdate={component.handleValueUpdate}/>
      <Button
        error={props.error}
        loading={props.loading}
        label={props.actionLabel}
        onClick={component.handleButtonClick}
        className={props.buttonClassName}/>
    </div>);
  }
})

export {ActionInput};
