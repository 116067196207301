import {InspectorComponent} from "./package.js";
import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";


const SimpleLabelInspector = createReactClass({
  load: function(context, property, value) {
    return {value: value}
  },
  render: function() {
    const component = this;
    const value = component.props.value;
    return <div>{value}</div>;
    }
})
export {SimpleLabelInspector};
