import React from "react";
import ReactDOM from "react-dom";

import {MC} from './MC.js';

class Upload extends React.Component {

  handleFileChange = (e) => {
    const props = this.props;
    const widget = props.widget;
    const field = props.field;
    let file = e.target.files[0];
    MC.putFieldParamValue(field.param, 'value/@fileName', props.iteration, file.name);
    MC.putFieldParamValue(field.param, 'value/@contentType', props.iteration, file.type);
    MC.putFieldParamValue(field.param, 'value/@size', props.iteration, file.size);
    var reader = new FileReader();
    reader.onload = (function() {
      return function(e) {
        MC.putFieldParamValue(field.param, 'value/@data', props.iteration, e.target.result.substring(e.target.result.indexOf(';base64,')+8));
        MC.handleEvent(field, props.iteration, 'change');
        if (field.flow && field.flow.context.data['env/cfg'] && field.flow.context.data['env/cfg']['fl:validationStyle'] === 'blur') {
          widget.revalidate(true);
        } else {
          widget.revalidate();
        }
      };
    })(file);
    reader.readAsDataURL(file);
  };

  handleUploadClick = () => {
    ReactDOM.findDOMNode(this.refs.input).value = null;
    ReactDOM.findDOMNode(this.refs.input).click();
  };

  render() {
    const props = this.props;
    const field = props.field;
    let innerDisabled = props.disabled;
    if (MC.isModelerActive(field)) {
      innerDisabled = true;
    }
    const buttonTitle = MC.getFieldParamValue(field.param, '@buttonTitle', props.iteration);
    let placeholder = props.placeholder;
    const fileName = MC.getFieldParamValue(field.param, 'value/@fileName', props.iteration);
    if (!MC.isNull(fileName) && fileName !== '') {
      placeholder = fileName;
    }
    let icon = MC.getFieldParamValue(field.param, '@icon', props.iteration);
    if (!MC.isNull(icon) && icon !== "") {
      icon = <i className={icon}></i>
    } else {
      icon = null;
    }
    return (
      <div>
        <button key="button" id={props.htmlId} className="upload-button ui button" onClick={this.handleUploadClick} disabled={innerDisabled} readOnly={props.readOnly}>{icon}{buttonTitle}</button>
        <span key="span" className="upload-file-name">{placeholder}</span>
        <input key="input" ref="input" data-widget-i-name={field.id} type="file" onChange={this.handleFileChange} style={{display: 'none'}}/>
      </div>
    );
  }

}

export {Upload};
