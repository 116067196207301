'use strict';
import {Library} from "../../../../../app/src/library.js";

const CHub = {};

CHub.assemblyName = (id) => {
  return 'ASM_' + id.replace(/-/g,"_");
}

// view

CHub.viewNodes = (view) => {
  return Library.ensureArray(view['chub:node']);
}

CHub.viewFindNode = (view, nodeId) => {
  const nodes = CHub.viewNodes(view);
  return nodes.find(node => node['rbs:id'] === nodeId);
}

CHub.viewConceptNodes = (view, conceptPath) => {
  const properties = view['rbs:properties'];
  const path = view['meta3:path'];
  return CHub.viewNodes(properties).filter(node => {
    const conceptReference = node['meta3:instanceOf'];
    const modelConceptPath = Library.resolveReference(path, conceptReference);
    return conceptPath === modelConceptPath
  })
}

CHub.referencedNode = (path, view, reference) => {
  const targetRi = Library.referenceResolve(reference, path);
  const id = Library.riFragment(targetRi);
  return CHub.viewFindNode(view, id);
}

CHub.nodeConceptName = (path, node) => {
  const conceptPath = Library.resolveReference(path, node['meta3:instanceOf']);
  const conceptName = Library.pathFileName(conceptPath);
  return conceptName;
}



// assembly
CHub.assemblyFlowPath = (assembly) => {
   const flowNode = CHub.viewConceptNodes(assembly, 'commhub/dsl/concepts/assemblyElements/flow')[0];
   if (flowNode) {
     const path = assembly['meta3:path'];
     const flowReference = flowNode['dsl:flow'];
     return Library.resolveReference(path, flowReference);
   }
}

CHub.assemblyTimeConstraints  = (assembly) => {
  const properties = assembly['rbs:properties'];
  const days= ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  const noTimeConstraints = Library.boolean(properties['chub:noTimeConstraints']);
  if (noTimeConstraints) {
    return false;
  } else {
    const timeConstraints = {
      start: {
        date: properties['chub:date'],
        time: properties['chub:time']
      }
    }
    const repeat = properties['chub:repeat'];
    if (repeat !== 'noRepeat') {
      timeConstraints.repeat = properties['chub:repeat'];
      if (properties['chub:finishDate']) {
        timeConstraints.finish = {
          date: properties['chub:finishDate'],
          time: properties['chub:finishTime']
        }
      }
    }
    if (timeConstraints.repeat === 'weekly') {
      timeConstraints.repeatDays = days.filter(day => Library.boolean(properties['chub:' + day + 'Repeat']))
    }
    return timeConstraints
  }
}


// flows

CHub.flowName = (id) => {
  return 'GOP_' + id.replace(/-/g,"_");
}

CHub.flowHandlerId = (flow, handler) => {
  const eventName = CHub.flowHandlerEventName(handler);
  return flow['rbs:id'] + '-handler-' + eventName
}

CHub.flowHandlerName = (flow, handler) => {
  return CHub.flowName(CHub.flowHandlerId(flow, handler));
}

CHub.flowHandlerEventName = (handler) => {
  return handler['dsl:listeningToCta'] || handler['chub:handlerEvent'];
}

CHub.flowTemplatesPaths = (flow) => {
  const path = flow['meta3:path'];
  const properties = flow['rbs:properties'];
  return Library.removeDuplicates(CHub.viewNodes(properties).filter(node => node['dsl:template']).map(node => {
    const templateReference = node['dsl:template'];
    return Library.resolveReference(path, templateReference);
  }))
}

CHub.flowNodeHandlers = (path, flow, node) => {
  return Library.ensureArray(node['chub:answerHandler']).map(reference => {
   return CHub.referencedNode(path, flow, reference);
  })
}

// template

CHub.templateCss = (storage, path, template) => {
  const styleReference = template['dsl:templateStyle'];
  const stylePath = Library.resolveReference(path, styleReference);
  if (Library.pathFileName(stylePath) === 'own') {
    return Promise.resolve(template['app:css']);
  } else {
    return storage.getFile(stylePath).then(styleFile => {
      return styleFile['meta3:properties']['app:css'];
    })
  }
}

export {CHub};
