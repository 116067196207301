import createReactClass from 'create-react-class'
import React from "../../../miniclient/in/client/node_modules/react"
import {Library} from '../../../app/src/library.js'
import {resolveReference, ensureArray} from '../../../app/src/lib.js'
import joint from './rappid/rappid.js'
import {MUtils} from './metamodeler-utils.js'
import ReactDOM from "../../../miniclient/in/client/node_modules/react-dom"
import './stencil.css'

const Stencil = createReactClass({
    getInitialState: function() {
      return {selectedGroup: "A", groups: []}
    },
    componentDidMount: function() {
      let self = this
      const props = self.props
      const viewFile = props.viewFile
      const concepts = props.concepts
      let groupsData = ensureArray(viewFile['meta3:properties']['mm:group'])
      groupsData = Array.isArray(groupsData) ? groupsData : [groupsData]
      if (groupsData && groupsData.length > 0) {
        self.stencil = {}
        let groupsHeight = {}
        groupsData.forEach(function(group) {
          let $node = $(ReactDOM.findDOMNode(self.refs["stencil-" + group["rbs:id"]]))
          self.stencil[group["rbs:id"]] = new joint.ui.Stencil({
            paper: props.paper,
            width: 250
          })
          $node.append(self.stencil[group["rbs:id"]].render().el)
          groupsHeight[group["rbs:id"]] = self.update(concepts, group)
        })
        self.setState({concepts, groupsHeight})
      } else {
        let $node = $(ReactDOM.findDOMNode(self.refs.stencil))
        self.stencil = new joint.ui.Stencil({
          paper: props.paper,
          width: 250
        })
        $node.append(self.stencil.render().el)
        self.update(concepts, null)
      }
    },
    load: async function(context) {
      const viewFile = context.view
      const properties = viewFile['meta3:properties']
      const conceptsFolderReference = properties['mm:conceptsFolder']
      const conceptsFolderPath = Library.resolveReference(viewFile['meta3:path'], conceptsFolderReference)
      const concepts = await context.storage.list(conceptsFolderPath)
      return {viewFile, concepts}
    },
    update: function(concepts, group) {
      let self = this
      let height = 0
      if (!concepts) {
        return
      }

      concepts = concepts.filter(concept => {
        if (!group || concept['meta3:properties']['mm:group'] == group["rbs:id"]) {
          return true
        }
        return false
      })

      const view = self.props.viewFile['meta3:properties']
      let cells
      if (view['mm:computedStencil'] === "true") {
        const shapeHeight = Number(view['mm:shapeHeight'])
        const shapeWidth = Number(view['mm:shapeWidth'])
        const shapeNames = Library.boolean(view['mm:shapeNames'])
        const shapesPerRow = Number(view['mm:shapesPerRow'])
        const shapeHDist = Number(view['mm:shapeHDist'])
        const shapeVDist = Number(view['mm:shapeVDist'])
        // Při hodnotě větší než 0 může poslední element přetéct přes celkovou délku groupy. Mělo by se přidat i do vykreslování výšky groupy
        const shapePaddingTop = Number(view['mm:shapePaddingTop'])
        const shapePaddingLeft = Number(view['mm:shapePaddingLeft'])

        // Seřadí elementy podle row a pos, aby je bylo možné vykreslit ve správném rozložení na stencil
        concepts = concepts.sort(function(a, b) {
          const row1 = Number(a['meta3:properties']['mm:stencilRow'])
          const pos1 = Number(a['meta3:properties']['mm:stencilPos'])
          const row2 = Number(b['meta3:properties']['mm:stencilRow'])
          const pos2 = Number(b['meta3:properties']['mm:stencilPos'])

          if ((row1 < row2) || ((row1 == row2) && (pos1 < pos2))) { 
            return -1; 
          }
          if ((row1 > row2) || ((row1 == row2) && (pos1 > pos2))) { 
            return 1; 
          }
          return 0;
        })

        var lastRow = 0
        var xPos = 0
        var yPos = 0


        cells = concepts.map((concept, index) => {
          const visual = MUtils.conceptVisual(concept)

          var cellConstructor = MUtils.visualConstructor(visual)
          var cell = new cellConstructor


          cell.set('size', {
            width: shapeWidth,
            height: shapeHeight
          });

          var rowHeight = (shapeHeight + shapeVDist)

          const currentRow = Number(concept['meta3:properties']['mm:stencilRow'])

          if (((xPos + 1) ==  shapesPerRow) || (currentRow != lastRow)) {
            xPos = 0
            if (lastRow) {
              yPos++ 
            }
          } else {
            xPos ++
          }

          lastRow = currentRow

          cell.set('position', {
            x: shapePaddingLeft + (xPos * (shapeWidth + shapeHDist)),
            y: shapePaddingTop + (yPos * rowHeight)
          })

          cell.set("conceptRI", concept['meta3:path'])
          height = shapePaddingTop + (yPos + 1) * rowHeight 

          if (shapeNames) {
            cell.attr({
              '.name' : { 'text': joint.util.breakText(concept["meta3:properties"]["meta3:prototype"]["meta3:type"], { width: shapeWidth + shapeHDist })},
              '.title' : { 'text': concept["meta3:properties"]["meta3:prototype"]["meta3:description"]}
            });
          }
          else {
            cell.attr({
              '.name' : { 'text': ''},
              '.title' : { 'text': concept["meta3:properties"]["meta3:prototype"]["meta3:type"]}
            });
            height -=shapeVDist
          }

          return cell
        })
      } else {
        cells = concepts.map((concept, index) => {
          const visual = MUtils.conceptVisual(concept)
          var cellConstructor = MUtils.visualConstructor(visual)
          var cell = new cellConstructor
          cell.set('position', {
            x: 15 + ((index % 2) * 70),
            y: 10 + (Math.floor(index / 2) * 70)
          })
          cell.set("conceptRI", concept['meta3:path'])
          height += ((index % 2) == 0 ? 70 : 0)
          return cell
        })
      }

      if (group) {
        self.stencil[ group["rbs:id"]].load(cells)
      } else {
        self.stencil.load(cells)
      }
      
      if (view['mm:computedStencil'] === "true") {
        return height
      } else {
      /* TODO: Nemělo by se tu vracet natvrdo nějaké číslo ... */
        return height - 20
      }
    },
    handleSelectGroup: function(group) {
      let self = this
      let newSelectedGroup = group["rbs:id"]
      if (group["rbs:id"] == self.state.selectedGroup) {
        newSelectedGroup = null
      }
      self.setState({selectedGroup: newSelectedGroup}, () => {
        self.update(self.state.concepts, group)
      })
    },
    render: function() {
      let self = this
      let groupsData = self.props.viewFile['meta3:properties']['mm:group']
      groupsData = Array.isArray(groupsData) ? groupsData : [groupsData]
      if (groupsData && groupsData.length > 0) {
        let groupsItems = groupsData.map(function(group) {
          let titleClass = "title"
          let contentClass = "content"
          if (group["rbs:id"] == self.state.selectedGroup) {
            titleClass += " active"
            contentClass += " active"
          }
          let groupHeight = self.state.groupsHeight ? self.state.groupsHeight[group["rbs:id"]] : 0
          let innerStyle = {height: groupHeight + "px"}
          return (
            <React.Fragment key={"frag-" + group["rbs:id"]}>
              <div className={titleClass} onClick={() => self.handleSelectGroup(group)} data-group={group["rbs:id"]} key={"title-" + group["rbs:id"]}>
                <i className={group["mm:icon"]}></i>
                {group["mm:title"]}
                <i className="dropdown icon"></i>
              </div>
              <div className={contentClass}  key={"content-" + group["rbs:id"]}>
                <div className="inner meta" style={innerStyle} ref={"stencil-" + group["rbs:id"]}/>
              </div>
            </React.Fragment>
          )
        })
        return (
          <div className="stencil meta column left component">
            <div className="inner meta grow">
              <div className="ui accordion">{groupsItems}</div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="stencil meta column left component">
            <div className="inner meta grow" ref="stencil"/>
          </div>
        )
      }
    }
})

export {Stencil}
