import React from "react";

import {MC} from './MC.js';

class Label extends React.Component {

  render() {
    var field = this.props.field;
    var value = this.props.value;
    var labelPlacement = MC.getFieldParamValue(field.param, '@titlePlacement', this.props.iteration);
    if (labelPlacement == 'IN' && !this.props.inTable) {
      value = MC.getFieldParamValue(field.param, '@title', this.props.iteration);
    }
    var cssclass = MC.getFieldParamValue(field.param, '@cssClass', this.props.iteration);
    var headerType =  MC.getFieldParamValue(field.param, '@headerType', this.props.iteration);
    if (MC.isNull(headerType)) {
      var escapeHtml = MC.getFieldParamValue(field.param, '@escapeHtml', this.props.iteration);
      if (escapeHtml) {
        return <span className={cssclass} id={this.props.id} ref={field.rbsid} data-widget-i-name={field.id}>{value}</span>;
      } else {
        return <div className={cssclass} id={this.props.id} ref={field.rbsid} dangerouslySetInnerHTML={{__html: MC.customHtml(value)}} data-widget-i-name={field.id}></div>;
      }
    } else {
      headerType = headerType.toUpperCase();
      cssclass = MC.isNull(cssclass) ? 'ui header' : 'ui header ' + cssclass;
      switch (headerType) {
        case 'H3': return <h3 className={cssclass} id={this.props.id} ref={field.rbsid} data-widget-i-name={field.id}>{value}</h3>;
        case 'H4': return <h4 className={cssclass} id={this.props.id} ref={field.rbsid} data-widget-i-name={field.id}>{value}</h4>;
        case 'H5': return <h5 className={cssclass} id={this.props.id} ref={field.rbsid} data-widget-i-name={field.id}>{value}</h5>;
        case 'H6': return <h6 className={cssclass} id={this.props.id} ref={field.rbsid} data-widget-i-name={field.id}>{value}</h6>;
      }
    }
  }

}

export {Label};