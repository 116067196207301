import React from "react";
import ReactDOM from "react-dom";

import {MC} from './MC.js';

class Camera extends React.Component {

  resolutionsToCheck = [
    {width: 160, height:120}, {width: 320, height:180}, {width: 320, height:240}, {width: 640, height:360}, {width: 640, height:480},
    {width: 768, height:576}, {width: 1024, height:576}, {width: 1280, height:720}, {width: 1280, height:768},
    {width: 1280, height:800}, {width: 1280, height:900}, {width: 1280, height:1000}, {width: 1920, height:1080},
    {width: 1920, height:1200}, {width: 2560, height:1440}, {width: 3840, height:2160}, {width: 4096, height:2160}
  ];
  left = 0;
  right= 0;
  selectedWidth = 0;
  selectedHeigh = 0;
  mid = 0;
  stream = null;

  componentWillMount() {
    this.right = this.resolutionsToCheck.length;
    this.setState({state: 'starting'});
  }

  componentWillReceiveProps(nextProps) {
    if (!MC.isModelerActive(nextProps.data) && nextProps.data.flow.reactFlow.state.start) {
      if (this.state.state == 'captured') {
        self.setState({state: 'video'});
      }
    }
  }

  componentDidMount() {
    var self = this;
    if (MC.isModelerActive(this.props.data)) {
      return;
    }
    if (MC.getFieldParamBooleanValue(this.props.data.param, '@focusArea', this.props.iteration)) {
      ReactDOM.findDOMNode(this.refs.video).addEventListener('loadeddata', function() { self.drawFocuser(); }, false);
    }
    this.findMaximumCameraResolutiuon();
  }

  componentWillUnmount() {
    if (MC.isModelerActive(this.props.data)) {
      return;
    }
    ReactDOM.findDOMNode(this.refs.video).pause();
    if (this.stream != null) {
      this.stream.getVideoTracks()[0].stop();
    }
  }

  initVideo() {
    var self = this;
    var video = ReactDOM.findDOMNode(this.refs.video);
    var videoProps = {"mandatory": { "minWidth": this.selectedWidth, "minHeight": this.selectedHeight, "maxWidth": this.selectedWidth, "maxHeight": this.selectedHeight}, "optional": []};
    navigator.mediaDevices.getUserMedia({video: videoProps, audio: false}).then(function(stream) {
      self.stream = stream;
      try {
        video.srcObject = stream;
      } catch (error) {
        video.src = window.URL.createObjectURL(stream);
      }
      video.play();
      self.setState({state: 'video'});
    }).catch(function(e) {
      self.setState({state: 'error'});
    });
  }

  findMaximumCameraResolutiuon = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      this.setState({state: 'notSupportedInBrowser'});
      return;
    }
    if (this.left > this.right) {
      if (this.selectedWidth > 0 && this.selectedHeight > 0) {
        this.initVideo();
      } else {
        this.setState({state: 'findingCamera'});
        setTimeout(this.findMaximumCameraResolutiuon, 2000);
      }
      return;
    }
    this.mid = Math.floor((this.left + this.right) / 2);
    var videoProps = {"mandatory":
                      { "minWidth": this.resolutionsToCheck[this.mid].width, "minHeight": this.resolutionsToCheck[this.mid].height, "maxWidth": this.resolutionsToCheck[this.mid].width, "maxHeight": this.resolutionsToCheck[this.mid].height},
                      "optional": []};
    navigator.mediaDevices.getUserMedia({video: videoProps, audio: false}).then(this.checkScanSuccess).catch(this.checkScanError);
  };

  drawFocuser() {
    var ratioW = Number(MC.getFieldParamValue(this.props.data.param, '@focusAreaRatioWidth', this.props.iteration)).valueOf();
    var ratioH = Number(MC.getFieldParamValue(this.props.data.param, '@focusAreaRatioHeight', this.props.iteration)).valueOf();
    var video = ReactDOM.findDOMNode(this.refs.video);
    var canvasOver = ReactDOM.findDOMNode(this.refs.overlayCanvas);
    canvasOver.width = video.clientWidth;
    canvasOver.height = video.clientHeight;
    var rectHeight = Math.floor(canvasOver.height * 0.9);
    var rectY = Math.floor(canvasOver.height * 0.05);
    var rectWidth = Math.floor(ratioW / ratioH * rectHeight);
    var rectX = ( (canvasOver.width - rectWidth) / 2);
    if (rectWidth > canvasOver.width * 0.9) {
      rectWidth = Math.floor(canvasOver.width * 0.9);
      rectX = Math.floor(canvasOver.width * 0.05);
      rectHeight = Math.floor(ratioH / ratioW * rectWidth);
      rectY = ( (canvasOver.height - rectHeight) / 2);
    }
    var ctx = canvasOver.getContext("2d");
    /*ctx.strokeStyle = '#008000';
    ctx.lineWidth=3;
    ctx.rect(rectX, rectY, rectWidth, rectHeight);
    ctx.stroke();
    */
    ctx.fillStyle='rgb(0, 0, 0)';
    ctx.globalAlpha = 0.5;
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(rectX, rectY);
    ctx.lineTo(rectX, rectY + rectHeight);
    ctx.lineTo(rectX + rectWidth, rectY + rectHeight);
    ctx.lineTo(rectX + rectWidth, rectY);
    ctx.lineTo(rectX, rectY);
    ctx.lineTo(0, 0);
    ctx.lineTo(canvasOver.width, 0);
    ctx.lineTo(canvasOver.width, canvasOver.height);
    ctx.lineTo(0, canvasOver.height);
    ctx.lineTo(0, 0);
    ctx.fill();
  }

  checkScanSuccess = (stream) => {
    this.selectedWidth = this.resolutionsToCheck[this.mid].width;
    this.selectedHeight = this.resolutionsToCheck[this.mid].height;
    this.left = this.mid + 1;
    var tracks = stream.getTracks();
    for (var i = 0; i < tracks.length; i++) {
      tracks[i].stop();
    }
    this.findMaximumCameraResolutiuon();
  };

  checkScanError = () => {
    this.right = this.mid - 1;
    this.findMaximumCameraResolutiuon();
  };

  snapPhoto = () => {
    if (this.state.state == 'captured') {
      MC.putFieldParamValue(this.props.data.param, 'value', this.props.iteration, null);
      this.setState({state: 'video'});
    } else {
      var video = ReactDOM.findDOMNode(this.refs.video);
      var canvasFull = ReactDOM.findDOMNode(this.refs.canvasFull);
      canvasFull.width = video.videoWidth;
      canvasFull.height = video.videoHeight;
      var context = canvasFull.getContext('2d');
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      var data = canvasFull.toDataURL('image/jpeg', 1.0);
      MC.putFieldParamValue(this.props.data.param, 'value', this.props.iteration, data.substring(data.indexOf(';base64,')+8));
      var canvas = ReactDOM.findDOMNode(this.refs.canvas);
      canvas.width = video.clientWidth;
      canvas.height = video.clientHeight;
      var destContext = canvas.getContext('2d');
      destContext.drawImage(canvasFull, 0, 0, video.clientWidth, video.clientHeight);
      if (MC.isFunction(this.props.data.onSubmit)) {
        this.props.data.onSubmit(this.props.data, this.props.iteration);
      }
      this.setState({state: 'captured'});
    }
    MC.handleEvent(this.props.data, this.props.iteration, 'change');
  };

  render() {
    var warning;
    if (['starting', 'findingCamera', 'error', 'notSupportedInBrowser'].indexOf(this.state.state) > -1) {
      if (MC.getLang() == 'cs') {
        switch (this.state.state) {
          case 'starting':  warning = <div className="ui message">Probíhá inicializace...</div>; break;
          case 'notSupportedInBrowser':  warning = <div className="ui red message">Váš prohlížeč nepodporuje kameru!</div>; break;
          case 'findingCamera':  warning = <div className="ui message">Vyhledávání kamery...</div>; break;
          case 'error':  warning = <div className="ui red message">Chyba při inicializaci kamery!</div>; break;
        }
      } else {
        switch (this.state.state) {
          case 'starting':  warning = <div className="ui message">Initialization is in progress...</div>; break;
          case 'notSupportedInBrowser':  warning = <div className="ui red message">Your browser does not support the camera!</div>; break;
          case 'findingCamera':  warning = <div className="ui message">Searching a camera...</div>; break;
          case 'error':  warning = <div className="ui red message">Camera initialization error!</div>; break;
        }
      }
    }
    var canvasCss = {position: 'relative'};
    var videoCss = {position: 'relative'};
    if (this.state.state != 'video') {
      videoCss.display = 'none';
    }
    if (this.state.state != 'captured') {
      canvasCss.display = 'none';
    }
    var className = 'mnc video';
    if (MC.getFieldParamBooleanValue(this.props.data.param, '@mirrored', this.props.iteration)) {
      className += ' mirrored';
    }
    return (
      <div data-widget-i-name={this.props.data.id}>
        {warning}
        <div style={videoCss}>
          <video className={className} ref="video" autoPlay/>
          <canvas ref="overlayCanvas" style={{width: '100% !important', height: 'auto !important', position: 'absolute', top:0, left: 0}}></canvas>
          <div style={{position: 'absolute', right: '5px', bottom: '15px'}}>
            <button className="ui circular icon huge basic yellow button" type="button" onClick={this.snapPhoto}><i className="photo icon"/></button>
          </div>
        </div>
        <div style={canvasCss}>
          <canvas ref="canvas"></canvas>
          <canvas ref="canvasFull" style={{display: 'none'}}></canvas>
          <div style={{position: 'absolute', right: '5px', bottom: '15px'}}>
            <button className="ui circular icon huge basic yellow button" type="button" onClick={this.snapPhoto}><i className="record icon"/></button>
          </div>
        </div>
        <label htmlFor={this.props.htmlId} className={this.props.helpCls} key="error">{this.props.help}</label>
      </div>
    )
  }

}

export {Camera};