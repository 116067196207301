import React from "../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {ReactFlow} from '../../miniclient/in/client/src/client/ReactFlow.jsx';

const Login = createReactClass({
  render: function() {
    const application = this;
    const login = <div>
                  <ReactFlow
                      configuration="/in/auth/"
                      flowName="SEC_Login_FE"
                      editMode={false}
                      debug={false}
                      start={true}
                      configurationChanger={true}
                      onEndFunction={application.onLoginEnd}
                      input={{}}/>
                  </div>
     return login;
  },
  onLoginEnd: function(val) {
    const component = this;
    const props = component.props;
    props.onUpdate({type: 'login'});
  }
});

export {Login};
