import React from "react"
import {DateTime} from "luxon"
import {Accordion} from 'semantic-ui-react'

import ObjectInspector from './ObjectInspector.js'
import {MCHistory} from './MCHistory.js'
import {MCBrws} from './MCBrws.js'
import {Modal} from './Modal.jsx'

import './LogConsole.css'

class LogConsole extends React.Component {

  state = {activeIndex: 1};

  close = () => {
    if (this.props.open) {
      this.props.onClose();
      document.querySelector('body').classList.remove('showing-modal');
    }
  };

  clearClick = () => {
    MCHistory.clear();
    this.forceUpdate();
  };

  handleTabClick = (i) => {
    this.setState({activeIndex: i});
  };

  render() {
    var history = MCHistory.getHistory();
    var historyHtml = [];
    if (Array.isArray(history)) {
      for (var i=0; i<history.length; i++) {
        var data = history[i];
        let path = '';
        if (data.path) {
          data.path.split('/').forEach(x => path += '\u00A0\u00A0\u00A0\u00A0\u00A0');
        }
        var title =  data.flow + (data.action ? ' / ' + data.action : '');
        var kind = ' (' + data.kind + ')';
        var text = (data.text ? ' - ' + data.text : '');
        var ioIcons = [];
        var roots = [];
        let isServer = false;
        if (data.logObject) {
          for (var root in data.logObject) {
            switch (root) {
              case 'Input': ioIcons.push(<div key={'ii'+i} className="letterCircle" style={{border: '1px solid #16AB39', color: '#16AB39'}}>I</div>); break;
              case 'Output': ioIcons.push(<div key={'io'+i} className="letterCircle" style={{border: '1px solid #2185D0', color: '#2185D0'}}>O</div>); break;
              case 'Server log': ioIcons.push(<div key={'ll'+i} className="letterCircle" style={{border: '1px solid #BEBEBE', color: '#BEBEBE'}}>L</div>); break;
            }
            if (root === 'Server log') {
              let flowLogId = data.logObject[root];
              if (flowLogId.flowLogId) {
                flowLogId = flowLogId.flowLogId.split('/');
                let log = `${rbBaseUri}in/flow/api/?flowlog=true&day=${flowLogId[0]}&name=${flowLogId[1]}&raw=true&tail=true`;
                let download = `${rbBaseUri}in/flow/api/?flowlog=true&day=${flowLogId[0]}&name=${flowLogId[1]}&raw=true&download=true`;
                roots.push(<div key={root} className="server-log-a">
                             <span className="left-arrow">▶</span>
                             <a href={log}>Server log</a> (<a href={download}>download</a>)
                           </div>);
              }
              isServer = true;
            } else {
              roots.push(<div key={root}><ObjectInspector data={data.logObject[root]} name={root}/></div>);
            }

          }
        }
        var strDate =  DateTime.fromJSDate(data.time).toFormat("HH:mm:ss.SSS yyyy-MM-d");
        historyHtml.push({
          key: i,
          title: {
            content: (
              <React.Fragment>
                {path}<span className={'actionColor ' + (isServer ? 'server-call' : data.flowKind)}>{title}</span><span className="kindColor">{kind}</span>{text} {ioIcons} <small>- {strDate}</small>
              </React.Fragment>
            )
          },
          content: {
            content: roots
          }
        })
      }
    }
    const activeIndex = this.state.activeIndex;
    return (
      <div>
        <Modal show={this.props.open} onClose={this.close} cssClass="large log-console">

          <div className="clear-icon" onClick={this.clearClick} title="Clear log">
            <i className="icon trash alternate outline"></i>
          </div>

          <div className="ui secondary pointing menu">
            <a className={'item' + (activeIndex === 1 ? ' active' : '')} onClick={this.handleTabClick.bind(this, 1)}>Log console</a>
            <a className={'item' + (activeIndex === 2 ? ' active' : '')} onClick={this.handleTabClick.bind(this, 2)}>Browser storage</a>
          </div>

          <div className={'ui tab segment' + (activeIndex === 1 ? ' active' : '')}>
            <Accordion fluid styled panels={historyHtml} exclusive={false}/>
            <div className="ui hidden divider"></div>
            <div ref="clearButton" className="ui black basic button" onClick={this.clearClick}>
              <i className="icon trash alternate outline"></i>
              Clear log
            </div>
            <div ref="closeButton" className="ui green button" onClick={this.close}>
              <i className="icon remove"></i>
              Close
            </div>
          </div>

          <div className={'ui tab segment' + (activeIndex === 2 ? ' active' : '')}>
            <ObjectInspector data={MCBrws.getForLog()} name="/"/>
            <div className="ui hidden divider"></div>
            <div ref="closeButton" className="ui green button" onClick={this.close}>
              <i className="icon remove"></i>
              Close
            </div>
          </div>

        </Modal>

      </div>);
  }

}

export {LogConsole};