import {Library} from "../../../../app/src/library.js";

const Appmodeler = {};

Appmodeler.constantMapping = (target, constant) => {
  let value;
  if (typeof constant === 'string') {
    value = '\'' + constant + '\'';
  } else {
    value = String(constant);
  }
  return {
    'rbs:id': Library.guid(),
    'svc:OperationActionMapping': [{
      'rbs:id': Library.guid(),
      'svc:param1': value
    }],
    'svc:tgtparam': target
  }
}

Appmodeler.jsonConstantMapping = (target, json) => {
  return {
    'rbs:id': Library.guid(),
    'svc:OperationActionMapping': [{
      'rbs:id': Library.guid(),
      'svc:mfunction': 'jsonToData',
      'svc:OperationActionMapping': [{
        'rbs:id': Library.guid(),
        'svc:param1': '\'' + JSON.stringify(json) + '\''
      }]
    }],
    'svc:tgtparam': target
  }
}


Appmodeler.simpleMapping = (target, sourcePath) => {
  return {
    'rbs:id': Library.guid(),
    'svc:OperationActionMapping': [{
      'rbs:id': Library.guid(),
      'svc:param1': sourcePath
    }],
    'svc:tgtparam': target
  }
}

Appmodeler.removeTimezone = (dateTime) => {
  return {
   'rbs:id': Library.guid(),
   'svc:OperationActionMapping': [{
     'rbs:id': Library.guid(),
     'svc:mfunction': 'removeTimezone',
     ...dateTime
   }]
  }
}

Appmodeler.appCfgVal2 = (key) => {
  return {
    'rbs:id': Library.guid(),
    'svc:OperationActionMapping': {
      'rbs:id': Library.guid(),
      'svc:mfunction': 'appCfgVal2',
      'svc:OperationActionMapping': {
        'rbs:id': Library.guid(),
        'svc:param1': key
      }
    }
  }
}

Appmodeler.mapping = (target, mapping) => {
  return {
    'rbs:id': Library.guid(),
    ...mapping,
    'svc:tgtparam': target
  }
}

Appmodeler.currentDate = () => {
  return {
    'rbs:id': Library.guid(),
    'svc:OperationActionMapping': [{
      'rbs:id': Library.guid(),
      'svc:mfunction': 'currentDate'
    }]
  }
}

Appmodeler.decisionAction = () => {
  return {
    'rbs:id': Library.guid(),
    'svc:kind': 'decision'
  }
}

Appmodeler.operationActionBranch = () => {
  return {
    'rbs:id': Library.guid(),
    'svc:applogexception': '0',
    'svc:applogging': '0'
  }
}

Appmodeler.endOperationAction = () => {
  return {
    'rbs:id': Library.guid(),
    'svc:kind': 'end'
  }
}

Appmodeler.callAction = () => {
  return {
    'rbs:id': Library.guid(),
    'svc:kind': 'call'
  }
}

Appmodeler.startAction = () => {
  return {
    'rbs:id': Library.guid(),
    'svc:kind': 'start'
  }
}

Appmodeler.endAction = () => {
  return {
    'rbs:id': Library.guid(),
    'svc:kind': 'end'
  }
}

Appmodeler.transform = () => {
  return {
    'rbs:id': Library.guid(),
    'svc:kind': 'transform'
  }
}

Appmodeler.position = (x, y) => {
  return {
    'svc:posx': String(x),
    'svc:posy': String(y)
  }
}

Appmodeler.operation = () => {
  return {
    'rb:type': 'Operation'
  }
}

export {Appmodeler};
