import React from "../../miniclient/in/client/node_modules/react";
import ReactDOM from "../../miniclient/in/client/node_modules/react-dom";
import createReactClass from 'create-react-class';
import {Library} from "./library.js";

const CodeEditor = createReactClass({
  handleChange() {
    const component = this;
    if (!component.withoutChanges) {
      const value = component.aceEditor.getValue();
      if (component.changeTimeout) {
        clearTimeout(component.changeTimeout);
      }
      component.changeTimeout = setTimeout(() => {
        component.props.onValueUpdate(value);
        component.changeTimeout = undefined;
      }, 1000);
    }
  },
  setValue: function(value) {
    const component = this;
    component.withoutChanges = true;
    component.aceEditor.setValue(value);
    component.aceEditor.clearSelection();
    component.withoutChanges = false;
  },
  setContentType: function(contentType) {
    const component = this;
    const modes = {
      'text/html': 'ace/mode/html',
      'text/css': 'ace/mode/css'
    }
    component.session.setMode(modes[contentType]);
  },
  componentDidUpdate: function(prevProps, prevState, snapshot) {
    const component = this;
    const props = component.props;
    if (props.contentType !== prevProps.contentType) {
      component.setContentType(props.contentType);
    }
    const valueChanged = props.value !== component.aceEditor.getValue() && props.value !== prevProps.value;
    if (!component.changeTimeout && valueChanged) {
      component.setValue(props.value);
    }
  },
  componentDidMount: function() {
    const component = this;
    const props = component.props;
    const editorDomNode = ReactDOM.findDOMNode(component);
    const aceEditorDomNode = document.createElement('div');
    aceEditorDomNode.style.position = 'absolute';
    aceEditorDomNode.style.top = '0px';
    aceEditorDomNode.style.left = '0px';
    aceEditorDomNode.style.right = '0px';
    aceEditorDomNode.style.bottom = '0px';
    editorDomNode.appendChild(aceEditorDomNode);
    component.aceEditor = ace.edit(aceEditorDomNode);
    const session = component.aceEditor.getSession();
    component.session = session;
    session.on('change', function(e) {
        component.handleChange();
    });
    if (typeof props.value != 'undefined' && props.value.length !== 0) {
      component.setValue(props.value);
    }
    component.setContentType(props.contentType);
    component.aceEditor.$blockScrolling = Infinity;
  },
  render: function() {
    const component = this;
    const style = {position: 'relative'};
    const className = 'meta column grow';
    return <div className={className} style={style}/>;
  }
})
export {CodeEditor};
