import React from "react";
import ReactDOM from "react-dom";
import Chartjs from 'chart.js';

import {MC} from './MC.js';

class Chart extends React.Component {

  chartObject = null;
  data = null;

  componentWillMount() {
    Chartjs.Tooltip.positioners.miniclient = function(tooltips, position) {
      if (Array.isArray(tooltips)) {
        var canvas = tooltips[0]._chart.canvas;
        var tooltip = tooltips[0]._view;
        if (position.x + tooltip.x > canvas.clientWidth) {
          return { x: canvas.clientWidth - tooltip.x, y: position.y };
        } else if (position.x < 80) {
          return { x: tooltip.x, y: position.y };
        }
      }
      return { x: position.x, y: position.y };
    };
    this.data = MC.extend(true, {}, this.props.data.param);
  }

  componentDidMount() {
    this.initializeChart(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.data) != JSON.stringify(nextProps.data.param)) {
      this.data = MC.extend(true, {}, nextProps.data.param);
      this.destroyChart();
      this.initializeChart(nextProps);
    }
  }

  componentWillUnmount() {
    this.destroyChart();
  }

  destroyChart() {
    this.chartObject && this.chartObject.destroy();
  }

  initializeChart(props) {
    var element = ReactDOM.findDOMNode(this);
    var defaultColors = ['#36A2EB', '#FF6384', '#FFCE56', '#33C379', '#0063E4', '#DD3B3B', '#F0D193', '#94F686', '#0088B7', '#A90C13', '#DCB927', '#ABCB00'];
    var type = props.type;
    var legPos = MC.getFieldParamValue(props.data.param, '@legendPosition', props.iteration);
    var legend = {};
    legend.display = (legPos == 'none' ? false : true);
    if (['right', 'left', 'top', 'bottom', 'none'].indexOf(legPos) < 0) {
      legPos = 'top';
    }
    legend.position = MC.isNull(legPos) || legPos == 'none' ? 'top' : legPos;
    if (type == 'pie') {
      var values = MC.asArray(MC.getFieldParamValue(props.data.param, 'values*/@amount', props.iteration));
      var labels = MC.asArray(MC.getFieldParamValue(props.data.param, 'values*/@name', props.iteration));
      var colors = MC.asArray(MC.getFieldParamValue(props.data.param, 'values*/@color', props.iteration));
      if (MC.isNull(colors)) {
        colors = defaultColors;
      }
      if (Array.isArray(values) && values.length > colors.length) {
        colors = this.fillColors(colors, values.length);
      }
      var data = {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: colors
        }]
      };
      var cutoutPercentage = MC.getFieldParamValue(props.data.param, '@cutoutPercentage', props.iteration);
      if (MC.isNull(cutoutPercentage)) {
        cutoutPercentage = 0;
      }
      this.chartObject = new Chartjs(element, {
        type: 'pie',
        data: data,
        options: {
          legend: legend,
          cutoutPercentage: cutoutPercentage,
          tooltips: {
            position: 'miniclient',
            caretSize: 0
          }
        }
      });
    } else if (type == 'basic') {
      var labels = MC.asArray(MC.getFieldParamValue(props.data.param, 'xAxis/c*', props.iteration));
      var ysoftmin = 0;
      ysoftmin = MC.asArray(MC.getFieldParamValue(props.data.param, 'yAxis/softMin', props.iteration));
      var ysoftmax = 0;
      ysoftmax = MC.asArray(MC.getFieldParamValue(props.data.param, 'yAxis/softMax', props.iteration));
      var ysoftminSecondary = 0;
      ysoftminSecondary = MC.asArray(MC.getFieldParamValue(props.data.param, 'yAxisSecondary/softMin', props.iteration));
      var ysoftmaxSecondary = 0;
      ysoftmaxSecondary = MC.asArray(MC.getFieldParamValue(props.data.param, 'yAxisSecondary/softMax', props.iteration));
      var series = MC.asArray(MC.getFieldParamValue(props.data.param, 'series*/d*', props.iteration));
      var types = MC.asArray(MC.getFieldParamValue(props.data.param, 'series*/@type', props.iteration));
      var names = MC.asArray(MC.getFieldParamValue(props.data.param, 'series*/@name', props.iteration));
      var colors =  MC.asArray(MC.getFieldParamValue(props.data.param, 'series*/@color', props.iteration));
      let isSecondary =  MC.asArray(MC.getFieldParamValue(props.data.param, 'series*/@isSecondary', props.iteration));
      let interpolationModes = MC.asArray(MC.getFieldParamValue(props.data.param, 'series*/@interpolationMode', props.iteration));
      let fills = MC.asArray(MC.getFieldParamValue(props.data.param, 'series*/@fill', props.iteration));
      let backgroundColors = MC.asArray(MC.getFieldParamValue(props.data.param, 'series*/@backgroundColor', props.iteration));
      var type = MC.getFieldParamValue(props.data.param, '@chartType', props.iteration);
      var stacked = MC.getFieldParamBooleanValue(props.data.param, '@stacked', props.iteration) || false;
      let timeUnit = MC.getFieldParamValue(props.data.param, 'xAxis/timeUnit', props.iteration);
      if (MC.isNull(colors)) {
        colors = defaultColors;
      }
      let useSecondaryYaxis = false;
      var datasets = [];
      for (var i = 0; i < series.length ; i++) {
        var dataset = {};
        dataset.type = i < types.length ? types[i] : types[types.length-1];
        dataset.data = series[i];
        dataset.label = i < names.length ? names[i] : '';
        let color = i<colors.length ? colors[i] : colors[i % colors.length];
        dataset.borderColor = color;
        dataset.backgroundColor = color;
        if (dataset.type == 'line') {
          if (i < interpolationModes.length && ["default", "monotone"].indexOf(interpolationModes[i]) > -1) {
            dataset.cubicInterpolationMode = interpolationModes[i];
          } else {
            dataset.lineTension = 0;
          }
          if (i < fills.length && ["origin", "start", "end"].indexOf(fills[i]) > -1) {
            dataset.fill = fills[i];
            if (backgroundColors[i]) {
              dataset.backgroundColor = backgroundColors[i];
            }
          } else {
            dataset.fill = false;
          }
        }
        if (i < isSecondary.length && isSecondary[i] === true) {
          dataset.yAxisID = 'yaxis-secondary';
          useSecondaryYaxis = true;
        } else {
          dataset.yAxisID = 'yaxis-primary';
        }
        dataset.borderWidth = 2;
        datasets.push(dataset);
      }
      const yAxes = [];
      yAxes.push({id: 'yaxis-primary', stacked: stacked, position: 'left', ticks: {suggestedMin: ysoftmin, suggestedMax: ysoftmax} });
      if (useSecondaryYaxis) {
        yAxes.push({id: 'yaxis-secondary', stacked: stacked, position: 'right', ticks: {suggestedMin: ysoftminSecondary, suggestedMax: ysoftmaxSecondary} });
      }
      type = (type == 'line' ? 'line' : 'bar');
      let xAxes = {stacked: stacked};
      if (!MC.isNull(timeUnit)) {
        xAxes.type = 'time';
        xAxes.distribution = 'linear';
        let time = {};
        time.unit = timeUnit;
        let timeMin = MC.getFieldParamValue(props.data.param, 'xAxis/timeMin', props.iteration);
        if (!MC.isNull(timeMin)) {
          time.min = timeMin;
        }
        let timeMax = MC.getFieldParamValue(props.data.param, 'xAxis/timeMax', props.iteration);
        if (!MC.isNull(timeMax)) {
          time.max = timeMax;
        }
        xAxes.time = time;
      }
      this.chartObject = new Chartjs(element, {
        type: type,
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          legend: legend,
          responsive: true,
          scales: {
            xAxes: [xAxes],
            yAxes: yAxes
          },
          tooltips: {
            mode: "x",
            intersect: false,
            position: "nearest",
            backgroundColor: "#FFFFFF",
            titleFontColor: "#000000",
            titleFontStyle: "normal",
            titleMarginBottom: 10,
            bodyFontColor: "#516C85",
            bodySpacing: 5,
            borderColor: "#E3EDED",
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: false,
            callbacks: {
              labelTextColor:function(tooltipItem, chart) {
                return chart.data.datasets[tooltipItem.datasetIndex].borderColor;
              }
            }
          }
        }
      });
    }
  }

  fillColors(colors, size) {
    var ncolors = [];
    for (var i = 0; i < size; i++) {
      if (i<colors.length) {
        ncolors.push(colors[i]);
      } else {
        ncolors.push(colors[i % colors.length]);
      }
    }
    return ncolors;
  }

  render() {
    return <canvas data-widget-i-name={this.props.data.id}/>
  }

}

export {Chart};