import React from "../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "./library.js";
import {Button} from './widget/button.jsx';

const SaveButton = createReactClass({
  load: function(context) {
    return {readOnly: context.readOnly, storage: context.storage};
  },
  render: function() {
    const component = this;
    const props = component.props;
    const isDisabled = props.readOnly || !props.storage.filesNeedStore();
    return (
         <Button
           className="save"
           onClick={() => props.onUpdate({type: 'save'})}
           label="Save"
           disabled={isDisabled}/>
       )
  }
});

export {SaveButton};
