import React from "react";

import {MC} from './MC.js';

class Media extends React.Component {

  render() {
    var field = this.props.data;
    var cssclass = MC.getFieldParamValue(field.param, '@cssClass', this.props.iteration);
    cssclass = cssclass ? ' ' + cssclass : '';
    cssclass = 'ui image' + cssclass;
    var contentType = MC.getFieldParamValue(field.param, '@contentType', this.props.iteration);
    var showAsLink = MC.getFieldParamValue(field.param, '@showAsLink', this.props.iteration);
    if (showAsLink == true || field.widget == 'defaultviewer') {
      var fileName = MC.getFieldParamValue(field.param, '@fileName', this.props.iteration);
      if (MC.isNull(fileName)) {
        fileName = "...";
      }
      var uri = MC.getFieldParamValue(field.param, '@url', this.props.iteration);
      if (MC.isNull(uri)) {
        uri = 'data:' + contentType + ';base64,' + field.param['value'];
      } else {
        uri = MC.rebaseUrl(field.flow.flow.model, uri);
      }
      return (
        <a href={uri} download={fileName} target="_blank" data-widget-i-name={field.id}><i className="file icon"></i>{fileName}</a>
      );
    } else if (field.widget == 'imageviewer') {
      var uri = MC.getFieldParamValue(field.param, '@url', this.props.iteration);
      var value = MC.getFieldParamValue(field.param, 'value', this.props.iteration);
      if (!MC.isNull(uri)) {
        uri = MC.rebaseUrl(field.flow.flow.model, uri);
      } else if (!MC.isNull(value)) {
        uri = 'data:' + contentType + ';base64,' + value;
      }
      if (MC.isNull(uri)) {
        return <span className={cssclass} data-widget-i-name={field.id}/>
      } else {
        return (
          <img className={cssclass} src={uri} data-widget-i-name={field.id}></img>
        );
      }

    } else if (field.widget == 'pdfviewer') {
      var uri = MC.getFieldParamValue(field.param, '@url', this.props.iteration);
      var value = MC.getFieldParamValue(field.param, 'value', this.props.iteration);
      if (!MC.isNull(uri)) {
        uri = MC.rebaseUrl(field.flow.flow.model, uri);
      } else if (!MC.isNull(value)) {
        uri = 'data:application/pdf;base64,' + field.param['value'];
      }
      if (MC.isNull(uri)) {
        return <span className={cssclass} data-widget-i-name={field.id}/>
      } else {
        return (
          <embed src={uri} width={field.width} height={field.height} type="application/pdf" data-widget-i-name={field.id}></embed>
        );
      }
    } else {
      return (
        <span data-widget-i-name={field.id}>Unsupported content type: {contentType}</span>
      );
    }

  }

}

export {Media};