import React from "react";
import ReactDOM from "react-dom";

import {MC} from "../client/MC.js";

import {InspectorOption} from "./InspectorOption.jsx";
import {WidgetModel} from "./WidgetModel.js";

/* Inspektor vybraného pole na formuláři. Vlastnosti polí jsou načítané z modelu. */
let popUpSetting = {delay: {show: 1000, hide: 0}};

class Inspector extends React.Component {

  state = {search: ""};

    componentDidMount() {
      var self = this;
      var $node = $(ReactDOM.findDOMNode(this));
      $node.find("input.search").focus();
      $node.find(".ui.sticky").sticky({context: '#innerModeler'});
      var $accordion = $node.find(".ui.accordion")
      $accordion.accordion({exclusive: false});
    }

    handleSearchChange = (event) => {
        this.setState({search: event.target.value});
    };

    editInRepository = () => {
      this.props.inspectedFields.forEach(function(field) {
        if (!field.isNotStored) {
          var modeler = field.getModelerReact();
          var formModel = modeler.state.form.model;
          var formId = modeler.state.form.formId;
          var fieldId = field.rbsid;

          window.open(rbBaseUri + formModel + '/Form/' + formId + '?p=svc:FormField;' + fieldId, '_blank');
        }
      })
    };

    componentDidUpdate (prevProps, prevState) {
      /*if (this.props.inspectedFields.length = 1 && prevProps.inspectedField != this.props.inspectedField) {
        var $node = $(ReactDOM.findDOMNode(this));
        $node.find("#searchInput").focus();
      }*/
      var $node = $(ReactDOM.findDOMNode(this));
      $node.find(".ui.accordion").accordion({exclusive: false});
      $node.find("button").popup(popUpSetting)
    }

    inspectedFieldsOptions = () => {
      var inspectedFields = this.props.inspectedFields;
      var fieldsOptions = inspectedFields.map(function(field) {
        return field.getOptions();
      });
      var moreThenOne = inspectedFields.length > 1;
      return fieldsOptions[0].filter(function(option) {
        var isInEvery = fieldsOptions.every(function(options) {
          return options.find(function(innerOption){
            return innerOption.name == option.name;
          });
        });
        return isInEvery && (!moreThenOne || option.path[0] != "id");
      });
    };

    inspectedFieldsOptionValue = (path) => {
      var inspectedFields = this.props.inspectedFields;
      var values = inspectedFields.map(function(field) {
        return field.getOption(path);
      });
      return reduceValues(values)
    };

    inspectedFieldsMappedOptionValue = (path) => {
      var inspectedFields = this.props.inspectedFields;
      var values = inspectedFields.map(function(field) {
        return field.getMappedOption(path);
      });
      return reduceValues(values)
    };

    inspectedFieldsSetOptionValue = (path, value) => {
      var inspectedFields = this.props.inspectedFields;
      console.log(path, value)
      inspectedFields.forEach(function(field) {
        field.setOption(path, value);
      })
    };

    groupOptions = () => {
      var self = this;
      var options = this.inspectedFieldsOptions();
      var inspectorOptions = options.filter(function (option) {
          return strContains(option.name, self.state.search);
        });
      var groups = ["basic", "grid", "column"].concat(["others"]); // .concat(WidgetModel.getGroups())
      return groups.map(function(group) {
        var groupOptions = inspectorOptions.filter(function(option) {
          return option.group == group;
        })
        return [group, groupOptions];
      });
    };

    makeInspectorOption = (option) => {
      var field = this.props.inspectedField;
      var self = this;
      var defValue = self.inspectedFieldsOptionValue(option.path);
      var mappedValue = self.inspectedFieldsMappedOptionValue(option.path);
      var cls = "name ";
      if (!isIndeterminate(defValue) && !isIndeterminate(mappedValue) && !equalValues(defValue,mappedValue)) {
        cls += " warning";
      }
      return <div className="meta row option" key={option.path.join('_')}>
              <div className={cls}>{option.name}</div>
              <div className="value"><InspectorOption
                            option={option}
                            field={field}
                            onOptionValue={self.inspectedFieldsOptionValue}
                            onSetOptionValue={self.inspectedFieldsSetOptionValue}
                            onSetSelectedField={self.props.onSetSelectedField}
                            onStoreForm={self.props.onStoreForm}
                            onGetFormCopy={self.props.onGetFormCopy}
                            onCancelChange={self.props.onCancelChange}
                            onFormWasChanged={self.props.onFormWasChanged}/></div>
             </div>
      return <tr className={cls} key={option.path.join('_')}>
              <td>{option.name}</td>
              <td>
                <InspectorOption
                              option={option}
                              field={field}
                              onOptionValue={self.inspectedFieldsOptionValue}
                              onSetOptionValue={self.inspectedFieldsSetOptionValue}
                              onSetSelectedField={self.props.onSetSelectedField}
                              onStoreForm={self.props.onStoreForm}
                              onGetFormCopy={self.props.onGetFormCopy}
                              onCancelChange={self.props.onCancelChange}
                              onFormWasChanged={self.props.onFormWasChanged}/>
                </td>
              </tr>;
    };

    makeInspectorItems = () => {
      var self = this;
      var groupOptions = this.groupOptions();
      groupOptions = groupOptions.filter(function(group) {
        return group[1].length != 0;
      })
      var groupNames = WidgetModel.getGroupsNames();
      if (typeof groupNames["grid"] != "undefined") {
        var modelerReact = this.props.inspectedFields[0].getModelerReact()
        var form = modelerReact.state.form;
        var resolution = modelerReact.state.resolution;
        var availableResolution = MC.getAvailableResolution(resolution, form);
        groupNames["grid"] += " (" + availableResolution + ")";
      }
      var inspectorOptions = groupOptions.map(function (groupAndOptions) {
        var group = groupAndOptions[0];
        var options = groupAndOptions[1];
        var inspectorOptions = options.map(function(option) {
          return self.makeInspectorOption(option);
        });
        var groupHeader = <div className="group-name">{groupNames[group]}</div>
        return [groupHeader, inspectorOptions];
      });

      return flatten(inspectorOptions);

      var accordionParts = inspectorOptions.map(function(groupAndInspectorOptions){
        var group = groupAndInspectorOptions[0];
        var inspectorOptions = groupAndInspectorOptions[1];
        return [<div key={group+ "title"}>
                  <div className="ui tiny header">{groupNames[group]}</div>

                </div>,
                <div className="" key={group+ "content"}>
                  <table className="ui compact very basic striped table">
                    <tbody>
                      {inspectorOptions}
                    </tbody>
                  </table>
              </div>];
        });
      return accordionParts;
    };
    
    render () {
      var self = this;
      var field = this.props.inspectedField;
      var inspectedFields = this.props.inspectedFields;
      var areInspectedFields = inspectedFields.length != 0;
      if (this.props.loaded && areInspectedFields) {
          var inspectorItems = self.makeInspectorItems();
          /*var middle = Math.round(inspectorSegments.length/2);
          var leftSegments = inspectorSegments.slice(0, middle);
          var rightSegments = inspectorSegments.slice(middle);
          var accordionInnerLeft = leftSegments.reduce(function(acc, part) {
            return acc.concat(part);
          }, []);
          var leftAccordion =  <div className="">
                                {accordionInnerLeft}
                               </div>;
          var accordionInnerRight = rightSegments.reduce(function(acc, part) {
            return acc.concat(part);
          }, []);
          var rightAccordion =  <div className="">
                                {accordionInnerRight}
                               </div>;*/

          var editInRepository;
          var isAtLeastOneOld = this.props.inspectedFields.some(function(field) {
            return !field.isNotStored
          });
          if (isAtLeastOneOld) {
            editInRepository = <div className="meta menu-item">
                                <button className="ui icon button" onClick={this.editInRepository} data-content="Editovat v respository"><i className={"edit icon"}/></button>
                               </div>
          }
          var search = <div className="ui icon input mini meta menu-item">
                        <input className="search"
                              id="searchInput"
                              type="text"
                              name="propertysearch"
                              placeholder="Hledej..."
                              onChange={this.handleSearchChange}
                              value={this.state.search}/>
                        <i className="search icon"/>
                       </div>
          var deleteItem = <div className="meta menu-item">
                             <button className="ui  button" onClick={this.props.onDeleteSelectedFields} data-content="Odstranit pole">Smazat</button>
                           </div>
          var menu = <div className="meta menu top component">
                      <div className="category search menu-item">
                        {search}
                      </div>
                      {deleteItem}
                      {editInRepository}
                    </div>;
          var style = {top: this.props.position.y, //Math.min(height/2,this.props.position.y),
                       left: this.props.position.x,
                       width: this.props.width};
          var innerClassName = "inner";
          if (this.props.large) {
            innerClassName += " large";
          }
            return (
                <div id="inspector" style={style}>
                  {menu}
                  <div className={innerClassName}>
                    {inspectorItems}
                  </div>
                </div>);
        }
        if (areInspectedFields && !this.props.loaded) {
            return <div className="" id ="inspector">
                <div className="ui segment basic loading">
                </div>
            </div>;
        }

        return <div className="" id ="inspector"/>;

    }
}

function strContains (str1, str2) {
    return str1.toUpperCase().includes(str2.toUpperCase())
}

function arrayEqual(arr1, arr2) {
 var length = arr1.length
 if (length != arr2.length) return false
 for (var i = 0; i < length; i++)
   if (arr1[i] != arr2[i])
     return false
 return true
}

function reduceValues (values) {
  return values.reduce(function(v1, v2) {
    if (isIndeterminate(v1)) {
      return v1;
    }
    if (v1 == v2) {
      return v1;
    } else {
      return {message: "indeterminate"};
    }
  });
}

function isIndeterminate (value) {
  return value instanceof Object && value.message == "indeterminate";
}

function equalValues(value1, value2) {
  if (typeof value1 == "undefined") {
    value1 = "";
  }
  if (typeof value2 == "undefined") {
    value2 = "";
  }
  return String(value1) == String(value2);
}


function flatten (arrays) {
  return arrays.reduce(function(a, b){
     return a.concat(b);
   }, []);
}

export {Inspector};
