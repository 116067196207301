import React from "react"
import ReactDOM from "react-dom"
import {Dimmer} from 'semantic-ui-react'

import {FieldDef} from "../modeler/FieldDef.js"
import {Modeler} from "../modeler/Modeler.jsx"

import {MC} from "./MC.js"
import {MCHistory} from "./MCHistory.js"
import {MCCache} from "./MCCache.js"
import {FlowInput} from "./FlowInput.jsx"
import {LogConsole} from "./LogConsole.jsx"
import {Dialog} from "./Dialog.jsx"
import {Form} from "./Form.jsx"
import {Flow} from "./Flow.js"
import {Message} from "./Message.jsx"

import './miniclient.css'

class ReactFlow extends React.Component {

  flow = null;
  flowTemplate = 'miniclient;v=2/in/convertor/flow?configuration={configuration}&id={flowId}&name={flowName}&lang={lang}';
  flowServerUrl = 'in/flow/api/';
  domElement = null;
  state = {dimmer: false, runReady: false, showLogConsole: false, showNoFE: false, showInput: false, flowName: null, configuration: null, loader: 'all',
    inputData: null, editMode: this.props.editMode, env: null, serverSide: false, serverLogLevel: '', input: null, request: null, start: false};

  load(configuration, flowName, input, options = {}) {
    let promiseObject = {}
    let promise = new Promise(function (resolve, reject) {
      promiseObject = {resolve: resolve, reject: reject}
    });
    var flow = new Flow(null, promiseObject, promise);
    flow.setFlowConfiguration(configuration, flowName);
    flow.setLang(MC.getLang()).setFlowDataTemplate('miniclient;v=2/in/convertor/flow?configuration={configuration}&id={flowId}&name={flowName}&lang={lang}').setFlowServerUrl('in/flow/api/');
    if (options.debug) {
      flow.debugMode();
    }
    if (options.onEndFunction) {
      flow.setOnEndFunction(options.onEndFunction)
    }
    flow.loadAndStart(input, {language: MC.getLang()}, null);
    return promise;
  }

  componentWillMount() {
    this.updateStateFromProps(this.props);
    if (!MC.isNull(this.props.flowTemplate)) {
      this.flowTemplate = this.props.flowTemplate;
    }
    if (!MC.isNull(this.props.flowServerUrl)) {
      this.flowServerUrl = this.props.flowServerUrl;
    }
  }

  componentWillReceiveProps(nextProps) {
    this.updateStateFromProps(nextProps);
  }

  componentDidMount() {
    this.domElement = ReactDOM.findDOMNode(this.refs.container);
    this.startFlow();
  }

  componentDidUpdate() {
    this.startFlow();
  }

  componentWillUnmount() {
    this.flow = null;
  }

  updateStateFromProps(props) {
    let self = this;
    if (props.flowInstance && props.formData) {
      if (this.flow == props.flowInstance) {
        return;
      }
      this.flow = props.flowInstance;
      this.flow.reactFlow = this;
      if (props.onEndFunction) {
        this.flow.setOnEndFunction(this.props.onEndFunction);
      }
      if (props.onSubmitFunction) {
        this.flow.setOnSubmitFunction(this.props.onSubmitFunction);
      }
      this.flow.setServerLogLevel(props.serverLogLevel || '');
      const configuration = this.flow.confPath;
      const flowName = this.flow.flowName;
      const loader = ['init', 'none'].indexOf(props.loader) > -1 ? props.loader : 'all';
      this.setState({showInput: false, flowName: flowName, configuration: configuration, env: props.env, serverSide: props.serverSide, serverLogLevel: props.serverLogLevel || '',
        input: props.input, start: props.start, request: props.request, loader: loader,
        state: null, formData: props.formData, dimmer: false, runReady: true}, () => {
          this.setState({state: 'form'});
      });
    } else {
      const configuration = props.configuration;
      const flowName = props.flowName;
      const showInput = !(configuration && flowName || MC.isPlainObject(props.configurationObject) &&  MC.isPlainObject(props.nsMap));
      const loader = ['init', 'none'].indexOf(props.loader) > -1 ? props.loader : 'all';
      this.setState({showInput: showInput, flowName: flowName, configuration: configuration, env: props.env, serverSide: props.serverSide, serverLogLevel: props.serverLogLevel  || '',
        input: props.input, runReady: false, start: props.start, request: props.request, loader: loader});
    }
  }

  startFlow() {
    if (this.state.start) {
      this.setState({start: false});
      var flow = new Flow(this);
      if (MC.isPlainObject(this.props.configurationObject) || MC.isPlainObject(this.props.nsMap)) {
        flow.setFlowConfigurationProps(this.props.configurationObject, this.state.configuration, this.state.flowName, this.props.nsMap);
      } else {
        flow.setFlowConfiguration(this.state.configuration, this.state.flowName);
      }
      flow.setLang(MC.getLang()).setFlowDataTemplate(this.flowTemplate).setFlowServerUrl(this.flowServerUrl);
      if (this.props.debug) {
        flow.debugMode();
      }
      var env = this.state.env;
      if (env) {
        flow.setEnv(env);
      }
      if (this.state.serverSide) {
        flow.setServerSide();
      }
      if (this.props.onEndFunction) {
        flow.setOnEndFunction(this.props.onEndFunction);
      }
      if (this.props.onLeaveFunction) {
        flow.setOnLeaveFunction(this.props.onLeaveFunction);
      }
      if (this.props.onNextFormFunction) {
        flow.setOnNextFormFunction(this.props.onNextFormFunction);
      }
      if (this.props.afterRenderFormFunction) {
        flow.setAfterRenderFormFunction(this.props.afterRenderFormFunction);
      }
      if (this.props.onSubmitFunction) {
        flow.setOnSubmitFunction(this.props.onSubmitFunction);
      }
      var request = this.state.request;
      if (!request) {
        request = {};
        request.language = MC.getLang();
      }
      flow.setServerLogLevel(this.state.serverLogLevel);
      flow.loadAndStart(this.state.input, request, null);
      this.flow = flow;
    }
  }

  showLogConsole = () => {
    this.setState({showLogConsole: true, start: false, runReady: false});
  };

  hideLogConsole = () => {
    this.setState({showLogConsole: false, start: false, runReady: false});
  };

  showInputPanel = () => {
    this.setState({showInput: true, showLogConsole: false, editMode: false, start: false, runReady: false});
  };

  tongueEditMode = () => {
    this.setState({editMode: !this.state.editMode, showLogConsole: false, start: false, runReady: false});
  };

  clickRunFlow = (configuration, flowName, inputData, showNoFE, serverSide, serverLogLevel) => {
    var env = {};
    var input = {};
    if (inputData) {
      var inputDataObj = MC.xmlToJson(MC.parseXml(inputData));
      if (inputDataObj.data) {
        if (inputDataObj.data.env && MC.isPlainObject(inputDataObj.data.env) && !MC.isEmptyObject(inputDataObj.data.env)) {
          env = inputDataObj.data.env;
        }
        if (inputDataObj.data.input) {
          input = inputDataObj.data.input;
        }
      }
    }
    var showInput = true;
    var start = false;
    if (configuration && flowName) {
      MCHistory.clear();
      MCCache.clear();
      showInput = false;
      start = true;
    }
    this.setState({configuration: configuration, flowName: flowName, inputData: inputData, input: input, showNoFE: showNoFE, serverSide: serverSide, serverLogLevel: serverLogLevel, showInput: showInput, env: env, start: start});
  };

  render() {
    var state = this.state.state;
    var content;
    if (state == 'exception') {
      if (this.flow.debug) {
        var exception = this.state.exception;
        if (exception.message) {
          content = <div className="exceptionEnd">Exception <strong>{exception.type}</strong> thrown: {exception.message}</div>;
        } else {
          content = <div className="exceptionEnd">Exception <strong>{exception.type}</strong> thrown.</div>;
        }
      } else {
        content = <div className="exceptionEnd">Error!</div>;
      }
    } else if (state == 'output') {
      content = <div className="outputEnd"><strong>Output:</strong><br/> <pre>{JSON.stringify(this.state.output, null, 2)}</pre></div>;
    } else if (state == 'form') {
      FieldDef.setProto(this.state.formData);
      if (this.state.editMode) {
        content = <Modeler onSwitchOff={this.tongueEditMode} flow={this.flow} form={this.state.formData} element={this.domElement} runReady={this.state.runReady}/>;
      } else {
        content = <Form form={this.state.formData} element={this.domElement} runReady={this.state.runReady} key="form" embedded={this.props.embedded}/>;
      }
    }
    if (!content && this.state.dimmer) {
      content = <div><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p></div>
    }
    var inputPanel = null;
    var logConsole = null;
    if (this.props.debug && !this.props.embedded) {
      if (this.state.showInput) {
        inputPanel = <FlowInput flowName={this.state.flowName} inputData={this.state.inputData} configuration={this.state.configuration} showNoFE={this.state.showNoFE} serverSide={this.state.serverSide}
                                onRun={this.clickRunFlow} configurationChanger={this.props.configurationChanger} flowServerUrl={this.flowServerUrl} flowTemplate={this.flowTemplate} serverLogLevel={this.state.serverLogLevel}/>
      } else if (!this.state.editMode){
        inputPanel = [];
        inputPanel.push(<button key="k1" className="ui icon button" onClick={this.showInputPanel}><i className="setting icon"></i></button>);
        inputPanel.push(<button key="k2" className="ui icon button" onClick={this.showLogConsole}><i className="bug icon"></i></button>);
        var cls = "ui icon button toggle";
        if (this.state.editMode) {
          cls += " active";
        }
        inputPanel.push(<button key="k3" className={cls} onClick={this.tongueEditMode}><i className="paint brush icon"></i></button>);
      }
      logConsole = <LogConsole open={this.state.showLogConsole} onClose={this.hideLogConsole}/>;
    }
    let activeDimmer = this.state.dimmer;
    if (this.state.loader == 'none') {
      activeDimmer = false;
    }
    return (
      <div ref="container">
        <div>{inputPanel}</div>
        <Dimmer.Dimmable dimmed={activeDimmer}>
          <Dimmer inverted active={activeDimmer}>
            <div className="ui text loader">Loading</div>
          </Dimmer>
          {content}
          <Dialog key="modaldialog" dialog={this.state.dialog}/>
          <Message key="message" data={this.state.message}/>
        </Dimmer.Dimmable>
        {logConsole}
      </div> );
  }

}

export {ReactFlow};