import math from 'mathjs';
import {DateTime} from "luxon";

import {Duration} from "./Duration.js";
import {MCHistory} from "./MCHistory.js";
import {JdateFormat} from "./JdateFormat.js";
import {NumberFormat} from "./NumberFormat.js";

if (typeof String.prototype.startsWith != 'function') {
  String.prototype.startsWith = function (str) {
    return this.indexOf(str) == 0;
  };
}

if (typeof String.prototype.endsWith != 'function') {
  String.prototype.endsWith = function(suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
  };
}

const MC = {
  
  localMessages: {
    en: {
      required: "This field is required.",
      date: "Please enter a valid date.",
      time: "Please enter a valid time.",
      datetime: "Please enter a valid date and time.",
      number: "Please enter a valid number.",
      digits: "Please enter only digits.",
      maxlength: "Please enter no more than {0} characters.",
      minlength: "Please enter at least {0} characters.",
      max: "Please enter a value less than or equal to {0}.",
      min: "Please enter a value greater than or equal to {0}.",
      pattern: "Invalid format.",
      whisper: "Please select valid value.",
      confirm: "Are you sure?",
      cancel: "Cancel"
    },
    cs: {
      required: "Tento údaj je povinný.",
      date: "Prosím, zadejte platné datum.",
      time: "Prosím, zadejte čas ve správném formátu.",
      datetime: "Prosím, zadejte datum a čas ve správném formátu.",
      number: "Prosím, zadejte číslo.",
      digits: "Prosím, zadávejte pouze číslice.",
      maxlength: "Prosím, zadejte nejvíce {0} znaků.",
      minlength: "Prosím, zadejte nejméně {0} znaků.",
      max: "Prosím, zadejte hodnotu menší nebo rovnu {0}.",
      min: "Prosím, zadejte hodnotu větší nebo rovnu {0}.",
      pattern: "Nesprávný formát.",
      whisper: "Vyberte správnou hodnotu.",
      confirm: "Jste si jisti?",
      cancel: "Storno"
    },
    sk: {
      required: "Tento údaj je povinný.",
      date: "Prosím, zadajte platný dátum.",
      time: "Prosím, zadajte čas v správnom formáte.",
      datetime: "Prosím, zadajte dátum a čas v správnom formáte.",
      number: "Prosím, zadajte číslo.",
      digits: "Prosím, zadávajte iba číslice.",
      maxlength: "Prosím, zadajte najviac {0} znakov.",
      minlength: "Prosím, zadajte aspoň {0} znakov.",
      max: "Prosím, zadajte hodnotu menšiu alebo rovnú {0}.",
      min: "Prosím, zadajte hodnotu väčšiu alebo rovnú {0}.",
      pattern: "Nesprávny formát.",
      whisper: "Vyberte správnu hodnotu.",
      confirm: "Ste si istí?",
      cancel: "Storno"
    }
  },
  reactComponents: {},

  registerReactRomponent: function(name, component) {
    this.reactComponents[name] = component;
    if (MC.isFunction(this.onRegisterReactRomponent)) {
      this.onRegisterReactRomponent(name);
    }
  },

  getReactRomponent: function(name) {
    return this.reactComponents[name];
  },

  getReactRomponents: function() {
    return this.reactComponents;
  },

  onRegisterReactRomponent: function(val) {
    if (MC.isFunction(val)) {
      this.onRegisterReactRomponent = val;
    }
  },

  getMessages: function(lang) {
    if (MC.localMessages[lang]) {
      return MC.localMessages[lang];
    } else {
      return  MC.localMessages.en;
    }
  },

  formatMessage: function(source, params) {
    var messages = MC.getMessages(MC.getLang());
    if (!messages[source]) {
      return source;
    } else {
      var mess = messages[source];
      if (MC.isNull(params)) {
        return mess;
      }
      if (arguments.length > 2 && params.constructor !== Array) {
        params = arguments.slice(1);
      }
      if (params.constructor !== Array) {
        params = [params];
      }
      for (var i=0; i<params.length; i++) {
        mess = mess.replace( new RegExp( "\\{" + i + "\\}", "g" ), params[i]);
      }
      return mess;
    }
  },

  getLang: function() {
    if (typeof rbLang !== 'undefined') {
      return rbLang;
    }
    return "en";
  },

  asArray: function(variable) {
    return [].concat(variable);
  },

  asScalar: function(variable) {
    if (Array.isArray(variable)) {
      variable = MC.stripNulls(variable);
      if (Array.isArray(variable) && variable.length > 0) {
        return variable[0];
      } else {
        return null;
      }
    } else {
      return variable;
    }
  },

  getJsonType: function() {
    return 'application/json';
  },

  isFunction: function(obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
  },

  error: function(msg) {
    MCHistory.log(MCHistory.T_ERROR, msg, true);
    throw new Error(msg);
  },

  sortFieldsByX: function(fields) {
    fields.sort(function(a, b) {
      return a.x-b.x;
    });
  },

  sortFieldsByY: function(fields) {
    fields.sort(function(a, b) {
      return a.y-b.y;
    });
  },

  sortFieldsByIndex: function(fields, resolution) {
    var self = this;
    var swapped;
    do {
      swapped = false;
      for (var i=0; i < fields.length-1; i++) {
        if (parseInt(self.getFieldGrid(fields[i], resolution).index) > parseInt(self.getFieldGrid(fields[i+1], resolution).index)) {
          var temp = fields[i];
          fields[i] = fields[i+1];
          fields[i+1] = temp;
          swapped = true;
        }
      }
    } while (swapped);
  },

  getFieldGrid: function(field, resolution) {
    if (typeof field.grid != 'undefined') {
      for (var i = 0; i < field.grid.length; i++) {
        if (field.grid[i].resolution == resolution) {
          return field.grid[i];
        }
      }
    }
    return {
      columns: "3",
      index: "0",
      newLineAfter: "never",
      newLineBefore: "never",
      offset: "0",
      index: "0",
      resolution: resolution,
      visible: "true"
    };
  },

  splitFieldsIntoRows: function(fields, resolution) {
    var rows = [];
    if (MC.isNull(resolution)) {
      this.sortFieldsByY(fields);
      var row = [];
      var lastY = null;
      for (var i=0; i<fields.length; i++) {
        var field = fields[i];
        if (field.y == lastY) {
          row.push(field);
        } else {
          if (row.length > 0) {
            this.sortFieldsByX(row);
            rows.push(row);
          }
          row = [];
          row.push(field);
        }
        lastY = field.y;
      }
      if (row.length > 0) {
        this.sortFieldsByX(row);
        rows.push(row);
      }
    } else {
      this.sortFieldsByIndex(fields, resolution);
      var row = [];
      for (var i=0; i<fields.length; i++) {
        var field = fields[i];
        var grid = this.getFieldGrid(field, resolution);
        if (grid.newLineBefore && grid.newLineBefore === 'yes') {
          if (row.length > 0) {
            rows.push(row);
          }
          row = [];
        }
        row.push(field);
        if (grid.newLineAfter && grid.newLineAfter === 'yes') {
          rows.push(row);
          row = [];
        }
      }
      if (row.length > 0) {
        rows.push(row);
      }
    }
    return rows;
  },

  getMaxX: function(panelw, fields) {
    var totalMax = 0;
    var width = 0;
    for (var i=0; i<fields.length; i++) {
      if (fields[i].x >= totalMax) {
        totalMax = fields[i].x;
        width = fields[i].width;
      } else {
        width = 0;
      }
    }
    totalMax = totalMax + width;
    if (totalMax < panelw) {
      totalMax = panelw;
    }
    var max = totalMax;
    var lastX = 0;
    // substract ignoring gaps
    for (var i=0; i<fields.length; i++) {
      if (fields[i].x <= lastX + Math.round(totalMax/12)) {
        max = max - (fields[i].x - lastX);
      }
      lastX = fields[i].x + fields[i].width;
    }
    return max;
  },

  getFieldWideClass: function(max, width) {
    if (!max || !width || max == 0 || width == 0) {
      return 'five';
    }
    var wide = Math.round(width/max*12);
    switch (wide) {
      case 1: return 'one'; break;
      case 2: return 'two'; break;
      case 3: return 'three'; break;
      case 4: return 'four'; break;
      case 5: return 'five'; break;
      case 6: return 'six'; break;
      case 7: return 'seven'; break;
      case 8: return 'eight'; break;
      case 9: return 'nine'; break;
      case 10: return 'ten'; break;
      case 11: return 'eleven'; break;
      case 12: return 'twelve'; break;
    }
    return 'five';
  },
  getFieldWideClassAsInt: function(max, width) {
    if (!max || !width || max == 0 || width == 0) {
      return 'five';
    }
    return Math.round(width/max*12);
  },
  getFieldWideClassFromInt: function(size) {
    if (!size || size == 0) {
      return 'twelve';
    }
    var size = parseInt(size);
    switch (size) {
      case 1: return 'one'; break;
      case 2: return 'two'; break;
      case 3: return 'three'; break;
      case 4: return 'four'; break;
      case 5: return 'five'; break;
      case 6: return 'six'; break;
      case 7: return 'seven'; break;
      case 8: return 'eight'; break;
      case 9: return 'nine'; break;
      case 10: return 'ten'; break;
      case 11: return 'eleven'; break;
      case 12: return 'twelve'; break;
    }
    return 'twelve';
  },

  parseXml: function(xml) {
    var dom = null;
    if (window.DOMParser) {
      try {
        dom = (new DOMParser()).parseFromString(xml, "text/xml");
      }
      catch (e) { dom = null; }
    }
    else if (window.ActiveXObject) {
      try {
        dom = new ActiveXObject('Microsoft.XMLDOM');
        dom.async = false;
        if (!dom.loadXML(xml)) // parse error ..
          this.error(dom.parseError.reason + dom.parseError.srcText);
      }
      catch (e) { dom = null; }
    }
    else
      this.error("cannot parse xml string!");
    return dom;
  },

  xmlToJson: function(xml) {
    var obj = {};
    if (xml.nodeType == 3) { // text
      obj = xml.nodeValue;
    } else if (xml.hasChildNodes()) {
      for(var i = 0; i < xml.childNodes.length; i++) {
        var item = xml.childNodes.item(i);
        var nodeName = item.nodeName;
        if (typeof(obj[nodeName]) == "undefined") {
          var child = this.xmlToJson(item);
          if (Object.keys(child).length == 1 && child['#text']) {
            child = child['#text'];
          }
          if (child['#text']) {
            delete child['#text'];
          }
          obj[nodeName] = child;
        } else {
          if (typeof(obj[nodeName].push) == "undefined") {
            var old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          var child = this.xmlToJson(item);
          if (Object.keys(child).length == 1 && child['#text']) {
            child = child['#text'];
          }
          if (child['#text']) {
            delete child['#text'];
          }
          obj[nodeName].push(child);
        }
      }
    } else if (xml.nodeType == 1) { // empty
      obj = '';
    }
    return obj;
  },

  formatValue: function(value, formatter, basictype, pattern, field, iteration) {
    if (formatter === 'message') {
      var msg = pattern;
      if (!msg || !field.param['value'] || !MC.isPlainObject(field.param['value']) && !Array.isArray(field.param['value'])) {
        return '';
      }
      var matches = msg.match(/{[^}]*}/g);
      if (Array.isArray(matches) && matches.length > 0) {
        for (var i=0; i<matches.length; i++) {
          var token = matches[i].substring(1, matches[i].length-1);
          var componentsAll = token.split(',');
          var components = componentsAll;
          if (componentsAll.length > 3) {
            components = componentsAll.splice(0,2);
            components.push(componentsAll.join(','));
          }
          var sfield = components[0];
          if (token.indexOf(',') > 0) {
            sfield = token.substring(0, token.indexOf(','));
          }
          var mandat = true;
          if (sfield.endsWith('?')) {
            mandat = false;
            sfield = sfield.substring(0, sfield.length-1);
          }
          let value = MC.getFieldParamValue(field.param, `value/@${sfield}`, iteration)
          if (!MC.isNull(value)) {
            if (components.length > 1) {
              var type = null;
              if (field.msgparam && field.msgparam[sfield]) {
                type = field.msgparam[sfield]['basictype'];
              }
              value = MC.formatValue(value, components[1], type, components[2]);
            }
            msg = msg.replace(matches[i], value);
          } else {
            if (mandat) {
              return '';
            } else {
              msg = msg.replace(matches[i], '');
            }
          }
        }
        return msg;
      } else {
        return '';
      }
    } if (formatter === 'date') {
      if (MC.isNull(value) || value === '') {
        return '';
      }
      let lux = MC.dateTimeStringToLuxon(value, {setZone: true})
      if (!lux.v.isValid) {
        MC.error('Invalid value "' + value + '" was passed into formatter with formatType date!')
      }
      lux.v.setLocale(MC.getLang())
      if (pattern == 'short') {
        return lux.v.toLocaleString(DateTime.DATE_SHORT)
      } else if (pattern == 'medium') {
        return lux.v.toLocaleString(DateTime.DATE_MED)
      } else if (pattern == 'long') {
        return lux.v.toLocaleString(DateTime.DATE_FULL)
      } else if (pattern != null && pattern !== '') {
        return MC.formatDate(value, pattern)
      } else {
        return lux.v.toLocaleString(DateTime.DATE_SHORT)
      }
    } if (formatter === 'time') {
      if (MC.isNull(value) || value === '') {
        return ''
      }
      let lux = MC.dateTimeStringToLuxon(value, {setZone: true})
      if (!lux.v.isValid) {
        MC.error('Invalid value "' + value + '" was passed into formatter with formatType time!')
      }
      lux.v.setLocale(MC.getLang())
      if (pattern == 'short') {
        return lux.v.toLocaleString(DateTime.TIME_SIMPLE)
      } else if (pattern == 'medium') {
        return lux.v.toLocaleString(DateTime.TIME_WITH_SECONDS)
      } else if (pattern == 'long') {
        return lux.v.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
      } else if (pattern != null && pattern !== '') {
        return MC.formatDate(value, pattern)
      } else {
        return lux.v.toLocaleString(DateTime.TIME_WITH_SECONDS)
      }
    } if (formatter === 'datetime') {
      if (MC.isNull(value) || value === '') {
        return ''
      }
      let lux = MC.dateTimeStringToLuxon(value, {setZone: true})
      if (!lux.v.isValid) {
        MC.error('Invalid value "' + value + '" was passed into formatter with formatType datetime!')
      }
      lux.v.setLocale(MC.getLang())
      if (pattern == 'short') {
        return lux.v.toLocaleString(DateTime.DATETIME_SHORT)
      } else if (pattern == 'medium') {
        return lux.v.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
      } else if (pattern == 'long') {
        return lux.v.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
      } else if (pattern != null && pattern !== '') {
        return MC.formatDate(value, pattern)
      } else {
        return lux.v.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
      }
    } else if (formatter === 'number') {
      let locale = MC.getLang();
      let formatter = new NumberFormat();
      if (pattern != null && pattern !== '') {
        return formatter.formatNumber(value, {format: pattern, locale: locale});
      } else {
        if (basictype == 'integer') {
          return formatter.formatNumber(value, {format: '#,###', locale: locale});
        } else if (basictype == 'decimal') {
          return formatter.formatNumber(value, {format: '#,###.##', locale: locale});
        } else {
          return formatter.formatNumber(value, {format: '#,###.##', locale: locale});
        }
      }
    } else if (formatter === 'currency') {
      let locale = MC.getLang();
      let formatter = new NumberFormat();
      return formatter.formatNumber(value, {format: '#,###.##', locale: locale});
    } else if (formatter === 'percent') {
      return value + "%";
    } else {
      return value;
    }
  },

  formatDate: function(value, formatString) {
    let lux = MC.dateTimeStringToLuxon(value, {setZone: true})
    if (MC.hasTimezone(value)) {
      if (lux.v.offset === 0 && formatString.indexOf('XXX') > -1) {
        formatString = formatString.replace("XXX", "'Z'")
      }
    } else {
      formatString = formatString.replace('XXX', '')
    }
    if (formatString === '') {
      return '';
    }
    return MC.dateTimeStringToLuxon(value, {setZone: true}).v.toFormat(JdateFormat.toLuxonFormatString(formatString))
  },

  parseDate: function(value, formatString) {
    let lux
    if (MC.isNull(value) || value == '') {
      lux = {v: DateTime.local()}
    } else {
      if (formatString.indexOf('y') == -1) {
        value = '0000-' + value
        formatString = 'yyyy-' + formatString
      }
      lux = {v: DateTime.fromFormat(value, JdateFormat.toLuxonFormatString(formatString), {setZone: true}), _i: value}
    }
    return MC.luxonToDateTimeString(lux, 'dateTime')
  },

  stripStars: function(object) {
    if (MC.isPlainObject(object)) {
      var newObject = {};
      for (var key in object) {
        if (hasOwnProperty.call(object, key)) {
          if (MC.isPlainObject(object[key]) || Array.isArray(object[key])) {
            newObject[key] = MC.stripStars(object[key]);
          } else {
            newObject[key] = object[key];
          }
          if (key.endsWith('*')) {
            newObject[key.substring(0, key.length-1)] = newObject[key];
            delete newObject[key];
          }
        }
      }
      return newObject;
    } else if (Array.isArray(object)) {
      var newObject = [];
      for (var i=0; i<object.length; i++) {
        newObject.push(MC.stripStars(object[i]));
      }
      return newObject;
    } else {
      return object;
    }
  },

  stripNulls: function(object) {
    if (MC.isPlainObject(object)) {
      var newObject = {};
      for (var key in object) {
        if (hasOwnProperty.call(object, key)) {
          if (MC.isPlainObject(object[key]) || Array.isArray(object[key])) {
            var val = MC.stripNulls(object[key]);
            if (!MC.isNull(val)) {
              newObject[key] = val;
            }
          } else {
            if (!MC.isNull(object[key])) {
              newObject[key] = object[key];
            }
          }
        }
      }
      return newObject;
    } else if (Array.isArray(object)) {
      var newObject = [];
      for (var i=0; i<object.length; i++) {
        var val = MC.stripNulls(object[i]);
        if (!MC.isNull(val)) {
          newObject.push(val);
        }
      }
      return newObject;
    } else {
      if (MC.isNull(object)) {
        return null;
      } else {
        return object;
      }
    }
  },

  nullsToEmpty: function(object) {
    if (MC.isPlainObject(object)) {
      var newObject = {};
      for (var key in object) {
        if (hasOwnProperty.call(object, key)) {
          if (MC.isPlainObject(object[key]) || Array.isArray(object[key])) {
            newObject[key] = MC.nullsToEmpty(object[key]);
          } else {
            if (!MC.isNull(object[key])) {
              newObject[key] = object[key];
            } else {
              newObject[key] = '';
            }
          }
        }
      }
      return newObject;
    } else if (Array.isArray(object)) {
      var newObject = [];
      for (var i=0; i<object.length; i++) {
        var val = MC.nullsToEmpty(object[i]);
        if (!MC.isNull(val)) {
          newObject.push(val);
        } else {
          newObject.push('');
        }
      }
      return newObject;
    } else {
      return object;
    }
  },

  emptysToNull: function(object) {
    if (MC.isPlainObject(object)) {
      var newObject = {};
      for (var key in object) {
        if (hasOwnProperty.call(object, key)) {
          if (MC.isPlainObject(object[key]) || Array.isArray(object[key])) {
            newObject[key] = MC.emptysToNull(object[key]);
          } else {
            if (object[key] === '') {
              newObject[key] = null;
            } else {
              newObject[key] = object[key];
            }
          }
        }
      }
      return newObject;
    } else if (Array.isArray(object)) {
      var newObject = [];
      for (var i=0; i<object.length; i++) {
        var val = MC.emptysToNull(object[i]);
        if (val === '') {
          newObject.push(null);
        } else {
          newObject.push(val);
        }
      }
      return newObject;
    } else {
      return object;
    }
  },

  replaceInKeys: function(object, what, to) {
    if (MC.isPlainObject(object)) {
      for (var key in object) {
        if (hasOwnProperty.call(object, key)) {
          if (MC.isPlainObject(object[key]) || Array.isArray(object[key])) {
            MC.replaceInKeys(object[key], what, to);
          }
          if (key.indexOf(what) > -1) {
            object[key.replace(what, to)] = object[key];
            delete object[key];
          }
        }
      }
    } else if (Array.isArray(object)) {
      for (var i=0; i<object.length; i++) {
        MC.replaceInKeys(object[i], what, to);
      }
    }
  },

  getRowsCount: function(field, iterations, max) {
    let val = MC.getFieldParamValue(field.param, '@id', iterations)
    if (val) {
      let subMax = 0
      if (!MC.isNull(val)) {
        if (Array.isArray(val)) {
          subMax = val.length
        } else {
          subMax = 1
        }
      }
      if (subMax > max) {
        max = subMax
      }
    }
    if (field.fields) {
      let subFields = field.fields
      if (subFields.length > 0 && subFields[0].id == 'rows') {
        subFields = subFields[0].fields
      }
      for (var i=0; i<subFields.length; i++) {
        let subMax = 0
        let subField = subFields[i]
        if (subField.param['value'] && !MC.isPlainObject(subField.param['value'])) {
          let val = MC.getFieldParamValue(subField.param, 'value', iterations)
          if (!MC.isNull(val)) {
            if (Array.isArray(val)) {
              subMax = val.length
            } else {
              subMax = 1
            }
          }
          if (subMax > max) {
            max = subMax
          }
        }
        if (subField.fields) {
          subMax = MC.getRowsCount(subField, iterations, max)
          if (subMax > max) {
            max = subMax
          }
        }
      }
    }
    return max
  },

  validateArraysInInput: function(input, object) {
    for (var i=0; i<input.input.length; i++) {
      var param = input.input[i];
      var key = param.name;
      if (key.endsWith('*')) {
        key = key.substring(0, key.length-1);
        if (object[key] && !Array.isArray(object[key])) {
          var newArr = [];
          newArr.push(object[key]);
          object[key] = newArr;
        }
      }
      if (object[key]) {
        if (MC.isPlainObject(object[key])) {
          MC.validateArraysInInput(param, object[key]);
        } else if (Array.isArray(object[key])) {
          for (var i=0; i<object[key].length; i++) {
            MC.validateArraysInInput(param, object[key][i]);
          }
        }
      }
    }
  },

  customHtml: function(html) {
    if (html && typeof html == 'string') {
      html = html.replace(/{.*}/, ' ');
      html = html.replace(/\\n/g, '<br />');
    }
    return html;
  },

  getFieldParamValue: function(field, name, iterations) {
    if (MC.isNull(field)) {
      return null
    }
    if (name.indexOf('/') > -1) {
      const subname = name.substring(name.indexOf('/') + 1)
      name = name.substring(0, name.indexOf('/'))
      return MC.getFieldParamValue(field[name], subname, iterations)
    } else {
      let val = field[name]
      if (Array.isArray(iterations) && iterations.length > 0 && Array.isArray(val)) {
        for (let iter of iterations) {
          if (MC.isNumeric(iter) && Array.isArray(val)) {
            val = val[iter]
          }
        }
      }
      if (!MC.isNull(val)) {
        return val
      }

    }
    return null
  },

  getFieldParamBooleanValue: function(field, name, iterations) {
    var val = MC.getFieldParamValue(field, name, iterations)
    if (val != null) {
      if (val == true) {
        return true
      }
      if (val == false) {
        return false
      }
    }
    return null
  },

  putFieldParamValue: function(field, name, iterations, val) {
    if (name.indexOf('/') > -1) {
      var subname = name.substring(name.indexOf('/') + 1);
      var name = name.substring(0, name.indexOf('/'));
      if (!MC.isPlainObject(field[name])) {
        field[name] = {};
      }
      return MC.putFieldParamValue(field[name], subname, iterations, val);
    } else {
      if (Array.isArray(iterations)) {
        if (!Array.isArray(field[name])) {
          field[name] = []
        }
        let levelArr = field[name]
        for (let i=0; i<iterations.length; i++) {
          let index = iterations[i]
          if (i < iterations.length-1) { // path
            if (!Array.isArray(levelArr[index])) {
              levelArr[index] = []
            }
            levelArr = levelArr[index]
          } else { // list
            levelArr[index] = val
          }
        }
      } else {
        field[name] = val;
      }
    }
  },

  isNull: function(value) {
    if (value == undefined || value == null) {
      return true;
    } else if (Array.isArray(value)) {
      if (value.length == 0) {
        return true;
      } else {
        for (var i=0; i<value.length; i++) {
          if (!MC.isNull(value[i])) {
            return false;
          }
        }
        return true;
      }
      return (value.length == 0);
    } else if (MC.isPlainObject(value)) {
      return MC.isEmptyObject(value);
    } else {
      return false;
    }
  },

  isNullOrEmpty: function(value) {
    return MC.isNull(value) || value === '';
  },

  isEmptyObject: function(object) {
    for(var property in object) {
      if(object.hasOwnProperty(property)) {
        return false;
      }
    }
    return true;
  },

  isPlainObject: function(obj) {
    var proto, Ctor;
		// Detect obvious negatives
		// Use toString instead of jQuery.type to catch host objects
		if (!obj || ({}).toString.call(obj) !== "[object Object]") {
			return false;
		}
		proto = Object.getPrototypeOf(obj);
		// Objects with no prototype (e.g., `Object.create( null )`) are plain
		if (!proto) {
			return true;
		}
		// Objects with prototype are plain iff they were constructed by a global Object function
		Ctor = Object.hasOwnProperty.call(proto, "constructor") && proto.constructor;
		return typeof Ctor === "function" && Object.hasOwnProperty.toString.call(Ctor) === Object.hasOwnProperty.toString.call(Object);
  },

  isNumeric: function( obj ) {
    // From jQuery
    // parseFloat NaNs numeric-cast false positives (null|true|false|"")
    // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
    // subtraction forces infinities to NaN
    // adding 1 corrects loss of precision from parseFloat (#15100)
    var realStringObj = obj && obj.toString();
    return !Array.isArray( obj ) && ( realStringObj - parseFloat( realStringObj ) + 1 ) >= 0;
  },

  objectToXML: function(obj, depth) {
    var xml = [];
    for (var key in obj) {
      if (!obj.hasOwnProperty(key)) {
        continue;
      }
      var value = obj[key];
      if (Array.isArray(value)) {
        for (var ia=0; ia<value.length; ia++) {
          var tmp = {};
          tmp[key] = value[ia];
          xml.push(MC.objectToXML(tmp, depth));
        }
      } else if (value === null || value === undefined || value === '' || (MC.isPlainObject(value) && MC.isEmptyObject(value))) {
        for (var x = 0; x < depth; x++) {
          xml.push('  ');
        }
        xml.push('<' + key + '/>\n');
      } else {
        for (var x = 0; x < depth; x++) {
          xml.push('  ');
        }
        xml.push('<' + key + '>');
        if (typeof (value) == 'object') {
          xml.push('\n');
          xml.push(MC.objectToXML(value, depth + 1));
          for (var x = 0; x < depth; x++) {
            xml.push('  ');
          }
        } else {
          xml.push(MC.escapeXML(value));
        }
        xml.push('</' + key + '>\n')
      }
    }
    return xml.join('');
  },

  stripWhiteSpaceInXML: function(str) {
    str = str.replace(/>\s*/g, '>');
    str = str.replace(/\s*</g, '<');
    return str;
  },

  escapeXML: function(string) {
    return (string+'').replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&apos;");
  },

  getConfiguration: function(ri, flowServerUrl, debug) {
    return new Promise(function (resolve, reject) {
      var url = flowServerUrl + '?start=true&interactive=false&flowconfig=miniclient;v=2&operationname=API_AppConfigGet&includelog=true&inputdata=' + encodeURIComponent(JSON.stringify({configPath: ri}));
      if (debug) {
        url += '&includelog=true';
      }
      MC.callServer('GET', url, MC.getJsonType()).then(function (res) {
        var output = {};
        var content = res.content;
        if (content) {
          output = JSON.parse(content);
        }
        if (res.status == 200 || res.status == 204) {
          var conf = {};
          if (output.configData && Array.isArray(output.configData)) {
            for (var i=0; i < output.configData.length; i++) {
              if (output.configData[i].key && output.configData[i].value) {
                conf[output.configData[i].key] = output.configData[i].value;
              }
            }
          }
          if (output.environmentOperation) {
            conf['fl:environmentOperation'] = output.environmentOperation;
          }
          resolve(conf);
        } else {
          var message = '';
          if (output.errorName) {
            message += output.errorName + ': ';
          }
          message += 'Loading application configuration failed! Status:' + res.status;
          if (output.errorMessage) {
            message += ' ' + output.errorMessage;
          }
          MCHistory.log(MCHistory.T_ERROR, message, true);
          reject(null);
        }
      }).catch(function (err) {
        if (navigator.onLine) {
          self.endOperationException('SYS_IntegrationExc', 'Reading configuration failed for url ' + url + ': ' + err);
          return;
        } else {
          self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available for url ' + url + ': ' + err);
          return;
        }
      });
    });
  },

  getEnvironmentContext: function(request, configuration, serverFlowUrl, envOperName) {
    return new Promise(function (resolve) {
      if (!MC.isNull(envOperName) && envOperName !== '') {
        if (envOperName.indexOf('/') > -1) {
          envOperName = envOperName.substring(envOperName.lastIndexOf('/') + 1);
        }
        var inputRequest = {};
        if (!MC.isNull(request) && MC.isPlainObject(request)) {
          inputRequest.request = request;
        }
        var url = serverFlowUrl + '?start=true&interactive=false&flowconfig=' + encodeURIComponent(configuration) + '&operationname=' + envOperName
          + (MC.isNull(inputRequest) ? '' : '&inputdata=' + encodeURIComponent(JSON.stringify(inputRequest)));
        MC.callServer('GET', url, MC.getJsonType()).then(function (res) {
          if (res.status == 200) {
            var output = {};
            var content = res.content;
            if (content) {
              output = JSON.parse(content);
            }
            if (!MC.isNull(output)) {
              resolve(output);
            } else {
              MC.error('Failed calling environment operation ' + envOperName + '! user object is empty.');
            }
          } else {
            MC.error('Failed calling environment operation ' + envOperName + '! Response status: ' + res.status);
          }
        }).catch(function (err) {
          MC.error('Failed calling environment operation ' + envOperName + '! Error: ' + err);
        });
      } else {
        resolve(null);
      }
    });
  },

  translateNamespaces: function(object, nsmap) {
    if (Array.isArray(object)) {
      for (var i = 0; i < object.length; i++) {
        MC.translateNamespaces(object[i], nsmap);
      }
    } else if (MC.isPlainObject(object)) {
      for (var property in object) {
        if (MC.isPlainObject(object[property]) || Array.isArray(object[property])) {
          MC.translateNamespaces(object[property], nsmap);
        }
        for (var ns in nsmap) {
          if (property.startsWith(nsmap[ns].uri)) {
            var newName = property.replace(nsmap[ns].uri, nsmap[ns].prefix + ':');
            object[newName] = object[property];
            delete object[property];
          }
        }
      }
    }
  },

  prepareNamespaces: function(object, nsmap) {
    if (MC.isNull(nsmap) || !MC.isPlainObject(nsmap)) {
      return;
    }
    if (Array.isArray(object)) {
      for (var i = 0; i < object.length; i++) {
        MC.translateNamespaces(object[i], nsmap);
      }
    } else if (MC.isPlainObject(object)) {
      for (var property in object) {
        if (MC.isPlainObject(object[property]) || Array.isArray(object[property])) {
          MC.translateNamespaces(object[property], nsmap);
        }
        for (var ns in nsmap) {
          if (property.startsWith(nsmap[ns] + ':')) {
            var newName = property.replace(nsmap[ns] + ':', ns);
            object[newName] = object[property];
            delete object[property];
          }
        }
      }
    }
  },

  toUTF8Array: function(str) {
    var utf8 = [];
    for (var i=0; i < str.length; i++) {
      var charcode = str.charCodeAt(i);
      if (charcode < 0x80) utf8.push(charcode);
      else if (charcode < 0x800) {
        utf8.push(0xc0 | (charcode >> 6),
          0x80 | (charcode & 0x3f));
      }
      else if (charcode < 0xd800 || charcode >= 0xe000) {
        utf8.push(0xe0 | (charcode >> 12),
          0x80 | ((charcode>>6) & 0x3f),
          0x80 | (charcode & 0x3f));
      }
      else {
        // let's keep things simple and only handle chars up to U+FFFF...
        utf8.push(0xef, 0xbf, 0xbd); // U+FFFE "replacement character"
      }
    }
    return utf8;
  },

  fromUTF8Array: function(arr) {
    var str = '';
    for (var i = 0; i < arr.length; i++) {
      str += '%' + ('0' + arr[i].toString(16)).slice(-2);
    }
    return decodeURIComponent(str);
  },

  luxonToDateTimeString: function(l, type, putZone) {
    if (MC.isNull(type)) {
      type = MC.getDateTimeType(l)
    }
    const hasTimezone = MC.objectHasTimezone(l) || putZone
    const milliseconds = l.v.millisecond != 0 ? '.SSS' : ''
    const tmz = l.v.offset === 0 ? "'Z'" : 'ZZ' 
    switch (type) {
      case 'date': return hasTimezone ? l.v.toFormat("yyyy-MM-dd" + tmz) : l.v.toFormat("yyyy-MM-dd")
      case 'time': return hasTimezone ? l.v.toFormat("HH:mm:ss" + milliseconds + tmz) : l.v.toFormat("HH:mm:ss" + milliseconds)
      default: return hasTimezone ? l.v.toFormat("yyyy-MM-dd'T'HH:mm:ss" + milliseconds + tmz) : l.v.toFormat("yyyy-MM-dd'T'HH:mm:ss" + milliseconds)
    }
  },

  dateTimeStringToLuxon: function(s, options) {
    if (MC.isNull(s)) {
      return {v: DateTime.local(), _i: DateTime.local().toISO({includeOffset: false})}
    }
    if (s.match(/^\d{4}-\d\d-\d\d(([+-]\d\d:\d\d)|Z)$/i)) {
      return {v: DateTime.fromFormat(s, 'yyyy-MM-ddZZ', options), _i: s}
    } else {
      return {v: DateTime.fromISO(s, options), _i: s}
    }
  },

  getDateTimeType: function(l) {
    if (!l._i) {
      return 'dateTime'
    }
    if (l._i.match(/^\d{4}-\d\d-\d\d((([+-]\d\d:\d\d)|Z)?)?$/i)) {
      return 'date'
    } else if (l._i.match(/^\d\d:\d\d(:\d\d?(\.\d+(([+-]\d\d:\d\d)|Z)?)?)?/i)) {
      return 'time'
    } else {
      return 'dateTime'
    }
  },

  hasTimezone: function(s) {
    if (MC.isNull(s)) {
      return false;
    }
    if (s.match(/^(\d{4}(-\d\d(-\d\d)?)?)?((T|\$\$\$)?\d\d:\d\d(:\d\d)?(\.\d+)?)?(([+-]\d\d:\d\d)|Z)$/i)) {
      return true;
    } else {
      return false;
    }
  },

  objectHasTimezone: function(m) {
    if (m._i && typeof(m._i) === 'string') {
      return MC.hasTimezone(m._i);
    }
    return false;
  },

  luxonAdd: function (lux, duration) {
    if (!lux.v.isValid) {
      return
    }
    if (MC.isDurationObject(duration)) {
      lux.v = lux.v.plus({years: duration.getYears()})
      lux.v = lux.v.plus({months: duration.getMonths()})
      lux.v = lux.v.plus({days: duration.getDays()})
      lux.v = lux.v.plus({hours: duration.getHours()})
      lux.v = lux.v.plus({minutes: duration.getMinutes()})
      lux.v = lux.v.plus({seconds: duration.getSeconds()})
      lux.v = lux.v.plus({milliseconds: duration.getMilliseconds()})
    }
  },

  isDurationObject: function(dur) {
    if (typeof(dur) == 'object' && MC.isFunction(dur.isValidDuration)) {
      return true;
    }
    return false;
  },

  durationBetween: function(date1, date2) {
    let negative = 1
    if (date2.v < date1.v) {
      negative = -1
      const tmp = date1
      date1 = date2
      date2 = tmp
    }
    let milliseconds = Math.floor(date2.v.diff(date1.v).toObject().milliseconds)
    let seconds = Math.floor(milliseconds/1000)
    milliseconds = milliseconds - seconds * 1000
    let minutes = Math.floor(seconds/60)
    seconds = seconds - minutes * 60
    let hours = Math.floor(minutes/60)
    minutes = minutes - hours * 60
    let totalDays = Math.floor(hours/24)
    hours = hours - totalDays*24
    let months = Math.floor(date2.v.diff(date1.v, 'months').toObject().months)
    let days = date2.v.day - date1.v.day
    if (months == 0) {
      days = totalDays
    } else {
      if (months > 0 && days < 0) {
        months--
        date1.v.plus({days: months})
        days = Math.floor(date2.v.diff(DateTime.fromISO('1970-01-01'), 'days').toObject().days) - Math.floor(date1.v.diff(DateTime.fromISO('1970-01-01'), 'days').toObject().days)
      } else if (months < 0 && days > 0) {
        months++
        days -= date2.v.daysInMonth
      }
    }
    let years = Math.floor(months/12)
    months = months % 12
    var duration = new Duration()
    duration.from(years * negative, months * negative, days * negative, hours * negative, minutes * negative, seconds * negative, milliseconds * negative)
    return duration
  },

  normalizeValue: function(value, basicType) {
    if (Array.isArray(value)) {
      MC.error('Can not normalize collection!');
    } else if (MC.isPlainObject(value)) {
      if ('anyType' === basicType) {
        return value;
      } else {
        MC.error('Can not normalize object!');
      }
    }
    if (MC.isNull(value) || value === '') {
      return value;
    }
    if ('boolean' === basicType) {
      if (value === true || value === 'true') {
        return true;
      } else if (value === false || value === 'false') {
        return false;
      } else {
        MC.error('Value "' + value + '" cannot be converted to boolean!');
      }
    } else if (['integer', 'int', 'long', 'short', 'byte', 'decimal', 'double', 'float'].indexOf(basicType) > -1) {
      return MC.getNumberAsString(math.bignumber(value));
    } else if ('duration' === basicType) {
      var dur = new Duration();
      dur.parseIsoString(value);
      if (!dur.isValidDuration()) {
        MC.error('Value "' + value + '" cannot be converted to duration!');
      }
      return dur.toIsoString();
    } else {
      return value;
    }
  },

  getNumberAsString: function(mathObj) {
    if (math.isInteger(mathObj)) {
      return math.format(mathObj, {notation: 'fixed'})
    } else {
      return math.format(mathObj, {exponential: {lower:1e-100, upper:1e100}})
    }
  },

  isRowVisible: function(fields, resolution, iteration) {
    if (MC.isNull(fields) || fields.length == 0) {
      return false;
    }
    if(fields[0].flow.modelerReact && fields[0].flow.modelerReact.state.ghostMode) {
      return true;
    }
    if (MC.isNull(resolution)) {
      for (var i = 0; i < fields.length; i++) {
        // set visible true for embedded dialog wehen reloading after end
        if (fields[i].widget === 'embeddeddialog') {
          if (MC.getFieldParamBooleanValue(fields[i].param, '@reload', iteration)) {
            MC.putFieldParamValue(fields[i].param, '@visible', iteration, true);
          }
        }
        if (!MC.isNull(fields[i].param['@visible'])) {
          if (Array.isArray(fields[i].param['@visible'])) {
            if (MC.isNumeric(iteration)) {
              if (fields[i].param['@visible'][iteration] == true) {
                return true;
              }
            } else {
              if (fields[i].param['@visible'].indexOf(true) > -1) {
                return true;
              }
            }
          } else {
            if (fields[i].param['@visible'] == true) {
              return true;
            }
          }
        }
      }
      return false;
    } else {
      for (var i = 0; i < fields.length; i++) {
        var grid = MC.getFieldGrid(fields[i], resolution);
        if (grid.visible === 'true') {
          return true;
        }
      }
      return false;
    }
  },

  collDepth: function(coll) {
    if (!Array.isArray(coll)) {
      return 0;
    }
    var depth = 1;
    for (var i = 0; i < coll.length; i++) {
      var itemDepth = MC.collDepth(coll[i]) + 1;
      if (itemDepth > depth) {
        depth = itemDepth;
      }
    }
    return depth;
  },

  rebaseUrl: function(model, url) {
    if (MC.isNull(url) || typeof(url) != 'string' || url.startsWith('http')) {
      return url;
    } else {
      return rbBaseUri + model + '/' + url;
    }
  },

  getResolutionFromWidth: function(width) {
    if (width <= 576) {
      return 'x-small';
    } else if (width < 768) {
      return 'small';
    } else if (width < 992) {
      return 'medium';
    } else {
      return 'large';
    }
  },

  hasLayout: function(form, resolution) {
    return form.fields.some(function(field) {
      if (field.grid && Array.isArray(field.grid)) {
        for (var i = 0; i < field.grid.length; i++) {
          if (field.grid[i].resolution === resolution) {
            return true;
          }
        }
      }
    });
  },

  getAvailableResolution: function(resolution, form) {
    if (MC.hasLayout(form, resolution)) {
      return resolution;
    }
    if (resolution === 'large') {
      if (MC.hasLayout(form, 'medium')) {
        return 'medium';
      } else if (MC.hasLayout(form, 'small')) {
        return 'small';
      } else if (MC.hasLayout(form, 'x-small')) {
        return 'x-small';
      }
    } else if (resolution === 'medium') {
      if (MC.hasLayout(form, 'large')) {
        return 'large';
      } else if (MC.hasLayout(form, 'small')) {
        return 'small';
      } else if (MC.hasLayout(form, 'x-small')) {
        return 'x-small';
      }
    } else if (resolution === 'small') {
      if (MC.hasLayout(form, 'x-small')) {
        return 'x-small';
      } else if (MC.hasLayout(form, 'medium')) {
        return 'medium';
      } else if (MC.hasLayout(form, 'large')) {
        return 'large';
      }
    } else if (resolution === 'x-small') {
      if (MC.hasLayout(form, 'small')) {
        return 'small';
      } else if (MC.hasLayout(form, 'medium')) {
        return 'medium';
      } else if (MC.hasLayout(form, 'large')) {
        return  'large';
      }
    }
    return null;
  },

  commonAncestor: function(thisPath, relativePath) {
    if ('.' == relativePath) {
      return thisPath;
    }
    var steps = relativePath.split('/');
    var thisSteps = thisPath.split('/');
    var contextItemStep = thisSteps.shift();
    while (true) {
      var step = steps.shift();
      if (step == '..') {
        if (thisSteps.length > 0) {
          thisSteps.pop();
        }
        continue;
      }
      break;
    }
    thisSteps.unshift(contextItemStep);
    return thisSteps.join('/');
  },

  collectionDepth: function(path) {
    var depth = 0;
    var steps = path.split('/');
    for (var i = 0; i < steps.length; i++) {
      if (steps[i].endsWith('*')) {
        depth++;
      }
    }
    return depth;
  },
  isModelerActive: function(field) {
    return field.flow.isModelerActive || typeof field.flow.modelerReact != "undefined";
  },
  isModelerReactAvailable: function(field) {
    return typeof field.flow.modelerReact != "undefined";
  },
  isModelerInEyeMode: function(field) {
    return MC.isModelerReactAvailable(field) &&  field.flow.modelerReact.state.ghostMode;
  },
  isModelerInStructuralMode: function(field) {
    return MC.isModelerReactAvailable(field) &&  field.flow.modelerReact.state.structuralMode;
  },
  showAtLeastOneIteration: function(field) {
    return MC.isModelerReactAvailable(field) && (field.flow.modelerReact.state.ghostMode || field.flow.modelerReact.props.isInStandardGui)
  },
  getModelerReact: function(field) {
    return field.flow.modelerReact;
  },
  handleEvent: function(field, iteration, event, target) {
    if (MC.isFunction(field.flow.eventForm)) {
      field.flow.eventForm(field, event, iteration, target);
    }
  },
  handleReactEvent: function(field, iteration, e) {
    if (e && typeof(e.type)) {
      switch (e.type) {
        case 'focus': MC.handleEvent(field, iteration, 'focus'); break;
        case 'blur': MC.handleEvent(field, iteration, 'blur'); break;
        case 'click':
          var target = MC.closestHasAttr(e.target, 'data-widget-name').getAttribute('data-widget-name');
          MC.handleEvent(field, iteration, 'click', target);
          break;
        case 'mouseenter': MC.handleEvent(field, iteration, 'mouseenter'); break;
        case 'mouseleave': MC.handleEvent(field, iteration, 'mouseleave'); break;
        default: break;
      }
    }
  },
  validateFieldTree: function(field, repeaterRows, iterations) {
    return new Promise(function (resolve, reject) {
      if (Array.isArray(field.fields) && field.fields.length > 0 && field.widget !== 'radiogroup') {
        var promises = []
        if (field.id === 'rows*') {
          for (let i = 0; i < field.fields.length; i++) {
            if (Array.isArray(repeaterRows) && (!Array.isArray(iterations) || iterations.length < repeaterRows.length)) {
              let iToPass = Array.isArray(iterations) ? repeaterRows.slice(0, iterations.length+1) : [repeaterRows[0]]
              promises.push(MC.validateFieldTree(field.fields[i], repeaterRows, iToPass))
            } else {
              let count = MC.getRowsCount(field, iterations, 0)
              for (let c = 0; c < count; c++) {
                let iToPass = (iterations) ? [...iterations, c] : [c]
                promises.push(MC.validateFieldTree(field.fields[i], repeaterRows, iToPass))
              }
            }
          }
        } else {
          var tabActiveIndex = 0;
          if (field.widget === "tabpanel") {
            tabActiveIndex = MC.getFieldParamValue(field.param, "@activeIndex", iterations)
          }
          for (var i = 0; i < field.fields.length; i++) {
            if (field.widget === "tabpanel" && tabActiveIndex != i) {
              continue
            }
            promises.push(MC.validateFieldTree(field.fields[i], repeaterRows, iterations))
          }
        }
        if (promises.length > 0) {
          Promise.all(promises).then(function (results) {
            for (var i=0; i<results.length; i++) {
              if (!results[i]) {
                resolve(false)
              }
            }
            resolve(true)
          }).catch(function (exception) {
            reject(exception)
          });
        } else {
          resolve(true)
        }
      } else {
        MC.validateField(field, iterations, true).then(function (valid) {
          resolve(valid)
        }).catch(function (exception) {
          reject(exception)
        })
      }
    })
  },
  validateField: function(field, iteration, onsubmit) {
    if (field.widget === "label" || !MC.getFieldParamBooleanValue(field.param, "@enabled", iteration)) {
      return new Promise(function (resolve) {
        resolve(true);
      });
    }
    var valid = true;
    var valMsg = null;
    var value = MC.getFieldParamValue(field.param, "value", iteration);
    if (field.widget === "radiogroup") {
      for (var i=0; i<field.fields.length; i++) {
        if (field.fields[i].widget === "radiobutton" && MC.getFieldParamBooleanValue(field.fields[i].param, "value", iteration)) {
          value = true;
        }
      }
    }
    // required
    var required = MC.getFieldParamBooleanValue(field.param, "validation/@required", iteration);
    if (required && (MC.isNull(value) || value === "")) {
      valid = false;
      valMsg = MC.formatMessage("required");
    }
    if (required && field.widget == "checkbox" && value !== true) {
      valid = false;
      valMsg = MC.formatMessage("required");
    }
    // minlength
    if (valid && !MC.isNull(value) && value !== "") {
      var minlength = MC.getFieldParamValue(field.param, "validation/@minLength", iteration);
      if (MC.isNumeric(minlength) && value.length < Number(minlength)) {
        valid = false;
        valMsg = MC.formatMessage("minlength", minlength);
      }
    }
    // maxlength
    if (valid && !MC.isNull(value) && value !== "") {
      var maxlength = MC.getFieldParamValue(field.param, "validation/@maxLength", iteration);
      if (MC.isNumeric(maxlength) && value.length > Number(maxlength)) {
        valid = false;
        valMsg = MC.formatMessage("maxlength", maxlength);
      }
    }
    //numbers
    var checkMaxMin = false;
    if (['integer', 'int', 'long', 'short', 'byte'].indexOf(field.basictype) > -1) {
      checkMaxMin = true;
      if (!MC.isNull(value) && !/^-?\d+$/.test(value)) {
        valid = false;
        valMsg = MC.formatMessage("digits");
      }
    } else if (['decimal', 'double', 'float'].indexOf(field.basictype) > -1) {
      checkMaxMin = true;
      if (!MC.isNull(value) && !/^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value)) {
        valid = false;
        valMsg = MC.formatMessage("number");
      }
    }
    if (checkMaxMin) {
      var min = MC.getFieldParamValue(field.param, "validation/@minValue", iteration);
      if (MC.isNumeric(min) && valid && !MC.isNull(value) && value !== "") {
        if (Number(value) < Number(min)) {
          valid = false;
          valMsg = MC.formatMessage("min", min);
        }
      }
      var max = MC.getFieldParamValue(field.param, 'validation/@maxValue', iteration);
      if (MC.isNumeric(max) && valid && !MC.isNull(value) && value !== "") {
        if (Number(value) > Number(max)) {
          valid = false;
          valMsg = MC.formatMessage("max", max);
        }
      }
    }
    // pattern
    var pattern = MC.getFieldParamValue(field.param, "validation/@pattern", iteration);
    if (!MC.isNull(pattern) && valid && !MC.isNull(value) && value !== "") {
      pattern  = new RegExp( "^(?:" + pattern  + ")$" );
      if (!pattern.test(value)) {
        valid = false;
        valMsg = MC.formatMessage("pattern");
      }
    }
    if ((field.widget == "datebox" || ['date', 'time', 'dateTime'].indexOf(field.basictype) > -1) && valid && !MC.isNull(value) && value !== "") {
      let minVal = MC.getFieldParamValue(field.param, "validation/@minValue", iteration)
      let maxVal = MC.getFieldParamValue(field.param, 'validation/@maxValue', iteration)
      if (field.basictype == 'time') {
        if (!/^\d\d:\d\d(:\d\d?(\.\d+(([+-]\d\d:\d\d)|Z)?)?)?/i.test(value)) {
          valid = false
          valMsg = MC.formatMessage("time")
        }
        let val = MC.dateTimeStringToLuxon(value).v
        if (valid && minVal) {
          if (!/^\d\d:\d\d(:\d\d(([+-]\d\d:\d\d)|Z)?)?$/i.test(minVal)) {
            MC.error('Unsupported time minimum format.')
          } else {
            minVal = MC.dateTimeStringToLuxon(minVal).v
            if (minVal.isValid) {
              if (val < minVal) {
                valid = false;
                valMsg = MC.formatMessage("time")
              }
            }
          }
        }
        if (valid && maxVal) {
          if (!/^\d\d:\d\d(:\d\d(([+-]\d\d:\d\d)|Z)?)?$/i.test(maxVal)) {
            MC.error('Unsupported time maximum format.');
          } else {
            maxVal = MC.dateTimeStringToLuxon(maxVal).v
            if (maxVal.isValid) {
              if (val > maxVal) {
                valid = false;
                valMsg = MC.formatMessage("time")
              }
            }
          }  
        }
      } else if (field.basictype == 'dateTime') {
        let val = MC.dateTimeStringToLuxon(value).v
        if (!val.isValid) {
          valid = false
          valMsg = MC.formatMessage("datetime")
        }
        if (valid && minVal) {
          if (minVal == 'today') {
            minVal = DateTime.local()
          } else {
            minVal = MC.dateTimeStringToLuxon(minVal).v
          }
          if (minVal.isValid) {
            if (val < minVal) {
              valid = false
              valMsg = MC.formatMessage("datetime")
            }
          } else {
            MC.error('Unsupported date time minimum format.')
          }
        }
        if (valid && maxVal) {
          if (maxVal == 'today') {
            minVal = DateTime.local()
          } else {
            maxVal = MC.dateTimeStringToLuxon(maxVal).v
          }
          if (maxVal.isValid) {
            if (val > maxVal) {
              valid = false
              valMsg = MC.formatMessage("datetime")
            }
          } else {
            MC.error('Unsupported date time maximum format.')
          }
        }
      } else {
        if (!/^\d{4}-\d\d-\d\d(([+-]\d\d:\d\d)|Z)?$/i.test(value)) {
          valid = false
          valMsg = MC.formatMessage("date")
        }
        let val = MC.dateTimeStringToLuxon(value).v
        if (valid && minVal) {
          if (minVal == 'today') {
            minVal = DateTime.local().toFormat("yyyy-MM-dd")
          }
          if (!/^\d{4}-\d\d-\d\d(([+-]\d\d:\d\d)|Z)?$/i.test(minVal)) {
            MC.error('Unsupported date minimum format.')
          } else {
            minVal = MC.dateTimeStringToLuxon(minVal).v
            if (minVal.isValid) {
              if (val < minVal) {
                valid = false;
                valMsg = MC.formatMessage("date");
              }
            }
          }
        }
        if (valid && maxVal) {
          if (maxVal == 'today') {
            maxVal = DateTime.local().toFormat("yyyy-MM-dd")
          }
          if (!/^\d{4}-\d\d-\d\d(([+-]\d\d:\d\d)|Z)?$/i.test(maxVal)) {
            MC.error('Unsupported date maximum format.')
          } else {
            maxVal = MC.dateTimeStringToLuxon(maxVal).v
            if (maxVal.isValid) {
              if (val > maxVal) {
                valid = false;
                valMsg = MC.formatMessage("date");
              }
            }
          }
        }
      }
    }
    if (field.widget == "whisperbox") {
      var operationName = MC.getFieldParamValue(field.param, '@operationName', iteration);
      var forceValue = MC.getFieldParamValue(field.param, '@forceValue', iteration);
      if (forceValue && MC.isNullOrEmpty(operationName) && field.param['items*'] && field.param['items*']['@key']) {
        var keys = MC.asArray(field.param['items*']['@key']);
        keys = keys.map(k => {
          return MC.isNull(k) ? null : k.toString();
        });
        if (keys.indexOf(value) < 0) {
          valid = false;
          valMsg = MC.formatMessage("whisper");
        }
      }
    }
    return new Promise(function (resolve, reject) {
      MC.validateWhisperOperation(field, value, iteration, valid).then(function (whisperRes) {
        var valid = whisperRes;
        if (typeof valid === 'string') {
          valMsg = valid;
          valid = false;
        }
        var invalidState = MC.getFieldParamValue(field.param, '@invalidState', iteration);
        // put result into filed params
        MC.putFieldParamValue(field.param, "@invalid", iteration, !valid);
        if (invalidState != 'validChecked') {
          if (valid) {
            MC.putFieldParamValue(field.param, "@invalidState", iteration, 'valid');
          } else {
            MC.putFieldParamValue(field.param, "@invalidState", iteration, 'error');
          }
        }
        MC.putFieldParamValue(field.param, "@invalidmessage", iteration, valMsg);
        if (!valid && !field.flow.focusedOnFirst) {
          field.flow.focusedOnFirst = true;
          MC.putFieldParamValue(field.param, "@focused", iteration, true);
        }
        if (valid) { // remote validation run only if is valid
          var operName = MC.getFieldParamValue(field.param, 'validation/@operationName', iteration);
          if (MC.isNull(operName) || onsubmit && invalidState == 'validChecked') {
            resolve(true);
          } else {
            MC.validateByOperation(field.flow, operName, field.id, MC.getFieldParamValue(field.param, "value", iteration)).then(function (output) {
              resolve(MC.updateFieldFByValidation(field, output, iteration));
            }).catch(function (ex) {
              reject(ex);
            });
          }
        } else {
          resolve(false);
        }
      }).catch(function (ex) {
        reject(ex);
      });
    });
  },
  validateWhisperOperation: function(field, value, iteration, valid) {
    return new Promise(function (resolve, reject) {
      if (valid && field.widget == "whisperbox") {
        var forceValue = MC.getFieldParamValue(field.param, '@forceValue', iteration);
        var operName = MC.getFieldParamValue(field.param, '@operationName', iteration);
        if (forceValue && !MC.isNullOrEmpty(operName)) {
          var text = MC.getFieldParamValue(field.param, 'text', iteration);
          MC.getWhisperItems(field, operName, text).then(function (matchedData) {
            var found = false;
            for (var i=0; i<matchedData.length; i++) {
              if (value == matchedData[i].key) {
                found = true;
                break;
              }
            }
            if (!found) {
              resolve(MC.formatMessage("whisper"));
            } else {
              resolve(valid);
            }
          }).catch(function (ex) {
            reject(ex);
          });
        } else {
          resolve(valid);
        }
      } else {
        resolve(valid);
      }
    });
  },
  getWhisperItems: function(field, operName, value) {
    return new Promise(function (resolve, reject) {
      var flow = field.flow;
      var input = {};
      input.value = value;
      input.widgetId = field.id;
      input.lang = flow.lang;
      var url = flow.flowServerUrl + '?start=true&interactive=false&flowconfig=' + flow.confPath + '&operationname=' + operName + '&inputdata=' + encodeURIComponent(JSON.stringify(input));
      if (flow.debug) {
        url += '&includeid=true';
      }
      MC.callServer('GET', url, MC.getJsonType()).then(function (res) {
        var output = {};
        var content = res.content;
        var log = null;
        if (content) {
          output = JSON.parse(content);
          if (!MC.isNull(output.flowId) && !MC.isNull(output.flowLogId)) {
            log = {flowId: output.flowId, flowLogId: output.flowLogId};
            delete output.flowId;
            delete output.flowLogId;
          }
        }
        if (res.status == 200 || res.status == 204) {
          MCHistory.history(flow, flow.context.action, 'WHISPERBOX', {'Input': input, 'Output': output, 'Server log': log});
          if (output.items) {
            let res = MC.asArray(output.items);
            for (var i=0; i < res.length; i++) {
              if (MC.isNull(res[i].title)) {
                res[i].title = res[i].key;
              }
              if (MC.isNull(res[i].key)) {
                res[i].key = res[i].title;
              }
              if (MC.isNull(res[i].title) && MC.isNull(res[i].key)) {
                reject({type: 'SYS_IntegrationExc', message: 'Key ot title must be defined for whisperbox title!', input: input, output: output, log: log});
              }
              res[i].key = res[i].key.toString();
              res[i].title = res[i].title.toString();
            }
            resolve(MC.asArray(output.items));
          } else {
            resolve([]);
          }
        } else {
          var type = 'SYS_IntegrationExc';
          if (output.errorName) {
            type = output.errorName;
          }
          var message = 'Calling server flow failed for url ' + url  + '! Status:' + res.status;
          if (output.errorMessage) {
            message = output.errorMessage;
          }
          reject({type: type, message: message, input: input, output: output, log: log});
        }
      }).catch(function (err) {
        var type = 'SYS_IntegrationExc';
        var message = 'Calling server flow failed for url ' + url + ': ' + err;
        if (!navigator.onLine) {
          type = 'SYS_SystemUnavailableExc';
          message = 'Internet connection is not available for url ' + url + ': ' + err;
        }
        reject({type: type, message: message, input: input});
      });
    });
  },
  findAncestor: function (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls)) ;
    return el;
  },
  isVisible: function(elem) {
    return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
  },
  getElemCoords: function(elem) {
    var box = elem.getBoundingClientRect();
    var body = document.body;
    var docEl = document.documentElement;
    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;
    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;
    return { top: Math.round(top), left: Math.round(left) };
  },
  validateByOperation: function(flow, operName, fieldName, value) {
    return new Promise(function (resolve, reject) {
      var input = {};
      input.value = value;
      input.widgetId = fieldName;
      var url = flow.flowServerUrl + '?start=true&interactive=false&flowconfig=' + flow.confPath + '&operationname=' + operName + '&inputdata=' + encodeURIComponent(JSON.stringify(input));
      if (flow.debug) {
        url += '&includeid=true';
      }
      MC.callServer('GET', url, MC.getJsonType()).then(function (res) {
        var output = {};
        var content = res.content;
        var log = null;
        if (content) {
          output = JSON.parse(content);
          if (!MC.isNull(output.flowId) && !MC.isNull(output.flowLogId)) {
            log = {flowId: output.flowId, flowLogId: output.flowLogId};
            delete output.flowId;
            delete output.flowLogId;
          }
        }
        if (res.status == 200 || res.status == 204) {
          MCHistory.history(flow, flow.context.action, 'SERVER VALIDATION', {'Input': input, 'Output': output, 'Server log': log});
          resolve(output);
        } else {
          var exception = {};
          exception.type = 'SYS_IntegrationExc';
          if (output.errorName) {
            exception.type = output.errorName;
          }
          exception.message = 'Calling server flow failed for url ' + url  + '! Status:' + res.status;
          if (output.errorMessage) {
            exception.message = output.errorMessage;
          }
          exception.input = input;
          exception.output = output;
          exception.log = log;
          reject(exception);
        }
      }).catch(function (err) {
        var type = 'SYS_IntegrationExc';
        var message = 'Calling server flow failed for url ' + url + ': ' + err;
        if (!navigator.onLine) {
          type = 'SYS_SystemUnavailableExc';
          message = 'Internet connection is not available for url ' + url + ': ' + err;
        }
        reject({type: type, message: message, input: input});
      });
    });
  },
  updateFieldFByValidation: function(field, output, iteration) {
    var invalid = true;
    var invalidState = 'error';
    var mess = 'Calling remote validation error!';
    if (!MC.isNull(output.resultCode)) {
      switch (output.resultCode) {
        case 'OK': invalid = false; invalidState = 'valid'; break;
        case 'WARN': invalidState = 'warning'; break;
        case 'ERROR': break;
        default: MCHistory.log(MCHistory.T_WARNING, 'Unsupported resultCode in output of remote validating operation!', true);
      }
      if (!MC.isNull(output.resultMessage)) {
        mess = output.resultMessage;
      }
    } else {
      MCHistory.log(MCHistory.T_WARNING, 'Remote validating operation has no resultCode in output!', true);
    }
    MC.putFieldParamValue(field.param, "@invalid", iteration, invalid);
    MC.putFieldParamValue(field.param, "@invalidState", iteration, invalidState);
    MC.putFieldParamValue(field.param, "@invalidmessage", iteration, mess);
    return !invalid;
  },
  extend: function() {
    let options, name, src, copy, copyIsArray, clone;
		let target = arguments[ 0 ] || {};
		let i = 1;
		let length = arguments.length;
		let deep = false;
    // Handle a deep copy situation
    if (typeof target === "boolean") {
      deep = target;
      // Skip the boolean and the target
      target = arguments[ i ] || {};
      i++;
    }
    // Handle case when target is a string or something (possible in deep copy)
    if (typeof target !== "object" && !MC.isFunction(target)) {
      target = {};
    }
    for (; i < length; i++) {
      // Only deal with non-null/undefined values
      if ((options = arguments[i]) != null) {
        // Extend the base object
        for (name in options) {
          src = target[name];
          copy = options[name];
          // Prevent never-ending loop
          if (target === copy) {
            continue;
          }
          // Recurse if we're merging plain objects or arrays
          if (deep && copy && (MC.isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))) {
            if (copyIsArray) {
              copyIsArray = false;
              clone = src && Array.isArray(src) ? src : [];
            } else {
              clone = src && MC.isPlainObject(src) ? src : {};
            }
            // Never move original objects, clone them
            target[name] = MC.extend(deep, clone, copy);
          } else {
            target[name] = copy;
          }
        }
      }
    }
    return target;
  },
  closestHasAttr: function(el, atrName) {
    while (!el.hasAttribute(atrName)) {
        el = el.parentNode;
        if (!el) {
            return null;
        }
    }
    return el;
  },
  callServer: function(method, ri, accept, content, contentType) {
    return new Promise(function (resolve, reject) {
      try {
        let xhr = new XMLHttpRequest()
        xhr.open(method, rbBaseUri + ri) 
        xhr.setRequestHeader('Accept', accept)
        xhr.onload = function () {
          let res = {}
          res.status = xhr.status
          res.content = xhr.responseText
          res.contentType = xhr.getResponseHeader('Content-Type')
          if (res.contentType) {
            res.contentType = res.contentType.split(';')[0]
          }
          resolve(res)
        }
        xhr.onerror = function (err) {
          reject(err)
        }
        if (!MC.isNull(content) && !MC.isNull(contentType)) {
          xhr.setRequestHeader('Content-Type', contentType)
          xhr.send(content)
        } else {
          xhr.send()
        }
      } catch (err) {
        reject(err)
      }
    })
  },
  URLUtils: function(url, baseURL) {
    var m = String(url).replace(/^\s+|\s+$/g, "").match(/^([^:\/?#]+:)?(?:\/\/(?:([^:@\/?#]*)(?::([^:@\/?#]*))?@)?(([^:\/?#]*)(?::(\d*))?))?([^?#]*)(\?[^#]*)?(#[\s\S]*)?/)
    if (!m) { throw new RangeError() }
    var pathname = m[7] || ""
    var search = m[8] || ""
    var hash = m[9] || ""
    if (baseURL !== undefined) {
      var base = new MC.URLUtils(baseURL)
      if (pathname === "" && search === "") {
        search = base.search
      }
      if (pathname.charAt(0) !== "/") {
        pathname = (pathname !== "" ? ((base.pathname === "" ? "/" : "") + base.pathname.slice(0, base.pathname.lastIndexOf("/") + 1) + pathname) : base.pathname);
      }
      var output = [];
      pathname.replace(/^(\.\.?(\/|$))+/, "").replace(/\/(\.(\/|$))+/g, "/").replace(/\/\.\.$/, "/../").replace(/\/?[^\/]*/g, (p) => {
          if (p === "/..") {
            output.pop()
          } else {
            output.push(p)
          }
      })
      pathname = output.join("").replace(/^\//, pathname.charAt(0) === "/" ? "/" : "")
    }
    this.href = pathname + search + hash
    this.pathname = pathname
    this.search = search
  },
  putValueIntoMultiArray: function(arr, indexes, totalSize, value) {
    if (MC.isNull(value) && totalSize == 1 && (!Array.isArray(indexes) || indexes.length <= 1)) {
      return null
    }
    if (!Array.isArray(indexes) || indexes.length < 1) {
      return value
    }
    if (MC.isNull(arr) || !Array.isArray(arr)) {
      arr = []
    }
    let levelArr = arr
    for (let i=0; i<indexes.length; i++) {
      let index = indexes[i]
      if (i < indexes.length-1) { // path
        if (MC.isNull(value) && totalSize == 1 && i == indexes.length-2) {
          levelArr[index] = null
          return arr
        }
        if (!Array.isArray(levelArr[index])) {
          levelArr[index] = []
        }
        levelArr = levelArr[index]
      } else { // list
        if (index == 0) {
          levelArr.length = 0
        }
        levelArr[index] = value
      }
    }
    return arr
  }

};

if (!window.MC) {
  window.MC = MC
}


export {MC};