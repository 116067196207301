import {Library} from '../../../app/src/library.js';
import {ensureArray, removeDuplicates, concatArrays} from '../../../app/src/lib.js'
import {isInstanceOf} from '../../../app/src/meta.js'
import {resolveReference} from '../../../app/src/properties.js'
import joint from './rappid/rappid.js';

const MUtils = {}

const loadConceptFileVisual = conceptFile => {
  if (!MUtils._cache.conceptsLoaded[conceptFile['meta3:path']]) {
    const concept = conceptFile['meta3:properties']
    if (concept['mm:elementVisual']) {
     ensureArray(concept['mm:elementVisual']).forEach(elementVisual => {
       const visual = Library.toSimpleJSON(elementVisual)
       MUtils.loadVisual(visual, undefined)      
     })
    }
    const prototypeProps = concept['meta3:prototype']
    if (prototypeProps) {
      const elementVisuals = ensureArray(prototypeProps['mm:elementVisual'])
      elementVisuals.forEach(elementVisual => {
        const visual = Library.toSimpleJSON(elementVisual)
        MUtils.loadVisual(visual, undefined)
      })
    }
  } else {
    MUtils._cache.conceptsLoaded[conceptFile['meta3:path']] = true
  }
}

MUtils.getValueById = (values, id) => {
  if (values) {
    return values.find(value => value['rbs:id'] === id)
  }
} 

MUtils.linkValueRelationship = (linkValue, object) => {
  if (linkValue['meta3:target']) {
    return linkValue
  }
  return ensureArray(object[linkValue['mm:property']]).find(relationship => relationship['rbs:id'] === linkValue['mm:id'])
}

MUtils.getFile = (files, path) => {
  return files.find(file => file['meta3:path'] === path)
}

MUtils.elementValueObject = (viewPath, elementValue, objectsFiles) => {
  if (elementValue['mm:object']) {
    const objectFile = MUtils.elementValueObjectFile(viewPath, elementValue, objectsFiles)
    return objectFile['meta3:properties']
  } else {
    return elementValue
  }
}

MUtils.elementValueObjectFile = (viewPath, elementValue, objectsFiles) => {
  const objectReference = elementValue['mm:object']
  if (objectReference) {
    const objectPath = resolveReference(viewPath, objectReference)
    return objectsFiles.find(objectFile => objectFile['meta3:path'] === objectPath)
  }
}

MUtils.viewConceptsFiles = (storage, viewPath, view) => {
  const conceptsFolderReference = view['mm:conceptsFolder']
  const conceptsFolderPath = resolveReference(viewPath, conceptsFolderReference)
  return storage.list(conceptsFolderPath)
}

MUtils.loadConceptsFilesVisuals = conceptsFiles => {
  conceptsFiles.forEach(conceptFile => {
    loadConceptFileVisual(conceptFile)
  })
}

MUtils._cache = {
  propertiesVisualLoaded: false,
  conceptsLoaded: {}
};

MUtils.loadPropertiesVisauls = () => {
  if (MUtils._cache['propertiesVisualLoaded']) {
    return;
  }
  Object.keys(Library.properties).forEach(property => {
    const properties = Library.propertyProperties(property);
    const visual = properties['mm:elementVisual'];
    if (visual) {
      MUtils.loadVisual(Library.toSimpleJSON(visual), property);
    }
  })
  MUtils._cache['propertiesVisualLoaded'] = true;
}

MUtils.loadVisual = (visual, name) => {
  const constr = MUtils.typeToConstructor(visual.prototype);
  const attributes = {defaults: joint.util.deepSupplement(visual.defaults, constr.prototype.defaults)};
  if (visual.markup) {
    attributes.markup = visual.markup;
  }
  attributes.definition = visual;
  attributes.name = name;
  const type = visual.defaults.type;
  const newConstr = constr.extend(attributes);
  MUtils.setType(type, newConstr);
  return newConstr;
}

MUtils.elementObjectConstructor = (elementObject, elementValue) => {
  const type = MUtils.modelType(elementObject, elementValue);
  let elementConstructor = MUtils.typeToConstructor(type);
  if (!elementConstructor) {
    const elementVisual = elementObject['mm:elementVisual'];
    if (elementVisual) {
      const visual = Library.toSimpleJSON(elementVisual);
      elementConstructor = MUtils.loadVisual(visual, undefined);
    }
  }
  return elementConstructor;
}

MUtils.elementConstructor = (viewPath, elementValue, objectsFiles) => {
  const type = elementValue['mm:type']
  if (typeof type !== 'undefined') {
    return MUtils.typeToConstructor(type)
  } else {
    const elementObject = MUtils.elementValueObject(viewPath, elementValue, objectsFiles)
    return MUtils.elementObjectConstructor(elementObject, elementValue);
  }
}

MUtils.typeToConstructor = type => {
  const typeSplit = type.split(".");
  const modelName = typeSplit[0];
  const cellName = typeSplit[1];
  if (joint.shapes[modelName]) {
    return joint.shapes[modelName][cellName];
  }
}

MUtils.setType = (type, constr) => {
  const typeParts = type.split(".");
  const modelName = typeParts[0]
  const cellName = typeParts[1]
  if (typeof joint.shapes[modelName] == "undefined") {
    joint.shapes[modelName] = {};
  }
  joint.shapes[modelName][cellName] = constr;
}

MUtils.visualConstructor = visual => {
  return MUtils.typeToConstructor(visual.defaults.type);
}

MUtils.conceptVisual = concept => {
  const properties = concept['meta3:properties'];
  if (properties['mm:elementVisual']) {
    return Library.toSimpleJSON(properties['mm:elementVisual'])
  } else {
    const prototypeProps = properties['meta3:prototype'];
    return Library.toSimpleJSON(prototypeProps['mm:elementVisual']);
  }
}

MUtils.propertyVisual = property => {
  const elementVisual = Library.propertyProperties(property)['mm:elementVisual'];
  return Library.toSimpleJSON(elementVisual);
}

MUtils.propertyVisualConstructor = property => {
   const visual = MUtils.propertyVisual(property);
   const constr = MUtils.typeToConstructor(visual.defaults.type);
   return constr;
}

MUtils.isCellElement = (basePath, cellValue) => {
  const conceptReference = cellValue['meta3:instanceOf'];
  const cellConceptPath = resolveReference(basePath, conceptReference);
  return cellConceptPath === 'metamodeler/concepts/element';
}

MUtils.modelType = (elementObject, elementValue) => {
  const visuals = ensureArray(elementObject['mm:elementVisual'])
  const typeVisual = elementValue['mm:typeVisual'] || 'defaultElementVisual'
  let visual = visuals.find(visual => visual['rbs:id'] === typeVisual)
  if (!visual) {
    visual = visuals[0]
  }
  return visual['mm:defaults']['mm:type'];
}

MUtils.linkValueType = (linkValue) => {
  const property = linkValue['mm:property'];
  const visual = Library.propertyProperty(property, 'mm:elementVisual');
  return visual['mm:defaults']['mm:type'];
}

MUtils.propertyType = (property) => {
  const visual = Library.propertyProperty(property, 'mm:elementVisual');
  return visual['mm:defaults']['mm:type'];
}

MUtils.modelPossibleProperties = (model) => {
  const possibilities = ensureArray(model['meta3:possibility']).filter(possibility => possibility['meta3:modeled'] === 'true')
  return removeDuplicates(possibilities.map(possibility => possibility['meta3:property']))
}

MUtils.objectEmbedablePossibilities = object => {
  return MUtils.objectModeledPossibilities(object).filter(possibility => {
    const propertyProperties = Library.propertyProperties(possibility['meta3:property'])
    return propertyProperties['mm:allowEmbedding'] === 'true'
  })
}

MUtils.objectsEmbedablePossibilities = (conceptsFiles, viewPath, sourceObject, targetObject) => {
  const possibilities = MUtils.objectEmbedablePossibilities(sourceObject)
  return possibilities.filter(possibility => {
    const reference = possibility['meta3:targetConcept']
    const possibleTargetPath = resolveReference(viewPath, reference)
    return isInstanceOf(conceptsFiles, viewPath, targetObject, possibleTargetPath)
  })
}

MUtils.objectModeledPossibilities = object => {
  return ensureArray(object['meta3:possibility']).filter(possibility => possibility['meta3:modeled'] === 'true')
}


MUtils.relationProperty = relationship => {
  const keys = Object.keys(relationship);
  const filtetedKeys = keys.filter(property => {
    return property !== 'meta3:instanceOf' && property !== 'rbs:id' && property != 'rbs:fullId';
  })
  if (filtetedKeys.length !== 1) {
    throw new Error('Could not get property of relationship.')
  }
  return filtetedKeys[0];
}

MUtils.propertiesWithHalo = (model) => {
  const possibleProperties = MUtils.modelPossibleProperties(model);
  return possibleProperties.filter(property => {
    var ownHandler = Library.boolean(Library.propertyProperties(property)['mm:ownHaloHandler']);
    if (!ownHandler) {
      return false;
    }
    var allowMultiplicity = Library.boolean(Library.propertyProperties(property)['mm:allowMultiplicity']);
    if (allowMultiplicity) {
      return true;
    }
    if (model[property]) {
      return false;
    }
    return true;
  })
}

MUtils.objectGetModeledPossibilities = object => {
  return ensureArray(object['meta3:possibility']).filter(possibility => possibility['meta3:modeled'] === 'true')
}

MUtils.elementValueGetRelationshipsNotInView = (viewPath, elementValue, objectsFiles) => {
  const object = MUtils.elementValueObject(viewPath, elementValue, objectsFiles)
  const modeledPossibilities = MUtils.objectGetModeledPossibilities(object)
  const links = ensureArray(elementValue['mm:link'])
  const possibleProperties = removeDuplicates(modeledPossibilities.map(possibility => possibility['meta3:property']))
  return concatArrays(possibleProperties.map(property => {
    const values = ensureArray(object[property])
    const propertyLinks = links.filter(link => link['mm:property'] === property)
    const valuesWithoutLinks = values.filter(value => !propertyLinks.some(link => link['mm:id'] === value['rbs:id']))
    return valuesWithoutLinks.map(value => {
      return {value, property}
    }) 
  }))
}

MUtils.getViewElements = view => {
  return ensureArray(view[view['mm:elementsProperty']])
}

MUtils.getElementObjectPath = (viewPath, element) => {
  return resolveReference(viewPath, element['mm:object'])
}

MUtils.getObjectsFilesNotInView = async (storage, modelPath, viewPath, view) => {
  const objectFolderPath = modelPath + view['mm:objectFolderPath']
  const objectsFiles = await storage.list(objectFolderPath)
  const elements = MUtils.getViewElements(view)
  const elementsObjectsPaths = elements.map(element => MUtils.getElementObjectPath(viewPath, element))
  const objectsFilesNotInView = objectsFiles.filter(file => !elementsObjectsPaths.includes(file['meta3:path']))
  return objectsFilesNotInView
}

MUtils.isLinkPossible = async (conceptsFiles, basePath, property, sourceObject, targetObject) => {
  const possibilities = ensureArray(sourceObject['meta3:possibility'])
  return possibilities.some(possibility => {
    if (possibility['meta3:property'] !== property) {
      return false
    }
    const reference = possibility['meta3:targetConcept']
    const possibleTargetPath = resolveReference(basePath, reference)
    return isInstanceOf(conceptsFiles, basePath, targetObject, possibleTargetPath)
  })
}

MUtils.viewObjectsFiles = async (storage, basePath, view) => {
  const elements = ensureArray(view['mm:element'])
  const objectsPaths = elements.map(element => element['mm:object'])
                               .filter(reference => reference)
                               .map(reference => resolveReference(basePath, reference))
  return await Promise.all(objectsPaths.map(objectPath => storage.getFile(objectPath))) 
}

export {MUtils};
