import React from "react";
import ReactDOM from "react-dom";

import {MC} from './MC.js';

class WhisperBox extends React.Component {

  state = {matchedData: [], titleValue: '', showDropdown: false, data: [], activeIndex: null};

  componentWillMount() {
    this.updateData(this.props);
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  componentWillReceiveProps(nextProps) {
    this.updateData(nextProps);
  }

  updateData(props) {
    var self = this;
    var field = props.data;
    var operName = MC.getFieldParamValue(props.data.param, '@operationName', props.iteration);
    var value = MC.getFieldParamValue(props.data.param, 'value', props.iteration);
    var titleValue = MC.getFieldParamValue(props.data.param, 'text', props.iteration);
    if (!MC.isNull(operName)) {
      if (!MC.isNull(value) && MC.isNull(titleValue)) {
        field.flow.endOperationException('SYS_InvalidModelExc', 'When whisperbox has operationName, only text (not value) can be mapped into widget input!', props.data.param, null, null);
      } else if (MC.isNull(value) && !MC.isNull(titleValue)) {
        MC.getWhisperItems(props.data, operName, titleValue).then(function (matchedData) {
          for (var i = 0; i < matchedData.length; i++) {
            if (titleValue == matchedData[i].title.toString()) {
              self.handleSetValue(matchedData[i].key.toString(), titleValue);
              break;
            }
          }
        }).catch(function (ex) {
          field.flow.endOperationException(ex.type, ex.message, ex.input, ex.output, ex.log);
        });
      }
    } else {
      var data = [];
      if (field.param['items*'] && (field.param['items*']['@key'] || field.param['items*']['@title'])) {
        var keys = field.param['items*']['@key'] ? MC.asArray(field.param['items*']['@key']) : MC.asArray(field.param['items*']['@title']);
        var titles = field.param['items*']['@title'] ? MC.asArray(field.param['items*']['@title']) : MC.asArray(field.param['items*']['@key']);
        for (var i = 0; i < keys.length; i++) {
          if (!MC.isNull(keys[i])) {
            data.push({key: keys[i].toString(), title: MC.isNull(titles[i]) ? keys[i].toString() : titles[i].toString()});
            if (!MC.isNull(value) && MC.isNull(titleValue)) {
              if (value == keys[i].toString()) {
                this.handleSetValue(value, titles[i].toString());
              }
            } else if (!MC.isNull(titleValue) && MC.isNull(value)) {
              if (titleValue == titles[i].toString()) {
                this.handleSetValue(keys[i].toString(), titleValue);
              }
            }
          }
        }
      }
      if (MC.isNull(titleValue)) {
        titleValue = "";
      }
      this.setState({data: data, activeIndex: null, titleValue: titleValue});
    }
  }

  handleChange = (e) => {
    var self = this;
    this.props.widget.handleTextChange(e.target.value, e.target.value);
    var operName = MC.getFieldParamValue(this.props.data.param, '@operationName', this.props.iteration);
    if (!MC.isNull(operName)) {
      this.setState({titleValue: e.target.value, showDropdown: false});
      MC.getWhisperItems(this.props.data, operName, e.target.value).then(function (matchedData) {
        self.setState({matchedData: matchedData, showDropdown: true, activeIndex: null});
      }).catch(function (ex) {
        field.flow.endOperationException(ex.type, ex.message, ex.input, ex.output, ex.log);
      });
    } else {
      if (e.target.value.trim()) {
        var matchedData = this.searchQuery(e.target.value.trim(), this.state.data);
        this.setState({matchedData: matchedData, titleValue: e.target.value, showDropdown: true, activeIndex: null});
      } else {
        this.setState({titleValue: e.target.value, showDropdown: false});
      }
    }
  };

  searchQuery(value, array) {
    var whisperType =  MC.getFieldParamValue(this.props.data.param, '@whisperType', this.props.iteration);
    var searchTitle = true;
    if (whisperType == 'value') {
      searchTitle = false;
    }
    var searchKey = false;
    if (whisperType == 'value' || whisperType == 'both') {
      searchKey = true;
    }
    var result = [];
    for (var i=0; i<array.length; i++) {
      if (searchKey && array[i]['key'].indexOf(value) !== -1) {
        result.push(array[i]);
      }
      if (searchTitle && array[i]['title'].indexOf(value) !== -1) {
        result.push(array[i]);
      }
    }
    return result;
  }

  handleSetValue(value, titleValue) {
    this.setState({titleValue: titleValue, showDropdown: false}, function() {
      this.props.widget.handleTextChange(value, titleValue);
      this.props.widget.blur();
    });
  }

  activateItem(i) {
    this.setState({activeIndex: i});
  }

  handleKeyUp = (e) => {
    if (!this.state.matchedData || !Array.isArray(this.state.matchedData)) {
      return;
    }
    if (e.key == 'Enter') {
      if (Number.isInteger(this.state.activeIndex) && (this.state.matchedData && Array.isArray(this.state.matchedData))) {
        this.handleSetValue(this.state.matchedData[this.state.activeIndex]['key'], this.state.matchedData[this.state.activeIndex]['title']);
      }
    } else if (e.key == 'Escape') {
      this.setState({activeIndex: null, showDropdown: false});
    } else if (e.key == 'ArrowUp') {
      if (Number.isInteger(this.state.activeIndex) && this.state.activeIndex > 0) {
        this.setState({activeIndex: this.state.activeIndex - 1});
      } else {
        this.setState({activeIndex: this.state.matchedData.length -1});
      }
    } else if (e.key == 'ArrowDown') {
      if (Number.isInteger(this.state.activeIndex) && this.state.activeIndex + 1 < this.state.matchedData.length) {
        this.setState({activeIndex: this.state.activeIndex + 1});
      } else {
        this.setState({activeIndex: 0});
      }
    }
  };

  handleOutsideClick = (e) => {
    if (ReactDOM.findDOMNode(this).contains(e.target)) {
      return;
    }
    this.setState({activeIndex: null, showDropdown: false});
  };

  render() {
    var inputCls = "input " + (this.state.showDropdown ? "input-show-dropdown" : "");
    var dropDown = null;
    if (this.state.showDropdown) {
      var list = null;
      if (this.state.matchedData && Array.isArray(this.state.matchedData)) {
        var mdata = this.state.matchedData;
        var items = [];
        for (var i=0; i<mdata.length; i++) {
          var cls = 'dropdown-ul-li' + (this.state.activeIndex === i ? ' active' : '');
          items.push(<li key={i} className={cls} onClick={this.handleSetValue.bind(this, mdata[i]['key'], mdata[i]['title'])} onMouseEnter={this.activateItem.bind(this, i, true)}>{mdata[i]['title']}</li>);
        }
        list = <ul className="dropdown-ul">{items}</ul>;
      }
      dropDown = <div className="dropdown">{list}</div>;
    }
    return (
      <div className="whisperbox" onKeyUp={this.handleKeyUp}>
        <div className="search-input">
          <input type="text" className={inputCls} placeholder={this.props.placeholder} onChange={this.handleChange} value={this.state.titleValue} onFocus={this.props.widget.focus} onBlur={this.props.widget.blur}
                 readOnly={this.props.readOnly} disabled={this.props.disabled} data-widget-i-name={this.props.data.id}/>
        </div>
        <div className="search-dropdown">{dropDown}</div>
      </div>
    );
  }

}

export {WhisperBox};
