import {FieldProto} from "./FieldProto.js";

let FieldDef = {
  setProto: function (field) {
    var self = this;
    field.__proto__ = FieldProto;
    field.fields.forEach(f => self.setProto(f));
  },
  endRowByNewLineAfter: function (row) {
    var self = this;
    if (row.length > 0) {
      row.map(field => field.removeNewLineAfter());
      row.map(field => field.removeNewLineBefore());
      row[row.length - 1].putNewLineAfter();
    }
  },
  getRowTopOffset: function (row) {
    var topOffsets = row.map(function(field) {
      var $el = field.get$DOM();
      if (field.isVisible()) {
        return $el.offset().top
      } else {
        return Infinity;
      }});
    return Math.min.apply(null, topOffsets);
  },
  getRowHeight: function (row) {
    var $elements = row.map(field => field.get$DOM());
    //var $existingElements = $elements.filter($el => $el != undefined);
    var heights = $elements.map($el => $el.outerHeight(true));
    return Math.max.apply(null,heights);
  },
  computeRowsOffsets: function (rows) {
    var self = this;
    var rowsOffsets = rows.map(row => self.getRowTopOffset(row));
    if (rows.length != 0) {
      var lastOffset = rowsOffsets[rowsOffsets.length - 1];
      var lastHeight = this.getRowHeight(rows[rows.length - 1]);
      rowsOffsets.push(lastOffset + lastHeight + 6);
    }
    return rowsOffsets;
  },
  computeColumnsOffsets: function (row) {
    var self = this;
    var columnsOffsets = row.map(field => field.getLeftOffset());
    if (row.length != 0) {
      var lastWidth = row[row.length - 1].get$DOM().outerWidth();
      var lastOffset = columnsOffsets[columnsOffsets.length - 1];
      columnsOffsets.push(lastOffset + lastWidth);
    }
    return columnsOffsets;
  },
  makeDummy: function(flow) {
    return {fields: [],
            flow: flow,
            id: "rows*",
            param: {},
            rbsid: "dummy-rows",
            widget: "dummy",
            __proto__: FieldProto}
  },
  rowColumns: function (row) {
    /* width of all fields in row */
    var columns = 0;
    row.forEach(function(field) {
      columns += field.offset() + field.columns();
    });
    return columns;
  },
  correctIndexes: function (row) {
    var indexLock = 0;
    row.forEach(function(field) {
      var index =  field.getOption(["grid", "index"]);
      if (indexLock != index) {
        field.setOption(["grid", "index"], indexLock);
      }
      indexLock += 1;
    })
  }
};

export {FieldDef};
