import React from "../../../../miniclient/in/client/node_modules/react";
import {Library} from "../../../../app/src/library.js";
import {Inspector} from "../../../../app/src/inspector.jsx";
import {Metamodeler} from "../../../../metamodeler/app/src/metamodeler.jsx";
import {Stencil} from "../../../../metamodeler/app/src/stencil.jsx";
import {DiagramEditorHeader} from "./diagram-editor-header.jsx";


class AssemblyEditor extends React.Component {
  constructor(props) {
    super(props);
    var editor = this;
    this.state = {paper: undefined, zoom: 1.3};
    this.handlePaperCreated = this.handlePaperCreated.bind(this);
  }
  handlePaperCreated(paper) {
    var editor = this;
    editor.setState({paper: paper});
  }
  handleZoomOut() {
    var editor = this;
    editor.setState(function(prevState) {
      return {zoom: Math.max(0.5, prevState.zoom - 0.1)};
    })
  }
  handleZoomIn() {
    var editor = this;
    editor.setState(function(prevState) {
      return {zoom: Math.min(2, prevState.zoom + 0.1)};
    })
  }
  load(context) {
    const component = this;
    const readOnly = context.readOnly
    const metamodelerContext = {...context, readOnly};
    return Promise.all([
      Inspector.prototype.load(context),
      Metamodeler.prototype.load(metamodelerContext),
      DiagramEditorHeader.prototype.load(context),
      Stencil.prototype.load(context),
    ]).then(([inspector, metamodeler, header, stencil]) => {
      return {inspector, metamodeler, header, stencil, readOnly};
    })
  }
  render() {
    const component = this;
    const props = component.props;
    const state = component.state;
    const readOnly = props.readOnly === true;
    let stencil;
    const paper = state.paper;
    if (paper && !readOnly) {
      stencil = (
        <Stencil {...props.stencil}
          paper={paper}/>
      )
    }
    const metamodeler = (
      <Metamodeler {...props.metamodeler}
        zoom={state.zoom}
        onUpdate={props.onUpdate}
        onPaperCreated={component.handlePaperCreated}
        readOnly={readOnly}/>
    );
    const header = (
      <DiagramEditorHeader {...props.header}
        onUpdate={props.onUpdate}
        onZoomOut={() => component.handleZoomOut()}
        onZoomIn={() => component.handleZoomIn()}/>
    );
    const inspector = (
      <div className="meta grow">
        <Inspector
          {...props.inspector}
          onUpdate={props.onUpdate}
          readOnly={readOnly}/>
      </div>
      );
    return  (
      <div className="component meta row">
        <div className="component flowEditor metamodeler meta row">
          {stencil}
          <div className="diagram meta column">
            {header}
            {metamodeler}
          </div>
        </div>
        {inspector}
      </div>
    )
   }
}

export {AssemblyEditor};
