import React from "../../../../miniclient/in/client/node_modules/react";
import {NavigationBar} from '../../../../app/src/navigation-bar.jsx';
import {EditButton} from '../../../../app/src/edit-button.jsx';
import {UserDropdown} from '../../../../app/src/user-dropdown.jsx';
import {SwitchConsoleButtons} from './switch-console-buttons.jsx';
import {Breadcrumbs} from '../../../../app/src/breadcrumbs.jsx';
import {VersionDropdown} from '../../../../app/src/version-dropdown.jsx';
import {Library} from "../../../../app/src/library.js";
import {Button} from 'semantic-ui-react'
import './header.css';

class Header extends React.Component {
  async load(context) {
    let navigationBarPromise;
    if (typeof context.menu !== 'undefined') {
      const menu = context.menu;
      if (typeof menu !== 'undefined') {
        navigationBarPromise = NavigationBar.prototype.load(context, menu);
      }
    }
    let versionDropdownPromise;
    if (context.versioningRoot && !context.readOnly) {
      versionDropdownPromise = VersionDropdown.prototype.load(context);
    }
    let editButtonPromise;
    if (context.debug) {
      editButtonPromise = EditButton.prototype.load(context);
    }
    const titleItem = Library.last(context.breadcrumbs);
    let title;
    if (titleItem) {
      title = titleItem.name;
    }
    const isReadOnly = context.readOnly;

    return Promise.all([
      navigationBarPromise,
      UserDropdown.prototype.load(context),
      versionDropdownPromise,
      editButtonPromise,
      SwitchConsoleButtons.prototype.load(context),
      Breadcrumbs.prototype.load(context),
    ]).then(([navigationBar, userDropdown, versionDropdown, editButton, switchConsoleButtons, breadcrumbs]) => {
      return {navigationBar, userDropdown, versionDropdown, editButton, switchConsoleButtons, breadcrumbs, title, isReadOnly}
    })
  }
  render() {
    /* avatar */
    const component = this;
    const props = component.props;
    const isReadOnly = props.isReadOnly;

    //Communication HUB
    let logo = (
      <div className="logo">
          <a onClick={function(e) {e.preventDefault(); props.onUpdate({type: 'routeTo', ri: '/'}); }} href="#"><img src="icons/logo.svg"/></a>
      </div>
    );

    /* user item */
    let userItem =
      <div className="item">
        <UserDropdown
          {...props.userDropdown}
          onUpdate={props.onUpdate}/>
      </div>;

    /* version item */
    let versionItem = typeof props.versionDropdown == 'undefined' ? '' :
      <div className="item">
        <VersionDropdown
          {...props.versionDropdown}
          onUpdate={props.onUpdate}/>
      </div>;

    /* loader */
    let loader;
    if (props.loading) {
      loader = <div className="item"><div className="ui active inline loader"/></div>;
    }

    /* error */
    let bug;
    if (props.error) {
      const bugStyle = {color: '#912d2b'};
      bug = <div className="item"><i style={bugStyle} className="large bug icon"/></div>;
    }

    /* edit button item*/
    let editButtonItem;
    if (props.editButton) {
      editButtonItem = (
        <div className="meta item">
          <EditButton {...props.editButton}
            onUpdate={props.onUpdate}/>
        </div>
      )

    }

    /* top right menu */
    const swichConsoleItem = (
      <div className="item">
        <SwitchConsoleButtons {...props.switchConsoleButtons} onUpdate={props.onUpdate}/>
      </div>
    );

    let readOnly;
    if (isReadOnly) {
      readOnly = <div className="meta vertical-center-content"><div className="ui yellow horizontal label">READ ONLY</div></div>;
    }

    let navigationBar;
    if (typeof props.navigationBar !== 'undefined') {
      navigationBar = <NavigationBar {...props.navigationBar} onUpdate={props.onUpdate}/>;
    }




    /* top */
    let topFirst = (
      <div className="meta row first center-items">
       {logo}
       {swichConsoleItem}
         <div className="right meta menu">
           {loader}
           {bug}
           {userItem}
         </div>
       </div>
    )

    let topSecond = (
      <div className="meta row second">
        {navigationBar}
      </div>
    );



    /* bottom */
    const title = <h1 className="main title">{props.title}</h1>;
    const reload = (
      <div className="meta item">
        <Button onClick={() => props.onUpdate({type: 'reload'})}>Reload</Button>
      </div>
    )

    const bottom = (
      <div className="meta row menu grow bottom">
        <div className="left meta column">
          {title}
          <Breadcrumbs {...props.breadcrumbs}
            onUpdate={props.onUpdate}/>
        </div>
        <div className="right meta menu">
          {reload}
          {editButtonItem}
          {readOnly}
          {versionItem}
        </div>
      </div>
    )


    const top = (
      <div className="meta column top">
        {topFirst}
        {topSecond}
      </div>
    )

    return (
      <div className="header main meta row">
       <div className="meta column grow">
         {top}
         {bottom}
       </div>
     </div>
    )
  }
}
export {Header};
