import React from "react";

import {MC} from "../client/MC.js";

class Dummy extends React.Component {

  render() {
    var field = this.props.field;
    var cssclass = MC.getFieldParamValue(field.param, '@cssClass', this.props.iteration);
    return <span className={cssclass} id={field.rbsid} ref={field.rbsid}/>;
  }

}

export {Dummy};
