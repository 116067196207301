import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import {Library} from '../library.js';
import {SimpleInspector} from './simple-inspector.jsx';
import {ReferenceInspector} from './reference-inspector.jsx';
import {ComplexInspector} from './complex-inspector.jsx';

const ValueInspector = createReactClass({
  load: function(context, property, value, possibility) {
    if (possibility) {
      const kind = possibility['meta3:kind']
      if (kind === 'simple') {
        return SimpleInspector.prototype.load(context, property, value, possibility)
      } else if (kind === 'reference') {
        return ReferenceInspector.prototype.load(context, property, value, possibility)
      } else if (kind === 'complex') {
        return ComplexInspector.prototype.load(context, property, value, possibility)
      } else {
        throw new Error('Unknown possibility kind.')
      }
    }
    if (Library.isSimple(value)) {
      return SimpleInspector.prototype.load(context, property, value);
    } else if (Library.isReference(value)) {
      return ReferenceInspector.prototype.load(context, property, value);
    } else {
      return ComplexInspector.prototype.load(context, property, value);
    }
  },
  render: function() {
    const component = this;
    const props = component.props;
    const type = props.type;
    if (type === 'simpleInspector') {
      return <SimpleInspector {...props}/>
    } else if (type === 'referenceInspector') {
      return <ReferenceInspector {...props}/>
    } else if (type === 'complexInspector'){
      return <ComplexInspector {...props}/>
    } else {
      throw new Error('Unknown type:', props);
    }
  }
})

export {ValueInspector};
