import {ValueInspector} from "./value-inspector.jsx";
import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import {Library} from '../library.js';
import {ensureArray} from '../lib.js'


const LabeledInspector = createReactClass({
  load: function(context, property, value, possibility) {
    return Promise.resolve(ValueInspector.prototype.load(context, property, value, possibility)).then(inspectorProps => {
      let title;
      if (possibility) {
        title = ensureArray(possibility['meta3:title'])[0]['meta3:titleText']
      } else {
        title = Library.propertyTitle(property);

      }
      
      return Object.assign({}, inspectorProps, {title: title});
    })
  },
  render: function() {
    const component = this;
    const props = component.props;
    const widget = React.createElement(ValueInspector, component.props);
    const title = props.title;
    if (props.hasTitle) {
      return widget;
    }
    const labelElement = <label>{title}</label>;
    return <div className="field">{labelElement}{widget}</div>
  }
})

export {LabeledInspector};
