import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import ReactDOM from "../../../miniclient/in/client/node_modules/react-dom";

const ToggleButton = createReactClass({
  render: function() {
    const component = this;
    const props = component.props;
    let buttonClassName = "ui button";
    if (props.loading) {
      buttonClassName += " loading";
    }
    if (props.error) {
      buttonClassName += " negative";
    }
    if (props.className) {
      buttonClassName += " " + props.className;
    }
    let label;
    if (props.isActive) {
      label = props.activeLabel;
    } else {
      label = props.pasiveLabel;
    }
    let icon;
    if (props.icon) {
      icon = <i className={props.icon}/>;
    }
    return (
      <button
        className={buttonClassName}
        onClick={component.props.onClick}>
        {icon}
        {label}
      </button>
    )
  }
})

export {ToggleButton};
