import joint from './rappid/rappid.js';
import Modal from './modal.js';
joint.ui.ContextMenu = joint.ui.Modal.extend({
    className: 'context-menu',
    eventNamespace: 'context-menu',
    events: { 'click .item': 'onItemPointerdown' },

    render: function() {
        if (this.options.type) {
            this.$el.attr('data-type', this.options.type);
        }
        joint.ui.Modal.prototype.render.apply(this, arguments);
    },

    renderContent: function() {

        var $items = $('<div/>', { 'class': 'ui vertical menu' });

        if (this.options.items) {

            _.each(this.options.items, function(item) {

                var $html;
                if (item.icon) {
                    $html = $('<img/>', { src: item.icon });
                } else {
                    $html = item.content;
                }

                var $item = $('<a/>', {
                    'class': 'item',
                    html: $html,
                    'data-action': item.action
                });

                if (item.attrs) {
                    $item.attr(item.attrs);
                }

                $items.append($item);
            });
        }

        this.$el.append($items);
    },
    onRemove: function() {
        joint.ui.Modal.prototype.onRemove.apply(this, arguments);
        this.trigger('context-menu:remove');
    },
    onItemPointerdown: function(event) {
        this.remove();
        var $item = $(event.target).closest('[data-action]');
        var action = $item.attr('data-action');
        if (action) {
            this.trigger('action:' + action, event);
        }

    }

});

const ContextMenu = joint.ui.ContextMenu;
export {ContextMenu};
