import React from "react";
import ReactDOM from "react-dom";

let popUpSetting = {delay: {show: 1000, hide: 0}};

class Button extends React.Component {

    componentDidMount() {
      var $node = $(ReactDOM.findDOMNode(this));
      $node.popup(popUpSetting)
    }

    render() {
      var cls = "ui";
      if (this.props.className) {
        cls += " " + this.props.className;
      }
      var icon;
      if (this.props.icon) {
        icon = <i className={this.props.icon + " icon"}/>;
        cls +=" icon";
      }
      cls += " button";
      if (this.props.disabled) {
        cls += " disabled";
      }
      if (this.props.active) {
        cls += " active";
      }
      return <button className={cls} onClick={this.props.onClick} data-content={this.props.help}>{icon}{this.props.title}</button>;
    }

}


class SaveButton extends React.Component {

    handleSave = () => {
      this.props.onSave();
    };

    render() {
      var cls = "ui button primary";
      if (!this.props.isSavePossible) {
        cls += " disabled";
      }
      if (this.props.inProcess) {
        cls += " loading";
      }
      return <button className={cls} onClick={this.handleSave}>Uložit</button>;
    }

}


class ResolutionButtons extends React.Component {

  get$node() {
    return $(ReactDOM.findDOMNode(this));
  }

  handleClick = (event) => {
    var resolution = event.currentTarget.getAttribute("data");
    this.props.onChangeResolution(resolution);
  };

  componentDidMount() {
    var self = this;
    var $node = this.get$node();
    $node.find(".dropdown").dropdown({action: "select",
                                      onChange: function (value, text, $selectedItem) {
      if (value == "delete") {
        self.props.onDeleteGrid();
      }
      if (value == "create") {
        self.props.onCreateGrid();
      }
    }});
    $node.find("button").popup(popUpSetting);
  }

  render() {
    var self = this;
    var shortcuts = {"x-small": "XS", small: "S", medium: "M", large: "L"};
    var resolutions = ["x-small", "small", "medium", "large"].filter(function(resolution){
      return self.props.autolayout.indexOf(resolution) == -1;
    });
    var items = resolutions.map(function(e) {
      var text = shortcuts[e];
      var help = e + " layout";
      var cls = "ui button";
      if (self.props.resolution == e) {
        cls += " active";
      }
      if (self.props.definedResolutions.indexOf(e) != -1) {
        text= <u>{text}</u>;
        help += " (s gridem)"
      }
      return <button className={cls} key={e} data={e} onClick={self.handleClick} data-content={help}>{text}</button>;

    });
    var deleteGridClass = "item";
    if (!this.props.isDeleteResolutionEnabled) {
      deleteGridClass += " disabled";
    }
    var createGridClass= "item";
    if (self.props.definedResolutions.indexOf(self.props.resolution) != -1) {
      createGridClass += " disabled";
    }

    return <div className="ui icon buttons">
              {items}
              <div className="ui floating dropdown icon button">
                <i className="dropdown icon" />
                <div className="menu">
                  <div className={createGridClass} data-value="create">
                    <i className="file icon"/>
                    Vytvořit grid
                  </div>
                  <div className={deleteGridClass} data-value="delete">
                    <i className="delete icon red"/>
                    Odstranit grid
                  </div>
                </div>
              </div>
            </div>;
  }

}


var helpStyle= {width: "450px"};
var helpPopup =  <div className="ui flowing popup top left transition hidden" style={helpStyle}>
 <div className="header">Ovládání</div>
   <table className="ui very basic table">
    <tbody>
      <tr>
        <td>
          <div className="ui label">Shift+S</div>
        </td>
        <td>Uložit</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Ctrl+X</div>
        </td>
        <td>Vyjmout</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Ctrl+C</div>
        </td>
        <td>Kopírovat</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Ctrl+V</div>
        </td>
        <td>Vložit</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Ctrl+Z</div>
        </td>
        <td>Odvolat akci</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Ctrl+Y</div>
        </td>
        <td>Opakovat akci</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Ctrl+L</div>
        </td>
        <td>Přepínání konce řádku</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Ctrl+A</div>
        </td>
        <td>Vybrat vše</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Backspace</div>
          <div className="ui label">Delete</div>
        </td>
        <td>Odstranit</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Shift+Click</div>
        </td>
        <td>Vyber pole</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Kontextové menu</div>
        </td>
        <td>Zobrazit paletu polí</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Shift+Drag</div>
        </td>
        <td>Změnit offset</td>
      </tr>
      <tr>
        <td>
          <div className="ui label">Dvoj klik</div>
        </td>
        <td>Změnit název nebo jméno</td>
      </tr>
    </tbody>
  </table>
</div>


class Menu extends React.Component {

  state = {search: ""};

  handleSearchChange = (event) => {
    this.setSearchValue(event.target.value);
  };

  handleSearchSelect = (value) => {
    console.log(value);
    this.setSearchValue(value.title);
  };

  setSearchValue = (value) => {
    this.setState({search: value});
    if (value == "") {
      this.props.setSelectedFields([]);
    } else {
      var form = this.props.form;
      var fields = form.findFieldsHelp(value, form.fields, true);
      var fieldsId = fields.map(function(field) {return field.rbsid});
      this.props.setSelectedFields(fieldsId);
    }
  };

  componentDidMount() {
    var $node = $(ReactDOM.findDOMNode(this));
    $node.find('.ui.search').search({cache: false, showNoResults: false});
    $node.find('.help.icon').popup()
  }

  handleSearchClick = () => {
    var self = this;
    var content = this.props.form.getAllFieldIds().filter(function(id) {
      return id != self.props.form.id;
    }).map(function(id) {
        return {title: id};
    });
    var $node = $(ReactDOM.findDOMNode(this));
    console.log(content);
    var $search = $node.find('.ui.search')
    $search.search("clear cache");
    $search.search({ source: content, onSelect: this.handleSearchSelect, showNoResults: false});
  };

  render() {
    var resolution = this.props.resolution;
    var definedResolutions = this.props.definedResolutions;
    var isDeleteResolutionEnabled = (definedResolutions.indexOf(resolution) != -1) && (definedResolutions.length > 1);
    var undoRedoButtons = <div className="ui icon buttons">
                            <Button icon="undo" onClick={this.props.onUndo} disabled={!this.props.isUndoStack} help="Odvolat akci"/>
                            <Button icon="horizontally flipped undo" onClick={this.props.onRedo} disabled={!this.props.isRedoStack} help="Opakovat akci"/>
                          </div>;
    var resolutionButtons = <ResolutionButtons definedResolutions={this.props.definedResolutions}
                                               autolayout={this.props.autolayout}
                                               resolution={this.props.resolution}
                                               isDeleteResolutionEnabled={isDeleteResolutionEnabled}
                                               onChangeResolution={this.props.onChangeResolution}
                                               onDeleteGrid={this.props.onDeleteGrid}
                                               onCreateGrid={this.props.onCreateGrid}/>;
    var saveButton = <SaveButton isSavePossible={this.props.isSavePossible} onSave={this.props.onSave} inProcess={this.props.saveInProcess}/>;
    var miniclientButtons;
    if (!this.props.isInStandardGui) {
      miniclientButtons = <div className="fitted item">
                            <button className="ui icon button disabled"><i className="setting icon"></i></button>
                            <button className="ui icon button disabled"><i className="bug icon"></i></button>
                            <Button icon="paint brush " active={true} onClick={this.props.onModelerSwitchOff} help="Vypnout/zapnout modeler"/>
                          </div>;
    }

    return  <div className="meta menu top component">
              {miniclientButtons}
              <div className="meta menu-item">
                {undoRedoButtons}
              </div>
              <div className="meta menu-item">
                {resolutionButtons}
              </div>
              <div className="meta menu-item">
                <div className="ui icon buttons">
                  <Button icon="eye" active={this.props.ghostMode} onClick={this.props.onTongueGhostMode} help="Vypnout/zapnout zobrazení neviditelných polí"/>
                  <Button icon="lab" active={this.props.modelerState.structuralMode} onClick={this.props.tongueStructuralMode} help="Vypnout/zapnout strukturální pohled"/>
                </div>
            </div>
            <div className="meta menu-item">
              <Button icon="browser"  onClick={this.props.showPalette} help="Zobraz paletu"/>
            </div>
            <div className="meta menu-item">
              <div className="ui search">
                <div className="ui icon input">
                    <input className="prompt"
                           id="searchInput"
                           type="text"
                           name="formfieldsearch"
                           placeholder="Hledej..."
                           onChange={this.handleSearchChange}
                           onClick={this.handleSearchClick}
                           value={this.state.search}/>
                    <i className="search icon"/>
                </div>
                <div className="results"></div>
              </div>
            </div>
            <div className="meta menu-item">
              <i className="help icon"></i>
              {helpPopup}
           </div>
              <div className="meta right menu">
                <div className="meta menu-item">
                  {saveButton}
                </div>
              </div>
            </div>;
    }

}

export {Menu};
