import {RB} from "../../lib/in/resourcebus/1.0.2018-07-31/resourcebus.js"


export const removeDuplicates = (array) => {
  return array.filter((item, pos) => {
    return array.indexOf(item) == pos;
  })
}

export const pathIsFolder = path => {
  return path[path.length - 1] === '/'
}

export const pathFileName = path => {
  if (pathIsFolder(path)) {
    const lastSegment = path.split('/').slice(-2,-1)[0]
    return lastSegment.split(';')[0]
  } else {
    return path.split('/').slice(-1)[0]
  }
}

export const referenceFragment = reference => {
  return refFragment(reference['rbs:ref'])
}

export const refFragment = ref=> {
  return ref.split('#')[1];
}

export const ensureArray = value => {
  if (typeof value === 'undefined') {
    return [];
  } else if (value instanceof Array) {
    return value;
  } else {
    return [value];
  }
}

export const unwrapSingleton = array => {
  if (array.length === 1) {
    return array[0];
  } else {
    return array;
  }
}

export const uuid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

export const concatArrays = arrays => {
  return arrays.reduce((result, array) => {
    return result.concat(array);
  }, [])
}


const jsonType = 'application/json'

const runFlowRiTemplate = 'in/flow/api/?start=true&redirect=false&interactive=false&flowconfig={flowConfig}&operationname={operationName}&includeid=true&exptrace=false'
const runFlowAsGetRiTemplate = 'in/flow/api/?start=true&redirect=false&interactive=false&flowconfig={flowConfig}&operationname={operationName}&includeid=true&exptrace=false&inputdata={inputData}'

export const runFlow = async (flowConfig, operationName, input, runAsGet) => {
  let runFlowRi
  if (runAsGet) {
    runFlowRi = runFlowAsGetRiTemplate.replace('{flowConfig}', flowConfig).replace('{operationName}', operationName).replace('{inputData}', encodeURIComponent(JSON.stringify(input)))
  } else {
    runFlowRi = runFlowRiTemplate.replace('{flowConfig}', flowConfig).replace('{operationName}', operationName)
  }
  const method = runAsGet ? 'GET' : 'POST';
  const rbResource = RB.resource().method(method).accept(jsonType).ri(runFlowRi)
  if (!runAsGet) {
    rbResource.content(JSON.stringify(input)).contentType(jsonType)
  }
  const resource = await rbResource.load()
  if (resource.status() == 200 || resource.status() == 204) {
    return JSON.parse(resource.content())
  } else {
    throw new Error('Run flow error: ' + JSON.stringify({status: resource.status(), flowConfig, operationName, input: input }))
  }
}

// cache

export const createCachedFunction = func => {
  let lastInput
  let lastResult
  let lastResultPromise
  let state = 'stoped'
  return async (input) => {
    if (state === 'running' && input === lastInput) {
      return await lastResultPromise
    }
    if (!(state === 'finished' && input === lastInput)) {
      state = 'running'
      lastInput = input
      lastResultPromise = func(input)
      lastResult = await lastResultPromise 
      state = 'finished'
    }
    return lastResult
  }
}
// reference
export const resolveReference = (basePath, reference) => {
  const relativePath = reference['rbs:ref']
  return riResolve(basePath, relativePath)
}

// ri
export const riResolve = (baseRi, relativeRi) => {
  const resolved = RB.resolve(relativeRi, '/' + baseRi);
  if (resolved !== '/') {
    return resolved.slice(1);
  } else {
    return '/'
  }
}