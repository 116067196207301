import React from "../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';

const Button = createReactClass({
  render: function() {
    const component = this;
    const props = component.props;
    let buttonClassName = "ui button";
    let label = props.label;
    if (props.loading) {
      buttonClassName += " loading";
    }
    if (props.error) {
      buttonClassName += " negative";
    }
    if (props.className) {
      buttonClassName += ' ' + props.className;
    }
    if (props.disabled) {
      buttonClassName += ' disabled';
    }
    if (props.icon) {
      label = <i className={"icon " + props.icon}></i>;
      buttonClassName += ' icon';
    }
    return (
     <button
       className={buttonClassName}
       onClick={component.props.onClick}>
       {label}
     </button>
    )
  }
})

export {Button};
