import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import {Library} from '../library.js';
import {Dropdown} from '../widget/dropdown.jsx';

const ReferenceInspector = createReactClass({
  loadTargets: function(context, value, property) {
    const locations = context.valuesLocations;
    if (!locations) {
      return Promise.resolve([]);
    }
    const location = Library.ensureArray(locations).find(location => location['app:propertyName'] === property);
    if (!location) {
      return Promise.resolve([]);
    }
    const valuesPath = location['app:folder']['rbs:ref'];
    return context.storage.list(valuesPath).then(files => {
      const targets = files.filter(resource => {
        const isFolder = resource['rbs:isFolder']
        if (isFolder) {
          return false;
        }
        const hasProperties = resource['meta3:properties'];
        if (!hasProperties) {
          return false;
        }
        const isIndex = resource['meta3:properties']['rbs:id'] === 'index';
        if (isIndex) {
          return false;
        }
        const hasName = resource['meta3:properties']['meta3:name'];
        if (!hasName) {
          return false;
        }
        return true;
      });
      return targets;
      /*
      const conceptReference = value['meta3:instanceOf'];
      if (!conceptReference) {
        return [];
      }
      const concept = Library.referencedValue(context, conceptReference);
      const targetReference = concept[property];
      if (!targetReference) {
        return [];
      }
      const targetConcept = Library.referencedValue(context, targetReference);
      const targets = resources.filter(resource => Library.isInstanceOf(resource['rbs:properties'], targetConcept));
      return targets;*/
    })
  },
  loadItems: function(context, value, property) {
    return ReferenceInspector.prototype.loadTargets(context, value, property).then(targets => {
      return targets.map(function(target) {
        return  {value: target['meta3:path'], name: target['meta3:properties']['meta3:name']};
      });
      /*
      let allGroups = targets.map(function(target) {
        return target.pathValue('meta3:parent');
      });
      let groups = RemoveDuplicates(allGroups);
      let items;
      if (groups.length > 1) {
        items = groups.map(function(group) {
          let groupItems = targets.filter(function(target) {
            return target.pathValue('meta3:parent') == group;
          }).map(function(target) {
            return  {value: target.getKey(), name: target.pathValue("meta:name")};
          });
          return {group: group.pathValue('meta3:name'), items: groupItems};
        })
      } else {
        items = targets.map(function(target) {
          return  {value: target.getKey(), name: target.pathValue("meta:name")};
        });
      }*/
    });
  },
  handleUpdateReference(path) {
    const component = this;
    const props = component.props;
    const basePath= props.basePath;
    const relativizedPath = Library.relativizePath('/' + basePath, '/' + path);
    component.props.onValueUpdate({'rbs:ref': relativizedPath});
  },
  load: function(context, property, value) {
    return ReferenceInspector.prototype.loadItems(context, value, property).then(items => {
      const basePath = context.file['meta3:path'];
      let reference = ''
      let referencedPath = ''
      if (value) {
        reference = value['rbs:ref']
        referencedPath = Library.referenceResolve(value, basePath)
      }
      return {
        value: value,
        items: items,
        type: 'referenceInspector',
        basePath: basePath,
        referencePath: reference,
        referencedPath
      }
    })
  },
  render: function() {
    const component = this;
    const props = component.props;
    const items = props.items;
    let field;
    if (items.length == 0) {
      field = (
        <div className="field">
          <div className="ui input">
            <input
              type="text"
              value={props.referencePath}
              readOnly={props.readOnly}/>
          </div>
        </div>
      )
    } else {
      const widget = <Dropdown items={items} value={props.referencedPath} onSetValue={component.handleUpdateReference} readOnly={props.readOnly}/>
      if (props.referencedPath) {
        const link = <a onClick={() => props.onUpdate({type: 'routeTo', ri: props.referencedPath})}><i className="ui icon link large linkify"/></a>
        field = (
          <div className="fields">
            <div className="ten wide field">
              {widget}
            </div>
            <div className="two wide field" style={{paddingTop: '2mm'}}>
              {link}
            </div>
          </div>
        )
      } else {
        field = <div className="field">{widget}</div>
      }
    }
    return field;
  }
})
export {ReferenceInspector};
