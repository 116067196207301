import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import ReactDOM from "../../../miniclient/in/client/node_modules/react-dom";
import {Library} from '../library.js';

const CreateMenu = function(items) {
  let arrayOfMenuElements = items.map(function(item) {
    if (item.group) {
      let header = document.createElement("h5");
      header.textContent = item.group;
      let submenu = CreateMenu(item.items);
      return [header].concat(submenu);
    } else {
      let div = document.createElement("div");
      div.setAttribute("class", "item");
      div.setAttribute("data-value", item.value);
      div.textContent = item.name;
      return [div];
    }
  })
  return Library.concatArrays(arrayOfMenuElements);
}


const Dropdown = createReactClass({
  componentWillReceiveProps: function(nextProps) {
    let dropdown = this;
    let value = nextProps.value;
    let node = ReactDOM.findDOMNode(dropdown);
    dropdown.withoutChanges = true;
    const $dropdown = $(node).find(".dropdown");
    if (nextProps.items.find(item => item.value === value)) {
      $dropdown.dropdown("set selected", value);
    } else {
      $dropdown.dropdown('clear');
    }
    dropdown.withoutChanges = false;
  },
  componentDidMount: function() {
    let dropdown = this;
    let object = dropdown.props.object;
    let items = dropdown.props.items;
    let value = dropdown.props.value;
    let input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    //input.setAttribute('name', object.name);
    let defaultText = document.createElement('div');
    defaultText.setAttribute('class', 'default text');
    /*defaultText.textContent = 'text';*/
    let icon = document.createElement('i');
    icon.setAttribute('class', 'dropdown icon');
    let menu = document.createElement('div');
    menu.setAttribute('class', 'menu');
    let itemsElements = CreateMenu(items);
    itemsElements.forEach(function(item) {
      menu.appendChild(item);
    });

    let dropdownElement = document.createElement("div");
    let cls = 'ui selection search fluid dropdown';
    if (dropdown.props.readOnly) {
      cls += ' disabled';
    }
    dropdownElement.setAttribute('class', cls);
    dropdownElement.appendChild(input);
    dropdownElement.appendChild(icon);
    dropdownElement.appendChild(defaultText);
    dropdownElement.appendChild(menu);
    let node = ReactDOM.findDOMNode(dropdown);
    node.appendChild(dropdownElement);
    $(dropdownElement).dropdown("set selected", value);
    $(dropdownElement).dropdown(
      {onChange: function(value) {
        if (!dropdown.withoutChanges) {
          dropdown.props.onSetValue(value);
        }
      }
    });
  },
  render: function() {
    return <div/>
  }
})

export {Dropdown};
