'use strict';
import {Library} from "./library.js";

const App = {};

App.getValue = async (storage, path, applicationPath) => {
  const file = await storage.getFile(path)
  file['app:path'] = applicationPath
  file['meta3:isFile'] = true
  return file
}

App.loadFile = async (storage, context, file) => {
  const properties = file['meta3:properties'] || {}
  if (properties['meta3:instanceOf'] && properties['meta3:instanceOf']['rbs:ref'] === '/in/app/concept/model') {
    context.model = file
  }
  if (file['meta3:isVersioningRoot']) {
    context.versioningRoot = file
  }
  if (properties['app:userData'] === 'true') {
    context.files = Library.ensureArray(context.files).concat([file])
    context.file = file
    if (!context.view) {
      context.view = file
    }
  }
}

App.loadApplicationValue = async (storage, context, applicationValue) => {
  if (applicationValue['meta3:isFile']) {
    await App.loadFile(storage, context, applicationValue)
  }
  context.value = applicationValue

  const properties = applicationValue['meta3:properties'] || {}
  if (properties['app:layout'] && !context.fixLayout) {
    const layout = {
      'meta3:path': applicationValue['meta3:path'],
      'meta3:properties': properties['app:layout']
    }
    context.layout = layout
  }
  if (properties['app:fixLayout'] == 'true') {
    context.fixLayout = true
  }
  if (properties['app:menu']) {
    context.menu = {
      'meta3:path': applicationValue['meta3:path'],
      'meta3:properties': properties['app:menu']
    }
  }
  if (properties['app:isNavigationItem'] === 'true') {
    if (typeof context.breadcrumbItems === 'undefined') {
      context.breadcrumbItems = []
    }
    context.breadcrumbItems.push(applicationValue)
  }
  if (properties['app:pathParameter']) {
    if (typeof context.pathParameters === 'undefined') {
      context.pathParameters = {}
    }
    const appPathSegments = applicationValue['app:path'].split('/')
    context.pathParameters[properties['app:pathParameter']] = Library.last(appPathSegments)
  }

  if (properties['app:valuesLocation']) {
    const valuesLocation = Library.ensureArray(properties['app:valuesLocation'])
    context.valuesLocations = Library.ensureArray(context.valuesLocations).concat(valuesLocation.map(valueLocation => {
      const folderReference = valueLocation['app:folder']
      const folderPath = Library.resolveReference(applicationValue['meta3:path'], folderReference)
      return {
        'app:folder': {'rbs:ref': '/' + folderPath},
        'app:propertyName': valueLocation['app:propertyName']
      }
    }))
  }
  if (properties['app:allowSubpaths']) {
    context.allowSubpaths = true
  }
}

App.entryPointValue = async (storage, applicationValue, pathSegment) => {
  const properties = applicationValue['meta3:properties'] || {}
  const entryPoint = Library.ensureArray(properties['app:entryPoint']).find(entryPoint => entryPoint['app:step'] === pathSegment)
  let description;
  if (entryPoint) {
    description = entryPoint['app:description']
  }
  if (!description) {
    description = properties['app:defaultEntryPoint']
  }
  if (!description && properties['app:referenceEntryPoint']) {
    const referenceEntryPoint = properties['app:referenceEntryPoint']
    const property = referenceEntryPoint['app:referenceProperty']
    const file = await storage.getFile(applicationValue['meta3:path'])
    const sourceComplexs = Library.findComplexes(file['meta3:properties'], pathSegment)
    const sourceComplex = sourceComplexs[0]
    if (sourceComplex) {
      const reference = sourceComplex[property]
      if (reference) {
        const referencedPath = Library.resolveReference(file['meta3:path'], reference)
        return await App.getValue(storage, referencedPath, applicationValue['app:path'] + '/' + pathSegment)
      }
    }
  }

  if (!description && properties['app:propertyEntryPoint']) {
    const property = properties['app:propertyEntryPoint']['app:property']
    const values = properties[property]
    description = {
      'meta3:value': values,
      'app:valueEntryPoint': true
    }
  }

  if (!description && properties['app:valueEntryPoint']) {
    description = Library.ensureArray(properties['meta3:value']).find(value => value['rbs:id'] === pathSegment)
  }

  if (description) {
    const value = {
      'app:path': applicationValue['app:path'] + '/' + pathSegment,
      'meta3:properties': description,
      'meta3:path': applicationValue['meta3:path'],
      'meta3:isFile': false,
      'meta3:exist': true
    }
    return value
  }
}

App.loadPath = async (storage, context, applicationValue, pathSegments) => {
  await App.loadApplicationValue(storage, context, applicationValue)
  if (pathSegments.length > 0 && (pathSegments.length !== 1 || pathSegments[0] !== '')) {
    const pathSegment = pathSegments[0]
    const appPath = applicationValue['app:path']
    let nextAppPath
    if (appPath === '') {
      nextAppPath = pathSegment
    } else {
      if (Library.isFolderPath(appPath)) {
        nextAppPath = appPath + pathSegment
      } else {
        nextAppPath = appPath + '/' + pathSegment
      }
    }
    let nextApplicationValue;
    nextApplicationValue = await App.entryPointValue(storage, applicationValue, pathSegment)

    if (!nextApplicationValue && applicationValue['meta3:isFile'] && applicationValue['meta3:exist']) {
      const metaPath = applicationValue['meta3:path']
      let nextMetaPath
      if (Library.isFolderPath(metaPath)) {
        nextMetaPath = metaPath + pathSegment
      } else {
        nextMetaPath = metaPath + '/' + pathSegment
      }
      nextApplicationValue = await App.getValue(storage, nextMetaPath, nextAppPath)
      if (!nextApplicationValue['meta3:exist']) {
        const continueAt = (applicationValue['meta3:properties'] || {})['app:continueAt']
        if (continueAt) {
          const continueAtPath = Library.resolveReference(applicationValue['meta3:path'], continueAt)
          if (continueAtPath === '/') {
            nextMetaPath = pathSegment
          } else {
            nextMetaPath += continueAtPath + "/" + pathSegment
          }
          nextApplicationValue = await App.getValue(storage, nextMetaPath, nextAppPath)
        }
      }
    }
    if (typeof nextApplicationValue !== 'undefined') {
      await App.loadPath(storage, context, nextApplicationValue, pathSegments.slice(1))
    }
  }
}

App.loadFragment = (storage, context, fragment) => {
  const value = context.value
  const complex = Library.findComplexes(value['meta3:properties'], fragment)[0]
  if (complex) {
    const applicationValue = {
      'app:path': value['app:path'],
      'meta3:path': value['meta3:path'],
      'meta3:exist': true,
      'meta3:properties': complex
    }
    context.value = applicationValue
    App.loadApplicationValue(storage, context, applicationValue)
  }
}

App.getContext = async (storage, applicationPath, ri) => {
  const context = {}
  const applicationValue = await App.getValue(storage, applicationPath, '')
  const parsedRi = Library.parseRi(ri)
  await App.loadPath(storage, context, applicationValue, parsedRi.path.split('/'))
  if (parsedRi.fragment) {
    App.loadFragment(storage, context, parsedRi.fragment)
  }
  if (storage.filesNeedStore() && context.view) {
    context.doNotLeavePath = context.view['meta3:path']
  }
  return context
}

export {App}
