import React from "../../../../../miniclient/in/client/node_modules/react";
import createReactClass from 'create-react-class';
import {Library} from "../../../../../app/src/library.js";
import {ToggleButton} from "../../../../../app/src/widget/toggle-button.jsx";
import {Checkbox} from "../../../../../app/src/widget/checkbox.jsx";
import {DropdownButton} from "../../../../../app/src/widget/dropdown-button.jsx";
import {Dropdown} from "../../../../../app/src/widget/dropdown.jsx";
import {UndoRedoButtons} from '../../../../../app/src/undo-redo-buttons.jsx';
import {Button} from '../../../../../app/src/widget/button.jsx';
import {ActionInput} from '../../../../../app/src/widget/action-input.jsx';
import {SaveButton} from '../../../../../app/src/save-button.jsx';


const Header = createReactClass({
  getInitialState: function() {
    return {
      testEmailSending: false,
      testEmailSendingError: false
    };
  },
  addPart: function(conceptPath) {
    const component = this;
    const props = component.props;
    const template = props.template;
    const positions = Library.ensureArray(template['app:part']).filter(part => {
      return typeof part['meta3:position'] !== 'undefined'
    }).map(part => {
      return Number(part['meta3:position'])
    })
    const maxPosition = positions.reduce((max, position) => Math.max(max, position), 0);
    const reference = {'rbs:ref':  '/' + conceptPath};
    const position = maxPosition + 1;
    const part = {
      'meta3:instanceOf': reference,
      'rbs:id': Library.guid(),
      'meta3:position': String(position)
    }
    const templateUpdate = {'app:part': [part]};
    props.onUpdate({type: 'fileUpdate', path: props.file['meta3:path'], fileUpdate: templateUpdate});
  },
  changeDisplay: function(display) {
    const component = this;
    const props = component.props;
    const riValue = props.riValue;
    let newQuery = {...riValue.query, display: display};
    let newRiValue = {...riValue, query: newQuery};
    props.onUpdate({type: 'routeTo', ri:  Library.stringifyRiValue(newRiValue)});
  },
  handleShowHiddenChange: function(showHidden) {
    const component = this;
    const props = component.props;
    const riValue = props.riValue;
    const newQuery = {...riValue.query}
    if (showHidden) {
      newQuery.showHidden = 'true'
    } else {
      delete newQuery.showHidden;
    }
    const newRiValue = {...riValue, query: newQuery};
    props.onUpdate({type: 'routeTo', ri: Library.stringifyRiValue(newRiValue)});
  },
  displayPreview: function() {
    const component = this;
    component.toggleLayout('preview');
    /*
    const props = component.props;
    const templateHtml = props.template['app:templateHtml'];
    const display = props.display;
    const widths = {
      mobile: '363px',
      tablet: '600px',
      desktop: '800px'
    }
    const width = widths[display];
    const win = window.open('', 'Title', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=' + width + ', height=200, top='+(screen.height-400)+', left='+(screen.width-840));
    let newDoc = document.implementation.createHTMLDocument();
    newDoc.open();
    newDoc.write(templateHtml);
    newDoc.close();
    const baseUrl = props.imgBaseUrl;
    const base = newDoc.createElement('base');
    base.href = baseUrl;
    newDoc.getElementsByTagName('head')[0].appendChild(base);

    //win.document = newDoc;
    win.document.head.innerHTML = newDoc.head.innerHTML;
    win.document.body.innerHTML = newDoc.body.innerHTML;*/

  },
  handleToggleEditHtml: function() {
    const component = this;
    component.toggleLayout('edit-html');
  },
  handleToggleEditPropeties: function() {
    const component = this;
    component.toggleLayout('edit-properties');
  },
  handleToggleEditCss: function() {
    const component = this;
    component.toggleLayout('edit-css');
  },
  toggleLayout: function(layout) {
    const component = this;
    const props = component.props;
    const riValue = props.riValue;
    let newQuery = {...riValue.query};
    if (newQuery.layout === layout) {
      delete newQuery.layout;
    } else {
      newQuery.layout = layout;
    }
    let newRiValue = {...riValue, query: newQuery};
    props.onUpdate({type: 'routeTo', ri: Library.stringifyRiValue(newRiValue)});
  },
  load: function(context, file) {
    const template = file['meta3:properties'];
    let propsPromise = Promise.resolve({});
    if (!context.isMessage) {
      propsPromise = context.storage.list('commhub/concept/template-part').then(parts => {
        return parts
          .filter(part => Library.boolean(part['meta3:properties']['app:allowAddByUser']))
          .map(part => {
            return {value: part['meta3:path'], name: part['meta3:properties']['meta3:prototype']['meta3:name']}
          })
      }).then(conceptParts => {
        return {concepts: conceptParts, filePath: file['meta3:path']}
      })
    }
    return propsPromise.then(props => {
      return {...props,
        storage: context.storage,
        isMessage: context.isMessage,
        layout: context.riValue.query.layout,
        allowPreview: template['mm:allowPreview'],
        allowEditHtml: template['app:allowEditHtml'],
        allowAddItem: template['mm:allowAddItem'],
        editHtml: context.editHtml,
        cssClass: template['app:cssClass'],
        displays: context.displays,
        display: context.display,
        showHidden: context.showHidden,
        file,
        template,
        readOnly: context.readOnly,
        imgBaseUrl: context.imgBaseUrl,
        riValue: context.riValue,
        modelPath: context.model['meta3:path']
      }
    })
  },
  handleSendTestEmail: function(testEmail) {
    const component = this;
    const props = component.props;
    const modelPath = props.modelPath;
    const filePath = props.file['meta3:path'];
    component.setState({testEmailSending: true, testEmailSendingError: false});
    Library.runFlow(modelPath, 'APP_SendTestEmail', {
      'app:templateRi': filePath,
      'app:email': testEmail
    }).then(() => {
      component.setState({testEmailSending: false});
    }).catch(() => {
      component.setState({testEmailSending: false, testEmailSendingError: true});
    })
  },
  render: function() {
    const component = this;
    const props = component.props;
    const state = component.state;
    const display = props.display;
    const readOnly = props.readOnly;

    let className = display;

    let previewItem;
    let showHiddenItem;
    let addItem;
    let editCssItem;
    if (!props.editHtml && !props.isMessage) {

      if (!readOnly) {
        showHiddenItem = (
          <div className="meta item">
            <Checkbox
              value={props.showHidden}
              title="Show hidden"
              onValueChange={component.handleShowHiddenChange}/>
          </div>
        );
      }
      if (!readOnly) {
        previewItem  = (
          <div className="meta item">
            <ToggleButton
              isActive={props.layout === 'preview'}
              activeLabel="Hide preview"
              pasiveLabel="Display preview"
              onClick={component.displayPreview}
              className="flat divider-buton"
              icon="ui eye icon"/>
          </div>
        );
      }
      if (!readOnly) {
        addItem =(<div className="meta item">
          <DropdownButton
            items={props.concepts}
            text="add"
            onSelect={component.addPart}
            className="flat"/>
        </div>);
      }
      if (!readOnly) {
        editCssItem = (
          <div className="meta item">
            <ToggleButton
              isActive={props.layout === 'edit-css'}
              activeLabel="Stop editing CSS"
              pasiveLabel="Edit CSS"
              onClick={component.handleToggleEditCss}
              className="flat divider-buton"
              icon="ui paint brush icon"/>
          </div>
        )
      }

    }
    let undoRedoItem;
    if (!readOnly) {
     undoRedoItem = (
       <div className="item">
         <UndoRedoButtons
           storage={props.storage}
           onUpdate={props.onUpdate}/>
       </div>
     )
    }

    let deleteButtonClassName = "ui icon button delete";
    if (props.readOnly) {
      deleteButtonClassName += " disabled"
    }

    const saveDeleteItem = (
      <div className="item savedelete">
        <SaveButton
          storage={props.storage}
          onUpdate={props.onUpdate}/>
        <button className={deleteButtonClassName} title="Delete" onClick={() => props.onUpdate({type:'routeTo', ri: props.file['meta3:path'] + '/delete'})}>
          <i className="icon trash alternate"></i>
        </button>
      </div>
    );
    let editHtmlItem;
    if (props.allowEditHtml && !props.isMessage && !readOnly) {
      editHtmlItem = (
        <div className="meta item">
          <ToggleButton
            isActive={props.layout === 'edit-html'}
            activeLabel="Stop editing HTML"
            pasiveLabel="Edit HTML"
            onClick={component.handleToggleEditHtml}
            className="flat divider-buton"
            icon="ui code icon"/>
        </div>
      )
    }
    let testEmailItem;
    if (!props.isMessage && !readOnly) {
      testEmailItem = <div className="item">
                        <ActionInput
                       onAction={component.handleSendTestEmail}
                       placeholder="Enter email..."
                       actionLabel="Send test email"
                       error={state.testEmailSendingError}
                       loading={state.testEmailSending}
                       buttonClassName="save"/>
                      </div>;
    }
    let editProperties;
    if (!readOnly) {
      editProperties =
        <div className="meta item">
          <ToggleButton
            isActive={props.layout === 'edit-properties'}
            activeLabel="Stop editing properties"
            pasiveLabel="Edit properties"
            onClick={component.handleToggleEditPropeties}
            className="flat divider-buton"
            icon="ui sun outline icon"/>
        </div>
    }
    const displays = props.displays;
    let displaysItem;
    if (displays.length > 1) {
      let items = displays.map((display, i) => {
        let className = "ui icon button display flat";
        if (props.display == display) {
          className += " disabled active-display";
        }
        return (
          <button className={className} title={display} key={i} onClick={() => component.changeDisplay(display)}>
            <i className={"ui icon " + display}></i>
          </button>
        );
      });
      displaysItem = <div className="meta menu-item display"><button className="ui button flat divider-button text">Preview:</button>{items}</div>;
    }

    let cssClass = props.cssClass;
    if (cssClass) {
      className += " " + cssClass;
    }

    let propertiesButtonClassName = "ui button flat divider-button";
    if (props.readOnly) {
      propertiesButtonClassName += " disabled"
    }
    let cloneButtonClassName = "ui button flat";
    if (props.readOnly) {
      cloneButtonClassName += " disabled"
    }

    const actionsItem = (
      <div className="meta menu-item">
        <div className="ui icon buttons">
          <button className={propertiesButtonClassName} title="Properties" onClick={() => props.onUpdate({type:'routeTo', ri: props.file['meta3:path'] + '/properties'})}>
            <i className="icon edit"></i>
          </button>
          <button className={cloneButtonClassName} title="Clone" onClick={() => props.onUpdate({type:'routeTo', ri: props.file['meta3:path'] + '/clone'})}>
            <i className="icon copy"></i>
          </button>
        </div>
      </div>
    );

    return (<div className="meta menu top component">
              {undoRedoItem}
              {actionsItem}

              {displaysItem}
              {showHiddenItem}
              {addItem}
              {editCssItem}
              {editProperties}
              {editHtmlItem}
              {previewItem}

              <div className="meta right menu">
                {testEmailItem}
                {saveDeleteItem}
              </div>
            </div>)
  }
});

export {Header};
