import {MC} from './MC.js';
import {MCCache} from "./MCCache.js";
import {MCHistory} from "./MCHistory.js";
import {MCBrws} from "./MCBrws.js";
import {Expression} from "./Expression.js";

let Flow = function(reactFlow, promiseObj, newPromise) {

  var self = this;
  this.instanceId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  if (reactFlow) {
    this.reactFlow = reactFlow;
  }
  if (promiseObj && newPromise) {
    this.promiseObject = promiseObj
    this.currentPromise = newPromise
  }
  if (!this.reactFlow && !this.promiseObject) {
    MC.error('React flow component or load promise must be set!');
  }
  this.parentFlow = null;
  this.flowId = null;
  this.flowName = null;
  this.flowdatatemplate = null;
  this.flowServerUrl = null;
  this.lang = null;
  this.flow = null;
  this.input = null;
  this.inputMapTrace = null;
  this.env = {};
  this.onEndFunction = null;
  this.onLeaveFlow = null;
  this.onNextForm = null;
  this.afterRenderForm = null;
  this.onSubmit = null;
  this.lazyAction = null;
  this.serverSide = false;
  this.cache = false;
  this.confPath = null;
  this.confNsMap = null;
  this.context = {data: {}};
  this.debug = false;
  this.serverLogLevel = '';
  this.logicTimers = {};
  this.lazyActionLogic = null;

  this.setParentFlow = function(pFlow) {
    this.parentFlow = pFlow;
    return this;
  };

  this.setFlowId = function(idval) {
    this.flowId = idval;
    return this;
  };

  this.setFlowConfiguration = function(vConfiguration, fConFlowName) {
    this.confPath = vConfiguration;
    this.flowName = fConFlowName;
    return this;
  };

  this.setFlowConfigurationProps = function(conf, fConfPath, fConFlowName, cNsMap) {
    this.conf = MC.extend(true, {}, conf);
    this.confPath = fConfPath;
    this.flowName = fConFlowName;
    this.confNsMap = cNsMap;
    return this;
  };

  this.setConfPath = function(fConfPath) {
    this.confPath = fConfPath;
    return this;
  };

  this.setFlowDataTemplate = function(val) {
    this.flowdatatemplate = val;
    return this;
  };

  this.setFlowServerUrl = function(val) {
    this.flowServerUrl = val;
    return this;
  };

  this.setLang = function(val) {
    this.lang = val;
    return this;
  };

  this.setInput = function(val) {
    this.input = val;
    return this;
  };

  this.setLazyAction = function(actionCode, logic) {
    this.lazyAction = actionCode;
    this.lazyActionLogic = logic;
    return this;
  };

  this.debugMode = function() {
    this.debug = true;
    return this;
  };

  this.setServerSide = function(iface) {
    this.serverSide = true;
    if (!MC.isNull(iface)) {
      var flow = {};
      flow.id = this.flowName;
      if (iface.input) {
        flow.input = iface.input;
      }
      if (iface.output) {
        flow.output = iface.output;
      }
      if (iface.exception) {
        flow.exception = iface.exception;
      }
      this.flow = flow;
      if (iface.cache === true) {
        this.cache = true;
      }
    }
    return this;
  };

  this.setOnEndFunction = function(val) {
    if (MC.isFunction(val)) {
      this.onEndFunction = val;
    } else {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', "Parameter of called 'setOnEndFunction' has to be function!");
    }
    return this;
  };

  this.setOnLeaveFunction = function(val) {
    if (MC.isFunction(val)) {
      this.onLeaveFlow = val;
    } else {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', "Parameter of called 'setOnLeaveFunction' has to be function!");
    }
    return this;
  };

  this.setOnNextFormFunction = function(val) {
    if (MC.isFunction(val)) {
      this.onNextForm = val;
    } else {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', "Parameter of called 'setOnNextFormFunction' has to be function!");
    }
    return this;
  };

  this.setAfterRenderFormFunction = function(val) {
    if (MC.isFunction(val)) {
      this.afterRenderForm = val;
    } else {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', "Parameter of called 'setAfterRenderFormFunction' has to be function!");
    }
    return this;
  };

  this.setOnSubmitFunction = function(val) {
    if (MC.isFunction(val)) {
      this.onSubmit = val;
    } else {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', "Parameter of called 'setOnSubmitFunction' has to be function!");
    }
    return this;
  };

  this.setEnv = function(env) {
    this.env = env;
  };

  this.setServerLogLevel = function(level) {
    const knownParams = ['', 'MINIMAL', 'BASIC', 'DETAIL', 'TRACE'];
    if (knownParams.indexOf(level) < 0) {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', `Parameter of called 'serverLogLevel' must be one of ${knownParams}!`);
    }
    this.serverLogLevel = level;
  };

  this.loadAndStart = function(input, request, inputMapTrace) {
    var self = this;
    if (!this.flowdatatemplate) {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', 'Flow data template URL must be set!');
      return;
    }
    if (!this.flowServerUrl) {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', 'Server flow interpreter URL must be set!');
      return;
    }
    if (!this.confPath) {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', 'Configuration path must be set!');
      return;
    }
    if (this.confPath && !this.flowId && !this.flowName) {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', 'Id or name of flow must be set when model is not empty!');
      return;
    }
    if (!this.lang) {
      this.endOperationException('SYS_UnrecoverableRuntimeExc', 'Lang must be set!');
      return;
    }
    if (!this.parentFlow && this.reactFlow) {
      this.reactFlow.setState({dimmer: true, runReady: false});
    }
    this.input = input;
    this.inputMapTrace = inputMapTrace;
    var origConf = this.conf || this.context.data['env/cfg'] || this.env.cfg;
    if (origConf) {
      this.loadAndStartStep1(request, origConf);
    } else {
      MC.getConfiguration(this.confPath, this.flowServerUrl, this.debug).then(function (conf) {
        self.loadAndStartStep1(request, conf);
      });
    }
  };

  this.getFlowDefinition = function() {
    var self = this;
    return new Promise(function(resolve, reject) {
      if (!MC.isNull(self.flow)) {
        resolve(self.flow);
      } else {
        var url = self.flowdatatemplate.replace('{configuration}', self.confPath).replace('{flowId}', self.flowId || '').replace('{flowName}', self.flowName || '').replace('{lang}', self.lang);
        var data = MCCache.get(url);
        if (data != null) {
          resolve(data);
        } else {
          MC.callServer('GET', url, MC.getJsonType()).then(function (result) {
            if (result.status == 200) {
              var def = JSON.parse(result.content);
              MCCache.put(url, def);
              resolve(def);
            } else {
              reject('Error in flow definition at RI ' + url  + '\n' + result.content);
            }
          }).catch(function (err) {
            if (navigator.onLine) {
              self.endOperationException('SYS_IntegrationExc', 'Reading flow definition failed for url ' + url + ': ' + err);
              return;
            } else {
              self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available for url ' + url + ': ' + err);
              return;
            }
          });
        }
      }
    });
  };

  this.loadAndStartStep1 = function(request, conf) {
    self = this;
    this.getFlowDefinition().then(function (data) {
      self.flow = data;
      if (self.serverSide || self.flow.kind == 'integration') {
        if (!self.parentFlow) {
          MCHistory.history(self, null, 'OPERATION START', {'Input': self.input});
        }
        self.runOnServer();
      } else {
        if (!self.parentFlow) {
          self.env.request = request;
          self.env.system = {};
          self.env.system.flowId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
          });
          self.env.system.correlationId = self.env.system.flowId;
          self.env.system.nodeName =  navigator.userAgent;
          self.env.system.flowConfig = self.confPath
          if (!MC.isNull(conf)) {
            MC.prepareNamespaces(conf, self.confNsMap);
            MC.translateNamespaces(conf, self.flow.ns);
            self.env.cfg = conf;
          }
        }
        self.env.operation = {};
        self.env.operation.operationName = self.flow.id;
        self.env.operation.rootOperationName = self.parentFlow ? self.parentFlow.flow.id : self.flow.id;
        self.env.ns = self.flow.ns;
        if (MC.isNull(self.env.context)) {
          MC.getEnvironmentContext(request, self.confPath, self.flowServerUrl, conf['fl:environmentOperation']).then(function(context) {
            self.env.context = context;
            if (!MC.isNull(context) && MC.isPlainObject(context)) {
              if (MC.isPlainObject(context.request) && !MC.isNull(context.request.language)) {
                self.setLang(context.request.language);
              }
              if (MC.isPlainObject(context.internalUser) && !MC.isNull(context.internalUser.internalUserId)) {
                self.env.system.userLoginId = context.internalUser.internalUserId;
              }
            }
            self.loadAndStartStep2();
          });
        } else {
          self.loadAndStartStep2();
        }
      }
    });
  };

  this.loadAndStartStep2 = function() {
    this.addToContext(self.context.data, 'env', this.env);
    if (!this.parentFlow) {
      MCHistory.logEnv(this.env, this.debug);
    }
    if (!this.flow.action && !this.parentFlow) {
      MCHistory.history(this, null, 'OPERATION START', {'Input': this.input});
    }
    if (!MC.isNull(this.flow.svl)) {
      this.addToContext(self.context.data, 'svl', this.flow.svl);
    }
    if (!MC.isNull(this.flow.vmt)) {
      this.addToContext(self.context.data, 'vmt', this.flow.vmt);
    }
    this.progress({'Input': this.input, 'Trace': this.inputMapTrace});
  };

  this.progress = function(logObject) {
    if (this.flow.mock && this.context.data["env/cfg"] && this.context.data["env/cfg"]["fl:useMocks"] == "true") {
      var mockOutput = this.runMock();
      if (mockOutput !== false) {
        this.endOperation(mockOutput);
        return;
      }
    }
    if (this.flow.kind == 'function') {
      this.runFunctionOperation();
    } else if (this.flow.kind == 'framework') {
      this.runFrameworkOperation();
    } else if (this.flow.action) {
      if (!this.context.nextAction) {
        this.context.nextAction = this.getStartAction();
      } else {
        MCHistory.history(this, this.context.action, null, logObject);
      }
      this.context.action = this.context.nextAction;
      this.context.nextAction = this.getActionById(this.context.action.nextaction);
      if (!this.context.nextAction && this.context.action.kind != 'end' && this.context.action.kind != 'decision' && !(this.context.action.kind == 'call' && this.context.action.leaveFlow)) {
        this.endOperationException('SYS_InvalidModelExc', this.context.action.kind + " action must have next action defined!");
        return;
      }
      var state = this.context.action.kind;
      switch (state) {
        case "start":
          this.startAction();
          break;
        case "form":
          this.formAction();
          break;
        case "call":
          this.callAction();
          break;
        case "decision":
          this.decisionAction();
          break;
        case "end":
          this.endAction();
          break;
        case "transform":
          this.transformAction();
          break;
        case "feedback":
          this.feedbackAction();
          break;
        default:
          this.endOperationException('SYS_InvalidModelExc', 'Unsupported action kind: "' + state + '"!');
          break;
      }
    } else {
      this.endOperationException('SYS_InvalidModelExc', "Operation has no action or has not supported operation type '" + this.flow.kind + "'!");
    }
  };

  this.progressLazyForm = function(formData, logObject, lazyAction, lazyActionLogic, deleteMode) {
    if (!MC.isNull(lazyAction)) {
      MCHistory.history(this, lazyAction, null, logObject);
    }
    if (!MC.isNull(this.context.dataActions)) {
      this.formData = formData;
      self.callAction(this.context.dataActions.pop());
    } else {
      delete this.context.dataActions;
      let [input, trace] = this.mapToResultObject(self.context.action.mapping, self.context, false);
      if (input) {
        self.setFormFields(formData, input, false, false, deleteMode);
      } else {
        return;
      }
      MCHistory.history(self, self.context.action, 'FORM RENDER', {'Input': input, 'Trace' : trace});
      if (MC.isFunction(self.onNextForm)) {
        var formTitle = formData.title;
        if (formData.param && formData.param['@title']) {
          formTitle = formData.param['@title'];
        }
        self.onNextForm({operation: self.flow.id, action: self.context.action.code, form: formData.id, formTitle: formTitle, formData: formData});
      }
      if (!MC.isNull(self.context.feedback)) {
        formData.feedback = self.context.feedback;
        self.context.feedback = [];
      }
      if(formData.flow && formData.flow.modelerReact) {
        formData.flow.modelerReact.resetStacks();
      }
      if (this.reactFlow) {
        let runReady = false;
        if (this.hardRenderMode) {
          runReady = true;
          this.hardRenderMode = false;
        }
        this.reactFlow.setState({state: 'form', formData: formData, dimmer: false, runReady: runReady});
        if (window && this.reactFlow.props.autoScrollUp !== false) {
          // scroll up
          window.scrollTo(0, 0);
        }
      }
      if (lazyActionLogic) {
        this.initLogicTimer(lazyActionLogic);
      }
      if (this.promiseObject) {
        this.promiseObject.resolve({flowInstance: this, formData: formData, debug: this.debug});
        this.promiseObject = null;
      }
    }
  };

  this.getStartAction = function() {
    if (this.flow.action) {
      for (var i=0; i < this.flow.action.length; i++) {
        if (this.flow.action[i].code == 'start') {
          return this.flow.action[i];
        }
      }
    }
    this.endOperationException('SYS_InvalidModelExc', "Can not find start action!");
    return null;
  };

  this.getActionById = function(id) {
    if (this.flow.action) {
      for (var i=0; i < this.flow.action.length; i++) {
        if (this.flow.action[i].id == id) {
          return this.flow.action[i];
        }
      }
    }
    return null;
  };

  this.getActionByCode = function(code) {
    if (this.flow.action) {
      for (var i=0; i < this.flow.action.length; i++) {
        if (this.flow.action[i].code == code) {
          return this.flow.action[i];
        }
      }
    }
    return null;
  };

  this.convertInputTreeData = function(inputTree, valueTree, contextTree) {
    if (Array.isArray(valueTree) && valueTree.length > 0) {
      valueTree = valueTree[0];
    }
    for (var i=0; i<inputTree.input.length; i++) {
      var param = inputTree.input[i];
      var key = param.name;
      if (key.endsWith('*')) {
        key = key.substring(0, key.length-1);
      }
      if (valueTree && !MC.isNull(valueTree[key])) {
        try {
          var values;
          var inValues = valueTree[key];
          if (param.name.endsWith('*')) {
            if (!Array.isArray(inValues)) {
              var arr = [];
              arr.push(inValues);
              inValues = arr;
            }
            values = [];
            for (var m=0; m<inValues.length; m++) {
              var value;
              if (param.input) {
                value = {};
                this.convertInputTreeData(param, inValues[m], value);
              } else {
                if (inValues[m].content) {
                  value = MC.normalizeValue(inValues[m].content, param.basictype);
                } else {
                  value = MC.normalizeValue(inValues[m], param.basictype);
                }
              }
              values.push(value);
            }
          } else {
            if (Array.isArray(inValues)) {
              inValues = inValues[0];
            }
            if (param.input) {
              values = {};
              this.convertInputTreeData(param, inValues, values);
            } else {
              if (inValues.content) {
                values = MC.normalizeValue(inValues.content, param.basictype);
              } else {
                values = MC.normalizeValue(inValues, param.basictype);
              }
            }
          }
          contextTree[param.name] = values;
        } catch (e) {
          e.message = 'Error parsing operation input: ' + e.message;
          this.endOperationException('SYS_MappingExc', e);
          return false;
        }
      } else {
        if (param.mandat) {
          this.endOperationException('SYS_MappingExc', "Input parameter '" + param.name + "' must have value!");
          return false;
        }
      }
    }
    return true;
  };

  this.addToContext = function(context, actionCode, object) {
    if (MC.isNull(object)) {
      return;
    }
    if (Array.isArray(object)) {
      object.forEach((item) => {
        for (key in item) {
          if (!Array.isArray(context[actionCode + '/' + key])) {
            context[actionCode + '/' + key] = [];
          }
          context[actionCode + '/' + key].push(item[key]);
        }
      });
    } else {
      for (let key in object) {
        context[actionCode + '/' + key] = object[key];
      }
    }
  };

  this.startAction = function() {
    if (this.flow.input && Array.isArray(this.flow.input)) {
      var result = {};
      if (this.convertInputTreeData(this.flow, this.input, result)) {
        this.addToContext(self.context.data, 'start', result);
        this.progress({'Input': this.input, 'Output': result});
      }
    } else {
      this.progress({'Input': this.input});
    }
  };

  this.findAllLazyDataActions = function(field, result) {
    if (!result) {
      result = [];
    }
    if (field.fields) {
      for (var i = 0; i < field.fields.length; i++) {
        var subField = field.fields[i];
        var actionInParam = MC.getFieldParamValue(field.param, '@dataAction', null);
        if (!MC.isNull(actionInParam)) {
          result.push(actionInParam);
        } else {
          result = this.findAllLazyDataActions(subField, result);
        }
      }
    }
    return result;
  };

  this.formAction = function() {
    var action = this.context.action;
    var formId = action.form;
    this.context.data['@lastFormAction'] = this.context.action.code;
    if (!formId) {
      this.endOperationException('SYS_InvalidModelExc', "Form action '" + this.context.action.code + "' has no form selected!");
      return;
    }
    var form = this.flow.form[formId];
    var dataActions = this.findAllLazyDataActions(form);
    if (!MC.isNull(dataActions)) {
      this.context.dataActions = dataActions;
    }
    this.hardRenderMode = true;
    this.progressLazyForm(this.prepareFormData(MC.extend(true, {}, form), formId), null, null, null, false);
  };

  this.prepareFormData = function(formData, formId) {
    formData.formId = formId;
    formData.model = this.flow.model;
    formData.lang = this.lang;
    formData.onSubmit = this.submitForm;
    formData.flow = this;
    if (this.flow.progressBar) {
      formData.progressBar = this.flow.progressBar;
      formData.progressBar.active = this.context.action.code;
    }
    return formData;
  };

  this.submitForm = function(triggeredByField, allValues, repeaterRows, submitAction) {
    this.clearLogicTimers();
    if (triggeredByField && MC.getFieldParamBooleanValue(triggeredByField.param, '@confirm', repeaterRows)) {
      let message = MC.getFieldParamValue(triggeredByField.param, '@confirmMessage', repeaterRows);
      if (!message) {
        message = MC.formatMessage("confirm");
      }
      this.reactFlow.setState({message: {heading: message, size: 'tiny', onClose: this.cancelSubmitForm, buttons: [
            {title: "OK", class: "green", icon: "checkmark icon", action: this.confirmSubmitForm.bind(this, triggeredByField, allValues, repeaterRows, submitAction)},
            {title: MC.formatMessage("cancel"), class: "orange",  icon: "cancel icon", action: this.cancelSubmitForm}
          ]}, runReady: false});
    } else {
      this.confirmSubmitForm(triggeredByField, allValues, repeaterRows, submitAction);
    }
  };

  this.confirmSubmitForm = function(triggeredByField, allValues, repeaterRows, submitAction) {
    var self = this;
    var action = this.context.action;
    var output = {};
    this.mapFormOutput(this.reactFlow.state.formData, output, triggeredByField, allValues, 'no', repeaterRows, false, submitAction);
    this.clearActionContext(action.code);
    this.addToContext(this.context.data, action.code, output);
    this.reactFlow.setState({dimmer: this.reactFlow.state.loader == 'all', runReady: false, message: null});
    if (MC.isFunction(this.onSubmit)) {
      if (this.currentPromise) {
        this.currentPromise = this.currentPromise.then(() => {
          const promise = new Promise(function (resolve, reject) {
            self.promiseObject = {resolve: resolve, reject: reject}
          })
          this.onSubmit(promise)
          this.progress({'Output': output})
          return promise
        })
      } else {
        const promise = new Promise(function (resolve, reject) {
          self.promiseObject = {resolve: resolve, reject: reject}
        })
        this.currentPromise = promise
        this.onSubmit(promise)
        this.progress({'Output': output})
      }
    } else {
      this.progress({'Output': output})
    }
  };

  this.cancelSubmitForm = () => {
    this.reactFlow.setState({message: null});
  };

  this.eventForm = function(triggeredByField, event, repeaterRow, target, receivedData) {
    var self = this;
    var formData = self.reactFlow.state.formData;
    var logic = [];
    if (!MC.isNull(formData) && Array.isArray(formData.logic)) {
      for (var i = 0; i < formData.logic.length; i++) {
        var actl =  formData.logic[i];
        if (Array.isArray(actl.event)) {
          for (var e = 0; e < actl.event.length; e++) {
            if (actl.event[e]['e'] == event && (Array.isArray(actl.event[e]['f']) && actl.event[e]['f'].indexOf(triggeredByField.rbsid) > -1 || MC.isNull(actl.event[e]['f']))) {
              if (logic.indexOf(actl) == -1) {
                if (event == 'click') {
                  var behavior = MC.getFieldParamValue(triggeredByField.param, '@behavior', repeaterRow);
                  if (!MC.isNull(behavior) && behavior !== '' && behavior !== 'formlogic') {
                    continue;
                  }
                }
                logic.push(actl);
              }
            }
          }
        }
        if (event === actl.name) { // call from timer
          if (logic.indexOf(actl) == -1) {
            logic.push(actl);
          }  
        }
      }
    }
    if (logic.length > 0) {
      var context = {};
      context['@event'] = event;
      context['@widgetName'] = triggeredByField ? triggeredByField.id : formData.name;
      context['@widgetPath'] = self.getFormFieldPath(formData, triggeredByField, '');
      context['@widgetIndex'] = repeaterRow;
      context['@widgetTarget'] = target;
      context['form'] = {};
      self.mapFormOutput(formData, context['form'], triggeredByField, true, 'no', null, false, null);
      context['form']['data'] = formData.data;
      context['env'] = {};
      context['svl'] = {};
      context['vmt'] = {};
      for (var prop in self.context.data) {
        if (prop.startsWith('env/')) {
          context['env'][prop.substring(4)] = self.context.data[prop];
        } else if (prop.startsWith('svl/') || prop.startsWith('vmt/')) {
          context[prop] = self.context.data[prop];
        }
      }
      if (!MC.isNull(receivedData) && MC.isPlainObject(receivedData)) {
        context['eventData'] = receivedData;
      }
      if (!triggeredByField) {
        triggeredByField = true;
      }
      loopLogics:
      for (var l = 0; l < logic.length; l++) {
        var condLog = [];
        if (Array.isArray(logic[l].condition) && logic[l].condition.length > 0) {
          for (var i = 0; i < logic[l].condition.length; i++) {
            var expression = new Expression(logic[l].condition[i], context, {singleRoot: true});
            var res = expression.evaluate();
            if (expression.getError()) {
              this.endOperationException('SYS_MappingExc', expression.getError());
              return false;
            }
            condLog.push(res);
            if (!res) {
              MCHistory.history(self, self.context.action, 'FORM LOGIC ' + logic[l].name, {'Input': context, 'Evaluated conditions': condLog.length > 0 ? condLog : null});
              this.initLogicTimer(logic[l]);
              continue loopLogics;
            }
          }
        }
        if (!MC.isNull(logic[l].dataAction)) {
          var dRes = self.callLogicAction(logic[l], triggeredByField, repeaterRow);
          if (!MC.isNull(dRes)) {
            self.reactFlow.setState({dialog: {flowName: dRes.flowName, input: dRes.input, trace: dRes.trace, actionCode: logic[l].dataAction, triggeredByField: triggeredByField, iteration: repeaterRow,
              size: dRes.size, start: true, parentFlow: this, logic: logic[l]}, runReady: false});
          }
        }
        var logContext = MC.extend(true, {}, context);
        let [input, trace] = self.mapToResultObject(logic[l].mapping, {data: context}, false, triggeredByField);
        MCHistory.history(self, self.context.action, 'FORM LOGIC ' + logic[l].name, {'Input': logContext, 'Evaluated conditions': condLog.length > 0 ? condLog : null, 'Output': input, 'Trace' : trace});
        var behavior = logic[l].behavior;
        if (!MC.isNull(logic[l].behavior)) {
          if ('submit' == behavior) {
            this.focusedOnFirst = false;
            MC.validateFieldTree(this.reactFlow.state.formData, repeaterRow).then(function (valid) {
              if (valid) {
                self.submitForm(triggeredByField, true, repeaterRow, logic[l].name);
              } else {
                self.reactFlow.forceUpdate();
                this.initLogicTimer(logic[l]);
              }
            }).catch(function (exception) {
              if (MC.isPlainObject(exception) && !MC.isNull(exception.type)) {
                this.endOperationException(exception.type, exception.message, exception.input, exception.output, exception.log);
              } else {
                this.endOperationException('SYS_UnrecoverableRuntimeExc', exception);
              }
            });
          } else {
            this.submitForm(triggeredByField, (behavior == 'cancel') ? false : true, repeaterRow, logic[l].name);
          }
          return;
        } else if (MC.isNull(logic[l].dataAction)) {
          this.initLogicTimer(logic[l]);
        }
      }
    }
  };

  this.endDialog = function(output, message) {
    var actionCode = this.reactFlow.state.dialog.actionCode;
    var input = this.reactFlow.state.dialog.input;
    var submitOpts = null;
    var dAction = this.getActionByCode(actionCode);
    if (dAction.submitParent) {
      submitOpts = {};
      submitOpts.triggeredByField = this.reactFlow.state.dialog.triggeredByField;
      submitOpts.iteration = this.reactFlow.state.dialog.iteration;
    }
    let logic = this.reactFlow.state.dialog.logic
    this.reactFlow.setState({dialog: null});
    if (!MC.isNull(message)) {
      this.endOperationException(output, message, input, null, null);
    } else {
      this.endEmbeddedDialog(actionCode, input, output, submitOpts, logic);
    }
  };

  this.endEmbeddedDialog = function(actionCode, input, output, submitOpts, logic) {
    this.clearActionContext(actionCode);
    this.addToContext(this.context.data, actionCode, output);
    this.progressLazyForm(this.reactFlow.state.formData, {'Input': input, 'Output': output}, null, logic, true);
    if (submitOpts) {
      this.submitForm(submitOpts.triggeredByField, true, submitOpts.iteration, null);
    }
  };

  this.paginateForm = function(actionCode) {
    var action = this.context.action;
    var output = {};
    this.mapFormOutput(this.reactFlow.state.formData, output, null, true, 'no', null, false, null);
    this.clearActionContext(action.code);
    this.addToContext(this.context.data, action.code, output);
    this.reactFlow.setState({dimmer: this.reactFlow.state.loader == 'all', runReady: false});
    this.callAction(actionCode);
  };

  this.isCorrespondingRepeater = function(definition, triggeredByField) {
    if (definition == triggeredByField) {
      return true
    } else if (definition.fields && definition.fields.length > 0) {
      for (var i=0; i<definition.fields.length; i++) {
        if (this.isCorrespondingRepeater(definition.fields[i], triggeredByField)) {
          return true
        }
      }
    }
    return false
  }

  this.mapFormOutput = function(definition, contextTree, triggeredByField, allValues, iterations, repeaterRows, isRightRepeater, submitAction) {
    if (definition.formId) { // is form root
      if (triggeredByField) {
        contextTree['@submitTrigger'] = triggeredByField.id
      }
      if (submitAction) {
        contextTree['@submitAction'] = submitAction
      }
    }
    if (definition.id === 'rows*') {
      if (Array.isArray(repeaterRows) && (!Array.isArray(iterations) || iterations.length < repeaterRows.length)) {
        let iterationsToPass = Array.isArray(iterations) ? repeaterRows.slice(0, iterations.length+1) : [repeaterRows[0]]
        this.mapFormOutputStep(definition, contextTree, triggeredByField, allValues, iterationsToPass, repeaterRows, isRightRepeater)
      } else {
        let count = MC.getRowsCount(definition, iterations, 0)
        for (var i=0; i<count; i++) {
          let iterationsToPass = Array.isArray(iterations) ? [...iterations, i] : [i]
          this.mapFormOutputStep(definition, contextTree, triggeredByField, allValues, iterationsToPass, true)
        }
      }
    } else {
      this.mapFormOutputStep(definition, contextTree, triggeredByField, allValues, iterations, repeaterRows, isRightRepeater)
    }
  }

  this.setParamInFormOutput = function(object, prop, valueObj, iterations) {
    if (MC.isPlainObject(valueObj)) {
      object[prop] = {};
      for (var propIn in valueObj) {
        var param = valueObj[propIn];
        if (Array.isArray(param) && Array.isArray(iterations)) {
          this.setParamInFormOutput(object[prop], propIn, MC.getFieldParamValue(valueObj, propIn, iterations));
        } else {
          this.setParamInFormOutput(object[prop], propIn, param, iterations);
        }
      }
    } else {
      if (Array.isArray(iterations) && Array.isArray(object)) {
        object[iterations[iterations.length - 1]][prop] = valueObj;
      } else {
        object[prop] = valueObj;
      }
    }
  };

  this.mapFormOutputStep = function(definition, contextTree, triggeredByField, allValues, iterations, repeaterRow, isRightRepeater) {
    for (var i=0; i<definition.fields.length; i++) {
      var isRightRepeaterSub = isRightRepeater;
      var field = definition.fields[i];
      var values = {};
      if (field.fields && field.fields.length > 0) {
        if (field.id === 'rows*') {
          isRightRepeaterSub = isRightRepeater || this.isCorrespondingRepeater(field, triggeredByField);
          if (!Array.isArray(repeaterRow) || isRightRepeaterSub) {
            values = [];
            this.mapFormOutput(field, values, triggeredByField, allValues, iterations, repeaterRow, isRightRepeaterSub, null);
          }
        } else {
          this.mapFormOutput(field, values, triggeredByField, allValues, iterations, repeaterRow, isRightRepeater, null);
        }
      }
      let enabled = field.param && field.param['@enabled'];
      if ((!Array.isArray(repeaterRow) || isRightRepeater || isRightRepeaterSub) && allValues && enabled) {
        if (field.id !== 'rows*') {
          for (var prop in field.param) {
            var param = field.param[prop];
            if (Array.isArray(param) && Array.isArray(iterations)) {
              this.setParamInFormOutput(values, prop, MC.getFieldParamValue(field.param, prop, iterations), iterations);
            } else {
              this.setParamInFormOutput(values, prop, param, iterations);
            }
          }
        } else {
          if (Array.isArray(repeaterRow)) {
            for (var prop in field.param) {
              var param = field.param[prop];
              if (field.id === 'rows*') {
                if (values[0]) {
                  if (Array.isArray(param)) {
                    this.setParamInFormOutput(values, prop, MC.getFieldParamValue(field.param, prop, repeaterRow), [0]);
                  } else {
                    this.setParamInFormOutput(values, prop, param, [0]);
                  }
                }
              } else {
                this.setParamInFormOutput(values, prop, param, iterations);
              }
            }
            if (field.id === 'rows*') {
              values['@submittedRowIndex'] = repeaterRow[0] + 1;
            }
          } else {
            for (var prop in field.param) {
              var param = field.param[prop];
              if (field.id === 'rows*') {
                let count = MC.getRowsCount(field, iterations, 0)
                for (var r = 0; r < count; r++) {
                  if (Array.isArray(param)) {
                    this.setParamInFormOutput(values, prop, param[r], [r]);
                  } else {
                    this.setParamInFormOutput(values, prop, param, [r]);
                  }
                }
              } else {
                this.setParamInFormOutput(values, prop, param, iterations);
              }
            }
          }
        }
        if (field.scriptedWidget && field.scriptedWidgetObject) {
          if (MC.isFunction(field.scriptedWidgetObject.getValue)) {
            var value = field.scriptedWidgetObject.getValue();
            for (prop in value) {
              values[prop] = value[prop];
            }
          }
        }
      }
      if (field == triggeredByField) {
        values['@submittedBy'] = true;
      }
      if (!MC.isNull(values)) {
        if (Array.isArray(values)) {
          for (var v=0; v<values.length; v++) {
            values[v]['@isField'] = true;
          }
        } else {
          values['@isField'] = true;
        }
        if (definition.id !== 'rows*') {
          contextTree[field.id] = values;
        } else {
          if (Array.isArray(iterations)) {
            var index = iterations[iterations.length - 1];
            if (Array.isArray(repeaterRow)) {
              index = 0;
            }
            if (!contextTree[index]) {
              contextTree[index] = {};
            }
            contextTree[index][field.id] = values;
          }
        }
      }
    }
  };

  this.getFormFieldPath = function(definition, triggeredByField, path) {
    for (var i = 0; i < definition.fields.length; i++) {
      var field = definition.fields[i];
      var subpath = path + (path === '' ? '' : '/') + field.id;
      if (field == triggeredByField) {
        return subpath;
      } else {
        var subres = this.getFormFieldPath(field, triggeredByField, subpath);
        if (subres !== null) {
          return subres;
        }
      }
    }
    return null;
  };

  this.setFormFields = function(definition, valueObj, rows, totalSize, deleteMode) {
    if (valueObj == null || valueObj == undefined) {
      return;
    }
    if (Array.isArray(valueObj) && definition.id === 'rows*') {
      for (var i=0;  i<valueObj.length; i++) {
        let rowsTopass = (rows) ? [...rows, i] : [i]
        this.setFormFields(definition, valueObj[i], rowsTopass, valueObj.length, deleteMode);
      }
    } else if (Array.isArray(valueObj) || !MC.isPlainObject(valueObj)) {
      this.putValueToFormList(definition.param, 'value', valueObj, rows, totalSize, deleteMode);
    } else {
      for (var target in valueObj) {
        var value = valueObj[target];
        if (target == 'data') {
          if (!MC.isPlainObject(definition.data)) {
            definition.data = {};
          }
          this.putValueToFormList(definition, 'data', value, false, false, deleteMode);
          continue;
        }
        var subField = null;
        for (var i=0; i<definition.fields.length; i++) {
          if (definition.fields[i].id == target) {
            subField = definition.fields[i];
            break;
          }
        }
        if (subField) {
          this.setFormFields(subField, value, rows, totalSize, deleteMode);
        } else {
          if (definition.scriptedWidget) {
            definition.param[target] = value;
          } else {
            this.putValueToFormList(definition.param, target, value, rows, totalSize, deleteMode);
          }
        }
      }
    }
  };

  this.makeFormListArray = function(value) {
    if (Array.isArray(value)) {
      var result = {};
      for (var i = 0; i < value.length; i++) {
        var item = value[i];
        if (MC.isPlainObject(item)) {
          for (var param in item) {
            if (MC.isNull(item[param]) && value.length == 1) {
              result[param] = null;
            } else {
              if (!Array.isArray(result[param]) || i === 0) {
                result[param] = [];
              }
              result[param].push(item[param]);
            }
          }
        } else {
          if (MC.isNull(item) && value.length == 1) {
            result = null;
          } else {
            if (!Array.isArray(result) || i === 0) {
              result = [];
            }
            result.push(item);
          }
        }
      }
      return result;
    } else {
      return value;
    }
  };

  this.putValueToFormList = function(param, target, value, rows, totalSize, deleteMode) {
    if (target.endsWith('*')) {
      value = this.makeFormListArray(value);
    }
    if (MC.isPlainObject(value)) {
      if (!param[target] || !MC.isPlainObject(param[target])) {
        param[target] = {};
      }
      for (var prop in value) {
        if (MC.isPlainObject(value[prop])) {
          this.putValueToFormList(param[target], prop, value[prop], rows, totalSize, deleteMode);
        } else {
          if (Array.isArray(rows)) {
            param[target][prop] = MC.putValueIntoMultiArray(param[target][prop], rows, totalSize, value[prop])
          } else {
            if (Array.isArray(value[prop]) && value[prop].length == 1 && MC.isNull(value[prop][0]) && deleteMode) {
              param[target][prop] = null;
            } else {
              if (deleteMode || !MC.isNull(value[prop])) {
                param[target][prop] = value[prop];
              }
            }
          }
        }
      }
    } else if (Array.isArray(value)) {
      for (var i=0; i<value.length; i++) {
        this.putValueToFormList(param, target, value[i], i, value.length, deleteMode);
      }
    } else {
      if (Array.isArray(rows)) {
        param[target] = MC.putValueIntoMultiArray(param[target], rows, totalSize, value)
      } else {
        if (Array.isArray(value) && value.length == 1 && MC.isNull(value[0]) && deleteMode) {
          param[target] = null;
        } else {
          if (deleteMode || !MC.isNull(value)) {
            param[target] = value
          }
        }
      }
    }
  };

  this.getFormFieldByPath = function(path, formDefinition) {
    if (!Array.isArray(path)) {
      path = path.split('/');
    }
    if (!formDefinition.fields || formDefinition.fields.length < 1) {
      return formDefinition;
    }
    if (path[0].endsWith('*')) {
      path[0] = path[0].substring(0, path[0].length-1);
    }
    var field;
    for (var i=0; i<formDefinition.fields.length; i++) {
      if (formDefinition.fields[i].id == path[0]) {
        field = formDefinition.fields[i];
        if (path.length > 1) {
          path.shift();
          return this.getFormFieldByPath(path, field);
        } else {
          return field;
        }
      }
    }
    return formDefinition;
  };

  this.getFormFieldInnerPath = function(path, formDefinition) {
    if (!Array.isArray(path)) {
      path = path.split('/');
    }
    if (!formDefinition.fields || formDefinition.fields.length < 1) {
      return path.join('/');
    }
    var path0 = path[0];
    if (path0.endsWith('*')) {
      path0 = path0.substring(0, path0.length-1);
    }
    var field;
    for (var i=0; i<formDefinition.fields.length; i++) {
      if (formDefinition.fields[i].id == path0) {
        field = formDefinition.fields[i];
        if (path.length > 1) {
          path.shift();
          return this.getFormFieldInnerPath(path, field);
        } else {
          return path.join('/');
        }
      }
    }
    return path.join('/');
  };

  this.convertMockTreeData = function(outputTree, mockTree, contextTree, actionCode) {
    for (var i=0; i<outputTree.output.length; i++) {
      var param = outputTree.output[i];
      var key = param.name;
      if (key.endsWith('*')) {
        key = key.substring(0, key.length-1);
      }
      if (!MC.isNull(mockTree[key])) {
        var values;
        var mockValues = mockTree[key];
        if (Array.isArray(mockValues)) {
          var values = [];
          for (var m=0; m<mockValues.length; m++) {
            var value;
            if (param.output) {
              value = {};
              this.convertMockTreeData(param, mockValues[m], value, false);
            } else {
              if (mockValues[m].content) {
                value = MC.normalizeValue(mockValues[m].content, param.basictype);
              } else {
                value = MC.normalizeValue(mockValues[m], param.basictype);
              }
            }
            values.push(value);
          }
        } else {
          if (param.output) {
            values = {};
            this.convertMockTreeData(param, mockValues, values, false);
          } else {
            if (mockValues.content) {
              values =  MC.normalizeValue(mockValues.content, param.basictype);
            } else {
              values =  MC.normalizeValue(mockValues, param.basictype);
            }
          }
        }
        if (actionCode) {
          contextTree[actionCode + '/' + param.name] = values;
        } else {
          contextTree[param.name] = values;
        }
      } else {
        if (param.mandat) {
          this.endOperationException('SYS_MappingExc', "Output parameter '" + param.name + "' must have value!");
        }
      }
    }
  };

  this.callLogicAction = function(logic, triggeredByField, repeaterRows, dialog) {
    var action = this.getActionByCode(logic.dataAction);
    if (!action) {
      this.endOperationException('SYS_InvalidModelExc', "Action with code '" + logic.dataAction + "' not found!");
      return;
    }
    if (action.kind != 'dialog' && action.kind != 'call') {
      this.endOperationException('SYS_InvalidModelExc', "Action '" + logic.dataAction + "' is not dialog or call action!");
      return;
    }
    var formOutput = {};
    this.mapFormOutput(this.reactFlow.state.formData, formOutput, triggeredByField, true, 'no', repeaterRows, false, logic.name ? logic.name : null);
    this.clearActionContext(this.context.action.code);
    this.addToContext(this.context.data, this.context.action.code, formOutput);
    if (action.kind == 'dialog') {
      var flowName = action.calls;
      if (!flowName) {
        this.endOperationException('SYS_InvalidModelExc', "Action '" + logic.dataAction + "' calls no operation!");
        return;
      }
      let [input, trace] = this.mapToResultObject(action.mapping, this.context, true);
      input = MC.stripStars(input);
      return {flowName: flowName, input: input, size: action.dialogSize ? action.dialogSize : null, trace: trace};
    } else if (dialog) {
      this.endOperationException('SYS_InvalidModelExc', "Action '" + logic.dataAction + "' is not dialog action!");
      return;
    } else {
      this.callAction(logic.dataAction, logic);
      return null;
    }
  };

  this.callAction = function(code, logic) {
    var self = this;
    var action = this.context.action;
    if (code) {
      var action = this.getActionByCode(code);
      if (action == null) {
        this.endOperationException('SYS_InvalidModelExc', "Lazy data action with code '" + code + "' not found!");
        return;
      }
    }
    var calledActionId = action.calls;
    var callsExternal = action.callsExternal;
    if (!calledActionId && !callsExternal) {
      this.endOperationException('SYS_InvalidModelExc', "Call action '" + this.context.action.code + "' calls no operation, not supported!");
      return;
    }
    let input = null;
    let trace = null; 
    if (action.multiCall && this.multiInput && this.multiInput.length > 0) {
      input = this.multiInput.shift(); 
    } else {
      [input, trace] = this.mapToResultObject(action.mapping, this.context, true);
    }
    if (input) {
      if (action.leaveFlow) {
        this.leaveOperation(callsExternal, calledActionId, input, trace);
      } else {
        if (action.multiCall) {
          if (!this.multiInput) {
            if (input['input*'] && Array.isArray(input['input*']) && input['input*'].length > 0) {
              this.multiInputOrig = input;
              this.multiInput = MC.extend(true, [], input['input*']);
              this.multiTrace = trace;
              input = this.multiInput.shift();
            } else {
              this.clearActionContext(action.code);
              this.progress({'Input': input, 'Trace': trace});
              return;
            }
          }
        }
        const flow = new Flow(this.reactFlow, this.promiseObject, this.currentPromise);
        flow.setLang(self.lang).setConfPath(this.confPath).setParentFlow(self)
          .setFlowDataTemplate(this.flowdatatemplate).setFlowServerUrl(this.flowServerUrl);
        if (callsExternal) {
          if (action.interface.isFrontend) {
            flow.setFlowId(null);
            flow.setFlowConfiguration(this.confPath, callsExternal);
          } else {
            flow.setFlowConfigurationProps(this.context.data['env/cfg'], this.confPath, callsExternal, this.confNsMap);
            flow.setServerSide(action.interface);
          }
        } else {
          flow.setFlowId(calledActionId);
        }
        if (code) {
          flow.setLazyAction(action, logic);
        }
        if (MC.isFunction(self.onNextForm)) {
          flow.setOnNextFormFunction(self.onNextForm);
        }
        if (MC.isFunction(self.afterRenderForm)) {
          flow.setAfterRenderFormFunction(self.afterRenderForm);
        }
        input = MC.stripStars(input);
        flow.setEnv(this.env);
        if (self.debug) {
          flow.debugMode();
        }
        flow.setServerLogLevel(this.serverLogLevel);
        flow.loadAndStart(input, null, trace);
      }
    }
  };

  this.runOnServer = function() {
    var input = {};
    if (this.flow.input && Array.isArray(this.flow.input)) {
      if (this.convertInputTreeData(this.flow, this.input, input)) {
        input = MC.stripStars(input);
      } else {
        return;
      }
    }
    var url = this.flowServerUrl + '?start=true&interactive=false&flowconfig=' + this.confPath;
    if (this.flowId) {
      url += '&operationid=' + this.flowId;
    } else {
      url += '&operationname=' + this.flowName;
    }
    if (this.debug) {
      url += '&includeid=true&loggingthreshold=' + this.serverLogLevel;
    }
    let method = 'POST';
    let content = JSON.stringify(input);
    if (this.cache) {
      method = 'GET'
      url += '&inputdata=' + encodeURIComponent(JSON.stringify(input))
      content = null;
    }
    MC.callServer(method, url, MC.getJsonType(), content, MC.getJsonType()).then(function (res) {
      try {
        var output = {};
        var content = res.content;
        var log = null;
        if (content) {
          output = JSON.parse(content);
          if (!MC.isNull(output.flowId) && !MC.isNull(output.flowLogId)) {
            log = {flowId: output.flowId, flowLogId: output.flowLogId};
            delete output.flowId;
            delete output.flowLogId;
          }
        }
        if (res.status == 200 || res.status == 204) {
          self.endOperation(output, log);
        } else {
          var type = 'SYS_IntegrationExc';
          if (output.errorName) {
            type = output.errorName;
          }
          var message = 'Calling server flow failed for url ' + url  + '! Status:' + res.status;
          if (output.errorMessage) {
            message = output.errorMessage;
          }
          self.endOperationException(type, message, input, output, log);
          return;
        }
      } catch (e) {
        self.endOperationException('SYS_IntegrationExc', res.content, input, null, null);
        return;
      }
    }).catch(function (err) {
      if (navigator.onLine) {
        self.endOperationException('SYS_IntegrationExc', 'Calling server flow failed for url ' + url + ': ' + err, input);
        return;
      } else {
        self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available for url ' + url + ': ' + err);
        return;
      }
    });
  };

  this.convertOutputTreeData = function(outputTree, valueTree, contextTree) {
    if (Array.isArray(valueTree) && valueTree.length > 0) {
      valueTree = valueTree[0];
    }
    for (var i=0; i<outputTree.output.length; i++) {
      var param = outputTree.output[i];
      var key = param.name;
      var keyNoStar = (key.endsWith('*') ? key.substring(0, key.length-1) : key);
      if (!MC.isNull(valueTree[key]) || !MC.isNull(valueTree[keyNoStar])) {
        try {
          var values;
          var inValues = valueTree[key];
          if (!inValues) {
            inValues = valueTree[keyNoStar];
          }
          if (key.endsWith('*')) {
            if (!Array.isArray(inValues)) {
              var arr = [];
              arr.push(inValues);
              inValues = arr;
            }
            values = [];
            for (var m=0; m<inValues.length; m++) {
              var value;
              if (param.output) {
                value = {};
                if (!this.convertOutputTreeData(param, inValues[m], value)) {
                  return false
                }
              } else {
                value = MC.normalizeValue(inValues[m], param.basictype);
              }
              values.push(value);
            }
          } else {
            if (Array.isArray(inValues)) {
              inValues = inValues[0];
            }
            if (param.output) {
              values = {};
              if (!this.convertOutputTreeData(param, inValues, values)) {
                return false
              }
            } else {
              values = MC.normalizeValue(inValues, param.basictype);
            }
          }
          contextTree[param.name] = values;
        } catch (e) {
          e.message = 'Error building operation output: ' + e.message
          this.endOperationException('SYS_MappingExc', e)
          return false
        }
      } else {
        if (param.mandat) {
          this.endOperationException('SYS_MappingExc', "Output parameter '" + param.name + "' must have value!")
          return false
        }
      }
    }
    return true
  }

  this.decisionAction = function() {
    try {
      var action = this.context.action;
      if (!action.branch) {
        this.endOperationException('SYS_InvalidModelExc', 'Decision action "' + action.code + '" must have at least one branch defined!');
        return;
      }
      var testedBranches = {};
      for (var i = 0; i < action.branch.length; i++) {
        var branch = action.branch[i];
        var passed = true;
        if (branch.expr && Array.isArray(branch.expr)) {
          for (var e = 0; e < branch.expr.length; e++) {
            var expression = new Expression(branch.expr[e], this.context.data);
            var res = expression.evaluate();
            if (expression.getError()) {
              MC.error(expression.getError());
            }
            if (!res) {
              passed = false;
              break;
            }
          }
          if (this.debug) {
            testedBranches[branch.name] = {result: res, trace: expression.getTrace()};
          }
        }
        if (passed) {
          if (!branch.nextaction) {
            this.endOperationException('SYS_InvalidModelExc', 'Branch "' + branch.name + '" of decision action "' + action.code + '" must have next action defined!');
            return;
          }
          this.context.nextAction = this.getActionById(branch.nextaction);
          var result = {};
          result['branch'] = branch.name;
          result['action'] = this.context.nextAction.code;
          this.progress({'Result': result, 'Tested branches': testedBranches});
          return;
        }
      }
      this.endOperationException('SYS_MappingExc', 'No branch of decision action "' + action.code + '" passed!');
      return;
    } catch (ex) {
      self.endOperationException('SYS_MappingExc', ex.message);
    }
  };

  this.endAction = function() {
    if (this.context.action.throwsException) {
      MCHistory.history(this, this.context.action, null);
      this.endOperationException(this.context.action.throwsException.name, "End action " + this.context.action.code + " with exception.");
    } else {
      let [output, trace] = this.mapToResultObject(this.context.action.mapping, this.context, true);
      if (output) {
        MCHistory.history(this, this.context.action, null, {'Output': output, 'Trace' : trace});
        this.endOperation(output, null, this.context.action.redirect);
      }
    }
  };

  this.endOperation = function(unorderedOutput, log, redirect) {
    var output = {};
    if (redirect) {
      output = unorderedOutput;
    } else if (unorderedOutput && this.flow.output) {
      if (!this.convertOutputTreeData(this.flow, unorderedOutput, output)) {
        return false
      }
    }
    if (!this.parentFlow) {
      output = MC.stripStars(output);
      if (!this.flow.action || this.serverSide) {
        MCHistory.history(this, null, 'OPERATION END', {'Output': output, 'Server log': log});
      }
      if (MC.isFunction(this.onSubmit) && this.promiseObject) {
        this.promiseObject.resolve(output);
        this.promiseObject = null;
        this.currentPromise = null;
      } else if (this.promiseObject) {
        this.promiseObject.resolve({flowInstance: this, debug: this.debug, output: output});
        this.promiseObject = null;
        this.currentPromise = null;
      } else if (MC.isFunction(this.onEndFunction)) {
        if (this.reactFlow && this.reactFlow.props.clearStateOnEnd) {
          this.reactFlow.setState({state: null, dimmer: false, runReady: false});
        }
        this.onEndFunction(output, undefined, redirect);
      } else  {
        this.showOutput(output);
      }
    } else {
      if (!MC.isNull(this.lazyAction)) {
        this.parentFlow.clearActionContext(this.lazyAction.code);
        if (unorderedOutput && this.flow.output) {
          this.parentFlow.addToContext(this.parentFlow.context.data, this.lazyAction.code, output);
        }
        const formData = this.parentFlow.formData ? this.parentFlow.formData : this.parentFlow.reactFlow.state.formData;
        this.parentFlow.progressLazyForm(formData, {'Input': this.input, 'Output': output, 'Server log': log, 'Trace': this.inputMapTrace}, this.lazyAction, this.lazyActionLogic, true);
      } else {
        if (['framework', 'function'].indexOf(this.flow.kind) > -1) {
          MCHistory.history(this, null, 'OPERATION', {'Input': this.input, 'Output': output, 'Trace': this.inputMapTrace});
        }
        let action = this.parentFlow.context.action;
        if (!action && this.parentFlow.flow.id == 'FWK_OperationExecute_FE') {
          this.parentFlow = this.parentFlow.parentFlow
          action = this.parentFlow.context.action
          output = {output: output}
        }
        if (action.multiCall) {
          if (!this.parentFlow.multiOutput) {
            this.parentFlow.multiOutput = [];
          }
          this.parentFlow.multiOutput.push(output);
          if (this.parentFlow.multiInput.length > 0) {
            this.parentFlow.callAction();
            return;
          } else {
            output = {'output*': this.parentFlow.multiOutput};
            this.parentFlow.multiInput = null;
            this.parentFlow.multiOutput = null;
            this.inputMapTrace = this.parentFlow.multiTrace; 
            this.parentFlow.multiTrace = null;
            this.input = this.parentFlow.multiInputOrig;
            this.parentFlow.multiInputOrig = null;
          }
        }
        this.parentFlow.clearActionContext(action.code);
        if (unorderedOutput && this.flow.output) {
          this.parentFlow.addToContext(this.parentFlow.context.data, action.code, output);
        }
        this.parentFlow.progress({'Input': this.input, 'Output': output, 'Server log': log, 'Trace': this.inputMapTrace});
      }
    }
  };

  this.endOperationException = function(type, message, input, output, log) {
    this.clearLogicTimers();
    if (!MC.isPlainObject(type)) {
      type = this.buildExceptionTypeObject(type);
    }
    if (this.flow) {
      if (this.context.action) {
        message = " operation " + this.flow.id + " / action " + this.context.action.code + " / " + message;
      } else {
        message = " operation " + this.flow.id + " / " + message;
      }
    }
    var exception = {};
    exception.errorName = type.name;
    exception.errorMessage = message;
    exception.errorCode = type.name;
    if (!MC.isNull(output)) {
      exception = output;
    }
    this.context.data['env/exception'] = exception;
    let relevantException = false;
    if (this.context.action && this.context.action.exception) {
      relevantException = this.getRelevantException(type, this.context.action.exception);
    }
    if (relevantException) {
      MCHistory.log(MCHistory.T_EXCEPTION, type.name + ': ' + message, this.debug);
      this.context.nextAction = this.getActionById(relevantException.nextaction);
      this.progress();
    } else {
      var startAction = null;
      if (this.flow && this.flow.action) {
        startAction = this.getStartAction();
      }
      let isThrowedFromEnd = false;
      if (this.context.action && this.context.action.kind === 'end' && this.context.action.throwsException && this.context.action.throwsException.name === type.name) {
        isThrowedFromEnd = true;
      }
      relevantException = false;
      if (startAction && startAction.exception) {
        relevantException = this.getRelevantException(type, startAction.exception);
      }
      if (!isThrowedFromEnd && relevantException) {
        MCHistory.log(MCHistory.T_EXCEPTION, type.name + ': ' + message, this.debug);
        this.context.nextAction = this.getActionById(relevantException.nextaction);
        this.progress();
      } else {
        if (!this.parentFlow) {
          MCHistory.history(this, this.context.action, 'EXCEPTION END', {'Input': input, 'Output': exception, 'Server log': log});
          if (this.promiseObject) {
            this.promiseObject.reject({exception: {type: type.name, message: message}});
            this.promiseObject = null;
            this.currentPromise = null;
          } else if (MC.isFunction(this.onEndFunction)) {
            if (this.reactFlow.props.clearStateOnEnd) {
              this.reactFlow.setState({state: null, dimmer: false});
            }
            this.onEndFunction(type.name, message);
          } else if (this.reactFlow) {
            this.reactFlow.setState({state: 'exception', exception: {type: type.name, message: message}, dimmer: false, runReady: false});
          }
        } else {
          if (this.flow && this.flow.action && !this.flow.mock) {
            MCHistory.history(this, this.context.action, 'EXCEPTION END', {'Input': input, 'Output': exception, 'Server log': log});
          }
          this.parentFlow.endOperationException(type, message, null, exception, null);
        }
      }
    }
  };

  this.buildExceptionTypeObject = function(type) {
    var exception = {name: type};
    exception.parent = [];
    if (this.flow && this.flow.exception && this.flow.exception[type]) {
      var curr = this.flow.exception[type];
      while (!MC.isNull(curr.parent)) {
        exception.parent.push(curr.parent);
        curr = this.flow.exception[curr.parent];
      }
    }
    return exception;
  };

  this.getRelevantException = function(thrown, caughtArr) {
    if (Array.isArray(caughtArr) && caughtArr.length > 0) {
      for (let caught of caughtArr) {
        if (thrown.name == caught.name || Array.isArray(thrown.parent) && thrown.parent.indexOf(caught.name) > -1) {
          return caught;
        }
      }
    }
    return false;
  };

  this.leaveOperation = function(callsExternal, flowId, input, trace) {
    MCHistory.history(this, this.context.action, 'LEAVE FLOW', {'Output': input, 'Trace': trace});
    if (!this.parentFlow) {
      input = MC.stripStars(input);
      if (MC.isFunction(this.onLeaveFlow)) {
        this.onLeaveFlow(callsExternal, flowId, input);
      } else {
        if (callsExternal) {
          this.setFlowId(null);
          this.setFlowConfiguration(this.confPath, callsExternal);
        } else {
          this.setFlowId(flowId);
          this.setFlowConfiguration(this.confPath, null);
        }
        var cfg = this.context.data['env/cfg'];
        this.context = {data: {}};
        this.env = {};
        this.context.data['env/cfg'] = cfg; //TODO: configuration should be deleted too, now throws js error
        this.flow = null;
        this.loadAndStart(input);
      }
    } else {
      this.parentFlow.leaveOperation(callsExternal, flowId, input, trace);
    }
  };

  this.transformAction = function() {
    var action = this.context.action;
    let [input, trace] = this.mapToResultObject(action.mapping, self.context, true);
    if (input) {
      var output = {};
      if (!this.convertOutputTreeData(action, input, output)) {
        return false
      }
      this.clearActionContext(action.code);
      this.addToContext(this.context.data, action.code, output);
      this.progress({'Input': input, 'Output': output, 'Trace' : trace});
    }
  };

  this.feedbackAction = function() {
    var action = this.context.action;
    var feedback = MC.extend(true, {}, action.feedback);
    if (MC.isNull(feedback)) {
      this.endOperationException('SYS_InvalidModelExc', "Feedback action '" + action.code + "' has no feedback assigned!");
      return;
    }
    let result = {};
    let trace;
    if (!MC.isNull(action.mapping)) {
      [result, trace] = this.mapToResultObject(action.mapping, self.context, true);
      var nresult = {};
      for (var prop in result) {
        nresult['@' + prop] = result[prop];
      }
      feedback.param = {value: nresult};
      feedback.title = MC.formatValue('', 'message', null, feedback.title, feedback, null);
      feedback.help = MC.formatValue('', 'message', null, feedback.help, feedback, null);
    }
    if (!Array.isArray(this.context.feedback)) {
      this.context.feedback = [];
    }
    this.context.feedback.push(feedback);
    this.progress({'Input': result, 'Trace' : trace});
  };

  this.clearActionContext = function(code) {
    for (var prop in this.context.data) {
      if (this.context.data.hasOwnProperty(prop) && prop.startsWith(code + '/')) {
        delete this.context.data[prop];
      }
    }
  };

  this.showOutput = function(output) {
    this.reactFlow.setState({state: 'output', output: output, dimmer: false, runReady: false});
  };

  this.makeObject = function(key, valueObj) {
    var newValue = null;
    var newKey = null;
    if (key.indexOf('/') > 0) {
      newKey = key.substring(0, key.indexOf('/'));
      if (newKey.endsWith('*') && Array.isArray(valueObj)) {
        newValue = [];
        for (var i=0; i<valueObj.length; i++) {
          var res = this.makeObject(key.substring(key.indexOf('/')+1), valueObj[i]);
          newValue.push(res);
        }
      } else {
        var res = this.makeObject(key.substring(key.indexOf('/')+1), valueObj);
        if (newKey.endsWith("*")) {
          newValue = [];
          newValue.push(res);
        } else {
          newValue = res;
        }
      }
    } else {
      newKey = key;
      if (key.endsWith("*") && !Array.isArray(valueObj)) {
        newValue = [valueObj];
      } else {
        newValue = valueObj;
      }
    }
    var object = {};
    object[newKey] = newValue;
    return object;
  };

  this.makeObjectRecursive = function(valueObj) {
    if (MC.isPlainObject(valueObj)) {
      var object = {};
      for (var key in valueObj) {
        if (hasOwnProperty.call(valueObj, key)) {
          var value = valueObj[key];
          var expectedLevel = (key.match(/\*/g) || []).length;
          value = this.stripDeepCollections(value, expectedLevel);
          var newObject = this.makeObject(key, value);
          if (!MC.isNull(newObject)) {
            MC.extend(true, object, newObject);
          }
        }
      }
      if (MC.isEmptyObject(object)) {
        return null;
      } else {
        return object;
      }
    } else {
      return valueObj;
    }
  };

  this.stripDeepCollections = function(value, fromLevel) {
    if (Array.isArray(value)) {
      if (fromLevel > 0) {
        fromLevel--;
        for (var i=0; i<value.length; i++) {
          value[i] = this.stripDeepCollections(value[i], fromLevel);
        }
      } else {
        value = this.stripDeepCollections(this.getFirstNotNull(value), fromLevel);
      }
    }
    return value;
  };

  this.getFirstNotNull = function(value) {
    if (Array.isArray(value)) {
      for (var i=0; i<value.length; i++) {
        if (!MC.isNull(value[i])) {
          return value[i];
        }
      }
      return null;
    } else {
      return value;
    }
  };

  this.mapToResultObject = function(mappingArr, context, stripNulls, triggeredByField) {
    var result = {};
    let traceObj = {};
    if (mappingArr && Array.isArray(mappingArr)) {
      for (var i=0; i<mappingArr.length; i++) {
        var mapping = mappingArr[i];
        if (MC.isEmptyObject(mapping)) {
          continue;
        }
        try {
          var opts = {};
          if (triggeredByField) {
            opts.singleRoot = true;
          }
          if (!stripNulls) {
            opts.nullMode = true;
          }
          var expression = new Expression(mapping, context.data, opts);
          var valueObj = expression.evaluate();
          if (this.debug) {
            MC.extend(traceObj, expression.getTraceAsPaths());
          }
          if (expression.getError()) {
            MC.error(expression.getError());
          }
        } catch (ex) {
          this.endOperationException('SYS_MappingExc', ex.message);
          return false;
        }
        if (!MC.isNull(valueObj)) {
          valueObj = this.makeObjectRecursive(valueObj);
          if (!MC.isNull(valueObj)) {
            if (Array.isArray(valueObj) && MC.isEmptyObject(result)) { // case where array is mapped into root
              result = valueObj;
            } else {
              var props = Object.getOwnPropertyNames(valueObj);
              for (var p=0; p<props.length; p++) {
                result[props[p]] = valueObj[props[p]];
              }
            }
          }
        }
        if (triggeredByField) { // theese lines needed for form logic live mapping
          var formData = this.reactFlow.state.formData;
          self.setFormFields(formData, result, false, false, true);
          formData.flow.mapFormOutput(formData, context.data.form, triggeredByField, true, 'no', null, false, null);
          this.reactFlow.setState({state: 'form', dimmer: false, formData: formData, runReady: false});
          context.data.form.data = formData.data;
        }
      }
    }
    if (stripNulls) {
      result = MC.stripNulls(result);
    }
    return [result, traceObj];
  };

  this.runMock = function() {
    try {
      var operation = this.flow;
      if (operation.output) {
        var output = {};
        if (Array.isArray(operation.mock) && operation.mock.length > 0) {
          // mapping into input for evaluating conditions
          for (var i=0; i<operation.mock.length; i++) {
            var passed = true;
            if (operation.mock[i].expr) {
              var expression = new Expression(operation.mock[i].expr[0], {input: this.input}, {singleRoot: true});
              if (!expression.evaluate()) {
                passed = false;
              }
              if (expression.getError()) {
                MC.error(expression.getError());
              }
            }
            if (passed) {
              this.convertMockTreeData(operation, operation.mock[i].data, output);
              return output;
            }
          }
        }
      }
    } catch (ex) {
      self.endOperationException('SYS_MappingExc', ex.message);
    }
    return false;
  };

  this.runFunctionOperation = function() {
    var type = this.flow.functiontype;
    if (type == 'javascript') {
      if (!this.flow.functioncode) {
        this.endOperationException('SYS_InvalidModelExc', 'Code of function operation ' + this.flow.id + ' can not be empty!');
      }
      var input = this.input;
      input = MC.stripStars(input);
      var output = {};
      eval(this.flow.functioncode);
      this.endOperation(output);
    } else {
      this.endOperationException('SYS_InvalidModelExc', 'Unsupported function type: ' + type);
    }
  };

  this.runFrameworkOperation = function() {
    var self = this;
    if (this.flow.id == 'BRWS_ExternalUrlWindowOpen') {
      var input = this.input;
      var url = input.url;
      if (MC.isNull(url) || url === '') {
        this.endOperationException('SYS_InvalidModelExc', '"url" input parameter of BRWS_ExternalUrlWindowOpen is required!');
        return;
      }
      if (!MC.isNull(input.params) && MC.isPlainObject(input.params)) {
        for (var param in input.params) {
          url += (url.indexOf('?') < 0 ? '?' : '&') + param + '=' + input.params[param];
        }
      }
      var target = input.target;
      if (target == 'blank') {
        window.open(url);
        this.endOperation(null);
      } else if (target == 'top') {
        window.top.location.href = url;
      } else if (target == 'parent') {
        window.parent.location.href = url;
      } else {
        window.location.href = url;
      }
    } else if (this.flow.id == 'FWK_EventSend') {
      var urlarr = window.location.href.split("/");
      if (this.input.parent === true && parent) {
        parent.postMessage(this.input, urlarr[0] + "//" + urlarr[2]);
      } else {
        window.postMessage(this.input, urlarr[0] + "//" + urlarr[2]);
      }
      this.endOperation(null);
    } else if (this.flow.id == 'FWK_EnvironmentRefresh') {
      if (this.context.data['env/cfg']['fl:environmentOperation']) {
        MC.getEnvironmentContext(this.context.data['env/request'], this.confPath, this.flowServerUrl,  this.context.data['env/cfg']['fl:environmentOperation']).then(function(context) {
          self.updateEnvContext(context);
          self.endOperation(null);
        });
      } else {
        self.updateEnvContext(null);
        self.endOperation(null);
      }
    } else if (['BRWS_LocalDataStore', 'BRWS_LocalDataGet', 'BRWS_LocalDataList', 'BRWS_LocalDataDelete'].indexOf(this.flow.id) > -1) {
      let res;
      switch (this.flow.id) {
        case 'BRWS_LocalDataStore': res = MCBrws.store(this.input.path, this.input.data); break;
        case 'BRWS_LocalDataGet': res = MCBrws.get(this.input.path); break;
        case 'BRWS_LocalDataList': res = MCBrws.list(this.input.path); break;
        case 'BRWS_LocalDataDelete': res = MCBrws.delete(this.input.path); break;
      }
      if (res.error) {
        this.endOperationException('SYS_InvalidModelExc', res.error);
      } else {
        this.endOperation(res.result);
      }
    } else if (this.flow.id == 'BRWS_ResizeImage') {
      let input = this.input;
      if (MC.isNull(input.data) || input.data === "") {
        this.endOperationException('SYS_InvalidModelExc', '"data" input parameter of BRWS_ResizeImage can not be null or empty!');
        return;
      }
      if (MC.isNull(input.contentType) || input.contentType === "") {
        this.endOperationException('SYS_InvalidModelExc', '"contentType" input parameter of BRWS_ResizeImage can not be null or empty!');
        return;
      }
      let quality = 0.75;
      if (input.quality && MC.isNumeric(input.quality) && input.quality >= 0 && input.quality <= 1) {
        quality = parseFloat(input.quality);
      }
      let maxWidth = 800;
      if (input.maxWidth && MC.isNumeric(input.maxWidth) && input.maxWidth > 0) {
        maxWidth = parseInt(input.maxWidth);
      }
      let maxHeight = 600;
      if (input.maxHeight && MC.isNumeric(input.maxHeight) && input.maxHeight > 0) {
        maxHeight = parseInt(input.maxHeight);
      }
      let image = new Image();
      image.onload = function() {
        var width = image.width;
        var height = image.height;
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, width, height);
        let output = {contentType: "image/jpeg"};
        let data = canvas.toDataURL('image/jpeg', quality);
        output.data = data.substring(data.indexOf(';base64,')+8);
        self.endOperation(output);
      };
      image.src = 'data:' + input.contentType + ';base64,' + input.data;
    } else if (this.flow.id == 'BRWS_ImageAdaptiveThreshold') {
      let input = this.input
      if (MC.isNull(input.data) || input.data === "") {
        this.endOperationException('SYS_InvalidModelExc', '"data" input parameter of BRWS_ImageAdaptiveThreshold can not be null or empty!')
        return
      }
      if (MC.isNull(input.contentType) || input.contentType === "") {
        this.endOperationException('SYS_InvalidModelExc', '"contentType" input parameter of BRWS_ImageAdaptiveThreshold can not be null or empty!')
        return
      }
      let ratio = 0.6
      if (input.ratio && MC.isNumeric(input.ratio) && input.ratio >= 0 && input.ratio <= 1) {
        ratio = parseFloat(input.ratio)
      }
      let image = new Image()
      image.onload = function() {
        let width = image.width
        let height = image.height
        let sourceCanvas = document.createElement('canvas')
        sourceCanvas.width = width
        sourceCanvas.height = height
        let ctx = sourceCanvas.getContext("2d")
        ctx.drawImage(image, 0, 0)
        let sourceImageData = ctx.getImageData(0, 0, width, height)
        let sourceData = sourceImageData.data
        let integral = new Int32Array(width * height)
        let x = 0, y = 0, lineIndex = 0, sum = 0
        for (x = 0; x < width; x++) {
          sum += sourceData[x << 2]
          integral[x] = sum
        }
        for (y = 1, lineIndex = width; y < height; y++, lineIndex += width) {
          sum = 0
          for (x = 0; x < width; x++) {
            sum += sourceData[(lineIndex + x) << 2]
            integral[lineIndex + x] = integral[lineIndex - width + x] + sum
          }
        }
        let s = width >> 4; // in fact it's s/2, but since we never use s...
        let canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        let result = canvas.getContext('2d').createImageData(width, height)
        let resultData = result.data
        let resultData32 = new Uint32Array(resultData.buffer)
        x = 0; y = 0; lineIndex = 0
        for (y = 0; y < height; y++, lineIndex += width) {
          for (x = 0; x < width; x++) {
            let value = sourceData[(lineIndex + x) << 2]
            let x1 = Math.max(x - s, 0)
            let y1 = Math.max(y - s, 0)
            let x2 = Math.min(x + s, width - 1)
            let y2 = Math.min(y + s, height - 1)
            let area = (x2 - x1 + 1) * (y2 - y1 + 1)
            let localIntegral = integral[x2 + y2 * width]
            if (y1 > 0) {
              localIntegral -= integral[x2 + (y1 - 1) * width]
              if (x1 > 0) {
                localIntegral += integral[(x1 - 1) + (y1 - 1) * width]
              }
            }
            if (x1 > 0) {
              localIntegral -= integral[(x1 - 1) + (y2) * width];
            }
            if (value * area > localIntegral * ratio) {
              resultData32[lineIndex + x] = 0xFFFFFFFF
            } else {
              resultData32[lineIndex + x] = 0xFF000000
            }
          }
        }
        canvas.getContext("2d").putImageData(result, 0, 0)
        let output = {contentType: "image/png"}
        let data = canvas.toDataURL('image/png', 1)
        output.data = data.substring(data.indexOf(';base64,')+8)
        self.endOperation(output)        
      }
      image.src = 'data:' + input.contentType + ';base64,' + input.data
    } else if (this.flow.id == 'FWK_OperationExecute_FE') {
      let input = this.input
      if (MC.isNull(input.operation) || input.operation === '') {
        this.endOperationException('SYS_InvalidModelExc', '"operation" input parameter of FWK_OperationExecute_FE can not be null or empty!')
        return
      }
      const flow = new Flow(this.reactFlow, this.promiseObject, this.currentPromise)
      flow.setLang(self.lang).setConfPath(this.confPath).setParentFlow(self)
          .setFlowDataTemplate(this.flowdatatemplate).setFlowServerUrl(this.flowServerUrl)
      flow.setFlowConfiguration(this.confPath, input.operation)
      if (MC.isFunction(self.onNextForm)) {
        flow.setOnNextFormFunction(self.onNextForm)
      }
      if (MC.isFunction(self.afterRenderForm)) {
        flow.setAfterRenderFormFunction(self.afterRenderForm)
      }
      flow.setEnv(this.env)
      if (self.debug) {
        flow.debugMode()
      }
      flow.setServerLogLevel(this.serverLogLevel)
      flow.loadAndStart(input.input, null, null)
    } else if (['BRWS_LocalStorageItemSet', 'BRWS_LocalStorageItemGet', 'BRWS_LocalStorageItemRemove'].indexOf(this.flow.id) > -1) {
      let input = this.input
      if (MC.isNull(input.key) || input.key === '') {
        this.endOperationException('SYS_InvalidModelExc', '"key" input parameter of "' + this.flow.id + '" can not be null or empty!')
        return
      }
      let res = null
      switch (this.flow.id) {
        case 'BRWS_LocalStorageItemSet':
          if (!MC.isNull(input.data)) {
            localStorage.setItem(input.key, JSON.stringify(input.data))
          } else {
            localStorage.removeItem(input.key)
          }
          break
        case 'BRWS_LocalStorageItemGet': 
          res = localStorage.getItem(input.key)
          res = {data: res ? JSON.parse(res) : null}
          break
        case 'BRWS_LocalStorageItemRemove': 
          localStorage.removeItem(input.key)
          break
      }
      this.endOperation(res);
    } else {
      this.endOperationException('SYS_InvalidModelExc', 'Unsupported framework operation: ' + this.flow.id);
    }
  };

  this.updateEnvContext = function(context) {
    this.env.context = context;
    if (!MC.isNull(context) && MC.isPlainObject(context.request) && !MC.isNull(context.request.language)) {
      this.setLang(context.request.language);
    }
    this.addToContext(self.context.data, 'env', self.env);
    if (this.parentFlow) {
      this.parentFlow.updateEnvContext(context);
    }
    MCHistory.logEnv(this.env, this.debug);
  };

  this.initLogicTimer = function(logic) {
    const self = this
    if (MC.isNumeric(logic.timer) && logic.timer > 0) {
      this.logicTimers[logic.name] = setTimeout(() => { 
        self.eventForm(null, logic.name, null)
      }, 1000*logic.timer) 
    }
  }
  
  this.initLogicTimers = function() {
    if (this.reactFlow.state.formData.logic && Array.isArray(this.reactFlow.state.formData.logic)) {
      for (let logic of this.reactFlow.state.formData.logic) {
        this.initLogicTimer(logic)
      }
    }
  }
  
  this.clearLogicTimers = function() {
    if (!this.logicTimers) return
    for (let timerKey in this.logicTimers) {
      clearTimeout(this.logicTimers[timerKey])
    }
  }

};

export {Flow};