import './dropdown-navigation-bar-item.css';
import React from "../../miniclient/in/client/node_modules/react";

class DropdownNavigationBarItem extends React.Component {
  constructor(props) {
    super(props);
    var dropdown = this;
    dropdown.state = {active: false};
  }

  handleContentClick() {
    var dropdown = this;
    dropdown.setState({active: false});
  }

  handleElementMouceEnter() {
    var dropdown = this;
    dropdown.setState({active: true});
  }

  handleDropdownMouseLeave() {
    var dropdown = this;
    dropdown.setState({active: false});
  }

  render() {
    var dropdown = this;
    var text = dropdown.props.text;
    var active = dropdown.state.active;
    var inner;
    if (active) {
      var content = dropdown.props.items.map(function(item) {
        return (
          <div className="item"
               key={item.key}
               onClick={() => dropdown.props.handleClick(item)}>
            {item.text}
          </div>
        )
      });
      inner = <div className="content"
                   onClick={() => dropdown.handleContentClick()}>
                {content}
              </div>
    }
    var className = "meta navigation-bar-dropdown-item";
    if (dropdown.props.active) {
      className += " active";
    }
    return (
      <div className = {className}
          onMouseLeave={() => dropdown.handleDropdownMouseLeave()}>
         <div className="text"
            onMouseEnter={() => dropdown.handleElementMouceEnter()}>
            {text}
          </div>
          {inner}
     </div>
    )
  }
}

export {DropdownNavigationBarItem};
