'use strict';
import React from "../../miniclient/in/client/node_modules/react";
import ReactDOM from "../../miniclient/in/client/node_modules/react-dom";
import createReactClass from 'create-react-class';
import {Library} from "./library.js";

const HtmlPage = createReactClass({
  setHtml: function(html) {
    let component = this;
    let domNode = ReactDOM.findDOMNode(component);
    domNode.style.height = '0px';
    let contentDocument = domNode.contentDocument;
    let newDoc = document.implementation.createHTMLDocument();
    newDoc.open();
    newDoc.write(html);
    newDoc.close();
    let baseUrl = component.props.baseUrl;
    if (baseUrl) {
      let base = newDoc.createElement('base');
      base.href = baseUrl;
      newDoc.getElementsByTagName('head')[0].appendChild(base);
    }
    //win.document = newDoc;
    contentDocument.head.innerHTML = newDoc.head.innerHTML;
    contentDocument.body.innerHTML = newDoc.body.innerHTML;


    let images = contentDocument.getElementsByTagName('img');
    let arrayOfImages = Array.from(images);
    let loaded = 0;
    const setHeight = () => {
      let body = domNode.contentDocument.body;
      domNode.style.height = body.scrollHeight + 'px';
    }
    let checkLoadedImages = () => {
      loaded = loaded + 1;
      if (arrayOfImages.length ===  loaded) {
        setHeight();
      }
    }
    arrayOfImages.forEach(image => {
      image.onerror = checkLoadedImages;
      image.onload = checkLoadedImages;
    })
    if (arrayOfImages.length === 0) {
      setHeight();
    }
    const scriptsElements = contentDocument.getElementsByTagName('script')
    Array.from(scriptsElements).forEach(scriptElement => {
      scriptElement.parentNode.removeChild(scriptElement)
      const newScriptElement = contentDocument.createElement("script")
      newScriptElement.innerText = scriptElement.innerText
      contentDocument.head.appendChild(newScriptElement)
    })

  },
  load: function(html, baseUrl, width) {
    return Promise.resolve({html, baseUrl, width});
  },
  componentDidMount: function() {
    const component = this;
    component.setHtml(component.props.html);
  },
  componentDidUpdate: function(prevProps, prevState, snapshot) {
    const component = this;
    const props = component.props;
    if (prevProps.html != props.html || prevProps.width != props.width) {
      component.setHtml(props.html);
    }
  },
  render: function() {
    let component = this;
    let props = component.props;
    let style = {
      border: 'none'
    }
    if (props.width) {
      style.width = props.width;
    }
    return <iframe style={style} scrolling="no"/>
  }
})

export {HtmlPage};
