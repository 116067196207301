import React from "../../../../miniclient/in/client/node_modules/react";
import {Library} from "../../../../app/src/library.js";
import {ReactFlow} from '../../../../miniclient/in/client/src/client/ReactFlow.jsx';
import './flow.css';

class Flow extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  load(context) {
    const component = context.component;
    const debug = context.debug
    let configuration = 'commhub/api/';
    if (context.flowConfiguration) {
      configuration = context.flowConfiguration;
    } else if (typeof context.model !== 'undefined') {
      configuration = context.model['meta3:path'];
    } else if (component['app:configuration']) {
      configuration = component['app:configuration'];
    }
    const flowName = component['app:flow'];
    const flowArguments = Library.ensureArray(component['app:flowArgument']);
    return Flow.prototype.loadFlowArguments(context, flowArguments).then(input => {
      return ReactFlow.prototype.load(configuration, flowName, input, {debug}).then(props => {
        return {...props, configuration: configuration}
      })
    });
  }

  loadFlowVersionArguments(context) {
  //  var currentResourcePath = context.file['meta3:path'];
    var versioningRootPath = context.versioningRoot['meta3:path'];
    const versionPart = context.riValue.path.split('/').find(part => part.split(';v=').length !== 1);
    let version;
    if (versionPart) {
      version = versionPart.split(';v=')[1];
    } else {
      version = 'master';
    }
    var user = context.user;
    var isPrivateBranche = version.includes(".private.");
    var privateBranchePrefix;
    var parentPath;
    if (isPrivateBranche) {
      var brancheParts = version.split(".");
      var parentBranche = brancheParts[0];
      privateBranchePrefix = parentBranche + ".private." + user + ".";
      parentPath = context.versioningRoot.pathValue("meta3:changeVersionKey/" + parentBranche);
    } else {
      privateBranchePrefix = version + ".private." + user + ".";
    }
    return {
    //  path: currentResourcePath,
      versioningRootPath: versioningRootPath,
      version: version,
      parentPath: parentPath,
      parentBranche: parentBranche,
      folderPath: context.model['meta3:path'],
      isPrivateBranche: isPrivateBranche,
      privateBranchePrefix: privateBranchePrefix,
      user: context.user
    }
  }

  loadFlowArguments(context, flowArguments) {
    const inputsPromises = flowArguments.map(flowArgument => {
      switch (flowArgument) {
        case "permissions":
          return {permissions: context.permissions};
          break;
        case "surveyId":
          return {surveyId: context.riValue.query.surveyId};
          break;
        case "filePath":
          return {filePath: context.file['meta3:path']};
          break;
        case "readOnly":
          return {readOnly: !context.permissions.includes('chp:design')};
          break;
        case "readOnlyRuntime":
          return {readOnlyRuntime: !context.permissions.includes('chp:runtime')};
          break;
        case "fileName":
          return {fileName: Library.pathFileName(context.file['meta3:path'])};
          break;
        case "fileId":
          return {id: Library.pathFileName(context.file['meta3:path'])};
          break;
        case "queryParameters":
          return context.riValue.query;
          break;
        case 'pathParameters':
          return context.pathParameters;
          break
        case 'flowId':
          return {flowId: context.riValue.query.flowId || context.pathParameters.flowId};
          break;
        case 'id':
          return {id: context.riValue.query.id};
          break;
        case 'batchItemId':
          return {batchItemId: context.riValue.query.batchItemId};
          break;
       case 'batchId':
         return {batchId: context.riValue.query.id};
         break;
        case 'page':
          return {page: context.riValue.query.page};
          break;
        case 'scope':
          return {scope: context.riValue.query.scope};
          break;
        case "version":
          return Flow.prototype.loadFlowVersionArguments(context);
          break;
        default:
          return Promise.resolve({});
      }
    })
    return Promise.all(inputsPromises).then(inputs => {
      return Object.assign.apply(null, [{}].concat(inputs));
    })
  }

  handleSubmit(promise) {
    const component = this;
    const riPromise = promise.then(value => {
      if (value['app:update'] && value['app:update'].type === 'routeTo') {
        return value['app:update'].ri;
      }
      return null
    })
    component.props.onUpdate({type: 'routeTo', ri: riPromise});
  }

  render() {
    const component = this;
    const props = component.props;
    return (
      <div className="flow component">
         <ReactFlow {...props}
            configuration={props.configuration}
            flowName={props.flowName}
            editMode={false}
            start={false}
            loader="init"
            configurationChanger={true}
            onSubmitFunction={component.handleSubmit}
            input={props.input}/>
       </div>
    )
  }
}

export {Flow};
