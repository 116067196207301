import createReactClass from 'create-react-class';
import React from "../../../miniclient/in/client/node_modules/react";
import {Library} from '../library.js';
import {ensureArray} from '../lib.js'

import {TableInspector} from './table-inspector.jsx';
import {ValueInspector} from './value-inspector.jsx';
import {LabeledInspector} from './labeled-inspector.jsx';

const ComplexInspector = createReactClass({
  load: function(context, property, value) {
    const inspectorDefinition = Library.ensureArray(value['app:inspectorDefinition'])[0];
    let keys = Object.keys(value);
    let inspectedKeys = [];
    if (inspectorDefinition) {
      const inspectorProperties = Library.ensureArray(inspectorDefinition['meta3:item']).map(item => item['meta3:propertyName']);
      const doNotInspectChildren = inspectorDefinition['app:doNotInspectChildren'] === 'true';
      const inspectedNamespaces =  Library.ensureArray(inspectorDefinition['app:inspectedNamespace']);
      inspectedKeys = inspectorProperties;
      if (inspectedNamespaces.length != 0) {
        const inspectedNamespacesProperties = Library.concatArrays(
          inspectedNamespaces.map(namespace => {
            const namespaceKeys = keys.filter(key => {
              return Library.propertyNamespace(key) === namespace;
            })
            return Library.sortProperties(namespaceKeys);
          })
        )
        inspectedKeys = inspectedKeys.concat(inspectedNamespacesProperties);
      }
      if (inspectorProperties.length !== 0) {
        if (doNotInspectChildren) {
          keys = inspectedKeys;
        } else {
          let nonInspectedKeys = keys.filter(name => {
            return inspectedKeys.indexOf(name) == -1;
          });
          keys = inspectedKeys.concat(nonInspectedKeys);
        }
      }
    }
    const valueName = value['meta3:name'];
    let arrayOfParts = keys.map((key, index) => {
      const title = Library.propertyTitle(key);
      const isInspected = inspectedKeys.indexOf(key) !== -1;
      const display = Library.propertyProperty(key, 'app:display');
      const propertyInspectorDefinition = Library.propertyProperty(key, 'app:inspectorDefinition');
      let subvalues = Library.ensureArray(value[key]);
      if (subvalues.length == 0) {
        return [];
      } if (display === 'table' && isInspected) {
        subvalues = Library.sortComplex(subvalues)
        return Promise.resolve(TableInspector.prototype.load(context, key, subvalues)).then(props => {
          return Object.assign({}, props, {
            property: key,
            title: title
          })
        })
      } else {
        let labeled = false;
        const areComplex = Library.areComplex(subvalues);
        const areSimple = Library.areSimple(subvalues);
        const isBoolean = Library.propertyDataType(property) === 'boolean'
        if (!areComplex && !isBoolean) {
          labeled = true;
        }
        if (!areComplex && !isInspected) {
          return [];
        } else {
          subvalues = Library.sortComplex(subvalues);
          return subvalues.map((subvalue, index) => {
            let inspectorProps;
            if (labeled) {
              inspectorProps = LabeledInspector.prototype.load(context, key, subvalue);
            } else {
              inspectorProps = ValueInspector.prototype.load(context, key, subvalue);
            }
            return Promise.resolve(inspectorProps).then(props => {
              return Object.assign({
                labeled: labeled,
                key: value['rbs:id'] + '_' + key + '_' + index,
                property: key
              }, props)
            })
          })
        }
      }
    })

    const possibilities = ensureArray(value['meta3:possibility'])
    const possibilitiesParts = possibilities.filter(possibility => possibility['meta3:inspected'] === 'true').map(async (possibility) => {
      const property = possibility['meta3:property']
      const props =  await LabeledInspector.prototype.load(context, property, value[property], possibility)
      return {labeled: true, key: value['rbs:id'] + '_' + property, property, ...props}
    })
    arrayOfParts = arrayOfParts.concat(possibilitiesParts)
    return Promise.all(Library.concatArrays(arrayOfParts)).then(parts => {
      return {
        value: value,
        name: valueName,
        parts: parts,
        isEmpty: parts.every(part => part.isEmpty),
        type: 'complexInspector'
      }
    })
  },
  render: function () {
    const component = this;
    const props = component.props;
    const value = props.value;
    if (props.isEmpty) {
      return <div/>;
    }
    const parts = props.parts.map(part => {
      const labeled = part.labeled;
      const isTable = part.isTable;
      if (isTable) {
        const componentProps = Object.assign({}, part, {
          onValueUpdate: (valueUpdate) => {
            const update = {'rbs:id': value['rbs:id']}
            update[part.property] = [valueUpdate];
            props.onValueUpdate(update);
          },
          onUpdate: props.onUpdate
        });
        const table = React.createElement(TableInspector, componentProps);
        return [<div className="field" key={name}><label>{part.title}</label>{table}</div>];
      } else {
        let component = ValueInspector;
        if (labeled) {
          component = LabeledInspector;
        }
        let componentProps = Object.assign({}, part, {
          onValueUpdate: (valueUpdate) => {
            const update = {'rbs:id': value['rbs:id']}
            update[part.property] = Library.isComplex(valueUpdate) ? [valueUpdate] : valueUpdate;
            props.onValueUpdate(update);
          },
          readOnly: props.readOnly,
          onUpdate: props.onUpdate
        });
        return React.createElement(component, componentProps);
      }
    })

    if (props.isTopLevel) {
      return <div>{parts}</div>
    }
    const valueName = props.name;
    const valueHeader = <h3 className="ui header">{valueName}</h3>;
    return (<div className='ui segment'>
              {valueHeader}
              {parts}
            </div>)
  }
})

export {ComplexInspector};
