import {Library} from "../../../../app/src/library.js";
import {CHub} from "./library/c-hub.js";
import {Appmodeler} from "./appmodeler.js";

const FlowGenerator = {};

FlowGenerator.yStep = 100;

FlowGenerator.nodeConceptName = (basePath, node) => {
  const conceptPath = Library.referenceResolve(node['meta3:instanceOf'], basePath);
  const conceptName = Library.pathName(conceptPath);
  return conceptName;
}

FlowGenerator.findNode = (nodes, nodeId) => {
  return nodes.find(node => node['rbs:id'] === nodeId);
}

FlowGenerator.relationshipTarget = (context, node, property) => {
  const link = node[property];
  if (link) {
    const reference = link['meta3:target']
    if (reference) {
      const targetRi = Library.referenceResolve(reference, context.basePath);
      const id = Library.riFragment(targetRi);
      return FlowGenerator.findNode(context.nodes, id);
    }

  }
}

FlowGenerator.nextNode = (context, node) => {
  return FlowGenerator.relationshipTarget(context, node, 'chub:nextNode');
}

FlowGenerator.standardActions = (context, node) => {
  const nextNode = FlowGenerator.nextNode(context, node);
  let nextNodeConceptName;
  let nextNodeId
  if (nextNode) {
    nextNodeConceptName = FlowGenerator.nodeConceptName(context, nextNode);
    nextNodeId = nextNode['rbs:id'];
  }
  let resultPromise;
  let nextActionId;
  if (nextNode && nextNodeConceptName !== 'join-of-processing-and-data-structure') {
    nextActionId = nextNode['rbs:id'];
    if (context.proceeded.find(proceededNode => proceededNode['rbs:id'] === nextActionId)) {
      return Promise.resolve({
        actions: [],
        nextNodeId: nextNodeId,
        nextActionId: nextActionId,
        lastCallNumber: context.callNumber,
        proceeded: context.proceeded});
    } else {
      const nextContext = {...context,
        callNumber: context.callNumber + 1,
        y: context.y + FlowGenerator.yStep,
        proceeded: [...context.proceeded, node]
      }
      resultPromise = FlowGenerator.actions(nextContext, nextNode);
    }

  } else {
    const nextContext = {...context,
      y: context.y + FlowGenerator.yStep,
      proceeded: [...context.proceeded, node]
    }
    resultPromise = FlowGenerator.generateEnd(nextContext, node).then(result => {
      return {...result, lastNode: node, lastCallNumber: nextContext.callNumber, proceeded: context.proceeded}
    })
    nextActionId = 'end_' + node['rbs:id'];
  }
  return resultPromise.then(result => {
    return {...result, nextActionId, nextNodeId, node};
  })
}

FlowGenerator.nodeCode = (context, node) => {
  return node['chub:flowCall'] + String(context.callNumber)
}

FlowGenerator.action = (context, node) => {
  const mappingDefinitions = Library.ensureArray(node['chub:mappingDefinition']);
  let action = {};
  if (mappingDefinitions.length > 0) {
    action = {
      'svc:OperationActionMapping': Library.concatArrays(mappingDefinitions.map(mappingDefinition => {
        const property = mappingDefinition['chub:property'];
        const value = node[property];
        if (typeof value === 'undefined' || value === '~') {
          return [];
        } else {
          return [Appmodeler.constantMapping(mappingDefinition['chub:target'], node[property])];
        }
      }))
    }
  }
  return {...action,
    'rbs:id': node['rbs:id'],
    'svc:code': FlowGenerator.nodeCode(context, node),
    'svc:extopcalled': node['chub:flowOperation'],
    'svc:kind': 'call',
    'svc:posx': String(context.x),
    'svc:posy': String(context.y)
  }
}

FlowGenerator.startNode = (context, node) => {
  const nodeCode = FlowGenerator.nodeCode(context, node);
  const nextContext = {...context,
    flowIdPath: nodeCode + '/flowId'
  }
  return FlowGenerator.standardActions(nextContext, node).then(async ({nextActionId, nextNodeId, actions, lastNode, ...rest}) => {
    const basePath = context.basePath
    const dataStructureName = await FlowGenerator.flowDataStructureName(context.storage, basePath, context.flow)
    let action = FlowGenerator.action(context, node);
    action = {...action,
      'svc:OperationActionMapping': [...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('surveyId', 'start/surveyId'),
        Appmodeler.simpleMapping('flowId', context.flowIdPath),
        Appmodeler.simpleMapping('inputCSV', 'start/inputCSV'),
        Appmodeler.constantMapping('flowName', context.flow['meta3:name']),
        Appmodeler.constantMapping('flowRI', basePath),
        Appmodeler.constantMapping('dataStructureName', dataStructureName),
        Appmodeler.constantMapping('nextNodeId', nextNodeId)
      ],
      'svc:nextaction': nextActionId
    }
    return {actions: [action, ...actions], lastNode, ...rest}
  })

}

/*
FlowGenerator.endNode = (context, node) => {
  return FlowGenerator.standardActions(context, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest }) => {
    const mappings = [];
    if (context.batchItemIdMapping) {
      mappings.push(Appmodeler.simpleMapping('batchItemId', 'start/batchItemId'))
    }
    let action = FlowGenerator.action(context, node);
    action = {...action,
      'svc:OperationActionMapping': [...mappings, ...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('flowId', context.flowIdPath)
      ],
      'svc:nextaction': nextActionId
    }
    return {actions: [action, ...actions], lastNode, ...rest}
  })
}
*/

FlowGenerator.flowDataStructureName = async (storage, basePath, flow) => {
  const dataStructureReference = flow['dsl:dataStructure']
  const dataStructure = await storage.referencedValue(basePath, dataStructureReference)
  if (!dataStructure) {
    throw new Error('Flow data structure does not exist.')
  }
  return dataStructure['meta3:name']
}

FlowGenerator.generateEmails = (context, node) => {
  return FlowGenerator.standardActions(context, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest }) => {
    const flow = context.flow;
    const basePath = context.basePath;
    return FlowGenerator.flowDataStructureName(context.storage, basePath, flow).then(dataStructureName => {
      const templateReference = node['dsl:template'];
      const templatePath = Library.resolveReference(context.basePath, templateReference);
      let action = FlowGenerator.action(context, node);
      action = {...action,
        'svc:OperationActionMapping': [...Library.ensureArray(action['svc:OperationActionMapping']),
          Appmodeler.simpleMapping('flowId', context.flowIdPath),
          Appmodeler.constantMapping('templateRi', templatePath),
          Appmodeler.constantMapping('dataStructureName', dataStructureName),
          Appmodeler.constantMapping('templateFlowId', flow['rbs:id']),
          Appmodeler.constantMapping('nextNodeId', nextNodeId)
        ],
        'svc:nextaction': nextActionId
      }
      return {actions: [action, ...actions], lastNode, ...rest}
    })
  })
}

FlowGenerator.generateSms = (context, node) => {
  return FlowGenerator.standardActions(context, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest }) => {
    const flow = context.flow;
    const basePath = context.basePath;
    return FlowGenerator.flowDataStructureName(context.storage, basePath, flow).then(dataStructureName => {
      const templateReference = node['dsl:template'];
      const templatePath = Library.resolveReference(context.basePath, templateReference);
      let action = FlowGenerator.action(context, node);
      action = {...action,
        'svc:OperationActionMapping': [...Library.ensureArray(action['svc:OperationActionMapping']),
          Appmodeler.simpleMapping('flowId', context.flowIdPath),
          Appmodeler.constantMapping('templateRi', templatePath),
          Appmodeler.constantMapping('dataStructureName', dataStructureName),
          Appmodeler.constantMapping('templateFlowId', flow['rbs:id']),
          Appmodeler.constantMapping('nextNodeId', nextNodeId)
        ],
        'svc:nextaction': nextActionId
      }
      return {actions: [action, ...actions], lastNode, ...rest}
    })
  })
}

FlowGenerator.generateEmail = async (context, node) => {
  const { nextActionId, nextNodeId, actions, lastNode, ...rest } = await FlowGenerator.standardActions(context, node)
  const flow = context.flow
  const basePath = context.basePath
  const dataStructureName = await FlowGenerator.flowDataStructureName(context.storage, basePath, flow)
  const templateReference = node['dsl:template']
  const templatePath = Library.resolveReference(context.basePath, templateReference)
  let action = FlowGenerator.action(context, node)
  action = {...action,
    'svc:OperationActionMapping': [...Library.ensureArray(action['svc:OperationActionMapping']),
      Appmodeler.simpleMapping('flowId', context.flowIdPath),
      Appmodeler.simpleMapping('batchItemId', 'start/batchItemId'),
      Appmodeler.constantMapping('templateRi', templatePath),
      Appmodeler.constantMapping('dataStructureName', dataStructureName),
      Appmodeler.constantMapping('templateFlowId', flow['rbs:id']),
      Appmodeler.constantMapping('nextNodeId', nextNodeId)
    ],
    'svc:nextaction': nextActionId
  }
  return {actions: [action, ...actions], lastNode, ...rest}
}

/*
FlowGenerator.sendEmailToCallCenter = (context, node) => {
  return FlowGenerator.standardActions(context, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest}) => {
    let action = FlowGenerator.action(context, node);
    action = {...action,
      'svc:OperationActionMapping': [...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('flowId', context.flowIdPath),
        Appmodeler.simpleMapping('batchItemId', 'start/batchItemId'),
        Appmodeler.constantMapping('nextNodeId', nextNodeId)
      ],
      'svc:nextaction': nextActionId
    }
    return {actions: [action, ...actions], lastNode, ...rest}
  })
}
*/

FlowGenerator.publishingOfASimpleLandingPage = (context, node) => {
  const nodeCode = FlowGenerator.nodeCode(context, node);
  const nextContext = {...context,
    endHtmlMappingPath:  nodeCode + '/html'
  }
  return FlowGenerator.standardActions(nextContext, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest }) => {
    const flow = context.flow;
    const basePath = context.basePath;
    return FlowGenerator.flowDataStructureName(context.storage, basePath, flow).then(dataStructureName => {
      const templateReference = node['dsl:page'];
      const templatePath = Library.resolveReference(context.basePath, templateReference);
      const action = {...FlowGenerator.action(context, node),
        'svc:OperationActionMapping': [
          Appmodeler.simpleMapping('flowId', context.flowIdPath),
          Appmodeler.simpleMapping('batchItemId', 'start/batchItemId'),
          Appmodeler.constantMapping('templateRi', templatePath),
          Appmodeler.constantMapping('templateFlowId', flow['rbs:id'])
        ],
        'svc:nextaction': nextActionId
      }
      const handlersReferences = Library.ensureArray(node['chub:answerHandler']).map(relationship => relationship['meta3:target'])
      return Promise.all(handlersReferences.map(handlerReference => {
        const targetRi = Library.referenceResolve(handlerReference, context.basePath);
        const id = Library.riFragment(targetRi);
        const handler = FlowGenerator.findNode(context.nodes, id);
        const linkName = handler['dsl:listeningToLink'];
        const handlerContext = {...context,
          y: 100,
          x: 150,
          callNumber: 1,
          resultCodePath: undefined,
          batchItemIdMapping: true,
          flowIdPath: 'start/flowId',
          operationTemplatePath: 'commhub/concept/template/flow-handler-operation',
          operationId: context.flow['rbs:id'] + '-link-handler-' + linkName
        }
        return FlowGenerator.generateOperation(handlerContext, handler);
      })).then(() => {
        return {actions: [action, ...actions], lastNode, ...rest}
      })
    })
  })
}

FlowGenerator.sendEmails = (context, node) => {
  return FlowGenerator.standardActions(context, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest }) => {
    const flow = context.flow;
    let action = FlowGenerator.action(context, node);
    action = {...action,
      'svc:OperationActionMapping': [...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('flowId', context.flowIdPath),
        Appmodeler.constantMapping('nextNodeId', nextNodeId)
      ],
      'svc:nextaction': nextActionId
    }
    const handlersReferences = Library.ensureArray(node['chub:answerHandler']).map(relationship => relationship['meta3:target'])
    return Promise.all(handlersReferences.map(handlerReference => {
      const targetRi = Library.referenceResolve(handlerReference, context.basePath);
      const id = Library.riFragment(targetRi);
      const handler = FlowGenerator.findNode(context.nodes, id);
      const handlerContext = {...context,
        y: 100,
        x: 150,
        callNumber: 1,
        resultCodePath: undefined,
        batchItemIdMapping: true,
        flowIdPath: 'start/flowId',
        operationTemplatePath: 'commhub/concept/template/flow-handler-operation',
        operationId: CHub.flowHandlerId(context.flow, handler)
      }
      return FlowGenerator.generateOperation(handlerContext, handler);
    })).then(() => {
      return {actions: [action, ...actions], lastNode, ...rest}
    })
  })
}

FlowGenerator.sendSms = (context, node) => {
  return FlowGenerator.standardActions(context, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest }) => {
    const flow = context.flow;
    let action = FlowGenerator.action(context, node);
    action = {...action,
      'svc:OperationActionMapping': [...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('flowId', context.flowIdPath),
        Appmodeler.constantMapping('nextNodeId', nextNodeId)
      ],
      'svc:nextaction': nextActionId
    }
    const handlersReferences = Library.ensureArray(node['chub:answerHandler']).map(relationship => relationship['meta3:target'])
    return Promise.all(handlersReferences.map(handlerReference => {
      const targetRi = Library.referenceResolve(handlerReference, context.basePath);
      const id = Library.riFragment(targetRi);
      const handler = FlowGenerator.findNode(context.nodes, id);
      const handlerContext = {...context,
        y: 100,
        x: 150,
        callNumber: 1,
        resultCodePath: undefined,
        batchItemIdMapping: true,
        flowIdPath: 'start/flowId',
        operationTemplatePath: 'commhub/concept/template/flow-handler-operation',
        operationId: CHub.flowHandlerId(context.flow, handler)
      }
      return FlowGenerator.generateOperation(handlerContext, handler);
    })).then(() => {
      return {actions: [action, ...actions], lastNode, ...rest}
    })
  })
}

/*
FlowGenerator.timerCheck = (context, node) => {
  return FlowGenerator.standardActions(context, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest }) => {
    const flow = context.flow;
    let action = FlowGenerator.action(context, node);
    const mappings = [];
    if (context.batchItemIdMapping) {
      mappings.push(Appmodeler.simpleMapping('batchItemId', 'start/batchItemId'))
    }
    action = {...action,
      'svc:OperationActionMapping': [...mappings, ...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('flowId', context.flowIdPath),
        Appmodeler.constantMapping('nextNodeId', nextNodeId)
      ],
      'svc:nextaction': nextActionId
    }
    return {actions: [action, ...actions], lastNode, ...rest}
  })
}

FlowGenerator.storingConsent = (context, node) => {
  return FlowGenerator.standardActions(context, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest }) => {
    const flow = context.flow;
    const mappings = [];
    if (context.batchItemIdMapping) {
      mappings.push(Appmodeler.simpleMapping('batchItemId', 'start/batchItemId'));
    }
    let action = FlowGenerator.action(context, node);
    action = {...action,
      'svc:OperationActionMapping': [...mappings, ...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('flowId', context.flowIdPath),
        Appmodeler.constantMapping('nextNodeId', nextNodeId)
      ],
      'svc:nextaction': nextActionId
    }
    return {actions: [action, ...actions], lastNode, ...rest}
  })
}
*/

FlowGenerator.standardNode = (context, node) => {
  return FlowGenerator.standardActions(context, node).then(({nextActionId, nextNodeId, actions, ...rest}) => {
    const flow = context.flow;
    const mappings = [];
    if (context.batchItemIdMapping) {
      mappings.push(Appmodeler.simpleMapping('batchItemId', 'start/batchItemId'));
    }
    if (nextNodeId) {
      mappings.push(Appmodeler.constantMapping('nextNodeId', nextNodeId));
    }
    let action = FlowGenerator.action(context, node);
    action = {...action,
      'svc:OperationActionMapping': [...mappings, ...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('flowId', context.flowIdPath)
      ],
      'svc:nextaction': nextActionId
    }
    return {actions: [action, ...actions], ...rest}
  })
}

FlowGenerator.nodeBranchNodes = (context, node) => {
  const splitReferences = Library.ensureArray(node['chub:split']).map(link => link['meta3:target'])
  return splitReferences.map(reference => {
    const targetRi = Library.referenceResolve(reference, context.basePath);
    const id = Library.riFragment(targetRi);
    const branchNode = FlowGenerator.findNode(context.nodes, id);
    return branchNode;
  });
}

FlowGenerator.findDefaultNextNodeId = (branchNodes, identifier) => {
  try {
    return branchNodes.find(branchNode => branchNode['dsl:identifier'] === identifier)['rbs:id'];
  } catch (error) {
    throw new Error('Could not find default node for identifier: ' + identifier);
  }
}

FlowGenerator.conditionCheck = (context, node) => {
  const branchNodes = FlowGenerator.nodeBranchNodes(context, node);
  const nodeCode = FlowGenerator.nodeCode(context, node);
  return Library.promiseReduce(branchNodes, (accumulator, branchNode, index) => {
    const branchContext = {...context,
      x: context.x + ((index + 1) * 200),
      y: context.y + (3 * FlowGenerator.yStep),
      callNumber: accumulator.lastCallNumber + 1,
      proceeded: accumulator.proceeded
    }
    return FlowGenerator.actions(branchContext, branchNode).then(({actions, ...rest}) => {
      return {...rest, actions: accumulator.actions.concat(actions)};
    })
  }, {actions: [], lastCallNumber: context.callNumber + 1, proceeded: context.proceeded.concat([node])}).then(result => {
    const nextNodesDescriptions = FlowGenerator.parseConditionExpression(context, node, branchNodes, node['dsl:matchingExpression'])
    const defaultNextNodeId = FlowGenerator.findDefaultNextNodeId(branchNodes, node['dsl:defaultAction']);

    const mappings = [];
    if (context.batchItemIdMapping) {
      mappings.push(Appmodeler.simpleMapping('batchItemId', 'start/batchItemId'));
    }
    if (context.resultCodePath) {
      mappings.push(Appmodeler.simpleMapping('resultCode', context.resultCodePath));
    }
    let action = FlowGenerator.action(context, node);
    action = {...action,
      'svc:OperationActionMapping': [
        ...mappings, ...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('flowId', context.flowIdPath),
        Appmodeler.jsonConstantMapping('nextNode*', nextNodesDescriptions),
        Appmodeler.mapping('defaultNode', {
          'svc:OperationActionMapping': [
            Appmodeler.constantMapping('nextNodeId', defaultNextNodeId)
          ]
        })
      ],
      'svc:nextaction': 'decision_' + node['rbs:id']
    }
    const decision = {...Appmodeler.decisionAction(),
      'svc:code': 'decision' + (context.callNumber + 1),
      'rbs:id': 'decision_' + node['rbs:id'],
      ...Appmodeler.position(context.x, context.y + FlowGenerator.yStep),
      'svc:OperationActionBranch': branchNodes.map((branchNode, index) => {
        return {
          'rbs:id': Library.guid(),
          'svc:name': branchNode['dsl:identifier'],
          'svc:nextaction': branchNode['rbs:id'],
          'svc:position': 100 * (index + 1),
          'svc:OperationActionBranchCondition': [{
            'rbs:id': Library.guid(),
            'svc:operator': '==',
            'svc:OperationActionBranchCondition': [{
              'rbs:id': Library.guid(),
              'svc:param1': nodeCode + '/nextNodeId'
            }, {
              'rbs:id': Library.guid(),
              'svc:param1': '\'' + branchNode['rbs:id'] + '\''
            }]
          }]
        }
      })
    }
    return {...result,
      actions: [action, decision, ...result.actions],
      proceeded: [...result.proceeded, node]
    }
  })
}

FlowGenerator.commandLineCall = (context, node) => {
  const nextContext = {...context,
    resultCodePath: 'commandResultTransform/value',
    y: context.y + FlowGenerator.yStep
  }
  return FlowGenerator.standardActions(nextContext, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest}) => {
    const mappings = [];
    const params = node['dsl:executionParams'];
    if (params !== '~') {
      const executionParams = params.match(/\S+/g).map(part => {
        const result = /%(\w+)%/.exec(part);
        if (result) {
          return {
            isVariable: true,
            value: result[1]
          }
        } else {
          return {
            isVariable: false,
            value: part
          }
        }
      });
      mappings.push(Appmodeler.jsonConstantMapping('executionParams*', executionParams));
    }
    if (context.batchItemIdMapping) {
      mappings.push(Appmodeler.simpleMapping('batchItemId', 'start/batchItemId'))
    }
    let action = FlowGenerator.action(context, node);
    action = {...action,
      'svc:OperationActionMapping': [...mappings, ...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('flowId', context.flowIdPath),
        Appmodeler.constantMapping('nextNodeId', nextNodeId)
      ],
        'svc:nextaction': 'transform_' + node['rbs:id']
    }
    const transform = {...Appmodeler.transform(),
      'rbs:id': 'transform_' + node['rbs:id'],
      'svc:nextaction': nextActionId,
      'svc:code': 'commandResultTransform',
      'svc:tgttype': {'rbs:ref': '../Type/1b24e8c1-5f89-42d8-942c-f834e3fc1acc'},
      ...Appmodeler.position(context.x, context.y + FlowGenerator.yStep),
      'svc:OperationActionMapping': [
        Appmodeler.simpleMapping('value', FlowGenerator.nodeCode(context, node) + '/resultCode'),
      ]

    }
    return {actions: [action, transform, ...actions], lastNode, ...rest}
  })
}

FlowGenerator.parseSplitExpression = (context, node, branchNodes, splitExpression) => {
  try {
    const splitParts = splitExpression.split(';').map(segment => {
      const withoutBrackets = segment.split('[')[1].split(']')[0];
      const parts = withoutBrackets.split(',').map(part => part.trim());
      return parts;
    })
    return splitParts.map(([nodeIdentifier, value]) => {
      const branchNode = branchNodes.find(node => node['dsl:identifier'] === nodeIdentifier);
      if (!branchNode) {
        throw new Error('Could not find node with identifier ' + nodeIdentifier);
      }
      const nodeId = branchNode['rbs:id'];
      const splitOperationId = FlowGenerator.splitOperationId(context.flow['rbs:id'], node['rbs:id'], nodeIdentifier);
      const splitOperationName = FlowGenerator.operationIdToName(splitOperationId);
      return {
        segmentationValue: value,
        branchOperationName: splitOperationName,
        nextNodeId: nodeId
      }
    })
    /*
    return branchNodes.map((branchNode, index) => {
      const identifier = branchNode['dsl:identifier'];
      const nodeId = branchNode['rbs:id'];
      const splitPart = splitParts.find(part => part[0] === identifier);
      if (!splitPart) {
        throw new Error('Could not find split expression for identifier ' + identifier)
      }
      const splitOperationId = FlowGenerator.splitOperationId(context.flow['rbs:id'], node['rbs:id'], splitPart[0]);
      const splitOperationName = FlowGenerator.operationIdToName(splitOperationId);
      return {
        segmentationValue: splitPart[1],
        branchOperationName: splitOperationName,
        nextNodeId: nodeId
      }
    })*/
  } catch (error) {
    throw new Error('Split expression parsed error.')
  }
}

FlowGenerator.parseConditionExpression = (context, node, branchNodes, splitExpression) => {
  try {
    const splitParts = splitExpression.split(';').map(segment => {
      const withoutBrackets = segment.split('[')[1].split(']')[0];
      const parts = withoutBrackets.split(',').map(part => part.trim());
      return parts;
    })
    return splitParts.map((splitPart, index) => {
      const branchNode = branchNodes.find(branchNode => splitPart[0] === branchNode['dsl:identifier']);
      const identifier = branchNode['dsl:identifier'];
      if (!branchNode) {
        throw new Error('Could not find branch node for identifier ' + splitPart[0])
      }
      const splitOperationId = FlowGenerator.splitOperationId(context.flow['rbs:id'], node['rbs:id'], splitPart[0]);
      const splitOperationName = FlowGenerator.operationIdToName(splitOperationId);
      return {
        segmentationValue: splitPart[1],
        nextNodeId: branchNode['rbs:id']
      }
    })
  } catch (error) {
    throw new Error('Split expression parsed error: ' + error.message)
  }
}

FlowGenerator.split = (context, node) => {
  const branchNodes = FlowGenerator.nodeBranchNodes(context,node)
  return Promise.all(branchNodes.map(branchNode => {
    const identifier = branchNode['dsl:identifier'];
    const operationId = FlowGenerator.splitOperationId(context.flow['rbs:id'], node['rbs:id'], identifier);
    const branchContext = {...context,
      callNumber: 1,
      flowIdPath: 'start/flowId',
      operationTemplatePath: 'commhub/concept/template/flow-branch-operation',
      operationId: operationId,
      y: 100
    }
    return FlowGenerator.generateOperation(branchContext, branchNode).then(({lastNode}) => lastNode)
  })).then(lastNodes => {
    const lastNode = lastNodes[0];
    if (!lastNode) {
      throw new Error('Missing join node.')
    }
    const joinNode = FlowGenerator.nextNode(context, lastNode);
    if (!joinNode) {
      throw new Error('Missing join node.')
    }
    const joinContext = {...context,
      endOnJoin: false,
      y: context.y + FlowGenerator.yStep
    }
    return FlowGenerator.standardActions(joinContext, joinNode).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest}) => {
      const splitExpression = node['dsl:splitExpression'];
      const nextNodesDescriptions = FlowGenerator.parseSplitExpression(context, node, branchNodes, splitExpression);
      let splitAction = FlowGenerator.action(context, node);
      splitAction = {...splitAction,
        'svc:OperationActionMapping': [...Library.ensureArray(splitAction['svc:OperationActionMapping']),
          Appmodeler.jsonConstantMapping('nextNode*', nextNodesDescriptions),
          Appmodeler.simpleMapping('flowId', context.flowIdPath)
        ],
        'svc:nextaction': joinNode['rbs:id']
      }
      let joinAction = FlowGenerator.action(joinContext, joinNode)
      joinAction = {...joinAction,
        'svc:OperationActionMapping': [...Library.ensureArray(joinAction['svc:OperationActionMapping']),
          Appmodeler.simpleMapping('flowId', context.flowIdPath),
          Appmodeler.constantMapping('nextNodeId', nextNodeId),
        ],
        'svc:nextaction': nextActionId
      }
      return {actions: [splitAction, joinAction, ...actions], lastNode}
    })
  })
}

FlowGenerator.join = (context, node) => {
  if (context.endOnJoin) {
    return FlowGenerator.generateEnd(context, node).then(({actions}) => {
      return Promise.resolve({actions: actions, lastNode: node});
    })
  }
  const nextContext = {...context,
    endOnJoin: true
  }
  return FlowGenerator.standardActions(nextContext, node).then(({ nextActionId, nextNodeId, actions, lastNode, ...rest }) => {
    const action = {...FlowGenerator.action(context, node),
      'svc:OperationActionMapping': [
        Appmodeler.simpleMapping('flowId', context.flowIdPath),
        Appmodeler.simpleMapping('batchItemId', 'start/batchItemId')
      ],
      'svc:nextaction': nextActionId
    }
    return {actions: [action, ...actions], lastNode, ...rest}
  })
}

FlowGenerator.emailTimedReplayBackHandler = (context, node) => {
  const nodeCode = FlowGenerator.nodeCode(context, node);
  const okNode = FlowGenerator.relationshipTarget(context, node, 'chub:nextNode');
  const expiredNode = FlowGenerator.relationshipTarget(context, node, 'chub:nextNodeTimeout');
  if (!okNode) {
    throw new Error('Missing ok node.')
  }
  const branchNodes = [okNode];
  const mappings = [];
  if (expiredNode) {
    branchNodes.push(expiredNode)
    mappings.push(Appmodeler.constantMapping('expiredNextNodeId', expiredNode['rbs:id']));
  }
  return Library.promiseReduce(branchNodes, (accumulator, branchNode, index) => {
    const branchContext = {...context,
      x: context.x + (index * 200),
      y: context.y + (3 * FlowGenerator.yStep),
      callNumber: accumulator.lastCallNumber + 1,
      proceeded: accumulator.proceeded,
      flowIdPath: FlowGenerator.nodeCode(context, node) + '/flowId'
    }
    return FlowGenerator.actions(branchContext, branchNode).then(({actions, ...rest}) => {
      return {...rest, actions: accumulator.actions.concat(actions)};
    })
  }, {actions: [], lastCallNumber: context.callNumber + 1, proceeded: context.proceeded.concat([node])}).then(result => {
    let action = FlowGenerator.action(context, node);
    action = {...action,
      'svc:OperationActionMapping': [...mappings, ...Library.ensureArray(action['svc:OperationActionMapping']),
        Appmodeler.simpleMapping('flowId', context.flowIdPath),
        Appmodeler.simpleMapping('batchItemId', 'start/batchItemId'),
        Appmodeler.constantMapping('nextNodeId', okNode['rbs:id']),
      ],
      'svc:nextaction': 'decision_' + node['rbs:id']
    }
    const decision = {...Appmodeler.decisionAction(),
      'svc:code': 'decision' + (context.callNumber + 1),
      'rbs:id': 'decision_' + node['rbs:id'],
      ...Appmodeler.position(context.x, context.y + FlowGenerator.yStep),
      'svc:OperationActionBranch': branchNodes.map((branchNode, index) => {
        return {
          'rbs:id': Library.guid(),
          'svc:name': index === 0 ? 'notExpired' : 'expired',
          'svc:nextaction': branchNode['rbs:id'],
          'svc:position': 100 * (index + 1),
          'svc:OperationActionBranchCondition': [{
            'rbs:id': Library.guid(),
            'svc:operator': '==',
            'svc:OperationActionBranchCondition': [{
              'rbs:id': Library.guid(),
              'svc:param1': nodeCode + '/nextNodeId'
            }, {
              'rbs:id': Library.guid(),
              'svc:param1': '\'' + branchNode['rbs:id'] + '\''
            }]
          }]
        }
      })
    }
    return {...result,
      actions: [action, decision, ...result.actions],
      proceeded: [...result.proceeded, node]
    }
  })
}

FlowGenerator.actions = (context, node) => {
  const conceptName = FlowGenerator.nodeConceptName(context.basePath, node);
  if (conceptName === 'start') {
    return FlowGenerator.startNode(context, node);
  } else if (conceptName === 'generate-personalized-email-messages') {
    return FlowGenerator.generateEmails(context, node);
  } else if (conceptName === 'generate-personalized-sms-messages') {
    return FlowGenerator.generateSms(context, node);
  } else if (conceptName === 'send-personalized-email-messages-two-ways' || conceptName === 'send-personalized-email-messages') {
    return FlowGenerator.sendEmails(context, node);
  } else if (conceptName === 'send-personalized-sms-messages') {
    return FlowGenerator.sendSms(context, node);
  } else if (conceptName === 'publishing-of-a-simple-landing-page') {
    return FlowGenerator.publishingOfASimpleLandingPage(context, node);
  } else if (conceptName === 'email-cta-replay-back-handler' || conceptName === 'email-opened-replay-back-handler' || conceptName === 'email-re-opened-replay-back-handler') {
    return FlowGenerator.emailTimedReplayBackHandler(context, node);
  } else if (conceptName === 'processing-split-based-on-data-input') {
    return FlowGenerator.split(context, node);
  } else if (conceptName === 'join-of-processing-and-data-structure') {
    return FlowGenerator.join(context, node);
  } else if (conceptName === 'generate-personalized-email-message') {
    return FlowGenerator.generateEmail(context, node)
  } else if (conceptName === 'condition-check' || conceptName === 'multi-choice') {
    return FlowGenerator.conditionCheck(context, node);
  } else if (conceptName === 'command-line-call') {
    return FlowGenerator.commandLineCall(context, node);
  } else {
    return FlowGenerator.standardNode(context, node);
  }
}

FlowGenerator.splitOperationId = (operationId, splitId, branchIdentifier) => {
  return operationId + '-split-' + splitId + '-' + branchIdentifier;
}

FlowGenerator.operationIdToName = (operationId) => {
  return 'GOP_' + operationId.replace(/-/g,"_");
}

FlowGenerator.generateStart = (context, node) => {
  return {actions: [{
    'rbs:id': 'start',
    'svc:code': 'start',
    'svc:kind': 'start',
    'svc:nextaction': node['rbs:id'],
    'svc:posx': String(context.x + 10),
    'svc:posy': String(context.y)
  }]}
}

FlowGenerator.generateEnd = (context, node) => {
  const mappings = [];
  if (context.endHtmlMappingPath) {
    mappings.push(Appmodeler.simpleMapping('html', context.endHtmlMappingPath))
  }
  const result = {
    'rbs:id': 'end_' + node['rbs:id'],
    'svc:code': 'end',
    'svc:kind': 'end',
    'svc:posx': String(context.x + 10),
    'svc:posy': String(context.y)
  }
  if (mappings.length !== 0) {
    result['svc:OperationActionMapping'] = mappings;
  }
  return Promise.resolve({actions: [result]})
}

FlowGenerator.generateOperation = (context, startNode) => {
  const result = FlowGenerator.generateStart(context, startNode);
  const startActions = result.actions;
  const nextContext = {...context, y: context.y + FlowGenerator.yStep};
  return FlowGenerator.actions(nextContext, startNode).then(({actions, lastNode, proceeded}) => {
    const resultActions = [...startActions, ...actions];
    const id = context.operationId;
    const name = CHub.flowName(id);
    return context.storage.getFile(context.operationTemplatePath).then(flowOperation => {
      const operation = {...flowOperation['meta3:properties'],
        'svc:name': name,
        'rbs:id': id,
        'svc:OperationAction': resultActions
      }
      const path = context.modelPath + 'gop/Operation/' + id;
      console.log('store operation', path, operation);
      return Library.storeProperties(path, operation).then(() => {
        return {lastNode};
      })
    })
  })
}

FlowGenerator.generate = (storage, basePath, flow, modelPath) => {
  const nodes = Library.ensureArray(flow['chub:node']);
  const startNode = nodes.find(node => FlowGenerator.nodeConceptName(basePath, node) === 'start');
  if (!startNode) {
    throw new Error('There is no start node.')
  }

  const context = {
    modelPath: modelPath,
    basePath: basePath,
    operationId: flow['rbs:id'],
    operationTemplatePath: 'commhub/concept/template/flow-operation',
    flow: flow,
    isFirstNode: true,
    callNumber: 1,
    flowIdPath: 'start/flowId',
    nodes: nodes,
    x: 150,
    y: 100,
    storage: storage,
    endOnJoin: true,
    proceeded: []
  }
  return FlowGenerator.generateOperation(context, startNode);
}

export {FlowGenerator};
