import React from "react";

import {MC} from './MC.js';

class Download extends React.Component {

  componentDidMount() {
    this.runDownload();
  }

  componentDidUpdate() {
    this.runDownload();
  }

  runDownload() {
    let data =  MC.getFieldParamValue(this.props.data.param, 'value/@data', this.props.iteration);
    if (!MC.isNull(data)) {
      MC.putFieldParamValue(this.props.data.param, 'value/@data', this.props.iteration, null);
      let uri = this.base64toBlob(data, "octet/stream");
      let fileName =  MC.getFieldParamValue(this.props.data.param, 'value/@fileName', this.props.iteration);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(uri, fileName);
      } else {
        uri = window.URL.createObjectURL(uri);
        let link = document.createElement('a');
        if (typeof link.download === 'string') {
          document.body.appendChild(link); // Firefox requires the link to be in the body
          link.download = fileName;
          link.href = uri;
          link.click();
          document.body.removeChild(link); // remove the link when done
        } else {
          location.replace(uri);
        }
      }
    }
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0 ; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  render() {
    return (
      <span data-widget-i-name={this.props.data.id}/>
    );
  }

}

export {Download};