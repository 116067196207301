import createReactClass from 'create-react-class';
import {UndoRedoButtons} from './undo-redo-buttons.jsx';
import {SaveButton} from './save-button.jsx';
import React from "../../miniclient/in/client/node_modules/react";
import ReactDOM from "../../miniclient/in/client/node_modules/react-dom";
import {Library} from "./library.js";

const PropertiesEditor = createReactClass({
  getInitialState: function() {
    return {}
  },
  load: function(context) {
    const saveButton = SaveButton.prototype.load(context);
    const undoRedoButtons = UndoRedoButtons.prototype.load(context);
    const value = context.file['meta3:properties'];
    return Promise.resolve({file: context.file, saveButton, undoRedoButtons, value});
  },
  setValue: function(value) {
    const component = this;
    const cursor = component.aceEditor.selection.getCursor();
    component.withoutChanges = true;
    component.aceEditor.setValue(JSON.stringify(value, null, 2));
    component.withoutChanges = false;
    component.aceEditor.clearSelection()
    component.aceEditor.selection.moveCursorTo(cursor.row, cursor.column)
  },
  handleUpdateValue(stringValue) {
    const component = this;
    const props = component.props;
    let value;
    try {
      value = JSON.parse(stringValue);
    } catch (error) {
      component.setState({error: 'Parse error: ' + error.message})
    }
    if (value) {
      component.props.onUpdate({type: 'fileReplace', path: props.file['meta3:path'], 'properties': value});
      component.setState({error: undefined})
    }
  },
  handleChange() {
    const component = this;
    const stringValue = component.aceEditor.getValue();
    if (component.changeTimeout) {
      clearTimeout(component.changeTimeout);
    }
    component.changeTimeout = setTimeout(() => {
      component.handleUpdateValue(stringValue);
      component.changeTimeout = undefined;
    }, 1000);
  },
  componentDidUpdate: function(prevProps, prevState, snapshot) {
    const component = this;
    const props = component.props;

    if (!component.aceEditor) {
      return;
    }
    const file = props.file;
    const value = props.value;
    const stringValue = component.aceEditor.getValue();
    let editorValue;
    try {
      editorValue = JSON.parse(stringValue);
    } catch (error) {

    }
    if (editorValue && !component.changeTimeout && !Library.equals(value, editorValue)) {
      component.setValue(value);
    }
  },
  componentDidMount: function() {
    const component = this;
    const props = component.props;
    const file = props.file;
    const node = ReactDOM.findDOMNode(component);
    const aceEditorNode = node.childNodes[1].childNodes[1].childNodes[0];
    component.aceEditor = ace.edit(aceEditorNode);
    const session = component.aceEditor.getSession();
    session.setMode("ace/mode/json");
    session.on('change', event => {
      if (!component.withoutChanges) {
        component.handleChange();
      }
    });
    component.aceEditor.$blockScrolling = Infinity
    component.aceEditor.commands.addCommand({
      name: 'follow ref',
      bindKey: {win: 'Ctrl-R',  mac: 'Command-R'},
      exec: function(aceEditor) {
        const cursor = aceEditor.selection.getCursor();
        const value = aceEditor.getValue();
        const lines = value.split(/\r\n|\r|\n/);
        const currentLine = lines[cursor.row];
        const result = /"rbs:ref":\s*"([^"]*)"/.exec(currentLine);
        if (result) {
          const relativePath = result[1];
          const basePath = component.props.file['meta3:path'];
          const resolvedPath = Library.resolveRelativePath(basePath, relativePath);
          props.onUpdate({type: 'routeTo', ri: 'properties-editor/' + resolvedPath});
        }
      },
      readOnly: true
    });
    component.aceEditor.commands.addCommand({
      name: 'generate GUID',
      bindKey: {win: 'Ctrl-G',  mac: 'Command-G'},
      exec: function(aceEditor) {
        aceEditor.insert(Library.guid());
      },
      readOnly: false
    });
    component.setValue(props.value);
  },
  render: function() {
    const component = this;
    const props = component.props;
    const error = component.state.error;
    const errorStyle = {
      height: '20px',
      color: 'red'
    };
    const aceEditorStyle = {
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px'
    };
    const boxEditorStyle = {position: 'relative', height:  '600px'};
    return (
      <div>
        <div className="meta menu top component">
          <div className="meta item">
            <SaveButton {...props.saveButton} onUpdate={props.onUpdate}/>
          </div>
          <div className="meta item">
            <UndoRedoButtons {...props.undoRedoButtons} onUpdate={props.onUpdate}/>
          </div>
        </div>
        <div>
          <div style={errorStyle}>{error}</div>
          <div style={boxEditorStyle}>
            <div style={aceEditorStyle}/>
          </div>
        </div>
      </div>
    );
  }
})

export {PropertiesEditor};
