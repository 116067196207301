import React from "react";

import {MC} from "./MC.js";
import {MCCache} from "./MCCache.js";
import {MCHistory} from "./MCHistory.js";
import {Widget} from "./Widget.jsx";
import {ProgressBar} from "./ProgressBar.jsx";

class Form extends React.Component {

  defaultActionField = null;
  runReady = false;
  state = {width: this.props.element.offsetWidth};
  serverEvents = {}

  componentWillMount() {
    MCCache.put('lastReactForm', this);
    if (this.props.runReady) {
      this.runReady = true;
    }
  }

  componentWillReceiveProps(nextProps) {
    MCCache.put('lastReactForm', this);
    if (nextProps.runReady) {
      this.runReady = true;
    }
  }

  handleSubmitDefault = (e) => {
    e.preventDefault();
  };

  handleSubmit = (field, iteration) => {
    if (MC.isModelerActive(field)) {
      return;
    }
    var self = this;
    var allValues = true;
    var submit = false;
    if (['submit', 'submitbydefault'].indexOf(field.param['@behavior']) > -1) {
      this.props.form.flow.focusedOnFirst = false;
      MC.validateFieldTree(this.props.form, iteration).then(function (valid) {
        if (valid) {
          if (MC.isFunction(self.props.form.onSubmit)) {
            self.props.form.onSubmit.call(self.props.form.flow, field, allValues, iteration, null);
          }
        } else {
          self.props.form.flow.reactFlow.setState({runReady: false});
          self.forceUpdate();
        }
      }).catch(function (exception) {
        if (MC.isPlainObject(exception) && !MC.isNull(exception.type)) {
          self.props.form.flow.endOperationException(exception.type, exception.message, exception.input, exception.output, exception.log);
        } else {
          self.props.form.flow.endOperationException('SYS_UnrecoverableRuntimeExc', exception);
        }
      });
    } else if (['cancel', 'cancelbydefault'].indexOf(field.param['@behavior']) > -1) {
      submit = true;
      allValues = false;
    } else if (field.param['@behavior'] == 'store') {
      submit = true;
    }
    if (submit && MC.isFunction(this.props.form.onSubmit)) {
      this.props.form.onSubmit.call(this.props.form.flow, field, allValues, iteration, null);
    }
  };

  closeFeedback(i) {
    this.props.form.feedback.splice(i, 1);
    this.props.form.flow.reactFlow.setState({runReady: false});
    this.forceUpdate();
  }

  componentDidMount() {
    window.addEventListener("message", this.onMessage, false);
    this.runReadyConditional();
    window.addEventListener("resize", this.runResize);
    this.setState({width: this.props.element.offsetWidth});
  }

  runReadyConditional() {
    if (MC.isModelerActive(this.props.form)) {
      return;
    }
    if (this.runReady) {
      if (MC.isFunction(this.props.form.flow.eventForm)) {
        this.props.form.flow.eventForm(null, 'ready', null);
      }
      var urlarr = window.location.href.split("/");
      window.postMessage({name: 'MNC-INTERNAL.READY-RUN', instanceId: this.props.form.flow.instanceId}, urlarr[0] + "//" + urlarr[2]);
      this.props.form.flow.initLogicTimers();
      this.unregisterServerEvents();
      this.registerServerEvents();
      this.runReady = false;
    }
    if (MC.isFunction(this.props.form.flow.afterRenderForm)) { // this should be called after every rerender
      this.props.form.flow.afterRenderForm(this.props.form);
    }
  }

  componentWillUnmount() {
    this.props.form.flow.clearLogicTimers()
    window.removeEventListener("resize", this.runResize)
    window.removeEventListener("message", this.onMessage)
    this.unregisterServerEvents()
  }

  onMessage = (e) => {
    if (e.data && MC.isPlainObject(e.data)) {
      var name = e.data.name;
      if (!MC.isNull(name) && name != '') {
        if (MC.isFunction(this.props.form.flow.eventForm)) {
          this.props.form.flow.eventForm(null, name, null, null, e.data);
        }
      }
    }
  };

  runResize = () => {
    if (this.props.form.flow.reactFlow) {
      this.props.form.flow.reactFlow.setState({runReady: false});
    }
    this.setState({width: this.props.element.offsetWidth});
  }

  componentDidUpdate() {
    this.runReadyConditional();
  }

  getResolution(field) {
    if (MC.isModelerReactAvailable(field)) {
      var modelerReact = MC.getModelerReact(field);
      return modelerReact.state.resolution;
    }
    return MC.getResolutionFromWidth(this.state.width);
  }

  buildSubFields(field) {
    hrows = '';
    var reslog = 'width: ' + this.state.width;
    var resolution = this.getResolution(field);
    reslog += ', resolution: ' + resolution;
    resolution = MC.getAvailableResolution(resolution, field);
    reslog += ', used resolution: ' + (resolution ? resolution : 'autolayout');
    MCHistory.log(MCHistory.T_INFO, reslog, field.flow.debug);
    if (field.fields) {
      var rows = MC.splitFieldsIntoRows(field.fields, resolution);
      var hrows = [];
      for (var i = 0; i < rows.length; i++) {
        var maxX = MC.getMaxX(0, rows[i]);
        var hrow = [];
        var lastX = 0;
        for (var ii = 0; ii < rows[i].length; ii++) {
          var subField = rows[i][ii];
          let offsetDiv;
          if (MC.isNull(resolution)) {
            if (subField.x > lastX + Math.round(maxX/12)) {
              var cls = "ui " + MC.getFieldWideClass(maxX, subField.x - lastX) + " wide column field";
              offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>;
              if (MC.isModelerActive(this.props.form)) {
                subField.autoLayoutOffset =  MC.getFieldWideClassAsInt(maxX, subField.x - lastX);
              }
            }
            if (MC.isModelerActive(this.props.form)) {
              subField.autoLayoutColumns =  MC.getFieldWideClassAsInt(maxX, subField.width);
            }
          } else {
            var grid = MC.getFieldGrid(subField, resolution);
            if (grid.offset > 0) {
              var cls = "ui " + MC.getFieldWideClassFromInt(grid.offset) + " wide column field";
              offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>;
            }
          }
          subField.onSubmit = this.handleSubmit;
          subField.flow = this.props.form.flow;
          hrow.push(<Widget key={subField.rbsid} widget={subField} maxX={maxX} ref={subField.rbsid} resolution={resolution} offsetDiv={offsetDiv}/>);
          lastX = subField.x + subField.width;
        }
        var inlineCss = {};
        if (!MC.isRowVisible(rows[i], resolution)) {
          inlineCss.display = 'none';
        }
        hrows.push(<div key={i} className="ui row" style={inlineCss}>{hrow}</div>);
      }
    }
    return hrows;
  }

  handleKeyUp = (e) => {
    if (e.key == 'Enter') {
      if (e.target.tagName != 'INPUT' || e.target.type == 'checkbox' || e.target.type == 'radio') {
        return;
      }
      this.defaultActionField = this.findDefaultAction(this.props.form.fields);
      if (this.defaultActionField) {
        this.handleSubmit(this.defaultActionField);
      }
    }
  };

  findDefaultAction(fields) {
    for (var i=0; i<fields.length; i++) {
      if (MC.getFieldParamBooleanValue(fields[i].param, '@defaultAction', null)) {
        return fields[i];
      } else {
        var res = this.findDefaultAction(fields[i].fields);
        if (res) {
          return res;
        }
      }
    }
    return false;
  }

  registerServerEvents() {
    let self = this
    if (this.props.form.param && this.props.form.param['serverEvents*']) {
      for (let event of MC.asArray(this.props.form.param['serverEvents*'])) {
        let key = event.type + '$' + (event.key ? event.key : '') + '$' + (event.itemId ? event.itemId : '')
        if (!this.serverEvents[key]) {
          let source = new EventSource(rbBaseUri + 'sse?event=' + key)
          source.addEventListener(key, (e) => { self.onServerMessage(e) }, false)
          this.serverEvents[key] = source
        }
      }
      
    }
  }

  onServerMessage(e) {
    if (e.type) {
      const tokens = e.type.split('$')
      let data = {type: tokens[0], key: tokens[1], itemId: tokens[2]}
      if (e.data) {
        data.data = JSON.parse(e.data).props
      }
      if (MC.isFunction(this.props.form.flow.eventForm)) {
        this.props.form.flow.eventForm(null, tokens[0], null, null, data)
      }
      console.log(tokens)
    }
  }

  unregisterServerEvents() {
    for (let eventKey in this.serverEvents) {
      this.serverEvents[eventKey].close()
      delete this.serverEvents[eventKey]
    }
  }

  render() {
    var cssclass = (this.props.form.cssclass ? this.props.form.cssclass + ' ' : '') + 'ui form';
    var messages = [];
    if (this.props.form.messages) {
      for (var i = 0; i < this.props.form.messages.length; i++) {
        var message = this.props.form.messages[i];
        messages.push(<div className={message.cssclass} key={i}>{message.text}</div>);
      }
    }
    var progressBar = '';
    if (this.props.form.progressBar) {
      progressBar = <ProgressBar data={this.props.form.progressBar}/>;
    }
    if (this.props.form.feedback && Array.isArray(this.props.form.feedback)) {
      var feedbacks = [];
      for (var i=0; i<this.props.form.feedback.length; i++) {
        var feedback = this.props.form.feedback[i];
        var color = '';
        var icon = 'info';
        switch (feedback.level) {
          case 'error': color = 'red'; icon = 'warning sign'; break;
          case 'warning': color = 'yellow'; icon = 'warning'; break;
          case 'success': color = 'green'; icon = 'checkmark'; break;
        }
        var cls = 'ui ' + color + ' icon message';
        var iconCls = icon + ' icon';
        var help = null;
        if (!MC.isNull(feedback.help) && feedback.help !== '') {
          help = <p>{feedback.help}</p>;
        }
        feedbacks.push(
          <div className={cls} key={i}>
            <i className="close icon" onClick={this.closeFeedback.bind(this, i)}></i>
            <i className={iconCls}></i>
            <div className="content">
              <div className="header">{feedback.title}</div>
              {help}
            </div>
          </div>
        );
      }
    }
    var interactive;
    if (MC.isModelerReactAvailable(this.props.form)) {
      var modelerReact = MC.getModelerReact(this.props.form)
      var interactiveField = modelerReact.state.interactiveField;
      if (interactiveField) {
        var resolution = this.getResolution(interactiveField);
        resolution = MC.getAvailableResolution(resolution, this.props.form);
        interactive =         <div className="ui row">
                                  <Widget widget={interactiveField} ref={interactiveField.id} resolution={resolution}/>
                              </div>
      }
    }
    var RootElem = this.props.embedded ? 'div' : 'form';
    return (
      <RootElem className={cssclass} role="form" id={this.props.form.id} onSubmit={this.handleSubmitDefault} onKeyUp={this.handleKeyUp} data-widget-id={this.props.form.id}>
        {progressBar}
        <div className="space20"></div>
        {feedbacks}
        <div className="ui twelve column grid stackable">
          {this.buildSubFields(this.props.form)}
        </div>
        <div className="ui">
          {messages}
        </div>
        {interactive}
      </RootElem>);
  }

}

export {Form};