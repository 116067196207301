import math from "mathjs"
import {DateTime} from "luxon"

import {MC} from './MC.js'
import {MCHistory} from "./MCHistory.js"
import {Duration} from "./Duration.js"

let Expression = function(data, cData, opts) {
  this.data = data;
  this.cData = cData;
  this.opts = opts;
  this.errorMsg = null;
  this.trace = {};

  if (!MC.isPlainObject(this.opts)) {
    this.opts = {};
  }

  this.error = function(msg) {
    if (!MC.isNull(msg) && this.errorMsg == null) {
      this.errorMsg = msg;
      this.trace.error = msg;
    }
  };

  this.clearError = function() {
    this.errorMsg = null;
  };

  this.getError = function() {
    return this.errorMsg;
  };

  this.getTrace = function() {
    if (MC.isEmptyObject(this.trace)) {
      return null;
    } else {
      return this.trace;
    }
  };

  this.getTraceAsPaths = function() {
    let result = {};
    this.getSubtraceAsPaths(result, this.trace, this.trace.path);   
    return result;
  };

  this.getSubtraceAsPaths = function(result, trace, path) {
    if (trace.subpath && trace.subpath.length > 0) {
      for (let subTrace of trace.subpath) {
        this.getSubtraceAsPaths(result, subTrace, trace.path);   
      }
    } else {
      if (result[path]) {
        if (!Array.isArray(result[path])) {
          result[path] = [result[path]]; 
        }
        result[path].push(trace);
      } else {
        result[path] = trace;
      }
    }
  };

  this.evaluate = function(path) {
    if (this.data.target) {
      if (!path) {
        path = this.data.target;
      }
      this.trace.path = path;
      var subpaths = {};
      if (this.data.expr) {
        this.trace.subpath = [];
        for (var i=0; i<this.data.expr.length; i++) {
          var expression = new Expression(this.data.expr[i], this.cData, this.opts);
          if (this.data.expr[i].target) {
            var nextPath = (path ? path + '/' : '') + this.data.expr[i].target;
            var value = expression.evaluate(nextPath);
            this.error(expression.getError());
            if (expression.data.expr && expression.data.expr[0].operator == 'jsonToData') {
              // special case
              subpaths[nextPath] = value;
            } else if (value && typeof(value) == 'object' && !Array.isArray(value)) {
              var newObject = {};
              var props = Object.getOwnPropertyNames(value);
              for (var p=0; p<props.length; p++) {
                if (!MC.isNull(value[props[p]]) || this.opts.nullMode) {
                  if (props[p].startsWith(nextPath)) {
                    if (MC.isNull(newObject[props[p]])) {
                      subpaths[props[p]] = value[props[p]];
                    }
                  } else {
                    if (MC.isNull(subpaths[props[p]])) {
                      newObject[props[p]] = value[props[p]];
                    }
                  }
                }
              }
              if ((!MC.isNull(newObject) || this.opts.nullMode) && MC.isNull(subpaths[nextPath])) {
                subpaths[nextPath] = newObject;
              }
            } else {
              if ((!MC.isNull(value) || this.opts.nullMode) && MC.isNull(subpaths[nextPath])) {
                subpaths[nextPath] = value;
              }
            }
          } else {
            if (path.indexOf('/') > -1) {
              let res = expression.evaluate();
              this.error(expression.getError());
              if ((!MC.isNull(res) || this.opts.nullMode) && MC.isNull(subpaths)) {
                subpaths = res;
              }
            } else {
              let res = expression.evaluate();
              this.error(expression.getError());
              if ((!MC.isNull(res) || this.opts.nullMode) && MC.isNull(subpaths[path])) {
                subpaths[path] = res;
              }
            }
          }
          this.trace.subpath.push(expression.getTrace());
        }
        this.trace.path = path;
        if (!MC.isNull(subpaths) || this.opts.nullMode) {
          return subpaths;
        } else {
          return null;
        }
      }
    } else if (this.data.operator) {
      this.trace.operator = this.data.operator;
      if ('submittedBy' == this.data.operator) {
        if (Array.isArray(this.data.expr) && MC.isPlainObject(this.data.expr[0]) && !MC.isNull(this.data.expr[0].source)) {
          if (!this.data.expr[0].source.endsWith('/@submittedBy')) {
            this.data.expr[0].source = this.data.expr[0].source + '/@submittedBy';
          }
          this.opts.submittedByPath = this.data.expr[0].source;
        }
      }
      let res;
      if (['--- comment ---', 'try', 'if', 'select', 'every', 'some', 'sort', 'filter', 'find', 'map', 'quote'].indexOf(this.data.operator) > -1) {
        res = this.evaluateOperator(this.data.expr);
      } else {
        let argsToPass = [];
        let subTrace = [];
        if (this.data.expr) {
          for (var i=0; i<this.data.expr.length; i++) {
            var expression = new Expression(this.data.expr[i], this.cData, this.opts);
            argsToPass[i] = expression.evaluate();
            this.error(expression.getError());
            subTrace.push(expression.getTrace());
          }
        }
        if (subTrace.length > 0) {
          this.trace.args = subTrace;
        }
        res = this.evaluateOperator(argsToPass);
      }
      if (!MC.isNull(res)) {
        this.trace.result = res;
      } else {
        this.trace.result = null;
      }
      return res;
    } else if (this.data.source) {
      let res;
      res = this.evaluateSource();
      if (!MC.isNull(res)) {
        this.trace.result = res;
      } else {
        this.trace.result = null;
      }
      return res;
    } else {
      this.error('Expression must have operator or source defined!');
      return false;
    }
  };

  this.setBase = function(relativeBase, relativeToBase) {
    if (relativeBase == null) {
      this.opts.base.shift();
      this.opts.position.shift();
      this.opts.positionValue.shift();
    } else if (relativeBase.length == 0) {
      this.opts.base.unshift("");
      this.opts.position.unshift([]);
      this.opts.positionValue.unshift(null);
    } else if (relativeToBase == -1) {
      this.opts.base.unshift(relativeBase);
      this.opts.position.unshift([]);
      this.opts.positionValue.unshift(null);
    } else {
      var fromBase = this.opts.base.slice().reverse()[relativeToBase - 1];
      this.opts.base.unshift(this.relativize(fromBase, relativeBase));
      var fromPosition = this.opts.position.slice().reverse()[relativeToBase - 1];
      var shared = MC.collectionDepth(MC.commonAncestor(fromBase, relativeBase));
      var newPosition = fromPosition.slice(0, shared);
      this.opts.position.unshift(newPosition);
      this.opts.positionValue.unshift(null);
    }
  };

  this.bases = function() {
    return this.opts.base;
  };

  this.base = function() {
    return this.opts.base[0];
  };

  this.setPosition = function(position) {
    if (position == null) {
      this.opts.position[0].pop();
    } else {
      this.opts.position[0].push(position);
    }
  };

  this.setPositionValue = function(value) {
    if (value == null) {
      this.opts.positionValue[0] = null;
    } else {
      this.opts.positionValue[0] = value;
    }
  };

  this.position = function() {
    return this.opts.position[0];
  };

  this.positions = function() {
    return this.opts.position;
  };

  this.positionValues = function() {
    return this.opts.positionValue;
  };

  this.enterBaseContext = function() {
    if (!Array.isArray(this.opts.base)) {
      this.opts.base = [];
    }
    if (!Array.isArray(this.opts.position)) {
      this.opts.position = [];
    }
    if (!Array.isArray(this.opts.positionValue)) {
      this.opts.positionValue = [];
    }
    var newBase = null;
    var relativeToBase = -1;
    if (Array.isArray(this.data.expr) && MC.isPlainObject(this.data.expr[0])) {
      if (!MC.isNull(this.data.expr[0].source)) {
        newBase = this.data.expr[0].source;
      } else if (!MC.isNull(this.data.expr[0].operator) && Array.isArray(this.data.expr[0].expr)) {
        var functionName = this.data.expr[0].operator;
        var fa1e = this.data.expr[0].expr[0];
        if (!MC.isNull(fa1e.source)) {
          var v1s = fa1e.source;
          if (v1s.startsWith("'")) {
            v1s = v1s.substring(1, v1s.length - 1);
          }
          if (functionName == 'path') {
            newBase = v1s;
          } else if (functionName == 'relative') {
            newBase = v1s;
            if (newBase.startsWith("$v")) {
              var i = newBase.indexOf("/");
              relativeToBase = parseInt(i == -1 ? newBase.substring(2) : newBase.substring(2, i));
              newBase = i == -1 ? "." : newBase.substring(i + 1);
            } else {
              relativeToBase = this.bases().length;
            }
          }
        }
      }
    }
    if (newBase == null) {
      this.setBase("", -1);
    } else {
      this.setBase(newBase, relativeToBase);
    }
    return newBase;
  };

  this.leaveBaseContext = function(base) {
    this.setBase(null, 0);
  };

  this.evaluateSource = function(data) {
    var source = data == null ? this.data.source : data.source;
    this.trace.source = source;
    var singleRoot = false;
    if (this.opts && this.opts.singleRoot === true) {
      singleRoot = true;
    }
    if (source.startsWith("'")) {
      return source.substring(1, source.length - 1);
    } else if (MC.isNumeric(source)) {
      return MC.getNumberAsString(math.bignumber(source));
    } else if (source == 'true') {
      return true;
    } else if (source == 'false') {
      return false;
    } else if (source == 'null') {
      return null;
    } else if (source == 'empty') {
      return '';
    } else if (source.startsWith('date(') && source.endsWith(')')) {
      return source.substring(5, source.length-1);
    } else if (source.startsWith('dateTime(') && source.endsWith(')')) {
      return source.substring(9, source.length-1);
    } else if (source.startsWith('time(') && source.endsWith(')')) {
      return source.substring(5, source.length-1);
    } else if (source.startsWith('duration(') && source.endsWith(')')) {
      var dur = new Duration();
      var val = source.substring(9, source.length-1);
      dur.parseIsoString(source.substring(9, source.length-1));
      if (!dur.isValidDuration()) {
        this.error(val + ' is not valid duration!');
      }
      return dur.toIsoString();
    } else if (source.indexOf('/') > -1 || singleRoot) {
      var tokens = source.split("/");
      var rootPath = singleRoot ? tokens[0] : tokens[0] + '/' + tokens[1];
      var value = this.cData[rootPath];
      if (tokens.length == 2 && !singleRoot || tokens.length == 1 && singleRoot) {
        if (value && value.hasOwnProperty('@isField') && value['@isField'] == true) {
          return value['value'];
        } else {
          if (MC.isPlainObject(value) && MC.isEmptyObject(value)) {
            return undefined;
          } else {
            return value;
          }
        }
      } else {
        if (!singleRoot) {
          tokens.shift();
        }
        var isCollection = tokens[0].endsWith('*');
        tokens.shift();
        return this.getValue(value, tokens, isCollection);
      }
    } else {
      var result = {};
      for (let key in this.cData) {
        if (key.startsWith(source)) {
          result[key.substring(key.indexOf('/') + 1)] = this.cData[key];
        }
      }
      if (MC.isEmptyObject(result)) {
        return null;
      } else {
        return result;
      }
    }
  };

  this.getValue = function(value, tokens, isCollection) {
    var result;
    if (isCollection && Array.isArray(value)) {
      result = [];
      for (var i=0; i<value.length; i++) {
        if (tokens.length > 1) {
          var subTokens = tokens.slice();
          subTokens.shift();
          result[i] = this.getValue(this.getValueByKeyIgnoreStar(value[i], tokens[0]), subTokens, tokens[0].endsWith('*'));
        } else {
          var item = this.getValueByKeyIgnoreStar(value[i], tokens[0]);
          if (item && item.hasOwnProperty('@isField') && item['@isField'] == true) {
            result[i] = MC.isNull(item['value']) ? null : item['value'];
          } else {
            result[i] = MC.isNull(item) ? null : item;
          }
        }
      }
      return result;
    } else if (!MC.isNull(value)) {
      if (Array.isArray(value)) {
        value = value[0];
      }
      result = this.getValueByKeyIgnoreStar(value, tokens[0]);
      if (result == undefined && value['@customwidget'] && MC.isPlainObject(value['value'])) {
        result = value['value'][tokens[0]];
      }
      if (tokens.length > 1) {
        var subTokens = tokens.slice();
        subTokens.shift();
        result = this.getValue(result, subTokens, tokens[0].endsWith('*'));
      } else {
        if (result && result.hasOwnProperty('@isField') && result['@isField'] == true) {
          result = result['value'];
        }
      }
      if (!MC.isNull(result)) {
        if (isCollection) {
          var coll = [];
          coll.push(result);
          return coll;
        } else {
          return result;
        }
      } else {
        return null;
      }
    }
  };

  this.getValueByKeyIgnoreStar = function(object, key) {
    var result = object[key];
    if (result == undefined) {
      if (key.endsWith('*')) {
        result = object[key.substring(0, key.length - 1)];
        if (result != undefined && !Array.isArray(result)) {
          result = [result];
        }
      } else {
        result = object[key + '*'];
        if (result != undefined && Array.isArray(result)) {
          result = result[0];
        }
      }
    }
    return result;
  };

  this.evaluateOperator = function(args) {
    switch (this.data.operator) {
      case '>': return this.scalarOperator(this.operatorGreater, args); break;
      case '<': return this.scalarOperator(this.operatorLower, args); break;
      case '>=': return this.scalarOperator(this.operatorGreaterEquals, args); break;
      case '<=': return this.scalarOperator(this.operatorLowerEquals, args); break;
      case '==': return this.scalarOperator(this.operatorEquals, args); break;
      case '!=': return this.scalarOperator(this.operatorNotEquals, args); break;
      case '+': return this.scalarOperator(this.operatorPlus, args); break;
      case '-':
      case '−': return this.scalarOperator(this.operatorMinus, args); break;
      case '*': return this.scalarOperator(this.operatorMultiply, args); break;
      case '/': return this.scalarOperator(this.operatorDivide, args); break;
      case '--- comment ---': return; break;
      case 'abs': return  this.scalarOperator(this.operatorAbs, args); break;
      case 'addDuration': return this.scalarOperator(this.operatorAddDuration, args); break;
      case 'and': return this.scalarOperator(this.operatorAnd, args); break;
      case 'appCfgVal': return this.scalarOperator(this.operatorAppCfgVal, args); break;
      case 'appCfgVal2': return this.scalarOperator(this.operatorAppCfgVal2, args); break;
      case 'avg': return this.operatorAvg(args); break;
      case 'cast': return this.scalarOperator(this.operatorCast, args); break;
      case 'collection': return this.operatorCollection(args); break;
      case 'collectionItem': return this.operatorCollectionItem(args); break;
      case 'collectionSize': return this.operatorCollectionSize(args); break;
      case 'collectionUnwrap': return this.operatorCollectionUnwrap(args); break;
      case 'concat': return this.scalarOperator(this.operatorConcat, args); break;
      case 'contains': return this.operatorContains(args); break;
      case 'count': return this.operatorCount(args); break;
      case 'currentDate': return this.operatorCurrentDate(); break;
      case 'dataNode': return this.operatorDataNode(args); break;
      case 'dataToJson': return this.scalarOperator(this.operatorDataToJson, args); break;
      case 'dataToXml': return this.scalarOperator(this.operatorDataToXml, args); break;
      case 'decodeHex': return this.scalarOperator(this.operatorDecodeHex, args); break;
      case 'delete': return this.operatorDelete(args); break;
      case 'distinct': return this.operatorDistinct(args); break;
      case 'div': return this.scalarOperator(this.operatorDiv, args); break;
      case 'durationBetween': return this.scalarOperator(this.operatorDurationBetween, args); break;
      case 'durationComponent': return this.scalarOperator(this.operatorDurationComponent, args); break;
      case 'emptyToNull': return this.scalarOperator(this.operatorEmptyToNull, args); break;
      case 'encodeHex': return this.scalarOperator(this.operatorEncodeHex, args); break;
      case 'endsWith': return this.scalarOperator(this.operatorEndsWith, args); break;
      case 'escapeHtml': return this.scalarOperator(this.operatorEscapeHtml, args); break;
      case 'every': return this.operatorEvery(args); break;
      case 'exists': return this.operatorExists(args); break;
      case 'false': return false; break;
      case 'fill': return this.operatorFill(args); break;
      case 'filter': return this.operatorFilter(args); break;
      case 'fillTimezone': return this.scalarOperator(this.operatorFillTimezone, args); break;
      case 'find': return this.operatorFind(args); break;
      case 'firstNonNull': return this.scalarOperator(this.operatorFirstNonNull, args); break;
      case 'fromMilliseconds': return this.scalarOperator(this.operatorFromMilliseconds, args); break;
      case 'flatten': return this.operatorFlatten(args); break;
      case 'formatDate': return this.scalarOperator(this.operatorFormatDate, args); break;
      case 'hasData': return this.scalarOperator(this.operatorHasData, args); break;
      case 'ibanToDisplay': return this.scalarOperator(this.operatorIbanToDisplay, args); break;
      case 'if': return this.operatorIf(args); break;
      case 'isEmpty': return this.operatorIsEmpty(args); break;
      case 'isNull': return this.operatorIsNull(args); break;
      case 'join': return this.operatorJoin(args); break;
      case 'jsonToData': return this.scalarOperator(this.operatorJsonToData, args); break;
      case 'length': return this.scalarOperator(this.operatorLength, args); break;
      case 'lookup': return this.operatorLookup(args); break;
      case 'map': return this.operatorMap(args); break;
      case 'matches': return this.scalarOperator(this.operatorMatches, args); break;
      case 'max': return this.operatorMax(args); break;
      case 'min': return this.operatorMin(args); break;
      case 'mod': return this.scalarOperator(this.operatorMod, args); break;
      case 'normalizeDuration': return this.scalarOperator(this.operatorNormalizeDuration, args); break;
      case 'not': return this.scalarOperator(this.operatorNot, args); break;
      case 'nullToEmpty': return this.scalarOperator(this.operatorNullToEmpty, args); break;
      case 'or': return this.scalarOperator(this.operatorOr, args); break;
      case 'parseDate': return this.scalarOperator(this.operatorParseDate, args); break;
      case 'path': return this.scalarOperator(this.operatorPath, args); break;
      case 'position': return this.operatorPosition(args); break;
      case 'power': return this.scalarOperator(this.operatorPower, args); break;
      case 'quote': return this.operatorQuote(args); break;
      case 'relative': return this.operatorRelative(args); break;
      case 'removeTimezone': return this.scalarOperator(this.operatorRemoveTimezone, args); break;
      case 'replace': return this.scalarOperator(this.operatorReplace, args); break;
      case 'riRelativize': return this.scalarOperator(this.operatorRiRelativize, args); break;
      case 'riResolve': return this.scalarOperator(this.operatorRiResolve, args); break;
      case 'round': return this.scalarOperator(this.operatorRound, args); break;
      case 's:=':
      case 's:==':
      case 's:!==':
      case 's:!=':
      case 's:=~':
      case 's::=':
      case 's:<':
      case 's:<=':
      case 's:>':
      case 's:>=':
      case 's:<*':
      case 's:<=*':
      case 's:>*':
      case 's:>=*': return this.operatorStorageOperator(this.data.operator.substring(2), args); break;
      case 's:and': return this.operatorStorageAnd(args); break;
      case 's:or': return this.operatorStorageOr(args); break;
      case 's:path': return this.operatorStoragePath(args); break;
      case 's:property': return this.operatorStorageProperty(args); break;
      case 's:trailingPath': return this.operatorStorageTrailingPath(args); break;
      case 'select': return this.operatorSelect(args); break;
      case 'setTimezone': return this.scalarOperator(this.operatorSetTimezone, args); break;
      case 'shorten': return this.scalarOperator(this.operatorShorten, args); break;
      case 'some': return this.operatorSome(args); break;
      case 'sort': return this.operatorSort(args); break;
      case 'split': return this.scalarOperator(this.operatorSplit, args); break;
      case 'startsWith': return this.scalarOperator(this.operatorStartsWith, args); break;
      case 'staticValue': return this.scalarOperator(this.operatorStaticValue, args); break;
      case 'staticValues': return this.scalarOperator(this.operatorStaticValues, args); break;
      case 'staticValueKeys': return this.scalarOperator(this.operatorStaticValueKeys, args); break;
      case 'stringContains': return this.scalarOperator(this.operatorStringContains, args); break;
      case 'stringFind': return this.scalarOperator(this.operatorStringFind, args); break;
      case 'submittedBy': return this.operatorSubmittedBy(args); break;
      case 'submittedRowIndex': return this.operatorSubmittedRowIndex(args); break;
      case 'subsequence': return this.operatorSubsequence(args); break;
      case 'substring': return this.scalarOperator(this.operatorSubstring, args); break;
      case 'substring1': return this.scalarOperator(this.operatorSubstring1, args); break;
      case 'sum': return this.operatorSum(args); break;
      case 'tableLookup': return this.scalarOperator(this.operatorTableLookup, args); break;
      case 'timezone': return this.scalarOperator(this.operatorTimezone, args); break;
      case 'toDate': return this.scalarOperator(this.operatorToDate, args); break;
      case 'toDateTime': return this.scalarOperator(this.operatorToDateTime, args); break;
      case 'toMilliseconds': return this.scalarOperator(this.operatorToMilliseconds, args); break;
      case 'toLowerCase': return this.scalarOperator(this.operatorToLowerCase, args); break;
      case 'toUpperCase': return this.scalarOperator(this.operatorToUpperCase, args); break;
      case 'toTime': return this.scalarOperator(this.operatorToTime, args); break;
      case 'toTimezone': return this.scalarOperator(this.operatorToTimezone, args); break;
      case 'trim': return this.scalarOperator(this.operatorTrim, args); break;
      case 'trim0': return this.scalarOperator(this.operatorTrim0, args); break;
      case 'try': return this.operatorTry(args); break;
      case 'true': return true; break;
      case 'union': return this.operatorUnion(args); break;
      case 'unquote': this.error('function "unquote" can be used only inside "quote" function!'); return false; break;
      case 'update': return this.operatorUpdate(args); break;
      case 'uuid': return this.operatorUUID(); break;
      case 'valueAt': return this.operatorValueAt(args); break;
      case 'xmlToData': return this.scalarOperator(this.operatorXmlToData, args); break;
      default: this.error('Unknown function "' + this.data.operator + '"!'); return false; break;
    }
  };

  this.scalarOperator = function(operator, args) {
    if (!MC.isFunction(operator)) {
      this.error('ScalarOperator can be called only with operator function as first argument!');
    }
    args = this.normalizeargs(args);
    if (Array.isArray(args[0])) {
      var result = [];
      for (var i=0; i<args[0].length; i++) {
        var subArgs = [];
        for (var a=0; a<args.length; a++) {
          subArgs.push(args[a][i]);
        }
        result.push(this.scalarOperator(operator, subArgs));
      }
      return result;
    } else {
      return operator.call(this, args);
    }
  };

  this.operatorGreater = function(args) {
    if (args.length != 2) {
      this.error('Operator ">" works only with two args! ' + args.length + ' args were passed.');
    }
    args = this.normalizeToCompare(args);
    return (args[0] > args[1]);
  };

  this.operatorLower = function(args) {
    if (args.length != 2) {
      this.error('< operator works only with two args! ' + args.length + ' args were passed.');
    }
    args = this.normalizeToCompare(args);
    if (args[0] === null && typeof(args[1]) === 'string') {
      return true;
    }
    return (args[0] < args[1]);
  };

  this.operatorGreaterEquals = function(args) {
    if (args.length != 2) {
      this.error('>= operator works only with two args! ' + args.length + ' args were passed.');
    }
    args = this.normalizeToCompare(args);
    if (args[0] === null && typeof(args[1]) === 'string' && args[1].trim() === '') {
      return false;
    }
    return (args[0] >= args[1]);
  };

  this.operatorLowerEquals = function(args) {
    if (args.length != 2) {
      this.error('<= operator works only with two args! ' + args.length + ' args were passed.');
    }
    args = this.normalizeToCompare(args);
    if (args[0] === null && typeof(args[1]) === 'string') {
      return true;
    }
    return (args[0] <= args[1]);
  };

  this.operatorCount = function(args) {
    if (args.length > 1) {
      this.error('Count operator works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return 0;
    } else if (Array.isArray(args[0])) {
      var result = 0;
      for (var i=0; i<args[0].length; i++) {
        if (Array.isArray(args[0][i])) {
          result += this.operatorCount([args[0][i]]);
        } else if (!MC.isNull(args[0][i])) {
          result++;
        }
      }
      return result;
    } else {
      return 1;
    }
  };

  this.operatorCollectionSize = function(args) {
    if (args.length > 1) {
      this.error('Function "collectionSize" works only with one argument! Passed arguments:' + JSON.stringify(args));
    }
    if (MC.isNull(args[0])) {
      return 0;
    } else if (Array.isArray(args[0])) {
      return args[0].length;
    } else {
      return 1;
    }
  };

  this.operatorAvg = function(args) {
    if (args.length > 1) {
      this.error('Operator "avg" works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    } else if (Array.isArray(args[0])) {
      var sumObject = {sum: 0, count: 0};
      this.avgSum(args[0], sumObject);
      if (sumObject.count == 0) {
        return null;
      } else {
        return MC.getNumberAsString(math.round(math.divide(sumObject.sum, sumObject.count), 18));
      }
    } else {
      if (MC.isNumeric(args[0])) {
        return args[0];
      } else {
        this.error('Argument of "avg" must be number! Passed: ' + args[0]);
      }
    }
  };

  this.avgSum = function(values, sumObject) {
    for (var i=0; i<values.length; i++) {
      if (Array.isArray(values[i])) {
        this.avgSum(values[i], sumObject);
      } else if (!MC.isNull(values[i]) && values[i] !== '') {
        if (MC.isNumeric(values[i])) {
          sumObject.sum = math.add(sumObject.sum, math.bignumber(values[i]));
          sumObject.count++;
        } else {
          this.error('Argument of "avg" must be number! Passed: ' + values[i]);
        }
      }
    }
  };

  this.operatorValueAt = function(args) {
    if (args.length != 2) {
      this.error('Function "valueAt" must have two args! Passed arguments:' + JSON.stringify(args));
    }
    if (!MC.isNumeric(args[1])) {
      this.error('Second argument of function "valueAt" must be an integer! ' + args[1] + ' was passed.');
    }
    var coll = this.flattenCollection(args[0], true);
    var index = parseInt(args[1]);
    if (index < 0) {
      index = coll.length + index;
    }
    if (index < 0 || index >= coll.length) {
      return null;
    }
    return coll[index];
  };

  this.operatorCollectionItem = function(args) {
    if (args.length != 2) {
      this.error('valueAt operator works only with two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (!Array.isArray(args[0])) {
      this.error('First valueAt argument must be collection! ' + args[0] + ' was passed.');
    }
    if (!MC.isNumeric(args[1])) {
      this.error('Second valueAt argument must be an integer! ' + args[1] + ' was passed.');
    }
    if (args[1] < 0) {
      args[1] = args[0].length + Number(args[1]);
    }
    if (args[0].length > args[1]) {
      return args[0][args[1]];
    } else {
      return null;
    }
  };

  this.operatorSubsequence = function(args) {
    if (args.length != 3) {
      this.error('Operator "subsequence" works only with three args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (!Array.isArray(args[0])) {
      this.error('First argument of "subsequence" must be collection! ' + args[0] + ' was passed.');
    }
    if (!MC.isNumeric(args[1])) {
      this.error('Second argument of "subsequence" must be an integer! ' + args[1] + ' was passed.');
    }
    if (!MC.isNumeric(args[2])) {
      this.error('Second argument of "subsequence" must be an integer! ' + args[2] + ' was passed.');
    }
    args[1] = Number(args[1])
    args[2] = Number(args[2])
    if (args[1] < 0) {
      args[1] = args[0].length + Number(args[1]);
    }
    if (args[2] < 0) {
      args[2] = args[0].length + Number(args[2]);
    }
    if (args[1] > args[2] || args[1] > args[0].length) {
      return [];
    }
    var result = [];
    for (var i=args[1]; i<=args[2]; i++) {
      if (i < args[0].length) {
        result.push(args[0][i]);
      }
    }
    return result;
  };

  this.operatorConcat = function(args) {
    if (args.length == 0) {
      return null;
    } else {
      var result = '';
      for (var i=0; i<args.length; i++) {
        if (!MC.isNull(args[i])) {
          result += args[i] + '';
        }
      }
      return result;
    }
  };

  this.operatorSubmittedBy = function(args) {
    if (args.length > 1) {
      this.error('SubmittedBy operator works only with one argument! ' + args.length + ' args were passed.');
      return;
    }
    if (MC.isNull(this.cData['@lastFormAction']) || MC.isNull(this.opts.submittedByPath) || !this.opts.submittedByPath.startsWith(this.cData['@lastFormAction'] + '/')) {
      return false;
    }
    if (Array.isArray(args[0])) {
      for (var i=0; i<args[0].length; i++) {
        if (args[0][i] == true) {
          return true;
        }
      }
      return false;
    } else if (args[0] == true) {
      return true;
    } else {
      return false;
    }
  };

  this.operatorSubmittedRowIndex = function(args) {
    if (args.length > 1) {
      this.error('submittedRowIndex operator works only with one argument! Passed arguments: ' + JSON.stringify(args));
    }
    if (Array.isArray(args[0])) {
      if (MC.isNumeric(args[0][0]['@submittedRowIndex'])) {
        return args[0][0]['@submittedRowIndex'];
      }
    }
    return -1;
  };

  this.normalizeargs = function(args) {
    var size = 1;
    for (var i=0; i<args.length; i++) {
      if (Array.isArray(args[i])) {
        if (args[i].length > size) {
          size = args[i].length;
        }
      }
    }
    if (size > 1) {
      for (var i = 0; i < args.length; i++) {
        if (!Array.isArray(args[i])) {
          var token = args[i];
          args[i] = [];
          for (var f = 0; f < size; f++) {
            args[i][f] = token === undefined ? null : token;
          }
        } else {
          if (args[i].length < size) {
            for (var f = 0; f < size; f++) {
              if (f > args[i].length) {
                args[i][f] = null;
              }
            }
          }
        }
      }
    } else {
      for (var i = 0; i < args.length; i++) {
        if (args[i] === undefined) {
          args[i] = null;
        } else if (Array.isArray(args[i])) {
          args[i] = args[i][0];
        }
      }
    }
    return args;
  };

  this.operatorSelect = function(exprs) {
    if (!Array.isArray(exprs) || exprs.length < 2 || exprs.length > 4) {
      this.error('Function "select" must have two to four args! ' + exprs.length + ' args were passed.');
    }
    var expr1 = new Expression(exprs[0], this.cData, this.opts);
    var arg1Coll = expr1.evaluate();
    this.trace.args = [expr1.getTrace()];
    if (expr1.getError()) {
      this.error(expr1.getError());
      return null;
    }
    if (MC.isNull(arg1Coll)) {
      return null;
    }
    if (arg1Coll === '') {
      return '';
    }
    arg1Coll = MC.asArray(arg1Coll);
    var base = this.enterBaseContext();
    var result = this.select(arg1Coll, exprs);
    this.leaveBaseContext(base);
    return result;
  };

  this.select = function(arg1Coll, exprs) {
    var result = [];
    this.setPositionValue(arg1Coll);
    this.trace.args.push([]);
    for (var i = 0; i < arg1Coll.length; i++) {
      var item = arg1Coll[i];
      this.setPosition(i);
      var resultItem;
      if (Array.isArray(item)) {
        resultItem = this.select(item, exprs);
      } else {
        var expr2 = new Expression(exprs[1], this.cData, this.opts);
        var isSelectedValue = MC.asScalar(expr2.evaluate());
        if (expr2.getError()) {
          this.error(expr2.getError());
          result.push(null);
        }
        this.trace.args[1].push(expr2.getTrace());
        if (isSelectedValue === true) {
          if (exprs.length > 2) {
            var expr3 = new Expression(exprs[2], this.cData, this.opts);
            resultItem = MC.asScalar(expr3.evaluate());
            if (expr3.getError()) {
              this.error(expr3.getError());
              resultItem = null;
            }
            if (!this.trace.args[2]) {
              this.trace.args[2] = [];
            }
            this.trace.args[2].push(expr3.getTrace());
          } else {
            resultItem = item;
          }
        } else {
          if (exprs.length > 3) {
            var expr4 = new Expression(exprs[3], this.cData, this.opts);
            resultItem = MC.asScalar(expr4.evaluate());
            if (expr4.getError()) {
              this.error(expr4.getError());
              resultItem = null;
            }
            if (!this.trace.args[3]) {
              this.trace.args[3] =[];
            }
            this.trace.args[3].push(expr4.getTrace());
          } else {
            resultItem = null;
          }
        }
      }
      result.push(resultItem);
      this.setPosition(null);
    }
    this.setPositionValue(null);
    if (result.length == 0) {
      return null;
    } else {
      return result;
    }
  };

  this.operatorFilter = function(exprs) {
    if (!Array.isArray(exprs) || exprs.length < 2 || exprs.length > 4) {
      this.error('Function "filter" must have two to four args! ' + exprs.length + ' args were passed.');
    }
    var expr1 = new Expression(exprs[0], this.cData, this.opts);
    var arg1Coll = expr1.evaluate();
    this.trace.args = [expr1.getTrace()];
    if (expr1.getError()) {
      this.error(expr1.getError());
      return null;
    }
    if (MC.isNull(arg1Coll)) {
      return null;
    }
    if (arg1Coll === '') {
      return '';
    }
    arg1Coll = MC.asArray(arg1Coll);
    var base = this.enterBaseContext();
    var result = [];
    this.setPositionValue(arg1Coll);
    this.trace.args.push([]);
    for (var i = 0; i < arg1Coll.length; i++) {
      var item = arg1Coll[i];
      this.setPosition(i);
      var expr2 = new Expression(exprs[1], this.cData, this.opts);
      var isSelectedValue = MC.asScalar(expr2.evaluate());
      this.trace.args[1].push(expr2.getTrace());
      if (expr2.getError()) {
        this.error(expr2.getError());
        result.push(null);
      }
      var resultItem;
      if (isSelectedValue === true) {
        if (exprs.length > 2) {
          var expr3 = new Expression(exprs[2], this.cData, this.opts);
          resultItem = MC.asScalar(expr3.evaluate());
          if (expr3.getError()) {
            this.error(expr3.getError());
            resultItem = null;
          }
          if (!this.trace.args[2]) {
            this.trace.args[2] = [];
          }
          this.trace.args[2].push(expr3.getTrace());
        } else {
          resultItem = item;
        }
      } else {
        if (exprs.length > 3) {
          var expr4 = new Expression(exprs[3], this.cData, this.opts);
          resultItem = MC.asScalar(expr4.evaluate());
          if (expr4.getError()) {
            this.error(expr4.getError());
            resultItem = null;
          }
          if (!this.trace.args[3]) {
            this.trace.args[3] =[];
          }
          this.trace.args[3].push(expr4.getTrace());
        } else {
          resultItem = null;
        }
      }
      if (!MC.isNull(resultItem)) {
        result.push(resultItem);
      }
      this.setPosition(null);
    }
    this.setPositionValue(null);
    this.leaveBaseContext(base);
    if (result.length == 0) {
      return null;
    } else {
      return result;
    }
  };

  this.operatorFind = function(exprs) {
    if (!Array.isArray(exprs) || exprs.length < 2 || exprs.length > 4) {
      this.error('Function "find" must have two to four args! ' + exprs.length + ' args were passed.');
    }
    var expr1 = new Expression(exprs[0], this.cData, this.opts);
    var arg1Coll = expr1.evaluate();
    this.trace.args = [expr1.getTrace()];
    if (expr1.getError()) {
      this.error(expr1.getError());
      return null;
    }
    if (MC.isNull(arg1Coll)) {
      return null;
    }
    if (arg1Coll === '') {
      return '';
    }
    arg1Coll = MC.asArray(arg1Coll);
    var base = this.enterBaseContext();
    var result = null;
    this.setPositionValue(arg1Coll);
    this.trace.args.push([]);
    for (var i = 0; i < arg1Coll.length; i++) {
      var item = arg1Coll[i];
      this.setPosition(i);
      var expr2 = new Expression(exprs[1], this.cData, this.opts);
      var isSelectedValue = MC.asScalar(expr2.evaluate());
      this.trace.args[1].push(expr2.getTrace());
      if (expr2.getError()) {
        this.error(expr2.getError());
        result = null;
      }
      var resultItem;
      if (isSelectedValue === true) {
        if (exprs.length > 2) {
          let expr3 = new Expression(exprs[2], this.cData, this.opts);
          resultItem = MC.asScalar(expr3.evaluate());
          if (expr3.getError()) {
            this.error(expr3.getError());
            resultItem = null;
          }
          if (!this.trace.args[2]) {
            this.trace.args[2] = [];
          }
          this.trace.args[2].push(expr3.getTrace());
        } else {
          resultItem = item;
        }
      } else {
        if (exprs.length > 3) {
          let expr4 = new Expression(exprs[3], this.cData, this.opts);
          resultItem = MC.asScalar(expr4.evaluate());
          if (expr4.getError()) {
            this.error(expr4.getError());
            resultItem = null;
          }
          if (!this.trace.args[3]) {
            this.trace.args[3] =[];
          }
          this.trace.args[3].push(expr4.getTrace());
        } else {
          resultItem = null;
        }
      }
      if (!MC.isNull(resultItem)) {
        result = resultItem;
        break;
      }
      this.setPosition(null);
    }
    this.setPositionValue(null);
    this.leaveBaseContext(base);
    return result;
  };

  this.getData = function(item, dataPath) {
    if (Array.isArray(item)) {
      item = item[0];
    }
    let i = dataPath.indexOf('/');
    let key = dataPath;
    if (i > -1) {
      key = key.substring(0, i);
    }
    if (key.endsWith('*')) {
      key = key.substring(0, key.length -1);
    }
    if (i < 0) {
      return item[key];
    } else {
      return this.getData(item[key], dataPath.substring(i + 1));
    }
  };

  this.operatorRelative = function(args) {
    if (args.length > 1) {
      this.error('Function "relative" must have zero or one argument! Passed arguments: ' + JSON.stringify(args));
    }
    var basePathRefs = this.bases();
    if (MC.isNull(basePathRefs)) {
      this.error('Base is not defined, cannot use function "relative" here!');
    }
    var relativePath;
    if (args.length == 0) {
      relativePath = ".";
    } else {
      if (MC.isNull(args[0]) || args[0] === '') {
        relativePath = ".";
      } else {
        relativePath = args[0]+'';
      }
    }
    if (relativePath == "." || relativePath.startsWith("./")) {
      relativePath = relativePath.replace(".", "$v" + basePathRefs.length);
    } else if (!relativePath.startsWith("$v")) {
      relativePath = "$v" + basePathRefs.length + "/" + relativePath;
    }
    var i = relativePath.indexOf("/");
    var relativeToBase = parseInt(i == -1 ? relativePath.substring(2) : relativePath.substring(2, i));
    relativePath = i == -1 ? "." : relativePath.substring(i +  1);
    var positions = this.positions().slice().reverse()[relativeToBase - 1].slice();
    var basePathRef = basePathRefs.slice().reverse()[relativeToBase - 1];
    var steps = basePathRef.split('/');
    var shortenedBasePath;
    var relativePathFull = relativePath;
    if (relativePath.startsWith('..')) {
      while (relativePath.startsWith('..')) {
        var last = steps.pop();
        if (MC.isNull(last)) {
          this.error('Relative function error - unable to resolve relative path "' + relativePathFull + '" on base path "' + basePathRef + '"');
          return null;
        }
        if (last.endsWith('*')) {
          positions.pop();
        }
        if (relativePath.length == '..'.length) {
          relativePath = null;
        } else {
          relativePath = relativePath.substring('..'.length + '/'.length);
        }
      }
      shortenedBasePath = steps.join('/');
    } else {
      shortenedBasePath = basePathRef;
    }
    var resolvedPath = this.relativize(shortenedBasePath, relativePath);
    var dereferenced;
    if (resolvedPath === '') {
      dereferenced = this.positionValues().slice().reverse();
      var level = positions.length - 1;
      var position = positions.shift();
      dereferenced = dereferenced[level][position];
    } else {
      dereferenced = this.evaluateSource({source: resolvedPath});
      while (positions.length > 0) {
        dereferenced = MC.asArray(dereferenced);
        var position = positions.shift();
        if (position >= dereferenced.length) {
          return null;
        }
        dereferenced = dereferenced[position];
      }
    }
    return dereferenced;
  };

  this.operatorPosition = function(args) {
    if (args.length !== 0) {
      this.error('Function "position" must not have any args! Passed arguments: ' + JSON.stringify(args));
    }
    var position = this.position();
    if (Array.isArray(position)) {
      position = position[position.length - 1];
    }
    if (!MC.isNumeric(position)) {
      this.error('Position is not defined, cannot use function "position" here!');
    }
    return position;
  };

  this.relativize = function(path, relPath) {
    if (relPath == '.') {
      return path;
    } else if (relPath.startsWith('./')) {
      relPath = relPath.replace(/\.\//g, '');
      return this.relativize(path, relPath);
    } else if (relPath.startsWith('../')) {
      relPath = relPath.replace(/\.\.\//, '');
      path = path.substring(0, path.lastIndexOf('/'));
      return this.relativize(path, relPath);
    } else {
      return path + (path ? '/' : '') + relPath;
    }
  };

  this.operatorEquals = function(args) {
    if (args.length != 2) {
      this.error('== operator works only with two args! ' + args.length + ' args were passed.');
    }
    args = this.normalizeToCompare(args);
    return (args[0] == args[1]);
  };

  this.normalizeToCompare = function(args) {
    if (typeof(args[0]) === "boolean") {
      if (MC.isNumeric(args[1])) {
        this.error('Can not compare boolean with number! ' + JSON.stringify(args));
      }
      args[1] = this.getBooleanForEquals(args[1]);
    }
    if (typeof(args[1]) === "boolean") {
      if (MC.isNumeric(args[0])) {
        this.error('Can not compare boolean with number! ' + JSON.stringify(args));
      }
      args[0] = this.getBooleanForEquals(args[0]);
    }
    if (typeof(args[0]) === "string" && MC.isNumeric(args[0])) {
      args[0] = Number(args[0]).valueOf();
    }
    if (typeof(args[1]) === "string" && MC.isNumeric(args[1])) {
      args[1] = Number(args[1]).valueOf();
    }
    return args;
  };

  this.getBooleanForEquals = function(val) {
    if (typeof(val) === "string") {
      if (val.toLowerCase() === 'true') {
        return true;
      }
      if (val.toLowerCase() === 'false') {
        return false;
      }
    }
    return val;
  };

  this.operatorNotEquals = function(args) {
    if (args.length != 2) {
      this.error('!= operator works only with two args! ' + args.length + ' args were passed.');
    }
    args = this.normalizeToCompare(args);
    return (args[0] != args[1]);
  };

  this.operatorEmptyToNull = function(args) {
    if (args.length != 1) {
      this.error('emptyToNull operator works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0]) || args[0] == '') {
      return null;
    } else {
      return args[0];
    }
  };

  this.operatorIf = function(exprs) {
    if (!Array.isArray(exprs) || exprs.length < 2 || exprs.length > 3) {
      this.error('If operator works only with two or three args! ' + args.length + ' args were passed.');
    }
    var expression = new Expression(exprs[0], this.cData, this.opts);
    var conditions = expression.evaluate();
    if (expression.getError()) {
      this.error(expression.getError());
    }
    this.trace.args = [expression.getTrace()];
    expression = new Expression(exprs[1], this.cData, this.opts);
    var thens = expression.evaluate();
    this.trace.args.push(expression.getTrace());
    var thenErr = expression.getError();
    var elses;
    var elseErr;
    if (exprs.length > 2) {
      expression = new Expression(exprs[2], this.cData, this.opts);
      elses = expression.evaluate();
      elseErr = expression.getError();
      this.trace.args.push(expression.getTrace());
    }
    if (Array.isArray(conditions)) {
      if (thenErr) {
        MCHistory.log(MCHistory.T_WARNING, 'Evaluating error in "then" branch of if operator: ' + thenErr, true);
      }
      if (elseErr) {
        MCHistory.log(MCHistory.T_WARNING, 'Evaluating error in "else" branch of if operator: ' + elseErr, true);
      }
      var nargs = [conditions, thens, elses];
      nargs = this.normalizeargs(nargs);
      var result = [];
      for (var i=0; i<nargs[0].length; i++) {
        if (nargs[0][i]) {
          result.push(nargs[1][i]);
        } else if (nargs[2] != null) {
          result.push(nargs[2][i]);
        } else {
          result.push(null);
        }
      }
      return result;
    } else {
      if (conditions) {
        if (thenErr) {
          MCHistory.log(MCHistory.T_WARNING, 'Evaluating error in "then" branch of if operator: ' + thenErr, true);
        }
        return thens;
      } else {
        if (elseErr) {
          MCHistory.log(MCHistory.T_WARNING, 'Evaluating error in "else" branch of if operator: ' + elseErr, true);
        }
        return elses;
      }
    }
  };

  this.operatorMin = function(args) {
    if (args.length == 0) {
      this.error('Operator "min" must have at least one argument! ');
    } else if (args.length == 1) {
      if (!Array.isArray(args[0])) {
        if (MC.isNumeric(args[0])) {
          return args[0];
        } else {
          return null;
        }
      } else {
        var result = null;
        for (var i=0; i<args[0].length; i++) {
          if (Array.isArray(args[0][i])) {
            var subRes = this.operatorMin([args[0][i]]);
            if (result == null || math.smaller(math.bignumber(subRes), math.bignumber(result))) {
              result = subRes;
            }
          } else if (MC.isNumeric(args[0][i]) && !MC.isNull(args[0][i])) {
            if (result == null || math.smaller(math.bignumber(args[0][i]), math.bignumber(result))) {
              result = args[0][i];
            }
          }
        }
        return result;
      }
    } else {
      if (Array.isArray(args[0])) {
        return this.scalarOperator(this.operatorMin, args);
      } else {
        var result = null;
        for (var i=0; i<args.length; i++) {
          if (Array.isArray(args[i])) {
            var subRes = this.operatorMin([args[i]]);
            if (result == null || math.smaller(math.bignumber(subRes), math.bignumber(result))) {
              result = subRes;
            }
          } else if (MC.isNumeric(args[i]) && !MC.isNull(args[i])) {
            if (result == null || math.smaller(math.bignumber(args[i]), math.bignumber(result))) {
              result = args[i];
            }
          }
        }
        return result;
      }
    }
  };

  this.operatorMax = function(args) {
    if (args.length == 0) {
      this.error('Operator "max" must have at least one argument! ');
    } else if (args.length == 1) {
      if (!Array.isArray(args[0])) {
        if (MC.isNumeric(args[0])) {
          return args[0];
        } else {
          return null;
        }
      } else {
        var result = null;
        for (var i=0; i<args[0].length; i++) {
          if (Array.isArray(args[0][i])) {
            var subRes = this.operatorMax([args[0][i]]);
            if (result == null || math.larger(math.bignumber(subRes), math.bignumber(result))) {
              result = subRes;
            }
          } else if (MC.isNumeric(args[0][i]) && !MC.isNull(args[0][i])) {
            if (result == null || math.larger(math.bignumber(args[0][i]), math.bignumber(result))) {
              result = args[0][i];
            }
          }
        }
        return result;
      }
    } else {
      if (Array.isArray(args[0])) {
        return this.scalarOperator(this.operatorMax, args);
      } else {
        var result = null;
        for (var i=0; i<args.length; i++) {
          if (Array.isArray(args[i])) {
            var subRes = this.operatorMax([args[i]]);
            if (result == null || math.larger(math.bignumber(subRes), math.bignumber(result))) {
              result = subRes;
            }
          } else if (MC.isNumeric(args[i]) && !MC.isNull(args[i])) {
            if (result == null || math.larger(math.bignumber(args[i]), math.bignumber(result))) {
              result = args[i];
            }
          }
        }
        return result;
      }
    }
  };

  this.operatorPlus = function(args) {
    if (args.length < 2) {
      this.error('Function "+" works only with two or more args! ' + args.length + ' args were passed.');
    }
    var result = null;
    for (var i=0; i<args.length; i++) {
      if (!MC.isNull(args[i])) {
        if (MC.isNumeric(args[i])) {
          if (result == null || result === '') {
            result = math.bignumber(args[i]);
          } else {
            result = math.add(result, math.bignumber(args[i]));
          }
        } else if (args[i] === '') {
          if (result == null) {
            result = '';
          }
        } else {
          this.error('Function "+" works only with numeric args! Passed arguments: ' + JSON.stringify(args));
        }
      }
    }
    if (result == null || result === '') {
      return result;
    } else {
      return MC.getNumberAsString(result);
    }
  };

  this.operatorMinus = function(args) {
    if (args.length < 2) {
      this.error('Function "-" works only with two or more args! ' + args.length + ' args were passed. Passed arguments: ' + JSON.stringify(args));
    }
    if (!MC.isNumeric(args[0])) {
      this.error('First argument of function "-" must be numeric! Passed arguments: ' + JSON.stringify(args));
    }
    var result = math.bignumber(args[0]);
    for (var i=1; i<args.length; i++) {
      if (MC.isNumeric(args[i])) {
        result = math.subtract(result, math.bignumber(args[i]));
      } else if (!MC.isNull(args[i]) && args[i] !== '') {
        this.error('Function "-" works only with numeric args! Passed arguments: ' + JSON.stringify(args));
      }
    }
    return MC.getNumberAsString(result);
  };

  this.operatorMultiply = function(args) {
    if (args.length < 2) {
      this.error('Function "*" works only with two or more args! ' + args.length + ' args were passed.');
    }
    var result = null;
    for (var i=0; i<args.length; i++) {
      if (!MC.isNull(args[i])) {
        if (MC.isNumeric(args[i])) {
          if (result == null || result === '') {
            result = math.bignumber(args[i]);
          } else {
            result = math.multiply(result, math.bignumber(args[i]));
          }
        } else if (args[i] === '') {
          if (result == null) {
            result = '';
          }
        } else {
          this.error('Function "*" works only with numeric args! Passed arguments: ' + JSON.stringify(args));
        }
      }
    }
    if (result == null || result === '') {
      return result;
    } else {
      return MC.getNumberAsString(result);
    }
  };

  this.operatorPower = function(args) {
    if (args.length != 2) {
      this.error('Function "power" works only with two! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[0]) || args[0]==='' && MC.isNull(args[1]) || args[1]==='') {
      return null;
    }
    if (MC.isNull(args[0]) || args[0]==='' || MC.isNull(args[1]) || args[1]==='') {
      this.error('Either none or both args of function "power" must be specified Passed arguments: ' + JSON.stringify(args));
    }
    let result = math.round(math.pow(math.bignumber(args[0]), math.bignumber(args[1])), 18);
    return MC.getNumberAsString(result);
  };

  this.operatorDivide = function(args) {
    if (args.length < 2) {
      this.error('Function "/" works only with two or more args! ' + args.length + ' args were passed.');
    }
    if (!MC.isNumeric(args[0])) {
      this.error('First argument of function "/" must be numeric! Passed arguments: ' + JSON.stringify(args));
    }
    var result = math.bignumber(args[0]);
    for (var i=1; i<args.length; i++) {
      if (MC.isNumeric(args[i])) {
        if (Number(args[i]).valueOf() == 0) {
          this.error('Operator "/" not allows dividing by zero! Passed arguments: ' + JSON.stringify(args));
        }
        result = math.round(math.divide(result, math.bignumber(args[i])), 18);
      } else if (!MC.isNull(args[i]) && args[i] !== '') {
        this.error('Function "/" works only with numeric args! Passed arguments: ' + JSON.stringify(args));
      }
    }
    return MC.getNumberAsString(result);
  };

  this.operatorMod = function(args) {
    if (args.length < 2) {
      this.error('Operator "mod" works only with two or more args! ' + args.length + ' args were passed.');
    }
    if (!MC.isNumeric(args[0]) || !MC.isNumeric(args[1])) {
      this.error('Operator "mod" works only with numeric args! Passed arguments: ' + JSON.stringify(args));
    }
    var result = math.bignumber(args[0]);
    for (var i=1; i<args.length; i++) {
      if (MC.isNumeric(args[i])) {
        result = math.mod(result, math.bignumber(args[i]));
      } else {
        this.error('Operator "mod" works only with numeric args! Passed arguments: ' + JSON.stringify(args));
      }
    }
    return MC.getNumberAsString(result);
  };

  this.operatorDiv = function(args) {
    if (args.length < 2) {
      this.error('Operator "div" works only with two or more args! ' + args.length + ' args were passed.');
    }
    if (!MC.isNumeric(args[0]) || !MC.isNumeric(args[1])) {
      this.error('Operator "div" works only with numeric args! Passed arguments: ' + JSON.stringify(args));
    }
    var result = math.bignumber(args[0]);
    for (var i=1; i<args.length; i++) {
      if (MC.isNumeric(args[i])) {
        result = math.floor(math.divide(result, math.bignumber(args[i])));
      } else {
        this.error('Operator "div" works only with numeric args! Passed arguments: ' + JSON.stringify(args));
      }
    }
    return MC.getNumberAsString(result);
  };

  this.operatorOr = function(args) {
    for (var i=0; i<args.length; i++) {
      if (args[i]) {
        return true;
      }
    }
    return false;
  };

  this.operatorAnd = function(args) {
    for (var i=0; i<args.length; i++) {
      if (!args[i]) {
        return false;
      }
    }
    return true;
  };

  this.operatorNot = function(args) {
    if (args.length != 1) {
      this.error('Not operator works only with one argument! ' + args.length + ' args were passed.');
    }
    if (args[0] == true || args[0] == 'true') {
      return false;
    } else if (args[0] == false || args[0] == 'false') {
      return true;
    } else {
      return !args[0];
    }
  };

  this.operatorSum = function(args) {
    if (args.length > 1) {
      this.error('Sum operator works only with one argument! ' + args.length + ' args were passed.');
    }
    if (Array.isArray(args[0])) {
      args[0] = this.operatorFlatten(args);
      var result = null;
      for (var i=0; i<args[0].length; i++) {
        if (MC.isNull(args[0][i]) || args[0][i] === '') {
          continue;
        }
        if (MC.isNumeric(args[0][i])) {
          if (result == null) {
            result = math.bignumber(args[0][i]);
          } else {
            if (math.typeof(result) !== 'BigNumber') {
              this.error('Sum not works with different types of argumetns! Passed arguments: ' + JSON.stringify(args[0]));
            }
            result = math.add(result, math.bignumber(args[0][i]));
          }
        } else {
          var act = new Duration();
          act.parseIsoString(args[0][i]);
          if (!act.isValidDuration()) {
            this.error('Sum works only with numbers or durations! Passed arguments: ' + JSON.stringify(args[0]));
          }
          if (result == null) {
            result = act;
          } else {
            if (!MC.isDurationObject(result)) {
              this.error('Sum not works with different types of argumetns! Passed arguments: ' + JSON.stringify(args[0]));
            }
            result.add(act);
          }
        }
      }
      if (result == null) {
        return null;
      } else if (MC.isDurationObject(result)) {
        return result.toIsoString();
      } else {
        return MC.getNumberAsString(result);
      }
    } else {
      if (MC.isNumeric(args[0])) {
        return args[0];
      } else {
        return null;
      }
    }
  };

  this.operatorUnion = function(args) {
    if (Array.isArray(args)) {
      var result = [];
      for (var i=0; i<args.length; i++) {
        if (Array.isArray(args[i]) && args[i].length > 0) {
          result = result.concat(args[i]);
        } else if (!MC.isNull(args[i])) {
          result.push(args[i]);
        }
      }
      return result;
    } else {
      if (Array.isArray(args[0])) {
        return args[0];
      } else {
        var result = [];
        result.push(args[0]);
        return result;
      }
    }
  };

  this.operatorContains = function(args) {
    if (args.length != 2) {
      this.error('Contains operator works only with two args! ' + args.length + ' args were passed.');
    }
    if (args[0] == null) {
      return false;
    }
    if (Array.isArray(args[1])) {
      var result = [];
      for (var v=0; v<args[1].length; v++) {
        if (Array.isArray(args[0])) {
          var found = false;
          for (var i=0; i<args[0].length; i++) {
            if (args[0][i] == args[1][v]) {
              found = true;
            }
          }
          result.push(found);
        } else {
          result.push(args[0] == args[1][v]);
        }
      }
      return result;
    } else {
      if (Array.isArray(args[0])) {
        for (var i=0; i<args[0].length; i++) {
          if (args[0][i] == args[1]) {
            return true;
          }
        }
        return false;
      } else {
        return (args[0] == args[1]);
      }
    }

  };

  this.operatorCollection = function(args) {
    var result = [];
    for (var i=0; i<args.length; i++) {
      result.push(args[i]);
    }
    return result;
  };

  this.operatorIsEmpty = function(args) {
    if (args.length != 1) {
      this.error('IsEmpty operator just works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return true;
    } else {
      if (args[0] == '') {
        return true;
      } else {
        if (Array.isArray(args[0])) {
          for (var i=0; i<args[0].length; i++) {
            if (!MC.isNull(args[0][i]) && args[0][i] != '') {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      }
    }
  };

  this.operatorIsNull = function(args) {
    if (args.length != 1) {
      this.error('IsNull operator just works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return true;
    } else {
      if (Array.isArray(args[0])) {
        for (var i=0; i<args[0].length; i++) {
          if (!MC.isNull(args[0][i])) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    }
  };

  this.operatorFill = function(args) {
    if (args.length != 2) {
      this.error('Fill operator just works only with two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[1]) || args[1] === '') {
      args[1] = 0;
    }
    if (!MC.isNumeric(args[1])) {
      this.error('Second argument of fill operator must be number! "' + args[1] + '" value was passed.');
    }
    var result = [];
    for (var i=0; i<args[1]; i++) {
      result.push(args[0]);
    }
    if (MC.isNull(result)) {
      return null;
    } else {
      return result;
    }
  };

  this.operatorHasData = function(args) {
    if (args.length > 1) {
      this.error('Operator hasData works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0]) || args[0] === '') {
      return false;
    } else {
      return true;
    }
  };

  this.operatorExists = function(args) {
    if (args.length > 1) {
      this.error('Operator hasData works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return false;
    } else {
      return true;
    }
  };

  this.operatorStorageProperty = function(args) {
    if (args.length != 1) {
      this.error('"s:property" function works only with one argument! ' + args.length + ' args were passed.')
    }
    if (MC.isNull(args[0])) {
      return null
    }
    if (args[0] === '') {
      return ''
    }
    let nsis = this.cData['env/ns']
    let prefixed = args[0]
    if (prefixed.indexOf(':') > -1 && Array.isArray(nsis)) {
      let tokens = prefixed.split(':')
      for (var i=0; i<nsis.length; i++) {
        var ns = nsis[i]
        if (ns.prefix == tokens[0]) {
          prefixed = '{' + ns.uri + '}' + tokens[1]
          break
        }
      }
    } else if (prefixed.indexOf(':') < 0) {
      prefixed = '{nonamespace/}' + prefixed
    }  
    return prefixed
  }

  this.operatorStorageAnd = function(args) {
    if (args.length < 2) {
      this.error('"s:and" operator works with two or more args! ' + args.length + ' args were passed.');
    }
    var filter = '';
    var sep = '';
    for (var i = 0; i < args.length; i++) {
      if (!MC.isNull(args[i])) {
        filter += sep;
        sep = ';';
        filter += args[i];
      }
    }
    return '(' + filter + ')';
  };

  this.operatorStorageOr = function(args) {
    if (args.length < 2) {
      this.error('"s:or" operator works with two or more args! ' + args.length + ' args were passed.');
    }
    var filter = '';
    var sep = '';
    for (var i = 0; i < args.length; i++) {
      if (!MC.isNull(args[i])) {
        filter += sep;
        sep = ';';
        filter += args[i];
      }
    }
    return 'or(' + filter + ')';
  };

  this.operatorStorageOperator = function(operator, args) {
    if (args.length != 2) {
      this.error('"' + operator + '" operator works with two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0]) || args[0] == '') {
      this.error('"' + operator + '" must have first argument not empty! Passed arguments: ' + JSON.stringify(args));
    }
    return  encodeURIComponent(args[0]) + operator + encodeURIComponent(args[1]);
  };

  this.operatorStoragePath = function(args) {
    if (args.length < 1) {
      this.error('"s:path" operator must have at least one argument! ' + args.length + ' args were passed.');
    }
    var filter = '';
    var sep = '';
    for (var i = 0; i < args.length; i++) {
      if (!MC.isNull(args[i])) {
        filter += sep;
        sep = '/';
        filter += args[i];
      }
    }
    if (filter == '') {
      return null;
    } else {
      return filter;
    }
  };

  this.operatorStorageTrailingPath = function(args) {
    if (args.length < 1) {
      this.error('"s:trailingPath" operator must have at least one argument! ' + args.length + ' args were passed.');
    }
    var filter = '';
    var sep = '';
    for (var i = 0; i < args.length; i++) {
      if (!MC.isNull(args[i])) {
        filter += sep;
        sep = '/';
        filter += args[i];
      }
    }
    if (filter == '') {
      return null;
    } else {
      return '*/' + filter;
    }
  };

  this.operatorSubstring = function(args) {
    if (args.length != 2 && args.length != 3) {
      this.error('Operator "substring" works only with 2 or 3 args! ' + args.length + ' args were passed.');
    }
    if (!MC.isNumeric(args[1])) {
      this.error('Operator "substring" must have integer as second argument! Passed arguments: ' + JSON.stringify(args) + '.');
    } else {
      args[1] = Number(args[1]).valueOf();
    }
    if (!MC.isNull(args[2])) {
      if (!MC.isNumeric(args[2])) {
        this.error('Operator "substring" must have integer as third argument! Passed arguments: ' + JSON.stringify(args) + '.');
      } else {
        args[2] = Number(args[2]).valueOf();
      }
    }
    if (MC.isNull(args[0])) {
      return null;
    } else {
      var s = args[0]+'';
      if (args[1] < 0) {
        args[1] = s.length + Number(args[1]);
      }
      if (MC.isNull(args[2])) {
        args[2] = s.length;
      } else if (args[2] < 0) {
        args[2] = s.length + Number(args[2]);
      }
      if (args[1] > args[2] || args[1] > s.length) {
        return '';
      }
      return s.substring(Number(args[1]), Number(args[2] + 1));
    }
  };

  this.operatorSubstring1 = function(args) {
    if (args.length != 2 && args.length != 3) {
      this.error('Operator "substring1" works only with 2 or 3 args! ' + args.length + ' args were passed.');
    }
    if (!MC.isNumeric(args[1])) {
      this.error('Operator "substring1" must have integer as second argument! Passed arguments: ' + JSON.stringify(args) + '.');
    } else {
      args[1] = Number(args[1]).valueOf();
    }
    if (!MC.isNull(args[2])) {
      if (!MC.isNumeric(args[2])) {
        this.error('Operator "substring" must have integer as third argument! Passed arguments: ' + JSON.stringify(args) + '.');
      } else {
        args[2] = Number(args[2]).valueOf();
      }
    }
    if (MC.isNull(args[0])) {
      return null;
    } else {
      var s = args[0]+'';
      if (args[1] < 0) {
        args[1] = s.length + Number(args[1]) + 1;
      }
      if (MC.isNull(args[2])) {
        args[2] = s.length;
      } else if (args[2] < 0) {
        args[2] = s.length + Number(args[2]) + 1;
      }
      args[1] = args[1] > 0 ? args[1] - 1 : args[1];
      if (args[1] > args[2] || args[1] > s.length) {
        return '';
      }
      return s.substring(Number(args[1]), Number(args[2]));
    }
  };

  this.operatorTrim = function(args) {
    if (args.length != 1) {
      this.error('Operator trim works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    } else {
      return (args[0]+'').trim();
    }
  };

  this.operatorTrim0 = function(args) {
    if (args.length != 1) {
      this.error('Operator trim works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    } else {
      return (args[0]+'').replace(/^0+/, '');
    }
  };

  this.operatorLength = function(args) {
    if (args.length != 1) {
      this.error('Operator length works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    } else {
      return (args[0]+'').length;
    }
  };

  this.operatorCurrentDate = function() {
    var mockNow = this.operatorAppCfgVal(['fl:mockNow'])
    if (!MC.isNull(mockNow)) {
      return mockNow
    } else {
      return DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ")
    }
  }

  this.operatorFormatDate = function(args) {
    if (args.length != 2 && args.length != 1) {
      this.error('Function "formatDate" works only with one or two args! ' + args.length + ' args were passed.')
    }
    if (MC.isNull(args[0])) {
      return null
    } else if (MC.isNull(args[1]) || args[1] === '') {
      return MC.dateTimeStringToLuxon(args[0], {setZone: true}).v.toFormat("yyyy-MM-dd HH:mm:ss")
    } else if (typeof(args[1]) !== 'string') {
      this.error('Second argument of function "formatDate" must be string! Passed arguments: ' + JSON.stringify(args))
    } else {
      return MC.formatDate(args[0], args[1])
    }
  }

  this.operatorStartsWith = function(args) {
    if (args.length != 2) {
      this.error('Operator startsWith works only with two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return false;
    } else {
      return (args[0]).startsWith(args[1]);
    }
  };

  this.operatorEndsWith = function(args) {
    if (args.length != 2) {
      this.error('Operator endsWith works only with two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return false;
    } else {
      return (args[0]).endsWith(args[1]);
    }
  };

  this.operatorSplit = function(args) {
    if (args.length != 2) {
      this.error('Operator split works only with two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    if (MC.isNull(args[1])) {
      return args[0];
    }
    return args[0].toString().split(new RegExp(args[1]));
  };

  this.operatorUUID = function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  };

  this.operatorAppCfgVal = function(args) {
    if (args.length != 1) {
      this.error('Function "appCfgValGet" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[0]) || args[0] === '') {
      this.error('Argument of function "appCfgValGet" cannot be null or empty! Passed arguments: ' + JSON.stringify(args));
      return;
    }
    if (MC.isNull(this.cData['env/cfg'])) {
      return null;
    }
    const path = args[0].split('/').map(t => t.indexOf(':') > 0 ? t : `cfgi:${t}`).join('/');
    return this.getData(this.cData['env/cfg'], path);
  };

  this.operatorAppCfgVal2 = function(args) {
    if (args.length != 1) {
      this.error('Function "appCfgValGet2" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[0]) || args[0] === '') {
      this.error('Argument of function "appCfgValGet2" cannot be null or empty! Passed arguments: ' + JSON.stringify(args));
      return;
    }
    if (MC.isNull(this.cData['env/cfg'])) {
      return null;
    }
    const path = args[0].split('/').map(t => t.indexOf(':') > 0 ? t : `cfgi:${t}`).join('/');
    return this.getData(this.cData['env/cfg'], path);
  };

  this.operatorJsonToData = function(args) {
    if (args.length != 1) {
      this.error('Operator jsonToData works only with one argument! Passed arguments: ' + JSON.stringify(args));
    }
    var data = JSON.parse(args[0]);
    return MC.nullsToEmpty(data);
  };

  this.operatorDataToJson = function(args) {
    if (args.length != 1) {
      this.error('Operator dataToJson works only with one argument! Passed arguments: ' + JSON.stringify(args));
    }
    args[0] = MC.stripStars(args[0]);
    args[0] = MC.emptysToNull(args[0]);
    return JSON.stringify(args[0], null, ' ');
  };

  this.operatorRound = function(args) {
    if (args.length < 1 || args.length > 3) {
      this.error('Function "round" must have one, two or three arguments! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    if (!MC.isNumeric(args[0])) {
      this.error('First argument of function "round" must be a number! Passed arguments: ' + JSON.stringify(args));
    }
    if (!MC.isNull(args[1]) && args[1] !== '') {
      if (!MC.isNumeric(args[1])) {
        this.error('Second argument of "round" operator must be a number if used! Passed arguments: ' + JSON.stringify(args));
      } else if (args[1] == 0) {
        this.error('Precision (second argument) of funciton "round"  must not be zero if used! Passed arguments: ' + JSON.stringify(args));
      }
    }
    let mode = args[2];
    if (mode == 0 || mode === "up") {
      if (math.largerEq(math.bignumber(args[0]), 0)) {
        mode = "ceiling";
      } else {
        mode = "floor";
      }
    }
    let result;
    if (!MC.isNull(mode) && mode !== '' && mode != 4 && mode !== "half_up") {
      if (mode == 1 || mode === "down") {
        if (args[1]) {
          if (Number(args[1]).valueOf() < 0) {
            let shift = math.bignumber(Math.pow(10, -1 * Number(args[1]).valueOf() - 1));
            result = math.fix(math.divide(math.bignumber(args[0]), shift));
            result = math.multiply(result, shift);
          } else {
            let precision = Math.abs(parseInt(args[1])) || 0;
            let coefficient = Math.pow(10, precision);
            result = math.divide(math.fix(math.multiply(math.bignumber(args[0]), math.bignumber(coefficient))), math.bignumber(coefficient));
          }
        } else {
          result = math.fix(math.bignumber(args[0]));
        }
      } else if (mode == 2 || mode === "ceiling") {
        if (args[1]) {
          if (Number(args[1]).valueOf() < 0) {
            let shift = math.bignumber(Math.pow(10, -1 * Number(args[1]).valueOf() - 1));
            result = math.ceil(math.divide(math.bignumber(args[0]), shift));
            result = math.multiply(result, shift);
          } else {
            let precision = Math.abs(parseInt(args[1])) || 0;
            let coefficient = Math.pow(10, precision);
            result = math.divide(math.ceil(math.multiply(math.bignumber(args[0]), math.bignumber(coefficient))), math.bignumber(coefficient));
          }
        } else {
          result = math.ceil(math.bignumber(args[0]));
        }
      } else if (mode == 3 || mode === "floor") {
          if (args[1]) {
            if (Number(args[1]).valueOf() < 0) {
              let shift = math.bignumber(Math.pow(10, -1*Number(args[1]).valueOf()-1));
              result = math.floor(math.divide(math.bignumber(args[0]), shift));
              result = math.multiply(result, shift);
            } else {
              let precision = Math.abs(parseInt(args[1])) || 0;
              let coefficient = Math.pow(10, precision);
              result = math.divide(math.floor(math.multiply(math.bignumber(args[0]), math.bignumber(coefficient))), math.bignumber(coefficient));
            }
          } else {
            result = math.floor(math.bignumber(args[0]));
          }
      } else {
        this.error('Unsupported rounding type ("' + mode + '") of funciton "round" in third argument! Passed arguments: ' + JSON.stringify(args));
      }
    } else {
      if (args[1]) {
        if (Number(args[1]).valueOf() < 0) {
          let shift = math.bignumber(Math.pow(10, -1*Number(args[1]).valueOf()-1));
          result = math.round(math.divide(math.bignumber(args[0]), shift));
          result = math.multiply(result, shift);
        } else {
          result = math.round(math.bignumber(args[0]), math.bignumber(args[1]));
        }
      } else {
        result = math.round(math.bignumber(args[0]));
      }
    }
    return MC.getNumberAsString(result);
  };

  this.operatorJoin = function(args) {
    if (args.length != 2 && args.length != 1) {
      this.error('Operator join works only with one or two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (!Array.isArray(args[0])) {
      return args[0]+'';
    }
    var separator = '';
    if (!MC.isNull(args[1])) {
      separator = args[1];
    }
    var argsn = [];
    argsn.push(args[0]);
    argsn = this.operatorFlatten(argsn);
    var result = '';
    for (var i = 0; i < argsn.length; i++) {
      if (!MC.isNull(argsn[i])) {
        if (i > 0) {
          result += separator;
        }
        result += argsn[i];
      }
    }
    return result;
  };

  this.operatorToUpperCase = function(args) {
    if (args.length != 1) {
      this.error('Operator toUpperCase works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    } else {
      return (args[0]+'').toUpperCase();
    }
  };

  this.operatorToLowerCase = function(args) {
    if (args.length != 1) {
      this.error('Operator toLowerCase works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    } else {
      return (args[0]+'').toLowerCase();
    }
  };

  this.operatorEscapeHtml = function(args) {
    if (args.length != 1) {
      this.error('Operator escapeHtml works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    } else {
      return (args[0]+'').replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&apos;");
    }
  };

  this.operatorMatches = function(args) {
    if (args.length != 2) {
      this.error('Operator matches works only with two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return false;
    } else {
      return (new RegExp('^' + args[1] + '$')).test(args[0]);
    }
  };

  this.operatorEncodeHex = function(args) {
    if (args.length != 1 && args.length != 2) {
      this.error('Operator encodeHex works only with one ore two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    var input = args[0]+'';
    var bytes = MC.toUTF8Array(input);
    var out = [];
    var digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
    for (var i = 0, j = 0; i < bytes.length; i++) {
      out[j++] = digits[(0xF0 & bytes[i]) >>> 4];
      out[j++] = digits[0x0F & bytes[i]];
    }
    return out.join('');
  };

  this.operatorDecodeHex = function(args) {
    if (args.length != 1 && args.length != 2) {
      this.error('Operator decodeHex works only with one or two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    var data = args[0]+'';
    var len = data.length;
    if (len % 2) {
      this.error('Argument of operator "decodeHex" has odd number of characters!');
    }
    var digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
    var out = [];
    for (var i = 0, j = 0; j < len; i++) {
      var f = digits.indexOf(data.charAt(j)) << 4;
      j++;
      f = f | digits.indexOf(data.charAt(j));
      j++;
      out[i] = (f & 0xFF);
    }
    return MC.fromUTF8Array(out);
  };

  this.operatorNullToEmpty = function(args) {
    if (args.length != 1) {
      this.error('Operator nullToEmpty works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return '';
    } else {
      return args[0];
    }
  };

  this.operatorFlatten = function(args) {
    if (args.length != 1) {
      this.error('Operator "Flatten" works only with one argument! ' + args.length + ' args were passed.');
    }
    return this.flattenCollection(args[0], false);
  };

  this.flattenCollection = function(coll, withNull) {
    var result = [];
    if (Array.isArray(coll)) {
      for (var i=0; i<coll.length; i++) {
        result = result.concat(this.flattenCollection(coll[i], withNull));
      }
    } else {
      if (MC.isNull(coll)) {
        if (withNull) {
          result.push(coll);
        }
      } else {
        result.push(coll);
      }
    }
    return result;
  };

  this.operatorDataToXml = function(args) {
    if (args.length != 1) {
      this.error('Operator "dataToXml" works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    } else if (!MC.isPlainObject(args[0])) {
      return args[0];
    } else {
      var xml = MC.objectToXML(args[0], 0);
      return MC.stripWhiteSpaceInXML(xml);
    }
  };

  this.operatorXmlToData = function(args) {
    if (args.length != 1) {
      this.error('Operator "dataToXml" works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      return null;
    } else {
      var data = MC.xmlToJson(MC.parseXml(args[0]));
      return data;
    }
  };

  this.operatorStaticValue = function(args) {
    if (args.length != 2 && args.length != 3) {
      this.error('Operator "staticValue" works only with two or three args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0]) || args[0] == '') {
      return null;
    }
    if (MC.isNull(args[1])) {
      this.error('Second argument of "staticValue" can not be empty! Passed arguments: ' + JSON.stringify(args) + '.');
    } else {
      var rootPath = 'svl/' + args[1];
      var value = MC.asArray(this.cData[rootPath]);
      var svl;
      for (var i = 0; i<value.length; i++) {
        if (value[i].value == args[0]) {
          svl = value[i];
          break;
        }
      }
      if (!MC.isNull(svl)) {
        if (MC.isNull(args[2])) {
          return svl['title'];
        } else {
          if (svl['mut'] && svl['mut'][args[2]]) {
            return svl['mut'][args[2]];
          } else {
            return svl['title'];
          }
        }
      }
      return null;
    }
  };

  this.operatorStaticValues = function(args) {
    if (args.length != 1 && args.length != 2) {
      this.error('Operator "staticValues" works only with one or two args! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      this.error('First argument of "staticValues" can not be empty! Passed arguments: ' + JSON.stringify(args) + '.');
    } else {
      var rootPath = 'svl/' + args[0];
      var value = MC.asArray(this.cData[rootPath]);
      if (MC.isNull(value)) {
        return null;
      } else {
        var result = [];
        for (var i = 0; i<value.length; i++) {
          if (!MC.isNull(value[i])) {
            if (!MC.isNull(args[1]) && value[i]['mut'] && value[i]['mut'][args[1]]) {
              result.push(value[i]['mut'][args[1]]);
            } else if (!MC.isNull(value[i].title)) {
              result.push(value[i].title);
            } else {
              result.push('');
            }
          }
        }
        return result;
      }
    }
  };

  this.operatorStaticValueKeys = function(args) {
    if (args.length != 1) {
      this.error('Operator "staticValueKeys" works only with one argument! ' + args.length + ' args were passed.');
    }
    if (MC.isNull(args[0])) {
      this.error('First argument of "staticValueKeys" can not be empty! Passed arguments: ' + JSON.stringify(args) + '.');
    } else {
      var rootPath = 'svl/' + args[0];
      var value = MC.asArray(this.cData[rootPath]);
      if (MC.isNull(value)) {
        return null;
      } else {
        var result = [];
        for (var i = 0; i<value.length; i++) {
          result.push(value[i].value);
        }
        return result;
      }
    }
  };

  this.operatorFromMilliseconds = function(args) {
    if (args.length != 1) {
      this.error('Operator "fromMilliseconds" works only with one argument! ' + args.length + ' args were passed.')
    }
    if (MC.isNull(args[0])) {
      return null
    }
    if (args[0] == '') {
      return ''
    }
    if (MC.isNumeric(args[0])) {
      let opts = undefined
      if (!MC.isNull(this.cData['env/context'])) {
        let timezone = this.getData(this.cData['env/context'], 'localTimezoneId')
        if (timezone != null) {
          opts = {zone: timezone}
        }
      }
      let lux = DateTime.fromMillis(Number(args[0]), opts)
      if (lux.isValid) {
        return MC.luxonToDateTimeString({v: lux}, 'dateTime', true)
      } else {
        this.error('Argument of "fromMilliseconds" must be valid unix milliseconds number! Passed: ' + args[0])
      }
    } else {
      this.error('Argument of "fromMilliseconds" must be number! Passed: ' + args[0])
    }
  }

  this.operatorToMilliseconds = function(args) {
    if (args.length != 1) {
      this.error('Function "toMilliseconds" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
    }
    if (MC.isNull(args[0])) {
      return null
    }
    if (args[0] === '') {
      return ''
    }
    let lux = MC.dateTimeStringToLuxon(args[0])
    if (lux.v.isValid) {
      if (!MC.hasTimezone(args[0])) {
        this.error('Argument of function "toMilliseconds" value must have timezone! Passed arguments: ' + JSON.stringify(args))
      }
      return lux.v.toMillis()
    } else {
      this.error('Argument of function "toMilliseconds" must be valid date time! Passed arguments: ' + JSON.stringify(args))
    }
  }

  this.operatorTry = function(exprs) {
    if (Array.isArray(exprs) && exprs.length > 0) {
      this.trace.args = [];
      for (let i=0; i<exprs.length; i++) {
        let expression = new Expression(exprs[i], this.cData);
        try {
          const result = expression.evaluate();
          let trace = expression.getTrace();
          this.trace.args.push(trace);
          if (!expression.getError()) {
            return result;
          }
        } catch (e) {
          this.trace.args.push("EVAL ERROR!");
        }
      }
      return null;
    } else {
      this.error('Function "try" must have at least one argument!');
    }
  };

  this.operatorToDate = function(args) {
    if (args.length != 1) {
      this.error('Function "toDate" works only with one argument! Passed arguments: ' + JSON.stringify(args))
    }
    if (MC.isNull(args[0])) {
      return null
    } else if (args[0] == '') {
      return ''
    } else {
      let lux = MC.dateTimeStringToLuxon(args[0])
      if (lux.v.isValid) {
        return lux.v.toFormat('yyyy-MM-dd')
      } else {
        this.error('Argument of "toTime" must be valid date time! Passed: ' + args[0])
      }
    }
  }

  this.operatorToTime = function(args) {
    if (args.length != 1) {
      this.error('Function "toTime" works only with one argument! Passed arguments: ' + JSON.stringify(args))
    }
    if (MC.isNull(args[0])) {
      return null
    } else if (args[0] == '') {
      return ''
    } else {
      let lux = MC.dateTimeStringToLuxon(args[0])
      if (lux.v.isValid) {
        return lux.v.toFormat('HH:mm:ss')
      } else {
        this.error('Argument of "toTime" must be valid date time! Passed: ' + args[0])
      }
    }
  }

  this.operatorAddDuration = function(args) {
    if (args.length < 2) {
      this.error('Operator "addDuration" must have at least two args! Passed arguments: ' + JSON.stringify(args))
    }
    if (MC.isNull(args[0])) {
      return null
    } else if (args[0] == '') {
      return ''
    } else {
      let result = MC.dateTimeStringToLuxon(args[0])
      if (!result.v.isValid) {
        this.error('Operator "addDuration" must have date, dateTime or time as first argument! Passed arguments: ' + JSON.stringify(args))
      }
      for (var i=1; i<args.length; i++) {
        if (!MC.isNull(args[i]) && args[i] !== '') {
          var act = new Duration()
          act.parseIsoString(args[i])
          if (!act.isValidDuration()) {
            this.error('Operator "addDuration" works only with durations from second argument! Passed arguments: ' + JSON.stringify(args))
          }
          MC.luxonAdd(result, act)
        }
      }
      return MC.luxonToDateTimeString(result, 'dateTime')
    }
  };

  this.operatorDataNode = function(args) {
    var result = {};
    if (args.length % 2 != 0) {
      this.error('Operator "object" must have even number of args! Passed arguments: ' + JSON.stringify(args));
    }
    for (var i=0; i<args.length; i++) {
      if (typeof(args[i]) == 'string'  && args[i] !== '') {
        if (Array.isArray(args[i+1])) {
          result[args[i] + '*'] = args[i+1];
        } else {
          result[args[i]] = args[i+1];
        }
      } else {
        this.error('Property key in operator "object" must be not null and not empty string! Passed arguments: ' + JSON.stringify(args));
      }
      i++;
    }
    return result;
  };

  this.operatorDurationBetween = function(args) {
    if (args.length!= 2 && args.length!= 3) {
      this.error('Function "durationBetween" must have two or three args! Passed arguments: ' + JSON.stringify(args))
    }
    let date1 = MC.dateTimeStringToLuxon(args[0])
    if (!date1.v.isValid) {
      this.error('Function "addDuration" must have valid dateTime as first argument! Passed arguments: ' + JSON.stringify(args))
    }
    let date2 = MC.dateTimeStringToLuxon(args[1])
    if (!date2.v.isValid) {
      this.error('Function "addDuration" must have valid dateTime as second argument! Passed arguments: ' + JSON.stringify(args))
    }
    if (MC.objectHasTimezone(date1) != MC.objectHasTimezone(date2)) {
      this.error('Either both of the args must have timezone or none of them!')
    }
    if (args.length == 3 && !MC.isNull(args[2]) && args[2] !== '') {
      const units = ["y", "M", "d", "H", "m", "s", "S"]
      if (units.indexOf(args[2]) == -1) {
        this.error('Unknown duration unit ' + args[1] + ' as second argument of function "durationComponent"! Available units are: ' + JSON.stringify(units))
      }
      let result = new Duration()
      switch (args[2]) {
        case 'y': result.from(Math.floor(date2.v.diff(date1.v, 'years').toObject().years), 0, 0, 0, 0, 0, 0); break
        case 'M': result.from(0, Math.floor(date2.v.diff(date1.v, 'months').toObject().months), 0, 0, 0, 0, 0); break
        case 'd': result.from(0, 0, Math.floor(date2.v.diff(date1.v, 'days').toObject().days), 0, 0, 0, 0); break
        case 'H': result.from(0, 0, 0, Math.floor(date2.v.diff(date1.v, 'hours').toObject().hours), 0, 0, 0); break
        case 'm': result.from(0, 0, 0, 0, Math.floor(date2.v.diff(date1.v, 'minutes').toObject().minutes), 0, 0); break
        case 's': result.from(0, 0, 0, 0, 0, Math.floor(date2.v.diff(date1.v, 'seconds').toObject().seconds), 0); break
        case 'S': result.from(0, 0, 0, 0, 0, 0, Math.floor(date2.v.diff(date1.v, 'milliseconds').toObject().milliseconds)); break
      }
      return result.toIsoString()
    } else {
      let duration = MC.durationBetween(date1, date2)
      return duration.toIsoString()
    }
  }

  this.operatorToTimezone = function(args) {
    if (args.length != 1 && args.length != 2) {
      this.error('Operator "toTimezone" must have one or two two args! Passed arguments: ' + JSON.stringify(args))
    }
    if (MC.isNull(args[0])) {
      return null
    }
    if (args[0] === '') {
      return ''
    }
    if ((args[0]+'').match(/^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?)?)?)?$/i)) {
      this.error('Cannot convert to different timezone, value ' + args[0] + ' is in no timezone!')
    }
    let lux = MC.dateTimeStringToLuxon(args[0])
    if (!MC.isNull(args[1]) && args[1] !== '') {
      if (args[1] == 'Z') {
        lux.v = lux.v.setZone('utc')
      } else {
        lux.v = lux.v.setZone('utc' + args[1])
      }
    } else {
      let timezone = null;
      if (!MC.isNull(this.cData['env/context'])) {
        timezone = this.getData(this.cData['env/context'], 'localTimezoneId')
      }
      if (timezone != null) {
        lux.v = lux.v.setZone(timezone)
      } else {
        let offsetToset = Math.floor(DateTime.local().offset/60)
        lux.v = lux.v.setZone('utc' + (offsetToset > 0 ? '+' : '') + offsetToset)
      }
    }
    return MC.luxonToDateTimeString(lux)
  };

  this.operatorTimezone = function(args) {
    if (args.length > 1) {
      this.error('Operator "toTimezone" must have no or one argument! Passed arguments: ' + JSON.stringify(args))
    }
    if (args.length == 0) {
      let mockNow = this.operatorAppCfgVal(['fl:mockNow'])
      if (MC.isNull(mockNow)) {
        return DateTime.local().toFormat('ZZ')
      } else {
        return DateTime.fromISO(mockNow).toFormat('ZZ')
      }
    }
    if (MC.isNull(args[0]) || args[0] === '') {
      this.error('Argument of function "timezone" can not be empty ot null! Passed arguments: ' + JSON.stringify(args))
    }
    let lux = MC.dateTimeStringToLuxon(args[0])
    if (!lux.v.isValid) {
      this.error('Argument of function "timezone" must be valid dateTime, date or time string! Passed arguments: ' + JSON.stringify(args))
    }
    let match = (args[0]).match(/^([\d-:\.T]*)(([+-]\d\d:\d\d)|Z)$/i)
    if (match) {
      return match[2]
    } else {
      return null
    } 
  }

  this.operatorParseDate = function(args) {
    if (args.length < 1 || args.length > 2) {
      this.error('Function "parseDate" must have one or two args! Passed arguments: ' + JSON.stringify(args))
    }
    if (MC.isNull(args[0])) {
      return null
    }
    if (args[0] === '') {
      return ''
    }
    return MC.parseDate(args[0], args[1])
  }

  this.operatorToDateTime = function(args) {
    if (args.length != 2) {
      this.error('Function "toDateTime" must have must have exactly two args! Passed arguments: ' + JSON.stringify(args))
      return
    }
    let date = args[0]
    if (MC.isNull(date) || date === '') {
      date = '0000-01-01'
    } else {  
      let test = MC.dateTimeStringToLuxon(args[0])
      if (!test.v.isValid) {
        this.error('First argument of function "toDateTime" has invalid format! Passed arguments: ' + JSON.stringify(args))
        return
      }
    }
    let time = args[1]
    if (MC.isNull(time) || time === '') {
      time = '00:00:00'
    } else {
      let test = MC.dateTimeStringToLuxon(args[1])
      if (!test.v.isValid) {
        this.error('Second argument of function "toDateTime" has invalid format! Passed arguments: ' + JSON.stringify(args))
        return
      }
    }
    let tz1 = this.operatorTimezone([date])
    let tz2 = this.operatorTimezone([time])
    if (tz1 && tz2 && tz1 != tz2) {
      this.error('Date and time args of function "toDateTime" are in different timezones! Passed arguments: ' + JSON.stringify(args))
      return
    }
    let zoneOut = ''
    if (tz1) {
      zoneOut = tz1
    } else if (tz2) {
      zoneOut = tz2
    }
    date = this.operatorRemoveTimezone([date])
    time = this.operatorRemoveTimezone([time])
    return date + 'T' + time + zoneOut
  }

  this.operatorSetTimezone = function(args) {
    if (args.length != 1 && args.length != 2) {
      this.error('Function "setTimezone" must have must one or two args! Passed arguments: ' + JSON.stringify(args))
    }
    if (MC.isNull(args[0])) {
      return null
    }
    if (args[0] === '') {
      return ''
    }
    let lux = MC.dateTimeStringToLuxon((args[0]+'').replace(/(([+-]\d\d:\d\d)|Z)/, ''))
    if (!lux.v.isValid) {
      this.error('First argument of function "setTimezone" has to be valid date or time value! Passed arguments: ' + JSON.stringify(args))
      return
    }
    let another = MC.dateTimeStringToLuxon((args[0]+'').replace(/(([+-]\d\d:\d\d)|Z)/, ''))
    if (!MC.isNull(args[1])) {
      if (args[1] == 'Z') {
        another.v = another.v.setZone('utc')
      } else {
        another.v = another.v.setZone('utc' + args[1])
      }
    } else {
      let timezone = null
      if (!MC.isNull(this.cData['env/context'])) {
        timezone = this.getData(this.cData['env/context'], 'localTimezoneId')
      }
      if (timezone != null) {
        another.v = another.v.setZone(timezone)
      } else {
        let offsetToset = Math.floor(DateTime.local().offset/60)
        another.v = another.v.setZone('utc' + (offsetToset > 0 ? '+' : '') + offsetToset)
      }
    }
    // shift the luxon by the difference in offsets
    another.v = another.v.plus({ minutes: lux.v.offset - another.v.offset})
    return MC.luxonToDateTimeString(another, null, true)
  };

  this.operatorRemoveTimezone = function(args) {
    if (args.length != 1) {
      this.error('Function "removeTimezone" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    return (args[0]+'').replace(/(([+-]\d\d:\d\d)|Z)/, '');
  };

  this.operatorNormalizeDuration = function(args) {
    if (args.length < 2 && args.length > 4) {
      this.error('Function "normalizeDuration" must have two, three or four args! Passed arguments: ' + JSON.stringify(args))
      return
    }
    if (MC.isNull(args[0])) {
      return null
    }
    if (args[0] === '') {
      return ''
    }
    if (MC.isNull(args[1]) || args[1] === '') {
      this.error('Second argument of function "normalizeDuration" cannot be null or empty! Passed arguments: ' + JSON.stringify(args));
      return
    }
    let date = MC.dateTimeStringToLuxon(args[1])
    if (!date.v.isValid) {
      this.error('Second argument of function "normalizeDuration" must be valid dateTime! Passed arguments: ' + JSON.stringify(args));
      return;
    }
    let atStart = true
    if (args.length > 2) {
      if (args[2] === 'false' || args[2] === false) {
        atStart = false
      }
    }
    let duration = new Duration()
    duration.parseIsoString(args[0])
    if (!duration.isValidDuration()) {
      this.error('First argument of function "normalizeDuration" must be valid duration! Passed arguments: ' + JSON.stringify(args))
      return
    }
    if (!atStart) {
      duration.negate()
    }
    let other = MC.dateTimeStringToLuxon(args[1])
    MC.luxonAdd(other, duration)

    if (args.length > 3) {
      const units = ["y", "M", "d", "H", "m", "s", "S"];
      if (units.indexOf(args[3]) == -1) {
        this.error('Unknown duration unit ' + args[3] + ' as second argument of function "durationComponent"! Available units are: ' + JSON.stringify(units))
      }
      var result = new Duration();
      switch (args[3]) {
        case 'y': result.from(Math.floor(other.v.diff(date.v, 'years').toObject().years), 0, 0, 0, 0, 0, 0); break
        case 'M': result.from(0, Math.floor(other.v.diff(date.v, 'months').toObject().months), 0, 0, 0, 0, 0); break
        case 'd': result.from(0, 0, Math.floor(other.v.diff(date.v, 'days').toObject().days), 0, 0, 0, 0); break
        case 'H': result.from(0, 0, 0, Math.floor(other.v.diff(date.v, 'hours').toObject().hours), 0, 0, 0); break
        case 'm': result.from(0, 0, 0, 0, Math.floor(other.v.diff(date.v, 'minutes').toObject().minutes), 0, 0); break
        case 's': result.from(0, 0, 0, 0, 0, Math.floor(other.v.diff(date.v, 'seconds').toObject().seconds), 0); break
        case 'S': result.from(0, 0, 0, 0, 0, 0, Math.floor(other.v.diff(date.v, 'milliseconds').toObject().milliseconds)); break
      }
      return result.toIsoString()
    } else {
      let result = MC.durationBetween(date, other)
      if (!atStart) {
        result.negate()
      }
      return result.toIsoString()
    }
  }

  this.operatorFillTimezone = function(args) {
    if (args.length != 1 && args.length != 2) {
      this.error('Function "fillTimezone" must have one or two args! Passed arguments: ' + JSON.stringify(args))
      return
    }
    if (MC.isNull(args[0])) {
      return null
    }
    if (args[0] === '') {
      return ''
    }
    let lux = MC.dateTimeStringToLuxon(args[0])
    if (!lux.v.isValid) {
      this.error('First argument of function "fillTimezone" must be valid dateTime! Passed arguments: ' + JSON.stringify(args))
      return
    }
    if (MC.hasTimezone(args[0])) {
      return args[0]
    }
    let another = MC.dateTimeStringToLuxon(args[0])
    if (!MC.isNull(args[1])) {
      if (args[1] == 'Z') {
        another.v = another.v.setZone('utc')
      } else {
        another.v = another.v.setZone('utc' + args[1])
      }
    } else {
      let timezone = null
      if (!MC.isNull(this.cData['env/context'])) {
        timezone = this.getData(this.cData['env/context'], 'localTimezoneId')
      }
      if (timezone != null) {
        another.v = another.v.setZone(timezone)
      } else {
        let offsetToset = Math.floor(DateTime.local().offset/60)
        another.v = another.v.setZone('utc' + (offsetToset > 0 ? '+' : '') + offsetToset)
      }
    }
    // shift the lux by the difference in offsets
    another.v = another.v.plus({ minutes: lux.v.offset - another.v.offset})
    return MC.luxonToDateTimeString(another, null, true)
  }

  this.operatorDurationComponent = function(args) {
    if (args.length != 2) {
      this.error('Function "durationComponent" must have exactly two args! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    var duration = new Duration();
    duration.parseIsoString(args[0]);
    if (!duration.isValidDuration()) {
      this.error('First argument of function "durationComponent" must be valid duration! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[1]) || args[1] === '') {
      this.error('Second argument of function "durationComponent" can not be null or empty! Passed arguments: ' + JSON.stringify(args));
    }
    var units = ["y", "M", "d", "H", "m", "s", "S"];
    if (units.indexOf(args[1]) == -1) {
      this.error('Unknown duration unit ' + args[1] + ' as second argument of function "durationComponent"! Available units are: ' + JSON.stringify(units));
    }
    switch (args[1]) {
      case 'y': return duration.getYears();
      case 'M': return duration.getMonths();
      case 'd': return duration.getDays();
      case 'H': return duration.getHours();
      case 'm': return duration.getMinutes();
      case 's': return duration.getSeconds();
      case 'S': return duration.getMilliseconds();
    }
  };

  this.operatorEvery = function(exprs) {
    if (!Array.isArray(exprs) || exprs.length != 2) {
      this.error('Function "every" must have exactly two args! ' + exprs.length + ' args were passed.');
    }
    var expr1 = new Expression(exprs[0], this.cData, this.opts);
    var arg1Coll = expr1.evaluate();
    this.trace.args = [expr1.getTrace()];
    if (expr1.getError()) {
      this.error(expr1.getError());
      return null;
    }
    if (MC.isNull(arg1Coll)) {
      return null;
    }
    if (arg1Coll === '') {
      return '';
    }
    arg1Coll = MC.asArray(arg1Coll);
    if (arg1Coll.length == 0) {
      return true;
    }
    var base = this.enterBaseContext();
    this.setPositionValue(arg1Coll);
    this.trace.args.push([]);
    for (var i = 0; i < arg1Coll.length; i++) {
      this.setPosition(i);
      var expr2 = new Expression(exprs[1], this.cData, this.opts);
      var value = MC.asScalar(expr2.evaluate());
      this.trace.args[1].push(expr2.getTrace());
      if (expr2.getError()) {
        this.error(expr2.getError());
        return null;
      }
      this.setPosition(null);
      if (MC.isNull(value) || value === '' || !value) {
        return false;
      }
    }
    this.setPositionValue(null);
    this.leaveBaseContext(base);
    return true;
  };

  this.operatorSome = function(exprs) {
    if (!Array.isArray(exprs) || exprs.length != 2) {
      this.error('Function "some" must have exactly two args! ' + exprs.length + ' args were passed.');
    }
    var expr1 = new Expression(exprs[0], this.cData, this.opts);
    var arg1Coll = expr1.evaluate();
    this.trace.args = [expr1.getTrace()];
    if (expr1.getError()) {
      this.error(expr1.getError());
      return null;
    }
    if (MC.isNull(arg1Coll)) {
      return null;
    }
    if (arg1Coll === '') {
      return '';
    }
    arg1Coll = MC.asArray(arg1Coll);
    if (arg1Coll.length == 0) {
      return false;
    }
    var base = this.enterBaseContext();
    this.setPositionValue(arg1Coll);
    this.trace.args.push([]);
    for (var i = 0; i < arg1Coll.length; i++) {
      this.setPosition(i);
      var expr2 = new Expression(exprs[1], this.cData, this.opts);
      var value = MC.asScalar(expr2.evaluate());
      this.trace.args[1].push(expr2.getTrace());
      if (expr2.getError()) {
        this.error(expr2.getError());
        return null;
      }
      this.setPosition(null);
      if (MC.isNull(value) || value === '') {
        continue;
      }
      if (value) {
        return true;
      }
    }
    this.setPositionValue(null);
    this.leaveBaseContext(base);
    return false;
  };

  this.operatorMap = function(exprs) {
    if (!Array.isArray(exprs) || exprs.length != 2) {
      this.error('Function "map" must have exactly two args! ' + exprs.length + ' args were passed.');
    }
    var expr1 = new Expression(exprs[0], this.cData, this.opts);
    var arg1Coll = expr1.evaluate();
    this.trace.args = [expr1.getTrace()];
    if (expr1.getError()) {
      this.error(expr1.getError());
      return null;
    }
    if (MC.isNull(arg1Coll)) {
      return null;
    }
    if (arg1Coll === '') {
      return '';
    }
    arg1Coll = MC.asArray(arg1Coll);
    var base = this.enterBaseContext();
    var result = [];
    this.setPositionValue(arg1Coll);
    this.trace.args.push([]);
    for (var i = 0; i < arg1Coll.length; i++) {
      this.setPosition(i);
      var expr2 = new Expression(exprs[1], this.cData, this.opts);
      result.push(expr2.evaluate());
      this.trace.args[1].push(expr2.getTrace());
      if (expr2.getError()) {
        this.error(expr2.getError());
        return null;
      }
      this.setPosition(null);
    }
    this.setPositionValue(null);
    this.leaveBaseContext(base);
    if (MC.isNull(result)) {
      return null;
    } else {
      return result;
    }
  };

  this.operatorTableLookup = function(args) {
    if (args.length != 2) {
      this.error('Function "tableLookup" must have exactly two args! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[1]) || args[1] == '') {
      this.error('Second argument of function  "tableLookup" cannot be null or empty! Passed arguments: ' + JSON.stringify(args));
    }
    var rootPath = 'vmt/' + args[1];
    var vmts = this.cData[rootPath];
    if (MC.isNull(vmts)) {
      this.error('No mapping table found!');
    }
    var result = vmts[args[0]];
    if (MC.isNull(result)) {
      return null;
    } else {
      var evaluated = this.evaluateSource({source: result});
      if (MC.isNull(evaluated) && result !== 'null') {
        return result;
      } else {
        return evaluated;
      }
    }
  };

  this.operatorDistinct = function(args) {
    if (args.length != 1) {
      this.error('Function "distinct" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
    }
    if (Array.isArray(args[0])) {
      var result = [];
      for (var i = 0; i < args[0].length; i++) {
        if (result.indexOf(args[0][i]) == -1) {
          result.push(args[0][i]);
        }
      }
      return result;
    } else {
      return [args[0]];
    }
  };

  this.operatorAbs = function(args) {
    if (args.length != 1) {
      this.error('Function "abs" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
      return;
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    if (MC.isNumeric(args[0])) {
      return MC.getNumberAsString(math.abs(math.bignumber(args[0])));
    } else {
      var result = new Duration();
      result.parseIsoString(args[0]);
      if (!result.isValidDuration()) {
        this.error('Function "abs" works only with numbers or durations! Passed arguments: ' + JSON.stringify(args[0]));
        return;
      }
      if (result.getNegative()) {
        result.negate();
      }
      return result.toIsoString();
    }
  };

  this.operatorSort = function(exprs) {
    if (!Array.isArray(exprs) || exprs.length < 1 || exprs.length > 4) {
      this.error('Function "sort" must have one to four args! ' + exprs.length + ' args were passed.');
    }
    var expr1 = new Expression(exprs[0], this.cData, this.opts);
    var arg1Coll = expr1.evaluate();
    this.trace.args = [expr1.getTrace()];
    if (expr1.getError()) {
      this.error(expr1.getError());
      return null;
    }
    if (MC.isNull(arg1Coll)) {
      return null;
    }
    if (arg1Coll === '') {
      return '';
    }
    var collection = MC.asArray(arg1Coll);
    var sortBy = [];
    if (exprs.length > 1) {
      this.trace.args.push([]);
      var base = this.enterBaseContext();
      this.setPositionValue(collection);
      for (var i = 0; i < collection.length; i++) {
        this.setPosition(i);
        var expr2 = new Expression(exprs[1], this.cData, this.opts);
        var value = MC.asScalar(expr2.evaluate());
        this.trace.args[1].push(expr2.getTrace());
        if (expr2.getError()) {
          this.error(expr2.getError());
          return null;
        }
        this.setPosition(null);
        sortBy.push(value);
      }
      this.setPositionValue(null);
      this.leaveBaseContext(base);
    }
    if (MC.isNull(sortBy)) {
      sortBy = collection;
    }
    var objects = [];
    for (var i=0; i<collection.length; i++) {
      objects.push({sortBy: (i < sortBy.length ? sortBy[i] : sortBy[sortBy.length-1]), value: collection[i]});
    }
    var desc = false;
    if (exprs[2] && exprs[2].source == "'descending'") {
      desc = true;
      this.trace.args.push('descending');
    }
    if (desc) {
      objects.sort(function(a, b) {
        if (a.sortBy < b.sortBy) return 1;
        if (a.sortBy > b.sortBy) return -1;
        return 0;
      });
    } else {
      objects.sort(function(a, b) {
        if (a.sortBy < b.sortBy) return -1;
        if (a.sortBy > b.sortBy) return 1;
        return 0;
      });
    }
    var result = [];
    for (var i=0; i<objects.length; i++) {
      result.push(objects[i]['value']);
    }
    return result;
  };

  this.operatorDelete = function(args) {
    if (args.length != 2) {
      this.error('Function "delete" must have exactly two args! Passed arguments: ' + JSON.stringify(args));
      return;
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    var indexes = [];
    var arg1 = MC.asArray(args[1]);
    for (var i = 0; i < arg1.length; i++) {
      if (!MC.isNull(arg1[i])) {
        if (MC.isNumeric(arg1[i])) {
          indexes.push(Number(arg1[i]).valueOf());
        } else {
          this.error('All indexes in second argument of "delete" function must be numbers! Passed arguments: ' + JSON.stringify(args));
        }
      }
    }
    var arg0 = MC.asArray(args[0]);
    if (indexes.length == 0) {
      return arg0;
    }
    var result = [];
    for (var i = 0; i < arg0.length; i++) {
      if (indexes.indexOf(i) == -1) {
        result.push(arg0[i]);
      }
    }
    if (result.length > 0) {
      return result;
    } else {
      return null;
    }
  };

  this.operatorUpdate = function(args) {
    if (args.length != 3) {
      this.error('Function "update" must have exactly three args! Passed arguments: ' + JSON.stringify(args));
      return;
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    var arg1 = MC.asArray(args[1]);
    var indexes = [];
    for (var i = 0; i < arg1.length; i++) {
      if (!MC.isNull(arg1[i])) {
        if (MC.isNumeric(arg1[i])) {
          indexes.push(Number(arg1[i]).valueOf());
        } else {
          this.error('All indexes in second argument of "update" function must be numbers! Passed arguments: ' + JSON.stringify(args));
        }
      } else {
        indexes.push(null);
      }
    }
    var arg0 = MC.asArray(args[0]);
    if (indexes.length == 0) {
      return arg0;
    }
    var arg2 = MC.asArray(args[2]);
    var result = [].concat(arg0);
    for (var i = 0; i < indexes.length; i++) {
      var index = indexes[i];
      if (index != null) {
        result[index] = i < arg2.length ? arg2[i] : arg2[arg2.lenght-1];
      }
    }
    if (result.length > 0) {
      return result;
    } else {
      return null;
    }
  };

  this.operatorCollectionUnwrap = function(args) {
    if (args.length < 1 || args.length > 3) {
      this.error('Function "collectionUnwrap" must have one, two or three args! Passed arguments: ' + JSON.stringify(args));
      return;
    }
    if (Array.isArray(args[0])) {
      var index = 0;
      if (args.length > 1) {
        if (!MC.isNull(args[1]) && args[1] !== '') {
          if (MC.isNumeric(args[1]) && args[1] > 0) {
            index = parseInt(args[1]);
          }
        }
      }
      var depth = -1;
      if (args.length > 2) {
        if (!MC.isNull(args[2]) && args[2] !== '') {
          if (MC.isNumeric(args[2]) && args[2] > -1) {
            depth = parseInt(args[2]);
          }
        }
      }
      if (depth == -1) {
        depth = MC.collDepth(args[0]) - 1;
      }
      this.unwrap(args[0], depth, index);
    }
    return args[0];
  };

  this.unwrap = function(collection, atDepth, index) {
    for (var i = 0; i < collection.length; i++) {
      if (Array.isArray(collection[i])) {
        var collItem = MC.asArray(collection[i]);
        if (atDepth == 1) {
          var selectedItem = collItem.length > index ? collItem[index] : null;
          collection[i] = selectedItem;
        } else {
          this.unwrap(collItem, atDepth - 1, index);
        }
      }
    }
  };

  this.operatorIbanToDisplay = function(args) {
    if (args.length != 1) {
      this.error('Function "ibanToDisplay" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
      return null;
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    var iban = args[0]+'';
    if (!iban.match(/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{0,30}$/)) {
      this.error("Invalid IBAN value: " + iban);
      return null;
    }
    var formatted = '';
    var i = 0;
    while (i + 4 <= iban.length) {
      if (i > 0) {
        formatted += " ";
      }
      formatted += iban.substring(i, i + 4);
      i += 4;
    }
    if (i < iban.length) {
      formatted += " ";
      formatted += iban.substring(i);
    }
    return formatted;
  };

  this.operatorLookup = function(args) {
    if (args.length != 3) {
      this.error('Function "lookup" must have exactly three args! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    var collection = MC.asArray(args[0]);
    var keys = MC.asArray(args[1]);
    var values = MC.asArray(args[2]);
    if (keys.length != values.length) {
      this.error('Collections in second and third argument of function "lookup" must have same size! Passed arguments: ' + JSON.stringify(args));
      return null;
    }
    var result = [];
    for (var i=0; i<collection.length; i++) {
      var found = false;
      for (var k=0; k<keys.length; k++) {
        if (keys[k] == collection[i]) {
          result.push(values[k]);
          found = true;
          break;
        }
      }
      if (!found) {
        result.push(null);
      }
    }
    if (MC.isNull(result)) {
      return null;
    } else {
      return result;
    }
  };

  this.operatorReplace = function(args) {
    if (args.length != 3) {
      this.error('Functions "replace" must have exactly 3 args! Passed arguments: ' + JSON.stringify(args) + '.');
    }
    if (MC.isNull(args[0])) {
      return null;
    }
    if (args[0] === '') {
      return '';
    }
    if (MC.isNull(args[1]) || args[1] === '') {
      return args[0];
    }
    if (MC.isNull(args[2])) {
      args[2] == '';
    }
    return (args[0]+'').replace(new RegExp(args[1]+'', 'g'), args[2]+'');
  };

  this.operatorFirstNonNull = function(args) {
    for (var i=0; i<args.length; i++) {
      if (!MC.isNull(args[i])) {
        return args[i];
      }
    }
    return null;
  };

  this.operatorQuote = function(exprs) {
    if (!Array.isArray(exprs) || exprs.length != 1) {
      this.error('Function "quote" must have exactly one argument! Passed arguments: ' + JSON.stringify(exprs) + '.');
    }
    var props = '<rbs:Data xmlns:d="http://metarepository.com/fspl/svc_mta#" xmlns:fl="http://resourcebus.org/interpreters/flow/#" xmlns:rbs="http://resourcebus.org/ns/storage#">\n';
    props += this.exprToPropertiesXml(exprs[0]);
    if (Array.isArray(this.cData['env/ns'])) {
      for (var i=0; i<this.cData['env/ns'].length; i++) {
        var ns = this.cData['env/ns'][i];
        props += '<fl:namespace rbs:id="' + ns.prefix + '">\n';
        props += '<d:prefix>' + ns.prefix + '</d:prefix>\n';
        props += '<d:uri>' + ns.uri + '</d:uri>\n';
        props += '</fl:namespace>\n';
      }
    }
    props += '</rbs:Data>';
    return props;
  };

  this.exprToPropertiesXml = function(expr) {
    let props = '';
    if (expr.operator) {
      if (expr.operator == 'unquote') {
        props += '<d:param1>' + this.operatorUnquote(expr.expr) + '</d:param1>\n';
        return props;
      } else {
        props += '<d:mfunction>' + MC.escapeXML(expr.operator) + '</d:mfunction>\n';
      }
    }
    if (expr.source) {
      props += '<d:param1>' + MC.escapeXML(expr.source) + '</d:param1>\n';
    }
    if (expr.expr && Array.isArray(expr.expr)) {
      for (var i=0; i<expr.expr.length; i++) {
        props += '<d:OperationActionMapping>\n';
        props += this.exprToPropertiesXml(expr.expr[i]);
        props += '</d:OperationActionMapping>\n';
      }
    }
    return props;
  };

  this.operatorUnquote = function(exprs) {
    if (!Array.isArray(exprs) || exprs.length != 1) {
      this.error('Function "unquote" must have exactly one argument! Passed arguments: ' + JSON.stringify(exprs) + '.');
    }
    var expression = new Expression(exprs[0], this.cData, this.opts);
    var result = expression.evaluate();
    if (!this.trace.args) {
      this.trace.args = [];
    }
    this.trace.args.push(expression.getTrace());
    if (expression.getError()) {
      this.error(expression.getError());
    }
    if (Array.isArray(result) && result.length > 0) {
      var res = '[';
      var sep = '';
      for (var i=0; i<result.length; i++) {
        res += sep + "'" + result[i].toString() + "'";
        sep = ', ';
      }
      return res + ']';
    } else { //TODO: support for other than simple types?????
      return "'" + result.toString() + "'";
    }
  };

  this.operatorPath = function(args) {
    if (args.length != 1) {
      this.error('Function "path" must have exactly 1 argument! Passed arguments: ' + JSON.stringify(args) + '.');
      return;
    }
    if (MC.isNull(args[0]) || args[0] === '') {
      this.error('Argument of function "path" must be specified. Passed arguments: ' + JSON.stringify(args) + '.');
      return;
    }
    return args[0].toString();
  };

  this.operatorShorten = function(args) {
    if (args.length != 2 && args.length != 3) {
      this.error('Function "shorten" works only with two or three args! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[0])) {
      return null;
    } else if (args[0] === '') {
      return '';
    } else {
      var length = args[1];
      if (!MC.isNumeric(length)) {
        this.error('Second argument of function "shorten" must be number! Passed: ' + length);
      } else {
        length = parseInt(length);
      }
      var addDoots = true;
      if (args[2] === false) {
        addDoots = false;
      }
      var res = args[0]+'';
      if (res.length > length) {
        res = res.substring(0, length);
        if (addDoots) {
          res += '...';
        }
      }
      return res;
    }
  };

  this.operatorCast = function(args) {
    if (args.length != 2) {
      this.error('Function "cast" must have exactly two args! Passed arguments: ' + JSON.stringify(args));
    }
    if (MC.isNull(args[0])) {
      return null;
    } else if (args[0] === '') {
      return '';
    } else {
      var desiredType = args[1];
      if (MC.isNull(desiredType) || desiredType === '') {
        this.error('Second argument of function "cast" must not be null or empty! Passed arguments: ' + JSON.stringify(args));
      }
      if (desiredType === 'string') {
        return '' + args[0];
      } else if (['integer', 'long', 'int', 'short', 'byte'].indexOf(desiredType) > -1) {
        return math.bignumber(args[0]).format(mathObj, {notation: 'fixed'});
      } else if (['float', 'double'].indexOf(desiredType) > -1) {
        return math.bignumber(args[0]).format(mathObj, {exponential: {lower:1e-100, upper:1e100}});
      } else {
        return args[0];
      }
    }
  };

  this.operatorRiResolve = function(args) {
    if (args.length != 2) {
      this.error('Function "riResolve" must have two args! Passed arguments: ' + JSON.stringify(args));
      return;
    }
    if (MC.isNull(args[0]) && MC.isNull(args[1])) {
      return null;
    }
    const base = args[0].toString();
    const ref = args[1].toString();
    const sep = '/';
    if (ref.startsWith(sep)) {
      return ref.substring(1);
    } else {
      let result = new MC.URLUtils(ref, base).href;
      if (result.startsWith(sep)) {
        return result.substring(1);
      } else {
        return result;
      }
    }
  };

  this.operatorRiRelativize = function(args) {
    if (args.length !== 2 && args.length !== 3) {
      this.error('Function "riRelativize" must have two or two or three args! Passed arguments: ' + JSON.stringify(args));
      return;
    }
    if (MC.isNull(args[0]) && MC.isNull(args[1])) {
      return null;
    }
    const base = args[0].toString();
    const ri = args[1].toString();
    const preferAbsolute = (args[2] === true || args[2] === 'true');
    const sep = '/';
    const pathSegments = base.split(sep).filter(t => t !== '');
    const riPathSegments = ri.split(sep).filter(t => t !== '');
    const fsStyle = (base.endsWith(sep) && base !== '/');

    let segments = [];
    let i = 0;
    // get index of last common segment (the highest index of segment for which preceding segments (including self) are
    // same and following segments are different - in other words, number of shared path segments)
    while (pathSegments.length > i && riPathSegments.length > i && pathSegments[i] === riPathSegments[i]) {
      i++;
    }
    if (i !== 0 || !preferAbsolute) {
      // add "step up" segment until this.pathSegments is traversed up to the last common segment (if necessary)
      // additional "minus one" is needed because of specific URI resolution rules (child segment is not appended to last
      // segment, but to a segment preceding last)
      const to = fsStyle ? (pathSegments.length - i) : (pathSegments.length - i - 1);
      for (let j = 0; j < to; j++) {
        segments.push('..');
      }
    }
    if (fsStyle) {
      // add all ri.pathSegments segments following last common segment
      for (let j = i; j < riPathSegments.length; j++) {
        segments.push(riPathSegments[j]);
      }
    } else {
      // add all ri.pathSegments segments following last common segment (repeat last common segment if not "stepping up")
      for (let j = (i < pathSegments.length || i === 0 ? i : i - 1); j < riPathSegments.length; j++) {
        segments.push(riPathSegments[j]);
      }
    }
    let relative = segments.join(sep);
    if (i === 0 && preferAbsolute) {
      relative = sep + relative;
    }
    if (ri.endsWith(sep) && ri !== '/') {
      relative = relative + sep;
    }
    return relative;
  };

  this.operatorStringFind = function(args) {
    if (args.length != 2 && args.length != 3) {
      this.error('Function "stringFind" must have two or three arguments! Passed arguments: ' + JSON.stringify(args))
    }
    if (MC.isNull(args[0])) {
      return null
    }
    if (MC.isNull(args[1]) || args[1] === '') {
      return null
    }
    if (args[2] !== false && args[2] !== 'false') {
      let res = (new RegExp('^' + args[1] + '$')).exec(args[0])
      if (MC.isNull(res)) {
        return null
      } else {
        res.shift()
        return res
      }
    } else {
      let result = [];
      let matches = (args[0]+'').match(new RegExp(args[1], 'g'))
      for (let match of matches) {
        let res = (new RegExp('^' + args[1] + '$')).exec(match)
        if (!MC.isNull(res)) {
          res.shift()
          result.push(res)
        }
      }
      if (result.length > 0) {
        return result
      } else {
        return null
      }
    }
  }

  this.operatorStringContains = function(args) {
    if (args.length != 2 && args.length != 3) {
      this.error('Function "stringContains" must have two or three arguments! Passed arguments: ' + JSON.stringify(args))
    }
    if (MC.isNull(args[0])) {
      return false
    }
    if (MC.isNull(args[1])) {
      return false
    }
    let string = '' + args[0]
    let substring = '' + args[1]
    let caseSensitive = true
    if (args.length > 2) {
      if (args[2] === false || args[2] === 'false') {
        caseSensitive = false
      }
    }
    if (!caseSensitive) {
      string = string.toLowerCase()
      substring = substring.toLowerCase()
    }
    return string.indexOf(substring) > -1
  }

};

export {Expression};