import React from "react";
import ReactDOM from "react-dom";

import {FieldDef} from "./FieldDef.js";
import {WidgetModel} from "./WidgetModel.js";
import {MC} from "../client/MC.js";

class InteractiveField extends React.Component {

  state = {x: this.props.field.initPosition.x,
    y: this.props.field.initPosition.y,
    left: this.props.style.left,
    top: this.props.style.top,
    width: this.props.style.width,
    height: this.props.style.height,
    initPosition: this.props.field.initPosition};
  
  componentDidMount() {
    this.point = this.state.initPosition;
    this.props.modelerReact.interactiveFieldReact = this;
    $(document).mousemove(this.handleMousemove);
    $(document).mouseup(this.handleMouseup);
    this.field = this.props.field.originalField;
    this.modelerReact = this.field.getModelerReact();
    this.formBeforeChange = this.modelerReact.state.form.getCopy();
    this.changed = false;
    this.deleted = false;
    if (this.field.isInPalette) {
        this.makeNewFieldFromPallete(this.field.id);
    }
    this.columnWidth = this.props.field.columnWidth;
    if (this.props.field.offsetChange ||  this.props.field.resizeChange) {
      this.beforeColumns = this.field.beforeColumns();
      this.initOffset = this.field.offset();
    }
    this.modelerReact.setState({placeholderField: this.field.rbsid});
    this.modelerReact.setSelectedField(this.field.rbsid);
    this.adjustTopLeft();
    this.adjustWidthAndHeight();
  }

  componentWillUnmount() {
    $(document).off("mousemove");
    $(document).off("mouseup");
  }

  handleMouseup = (event) => {
    var actualForm = this.modelerReact.state.form;
    if (!formEqual(actualForm, this.formBeforeChange)) {
      this.modelerReact.store(this.formBeforeChange);
    }
    this.interactionEnd = true;
    this.modelerReact.setState({placeholderField: null,
                                interactiveField: null});
    document.body.style.cursor = "";
  };

  handleMousemove = (event) => {
      this.point = {x: event.pageX, y: event.pageY};
      if (this.props.field.resizeChange) {
        this.checkGridColumnsChange();
      } else if (this.props.field.offsetChange) {
        this.setState(this.point);
        this.checkOffset();
      } else {
        this.setState(this.point);
        this.checkMove();
        var x = event.pageX;
        var y = event.pageY;
        var self = this;
        setTimeout(function() {
          if (x == self.point.x && y == self.point.y) {
            if (!self.interactionEnd) {
              self.checkInAndOut();
            }
          }
        }, 200);
      }
  };

  makeNewFieldFromPallete = () => {
    var self = this;
    var flow = this.props.field.flow;
    var palette = WidgetModel.getFieldsPalette();
    var form = this.modelerReact.state.form;

    var fieldFromPalette = palette.find(field => field.id == self.field.id);
    this.field = MC.extend(true, {}, fieldFromPalette);
    FieldDef.setProto(this.field);
    var count = 1;
    while (form.findFieldByName(this.field.id + count))
    {
      count++;
    }
    this.field.setOption(["id"], this.field.id + count);
    this.field.flow = flow;
    this.deleted = true;
    this.field.grid = [];
    if (this.modelerReact.state.form.fields.length == 0) {
      this.field.ensureGrid("medium");
    }
    this.field.addGrid();
    this.field.isNotStored = true;
    this.field.generateRbsId();
    this.checkUndelete();
    if (!this.deleted) {
      this.checkIn();
    }
  };

  afterChange = () => {
    this.modelerReact.formWasChanged();
    if (!this.changed) {
      this.changed = true;
    } else {
    /*
      var topUndoStack = this.modelerReact.state.undoStack[this.modelerReact.state.undoStack.length - 1];
      var actualForm = this.modelerReact.state.form;
      if (formEqual(actualForm, topUndoStack)) {
        this.modelerReact.cancel();
        this.changed = false;
      }
      */
    }
    if (this.point && !this.props.field.offsetChange && !this.props.field.resizeChange) {
      this.adjustInteracted();
    }
  };

  onInOutChange = () => {
    delete this.offsets;
    delete this.rows;
  };

  adjustHeight = () => {
    var $placeholder = $(".placeholder");
    if($placeholder.length != 0) {
      this.setState({height: $placeholder.outerHeight()});
    }
  };

  adjustWidthAndHeight = () => {
    var $placeholder = $(".placeholder");
    if($placeholder.length != 0) {
      this.setState({width: $placeholder.outerWidth(),
                     height: $placeholder.outerHeight()});
    }
  };

  adjustTopLeft = () => {
    var $placeholder = $(".placeholder");
    if($placeholder.length != 0) {
      var $form = $("#modeler .ui.form");
      var left = $placeholder.offset().left - $form.offset().left;
      var top = $placeholder.offset().top - $form.offset().top;
      this.setState({left: left, top: top});
    }
  };

  adjustInteractedToBeUnderCursor = () => {
    var $interactiveField = $(ReactDOM.findDOMNode(this));
    var left = $interactiveField.offset().left;
    var top = $interactiveField.offset().top;
    var height = $interactiveField.outerHeight();
    var width = $interactiveField.outerWidth();
    var x = this.state.initPosition.x;
    var y = this.state.initPosition.y;
    if (this.point.x > left + width) {
      x -= (this.point.x - (left + width)) + 10;
    }
    if (this.point.y > top + height) {
      y -= (this.point.y - (top + height)) + 10;
    }
    this.setState({initPosition: {x: x, y: y}});
  };

  adjustInteracted = () => {
    this.adjustWidthAndHeight();
    this.adjustInteractedToBeUnderCursor();
  };

  computeColumnWidth = ($target) => {
    this.columnWidth = this.props.field.columnWidth;
  };

  checkGridColumnsChange = () => {
    var $node = $(ReactDOM.findDOMNode(this));
    this.setState({width: this.point.x - $node.offset().left});
    var currentColumns;
    if (this.field.isInTabPanel()) {
      currentColumns = 12;
    } else {
      var currentWidth = $node.outerWidth(true);
      currentColumns = Math.max(1, Math.min(Math.ceil(currentWidth / this.columnWidth), 12)); 
    }
    var grid = this.field.getCurrentGrid();
    if (this.field.columns() != currentColumns) {
      this.field.setColumns(currentColumns);
      this.afterChange();
    }
    this.adjustHeight();
    this.adjustTopLeft();
  };

  checkInAndOut = () => {
    if (this.deleted) {
        this.checkUndelete();
    }
    if (!this.deleted) {
      this.checkIn();
      this.checkOut();
      //this.checkDelete();
    }
  };

  checkMove = () => {
    if (!this.deleted) {
      if (this.field.isInTable()) {
        this.checkTableColumn();
      } else if (this.field.isInTabPanel()) {
        this.checkTabPosition();
      } else  {
        this.checkGrid();
      }
    }
  };

  checkOffset = () => {
    var initPosition = this.props.field.initPosition;
    var x = this.point.x - initPosition.x;
    var currentOffset =  this.initOffset + Math.round(x / this.columnWidth);
    var columns = this.field.columns();
    currentOffset = Math.min(Math.max(0,currentOffset), 12 - columns - this.beforeColumns);
    var offset = this.field.offset();
    var offsetChanged = offset !=  currentOffset;
    if (offsetChanged) {
      this.field.setOffset(currentOffset);
      this.afterChange();
    }
  };

  getTargetField = (field) => {
    var fields = field.getSubfields();
    var bboxes = fields.map(field => field.getBBox());
    var inIndex = bboxes.findIndex(bbox => isPointInBox(bbox, this.point));
    if (inIndex != -1) {
      return fields[inIndex];
    }
  };

  checkInHelp = (field) => {
    var targetField = this.getTargetField(field);
    if (targetField  && targetField != this.field) {
      if (WidgetModel.isSupportedChild(this.field.widget, targetField.widget)) {
        var placedIn = this.checkInHelp(targetField);
        if (!placedIn) {
          this.field.removeFromContainer();
          this.placeToContainer(targetField);
          this.afterChange();
          this.onInOutChange();
          return true;
        }
      } else {
        return this.checkInHelp(targetField);
      }
    } else {
      return false;
    }
  };

  placeToContainer = (container) => {
    if (container.isTable()) {
      this.placeToTable(container);
    } else if (container.isWithoutGrid()) {
      var subfields = container.getSubfields();
      container.setSubfields(subfields.concat([this.field]));
      if (container.isTabPanel()) {
        this.field.setColumns(12);
      }
    } else {
      this.placeToGrid(container);
    }
  };

  checkIn = () => {
    var parent = this.field.getTrueParent();
    this.checkInHelp(parent);
  };

  checkOutHelp = (predecessor) => {
    if (!predecessor.isTopLevel()) {
      var bbox = predecessor.getBBox();
      if(!isPointInBox(bbox, this.point)) {
        var parent = predecessor.getTrueParent();
        if (WidgetModel.isSupportedChild(this.field.widget, parent.widget)) {
          this.field.removeFromContainer();
          this.placeToContainer(parent);
          this.afterChange();
          this.onInOutChange();
        }
        this.checkOutHelp(parent);
      }
    }
  };

  checkOut = () => {
    this.checkOutHelp(this.field.getTrueParent());
  };

  innerModelerBBox = () => {
    var $el = $("#modeler > .modelerContainer");
    var bbox = {
      left: $el.offset().left,
      top: $el.offset().top,
      width: $el.outerWidth(),
      height: $el.outerHeight()
    };
    return bbox;
  };

  checkDelete = () => {
    var bbox = this.innerModelerBBox();

    if(!isPointInBox(bbox, this.point)) {
      this.field.removeFromContainer();
      this.deleted = true;
      this.afterChange();
    }
  };

  checkUndelete = () => {
    var bbox = this.innerModelerBBox();
    if(isPointInBox(bbox, this.point)) {
      var form = this.modelerReact.state.form;
      this.field.parent = form;
      this.placeToContainer(form)
      this.checkIn();
      this.deleted = false;
      this.afterChange();
    }
  };

  getColumnIndex = (fields) => {
    var columnsOffsets = FieldDef.computeColumnsOffsets(fields);
    return findNearPoint(columnsOffsets, this.point.x);
  };

  ensureTabPanelOffsets = (tabPanel) => {
    if (typeof this.offsets == "undefined") {
      this.offsets = tabPanel.getTabPanelMenuItemOffsets();
    }
  };

  checkTabPosition = () => {
    var tabPanel = this.field.getTrueParent();
    var subfields = tabPanel.getSubfields();
    var sourceIndex = subfields.findIndex(w => w == this.field);
    this.ensureTabPanelOffsets(tabPanel);
    FieldDef.correctIndexes(subfields);
    var targetIndex = findNearPoint(this.offsets, this.point.x);
    if (targetIndex != -1 && targetIndex != sourceIndex + 1 && targetIndex != sourceIndex) {
      moveElement(subfields, sourceIndex, targetIndex);
      tabPanel.setSubfields(subfields);
      this.afterChange();
    }
  };

  checkTableColumn = () => {
    var table = this.field.getTrueParent();
    var tableFields = table.getSubfields();
    var sourceIndex = tableFields.findIndex(w => w == this.field);
    var targetIndex = this.getColumnIndex(tableFields);
    if (targetIndex != -1 && targetIndex != sourceIndex + 1 && targetIndex != sourceIndex) {
      table.tableMoveColumn(sourceIndex, targetIndex);
      this.afterChange();
    }
  };

  ensureCheckGridRows = (parent) => {
    if (typeof this.rows == "undefined") {
      this.rows = parent.getRows();
    }
  };

  checkGrid = () => {
    var parent = this.field.getTrueParent();
    //this.ensureCheckGridRows(parent);
    var rows = parent.getRows();
    var sourceRowIndex = rows.findIndex(row => row.includes(this.field));
    var sourceRow = rows[sourceRowIndex];
    if (sourceRow) {
        this.gridChanged = false;
        this.checkColumn(sourceRow);
        this.checkRow(rows, sourceRowIndex);
        if (this.gridChanged) {
          var subfields = flatten(rows);
          parent.setSubfields(subfields);
          FieldDef.correctIndexes(subfields);
          delete this.rows;
          this.afterChange();
        }
      }
  };

  checkColumn = (sourceRow) => {
      var sourceIndex = sourceRow.findIndex(w => w == this.field);
      var targetIndex = this.getColumnIndex(sourceRow);
      if (targetIndex != -1 && targetIndex != sourceIndex) {
        moveElement(sourceRow, sourceIndex, targetIndex);
        this.gridChanged = true;
      }
  };

  prepareRowsForCheckRow = (rows, sourceIndex) => {
    var offsets = FieldDef.computeRowsOffsets(rows);
    var targetIndex = findNearPoint(offsets, this.point.y, 20);
    if (targetIndex != -1) {
      if (sourceIndex >= targetIndex) {
        sourceIndex ++;
      }
      rows.splice(targetIndex , 0, []);
    }

    if (targetIndex == -1) {
      var targetIndex = findOffsetIndex(offsets, this.point.y);
      if (targetIndex == -1) {
        rows.unshift([]);
        targetIndex = 0;
        sourceIndex += 1;
      }
      if (targetIndex == rows.length) {
        rows.push([]);
      }
    }
    return [sourceIndex, targetIndex];
  };

  removeFieldFromRows = (rows, sourceIndex) => {
    var sourceRow = rows[sourceIndex];
    var sourceColumnIndex = sourceRow.findIndex(w => w == this.field);
    sourceRow.splice(sourceColumnIndex, 1);  // remove widget from its row
  };

  checkRow = (rows, sourceIndex) => {
      // check if the row of the widget has to be changed and change it

    var pair = this.prepareRowsForCheckRow(rows, sourceIndex);
    var sourceIndex = pair[0];
    var targetIndex = pair[1];
    if (targetIndex != sourceIndex) {
      this.removeFieldFromRows(rows, sourceIndex);
      var targetRow = rows[targetIndex];
      this.placeToRow(targetRow);

      this.gridChanged = true;
    }
  };

  prepareRowsForPlaceToGrid = (rows) => {
    var offsets = FieldDef.computeRowsOffsets(rows);
    var index = findNearPoint(offsets, this.point.y, 20);

    if (index == -1) {
        index = findOffsetIndex(offsets, this.point.y);
    } else {
      rows.splice(index , 0, []);
    }
    if (index == rows.length) { // add new row in the end
        rows.push([]);
    }
    if (index == -1 ) { // add new row at begining
        rows.unshift([]);
        index = 0;
    }
    return index;
  };

  placeToGrid = (targetField) => {
    var rows = targetField.getRows();
    var index = this.prepareRowsForPlaceToGrid(rows);
    var targetRow = rows[index];

    this.placeToRow(targetRow);
    targetField.setSubfields(flatten(rows));

  };

  getPlaceToRowIndex = (row) => {
    var columnsOffsets = FieldDef.computeColumnsOffsets(row);
    var index = findNearPoint(columnsOffsets,this.point.x);
    if (index == -1) {
        index = findOffsetIndex(columnsOffsets, this.point.x);
    }
    if (index == -1) {
        index = 0;
    }
    if (index != columnsOffsets.length) {
      index++;
    }
    return index;
  };

  getPlaceToTableIndex = (table) => {
    var tableFields = table.getSubfields();
    var columnsOffsets = FieldDef.computeColumnsOffsets(tableFields);
    var index = findNearPoint(columnsOffsets, this.point.x);
    if (index == -1) {
        index = findOffsetIndex(columnsOffsets, this.point.x);
    }
    if (index == -1) {
        index = 0;
    }
    return index;
  };

  placeToRow = (row) => {
    var index = this.getPlaceToRowIndex(row);
    row.splice(index, 0, this.field); // put widget to correct column
    //this.field.setOffset(0);
  };

  placeToTable = (table) => {
    var index = this.getPlaceToTableIndex(table);
    table.putToTable(this.field, index);
  };

  render() {
    var initPosition = this.state.initPosition;
    var transform = 'translate(' + (this.state.x - initPosition.x)+ 'px, ' + (this.state.y - initPosition.y) + 'px)';
    var style = MC.extend({}, this.props.style, {
                    WebkitTransform: transform,
                    transform: transform,
                    width: this.state.width + "px",
                    height: this.state.height + "px",
                    left: this.state.left + "px",
                    top: this.state.top + "px"
                  });

    return <div className={this.props.className}
                id="formModelerInteractiveField"
                style={style}>
              {this.props.idLabel}
              {this.props.widget}
           </div>
  }
}


function flatten(arr) {
    return arr.reduce((acc, val) => acc.concat(
                Array.isArray(val) ? flatten(val) : val
                ),[]);
}

function findOffsetIndex (offsets, value) {
    return offsets.map(offset => value > offset).lastIndexOf(true);
}
function findNearPoint (points, value, distance) {
    if (!distance) {
        distance = 50;
    }
    return points.map(point => Math.abs(point - value) < distance).indexOf(true);
}

function moveElement (array, sourcePosition, targetPosition) {
    var element = array[sourcePosition];
    if (sourcePosition < targetPosition) {
        array.splice(targetPosition + 1, 0, element);
        array.splice(sourcePosition, 1);
    } else {
        array.splice(sourcePosition, 1);
        array.splice(targetPosition, 0, element);
    }
}

function isPointInBox (box, point) {
    var x = point.x;
    var y = point.y;
    var left = box.left;
    var top = box.top;
    var right = box.left + box.width;
    var bottom = box.top + box.height;

    return (left <= x) && (x <= right) && (top <= y) && (y <= bottom);
}

function removeElement (array, element) {
    var index = array.indexOf(element);
    if (index != -1) {
        array.splice(index, 1);
    }
}

function adjustBBox (bbox, diff) {
    var result = {};
    result.left = bbox.left - diff;
    result.top = bbox.top - diff;
    result.width = bbox.width + diff * 2;
    result.height = bbox.height + diff * 2;
    return result;
}

function extendBBox (bbox) {
    return adjustBBox(bbox, 50);
}

function reduceBBox(bbox) {
    var size = Math.min(bbox.width, bbox.height);
    var diff = Math.min((size * 0.1), 20);
    return adjustBBox(bbox, - diff);
}

function placeInIndex (offsets, value) {
    var index = findOffsetIndex(offsets, value);
    var nearPoint = findNearPoint(offsets, value, 30);
    if (nearPoint == -1 && index >= 0 && index < offsets.length -1) {
        return index;
    } else {
        return -1;
    }
}


function formEqual ( x, y ) {
  if ( x == y ) return true;
    // if both x and y are null or undefined and exactly the same

  if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) {
      //console.log("x != y", x, y);
      return false;}
    // if they are not strictly equal, they both need to be Objects

  //if ( x.constructor !== y.constructor ) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

  for ( var p in x ) {
    if (p == "parent") continue;
    if ( ! x.hasOwnProperty( p ) ) continue;
      // other properties were tested using x.constructor === y.constructor

    if ( ! y.hasOwnProperty( p ) ) {
        //console.log("y does not have property", x, y, p);
        return false;
    }
      // allows to compare x[ p ] and y[ p ] when set to undefined

    //if ( x[ p ] === y[ p ] ) continue;
      // if they have the same strict value or identity then they are equal

    //if ( typeof( x[ p ] ) !== "object" ) return false;
      // Numbers, Strings, Functions, Booleans must be strictly equal

    if ( ! formEqual( x[ p ],  y[ p ] ) ) {
        //console.log("x[p] != y[p]", x, y, p);
        return false;
    }
      // Objects and Arrays must be tested recursively
  }

  for ( p in y ) {
    if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) ) {
         //console.log("x does not have property", x, y, p);
         return false;}
      // allows x[ p ] to be set to undefined
  }
  return true;
}

function objectToEntries (object) {
    var result = [];
    for (var key in object) {
        result.push([key, object[key]]);
    }
    return result;
}

function entriesToObject (entries) {
    var result = [];
    entries.forEach(entry => result[entry[0]] = entry[1]);
    return result;
}

export {InteractiveField};